import React, { useState, useEffect, useContext } from 'react';
import { LegalContext } from './context';
import {
  BodyLegalCreateUpdateLor,
  LegalStore,
  StoreStateV3,
  PostLegalCreateUpdateLor,
  GetLegalLor,
  GetLegalList,
  HttpTokens,
  LegalFilterQuery,
  GetLegalBuRelated,
  BodyUploadLegalFile,
  PostLegalFileUpload,
  RemoveLegalFile,
  LorHistoryQuery,
  LegalFileSchema,
  LegalFileRemoveSchema,
  GetLegalCharts,
  DeleteLegalLor,
  EmployeeByQuery,
  GetEmployeeByQuery,
  GetLorHistory,
  LegalChartsQuery,
  EmployeeSchema,
  PostLegalLorEvaluation,
  BodyLegalLorEvaluation,
  LegalLorProgressBarQuery,
  GetLegalLorProgressBar,
  BodyPostLegalLorStartEval,
  PostLegalLorStartEval,
  LegalChartSchema,
  GetLegalHistory,
  GetLegalHistoryList,
} from '@/src/types';
// import { getJwtToken } from '@/src/utils';

// import { Logger } from '@/logger/index';
import { AzureAuthProviderProps, NotificationStatus, StoreStateDefaultV3, StoreStateDefaultV4 } from '@/types/store';
import { getJwtToken, transformLegalDataToListPageStore } from '@/src/utils';
import {
  LegalLORCreateUpdatePost,
  LegalLorDetailGet,
  LegalListGet,
  LegalBuRelatedFilterGet,
  UploadFileUpload,
  LegalFileRemove,
  LegalChartsGet,
  LegalDelete,
  EmployeeQueryGet,
  LegalLorHistoryGet,
  LegalLORevaluationPost,
  LegalLorProgressGet,
  LegalLORStartEvaluationPost,
  LegalHistoryGet,
  LegalHistoryListGet,
} from '@/services/http';
// import { mockupRegalFilter } from '@/src/static/legal/mockupData';
import { Logger } from '@/logger/index';

/**
 * to give us access to azure for admin
 */
interface ProviderProps {
  children: React.ReactNode;
  context: {
    [name: string]: any;
    azure: AzureAuthProviderProps;
  };
}

export const useLegalInitialState = () => useContext(LegalContext);

const LegalContextProvider = (props: ProviderProps) => {
  // const { children, context } = props;
  const { children } = props;
  /** Access to azure  */
  // const { azure } = context;
  const contextInitialState = useLegalInitialState();
  const tokens: HttpTokens = { jwt: getJwtToken() };

  const [notificationStatus, setNotificationStatus] = useState<NotificationStatus>(contextInitialState.store.notify.notificationStatus);
  const [legalLorUpdateCreateData, setLegalLorUpdateCreate] = useState<StoreStateDefaultV3<PostLegalCreateUpdateLor>>(
    contextInitialState.store.legalLorUpdateCreateData
  );

  const [legalLorDetailData, setLegalLorDetail] = useState<StoreStateDefaultV4<GetLegalLor>>(contextInitialState.store.legalLorDetailData);
  const [legalListData, setLegalList] = useState<StoreStateDefaultV3<GetLegalList>>(contextInitialState.store.legalListData);
  const [legalBuRelatedFilter, setLegalBuRelatedFilter] = useState<StoreStateDefaultV3<GetLegalBuRelated>>(contextInitialState.store.legalBuRelatedFilter);

  const [legalFileUploadData, setLegalFileUpload] = useState<StoreStateDefaultV3<PostLegalFileUpload>>(contextInitialState.store.legalFileUploadData);
  const [legalFileRemoveData, setLegalFileRemove] = useState<StoreStateDefaultV3<RemoveLegalFile>>(contextInitialState.store.legalFileRemoveData);
  const [legalDeleteData, setLegalDelete] = useState<StoreStateDefaultV3<DeleteLegalLor>>(contextInitialState.store.legalDeleteData);

  const [legalChartsData, setLegalCharts] = useState<StoreStateDefaultV3<GetLegalCharts>>(contextInitialState.store.legalChartsData);
  const [legalEmployeesQueryData, setLegalEmployeesQuery] = useState<StoreStateDefaultV3<GetEmployeeByQuery>>(
    contextInitialState.store.legalEmployeesQueryData
  );

  const [legalLorHistoryData, setLegalLorHistory] = useState<StoreStateDefaultV3<GetLorHistory>>(contextInitialState.store.legalLorHistoryData);
  const [legalEvaluationData, setLegalEvaluation] = useState<StoreStateDefaultV4<PostLegalLorEvaluation>>(contextInitialState.store.legalEvaluationData);

  const [legalLorProgressData, setLegalLorProgress] = useState<StoreStateDefaultV3<GetLegalLorProgressBar>>(contextInitialState.store.legalLorProgressData);
  const [legalLorStartEvaData, setLegalLorStartEva] = useState<StoreStateDefaultV3<PostLegalLorStartEval>>(contextInitialState.store.legalLorStartEvaData);

  const [legalHistoryData, setLegalHistory] = useState<StoreStateDefaultV4<GetLegalHistory>>(contextInitialState.store.legalHistoryData);
  const [legalHistoryListData, setLegalHistoryList] = useState<StoreStateDefaultV3<GetLegalHistoryList>>(contextInitialState.store.legalHistoryListData);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const store: LegalStore = {
    store: {
      legalChartsData,
      legalLorUpdateCreateData,
      legalLorDetailData,
      legalListData,
      legalBuRelatedFilter,
      legalFileUploadData,
      legalFileRemoveData,
      legalDeleteData,
      legalEmployeesQueryData,
      legalLorHistoryData,
      legalEvaluationData,
      legalLorProgressData,
      legalLorStartEvaData,
      legalHistoryData,
      legalHistoryListData,
      notify: {
        notificationStatus,
        /**
         * call to action when status has changed
         */
        onStatusChange: (type: NotificationStatus, message?: string) => {
          if (!isMounted) return;
          setNotificationStatus(type);
          if (message) {
            Logger([`[notify][onStatusChange][${type}]`, message]);
          }
        },
      },
      resetStatus: (apiName: string) => {
        if (!isMounted) return;
        if (apiName === 'legal/lor/create-update') {
          setLegalLorUpdateCreate({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'legal/list') {
          setLegalList({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'legal/lor/detail') {
          setLegalLorDetail({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'legal/cache/file/upload') {
          setLegalFileUpload({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'legal/cache/file/remove') {
          setLegalFileRemove({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'legal/delete/lor') {
          setLegalDelete({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'legal/lor/history') {
          setLegalLorHistory({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'legal/lor/evaluation') {
          setLegalEvaluation({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'legal/lor/progress') {
          setLegalLorProgress({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'legal/lor/start-eval') {
          setLegalLorStartEva({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'legal/charts') {
          setLegalCharts({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'legal/history') {
          setLegalHistory({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'legal/history-list') {
          setLegalHistoryList({ data: undefined as any, state: 'initial' });
        }
      },
      resetState: (apiName: string, state: StoreStateV3) => {
        // if (!isMounted) return;
      },
      setLegalLorUpdateCreate: (body: BodyLegalCreateUpdateLor, conditions?: 'NO_STORE_UPDATE', type?: 'new' | 'update', done?: (ok: boolean) => void) => {
        if (!isMounted) return;
        if (conditions !== 'NO_STORE_UPDATE') setLegalLorUpdateCreate({ data: undefined as any, state: 'loading' });
        LegalLORCreateUpdatePost('legal/lor/create-update', tokens, body)
          .then((data) => {
            if (conditions === 'NO_STORE_UPDATE') {
              // legalListData;

              let d = legalListData.data?.data || [];
              let updated = false;

              if (legalListData.data?.data) {
                d = d.map((n) => {
                  if (n.id === data.data.id) {
                    n = transformLegalDataToListPageStore(n, data.data, 'update');
                    updated = true;
                  }
                  return n;
                });

                if (!updated) {
                  d = [...[transformLegalDataToListPageStore(null as any, data?.data, 'new')], ...d];
                  if (d.length > legalListData.data?.pagination?.itemPerPage) {
                    Logger(['[LegalStore][setLegalLorUpdateCreate] new row added to list, then delete last row', d], 'notice');
                    d = d.slice(0, legalListData.data?.pagination?.itemPerPage);
                  }
                  if (legalListData.data?.pagination?.totalItems) legalListData.data.pagination.totalItems = legalListData.data.pagination.totalItems + 1;
                  updated = true;
                }

                if (updated) {
                  if (legalListData.data?.data) {
                    legalListData.data.data = d;
                    delete legalListData.error;
                    legalListData.state = 'ready';
                    setLegalList(legalListData);
                    Logger(['[LegalLORCreateUpdatePost][legalListData][updated]', legalListData], 'notice');
                  }
                }
              }
              if (done) done(true);
            }
            // NOTE we currently dont use this function without the condition so can comment out the logic
            // but if we do, then we have to use common (above) logic for both
            // if (isMounted) {
            //   setLegalLorUpdateCreate({ data, state: 'ready' });
            //   if (done) done(true);
            // }
          })
          .catch((error) => {
            if (conditions === 'NO_STORE_UPDATE') {
              if (done) done(false);
              return;
            }
            if (isMounted) {
              setLegalLorUpdateCreate({ data: undefined as any, error, state: 'error' });
              if (done) done(false);
            }
          });
      },
      setLegalList: (params: LegalFilterQuery, type?: 'hidden_load_after_deleted') => {
        if (!isMounted) return;
        if (!type) setLegalList({ data: undefined as any, state: 'loading' });
        LegalListGet('legal', params, tokens)
          .then((data) => {
            if (isMounted) setLegalList({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setLegalList({ data: undefined as any, error, state: 'error' });
          })
          .then(() => {
            // NOTE: to reset legalDeleteData after hidden load success
            if (type === 'hidden_load_after_deleted') setLegalDelete({ data: undefined as any, state: 'initial' });
          });
      },
      setLegalLorDetail: (id: string, query: LorHistoryQuery = {} as any, action?: 'update' | 'new', done?: (ok: boolean) => void, ref?: string) => {
        if (!isMounted) return;

        if (!action || action !== 'update') setLegalLorDetail({ data: undefined as any, state: 'loading' });
        LegalLorDetailGet('legal/lor', id, query, tokens)
          .then((data) => {
            if (isMounted) {
              setLegalLorDetail({ data, state: 'ready', ref });

              // NOTE: update list of legal if it has data row with the same id and the same deadline(different in each year)
              // if (legalListData.data?.data) {
              //   let d = legalListData.data.data || [];
              //   let updated = false;
              //   d = d.map((n) => {
              //     // REVIEW to change to  n.evaluationId === data.data.evaluationId
              //     // once backend update api
              //     // if (n.evaluationId === data.data.evaluationId) {
              //     if (n.deadline === data.data.deadline && n.id === data.data.id) {
              //       n = transformLegalDataToListPageStore(n, data.data, 'update');
              //       updated = true;
              //     }
              //     return n;
              //   });
              //   if (updated) {
              //     legalListData.data.data = d;
              //     setLegalList(legalListData);
              //     Logger(['[LegalLorDetailGet][legalListData][updated]', legalListData], 'notice');
              //   }
              // }
              if (done) done(true);
            }
          })
          .catch((error) => {
            if (isMounted) {
              // NOTE original reference matching possible success, now we check for error conditions
              const _ref = ref === 'data-submitted' ? 'data-submitted-error' : ref === 'data-updated' ? 'data-updated-error' : ref;
              setLegalLorDetail({ data: undefined as any, error, state: 'error', ref: _ref });
              if (done) done(false);
            }
          });
      },
      setLegalBuRelatedFilter: () => {
        if (!isMounted) return;
        setLegalBuRelatedFilter({ data: undefined as any, state: 'loading' });

        LegalBuRelatedFilterGet('legal/bu-related', tokens)
          .then((data) => {
            if (isMounted) setLegalBuRelatedFilter({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setLegalBuRelatedFilter({ data: undefined as any, error, state: 'error' });
          });
      },

      setLegalCharts: (query?: LegalChartsQuery, action?: 'update' | 'new', done?: (ok: boolean, data?: LegalChartSchema) => void) => {
        if (!isMounted) return;
        if ((!action || action === 'new') && !done) setLegalCharts({ data: undefined as any, state: 'loading' });

        LegalChartsGet('legal/charts', query || {}, tokens)
          .then((data) => {
            if (!isMounted) return;
            if (done) {
              done(true, data.data);
              return;
            }
            setLegalCharts({ data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            if (done) {
              done(true, null as any);
              return;
            }
            setLegalCharts({ data: undefined as any, error, state: 'error' });
          });
      },

      setLegalFileUpload: (body: BodyUploadLegalFile, ref: string, conditions?: 'NO_STORE_UPDATE', done?: (ok: boolean, data?: LegalFileSchema) => void) => {
        if (!isMounted) return;
        if (conditions !== 'NO_STORE_UPDATE') setLegalFileUpload({ data: undefined as any, state: 'loading' });
        UploadFileUpload('legal/cache/file/upload', ref, body, tokens)
          .then((data) => {
            if (conditions === 'NO_STORE_UPDATE') {
              if (done) done(true, data?.data);
              return;
            }
            if (isMounted) {
              setLegalFileUpload({ data, state: 'ready' });
              if (done) done(true, data?.data);
            }
          })
          .catch((error) => {
            if (conditions === 'NO_STORE_UPDATE') {
              if (done) done(false, null as any);
              return;
            }
            if (isMounted) {
              setLegalLorDetail({ data: undefined as any, error, state: 'error' });
              if (done) done(false, null as any);
            }
          });
      },

      setLegalFileRemove: (id: string, conditions?: 'NO_STORE_UPDATE', done?: (ok: boolean, data?: LegalFileRemoveSchema) => void) => {
        if (!isMounted) return;

        if (conditions !== 'NO_STORE_UPDATE') setLegalFileRemove({ data: undefined as any, state: 'loading' });

        LegalFileRemove('legal/cache/file/remove', id, tokens)
          .then((data) => {
            if (conditions === 'NO_STORE_UPDATE') {
              if (done) done(true, data?.data);
              return;
            }

            if (isMounted) {
              setLegalFileRemove({ data, state: 'ready' });
              if (done) done(true, data?.data);
            }
          })
          .catch((error) => {
            if (conditions === 'NO_STORE_UPDATE') {
              if (done) done(false, null as any);
              return;
            }
            if (isMounted) {
              setLegalFileRemove({ data: undefined as any, error, state: 'error' });
              if (done) done(false, null as any);
            }
          });
      },
      setLegalDelete: (id: string) => {
        if (!isMounted) return;
        setLegalDelete({ data: undefined as any, state: 'loading' });
        LegalDelete('legal/delete/lor', id, tokens)
          .then((data) => {
            if (isMounted) {
              const d = legalListData.data?.data.filter((n) => n.id !== data.data.id);
              legalListData.data.data = d;
              setLegalList(legalListData);
              setLegalDelete({ data, state: 'ready' });
            }
          })
          .catch((error) => {
            if (isMounted) setLegalDelete({ data: undefined as any, error, state: 'error' });
          });
      },
      // NOTE: this employees data is from API GET/employees/query
      setLegalEmployeesQuery: (
        params: EmployeeByQuery,
        action?: 'updated' | 'new' | 'NO_STORE_UPDATE',
        done?: (ok: boolean, data?: EmployeeSchema[]) => void
      ) => {
        if (!isMounted) return;
        if (!action || action === 'new') setLegalEmployeesQuery({ data: undefined as any, state: 'loading' });
        EmployeeQueryGet('employees/query', params, tokens)
          .then((data) => {
            if (isMounted) {
              if (action === 'NO_STORE_UPDATE') {
                if (done) {
                  return done(true, data.data as any);
                }
              } else setLegalEmployeesQuery({ data, state: 'ready' });
            }
          })
          .catch((error) => {
            if (isMounted) {
              if (action === 'NO_STORE_UPDATE') {
                if (done) {
                  return done(false, null as any);
                }
              } else setLegalEmployeesQuery({ data: undefined as any, error, state: 'error' });
            }
          });
      },
      setLegalLorHistory: (id: string, params: LorHistoryQuery) => {
        if (!isMounted) return;
        setLegalLorHistory({ data: undefined as any, state: 'loading' });
        LegalLorHistoryGet('legal/lor/history', id, params, tokens)
          .then((data) => {
            if (isMounted) setLegalLorHistory({ data: data as any, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setLegalLorHistory({ data: undefined as any, error, state: 'error' });
          });
      },
      setLegalEvaluation: (lorUid: string, body: BodyLegalLorEvaluation, ref?: string) => {
        if (!isMounted) return;
        setLegalEvaluation({ data: undefined as any, state: 'loading' });
        LegalLORevaluationPost('legal/lor/evaluation', lorUid, body, tokens)
          .then((data) => {
            const _ref = ref === 'data-submitted' ? 'data-submitted' : 'data-updated';
            if (isMounted) {
              // we need to update lor list page status
              if (legalListData?.data?.data) {
                let updated = false;

                const d = legalListData?.data?.data.map((n) => {
                  if (n.evaluationId === data.data?.evaluationId) {
                    // if completed, then no longer overdue
                    if (data.data.status === 5) {
                      n.overdueState = 0;
                    }
                    n.status = data.data.status as any;
                    updated = true;
                  }
                  return n;
                });
                if (updated) {
                  legalListData.data.data = d;
                  setLegalList(legalListData);
                }
              }

              setLegalEvaluation({ data: data as any, state: 'ready', ref: _ref });
            }
          })
          .catch((error) => {
            // NOTE original reference matching possible success, now we check for error conditions
            const _ref = ref === 'data-submitted' ? 'data-submitted-error' : ref === 'data-updated' ? 'data-updated-error' : ref;
            if (isMounted) setLegalEvaluation({ data: undefined as any, error, state: 'error', ref: _ref });
          });
      },
      setLegalLorProgress: (id: string, params: LegalLorProgressBarQuery) => {
        if (!isMounted) return;
        setLegalLorProgress({ data: undefined as any, state: 'loading' });
        LegalLorProgressGet('legal/lor/progress-bar', id, params, tokens)
          .then((data) => {
            if (isMounted) setLegalLorProgress({ data: data as any, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setLegalLorProgress({ data: undefined as any, error, state: 'error' });
          });
      },
      setLegalLorStartEva: (id: string, body: BodyPostLegalLorStartEval, done?: (ok: boolean) => void) => {
        if (!isMounted) return;
        setLegalLorStartEva({ data: undefined as any, state: 'loading' });
        LegalLORStartEvaluationPost('legal/lor/start-eval', id, body, tokens)
          .then((data) => {
            if (isMounted) {
              if (legalListData?.data?.data) {
                let updated = false;
                const d = legalListData?.data?.data.map((n) => {
                  if (n.id === data.data.lorId) {
                    if (n.evaluationId !== data.data?.evaluationId) {
                      if (n.evaluateDate) {
                        // NOTE: this LOR eval already exists
                        const inListEvalDate = new Date(n.evaluateDate);
                        const newEvalDate = new Date(data.data.evaluateDate);
                        if (newEvalDate > inListEvalDate) {
                          // NOTE: the exist eval older than the new eval
                          n.evaluateDate = data.data.evaluateDate;
                          n.evaluationId = data.data?.evaluationId;
                          n.overdueState = data.data?.overdueState;
                          n.status = data.data?.status;
                          updated = true;
                        } else {
                          // NOTE: the exist eval not older than the new eval
                        }
                      } else {
                        // NOTE: this started eval is the first eval of this LOR
                        n.evaluateDate = data.data.evaluateDate;
                        n.evaluationId = data.data?.evaluationId;
                        n.overdueState = data.data?.overdueState;
                        n.status = data.data?.status;
                        updated = true;
                      }
                    } else {
                      // NOTE: new logic should not be possible for this case
                    }
                  }
                  return n;
                });
                if (updated) {
                  legalListData.data.data = d;
                  setLegalList(legalListData);
                  Logger(['[LegalStore][setLegalLorStartEva] new evaluation updated to list', d], 'notice');
                }
              }
              setLegalLorStartEva({ data, state: 'ready' });
              if (done) done(true);
            }
          })
          .catch((error) => {
            if (isMounted) {
              setLegalLorStartEva({ data: undefined as any, error, state: 'error' });
              if (done) done(false);
            }
          });
      },

      setLegalHistory: (id: string) => {
        if (!isMounted) return;
        LegalHistoryGet('legal/history', id, tokens)
          .then((data) => {
            if (isMounted) setLegalHistory({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setLegalHistory({ data: undefined as any, error, state: 'error' });
          });
      },
      setLegalHistoryList: (id: string, query: LorHistoryQuery = {} as any) => {
        if (!isMounted) return;
        LegalHistoryListGet('legal/history-list', id, query, tokens)
          .then((data) => {
            if (isMounted) setLegalHistoryList({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setLegalHistoryList({ data: undefined as any, error, state: 'error' });
          });
      },
    },
  };

  return <LegalContext.Provider value={store}>{children}</LegalContext.Provider>;
};

const withLegalContext = (Component: any) => {
  return function LegalComponent(props: any) {
    return <LegalContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</LegalContext.Consumer>;
  };
};

export { LegalContextProvider, withLegalContext };
