import chalk from 'chalk';

/**
 * @Logger
 * @description Use the logger to print meaningful messages, preferably over console.log
 * - We can control how to interact with logger based on environment
 * */
export const Logger = (messages: any[], type: 'debug' | 'log' | 'notice' | 'warn' | 'error' | 'attention' = 'log', printLogType: boolean = false): void => {
  // TODO add
  // if(ENVS.ENVIRONMENT!=='LOCAL') dont print ?

  if (printLogType && typeof messages[0] === 'string') messages[0] = `[${type}] ` + messages[0];

  const msg = () => {
    return (messages || []).slice(1);
  };

  if (type === 'attention') {
    if (typeof messages[0] !== 'string') {
      console.log(chalk.bgWhiteBright.magenta.bold('[log]'), ...msg());
    } else console.log(chalk.bgWhiteBright.magenta.bold(messages[0]), ...msg());
  }

  if (type === 'log') {
    if (typeof messages[0] !== 'string') {
      console.log(chalk.bgWhiteBright.grey.bold('[log]'), ...msg());
    } else console.log(chalk.bgWhiteBright.grey.bold(messages[0]), ...msg());
  }

  if (type === 'debug') {
    if (typeof messages[0] !== 'string') {
      console.log(chalk.bgWhiteBright.bold.black('[debug]'), ...messages);
    } else console.log(chalk.bgWhiteBright.bold.black(messages[0]), ...msg());
  }

  if (type === 'warn') {
    if (typeof messages[0] !== 'string') {
      console.log(chalk.bgWhiteBright.magenta('[warn]'), ...messages);
    } else console.log(chalk.bgWhiteBright.magenta(messages[0]), ...msg());
  }

  if (type === 'notice') {
    if (typeof messages[0] !== 'string') {
      console.log(chalk.bgWhiteBright.green('[notice]'), ...messages);
    } else console.log(chalk.bgWhiteBright.green(messages[0]), ...msg());
  }

  if (type === 'error') {
    if (typeof messages[0] !== 'string') {
      console.log(chalk.bgWhiteBright.bold.red('[error]'), ...messages);
    } else console.log(chalk.bgWhiteBright.bold.red(messages[0]), ...msg());
  }
};
