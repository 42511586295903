import React from 'react';
import { PartOneSectionOne, PartOneSectionThree, PartOneSectionTwo } from '@/src/components';
import { IncidentFormStoreParts, IncidentStore } from '@/src/types';
// import { Logger } from '@/logger/index';

interface InitialReportPartOneProps {
  incidentStore: IncidentStore;
  isReadyToSaveDraftIncident: boolean;
}

export const InitialReportPartOne = (props: InitialReportPartOneProps & IncidentFormStoreParts) => {
  const {
    formStore,
    incidentStore: { store: incidentStore },
    isReadyToSaveDraftIncident,
  } = props;

  // Logger(['[InitialReportPartOne][formStore][initialReport]', formStore.store.formData.initialReport]);

  return (
    <>
      {/* section 1 */}
      <PartOneSectionOne formStore={{ formStore }} isReadyToSaveDraftIncident={isReadyToSaveDraftIncident} />

      {/* section 2 */}
      <PartOneSectionTwo formStore={{ formStore }} incidentStore={{ store: incidentStore }} isReadyToSaveDraftIncident={isReadyToSaveDraftIncident} />

      {/* section 3 */}
      <PartOneSectionThree formStore={{ formStore }} incidentStore={{ store: incidentStore }} />
    </>
  );
};
