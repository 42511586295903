import React, { useState, useEffect } from 'react';
import { Icon } from '../../ui/icons';
import { TextField, Typography, Autocomplete, Box, FormControl, InputLabel, CircularProgress } from '@mui/material';
import { EmployeeSchema } from '@/src/types';
import { isArray, uniqBy } from 'lodash';

type EmployeeAttendeeList = Array<{ employeeId: string; firstName: string; middleName?: string; lastName: string }>;
interface TagsInputProp {
  data: EmployeeSchema[];
  /** CTA indication of call, did we execute what ? */
  action: 'submit-publish' | 'submit-draft';
  [name: string]: any;
  mode?: 'edit' | 'create' | 'edit-publish';
  selectedAttendees?: EmployeeAttendeeList;
  handleKeyFilter?: (val: any) => any;
  handleKeyPress?: (val: any, lastSearch?: string) => any;
  handleDelete?: (e: React.KeyboardEvent<HTMLDivElement>) => any;
  handleOnSelect?: (employees: EmployeeSchema[]) => any;
  /** remove all except currently selectedIndex */
  handleReset?: (employees: EmployeeSchema[]) => any;
  error: boolean;
}

interface SelectedItems extends EmployeeSchema {
  label: string;
  key: string;
}
interface Employee extends EmployeeSchema {
  hidden?: boolean;
}

export default function TagsInput(props: TagsInputProp) {
  const {
    error,
    // action,
    handleKeyFilter,
    handleKeyPress,
    // handleDelete,
    handleOnSelect,
    data,
    mode,
    selectedAttendees,
    handleReset,
  } = props;
  const [total, setTotal] = useState(selectedAttendees?.length || 0);
  const [useData, setData] = useState<Employee[]>(data as any);
  const [selected, setSelectedData] = useState<SelectedItems[]>([]);
  const [latestWords, setAddWords] = useState('');
  const [selectedIndex, setSelectIndex] = useState<number>(null as any);
  const [preSelected, setPreSelected] = useState<EmployeeAttendeeList>(null as any);

  const [selectRef, setSelectRef] = useState<Element & { eventSet: boolean }>(null as any);
  const _mode = !mode ? 'create' : mode;

  useEffect(() => {
    if (isArray(data)) setData(data);
  }, [useData, setData, data]);

  useEffect(() => {
    if (selectedAttendees && ['edit', 'edit-publish'].indexOf(_mode) !== -1 && !preSelected) {
      setPreSelected(selectedAttendees as any);
    }
  }, [preSelected, setPreSelected, selectedAttendees, _mode, data]);

  const TextFieldProps = (params: any) => {
    if (['edit', 'edit-publish'].indexOf(_mode) !== -1 && !error) return params?.InputProps;
    return params?.InputProps;
  };

  if (selectRef) {
    if (!selectRef.eventSet) {
      // ATTENTION  this binding implementation works over component event
      selectRef.addEventListener('keydown', (e: any) => {
        const event: KeyboardEvent = e as any;
        const target: string = (event.target as any)?.value || '';
        if ((event.keyCode === 46 || event.keyCode === 8) && target?.length < 1) {
          event.preventDefault();
          event.stopPropagation();
        }
        return false;
      });
    }
    selectRef.eventSet = true;
  }

  const optionsText = (data: EmployeeSchema[], show = !!(latestWords || '').length) => {
    const dd: Employee[] = uniqBy([].concat([...data] as any, [...selected] as any), 'employeeId');
    return !dd
      ? []
      : (dd || [])
          // NOTE dont show results in dropdown if no values typed in
          // this is a small hack, it will not display cached values already available> but only after we entre some key,
          // then if more then 2 strokes it will combine with new and cached response together.
          .filter((n) => show)
          .filter((n) => !n.hidden)
          .map((n, index) => {
            let label;
            if (n.middleName) label = `${n.firstName} ${n.lastName} (${n.middleName})`;
            else label = `${n.firstName} ${n.lastName}`;

            return {
              label,
              key: n.employeeId + index,
              ...(n as any),
            };
          });
  };

  let optText: any;
  // NOTE we need loader to initialize <Autocomplete/> defaultValue for edit mode
  if (['edit', 'edit-publish'].indexOf(_mode) !== -1 && !preSelected && !error) {
    return <CircularProgress />;
  } else if (['edit', 'edit-publish'].indexOf(_mode) !== -1) {
    optText = optionsText(preSelected as any, true);
  }

  return (
    <Box className="relative tagsInput">
      <Typography className="absolute flex items-flex-start right-0 font-medium">
        <Icon className="mr-1" icon="userGroup" color="#000000" viewBox="0 0 640 512" size={18} /> Total: {total}
      </Typography>
      <FormControl className="w-full" required error={selectedIndex === 0}>
        <InputLabel shrink>Search for attendees</InputLabel>
        <Autocomplete
          ref={(ref: Element) => {
            setSelectRef(ref as any);
          }}
          defaultValue={optText}
          multiple
          noOptionsText={'No options'}
          id="autocomplete-tags-outlined"
          options={optionsText(useData)}
          filterSelectedOptions={true}
          renderOption={(props, option: any) => (
            <Box component="li" {...props} key={option.key}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              // required={action === 'submit-publish'}
              {...params}
              placeholder="Enter name or email who you want to select"
              InputProps={{
                //   required: action === 'submit-publish',
                ...TextFieldProps(params),
                //   endAdornment: <React.Fragment>{!useData?.length ? null : params.InputProps.endAdornment}</React.Fragment>,
              }}
            />
          )}
          // onMouseDown={() => {
          //   if (!latestWords?.length) {
          //     hideHack();
          //   }
          // }}
          // onFocus={() => {
          //   if (!latestWords?.length) {
          //     hideHack();
          //   }
          // }}

          onBlur={() => {
            const d = useData.map((n) => {
              n.hidden = true;
              return n;
            });
            setAddWords('');
            setData(d);
          }}
          isOptionEqualToValue={(option, value) => {
            return option.employeeId === value.employeeId;
          }}
          onChange={(e, allSelected) => {
            // do not execute for keydown events
            if (e.type !== 'keydown') {
              if (handleOnSelect) {
                handleOnSelect((allSelected || []) as any);
                setTotal(allSelected.length);
                setSelectIndex(allSelected.length);
                const d = useData?.length ? useData.filter((n) => allSelected.filter((x) => x.employeeId === n.employeeId).length) : allSelected;
                setSelectedData(d);

                if (!d.length) {
                  if (handleReset) handleReset(allSelected);
                }
              }
            }
          }}
          onKeyPress={(e) => {
            if (handleKeyPress) handleKeyPress(e, latestWords);
          }}
          onInputChange={function (event, inputValue, type: 'input' | 'change' | 'reset' | any) {
            setAddWords(inputValue);
            if (handleKeyFilter) {
              // NOTE only execute on some input
              if ((inputValue || '').length > 0) handleKeyFilter(inputValue);
            }
          }}
        />
      </FormControl>
    </Box>
  );
}
