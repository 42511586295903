import React, { Component } from 'react';
import { Subject } from 'rxjs';
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material';
import { Icon } from '@/src/components';
import { IncidentFormStoreParts, FileDropped, IncidentFileSchema, UploadIncidentFile } from '@/src/types';
import { v4 } from 'uuid';
import { IncidentStore } from '@/types/store/index';
import { Logger } from '@/logger/index';
import { genNewFile, delay, validFileSize } from '@/src/utils';
import Dropzone, { FileRejection } from 'react-dropzone';
import { clone } from 'lodash';
import { AddImageSectionDto } from '@/src/components/dto';
import WarningIcon from '@mui/icons-material/Warning';
import Masonry from '@mui/lab/Masonry';
interface AddImageSectionProps extends IncidentFormStoreParts {
  [name: string]: any;
  incidentStore: IncidentStore;
}

interface IncidentFileSchemaAlt extends IncidentFileSchema {
  /** local file we dropped to render  */
  file?: FileDropped;
  /** on drag image to upload */
  toUpload?: boolean;
  /* to check file upload error */
  errorUpload?: boolean;
}

interface State {
  error: any;
  files: IncidentFileSchemaAlt[];
  imageRef: any;
  filesUploading: { ref: string; fileName: string; uploadStatus: 'uploading' | 'upload_success' | 'upload_fail' }[];
  isRemoving: boolean;
  remainingFileSlot: number;
  modalState: { isOpen: boolean; title: string; description: string };
}
export default class AddImageSection extends Component<AddImageSectionProps, {}, any> {
  maxFileUpload = 5;
  modalText = {
    serverError: { title: 'Failed to upload', description: 'File could not uploaded for some reason. Please try uploading it again.' },
    maxFileSizeError: { title: 'Failed to upload', description: 'File size exceeds maximum limit. Maximum allowed file size is 5 MB.' },
    maxFileCountError: { title: 'Maximum 5 images can be upload.', description: 'Please select 5 images and try uploading it again.' },
    fileTypeError: {
      title: 'Invalid file type. Allowed file types are JPG, PNG, SVG, GIF, WebP, AV1/AVIF.',
      description: 'Please upload images with the allowed file types.',
    },
  };

  subs$: Array<any> = [];
  subSearch$: Subject<{ q: string }> = new Subject();
  state: State;
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      files: [] as any,
      imageRef: null,
      filesUploading: [],
      isRemoving: false,
      remainingFileSlot: 5,
      modalState: { isOpen: false, title: '', description: '' },
    };
  }

  checkAllUploaded(currentFileRef: string | undefined): boolean {
    let isAllUploaded = true;
    this.state.filesUploading.forEach((file) => {
      if (file.ref !== currentFileRef && file.uploadStatus !== 'upload_success') isAllUploaded = false;
      if (file.ref === currentFileRef) Logger(['[AddImageSection][checkAllUploaded][currentFileRef] : file upload SUCCESS >>>', file.fileName], 'notice');
    });
    return isAllUploaded;
  }

  checkAllUploadedReady(currentFileRef: string | undefined): boolean {
    let isAllUploaded = true;
    this.state.filesUploading.forEach((file) => {
      if (file.ref !== currentFileRef && file.uploadStatus === 'uploading') isAllUploaded = false;
      if (file.ref === currentFileRef) Logger(['[AddImageSection][checkAllUploaded][currentFileRef] : file upload Failed >>>', file.fileName], 'error');
    });
    return isAllUploaded;
  }

  componentDidUpdate(prevProps) {
    if (this.props.incidentStore.store.incidentFileUploadData.state === 'ready' && this.state.filesUploading.length > 0) {
      const incidentFileUploadData: UploadIncidentFile = clone(this.props.incidentStore.store.incidentFileUploadData.data);
      this.setState((prevState: State) => {
        const newFilesUploading = prevState.filesUploading.map((file) => {
          if (file.ref === incidentFileUploadData?.data?.ref) return { ...file, uploadStatus: 'upload_success' };
          else return file;
        });
        Logger(['[AddImageSection][state][filesUploading] : CHECK which filesUploading uploadStatus SUCCESS!!! >>>', newFilesUploading], 'notice');
        return { ...prevState, filesUploading: newFilesUploading };
      });
      let updated = false;
      const files = this.state.files.map((n) => {
        if (n.file?.refer === incidentFileUploadData.data.ref) {
          if (!(n as any).testImage) n = new AddImageSectionDto(incidentFileUploadData.data);
          n.file = genNewFile(n.url, n.uid);
          n = { ...n, ...incidentFileUploadData.data };
          updated = true;
        }
        return n;
      });
      if (updated) {
        this.setState({ files: files });
      }
      this.props.incidentStore.store.resetStatus('incidents/cache/file/upload');
      if (this.checkAllUploaded(incidentFileUploadData?.data?.ref)) {
        this.props.incidentStore.store.notify.onStatusChange('uploaded');
        this.setState({ filesUploading: [] });
        this.handleChangeStoreUpdate(files?.length ? clone(files) : null, 'images');
      }
      return;
    }
    if (this.props.incidentStore.store.incidentFileUploadData.state === 'error' && this.state.filesUploading.length > 0) {
      const incidentFileUploadData: UploadIncidentFile = clone(this.props.incidentStore.store.incidentFileUploadData.data);
      const newFiles = this.state.files.map((file) => {
        if (file.file?.refer === incidentFileUploadData?.status?.ref) return { ...file, errorUpload: true };
        else return file;
      });
      this.setState((prevState: State) => {
        const newFilesUploading = prevState.filesUploading.map((file) => {
          if (file.ref === incidentFileUploadData?.status?.ref) return { ...file, uploadStatus: 'upload_fail' };
          else return file;
        });
        Logger(['[AddImageSection][state][filesUploading] : CHECK which filesUploading uploadStatus ERROR!!! >>>', newFilesUploading], 'error');
        return { ...prevState, files: newFiles, filesUploading: newFilesUploading };
      });
      this.props.incidentStore.store.resetStatus('incidents/cache/file/upload');
      if (this.checkAllUploadedReady(incidentFileUploadData?.status?.ref)) {
        this.props.incidentStore.store.notify.onStatusChange('upload-error');
        this.setState({
          filesUploading: [],
          modalState: { isOpen: true, title: this.modalText.serverError.title, description: this.modalText.serverError.description },
        });
        this.handleChangeStoreUpdate(newFiles?.length ? clone(newFiles) : null, 'images');
      }
      return;
    }
    if (this.props.incidentStore.store.incidentFileRemoveData.state === 'ready' && this.state.isRemoving === true) {
      this.props.incidentStore.store.notify.onStatusChange('removed');
      this.props.incidentStore.store.resetStatus('incidents/cache/file/remove');
      this.setState({ isRemoving: false });
      return;
    }
    if (this.props.incidentStore.store.incidentFileRemoveData.state === 'error' && this.state.isRemoving === true) {
      this.props.incidentStore.store.notify.onStatusChange('remove-error');
      this.props.incidentStore.store.resetStatus('incidents/cache/file/remove');
      this.setState({ isRemoving: false });
    }
  }

  handleChangeStoreUpdate(value: any, keyName: string, subKeyName?: string) {
    if (!this.props.formStore) return;
    this.props.formStore.setUpdateFormItem('description', 'updated').setup(value, keyName, subKeyName);
  }

  componentDidMount() {
    let descImages: IncidentFileSchemaAlt[] = (this.props.formStore.store.formData?.description.data?.images || []) as any;
    descImages = descImages.slice(0, this.maxFileUpload);
    if (descImages.length) {
      const newDescImages = descImages.map((n) => {
        if (!(n as any).testImage) n = new AddImageSectionDto(n);
        n.file = genNewFile(n.url, n.uid);
        return n;
      });
      this.setState({ files: newDescImages, remainingFileSlot: this.maxFileUpload - newDescImages.length });
    }
  }

  componentWillUnmount() {}
  fileUploadInvalid(droppedFiles: IncidentFileSchemaAlt[]): boolean {
    // check if any file is larger then 5mg
    let tooLarge = false;
    for (const item of droppedFiles) {
      if (!validFileSize(item.file as any, this.maxFileUpload)) {
        tooLarge = true;
        break;
      }
    }
    if (tooLarge) {
      // this.props.incidentStore.store.notify.onStatusChange('file-too-large');
      // this.props.incidentStore.store.resetStatus('incidents/cache/file/remove');
      this.setState({
        modalState: { isOpen: true, title: this.modalText.maxFileSizeError.title, description: this.modalText.maxFileSizeError.description },
      });
    }
    return tooLarge;
  }

  onDrop = (acceptedFiles: any[], fileRejections: FileRejection[]): void => {
    if (fileRejections.length > 0) {
      if (fileRejections.length > this.state.remainingFileSlot) {
        this.setState({
          modalState: { isOpen: true, title: this.modalText.maxFileCountError.title, description: this.modalText.maxFileCountError.description },
        });
      } else {
        this.setState({
          modalState: { isOpen: true, title: this.modalText.fileTypeError.title, description: this.modalText.fileTypeError.description },
        });
      }
    }
    const files: FileDropped[] = acceptedFiles.map((n) => ({ file: n, toUpload: true } as any));
    let rerenderAllFiles: IncidentFileSchemaAlt[] = [].concat(files as any, (this.state.files || []) as any).filter((n) => n!!) as any;
    rerenderAllFiles = rerenderAllFiles.splice(0, this.maxFileUpload);

    // check if any file is larger then 5mg
    if (this.fileUploadInvalid(rerenderAllFiles)) return;
    if (acceptedFiles.length > 0) {
      this.setState((prevState: State) => {
        return { remainingFileSlot: prevState.remainingFileSlot - acceptedFiles.length };
      });
    }
    rerenderAllFiles.forEach((n: IncidentFileSchemaAlt) => {
      if (n.file) {
        Object.assign(n.file, {
          preview: URL.createObjectURL(n.file as any),
          ...(!n.file?.refer ? { refer: v4() } : {}),
        });
      } else {
        Logger(['[AddImageSection][rerenderAllFiles] : file ERROR!!! >>>', n], 'error');
      }
    });
    this.onUploadImages(rerenderAllFiles);
    this.setState({ files: rerenderAllFiles });
  };

  onRemoveImage = (f: IncidentFileSchemaAlt, inx: number) => {
    this.setState((prevState: State) => {
      const newFiles = clone(prevState.files);
      const removedFile = newFiles.splice(inx, 1);
      if (removedFile[0]?.cached) Logger(['[AddImageSection][onRemoveImage] : cached file url REMOVED!!! >>>', removedFile[0]?.url], 'warn');
      if (removedFile[0]?.errorUpload) Logger(['[AddImageSection][onRemoveImage] : not cached file name REMOVED!!! >>>', removedFile[0]?.file?.name], 'warn');
      this.handleChangeStoreUpdate(newFiles?.length ? clone(newFiles) : null, 'images');
      return { ...prevState, isRemoving: true, files: newFiles, remainingFileSlot: prevState.remainingFileSlot + 1 };
    });
    if (f.uid) this.props.incidentStore.store.setIncidentFileRemove(f.uid, { cache: true });
  };

  onUploadImages = async (files: IncidentFileSchemaAlt[]) => {
    /** get images to upload only first and offset to false */
    const imagesToUpload = files
      .filter((n) => n.toUpload)
      .map((n) => {
        n.toUpload = false;
        return n;
      });
    this.setState({
      filesUploading: imagesToUpload.map((n) => {
        return { ref: n.file?.refer, fileName: n.file?.name, uploadStatus: 'uploading' };
      }),
    });
    for (const item of imagesToUpload) {
      const formData: FormData = new FormData();
      formData.append('file', item.file as any);
      this.props.incidentStore.store.setIncidentFileUpload({ file: formData }, item.file?.refer as any);
      await delay(300);
    }
  };

  get renderImages() {
    return this.state.files.map((item, index) => (
      <Box className="flex overflow-hidden relative" key={index}>
        <IconButton
          onClick={() => this.onRemoveImage(item, index)}
          color="error"
          component="label"
          className="absolute top-1 right-1 p-1 bg-red-500 hover:bg-red-700"
        >
          <Icon icon="xmark" color="#fff" size={16} viewBox={'0 0 320 512'} />
        </IconButton>
        {this.state.filesUploading.find((file) => file.ref === item.file?.refer)?.uploadStatus === 'uploading' && (
          <CircularProgress className="absolute" size={60} sx={{ top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }} />
        )}
        {item.errorUpload && <WarningIcon className="absolute text-red-500 text-[48px]" sx={{ top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }} />}
        <img src={item.file?.serverUrl || item.file?.preview} alt={item.url} loading="lazy" style={{ display: 'block', maxWidth: '100%', width: '100%' }} />
      </Box>
    ));
  }

  render() {
    return (
      <Card variant="outlined" className=" rounded-2xl p-6">
        <Box className="flex flex-wrap">
          <img src={require('@/assets/icons/image.png')} alt="Occupational" width={27} height={21} className="mr-3" />
          <Typography variant="h5">Add Images</Typography>
          <Typography variant="caption" className="ml-auto text-gray-400">
            Maximum 5 images. Maximum File Size 5 MB per image.
          </Typography>
        </Box>
        <Box className="mt-4">
          <Box className="border border-dashed border-gray-300">
            {this.state.files.length < 5 ? (
              <Dropzone
                maxFiles={this.state.remainingFileSlot} // this prevents from selecting multiples of more then 5, and cannot concat to smaller number to load
                accept={{ 'image/*': [] }}
                onDrop={this.onDrop}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps({ className: 'flex align-middle justify-center py-12 cursor-pointer dropzone hover:bg-sky-50' })}>
                    <input {...getInputProps()} />
                    <Typography className="text-gray-500 hover:text-blue-600">Drop image here or click to upload</Typography>
                  </Box>
                )}
              </Dropzone>
            ) : (
              <Box className="flex align-middle justify-center py-12 cursor-no-drop bg-gray-50 hover:bg-gray-100">
                <Typography className="text-gray-500">Maximum images uploaded</Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ width: '100%', paddingTop: '8px' }}>
            <Masonry columns={{ imageOneColumn: 1, imageTwoColumn: 2, imageThreeColumn: 3 }} spacing={1}>
              {this.renderImages}
            </Masonry>
          </Box>
        </Box>

        <Dialog
          open={this.state.modalState.isOpen}
          // onClose={() => {
          //   setClosed(true);
          // }}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <Typography variant="h2" className="p-0 mb-6">
            {this.state.modalState.title}
          </Typography>
          <DialogContent className="p-0 mb-6">
            <Typography id="transition-modal-description">{this.state.modalState.description}</Typography>
          </DialogContent>
          <DialogActions className="p-0 justify-start">
            <Button
              className="w-28"
              variant="contained"
              color="error"
              onClick={() => {
                this.setState({ modalState: { isOpen: false, title: '', description: '' } });
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}
