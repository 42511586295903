import { BusinessUnitsSchema } from '@/src/types';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { Fragment } from 'react';

const configCompanyColor = { ARV: 'bg-violet-100', CAR: 'bg-cyan-100', ROV: 'bg-amber-100', SKL: 'bg-sky-100', VRN: 'bg-emerald-100' };

interface Props {
  businessUnitsData: BusinessUnitsSchema[];
  buColumn: string[];
}

export function ManHourEnhancedTableHead(_props: Props) {
  const { businessUnitsData, buColumn } = _props;

  return (
    <TableHead>
      <TableRow>
        <TableCell className="font-bold border-0 p-2 pl-10 pr-0" rowSpan={2} sx={{ maxWidth: '70px', minWidth: '70px' }}>
          Month
        </TableCell>
        <TableCell className="font-bold border-0 p-2" rowSpan={2} align="center" sx={{ maxWidth: '100px', minWidth: '100px' }}>
          Working day
        </TableCell>
        <TableCell className="font-bold border-0 p-2" rowSpan={2} align="center" sx={{ maxWidth: '100px', minWidth: '100px' }}>
          Hrs.
        </TableCell>
        {buColumn.map((item: string, index: number) => (
          <TableCell className={`font-bold border-0 py-0 px-5 ${configCompanyColor[item]} borderManHour`} colSpan={2} align="center" key={index}>
            <span className="block py-1 border-0 border-b border-solid border-white">{businessUnitsData.find((buItem) => buItem.buCode === item)?.buName}</span>
          </TableCell>
        ))}
        <TableCell className="p-0 w-full" rowSpan={2}></TableCell>
        <TableCell className="font-bold border-0 p-2 pr-10" rowSpan={2} align="right" sx={{ maxWidth: '90px', minWidth: '90px' }}>
          Total
        </TableCell>
      </TableRow>
      <TableRow>
        {buColumn.map((item: string, index: number) => (
          <Fragment key={index}>
            <TableCell
              className={`font-bold border-0 py-2 px-1 ${configCompanyColor[item]} `}
              align="center"
              sx={{ borderRadius: '0 !important', maxWidth: '100px', minWidth: '100px' }}
            >
              Employees
            </TableCell>
            <TableCell
              className={`font-bold border-0 py-2 px-1 ${configCompanyColor[item]} borderManHour pr-5`}
              align="right"
              sx={{ borderRadius: '0 !important', maxWidth: '74px', minWidth: '74px' }}
            >
              Man-hour
            </TableCell>
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}
