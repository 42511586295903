import { AzureAuthProviderProps, IncidentStore, PropDefaults } from '@/src/types';
import { Icon } from '@/src/components';
import { Box, Typography } from '@mui/material';

interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export default function PartSevenReport(props: IncidentProps & IncidentStore) {
  // const { history, detailId, store } = props;
  const { store } = props;
  const {
    // incidentBusinessUnitsFilter,
    // // incidentCreateUpdateData,
    // incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    // setIncidentDetail,
    incidentDetailData,
  } = store;
  const incidentInvestigationTeamData = incidentDetailData?.data?.data?.incidentInvestigationTeam as any;

  return (
    <>
      <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
        <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
        Incident investigation team
      </Typography>
      <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:mt-5 bg-white">
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Investigators
          </Typography>
          <Typography className="lg:pl-6 w-full">
            {incidentInvestigationTeamData?.investigators?.length
              ? (incidentInvestigationTeamData?.investigators || []).map((investigators: any, index: number) => {
                  const investigatorsLabel =
                    `${investigators.firstName} ${investigators.lastName} - ${investigators.position} (${investigators.buName})` || '-';
                  return index === 0 ? investigatorsLabel : ', ' + investigatorsLabel;
                })
              : '-'}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
