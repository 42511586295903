import { useState, useEffect, ChangeEvent } from 'react';
import { ActionInProgress, AttendeeSchema, AttendeeStore, TrainingStatus } from '@/src/types';
import { TableCell, TableRow, TextField } from '@mui/material';
import { Icon } from '@/components/ui/icons';

import { UploadCertificate } from './upload-certificate';
import { debounce, isNumber } from 'lodash';
import { gthPostTestScore, gthPreTestScore, isEmptyAndUnset, isSuperAdmin } from '@/src/utils';
import { useGlobalContext } from '@/store/index';

interface AttendeeSegmentProps extends AttendeeStore {
  [name: string]: any;
  index: number;
  trainingStatus: TrainingStatus;
  data: AttendeeSchema;
  trainingId: string;
  actionInProgress?: ActionInProgress;
  preTestScore: number;
  postTestScore: number;
  onChangeInput?: () => void;
}

const scoresSet = (d: AttendeeSchema) => {
  return d.preTestScore !== undefined && d.postTestScore !== undefined;
};

interface AttendeeScoreValues {
  preTestScore?: number | undefined;
  postTestScore?: number | undefined;
}

export const AttendeeSegment = (props: AttendeeSegmentProps) => {
  const { currentUser } = useGlobalContext();
  const { data: attendeeData, index, trainingStatus, store, preTestScore: trainingPreTestScore, postTestScore: trainingPostTestScore, onChangeInput } = props;
  const [attendeeScoreValues, setAttendeeScoreValues] = useState<AttendeeScoreValues>({} as any);
  const [data, setData] = useState<AttendeeSchema>(attendeeData || {});
  const [mount, setMount] = useState<number>(0);

  const setScores = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: 'preTestScore' | 'postTestScore',
    action: 'onBlur' | 'onChange',
    onDone: (data: AttendeeScoreValues) => void
  ): boolean => {
    const val = !isNumber(Number(e.target.value)) ? undefined : isEmptyAndUnset(e.target.value) ? null : Number(e.target.value);

    if (trainingPreTestScore && gthPreTestScore(trainingPreTestScore, val) && type === 'preTestScore') {
      e.preventDefault();
      e.target.value = trainingPreTestScore as any;
      return false;
    }
    if (trainingPostTestScore && gthPostTestScore(trainingPostTestScore, val) && type === 'postTestScore') {
      e.preventDefault();
      e.target.value = trainingPostTestScore as any;
      return false;
    }

    if (typeof val === 'number' && val < 0) {
      e.preventDefault();
      e.target.value = 0 as any;
      return false;
    } else {
      if (!mount) return false;
      const d: AttendeeScoreValues = {
        ...attendeeScoreValues,
        ...(type === 'postTestScore' ? { postTestScore: val } : { preTestScore: val }),
      } as any;
      if (isEmptyAndUnset(data.postTestScore) && isEmptyAndUnset(d.postTestScore) && isEmptyAndUnset(data.preTestScore) && isEmptyAndUnset(d.preTestScore))
        return false;
      if (data.postTestScore === d.postTestScore && data.preTestScore === d.preTestScore) return false;
      setAttendeeScoreValues(d);
      onDone(d);
    }
    return true;
  };

  const setScoresV2 = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type: 'preTestScore' | 'postTestScore'): boolean => {
    const val = !isNumber(Number(e.target.value)) ? undefined : Number(e.target.value);
    if (trainingPreTestScore && gthPreTestScore(trainingPreTestScore, val) && type === 'preTestScore') {
      e.preventDefault();
      e.target.value = trainingPreTestScore as any;
      return false;
    }
    if (trainingPostTestScore && gthPostTestScore(trainingPostTestScore, val) && type === 'postTestScore') {
      e.preventDefault();
      e.target.value = trainingPostTestScore as any;
      return false;
    }
    return true;
  };

  const setScoresDebounced2 = debounce(
    (val: any) => {
      setScores(val.e as any, val.type as any, val.action as any, (d) => {
        if (val.action === 'onBlur') {
          store.setAttendeeUpdate(data.id, d);
          setTimeout(() => (onChangeInput ? onChangeInput() : null), 500);
        }
      });
    },
    mount > 1 ? 200 : 0
    // { maxWait: 1000 }
  );

  useEffect(() => {
    if (attendeeData) {
      setData(attendeeData);
    }
  }, [data, setData, attendeeData]);

  useEffect(() => {
    if (!mount) {
      setMount(mount + 1);
    } else if ((data.preTestScore || data.postTestScore) && !Object.keys(attendeeScoreValues).length) {
      setAttendeeScoreValues({
        ...(data.preTestScore ? { preTestScore: data.preTestScore } : {}),
        ...(data.postTestScore ? { postTestScore: data.postTestScore } : {}),
      });
    }
  }, [setAttendeeScoreValues, attendeeScoreValues, setMount, mount]);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>{data.employeeId}</TableCell>
      <TableCell>
        {data.firstName} {data.lastName}
      </TableCell>
      <TableCell>{data.position || '-'}</TableCell>
      <TableCell>{data.buName || '-'}</TableCell>
      <TableCell>
        <span
          className={`py-1 px-2 rounded whitespace-nowrap w-fit ${
            data.status === 1 ? 'inReview' : data.status === 2 ? 'overdue' : data.status === 3 ? 'cancelled' : ''
          }`}
        >
          {data.status === 1 ? 'Pending' : data.status === 2 ? 'Accepted' : data.status === 3 ? 'Declined' : ''}
        </span>
      </TableCell>
      <TableCell align="center">
        <TextField
          onBlur={(e) => setScoresDebounced2({ e, type: 'preTestScore', action: 'onBlur' })}
          onChange={(e) => setScoresV2(e, 'preTestScore')}
          type="number"
          id="outlined-size-small"
          inputProps={{ style: { textAlign: 'center' }, inputMode: 'numeric' }}
          sx={{ maxWidth: '92px' }}
          defaultValue={data.preTestScore || undefined}
          size="small"
          disabled={trainingStatus === 3 || !isSuperAdmin(currentUser?.userProfile)}
        />
      </TableCell>
      <TableCell align="center">
        <TextField
          onBlur={(e) => setScoresDebounced2({ e, type: 'postTestScore', action: 'onBlur' })}
          onChange={(e) => setScoresV2(e, 'postTestScore')}
          type="number"
          id="outlined-size-small"
          inputProps={{ style: { textAlign: 'center' }, inputMode: 'numeric' }}
          sx={{ maxWidth: '92px' }}
          defaultValue={data.postTestScore || undefined}
          size="small"
          disabled={trainingStatus === 3 || !isSuperAdmin(currentUser?.userProfile)}
        />
      </TableCell>

      <TableCell align="center">
        {scoresSet(data) && data.testStatus === 1 ? (
          <Icon icon="circleCheck" color="#3CDB91" size={24} />
        ) : scoresSet(data) && data.testStatus === 2 ? (
          <Icon icon="circleXmark" color="#D65A5A" size={24} />
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>
        <UploadCertificate store={store} index={index} data={data} />
      </TableCell>
    </TableRow>
  );
};
