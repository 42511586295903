import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { progresses } from '@/src/static/incidents/mockupData';
import { Icon } from '@/src/components';
import { PropDefaults, AzureAuthProviderProps, IncidentStore, IncidentProgressSchema } from '@/src/types';
import { Logger } from '@/logger/index';
import { printDate } from '@/utils/index';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: 170,
  },
}));

interface ProgressBarIncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
}

export const ProgressBarIncident: React.FC<any> = (props: ProgressBarIncidentProps & IncidentStore) => {
  const { store: incidentStore } = props;
  const incidentProgress = incidentStore?.incidentProgressData?.data?.data || [];
  const [openReadNoteDialog, setOpenReadNoteDialog] = React.useState(false);
  const [progressData, setProgressData] = useState<IncidentProgressSchema>();
  // const [complete, setComplete] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const handleClickOpenReadNoteDialog = (progress) => {
    setProgressData(progress);
    setOpenReadNoteDialog(true);
  };
  const handleCloseReadNoteDialog = () => {
    setOpenReadNoteDialog(false);
  };

  const calculatorProgress = (incidentProgress: IncidentProgressSchema[]) => {
    let completedCount = 0;
    let total = 0;
    if (incidentProgress.length) {
      let lastApproved = 0;
      incidentProgress.forEach((o, index) => {
        if (o.approved === true) {
          completedCount++;
          lastApproved = index;
        }
        total++;
      });

      if (completedCount === 0) {
        setCurrentStep(0);
      } else {
        setCurrentStep(lastApproved + 1);
      }

      const percentage = (completedCount / total) * 100;
      setPercentage(percentage);
    } else {
      setCurrentStep(0);
      setPercentage(0);
    }
  };

  useEffect(() => {
    if (incidentProgress.length) {
      calculatorProgress(incidentProgress);
    }
  }, []);

  Logger(['[ProgressBarIncident][incidentProgress]', incidentProgress]);
  // Logger(['[ProgressBarIncident][props]', props]);

  return (
    <Box
      className="w-full p-6 rounded-3xl my-5 bg-gradient-to-t from-blue-100 to-white max-w-[326px]"
      sx={{
        boxShadow: '0px 3px 10px #AAAAAA29',
      }}
    >
      <Box className="flex items-center">
        <Typography variant="h2" color="primary">
          Progress
        </Typography>
        <LinearProgress className="w-full ml-4 rounded-full h-2" variant="determinate" value={percentage} />
      </Box>
      <Box className="mt-6">
        <Stepper activeStep={currentStep} orientation="vertical" className="progressStep">
          {incidentProgress.map((progress, index) => (
            <Step key={index} completed={progress.approved}>
              {progress?.approved === true ? (
                <LightTooltip
                  arrow
                  followCursor
                  title={
                    <>
                      <Typography className="text-xs text-black">{`By ${
                        (progress?.employee?.firstName ? progress?.employee?.firstName : '') +
                        ' ' +
                        (progress?.employee?.middleName ? progress?.employee?.middleName : '') +
                        ' ' +
                        (progress?.employee?.lastName ? progress?.employee?.lastName : '')
                      }`}</Typography>
                      <Typography className="text-xs text-black mt-1">
                        {progress?.approvalDate ? printDate(progress?.approvalDate, 'DD MMM YYYY, HH:mm') : '-'}
                      </Typography>
                    </>
                  }
                >
                  <StepLabel color="inherit">{progresses[index].label}</StepLabel>
                </LightTooltip>
              ) : (
                <StepLabel color="inherit">{progresses[index].label}</StepLabel>
              )}
              {progress.note && (
                <Box className="border-0 border-l border-solid ml-[10px] border-[#bdbdbd]">
                  <Button
                    variant="outlined"
                    className="ml-5 mt-3 py-0 h-9"
                    startIcon={<Icon icon="fileLines" color="#3155FF" size={16} viewBox="0 0 384 512" />}
                    onClick={() => handleClickOpenReadNoteDialog(progress)}
                  >
                    Read note
                  </Button>
                </Box>
              )}
            </Step>
          ))}
        </Stepper>
      </Box>
      <Dialog
        open={openReadNoteDialog}
        onClose={handleCloseReadNoteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title" className=" text-2xl p-0">
          Note from{' '}
          {(progressData?.employee?.firstName ? progressData?.employee?.firstName : '') +
            ' ' +
            (progressData?.employee?.middleName ? progressData?.employee?.middleName : '') +
            ' ' +
            (progressData?.employee?.lastName ? progressData?.employee?.lastName : '')}
        </DialogTitle>
        <DialogContent className="p-0">
          <DialogContentText id="alert-dialog-description" className=" text-black mt-3">
            {progressData?.note}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-0 mt-5 justify-start">
          <Button variant="outlined" className="w-28" onClick={handleCloseReadNoteDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
