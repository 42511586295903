// @ts-ignore
// @ts-nocheck
/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
import { createTheme, Theme as _Theme } from '@mui/material/styles';
import typography from './typography';
import palette from './palette';

interface Theme extends _Theme {
  status: any;
}
// Create a theme instance.
export const theme: Theme = createTheme({
  status: {
    appState: 'loading',
  },
  typography,
  palette,
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 560,
      md: 768,
      lg: 992,
      xl: 3000,
      imageOneColumn: 0,
      imageTwoColumn: 900,
      imageThreeColumn: 1650,
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: '20px 40px',
          boxShadow: 'none',
          borderRadius: '0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: '400',
          fontSize: '14px',
          borderRadius: '8px !important',
          height: '40px',
          borderColor: '#E0E0E0',
          '&.Mui-disabled': {
            background: '#E0E0E0',
          },
          textarea: {
            padding: '0',
          },
        },
        multiline: {
          height: 'auto',
          '&.Mui-disabled': {
            background: '#E0E0E0',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          paddingTop: '10px',
          paddingBottom: '10px',
          top: '0 !important',
        },
        input: {
          padding: '9px 14px',
          '&.Mui-disabled': {
            background: '#E0E0E0',
            borderRadius: '8px',
            border: 'none',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '2px 14px 2px 12px !important',
          '&.Mui-disabled': {
            background: '#E0E0E0',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'relative !important',
          color: '#000000',
          transform: 'none !important',
          fontWeight: '500',
          fontSize: '14px',
          transition: 'none !important',
          marginBottom: '6px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000000',
          fontWeight: '500',
          fontSize: '14px',
        },
        asterisk: {
          color: '#ff1744',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          lineHeight: 1,
          borderRadius: '8px',
          textTransform: 'none',
          fontWeight: '500',
        },
        sizeMedium: {
          minWidth: '100px',
          height: '40px',
        },
        sizeSmall: {
          height: '36px',
          minWidth: '50px',
        },
        outlined: {
          minWidth: 'unset',
          height: 'auto',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          border: '0',
          background: '#F5F6F8',
          overflow: 'hidden',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          border: '0',
          borderBottom: '1px dashed #E0E0E0',
          padding: '8px 12px',
          borderStyle: 'dashed',
          height: '40px',
        },
        head: {
          padding: '16px 12px',
          '&:first-of-type': {
            borderRadius: '8px 0 0 8px',
          },
          '&:last-of-type': {
            borderRadius: '0 8px 8px 0',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          border: '0',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          color: '#212121',
          backgroundColor: '#E0E0E0',
          maxWidth: '425px',
        },
        arrow: {
          color: '#E0E0E0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation4: {
          borderRadius: '20px',
          boxShadow: '0px 3px 10px #5A5A5A29',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '20px',
          padding: '30px',
          overflow: 'unset',
        },
        container: {
          alignItems: 'start',
        },
        paperWidthLg: {
          maxWidth: '1200px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          path: {
            d: 'path("M7.268,13.889l.626-.626a.379.379,0,0,0,0-.537L2.181,7,7.894,1.274a.379.379,0,0,0,0-.537L7.268.111a.379.379,0,0,0-.537,0L.111,6.732a.379.379,0,0,0,0,.537l6.621,6.621A.379.379,0,0,0,7.268,13.889Z")',
          },
          transform: 'rotate(-90deg)',
          fill: '#3155ff',
          top: '0',
          right: '4px',
        },
        iconOpen: {
          transform: 'rotate(90deg)',
          top: '14px',
          right: '14px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #E0E0E0',
          padding: '10px 50px !important',
        },
        actions: {
          '.MuiSvgIcon-root': {
            fontSize: '2rem !important',
          },
        },
        selectIcon: {
          right: '-10px',
          fontSize: '1.2rem',
          fill: '#000000',
          top: '3px',
          '&.MuiSelect-iconOpen': {
            top: '17px',
            right: '-3px',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: '#000000 !important',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: '#4CAF50',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fill: '#3155FF',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.no-label': {
            label: {
              height: '20px',
            },
          },
        },
      },
    },
  },
}) as any;

export default theme;
