/** declare only http service related requests */

import { HttpTokens, UploadAttendeeFile, AttendeeUploadBodyData } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
// NOTE  for file upload please refer to this example:
// https://stackoverflow.com/questions/57398438/axios-wont-let-me-set-a-content-type-when-uploading-a-file
// around :  let reader = new FileReader();
/**
 * @description upload attendee file by id
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const AttendeeUpload = (
  prefix: string = 'training/attendee/upload',
  id: string,
  body: AttendeeUploadBodyData,
  tokens: HttpTokens,
  hardRejection = true
): Promise<UploadAttendeeFile> => {
  /** provide higher timeout for upload */
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .post(`${prefix}/${id}`, body.file, config)
    .then((d: AxiosResponse<UploadAttendeeFile>) => {
      // return Promise.reject(new Error('ups'))
      // NOTE  mock file status type non existent on api
      const data = d.data.data;
      if (data.certificateLink) {
        data.certificateFileState = 'ready';
      } else {
        data.certificateFileState = 'initial';
      }
      d.data.data = data;

      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<UploadAttendeeFile>;
};
