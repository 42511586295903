import { PropDefaults, AzureAuthProviderProps, IncidentStore } from '@/src/types';
import React from 'react';
import { withIncidentContext } from '@/store/index';
import { SnackBarAlertWithStore } from '@/src/components';
import IncidentsRouter from './incidents.router';

interface IncidentsProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

const IncidentPage: React.FC<IncidentsProps & IncidentStore> = (props) => {
  const { store: incidentStore } = props;

  const _props: any = {
    ...props,
  };

  return (
    <>
      <IncidentsRouter {..._props} />

      {
        <SnackBarAlertWithStore
          autoHideDurationAndClearStore={4000}
          storeName={['IncidentStore']}
          store={{ store: incidentStore }}
          // onDeleted={(del: boolean) => {
          //   if (del) {
          //     if (routeName === 'trainings/detail') {
          //       setTimeout(() => {
          //         navigate('/trainings');
          //       }, 2000);
          //     }
          //   }
          // }}
        />
      }
    </>
  );
};

export default withIncidentContext(IncidentPage);
