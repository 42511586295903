import React from 'react';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';

// import { Icon, SearchBoxAutocomplete } from '@/src/components';
// import { withAdminContext } from '@/store/admins.store';
// import { AdminStore } from '@/types/store';
import { filter, switchMap, from, of, catchError, pairwise } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EmployeeCreate, EmployeeFilterGet } from '@/src/services/http';
import { getJwtToken } from '@/src/utils';
import { EmployeeSchema, AddUserActionSignal } from '@/src/types';
import { Logger } from '@/logger/index';
import { useGlobalContext } from '@/store/index';
export function withRxAddModal(Ctr) {
  return function Hoc(props, context = {}) {
    const { currentUser } = useGlobalContext();
    const initialActionValue: AddUserActionSignal = { type: 'CLOSE', error: undefined };
    const [actionChanges$, setActionSignal] = createSignal<AddUserActionSignal>();

    const [useAction] = bind(
      actionChanges$
        .pipe(debounceTime(400))
        .pipe(
          filter((v) => {
            if (!v) return false;
            if (v.type === 'SEARCH') {
              return (v.value?.q || '').length > 0;
            } else {
              return true;
            }
          })
        )
        .pipe(
          // pairwise(),
          // NOTE find member exists and display in dropdown
          switchMap((val) => {
            // when we have search value
            if (val.type === 'SEARCH') {
              /** only get results for non admins we can to upgrade */
              // current
              const roleIdResults = 3;
              const userProfile = currentUser?.userProfile;
              // if user is not Super Admin only show results from same company
              const opts = {
                q: val.value?.q,
                roleId: roleIdResults,
                ...(userProfile.roleId !== 1 ? { businessUnits: userProfile?.buCode } : {}),
              };
              Logger(['[admin][create][filter][select]', opts.q], 'log');
              return from(
                EmployeeFilterGet('employees/filter', opts as any, {
                  jwt: getJwtToken(),
                }).then((x) => {
                  Logger(['[admin][create][filter][result]', x.data], 'log');
                  return { type: 'SEARCH', results: x, value: val.value };
                })
              ).pipe(catchError((error) => of({ type: 'SOFT_ERROR', error, value: val.value })));
            } else {
              if (val.type === 'SELECT') {
                Logger(['[admin][create][select]', val], 'log');
              }
              return of(val);
            }
          })
        )
        .pipe(filter((n) => !!n))
        .pipe(
          // NOTE  add new member to database
          pairwise(),
          switchMap(([old, val]) => {
            const n: AddUserActionSignal = val as any;
            if (n.type === 'ADD') {
              const results: EmployeeSchema = n?.results?.data[0] as any;
              Logger(['[admin][create][select]', results], 'log');
              return from(
                EmployeeCreate('employee/create', results.employeeId, { jwt: getJwtToken() }, { roleId: val.value.roleId }).then((x) => {
                  Logger(['[admin][create][response]', x.data], 'log');
                  // call to action data added
                  return { type: 'ADD', results: x };
                })
              ).pipe(
                catchError((error) => {
                  return of({ type: 'ERROR', error });
                })
              );
            } else return of(n);
          })
        ),
      initialActionValue
    );

    const newProps = {
      useAction: useAction,
      setActionSignal,
      ...props,
      // ... some new props
    };
    return <Ctr {...newProps} {...context} />;
  };
}
