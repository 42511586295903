import { AzureAuthProviderProps, CourseStore } from '@/types/store';
import { useState, useEffect } from 'react';
import {
  CourseByIdGet,
  CourseByNoGet,
  CourseCreatePost,
  CourseDelete,
  CourseListGet,
  CourseUpdatePut,
  CourseTrainingTypesGet,
  CourseFilterGet,
  CourseSelectGet,
} from '@/services/http';
import { CourseContext } from './context';
import { CourseBodyData, CourseBodyDataUpdate, GetCoursesList, HttpTokens, QueryCourseList, CoursesFilterQuery } from '@/src/types';
import { getJwtToken, delay, updateCourseList, checkIsLastPage } from '@/src/utils';
// import { deAT } from 'date-fns/locale';

/**
 * to give us access to azure for admin
 */
interface ProviderProps {
  children: React.ReactNode;
  context: {
    [name: string]: any;
    azure: AzureAuthProviderProps;
  };
}

const CourseContextProvider = (props: ProviderProps) => {
  const tokens: HttpTokens = { jwt: getJwtToken() };
  const [courseList, setCourseList] = useState<any>({ data: undefined, state: 'initial' });
  const [courseCreatedData, setCourseCreate] = useState<any>({ data: undefined, state: 'initial' });
  const [courseDeletedData, setCourseDelete] = useState<any>({ data: undefined, state: 'initial' });
  const [courseUpdatedData, setCourseUpdate] = useState<any>({ data: undefined, state: 'initial' });
  const [courseByIdData, setCourseById] = useState<any>({ data: undefined, state: 'initial' });
  const [courseByNoData, setCourseByNo] = useState<any>({ data: undefined, state: 'initial' });
  const [courseTrainingTypeFilter, setCourseTrainingTypesFilter] = useState<any>({ data: undefined, state: 'initial' });
  const [courseFilterByList, setCourseByFilter] = useState<any>({ data: undefined, state: 'initial' });
  const [courseSelectData, setCourseSelect] = useState<any>({ data: undefined, state: 'initial' });

  // const { children, context } = props;
  const { children } = props;
  /** Access to azure  */
  // const { azure } = context;

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const store: CourseStore = {
    store: {
      courseFilterByList,
      courseList,
      courseCreatedData,
      courseDeletedData,
      courseUpdatedData,
      courseByNoData,
      courseByIdData,
      courseSelectData,
      courseTrainingTypeFilter,

      resetStatus: (apiName: string) => {
        // TODO TO COMPLETE ALL OTHER apis later
        // or as we see fit
        if (apiName === 'course/update') {
          setCourseUpdate({ data: undefined, state: 'initial' });
        }
        if (apiName === 'course/create') {
          setCourseCreate({ data: undefined, state: 'initial' });
        }

        if (apiName === 'course/no') {
          setCourseByNo({ data: undefined, state: 'initial' });
        }

        if (apiName === 'course/id') {
          setCourseById({ data: undefined, state: 'initial' });
        }

        if (apiName === 'courses/list') {
          setCourseList({ data: undefined, state: 'initial' });
        }
      },

      courseByNo: (trainingCode: string) => {
        if (!isMounted) return;
        setCourseByNo({ data: undefined, state: 'loading' });
        CourseByNoGet('course', trainingCode, tokens)
          .then((n) => {
            if (isMounted) setCourseByNo({ data: n, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) setCourseByNo({ data: undefined, error: err, state: 'error' });
          });
      },
      courseById: (id: string) => {
        if (!isMounted) return;
        setCourseById({ data: undefined, state: 'loading' });
        CourseByIdGet('course', id, tokens)
          .then((n) => {
            if (isMounted) setCourseById({ data: n, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) setCourseById({ data: undefined, error: err, state: 'error' });
          });
      },

      setCourseList: (params: QueryCourseList) => {
        if (!isMounted) return;
        if (courseDeletedData.state === 'initial') setCourseList({ data: undefined, state: 'loading' });
        CourseListGet('courses', params, tokens)
          .then((resData) => {
            if (isMounted) {
              setCourseList({ data: resData, state: 'ready' });
              // have to wait for new data after deleted realtime, before update deleting status back to initial
              setCourseDelete({ data: undefined, state: 'initial' });
            }
          })
          .catch((err) => {
            if (isMounted) setCourseList({ data: undefined, error: err, state: 'error' });
          });
      },
      setCourseByFilter: (params: CoursesFilterQuery) => {
        if (!isMounted) return;
        setCourseByFilter({ data: undefined, state: 'loading' });
        CourseFilterGet('courses/filter', params, tokens)
          .then((resData) => {
            if (isMounted) setCourseByFilter({ data: resData, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) setCourseByFilter({ data: undefined, error: err, state: 'error' });
          });
      },
      courseCreate: (formData: CourseBodyData) => {
        if (!isMounted) return;
        setCourseCreate({ data: undefined, state: 'loading' });
        CourseCreatePost('course/create', tokens, formData)
          .then((n) => {
            if (isMounted) {
              setCourseCreate({ data: n, state: 'ready' });
              const list: GetCoursesList = courseList?.data;
              if (list?.data) {
                // check if last row is on correct page
                const isLastPage = checkIsLastPage(list.pagination.pageNumber, list.pagination.totalItems, list.pagination.itemPerPage);

                // this data is CourseSchema extended model of CourseSmallSchema, should work fine before page is refreshed
                list.data.unshift(n.data as any);
                if (!isLastPage) {
                  list.data.splice(list.data.length - 1, 1);
                }
                list.pagination.totalItems = list.pagination.totalItems + 1;
                setCourseList({ data: list, state: 'ready' });

                // offset create status back to initial
                delay(100).then(() => {
                  setCourseCreate({ data: undefined, state: 'initial' });
                });
              }
            }
          })
          .catch((err) => {
            if (isMounted) setCourseCreate({ data: undefined, error: err, state: 'error' });
          });
      },
      courseDelete: (id: string) => {
        if (!isMounted) return;
        setCourseDelete({ data: undefined, state: 'loading' });
        CourseDelete('courses/delete', id, tokens)
          .then((n) => {
            if (isMounted) {
              setCourseDelete({ data: n, state: 'ready' });
              const list: GetCoursesList = courseList?.data;
              if (list) {
                // i delete this pagination out to prevent errors when MUI pagination is delay setting new page after deleted last row on last page, it's still updated when soft delete completed and call API get new list again.
                list.data = courseList.data.data.filter((i) => i.id !== n.data.id);
                setCourseList({ data: list, state: 'ready' });
              }
            }
          })
          .catch((err) => {
            if (isMounted) setCourseDelete({ data: undefined, error: err, state: 'error' });
          });
      },
      courseUpdate: (id: string, formData: CourseBodyDataUpdate, updateFor: 'list' | 'item') => {
        if (!isMounted) return;
        setCourseUpdate({ data: undefined, state: 'loading' });
        CourseUpdatePut('course/update', id, formData, tokens)
          .then((n) => {
            if (isMounted) {
              setCourseUpdate({ data: n, state: 'ready' });
              // NOTE we do this in case updated item on list page, is already loaded, and now data has changed
              if (updateFor !== 'item') setCourseByNo({ data: n, state: 'ready' });

              if (updateFor === 'list') {
                const list: GetCoursesList = courseList?.data;

                if (list?.data) {
                  const { data, updated } = updateCourseList(list.data, n.data);
                  if (updated) {
                    list.data = data;
                    setCourseList({ data: list, state: 'ready' });
                  }

                  // offset create status back to initial
                  delay(300).then(() => {
                    setCourseUpdate({ data: undefined, state: 'initial' });
                  });
                }
              }

              if (updateFor === 'item') {
                if (isMounted) {
                  setCourseByNo({ data: n, state: 'ready' });

                  if (courseList.data) {
                    const courseListData: { data: GetCoursesList } = courseList;
                    const { data, updated } = updateCourseList(courseListData.data.data, n.data);

                    if (updated) {
                      courseListData.data.data = data;
                      setCourseList({
                        data: courseListData.data,
                        state: 'ready',
                      });
                    }
                  }

                  delay(300).then(() => {
                    setCourseUpdate({ data: undefined, state: 'initial' });
                  });
                }
              }
            }
          })
          .catch((err) => {
            if (isMounted) {
              setCourseUpdate({ data: undefined, error: err, state: 'error' });
            }
          });
      },
      setCourseTrainingTypes: () => {
        if (isMounted) setCourseTrainingTypesFilter({ data: undefined, state: 'loading' });
        CourseTrainingTypesGet('course/training-types', tokens)
          .then((resData) => {
            if (isMounted) setCourseTrainingTypesFilter({ data: resData, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) setCourseTrainingTypesFilter({ data: undefined, error: err, state: 'error' });
          });
      },

      courseSelect: () => {
        if (!isMounted) return;
        setCourseSelect({ data: undefined, state: 'loading' });
        CourseSelectGet('courses/select', tokens)
          .then((data) => {
            if (isMounted) setCourseSelect({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setCourseSelect({ data: undefined, error, state: 'error' });
          });
      },
    },
  };

  return <CourseContext.Provider value={store}>{children}</CourseContext.Provider>;
};

const withCourseContext = (Component: any) => {
  return function CoursesComponent(props: any) {
    return <CourseContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</CourseContext.Consumer>;
  };
};

export { CourseContextProvider, withCourseContext };
