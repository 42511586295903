/** declare only http service related requests */

import { PutCancelTraining, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description cancel training
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const TrainingCancelPut = (
  /** create can also refer to update for draft */
  prefix: string = 'training/cancel',
  tokens: HttpTokens,
  /** id of training to cancel */
  uid: string,
  hardRejection = true
): Promise<PutCancelTraining> => {
  const instance = new AxiosService({ method: 'put', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .put(`${prefix}/${uid}`, '', config)
    .then((d: AxiosResponse<PutCancelTraining>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PutCancelTraining>;
};
