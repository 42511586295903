/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-useless-escape */

import React, { Component } from 'react';

import { Alert, Box, Button, CircularProgress, DialogActions, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Logger } from '@/logger/index';
import { stateLeveledValue, validData, isError, TEXT_REGEX, empty, validDateFormat } from '@/src/utils';
import { CourseRequestDto } from '@/dto/index';
import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import { cloneDeep, identity, pickBy } from 'lodash';

import { FIELD_INVALID, SERVER_API_ERROR } from '@/static/index';
import { CourseSchema } from '@/src/types';

/**
 * regex validation
 * https://www.cluemediator.com/regular-expression-examples-in-javascript
 */

interface StateProps extends CourseRequestDto {
  [name: string]: any;
  status?: FormStatus;
  serverError: string | undefined;
  error?: boolean;
  onSubmit?: (data) => void;
  loading?: boolean;
  // onClose: (data: any) => void;
}

type FormStatus = 'open' | 'close' | 'error' | 'submit';
interface CourseFormProps {
  error?: boolean;
  /** Used for update courses only */
  editUid?: string;
  editData?: CourseSchema;
  // end
  mode: 'create' | 'edit';
  status: FormStatus;
  onClose: () => void;
  /** {uid} is optional and only required in mode=edit  */
  onSubmit: (data, uid?: string) => void;
  allRequired?: boolean;
  allRequiredMessage?: string;
  /** if server return error after call */
  serverErrorResponse?: string;
  loading?: boolean;
}

interface FormErrors {
  [name: string]: any;
}

export default class CourseForm extends Component<CourseFormProps, {}, any> {
  state: StateProps;
  /** how many times was set */
  formIndex = 0;
  lastTimeout: any = undefined;
  editDataLoaded = false;
  /** initial data for the form **/
  courseFormData: CourseRequestDto = undefined as any;

  /** form errors has the same structure as CourseRequestDto, but it provides errors on each property */
  formErrors: FormErrors = {} as any;
  constructor(props) {
    super(props);

    // if (this.props.mode === 'edit' && (!this.props.editData || !this.props.editUid)) {
    //   throw new Error('[FormErrors] in edit mode you should provide [editData] and [editUid');
    // }

    // REVIEW or to use with componentDidUpdate() ?
    if (Object.keys(this.props?.editData || {}).length) {
      this.courseFormData = this.props.editData as any;
    } else {
      const useMockData = false;
      this.courseFormData = new CourseRequestDto(null as any, false, useMockData);
    }

    // Logger(['[CourseForm][mode]', this.props.mode], 'log');

    if (this.props.mode === 'edit') {
      // Logger(['[CourseForm][editUid]', this.props.editUid], 'log');
    }

    this.state = {
      loading: !!this.props.loading,
      serverError: undefined,
      status: this.props.status || 'open',
      error: !!this.props.error,
      // any other values we want to add to the state
      preTest: {} as any,
      postTest: {} as any,
      learning: {} as any,
      ...(this.courseFormData as any),
    };
  }

  componentDidUpdate(prevProps) {
    // NOTE we can use this tup update state with new props from outer component
    // if (prevProps.text !== this.props.text) {
    //   this.updateAndNotify();
    // }
    if (!this.editDataLoaded) {
      if (Object.keys(this.props?.editData || {}).length) {
        this.courseFormData = this.props.editData as any;

        this.editDataLoaded = true;
        this.setState({
          // ...this.state,
          ...this.courseFormData,
        });
      }
    }
  }

  /**
   *
   * @param fieldName
   * @param data
   * @param cb if we have available regex check condition before calling validate and insert to this.formErrors
   */
  errorMessage(fieldName: string, data: FormErrors, cb?: (arr: string[]) => Error | undefined): string {
    const leveledField = (fieldName || '').split('.');
    let callbackError;
    try {
      if (typeof cb === 'function') {
        // eslint-disable-next-line standard/no-callback-literal
        callbackError = cb([leveledField[0], leveledField[1]]);
      }

      const err: Error = isError(callbackError) ? callbackError : (data[leveledField.length === 2 ? leveledField[1] : (leveledField[0] as any)] as any);
      if (err) {
        this.checkErrors(fieldName, null, err);
      }

      if (isError(err)) return err.message as any;
      else return false as any;
    } catch (err) {
      return false as any;
    }
  }

  /**
   * @param fieldName exact field name, top + lower level, example:  a.b, or a
   * @param required required or not we still validate for correct input, required is optional but set to true bu default4
   * @param type type of input
   * @param message
   */
  validate(fieldName, message = '', regEx?: RegExp, required?: boolean, type?: string): { required: boolean; error: boolean; helperText: string } {
    const defaultMessage = 'Incorrect entry.';
    /** required is optional b */
    const _required = required === undefined ? true : required;
    const testState = (l1, l2) => {
      try {
        return this.state[l1][l2];
      } catch (err) {
        return undefined;
      }
    };
    const error = this.errorMessage(
      fieldName,
      this.formErrors,
      regEx
        ? ([l1, l2]) => {
            try {
              if (testState(l1, l2) && l1 && l2) {
                // NOTE  l1 formErrors[l1] or second level ormErrors[l1][l2]
                const value = this.state[l1][l2];

                if (value === undefined) return undefined as any;
                const rx = new RegExp(regEx);
                if (!rx.test(value)) {
                  throw new Error(message || defaultMessage);
                }
                return undefined as any;
              }

              if (l1 && !l2) {
                // NOTE  l1 formErrors[l1] or second level ormErrors[l1][l2]
                const value = this.state[l1];

                // override conditions
                if (l1 === 'effectiveDate' && !!value) {
                  if (!validDateFormat(value)) throw new Error(message || defaultMessage);
                  else return undefined;
                }

                if (value === undefined) return undefined as any;
                const rx = new RegExp(regEx);

                if (!rx.test(value)) {
                  throw new Error(message || defaultMessage);
                }
              }

              return undefined as any;
            } catch (err) {
              // NOTE return error to errorMessage as priority if regEx was specified and failed validation
              return err as any;
            }
          }
        : undefined
    );

    const helperText = (error || '').toString() || message || defaultMessage;

    const o = {
      required: _required,
      error: !!error,
      helperText,
      // variant: 'outlined',
    } as any;

    // dont provide message if no error found

    if (!!o.error === false) {
      delete o.helperText;
      delete o.error;
      delete o.variant;
    }
    // no helperText on this
    if (type === 'FormControl') {
      delete o.helperText;
      delete o.variant;
    }

    if (!o.required) {
      delete o.error;
      delete o.helperText;
      delete o.variant;
    }

    return {
      ...o,
    };
  }

  /**
   * Populate formErrors with any errors found, or remove if none
   * TODO build regex, message, requirements per validate() setting ad add {condition}
   * @param field  setting of field >.< indicate next level object
   * @param value
   */
  checkErrors(field: string, value: any, error?: Error): boolean {
    const leveledField = field.split('.');
    let invalid = false;
    if (!this.formErrors) this.formErrors = {};

    const message = this.props?.allRequiredMessage || `is empty field`;

    // validate for above 1 level
    if (leveledField.length === 2) {
      // NOTE add {condition}
      let val = value;
      if (
        ['preTest.link', 'preTest.fullScore', 'preTest.passScore', 'postTest.link', 'postTest.fullScore', 'postTest.passScore'].indexOf(field) < 0 &&
        ((!val && this.props?.allRequired) || isError(error))
      ) {
        val = isError(error) ? error : new Error(`${message}`);
        invalid = true;
      } else {
        try {
          delete this.formErrors[leveledField[0]][leveledField[1]];
          if (this.formErrors[leveledField[0]] && !Object.keys(this.formErrors[leveledField[0]]).length) {
            delete this.formErrors[leveledField[0]];
          }
        } catch (err) {}
      }

      this.formErrors = {
        ...this.formErrors,
        [leveledField[0]]: {
          ...stateLeveledValue(this.formErrors || {}, leveledField),
          ...(['preTest.link', 'preTest.fullScore', 'preTest.passScore', 'postTest.link', 'postTest.fullScore', 'postTest.passScore'].indexOf(field) < 0 &&
          ((!val && this.props?.allRequired) || isError(error))
            ? { [leveledField[1]]: val }
            : {}),
        },
      };

      // validate for top level
    } else {
      // NOTE add {condition}
      let val = value;
      if ((!val && this.props?.allRequired) || isError(error)) {
        val = isError(error) ? error : new Error(`${message}`);
        invalid = true;
      } else {
        try {
          delete this.formErrors[leveledField[0]];
        } catch (err) {}
      }
      this.formErrors = {
        ...this.formErrors,
        ...(isError(val) || empty(val) ? { [leveledField[0]]: val } : {}),
      };
    }

    Object.entries(this.formErrors).forEach(([k, val]) => {
      if (!isError(this.formErrors[k])) {
        if (!this.formErrors[k] || !Object.keys(this.formErrors[k]).length) delete this.formErrors[k];
      }
    });

    return invalid;
  }

  /**
   *
   * @param field  setting of field >.< indicate next level object
   * @param value
   */
  handleChange = (field: string, value: any) => {
    const leveledField = field.split('.');
    this.checkErrors(field, value);
    if (leveledField.length === 2) {
      this.setState({
        [leveledField[0]]: {
          ...stateLeveledValue(this.state, leveledField),
          [leveledField[1]]: value,
        },
      });
    } else {
      this.setState({ [leveledField[0]]: value });
    }
    this.formIndex++;
  };

  /**
   * Check formErrors has any errors assigned
   */
  get hasErrors() {
    return Object.keys(this.formErrors || {}).length > 0;
  }

  /**
   * check if form is untouched
   */
  get pristine() {
    return this.formIndex < 1;
  }

  /**
   * if form is invalid the submit will not execute, so will call on click event to test status manually
   */
  submitTest = (e) => {
    e.preventDefault();
    if (this.hasErrors) {
    }
    // Logger(['[CourseForm][submitTest]', this.formErrors, this.hasErrors], 'log');
  };

  /**
   * Send data to Base component
   */
  submit = (e) => {
    e.preventDefault();
    if (this.hasErrors) {
      Logger(['[CourseForm]', 'form is not valid', this.formErrors], 'error');
      return false;
    }

    const stateCopy = cloneDeep(this.state);

    let formData = new CourseRequestDto(stateCopy as any, true);
    // remove nullable values
    formData = pickBy(formData, identity) as any;
    if (this.props.mode === 'create') this.props.onSubmit(formData);
    if (this.props.mode === 'edit') this.props.onSubmit(formData, this.props.editUid);
    // delay(500).then(() => {
    this.setState({ status: 'submit' });
    // });
    // Logger(['[CourseForm][Course/create]', 'submitted', formData], 'attention');
    return false;
  };

  resetStates() {
    this.setState({});
  }

  render() {
    if (this.state.error || this.props.error) {
      return <Alert severity="error">{SERVER_API_ERROR}</Alert>;
    }

    if (this.props.loading) {
      return <CircularProgress className="mt-5" />;
    }

    if (this.hasErrors) {
      Logger(['[CourseForm]/formErrors', this.formErrors], 'log');
    }

    if (this.props.serverErrorResponse && this.state.status !== 'error') {
      this.setState({ status: 'error' });
    }

    if (this.props.status === 'close') {
      this.resetStates();
    }

    return (
      <form
        onSubmit={this.submit}
        onInvalid={(e) => {
          //  Logger(['[CourseForm][onInvalid]', e], 'log');
        }}
      >
        <Box className="w-full flex">
          <Box className="w-1/2 border-0 border-r border-dashed border-gray-300 pr-8 pt-7">
            <FormControl {...this.validate('trainingCode', 'No special characters', /^[a-zA-Z- ]+\s*$/, true, 'FormControl')} className="mb-2 w-full">
              <FormLabel id="training-type-label">Training type</FormLabel>
              <RadioGroup
                /* NOTE set default */
                value={this.state?.trainingCode ? this.state?.trainingCode : 'classroom'}
                onChange={(el) => {
                  if (el.target?.defaultValue) this.handleChange('trainingCode', el.target?.defaultValue);
                }}
                row
                aria-labelledby="label"
                name="training-type-radio-buttons-group"
              >
                <FormControlLabel value="classroom" control={<Radio />} label="Classroom" />
                <FormControlLabel value="online" control={<Radio />} label="Online" />
                <FormControlLabel value="e-learning" control={<Radio />} label="E-Learning" />
              </RadioGroup>
            </FormControl>
            <FormControl className="mb-6 w-full">
              <TextField
                {...this.validate('courseNo', FIELD_INVALID, /^[\u0E00-\u0E7Fa-zA-Z0-9-_|]+$/)}
                onChange={(e) => this.handleChange('courseNo', e.target.value)}
                value={this.state?.courseNo ?? undefined}
                InputLabelProps={{ shrink: true }}
                label="Course no."
                placeholder="Please enter course no."
              />
            </FormControl>
            <FormControl className="mb-6 w-full">
              <TextField
                {...this.validate('courseName', FIELD_INVALID, /./g)}
                onChange={(e) => this.handleChange('courseName', e.target.value)}
                value={this.state?.courseName ?? undefined}
                InputLabelProps={{ shrink: true }}
                label="Course name"
                placeholder="Please enter course name"
              />
            </FormControl>
            {this.state?.trainingCode === 'e-learning' ? (
              <Box className="flex">
                <FormControl className="mb-6 w-full mr-3">
                  <TextField
                    {...this.validate('learning.link', FIELD_INVALID, /^(ftp|http|https):\/\/[^ "]+$/, false)}
                    onChange={(e) => this.handleChange('learning.link', e.target.value)}
                    value={this.state?.learning?.link ?? undefined}
                    InputLabelProps={{ shrink: true }}
                    label="E-learning link"
                    placeholder="Please enter a link"
                  />
                </FormControl>

                <FormControl className="mb-6 w-full" sx={{ maxWidth: '158px' }}>
                  <TextField
                    {...this.validate('learning.period', FIELD_INVALID, /^[0-9]*$/, false)}
                    onChange={(e) => this.handleChange('learning.period', e.target.value)}
                    value={this.state?.learning?.period ?? undefined}
                    InputLabelProps={{ shrink: true }}
                    label="Period (Days)"
                  />
                </FormControl>
              </Box>
            ) : null}

            <Box className="flex">
              <FormControl className="mb-6 w-full mr-3">
                <TextField
                  {...this.validate('preTest.link', FIELD_INVALID, /^(ftp|http|https):\/\/[^ "]+$/)}
                  onChange={(e) => this.handleChange('preTest.link', e.target.value)}
                  value={this.state?.preTest?.link ?? undefined}
                  InputLabelProps={{ shrink: true }}
                  label="Pre-test link"
                  placeholder="Please enter a link"
                  required={false}
                />
              </FormControl>

              <FormControl className="mb-6 mr-3 w-full" sx={{ maxWidth: '80px' }}>
                <TextField
                  {...this.validate('preTest.fullScore', FIELD_INVALID, /^[0-9]*$/)}
                  value={this.state?.preTest?.fullScore ?? undefined}
                  onChange={(e) => this.handleChange('preTest.fullScore', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  label="Full score"
                  required={false}
                />
              </FormControl>
              <FormControl className="mb-6 w-full" sx={{ maxWidth: '80px' }}>
                <TextField
                  {...this.validate('preTest.passScore', FIELD_INVALID, /^[0-9]*$/)}
                  value={this.state?.preTest?.passScore ?? undefined}
                  onChange={(e) => this.handleChange('preTest.passScore', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  label="Pass score"
                  required={false}
                />
              </FormControl>
            </Box>

            <Box className="flex">
              <FormControl className="mb-6 w-full mr-3">
                <TextField
                  {...this.validate('postTest.link', FIELD_INVALID, /^(ftp|http|https):\/\/[^ "]+$/)}
                  value={this.state?.postTest?.link ?? undefined}
                  onChange={(e) => this.handleChange('postTest.link', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  label="Post-test link"
                  placeholder="Please enter a link"
                  required={false}
                />
              </FormControl>
              <FormControl className="mb-6 mr-3 w-full" sx={{ maxWidth: '80px' }}>
                <TextField
                  {...this.validate('postTest.fullScore', FIELD_INVALID, /^[0-9]*$/)}
                  value={this.state?.postTest?.fullScore ?? undefined}
                  onChange={(e) => this.handleChange('postTest.fullScore', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  label="Full score"
                  required={false}
                />
              </FormControl>
              <FormControl className="mb-6 w-full" sx={{ maxWidth: '80px' }}>
                <TextField
                  {...this.validate('postTest.passScore', FIELD_INVALID, /^[0-9]*$/)}
                  value={this.state?.postTest?.passScore ?? undefined}
                  onChange={(e) => this.handleChange('postTest.passScore', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  label="Pass score"
                  required={false}
                />
              </FormControl>
            </Box>

            <FormControl {...this.validate('instruction', FIELD_INVALID, /^[0-9]+\s*$/, true, 'FormControl')} className="mb-3 w-full">
              <FormLabel id="instructor">Instructor/Institution</FormLabel>
              <RadioGroup
                /* NOTE set default */
                value={this.state?.instruction >= 1 ? this.state?.instruction : 1}
                onChange={(el) => {
                  if (el.target?.defaultValue) this.handleChange('instruction', el.target?.defaultValue);
                }}
                row
                aria-labelledby="label"
                name="instructor-radio-buttons-group"
              >
                <FormControlLabel value={1} control={<Radio />} label="Internal" />
                <FormControlLabel value={2} control={<Radio />} label="External" />
              </RadioGroup>
            </FormControl>
            <FormControl className="mb-6 w-full">
              <TextField
                {...this.validate('targetAudience', FIELD_INVALID, /^[\u0E00-\u0E7Fa-zA-Z0-9 ]+\s*$/)}
                value={this.state?.targetAudience ?? undefined}
                onChange={(e) => this.handleChange('targetAudience', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Target audience"
                placeholder="Please enter target audience"
              />
            </FormControl>
            <FormControl className="mb-6 w-full">
              <TextField
                {...this.validate('totalPersons', FIELD_INVALID, /^[0-9\s,]*$/)}
                value={this.state?.totalPersons ?? undefined}
                onChange={(e) => this.handleChange('totalPersons', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Total (persons)"
                placeholder="Please enter total number of attendees"
              />
            </FormControl>
            <FormControl className="mb-6 w-full">
              <TextField
                {...this.validate('courseDuration', FIELD_INVALID, /^\s*[0-9,]+\s*$/)}
                value={this.state?.courseDuration ?? undefined}
                onChange={(e) => this.handleChange('courseDuration', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Course duration (hours)"
                placeholder="Please enter course duration"
              />
            </FormControl>
          </Box>
          <Box className="w-1/2 pl-8 pt-7">
            <FormControl className="mb-7 w-full">
              <TextField
                {...this.validate('overview', FIELD_INVALID, TEXT_REGEX)}
                value={this.state?.overview ?? undefined}
                onChange={(e) => this.handleChange('overview', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Overview"
                multiline
                minRows={3}
                placeholder="Please enter the content"
              />
            </FormControl>
            <FormControl className="mb-7 w-full">
              <TextField
                {...this.validate('learningOutcomes', FIELD_INVALID, TEXT_REGEX)}
                value={this.state?.learningOutcomes ?? undefined}
                onChange={(e) => this.handleChange('learningOutcomes', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Learning outcomes"
                multiline
                minRows={3}
                placeholder="Please enter the content"
              />
            </FormControl>
            <FormControl className="mb-7 w-full">
              <TextField
                {...this.validate('trainingObjectives', FIELD_INVALID, TEXT_REGEX)}
                value={this.state?.trainingObjectives ?? undefined}
                onChange={(e) => this.handleChange('trainingObjectives', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Training objectives"
                multiline
                minRows={3}
                placeholder="Please enter the content"
              />
            </FormControl>
            <FormControl className="mb-7 w-full">
              <TextField
                {...this.validate('topics', FIELD_INVALID, TEXT_REGEX)}
                value={this.state?.topics ?? undefined}
                onChange={(e) => this.handleChange('topics', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Topics"
                multiline
                minRows={3}
                placeholder="Please enter the content"
              />
            </FormControl>
            <FormControl className="mb-6 w-full">
              <TextField
                {...this.validate('costPerPerson', FIELD_INVALID, /^\s*[a-zA-Z0-9,]+\s*$/)}
                value={this.state?.costPerPerson ?? undefined}
                onChange={(e) => this.handleChange('costPerPerson', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Cost per person"
                placeholder="Please enter cost per person"
              />
            </FormControl>
            <Box className="flex">
              <FormControl className="mb-6 w-1/2 mr-3">
                <TextField
                  {...this.validate('version', FIELD_INVALID, /^[0-9.,]+$/)}
                  value={this.state?.version ?? undefined}
                  onChange={(e) => this.handleChange('version', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  label="Version"
                  placeholder="Please enter version number"
                />
              </FormControl>
              <FormControl className="mb-6 w-1/2">
                {/* <TextField InputLabelProps={{ shrink: true }} label="Effective date" /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    onError={(reason: DateValidationError, value: Date | null) => {
                      //  Logger(['[CourseForm][DatePicker]', reason], 'error');
                    }}
                    disableMaskedInput={true}
                    label="Effective Date"
                    views={['year', 'month', 'day']}
                    inputFormat="dd MMM yyyy"
                    // mask={'__ ____ ____'}
                    value={this.state?.effectiveDate ? validData(this.state?.effectiveDate) : null}
                    onChange={(newValue) => {
                      this.handleChange('effectiveDate', newValue);
                    }}
                    renderInput={(params) => <TextField {...this.validate('effectiveDate', FIELD_INVALID, /^\s*\d*\.?\d+\s*$/)} {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <DialogActions className="px-8 pt-7 pb-0 justify-start border-0 border-t border-solid border-gray-300" sx={{ margin: '0 -30px' }}>
          <Button disabled={this.pristine || this.hasErrors || this.state.status === 'submit'} type="submit" className="mr-1 w-28" variant="contained">
            {this.props.mode === 'create' ? <>Add</> : this.props.mode === 'edit' ? <>Save</> : null}
          </Button>
          <Button
            type="button"
            className="w-28"
            variant="outlined"
            onClick={() => {
              this.setState({ status: 'close' });
              this.props.onClose();
            }}
          >
            Cancel
          </Button>
          {this.props.serverErrorResponse && this.state.status !== 'submit' && <Alert severity="error">{this.props.serverErrorResponse}</Alert>}
        </DialogActions>
      </form>
    );
  }
}
