export const complianceOptions = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      // Use axis to trigger tooltip
      type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
    },
  },
  title: {
    show: false,
    textStyle: {
      color: 'grey',
      fontSize: 17,
    },
    text: 'No data',
    left: 'center',
    top: 'center',
  },
  legend: {
    itemHeight: 6,
    itemWidth: 21,
    itemGap: 22,
    textStyle: {
      color: 'rgba(0, 0, 0, 1)',
    },
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '0',
    top: '20%',
    containLabel: true,
  },
  textStyle: {
    fontSize: 13,
  },
  color: ['#00C083', '#00B8FF'],
  xAxis: {
    type: 'value',
    axisLabel: {
      color: '#bbb',
      fontSize: 13,
    },
  },
  yAxis: {
    type: 'category',
    // data: ['Varuna', 'Skyller', 'Rovula', 'Cariva', 'ARV'],
    data: [],
    axisLabel: {
      color: '#000',
      fontSize: 13,
    },
  },
  series: [
    {
      id: 'compliance',
      name: 'Compliance (C)',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#fff',
      },
      emphasis: {
        focus: 'series',
      } as any,
      // data: [90, 34, 91, 34, 82],
      data: [],
    },
    {
      id: 'non-compliance',
      name: 'Non-Compliance (NC)',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#fff',
      },
      emphasis: {
        focus: 'series',
      } as any,
      // data: [20, 32, 11, 34, 90],
      data: [],
    },
  ],
};
export const overallStatusOptions = {
  tooltip: {
    trigger: 'item',
  },
  title: {
    show: false,
    textStyle: {
      color: 'grey',
      fontSize: 17,
    },
    text: 'No data',
    left: '25%',
    top: 'center',
  },
  legend: {
    itemHeight: 8,
    bottom: '0',
    orient: 'horizontal',
    itemGap: 20,
    textStyle: {
      color: 'rgba(0, 0, 0, 1)',
    },
  },
  textStyle: {
    fontSize: 13,
  },
  // color: ['#EACD67', '#A3CF7C', '#92BDDB', '#ffc0cb'], //ATTENTION disabled evaluate status logic for now
  color: ['#A3CF7C', '#EACD67', '#92BDDB'],
  series: [
    {
      type: 'pie',
      radius: ['50%', '75%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 5,
        borderColor: '#fff',
        borderWidth: 1,
      },
      label: {
        show: false,
        position: 'center',
      },
      left: '20%',
      right: '20%',
      top: 0,
      bottom: 5,
      labelLine: {
        show: false,
      },
      colors: ['#A3CF7C', '#EACD67', '#92BDDB'],
      data: [
        // old static values
        // { value: 30, name: 'Completed (30)' },
        // { value: 40, name: 'In progress (40)' },
        // { value: 10, name: 'Overdue (10)' },
      ] as { value: number; name: string }[],
      orgData: [{ test: 1 }],
    },
  ],
};
