import React from 'react';
import { Container } from '@/components/index';
import { Box, Paper, Typography } from '@mui/material';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { RESPONSE_PAGE_TITLE } from '@/src/static';

export const ResponseMessage: React.FC = () => {
  setDocTitle(RESPONSE_PAGE_TITLE);
  return (
    <Container>
      <Box className="generalMessage flex flex-col">
        <img className="mx-auto mt-14" src="/icons/SSHE-logo.svg" alt="pankod" height="58" />
        <Paper elevation={4} className="w-full text-center block mx-auto mt-14 p-12" sx={{ maxWidth: '700px' }}>
          <img className="mx-auto" src="/icons/thank-you-note.svg" alt="pankod" height="94" width="84" />
          <Typography className="mt-8" variant="h2">
            Thank you for your response.
          </Typography>
          {/* <Button className="mt-8 w-28" variant="outlined">
            Close
          </Button> */}
        </Paper>
      </Box>
    </Container>
  );
};
