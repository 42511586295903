import { Card, Box, Typography } from '@mui/material';
import React, { Component } from 'react';
import { EmployeeSchema, HttpTokens, GetEmployeeBySearch, IncidentFormStoreParts, IncidentInvestigationTeamPartial } from '@/src/types';
import { Logger } from '@/utils/logger/index';
import { filter, switchMap, from, Subject, Observable, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EmployeeSearchGet } from '@/services/http';
import { getJwtToken } from '@/src/utils';
import { clone } from 'lodash';
import { TagInputSearchEmployees } from '@/src/components';

interface IncidentInvestigationTeamPartSevenProps extends IncidentFormStoreParts {
  [name: string]: any;
}

export default class IncidentInvestigationTeamPartSeven extends Component<IncidentInvestigationTeamPartSevenProps, {}, any> {
  subs$: Array<any> = [];
  subSearch$: Subject<{ q: string }> = new Subject();
  state: any;
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      employeeList: [],
      submitType: undefined,
      isLoading: false,
    };
  }

  get initSubSearch$(): Observable<GetEmployeeBySearch> {
    const tokens: HttpTokens = { jwt: getJwtToken() };
    return (
      this.subSearch$
        .pipe(tap(() => this.setState({ isLoading: true, error: undefined })))
        .pipe(debounceTime(500))
        // must not be empty and gth 1
        .pipe(filter((n) => !!n?.q && n?.q.length > 0))
        .pipe(switchMap((val) => from(EmployeeSearchGet('employees/search', { q: val.q }, tokens))))
    );
  }

  componentDidUpdate(prevProps) {
    // Logger(['[TrainingForm][formStore][incidentInvestigationTeam]', this.props.formStore.store.formData.incidentInvestigationTeam]);
  }

  componentDidMount() {
    const s1 = this.initSubSearch$.subscribe({
      error: (err) => {
        Logger(['[IncidentInvestigationTeamPartSeven][initSubSearch$][subscribe][error]', err], 'error');
        this.setState({ isLoading: false, error: err });
      },
      next: (n) => {
        Logger(['[IncidentInvestigationTeamPartSeven][initSubSearch$][subscribe][next]', n]);
        this.setState({ employeeList: clone(n.data), isLoading: false, error: undefined });
      },
      complete: () => {},
    });

    this.subs$.push(...[s1]);
  }

  componentWillUnmount() {
    this.subs$.forEach((sub, inx) => {
      try {
        sub.unsubscribe(sub);
        Logger(['[TrainingForm][unsubscribe][index]', inx], 'debug');
      } catch (err) {}
    });
  }

  handleChangeStoreUpdate(value: any, keyName: string, subKeyName?: string) {
    if (!this.props.formStore) return;
    this.props.formStore.setUpdateFormItem('incidentInvestigationTeam', 'updated').setup(value, keyName, subKeyName);
  }

  get incidentInvestigationTeam(): IncidentInvestigationTeamPartial {
    try {
      return this.props.formStore.store.formData.incidentInvestigationTeam.data;
    } catch (err) {
      return null as any;
    }
  }

  render() {
    return (
      <>
        <Card variant="outlined" className=" rounded-2xl p-6">
          <Box className="flex">
            <img src={require('@/assets/icons/Search.png')} alt="Occupational" width={28} height={27} className="mr-3" />
            <Typography variant="h5">Select investigators</Typography>
          </Box>
          <Box className="mt-4">
            <TagInputSearchEmployees
              optionData={this.state.employeeList || ([] as EmployeeSchema[])}
              selectedData={this.props.formStore.store.formData.incidentInvestigationTeam?.data?.investigators || ([] as EmployeeSchema[])}
              isLoading={this.state.isLoading}
              isErrorSearch={!!this.state.error}
              handleKeyFilter={(searchText: string) => this.subSearch$.next({ q: searchText })}
              handleOnSelect={(selected: EmployeeSchema[]) => this.handleChangeStoreUpdate(selected.length ? selected : null, 'investigators')}
            />
          </Box>
        </Card>
      </>
    );
  }
}
