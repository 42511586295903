import { HttpTokens, RemoveLegalFile } from '@/src/types';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';

/**
 * @description delete image by id
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const LegalFileRemove = (prefix: string = 'legal/cache/file/remove', id: string, tokens: HttpTokens, hardRejection = true): Promise<RemoveLegalFile> => {
  /** provide higher timeout for upload */
  const instance = new AxiosService({ method: 'delete', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // const queryUri = paramsQueryString(`${prefix}/${id}`, params);
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .delete(`${prefix}/${id}`, config)
    .then((d: AxiosResponse<RemoveLegalFile>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<RemoveLegalFile>;
};
