import React, { useState, useEffect, useReducer } from 'react';
import { PropDefaults } from '@/types/common';
import { Paper, Typography, Box, MenuItem, OutlinedInput, Select, LinearProgress, Button, Table } from '@mui/material';
import { EnhancedTableManHours, Icon, FilterMultiSelect, ManHourChartSections } from '@/components/index';
import { AzureAuthProviderProps, ManhoursStore, IncidentStore } from '@/src/types/store';
import { BusinessUnitsSchema, CallBackFilterType, QueryManHourList } from '@/src/types';
// import { mockupRows } from '@/static/manHours/mockupData';
import { NavLink, useOutletContext } from 'react-router-dom';
import { withManhoursContext } from '@/store/index';
import { getYearArray, excelAllBorders, excelRowOne, excelRowEnd } from '@/src/utils';
import { Logger } from '@/logger/index';
import { chartReducer } from '@/src/utils/reducers';
import { TableDataError } from '@/src/components/ui/dataError';
// import { Logger } from '@/logger/index';
// import { ManHourDto } from '@/src/components/dto/man-hour.dto';
// import { ENVS_CONFIG } from '@/constants/index';

import ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';

const PAGE_TITLE = 'Incidents';
interface ManHoursProps extends PropDefaults {
  azure: AzureAuthProviderProps;

  // title?: string;
}

const ManHours: React.FC<any> = (props: ManHoursProps & ManhoursStore, context) => {
  const { store: manHoursStore } = props;
  // NOTE use this for setting new chart state
  // example:  dispatchChartState({ type: 'buCodes', value: ['1212','1234'],state:'updated' });
  const [chartState, dispatchChartState] = useReducer(chartReducer, {
    value: { year: new Date().getFullYear().toString(), buCodes: null },
    type: 'buCodes/year',
    state: 'initial',
  });

  const contextProps: ManHoursProps & IncidentStore = useOutletContext();
  const {
    store: incidentStore,
    // azure
  } = contextProps;

  // Logger(['[ManHours][manHoursStore][child]', manHoursStore]);
  // Logger(['[ManHours][incidentStore][parent]', incidentStore]);

  const now = new Date();
  const yearData = getYearArray();
  const [yearFilter, setYearFilter] = React.useState<number>(now.getFullYear());
  const [prevSelectedYearFilter, setPrevSelectedYearFilter] = React.useState<number>(yearFilter);
  const [isOpenYearFilter, setOpenYearFilter] = React.useState<boolean>(false);
  const [mount, setMount] = React.useState(0);
  const [businessUnitsFilter, setBusinessUnitsFilter] = React.useState<string[]>([]);
  const [allBuCode, setAllBuCode] = React.useState<string[]>([]);
  const [buColumn, setBuColumn] = React.useState<string[]>([]);

  useEffect(() => {
    if (!mount) {
      if (manHoursStore.manhoursListData.state === 'initial' && !manHoursStore.manhoursListData.data) {
        manHoursStore.setManhoursList({
          businessUnits: businessUnitsFilter,
          year: yearFilter,
        });
      }
      if (manHoursStore.manhoursBusinessUnitsFilter.state === 'initial' && !manHoursStore.manhoursBusinessUnitsFilter.data)
        manHoursStore.setManhoursBusinessUnitsFilter();

      setMount(1);
    }
  }, [mount, setMount, manHoursStore]);

  useEffect(() => {
    if (manHoursStore.manhoursBusinessUnitsFilter.state === 'ready') {
      const mapAllBuCode = manHoursStore?.manhoursBusinessUnitsFilter?.data?.data?.map((item: BusinessUnitsSchema) => item.buCode);
      setAllBuCode(mapAllBuCode);
      setBuColumn(mapAllBuCode);
    }
  }, [manHoursStore.manhoursBusinessUnitsFilter.state]);

  const handleOpenCloseYear = (newState: boolean) => {
    setOpenYearFilter((prevState) => {
      if (prevState === false && newState === true) setPrevSelectedYearFilter(yearFilter);
      else if (prevState === true && newState === false) {
        if (yearFilter !== prevSelectedYearFilter) {
          const params: QueryManHourList = {
            year: yearFilter,
          };
          if (businessUnitsFilter.length) params.businessUnits = businessUnitsFilter;
          manHoursStore.setManhoursList(params);

          // NOTE: to reset chart after selected new businessUnitsFilter
          manHoursStore.resetStatus('incidents/man-hours/charts');
          dispatchChartState({ type: 'buCodes/year', value: { year: yearFilter, buCodes: businessUnitsFilter, loading: true }, state: 'updated' });
        }
      }
      return newState;
    });
  };
  const handleYearChange = (event: any) => {
    if (yearFilter !== event.target.value) {
      setYearFilter(event.target.value);
      setOpenYearFilter(false);

      const params: QueryManHourList = {
        year: event.target.value,
      };
      if (businessUnitsFilter.length) params.businessUnits = businessUnitsFilter;
      manHoursStore.setManhoursList(params);

      manHoursStore.resetStatus('incidents/man-hours/charts');
      dispatchChartState({ type: 'buCodes/year', value: { year: event.target.value, buCodes: businessUnitsFilter, loading: true }, state: 'updated' });
    }
  };

  const onCallBackBusinessUnitsFilter = (type: CallBackFilterType, payload?: any) => {
    const params: any = {
      year: yearFilter,
    };
    let _businessUnitsFilter: string[] = [];
    if (type === 'setSelectedFilter' && payload !== undefined) _businessUnitsFilter = payload;
    else _businessUnitsFilter = businessUnitsFilter;
    if (type !== 'finishSelectingFilter') {
      setBusinessUnitsFilter(_businessUnitsFilter);
      if (_businessUnitsFilter.length) params.businessUnits = _businessUnitsFilter;
      manHoursStore.setManhoursList(params);
      setBuColumn(_businessUnitsFilter.length === 0 ? allBuCode : _businessUnitsFilter);

      manHoursStore.resetStatus('incidents/man-hours/charts');
      dispatchChartState({ type: 'buCodes/year', value: { year: yearFilter, buCodes: _businessUnitsFilter, loading: true }, state: 'updated' });
    }
  };

  const manHourDataReady =
    manHoursStore?.manhoursListData.state === 'ready' &&
    manHoursStore?.manhoursListData?.data !== undefined &&
    manHoursStore?.manhoursBusinessUnitsFilter?.data?.data !== undefined;

  // START: Export Data
  const [exportReady, setExportReady] = useState(true);
  const onExportExcel = async (e: any) => {
    e.preventDefault();
    const _data = manHoursStore.manhoursListData?.data;
    if (exportReady && _data) {
      setExportReady(false);

      const data = _data.data;
      const yearSelected = _data.yearSelected;
      const calculations = _data.calculations;
      const totals = calculations.totals;
      const allBu: { total: string | number; buCode: string; buName: string }[] = [];
      manHoursStore?.manhoursBusinessUnitsFilter.data.data.forEach((d) => {
        if (!buColumn.length || buColumn.indexOf(d.buCode) > -1) {
          const _t = totals.find((k) => k.buCode === d.buCode);
          allBu.push({ ...d, total: _t && _t.total ? _t.total : 0 });
        }
      });

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet();
      worksheet.views.push({ showGridLines: false });
      worksheet.properties.defaultRowHeight = 25;

      const columns = [
        { key: 'col0', width: 12, header: `Man-hour ${yearSelected}` },
        { key: 'col1', width: 12, header: '' },
        { key: 'col2', width: 12, header: '' },
      ];
      allBu.forEach((d) => {
        columns.push({ key: `${d.buCode}0`, width: 12, header: '' });
        columns.push({ key: `${d.buCode}1`, width: 12, header: '' });
      });
      columns.push({ key: 'total', width: 12, header: '' });
      worksheet.columns = columns;
      worksheet.mergeCells(1, 1, 1, worksheet.columns.length);
      worksheet.getRow(1).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
      worksheet.getRow(1).font = { bold: true };

      const header1 = { col0: 'Month', col1: 'Working day', col2: 'Hrs.', total: 'Total' };
      const header2 = {};
      allBu.forEach((d) => {
        header1[`${d.buCode}0`] = d.buName;
        header2[`${d.buCode}0`] = 'Employees';
        header2[`${d.buCode}1`] = 'Man-hour';
      });
      worksheet.addRow(header1);
      worksheet.addRow(header2);
      excelAllBorders(worksheet, 2, worksheet.columns.length);
      excelAllBorders(worksheet, 3, worksheet.columns.length);
      worksheet.mergeCells(2, 1, 3, 1);
      worksheet.mergeCells(2, 2, 3, 2);
      worksheet.mergeCells(2, 3, 3, 3);
      worksheet.mergeCells(2, worksheet.columns.length, 3, worksheet.columns.length);
      allBu.forEach((d, i) => {
        worksheet.mergeCells(2, 3 + 2 * i + 1, 2, 3 + 2 * i + 2);
      });

      let rowId = 3;
      ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].forEach((month, i) => {
        const _d = data.find((k) => k.month === i + 1);
        if (_d) {
          rowId += 1;
          const _row = { col0: month, col1: _d.workDay, col2: _d.hours, total: _d.totalPerMonth };
          allBu.forEach((k) => {
            const _k = _d.manhours.find((m) => m.buCode === k.buCode);
            _row[`${k.buCode}0`] = _k && _k.employeeCount ? _k.employeeCount : 0;
            _row[`${k.buCode}1`] = _k && _k.manHour ? _k.manHour : 0;
          });
          worksheet.addRow(_row);
          excelRowOne(worksheet, rowId, worksheet.columns.length);
        }
      });

      rowId += 1;
      const _row = { col0: 'Total', total: calculations.yearTotal };
      allBu.forEach((d) => {
        const _d = totals.find((k) => k.buCode === d.buCode);
        _row[`${d.buCode}0`] = _d && _d.total ? d.total : 0;
      });
      worksheet.addRow(_row);
      excelRowEnd(worksheet, rowId, worksheet.columns.length);
      worksheet.mergeCells(rowId, 1, rowId, 3);
      allBu.forEach((d, i) => {
        worksheet.mergeCells(rowId, 3 + 2 * i + 1, rowId, 3 + 2 * i + 2);
      });

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `Man-hour ${yearSelected}.xlsx`);

      setExportReady(true);
    }
  };
  // END: Export Data

  return (
    <Paper elevation={0}>
      <Typography variant="h1" className="mb-6">
        {PAGE_TITLE}
      </Typography>

      <Box className="flex mb-6" sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Box className="grid grid-cols-1">
          <ManHourChartSections
            chartQueryState={chartState}
            {...(props as any)}
            onReadyStatusChart={() => {
              Logger(['[ChartSections][onReadyStatusChart]']);
            }}
          />
        </Box>
      </Box>

      <Paper elevation={4}>
        <Box className="border-0 border-b border-solid border-gray-300 flex px-6">
          <NavLink to="/incidents" className="font-medium text-lg p-5 pb-2 no-underline text-gray-900">
            Incidents
          </NavLink>
          <NavLink
            to="/incidents/man-hour"
            className="font-medium text-lg p-5 pb-2 no-underline text-blue-700 border-0 border-b-2 border-solid border-blue-700"
          >
            Man-hour
          </NavLink>
        </Box>
        <Box className="flex flex-wrap p-6 pb-0">
          <FilterMultiSelect
            clearable
            filterData={manHoursStore.manhoursBusinessUnitsFilter?.data?.data || ([] as BusinessUnitsSchema[])}
            isLoadingFilterData={manHoursStore.manhoursBusinessUnitsFilter.state !== 'ready'}
            selectedFilter={businessUnitsFilter}
            onCallBackFilter={onCallBackBusinessUnitsFilter}
            dropdownSetting={{
              valueKey: 'buCode',
              labelKey: 'buName',
              noSelectedText: 'All company',
            }}
            dropdownLabelConfig={(_value, _label) => `${_label} (${_value})`}
            sx={{ width: '200px' }}
            className="mr-5 mb-6"
          />

          <Select
            value={yearFilter as any}
            onChange={handleYearChange}
            open={isOpenYearFilter}
            onOpen={() => handleOpenCloseYear(true)}
            onClose={(event: any) => {
              // if (event.target.selected === undefined)
              handleOpenCloseYear(false);
            }}
            displayEmpty
            input={<OutlinedInput />}
            sx={{ width: '120px' }}
            className="mr-5 mb-6"
          >
            {yearData
              .sort((a, b) => b - a)
              .map((years) => (
                <MenuItem key={years} value={years}>
                  {years}
                </MenuItem>
              ))}
          </Select>
          {/* <Button
            className="w-36 ml-auto"
            variant="outlined"
            startIcon={<Icon icon="file" color="#3155FF" viewBox="0 0 384 512" size={18} />}
            download
            href={exportLinkV2(`/incidents/man-hours/export/manhours/manhours-${yearFilter}-${buColumn}.pdf`)}
          >
            Export PDF
          </Button> */}
          <Button
            onClick={onExportExcel}
            disabled={!exportReady}
            className="w-36 ml-auto"
            variant="outlined"
            startIcon={<Icon icon="file" color="#3155FF" viewBox="0 0 384 512" size={18} />}
          >
            Export Excel
          </Button>
        </Box>
        <Box className="px-6">
          {manHourDataReady && (
            <EnhancedTableManHours
              onInputChange={() => {
                // just pass the same filter selection value on change
                if (chartState?.value) {
                  const buCodes = chartState.value?.buCodes || [];
                  if (!buCodes.length) delete chartState.value?.buCodes;
                  dispatchChartState({ type: 'buCodes/year', value: { ...chartState.value, loading: true }, state: 'updated' });
                }
              }}
              parentStore={{ store: incidentStore }}
              manhoursStore={{ store: manHoursStore }}
              data={manHoursStore.manhoursListData.data}
              businessUnitsData={manHoursStore?.manhoursBusinessUnitsFilter.data.data}
              buColumn={buColumn}
            />
          )}
          {!manHourDataReady && manHoursStore.manhoursListData.state !== 'error' && <LinearProgress style={{ margin: '0px 24px 0px 24px' }} />}
          {!manHourDataReady && manHoursStore.manhoursListData.state === 'error' && (
            <Table aria-labelledby="tableTitle" size="small">
              <TableDataError col={10} description={`Please reload the page again.`} />
            </Table>
          )}
        </Box>
        {/* {employees.state === 'ready' && (
          <EnhancedTablePagination
            rowsLength={employees.data.pagination.totalItems}
            page={employees.data.pagination.pageNumber}
            rowsPerPage={employees.data.pagination.itemPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            setEmployeesPagination={setEmployeesPagination}
          />
        )} */}
      </Paper>
    </Paper>
  );
};

export default withManhoursContext(ManHours);
