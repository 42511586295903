import React from 'react';

import { Typography, Box, TableRow, TableCell, TableBody } from '@mui/material';

interface PropsDataNotFound {
  description?: string;
}

interface PropsTableDataNotFound {
  description?: string;
  col: number;
}

export const DataNotFound = (props: PropsDataNotFound) => {
  const { description } = props;
  return (
    <Box className="flex flex-col w-full items-center py-14">
      <img className="w-15 mb-4" alt="Data not found" src="/icons/DataNotFound.svg" />
      <Typography variant="body2" className="p-0 text-gray-400 font-medium mb-1">
        No result found
      </Typography>
      {description ? (
        <Typography variant="body2" className="p-0 text-gray-400 whitespace-nowrap">
          {description}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export function TableDataNotFound(props: PropsTableDataNotFound) {
  const { col } = props;
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={col} className="border-0">
          <DataNotFound {...(props as any)} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
