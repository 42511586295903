// ! NOT REUSE THIS COMPONENT ON ANYTHING ELSE!
// ? YOU CAN CREATE NEW INSTEAD

import React from 'react';
import { Typography, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { TrainingStore } from '@/src/types';
import { useGlobalContext } from '@/store/index';
import { Logger } from '@/utils/logger/index';
import { isSuperAdmin } from '@/src/utils';

interface PropsDeleteTrainingModal {
  /** Same modal for cancel and delete */
  modalType: 'delete' | 'cancel';
  location: 'detail' | 'list';
  title: string;
  description: string;
  uid: any;

  /** exit action to delete or cancel */
  handleExit(): void;
  handleSubmit(): void;
  /** how long to wait until we reset detail state */
  deleteWaitTime?: number;
}

function DeleteCancelTrainingModal(props: PropsDeleteTrainingModal & TrainingStore) {
  const { currentUser } = useGlobalContext();
  const { title, description, store, uid, modalType, handleExit, handleSubmit, location, deleteWaitTime } = props;
  const handleAction = () => {
    if (isSuperAdmin(currentUser?.userProfile)) {
      if (modalType === 'delete') {
        if (deleteWaitTime) store.setTrainingDelete(uid, location === 'list' ? 'list' : 'item', deleteWaitTime);
        else store.setTrainingDelete(uid, location === 'list' ? 'list' : 'item');
        handleSubmit();
        return;
      }

      if (modalType === 'cancel') {
        store.setTrainingCancel(uid, location === 'list' ? 'list' : 'item');
        handleSubmit();
        return;
      }
    }
    Logger(['[DeleteCancelTrainingModal]', 'wrong selection', `modalType=${modalType}`, `roleId=${currentUser?.userProfile?.roleId}`], 'warn');
  };

  return (
    <>
      <Dialog open={!!modalType && !!uid} onClose={handleExit} fullWidth={true} maxWidth={'sm'}>
        <Typography variant="h2" className="p-0 mb-6">
          {title}
        </Typography>
        <DialogContent className="p-0 mb-6">
          <Typography id="transition-modal-description">
            {description}
            {/* uid: {uid}
            <br />
            modalType: {modalType}
            <br /> */}
          </Typography>
        </DialogContent>
        <DialogActions className="p-0 justify-start">
          <Button className="mr-1 w-28" variant="contained" color="error" onClick={handleAction}>
            {modalType === 'cancel' ? 'Yes, cancel' : 'Delete'}
          </Button>
          <Button className="w-28" variant="outlined" color="error" onClick={handleExit}>
            {modalType === 'cancel' ? 'No' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteCancelTrainingModal;
