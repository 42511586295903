// ? THIS IS AN EXAMPLE STORE SAMPLE CAN USE TO CREATE NEW STORE

import { useState, useEffect, useContext } from 'react';
import { CalendarContext } from './context';
import { HttpTokens, CalendarStore, QueryCalendarEvents, GetCalendarEvents, CalendarSchema } from '@/src/types';
import {
  // delay,
  getJwtToken,
} from '@/src/utils';
// import { Logger } from '@/logger/index';
import {
  AzureAuthProviderProps,
  // StoreStateDefault,
  StoreStateDefaultV3,
  StoreStateV3,
} from '@/types/store';
import { CalendarEventListGet } from '@/services/http';
// import { calendarMockData } from '@/src/static';
// import { isFloat32Array } from 'util/types';
import { uniqBy } from 'lodash';

/**
 * to give us access to azure for admin
 */
interface ProviderProps {
  children: React.ReactNode;
  context: {
    [name: string]: any;
    azure: AzureAuthProviderProps;
  };
}
export const useCalendarContextInitialState = () => useContext(CalendarContext);

const CalendarContextProvider = (props: ProviderProps) => {
  const {
    children,
    // context
  } = props;
  /** Access to azure  */
  // const { azure } = context;
  const tokens: HttpTokens = { jwt: getJwtToken() };

  const contextInitialState = useCalendarContextInitialState();

  const [calendarData, setCalendarEvents] = useState<StoreStateDefaultV3<GetCalendarEvents>>(contextInitialState.store.calendarData);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const store: CalendarStore = {
    store: {
      calendarData,
      resetStatus: (apiName: string) => {},
      resetState: (apiName: string, state: StoreStateV3) => {
        if (apiName === 'calendar') {
          setCalendarEvents({ ...calendarData, state } as any);
        }
      },

      setCalendarEvents: (query: QueryCalendarEvents, updateState: 'update' | 'new' = 'new') => {
        if (updateState !== 'update') {
          if (isMounted) setCalendarEvents({ data: undefined, state: 'loading' } as any);
        }

        if (!isMounted) return;
        CalendarEventListGet('calendar', query, tokens)
          .then((data) => {
            if (isMounted) {
              // append more dates to calendar if already set

              if (updateState === 'update' && calendarData.state === 'ready') {
                // append more items to calendar and sort same way as on server

                const d: CalendarSchema[] = uniqBy([...calendarData.data.data, ...data.data], 'id').sort((a, b) => {
                  return (new Date(a.start).getTime() - new Date(b.start).getTime()) as any;
                });

                data.data = d;
                data.range = {
                  from: calendarData.data.range.from,
                  to: data.range.to,
                };
                setCalendarEvents({ data, state: 'updated' });
              } else {
                setCalendarEvents({ data, state: 'ready' });
              }
            }
          })

          .catch((err) => {
            if (isMounted) setCalendarEvents({ data: undefined as any, error: err, state: 'error' });
          });
      },
    },
  };

  return <CalendarContext.Provider value={store}>{children}</CalendarContext.Provider>;
};

const withCalendarContext = (Component: any) => {
  return function CalendarComponent(props: any) {
    return <CalendarContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</CalendarContext.Consumer>;
  };
};

export { CalendarContextProvider, withCalendarContext };
