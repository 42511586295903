// import { SnackBarAlertWithStore } from '@/src/components';
import { PropDefaults, AzureAuthProviderProps, ProfileStore } from '@/src/types';
import { useGlobalContext, withProfileContext } from '@/store/index';
import { Alert, LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

interface ProfileProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

const Router = (props: any): any => {
  const { pageType } = props;
  const outletProps = {
    ...props,
  };

  switch (pageType) {
    // ATTENTION we need loading status so we only load the main page one the router is mounded
    case 'loading': {
      return <LinearProgress className="mt-5" />;
    }
    case 'profile-parent': {
      // NOTE we never use access profile base
      return <Navigate to="/dashboard" replace />;
      // return <Profile {...(outletProps as any)} />;
    }
    case 'invalid-user': {
      return (
        <>
          <Alert severity="error">Invalid user</Alert>
        </>
      );
    }

    case 'detail-child': {
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }
    default: {
      // console.log('loading no route found!', pageType);
      return <>No mach for profile found</>;
    }
  }
};

const ProfilePage: React.FC<ProfileProps & ProfileStore> = (props) => {
  const { detailId } = useParams();
  const { currentUser } = useGlobalContext();
  const [mount, setMount] = useState<number>(0);
  const employeeId = currentUser?.userProfile?.employeeId;

  useEffect(() => {
    if (!mount) {
      setMount(1);
    }
  }, [setMount, mount]);
  const pageType: 'profile-parent' | 'detail-child' | 'loading' | 'invalid-user' = [
    !mount && 'loading',
    !!detailId && !!employeeId && detailId !== employeeId && 'invalid-user',
    !!detailId && 'detail-child',
    !detailId && 'profile-parent',
  ].filter((n) => !!n)[0] as any;

  return (
    <>
      <Router {...props} pageType={pageType} detailId={detailId} />
      {/* <SnackBarAlertWithStore autoHideDurationAndClearStore={4000} storeName={['LegalStore']} store={{ store: legalStore }} /> */}
    </>
  );
};
export default withProfileContext(ProfilePage);
