import { Icon, ConfirmIncidentModal } from '@/src/components';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { IncidentStatusReport as IncidentStatusReportConst } from '@/constants/index';
import { IncidentFormStoreParts, FormSubmitType, ConfirmModalState } from '@/src/types';
import { IncidentStore } from '@/types/store';

interface SubmitFooterProps {
  setFormRef: (type: FormSubmitType, decision?: 'OK' | 'CANCEL') => void;
  incidentStore: IncidentStore;
  pageType: 'create' | 'update';
  checkReadyToSave: () => boolean; // NOTE: use to check whether form is ready to submit, before open the confirmation modal.
}

export function SubmitFooter(props: SubmitFooterProps & IncidentFormStoreParts) {
  const {
    pageType,
    formStore,
    setFormRef,
    incidentStore: { store: incidentStore },
    checkReadyToSave,
  } = props;

  // TODO ADD state type to setConfirmModalState > {type, state}

  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>({ type: 'INITIAL', state: 'INITIAL' });
  const [title, setTitle] = useState<JSX.Element>(() => <span></span>);
  const [description, setDescription] = useState('');
  const [textArea, setTextArea] = useState('');
  const [primaryButton, setPrimaryButton] = useState('');
  const [secondaryButton, setSecondaryButton] = useState('');
  const handleOpenSubmit = () => {
    if (checkReadyToSave()) {
      setConfirmModalState({ type: 'CONFIRM', state: 'OPEN' });
      setTitle(<>Are you sure you want to submit this incident?</>);
      setDescription('This incident will be sent to SSHE Admin to review. The action cannot be undone.');
      setPrimaryButton('Submit');
      setSecondaryButton('Cancel');
      setTextArea('');
    }
  };

  const handleOpenApprove = () => {
    if (checkReadyToSave()) {
      setConfirmModalState({ type: 'APPROVE', state: 'OPEN' });
      setTitle(
        <>
          Are you sure you want to <span className="text-teal-500">approve</span> and submit?
        </>
      );
      setDescription('This incident will send to team lead to review.');
      setTextArea('Add note (optional)');
      setPrimaryButton('Submit');
      setSecondaryButton('Cancel');
    }
  };

  const handleOpenReject = () => {
    if (checkReadyToSave()) {
      setConfirmModalState({ type: 'REJECT', state: 'OPEN' });
      setTitle(
        <>
          Are you sure you want to <span className="text-red-500">reject</span> this incident?
        </>
      );
      setDescription('This incident will send back to the reporter to edit.');
      setTextArea('Add note (optional)');
      setPrimaryButton('Submit');
      setSecondaryButton('Cancel');
    }
  };

  /**
   * Super admin can update  without changing state
   */
  const handleOpenAdminSave = () => {
    setConfirmModalState({ type: 'SAVE-ONLY', state: 'INITIAL' });
    setFormRef('submit-update', 'OK');
  };

  const handleSaveDraft = () => {
    setConfirmModalState({ type: 'DRAFT', state: 'INITIAL' });
    setFormRef('submit-draft', 'OK');
  };

  const canShowByType = (type: FormSubmitType): boolean => {
    if (type === 'submit-draft') {
      if (pageType === 'create') return true;
      if (pageType === 'update') {
        return incidentStore.incidentDetailData.data?.data?.status === IncidentStatusReportConst.draft;
      }
    }

    if (type === 'submit-publish') {
      if (pageType === 'create') return true;
      if (pageType === 'update') {
        return incidentStore.incidentDetailData.data?.data?.status === IncidentStatusReportConst.draft;
      }
    }

    if (type === 'submit-approve') {
      if (pageType === 'update') {
        return incidentStore.incidentDetailData.data?.data?.status === IncidentStatusReportConst.review;
      }
    }
    if (type === 'submit-reject') {
      if (pageType === 'update') {
        return incidentStore.incidentDetailData.data?.data?.status === IncidentStatusReportConst.review;
      }
    }

    if (type === 'submit-update') {
      if (pageType === 'update') {
        return incidentStore.incidentDetailData.data?.data?.status === IncidentStatusReportConst.review;
      }
    }

    return false;
  };

  return (
    <>
      <Box
        className="sticky bottom-0 py-5 px-8 bg-white z-20 flex"
        sx={{ boxShadow: '0px 3px 10px #00000029', marginLeft: '-32px', marginBottom: '-32px', width: 'calc(100% + 400px)' }}
      >
        <Box className="mr-auto">
          {canShowByType('submit-update') && (
            <>
              <Button
                name="submit-update"
                disabled={formStore.actionState !== 'initial' || formStore.store.state === 'loading'}
                type="submit"
                variant="contained"
                onClick={handleOpenAdminSave}
              >
                Save
              </Button>
            </>
          )}

          {canShowByType('submit-draft') && (
            <>
              <Button
                name="submit-draft"
                disabled={formStore.actionState !== 'initial' || formStore.store.state === 'loading'}
                type="submit"
                variant="contained"
                onClick={handleSaveDraft}
              >
                Save draft
              </Button>
              <Button
                name="submit-draft"
                type="submit"
                variant="outlined"
                className="ml-4"
                onClick={() => {
                  setFormRef('submit-draft', 'CANCEL');
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>

        <Box className="ml-auto">
          {canShowByType('submit-publish') && (
            <Button
              disabled={formStore.actionState !== 'initial' || formStore.store.state === 'loading'}
              name="submit-publish"
              type="submit"
              variant="contained"
              className="ml-4"
              onClick={() => handleOpenSubmit()}
            >
              Submit to SSHE Admin
            </Button>
          )}

          {canShowByType('submit-approve') && (
            <>
              <Button
                disabled={formStore.actionState !== 'initial' || formStore.store.state === 'loading'}
                name="submit-approve"
                type="submit"
                variant="contained"
                color="success"
                className="text-white ml-4"
                startIcon={<Icon icon="circleCheckThin" color="#fff" size={16} />}
                onClick={() => handleOpenApprove()}
              >
                Approve
              </Button>
              <Button
                disabled={formStore.actionState !== 'initial' || formStore.store.state === 'loading'}
                name="submit-reject"
                type="submit"
                variant="contained"
                color="error"
                className="ml-4"
                startIcon={<Icon icon="circleXmarkThin" color="#fff" size={16} />}
                onClick={() => handleOpenReject()}
              >
                Reject
              </Button>
            </>
          )}
        </Box>
      </Box>
      <ConfirmIncidentModal
        disabled={incidentStore.incidentCreateUpdateData.state !== 'initial'}
        pageType="edit_new"
        incidentStore={{ store: incidentStore }}
        formStore={formStore}
        title={title}
        description={description}
        textArea={textArea}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        setState={(val: ConfirmModalState) => {
          if (val.type === 'CONFIRM') setFormRef('submit-publish', val.decision);
          if (val.type === 'APPROVE') setFormRef('submit-approve', val.decision);
          if (val.type === 'DRAFT' || val.type === 'INITIAL') setFormRef('submit-draft', val.decision);
          if (val.type === 'REJECT') setFormRef('submit-reject', val.decision);
          setConfirmModalState(val as any);
          return null as any;
        }}
        state={confirmModalState}
      />
    </>
  );
}
