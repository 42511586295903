// ? export all data for use with > Mock Proxy Server <
// ! must use .js extension for this file

const { example } = require('./example');
const { employeeList } = require('./employee/employee.list');
const { employeeCreate } = require('./employee/employee.create');
const { employeeDelete } = require('./employee/employee.delete');
const { employeeUpdate } = require('./employee/employee.update');
const { employeeById } = require('./employee/employee.id');
const { employeesFilter } = require('./employee/employees.filter');
const { businessUnits } = require('./business.units');

const { authLogin, authVerify, authLogout } = require('./auth');

/** @type {import('../../types/common').MockApiData[]} */
const mockApiDataList = [
  employeesFilter,
  example,
  employeeList,
  employeeCreate,
  employeeDelete,
  employeeUpdate,
  authLogin,
  authVerify,
  authLogout,
  businessUnits,
  employeeById,
];

exports.mockApiDataList = mockApiDataList;
