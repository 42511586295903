import { createContext } from 'react';
import {
  StoreStateV3,
  LegalStore,
  BodyLegalCreateUpdateLor,
  LegalFilterQuery,
  BodyUploadLegalFile,
  LorHistoryQuery,
  LegalFileSchema,
  LegalFileRemoveSchema,
  NotificationStatus,
  EmployeeByQuery,
  LegalChartsQuery,
  EmployeeSchema,
  BodyLegalLorEvaluation,
  LegalLorProgressBarQuery,
  BodyPostLegalLorStartEval,
  LegalChartSchema,
} from '@/types/index';
import { initialDataWrapper } from '../common';

export const LegalContext: React.Context<LegalStore> = createContext({
  store: {
    notify: {
      /**
       * call to action when status has changed
       */
      onStatusChange: (type: NotificationStatus, message?: string) => {},
      notificationStatus: 'initial',
    },

    resetStatus: (apiName: string) => {},
    resetState: (apiName: string, state: StoreStateV3) => {},

    setLegalLorUpdateCreate: (body: BodyLegalCreateUpdateLor, conditions?: 'NO_STORE_UPDATE', type?: 'new' | 'update', done?: (ok: boolean) => void) => {},
    legalLorUpdateCreateData: {
      ...initialDataWrapper,
    },
    setLegalLorDetail: (id: string, query: LorHistoryQuery, action?: 'update' | 'new', done?: (ok: boolean) => void, ref?: string) => {},
    legalLorDetailData: {
      ...initialDataWrapper,
      ref: null,
    } as any,
    setLegalList: (params: LegalFilterQuery, type?: 'hidden_load_after_deleted') => {},
    legalListData: {
      ...initialDataWrapper,
    },
    setLegalBuRelatedFilter: () => {},
    legalBuRelatedFilter: {
      ...initialDataWrapper,
    },
    setLegalFileUpload: (body: BodyUploadLegalFile, ref: string, conditions?: 'NO_STORE_UPDATE', done?: (ok: boolean, data?: LegalFileSchema) => void) => {},
    legalFileUploadData: {
      ...initialDataWrapper,
    },
    setLegalFileRemove: (id: string, conditions?: 'NO_STORE_UPDATE', done?: (ok: boolean, data?: LegalFileRemoveSchema) => void) => {},
    legalFileRemoveData: {
      ...initialDataWrapper,
    },
    setLegalCharts: (query?: LegalChartsQuery, action?: 'update' | 'new', done?: (ok: boolean, data?: LegalChartSchema) => void) => {},
    legalChartsData: {
      ...initialDataWrapper,
    },
    setLegalDelete: (id: string) => {},
    legalDeleteData: {
      ...initialDataWrapper,
    },
    setLegalEmployeesQuery: (
      params: EmployeeByQuery,
      action?: 'updated' | 'new' | 'NO_STORE_UPDATE',
      done?: (ok: boolean, data?: EmployeeSchema[]) => void
    ) => {},
    legalEmployeesQueryData: {
      ...initialDataWrapper,
    },
    setLegalLorHistory: (id: string, params: LorHistoryQuery) => {},
    legalLorHistoryData: {
      ...initialDataWrapper,
    },

    setLegalEvaluation: (lorUid: string, body: BodyLegalLorEvaluation, ref?: string) => {},
    legalEvaluationData: {
      ...initialDataWrapper,
      ref: null,
    } as any,

    setLegalLorProgress: (id: string, params: LegalLorProgressBarQuery) => {},
    legalLorProgressData: {
      ...initialDataWrapper,
    },

    setLegalLorStartEva: (id: string, body: BodyPostLegalLorStartEval, done?: (ok: boolean) => void) => {},
    legalLorStartEvaData: {
      ...initialDataWrapper,
    },
  },
} as LegalStore);
