import { Icon, ProgressBarIncident, ConfirmIncidentModal } from '@/src/components';
import {
  AzureAuthProviderProps,
  PropDefaults,
  IncidentStore,
  ConfirmModalState,
  FormSubmitType,
  IncidentReportApprovalSchema,
  IncidentStatusReport,
  IncidentCanApprove,
} from '@/src/types';
import { Box, Button, Modal, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { Logger } from '@/logger/index';
import { useGlobalContext } from '@/store/index';
import { userCanOnIncident, isTeamLead, isHod, isGM } from '@/utils/index';
import { TaskCompletion } from './taskCompletion';
import { IncidentStatusReport as IncidentStatusReportConst } from '@/constants/index';

interface YourTasksIncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /**
   * @param text optional text from text field
   */
  setFormRef: (type: FormSubmitType, decision?: 'OK' | 'CANCEL', text?: string) => void;
  /** incident uid */
  detailId: string;
}

export default function YourTasksIncident(props: YourTasksIncidentProps & IncidentStore) {
  const { currentUser } = useGlobalContext();
  const { store: incidentStore, setFormRef, detailId } = props;
  const [openProgressDialog, setOpenProgressDialog] = React.useState(false);
  // const [mount, setMount] = React.useState<number>(0);
  const [confirmModalState, setConfirmModalState] = React.useState<ConfirmModalState>({ type: 'INITIAL', state: 'INITIAL' });
  const [title, setTitle] = React.useState<JSX.Element>(() => <span></span>);
  const [description, setDescription] = React.useState('');
  const [textArea, setTextArea] = React.useState('');
  const [primaryButton, setPrimaryButton] = React.useState('');
  const [secondaryButton, setSecondaryButton] = React.useState('');

  const { incidentReportApprovalData, incidentDetailData } = incidentStore;

  const handleClickOpenProgressDialog = () => {
    setOpenProgressDialog(true);
  };
  const handleCloseProgressDialog = () => {
    setOpenProgressDialog(false);
  };

  const handleOpenApprove = () => {
    setConfirmModalState({ type: 'APPROVE', state: 'OPEN' });
    setTitle(
      isGM(currentUser.userProfile) ? (
        <>
          Are you sure you want to <span className="text-teal-500">approve</span> and close this incident?
        </>
      ) : (
        <>
          Are you sure you want to <span className="text-teal-500">approve</span> and submit?
        </>
      )
    );

    const message = isTeamLead(currentUser.userProfile, incidentDetailData.data?.data?.reporters as any)
      ? 'This incident will be send to Head of department/Co-founder to review.'
      : isHod(currentUser.userProfile)
      ? 'This incident will send to General Manager to review.'
      : isGM(currentUser.userProfile)
      ? 'This incident will close. cannot be undone.'
      : '';

    setDescription(message);
    setTextArea('Add note (optional)');
    setPrimaryButton('Submit');
    setSecondaryButton('Cancel');
  };
  const handleOpenReject = () => {
    setConfirmModalState({ type: 'REJECT', state: 'OPEN' });
    setTitle(
      <>
        Are you sure you want to <span className="text-red-500">reject</span> this incident?
      </>
    );
    setDescription('This incident will send back to the reporter to edit.');
    setTextArea('Add note (optional)');
    setPrimaryButton('Submit');
    setSecondaryButton('Cancel');
  };

  useEffect(() => {
    if (confirmModalState.state === 'OPEN') {
      // Logger(['[YourTasksIncident][confirmModalState]', confirmModalState], 'notice');
    }
  }, [confirmModalState]);

  const handleApiReportApproval = (state: ConfirmModalState) => {
    const approvalSteps = (): IncidentStatusReport => {
      if (isTeamLead(currentUser?.userProfile)) return 3;
      if (isHod(currentUser?.userProfile)) return 3;
      if (isGM(currentUser?.userProfile)) return 4;
      else return 3;
    };
    if (IncidentStatusReportConst.ongoing === incidentStore.incidentDetailData.data.data.status) {
      const bodyApprovalData: IncidentReportApprovalSchema = {
        incidentStatus: approvalSteps(),
        roleId: currentUser?.userProfile?.roleId,
        employeeId: currentUser?.userProfile?.employeeId,
        approve: state.type === 'APPROVE' ? 'accept' : 'reject',
        notes: state.text,
      } as any;
      incidentStore.setIncidentReportApproval(detailId, bodyApprovalData);
    } else {
      Logger(['[YourTasksIncident][setIncidentReportApproval]', 'cannot submit if status is not set to ongoing (3)'], 'error');
    }
  };

  Logger(['[YourTasksIncident][incidentStore]', incidentStore]);
  // NOTE  and is not super_admin
  const canApprove: IncidentCanApprove = incidentDetailData.data?.data?.canApprove as any;
  const canSubmitApprovals =
    userCanOnIncident(
      currentUser,
      incidentStore.incidentDetailData.data?.data,
      incidentStore.incidentDetailData.data?.data.status as any,
      'can_submit_approval'
    ) && canApprove === 'CAN_APPROVE';

  return (
    <>
      <Box
        className="w-full p-3  bg-white xl:max-w-[326px] xl:rounded-3xl xl:p-6 xl:bg-gradient-to-t from-blue-100 to-white "
        sx={{
          boxShadow: '0px 3px 10px #AAAAAA29',
        }}
      >
        <TaskCompletion {...(props as any)} />

        <Box className="flex xl:block xl:mt-5">
          <Button variant="outlined" className="ml-3 mr-auto w-32  xl:hidden" onClick={handleClickOpenProgressDialog}>
            View progress
          </Button>

          {canSubmitApprovals && (
            <>
              <Button
                disabled={incidentReportApprovalData.state !== 'initial'}
                onClick={handleOpenApprove}
                variant="contained"
                color="success"
                className="text-white ml-1 w-32"
                startIcon={<Icon icon="circleCheckThin" color="#fff" size={16} />}
              >
                Approve
              </Button>
              <Button
                disabled={incidentReportApprovalData.state !== 'initial'}
                onClick={handleOpenReject}
                variant="contained"
                color="error"
                className="ml-4 mr-6 w-32"
                startIcon={<Icon icon="circleXmarkThin" color="#fff" size={16} />}
              >
                Reject
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Modal open={openProgressDialog} onClose={handleCloseProgressDialog} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="left-[50%] top-[50%] absolute w-[374px]" sx={{ transform: 'translate(-50%, -50%)' }}>
          <IconButton aria-label="close" onClick={handleCloseProgressDialog} className=" absolute right-[-18px] top-[1px]">
            <Icon icon="circleXmark" color="#3155FF" size={34} />
          </IconButton>
          <Box className="rounded-3xl rounded-tr-none bg-white">
            <ProgressBarIncident {...(props as any)}></ProgressBarIncident>
          </Box>
        </Box>
      </Modal>

      <ConfirmIncidentModal
        disabled={incidentStore.incidentReportApprovalData.state !== 'initial'}
        incidentStore={{ store: incidentStore }}
        pageType="detail"
        formStore={null as any} // no store here
        title={title}
        description={description}
        textArea={textArea}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        setState={(val: ConfirmModalState) => {
          setConfirmModalState(val as any);
          //  if (val.type === 'CONFIRM') setFormRef('submit-publish', val.decision);
          // if (val.type === 'DRAFT' || val.type === 'INITIAL') setFormRef('submit-draft', val.decision);
          if (val.type === 'APPROVE') setFormRef('submit-approve', val.decision, val.text);
          if (val.type === 'REJECT') setFormRef('submit-reject', val.decision, val.text);

          if (val.state === 'CONFIRM' && val.decision === 'OK') {
            handleApiReportApproval(val);
          }
          return null as any;
        }}
        state={confirmModalState}
      />
    </>
  );
}
