import { IconButton, TableBody, TableCell, TableRow, Box, Table, TableContainer, Button, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Icon } from '../../icons';
// import { AdminStore, AzureAuthProviderProps } from '@/src/types/store';
import { headCellsActionItemForm } from '@/src/static';
import { EnhancedTableHead } from '../../tableHead';
// import { DeleteCustomModal } from '@/src/components';
// import { ActionItemsSchema } from '@/src/static/legal/mockupData';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LorActionItemSchema } from '@/src/types';
import { v4 } from 'uuid';
import { clone } from 'lodash';
import { delay } from '@/src/utils';
interface Props {
  data: LorActionItemSchema[];
  onUpdate: (newData: LorActionItemSchema[]) => void;
}
export function EnhancedReviewLegislationActionItemsForm(props: Props) {
  const { data, onUpdate } = props;

  const [actionItemsData, setActionItems] = useState<LorActionItemSchema[]>(data || []);
  const [mount, setMount] = useState<number>(0);

  const updateItem = (item: LorActionItemSchema) => {
    let updated = false;
    const d = actionItemsData.map((n) => {
      if (n.uid === item.uid) {
        n = item;
        updated = true;
      }
      return n;
    });
    // new item
    if (!updated) {
      d.push(item);
      updated = true;
    }
    setActionItems(d);
  };

  useEffect(() => {
    if (!mount) {
      setMount(1);
    }
  }, [mount, setMount]);

  useEffect(() => {
    if (actionItemsData && mount) {
      onUpdate(actionItemsData);
    }
  }, [actionItemsData, mount]);

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead headCells={headCellsActionItemForm} />
          <TableBody>
            {actionItemsData?.map((row, index: number) => (
              <TableRow key={`${row.uid}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <TextField
                    onBlur={(e) => {
                      row.action = e.target.value;
                      updateItem(row);
                    }}
                    key={`${row.uid}`}
                    className="w-full"
                    id="outlined-basic"
                    defaultValue={row.action}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableMaskedInput
                      key={`${row.uid}`}
                      views={['year', 'month', 'day']}
                      inputFormat="dd MMM yyyy"
                      value={row.date}
                      onChange={(newValue) => {
                        row.date = newValue as any;
                        updateItem(row);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          InputLabelProps={{ shrink: true }}
                          className="calenderIcon w-full"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell align="right">
                  <Box className="flex justify-end">
                    <IconButton
                      key={`${index}-${row.uid}`}
                      color="error"
                      aria-label="Delete data"
                      className="mx-2"
                      onClick={() => {
                        actionItemsData.forEach((n, inx) => {
                          if (n.uid === row.uid) {
                            actionItemsData.splice(inx, 1);
                            delay(200).then(() => {
                              setActionItems(clone(actionItemsData));
                            });
                          }
                        });
                      }}
                    >
                      <Icon icon="trash" color="#F14242" viewBox="0 0 448 512" size={18} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={7}>
                <Button
                  onClick={(e) => {
                    const item: LorActionItemSchema = {
                      uid: v4(),
                      date: new Date(),
                      action: '',
                    } as any;
                    updateItem(item);
                  }}
                  variant="outlined"
                  startIcon={<Icon icon="plus" color="#3155FF" viewBox="0 0 512 512" size={18} />}
                >
                  Add new row
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* <DeleteCustomModal
        title={title}
        description={'This action will permanently delete the Incident and cannot be undone.'}
        id={currentid}
        onAction={(id, status) => {}}
      /> */}
    </>
  );
}
