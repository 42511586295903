import { UserModelData, UserSchema } from '@/types/schema/user.schema';
import { useContext, useState } from 'react';
import { GlobalContext } from './context';
import { nonUserModel, userModel } from '../users';
import { GlobalStore, NotificationStatus, PersistanceStoreSets } from '@/src/types';
import { Logger } from '@/utils/logger/index';
import { delay } from '@/utils/index';

export const useGlobalContextInitialState = () => useContext(GlobalContext);

const GlobalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const notifyCancelTimeout = 100;
  const contextInitialState = useGlobalContextInitialState();
  const [currentUser, setCurrentUser] = useState<UserSchema>(contextInitialState.currentUser);

  const notifyState = useState<NotificationStatus>(contextInitialState.notify.notificationStatus);
  const [persistance, setPersistance] = useState<PersistanceStoreSets>(contextInitialState.persistanceStore.persistance);
  const globalStore: GlobalStore = {
    currentUser,
    setCurrentUser: (user: UserModelData, userType: 'nonUser' | 'user' = 'user') => {
      if (userType === 'nonUser') setCurrentUser(nonUserModel(user as any));
      else {
        setCurrentUser(userModel(user as any));
      }
    },
    notify: {
      changeNotificationStatus: (type: NotificationStatus, message?: string) => {
        notifyState[1](type);
        delay(notifyCancelTimeout).then(() => {
          notifyState[0] = 'initial';
          Object.assign([], notifyState);
        });
        if (message) {
          Logger(['[changeNotificationStatus][type]', type], 'notice');
          Logger(['[changeNotificationStatus][message]', message], 'notice');
        }
      },
      notificationStatus: notifyState[0],
    },
    persistanceStore: {
      persistance,
      setPersistance: (storeName: 'legals', data: any, action: 'update' | 'new' = 'update') => {
        try {
          if (action === 'update') {
            const d = Object.assign({}, { ...(persistance || {}) }, { [storeName]: data });
            setPersistance({ ...d });
            Logger(['[persistanceStore]', `updated for storeName:${storeName}`, d], 'notice');
          } else {
            setPersistance({ [storeName]: data });
            Logger(['[persistanceStore]', `added for storeName:${storeName}`], 'notice');
          }
        } catch (err) {
          Logger(['[persistanceStore]', `Issue creating persistance for storeName: ${storeName}`, err], 'error');
        }
      },
    },
  };

  return <GlobalContext.Provider value={globalStore}>{children}</GlobalContext.Provider>;
};

const useGlobalContext = () => {
  return useContext(GlobalContext);
};

const withGlobalContext = (Component: any) => {
  return function GlobalComponent(props: any) {
    return <GlobalContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</GlobalContext.Consumer>;
  };
};

export { GlobalContextProvider, useGlobalContext, withGlobalContext };
