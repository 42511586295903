import { TableBody, TableCell, TableRow, Box, Table, TableContainer, Button } from '@mui/material';
import { headCellsIncidentsCorrectiveAction } from '@/src/static';

import { EnhancedTableHead } from '../../tableHead';
import { exportLinkV2, printDate } from '@/src/utils';
import { IncidentActionItemSchema } from '@/src/types';

interface EnhancedTableIncidentCorrectiveActionDetailsProps {
  data: IncidentActionItemSchema[] | undefined;
}
export function EnhancedTableIncidentCorrectiveActionDetails(props: EnhancedTableIncidentCorrectiveActionDetailsProps) {
  const { data } = props;

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small" className="xxl:mb-5 responseTable">
          <EnhancedTableHead headCells={headCellsIncidentsCorrectiveAction} />
          <TableBody>
            {data?.length ? (
              data.map((row: IncidentActionItemSchema, index: number) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell data-column="NO.">{index + 1}</TableCell>
                  <TableCell data-column="Corrective action">{row.action ? row.action : '-'}</TableCell>
                  <TableCell data-column="Action party">{row.party ? row.party : '-'}</TableCell>
                  <TableCell data-column="Responsibility">{row.responsibility ? row.responsibility : '-'}</TableCell>
                  <TableCell data-column="Due date">{row?.date ? printDate(row?.date) : '-'}</TableCell>
                  <TableCell data-column="File">
                    <Box className="flex ">
                      {row.file ? (
                        <>
                          <Button target={`_blank`} href={exportLinkV2(row.file.url)} className="p-0 min-w-0 h-auto text-black underline" variant="text">
                            View
                          </Button>
                        </>
                      ) : (
                        '-'
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell data-column="NO.">-</TableCell>
                <TableCell data-column="Corrective action">-</TableCell>
                <TableCell data-column="Action party">-</TableCell>
                <TableCell data-column="Responsibility">-</TableCell>
                <TableCell data-column="Due date">-</TableCell>
                <TableCell data-column="File">
                  <Box className="flex ">-</Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
