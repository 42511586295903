import { Logger } from '@/logger/index';
import React from 'react';
import { Icon } from '@/src/components';
import { AzureAuthProviderProps, IncidentStore, PropDefaults, ReviewerSchema } from '@/src/types';
import { Box, Typography } from '@mui/material';
import { getEmployeeRole, isUser, printDate } from '@/utils/index';
// import { useGlobalContext } from '@/store/index';
import { approverTaskTitles } from '@/static/incidents/index';

interface ReviewerProps {
  reviewers: ReviewerSchema;
}

const ReviewerList = (props: ReviewerProps): any => {
  // const { currentUser } = useGlobalContext();
  const { reviewers } = props;
  const { remarks } = reviewers;

  return (
    (remarks || [])
      .filter((n) => !!n.remark && !!n.employee)
      // TODO once the date is correct add sorting a-b
      .sort((a, b) => {
        return new Date(a.date as any).getTime() - new Date(b.date as any).getTime();
      })

      .map((n, inx) => {
        return (
          <React.Fragment key={inx}>
            <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
              <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
              {/* {isTeamLead(currentUser.userProfile, { approveBy: n.employee } as any) && <>{approverTaskTitles.tl}</>} */}
              {/**
               * remarks object only has allowed approvers, and team_lead is general user, so we can test for it:)
               */}
              {isUser(n.employee) && <>{approverTaskTitles.tl}</>}
              {getEmployeeRole(n.employee as any) === 'hod' && <>{approverTaskTitles.hod}</>}
            </Typography>
            <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:my-5 bg-white">
              <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
                <Typography className="lg:pl-6 w-full">{n.remark}</Typography>
              </Box>
              <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
                <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
                  <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
                    Approved by
                  </Typography>
                  <Typography className="lg:pl-6 w-full">
                    <Typography>
                      {n.employee?.firstName}&nbsp;{n.employee?.lastName}
                    </Typography>
                    <Typography>
                      {n.employee?.position}&nbsp;{!!n.employee?.buName && n.employee?.buName}
                    </Typography>
                  </Typography>
                </Box>
                <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
                  <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
                    Approval date
                  </Typography>
                  <Typography className="lg:pl-6 w-full">{printDate(n.date)}</Typography>
                </Box>
              </Box>
            </Box>
          </React.Fragment>
        );
      })
  );
};

interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export function ReportRemarkList(props: IncidentProps & IncidentStore): any {
  const { store } = props;
  const { incidentDetailData } = store;
  Logger(['[ReportRemarkList][reviewers]', incidentDetailData.data?.data?.reviewers]);
  return <>{!!incidentDetailData.data?.data?.reviewers && <ReviewerList reviewers={incidentDetailData.data.data.reviewers as any} />}</>;
}
