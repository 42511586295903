import { HeadCell } from '@/types/index';

export const headCellsTrainings: HeadCell[] = [
  {
    id: 'draft',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 0.25 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'date',
    label: 'Training date',
    disablePadding: false,
    sortAble: false,
    width: 1.5 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'time',
    label: 'Training time',
    disablePadding: false,
    sortAble: false,
    width: 0.75 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'courseId',
    label: 'Course no.',
    disablePadding: false,
    sortAble: false,
    width: 1 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'courseName',
    label: 'Course name',
    disablePadding: false,
    sortAble: false,
    width: 1.5 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'location',
    label: 'Location',
    disablePadding: false,
    sortAble: false,
    width: 0.5 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'attendees',
    label: 'Attendees',
    disablePadding: false,
    sortAble: false,
    width: 0.5 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'type',
    label: 'Training type',
    disablePadding: false,
    sortAble: false,
    width: 1 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'status',
    label: 'Status',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 9,
    textAlign: 'inherit',
  },
  {
    id: 'actions',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 1 / 9,
    textAlign: 'inherit',
  },
];

export const headCellsAttendees: HeadCell[] = [
  // {
  //   id: 'photo',
  //   label: '',
  //   disablePadding: false,
  //   sortAble: false,
  //   width: 0.5 / 10,
  //   textAlign: 'inherit',
  // },
  {
    id: 'id',
    label: 'Employee ID',
    disablePadding: false,
    sortAble: false,
    width: 1 / 10,
    textAlign: 'inherit',
  },
  {
    id: 'name',
    label: 'Name',
    disablePadding: false,
    sortAble: false,
    width: 1.5 / 10,
    textAlign: 'inherit',
  },
  {
    id: 'position',
    label: 'Position',
    disablePadding: false,
    sortAble: false,
    width: 0.9 / 10,
    textAlign: 'inherit',
  },
  {
    id: 'company',
    label: 'Company',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 10,
    textAlign: 'inherit',
  },
  {
    id: 'status',
    label: 'Status',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 10,
    textAlign: 'inherit',
  },
  {
    /**
     * ATTENTION don't change this ID!
     */
    id: 'preTestScore',
    label: 'Pre test',
    disablePadding: false,
    sortAble: false,
    width: 0.9 / 10,
    textAlign: 'center',
  },
  {
    /**
     * ATTENTION don't change this ID!
     */
    id: 'postTestScore',
    label: 'Post test',
    disablePadding: false,
    sortAble: false,
    width: 0.9 / 10,
    textAlign: 'center',
  },
  {
    /**
     * ATTENTION don't change this ID!
     */
    id: 'testScore',
    label: 'Result',
    disablePadding: false,
    sortAble: false,
    width: 0.7 / 10,
    textAlign: 'center',
  },
  {
    id: 'certificate',
    label: 'Certificate',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 10,
    textAlign: 'inherit',
  },
];

// REVIEW please correct it and use single values
export const statusData = [
  { value: '1,2', label: 'Draft, Published' },
  { value: '3', label: 'Cancelled' },
];

/**
 * based on TrainingStatus
 *
 */
export const trainingStatus = {
  1: 'Draft',
  2: 'Published',
  3: 'Cancelled',
};
