/** declare only http service related requests */

import { GetCourseTrainingTypes, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
export const CourseTrainingTypesGet = (prefix: string = 'course/training-types', tokens: HttpTokens, hardRejection = true): Promise<GetCourseTrainingTypes> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<GetCourseTrainingTypes>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetCourseTrainingTypes>;
};
