import { Autocomplete, Box, InputAdornment, TextField, CircularProgress } from '@mui/material';
import { Icon } from '@/components/index';
import { GetEmployeeByFilter } from 'src/types/crud';
import { useEffect, useState, useCallback } from 'react';
import { Logger } from '@/logger/index';
import { debounce, find } from 'lodash';
import { EmployeeSchema, CourseSmallSchema, CoursesSelect } from '@/types/schema';
import { getByTrainingCode } from '@/utils/index';

type FormatType = 'ADD-ADMIN' | 'ADD-TRAINING' | 'DEFAULT';
interface AdminSelection {
  label: string;
  key: string;
  employeeId: string;
}

interface TrainingSelection {
  label: string;
  key: string;
  id: string;
}

const formatOptionText = (data: any, format: FormatType): {} => {
  if (format === 'ADD-TRAINING') {
    const d: CoursesSelect = data as any;
    return {
      label: `[${getByTrainingCode(d.trainingCode).trainingName}] ${d.courseName}`,
      key: d.id,
      id: d.id,
    };
  } else return {};
};

export function SearchBoxAutocomplete(props: {
  /** providing default value, or selecting new value */
  mode?: 'edit' | 'create' | 'draft' | 'edit-publish';
  /** error indicates no results, so we dont have to show spinning wheel */
  error?: boolean;
  format?: FormatType;
  /** on available data pre/populated autoselect */
  selected?: any;
  data: EmployeeSchema[] | CoursesSelect[] | any;
  handleKeyPress?: (val: any, lastSearch?: string) => any;
  setNameSearching?: (inputValue?: any, type?: 'input' | 'change' | 'reset') => any;
  onSelectedOption?: (item: EmployeeSchema | CourseSmallSchema | CoursesSelect | any) => any;
  placeholder?: string;
  handleKeyFilter?: (val: any) => any;
}) {
  const { data, mode, error, handleKeyPress, setNameSearching, placeholder, onSelectedOption, format, handleKeyFilter, selected } = props || {};
  const [latestWords, setAddWords] = useState('');
  const [useData, setData] = useState<GetEmployeeByFilter | any>(null as any);
  const [preSelectedData, setPreSelect] = useState<CoursesSelect>(undefined as any);
  const [mount, setMount] = useState<number>(0);

  const viewFormat = !format ? 'DEFAULT' : format;
  const _mode = mode || 'create';

  useEffect(() => {
    if (data) {
      setData(data);
      if (!(data || []).length) {
        Logger(['[autoselect][no_results]'], 'warn');
      }
    }
  }, [setData, data]);

  // BUG, it clears all data from dropdown on load
  useEffect(() => {
    if ((latestWords || '').length === 0) {
      // REVIEW temporarily fix
      // this implementation will only work with dynamic data and not static, once cleared cannot be retrieved
      if (viewFormat !== 'ADD-TRAINING') {
        setData(null as any);
      }

      if (handleKeyFilter) handleKeyFilter('');
    }
  }, [latestWords]);

  const debounceTypingSearch = useCallback(
    debounce((value) => {
      if (value.length > 1) {
        if (handleKeyFilter) handleKeyFilter(value);
      }
    }, 300),
    []
  );

  const onChangeSelection = (selectOption: any) => {
    if (!onSelectedOption) return;
    switch (viewFormat) {
      case 'ADD-ADMIN': {
        const adminOption: AdminSelection = selectOption?.option;
        if (adminOption) {
          const matched = find(useData || [], { employeeId: adminOption.employeeId });
          if (matched) {
            onSelectedOption(matched);
          } else {
            Logger(['[SearchBoxAutocomplete][ADD-ADMIN][select]', 'not found for', adminOption], 'error');
          }
        }
        return;
      }
      case 'ADD-TRAINING': {
        const selection: TrainingSelection = selectOption?.option;
        if (selection) {
          const d: CoursesSelect[] = useData as any;
          const matched = find(d || [], { id: selection.id });
          if (matched) {
            onSelectedOption(matched);
          } else {
            Logger(['[SearchBoxAutocomplete][ADD-TRAINING][select]', 'not found for', matched], 'error');
          }
        }
        return;
      }
      default:
        Logger(['[SearchBoxAutocomplete][onChangeSelection]', `no match for ${viewFormat}`], 'warn');
    }
  };

  useEffect(() => {
    // NOTE we need to find corresponding item to pre/select
    if (viewFormat === 'ADD-TRAINING') {
      if (selected && (data?.length || useData?.length) && !mount && ['edit', 'edit-publish'].indexOf(_mode) !== -1) {
        const d: CoursesSelect[] = (data || useData) as any;
        const matchResp = d.filter((n) => selected === n.id)[0];
        if (!preSelectedData) {
          // NOTE  matching selected (courseId) with course/select by id
          if (matchResp) {
            setPreSelect(formatOptionText(matchResp, 'ADD-TRAINING' as any) as any);
          }
        }
        // NOTE onchange is not invoked for defaultValue we need t call the callback manually
        if (matchResp && !error) {
          if (onSelectedOption) onSelectedOption(matchResp as any);
        }
      }

      if (!mount) {
        setMount(1);
      }
    }
  }, [mount, setMount, selected, preSelectedData, setPreSelect, viewFormat, useData, useData]);

  const optionsText = !useData
    ? []
    : (useData || []).map((a, index) => {
        if (viewFormat === 'ADD-ADMIN') {
          return {
            // WARN PLEASE DONT REMOVE {employeeId} or onChange>onSelectedOption wont work
            label: `${a.firstName} ${a.lastName} (${a.buName}) ${a.email}`,
            key: a.employeeId + index,
            employeeId: a.employeeId,
          };
        } else if (viewFormat === 'ADD-TRAINING') {
          const _a: CoursesSelect = a as any;

          return {
            label: `[${getByTrainingCode(_a.trainingCode).trainingName}] ${_a.courseName}`,
            key: _a.id + index,
            id: _a.id,
          };
        } else {
          return { label: `${a.firstName} ${a.lastName} ${a.email}`, key: a.employeeId + index };
        }
      });

  /** optional props based on condition */
  const TextFieldProps: any = {
    ...(viewFormat === 'ADD-TRAINING' ? { required: true } : {}),
  };

  // BUG still not working, cannot assign dynamic value
  // issue with: MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled.
  const AutocompleteProps: any = {
    ...(['edit', 'edit-publish'].indexOf(_mode) !== -1 && preSelectedData && !error ? { defaultValue: preSelectedData } : {}),
  };

  // NOTE we need loader to initialize <Autocomplete/> defaultValue for edit mode
  if (viewFormat === 'ADD-TRAINING') {
    if (['edit', 'edit-publish'].indexOf(_mode) !== -1 && !preSelectedData && !error) {
      return <CircularProgress />;
    }
  }

  return (
    <Autocomplete
      {...AutocompleteProps}
      disablePortal
      id="Autocomplete-txt"
      freeSolo
      /** to figure out not working */
      loadingText={'Loading'}
      noOptionsText={'No results found'}
      options={optionsText}
      renderOption={(props, option: any) => (
        <Box component="li" {...props} key={option.key}>
          {option.label}
        </Box>
      )}
      sx={{
        width: viewFormat === 'ADD-ADMIN' || viewFormat === 'ADD-TRAINING' ? '100%' : '340px',
        marginRight: viewFormat === 'DEFAULT' ? '18px' : viewFormat === 'ADD-ADMIN' ? '0' : '18px',
      }}
      renderInput={(params) => (
        <TextField
          {...TextFieldProps}
          {...params}
          placeholder={placeholder || 'Enter keyword'}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            type: 'search',

            endAdornment: (
              <InputAdornment position="end" sx={{ display: viewFormat === 'ADD-ADMIN' ? 'none' : '' }}>
                {viewFormat === 'ADD-TRAINING' ? (
                  <Icon icon="chevronsDown" color="#3155FF" viewBox="0 0 512 512" size={20} />
                ) : (
                  <Icon icon="magnifyingGlass" color="#3155FF" viewBox="0 0 512 512" size={20} />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
      onChange={function (e, a, b, selectOption) {
        onChangeSelection(selectOption);
      }}
      onKeyPress={(e) => {
        if (handleKeyPress) {
          handleKeyPress(e, latestWords);
        }
      }}
      onInputChange={function (event, inputValue, type: 'input' | 'change' | 'reset' | any) {
        setAddWords(inputValue);

        if (viewFormat === 'DEFAULT') debounceTypingSearch(inputValue);
        if (setNameSearching) {
          if (viewFormat === 'ADD-ADMIN') setNameSearching(inputValue, type);
          if (viewFormat === 'ADD-TRAINING' && inputValue) setNameSearching(inputValue, type);
        }
      }}
    />
  );
}
