import {
  Box,
  IconButton,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  LinearProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  styled,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AzureAuthProviderProps, PropDefaults, CourseRequestDtoType, CourseSchema } from '@/src/types';
import { Navigate, NavLink, useLocation, useOutletContext } from 'react-router-dom';
import { Icon } from '@/src/components';
import { CourseStore } from '@/types/store';
import { Logger } from '@/logger/index';
import { printDate, printInstructionValue, serverError, getByTrainingCode, isSuperAdmin, excelRowInfo } from '@/src/utils';
import CourseForm from '@/components/ui/courses/createEditCourseModal/courseForm';
import { size } from 'lodash';
import { useGlobalContext } from '@/store/index';
import { SnackBarAlert } from '@/components/ui/variants/snackbar';
import { toastMessages } from '@/src/static';

import ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';

interface CoursesProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

/**
 * redirect to new course page if user updated courseNo
 */
const RedirectTo = ({ courseNo, newCourseNo }) => {
  if (!!courseNo && !!newCourseNo && courseNo !== newCourseNo) {
    return <Navigate to={`/courses/detail/${newCourseNo}`} />;
  } else return null;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export const CourseDetail: React.FC<any> = (props, context) => {
  const location = useLocation();
  const locationState: any = location.state;
  const isFromTrainingsDetail = locationState?.from;
  const trainingDate = locationState?.trainingDate;
  // const trainingIdFromState = locationState?.trainingId;
  const currentTrainingPassScore = locationState?.currentTrainingPassScore;

  const [alertState, setAlertState] = useState<boolean>(undefined as any);

  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success');
  const { currentUser } = useGlobalContext();
  const [mount, setMount] = useState<number>(0);
  const contextProps: CoursesProps & CourseStore = useOutletContext();
  const [getUid, setUid] = useState<string | undefined>(undefined);
  const [courseByNoData, setCourseByNoData] = useState<CourseSchema>({} as any);
  const { detail, store } = contextProps;

  const onGetCourseHandler = (uid: string) => {
    setUid(uid);
    Logger(['[onGetCourseHandler][uid]', uid], 'attention');
  };

  const onSubmitEditHandler = (data: CourseRequestDtoType, uid: string) => {
    Logger(['[edit/course][onSubmitEditHandler]', data], 'attention');
    Logger(['json', JSON.stringify(data)], 'log');
    store.courseUpdate(uid, data as any, 'item');
  };

  useEffect(() => {
    const courseNo: string = detail as any;
    if (!mount && store.courseByNoData.state !== 'ready' && courseNo) {
      store.courseByNo(courseNo);
      setMount(1);
    }
  }, [mount, setMount, detail, setUid]);

  useEffect(() => {
    // console.log('[CourseDetail] get in useEffect store.courseUpdatedData >>>', store.courseUpdatedData);

    if (store.courseByNoData.state === 'ready' && !size(courseByNoData)) {
      setCourseByNoData(store.courseByNoData.data?.data);
    }

    if (store.courseUpdatedData.state === 'ready') {
      // console.log('[CourseDetail] get in useEffect state === ready store.courseUpdatedData.data >>>', store.courseUpdatedData.data);
      // console.log('[CourseDetail] get in useEffect state === ready alertState >>>', alertState);

      if (store.courseUpdatedData.data?.data && !alertState) {
        setCourseByNoData(store.courseUpdatedData?.data.data);
        setAlertSeverity('success');
        setAlertState(true);
        setUid(undefined);
      }
    }

    if (store.courseByNoData.state === 'ready') {
      setUid(undefined);
    }

    if (store.courseUpdatedData.state === 'error') {
      // console.log('[CourseDetail] get in useEffect state === error store.courseByNoData.data >>>', store.courseByNoData.data);
      // console.log('[CourseDetail] get in useEffect state === error alertState >>>', alertState);

      if (store.courseByNoData.data?.data?.id && !alertState) {
        setUid(store.courseByNoData.data?.data?.id);
        setAlertSeverity('error');
        setAlertState(true);
      }
    }
  }, [setCourseByNoData, courseByNoData, setUid, store, setAlertState, setAlertSeverity]);

  if (store.courseUpdatedData.state === 'ready' && getUid) {
    Logger(['[store][courseUpdatedData]', store.courseUpdatedData.data], 'attention');
  }

  if (store.courseByNoData.state === 'ready') {
    Logger(['[store][courseByNoData]', store.courseByNoData.data, store.courseByNoData.state], 'attention');
  }

  const LoadEditCourseModal = (props: { getUid: string }) => {
    const { getUid } = props;
    // const _uid = !!detail || !!getUid;
    return (
      <>
        <Dialog
          open={!!getUid}
          onClose={() => {
            setUid(undefined);
            store.resetStatus('course/update');
            if (store.courseByIdData.state === 'error') {
              store.resetStatus('course/no');
            }
          }}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'body'}
        >
          <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
            <Typography className="font-medium text-2xl">Edit course</Typography>
          </DialogTitle>
          <DialogContent className="p-0 " sx={{ overflow: 'unset' }}>
            <CourseForm
              mode="edit"
              error={store.courseByNoData.state === 'error'}
              loading={store?.courseByNoData.state !== 'error' && store?.courseByNoData.state !== 'ready'}
              editUid={getUid}
              editData={store.courseByNoData?.data?.data}
              serverErrorResponse={serverError(store?.courseUpdatedData?.error)}
              allRequired={true}
              allRequiredMessage="cannot be empty."
              onClose={() => {
                if (store.courseByNoData.state === 'error') {
                  store.resetStatus('course/no');
                }
                setUid(undefined);
                store.resetStatus('course/update');
              }}
              onSubmit={onSubmitEditHandler as any}
              status="open"
            />
          </DialogContent>
        </Dialog>
      </>
    );
  };

  // START: Export Data
  const [exportReady, setExportReady] = useState(true);
  const onExportExcel = async (e: any) => {
    e.preventDefault();
    const data = courseByNoData;
    if (exportReady && data && data.courseName) {
      setExportReady(false);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet();
      worksheet.views.push({ showGridLines: false });
      worksheet.properties.defaultRowHeight = 25;

      worksheet.columns = [
        { key: 'col0', width: 20, header: 'Course name' },
        { key: 'col1', width: 40, header: data.courseName },
        { key: 'col2', width: 20, header: '' },
        { key: 'col3', width: 20, header: '' },
        { key: 'col4', width: 20, header: '' },
        { key: 'col5', width: 20, header: '' },
      ];
      worksheet.mergeCells(1, 2, 1, worksheet.columns.length);
      excelRowInfo(worksheet, 1, 'A', 'B');

      worksheet.addRow({
        col0: 'Course ID',
        col1: data.courseNo,
        col2: 'Training type',
        col3: getByTrainingCode(data?.trainingCode)?.trainingName,
        col4: 'Instructor/Institution',
        col5: printInstructionValue(data?.instruction),
      });
      excelRowInfo(worksheet, 2, 'A', 'B');
      excelRowInfo(worksheet, 2, 'C', 'D');
      excelRowInfo(worksheet, 2, 'E', 'F');

      worksheet.addRow({
        col0: 'Pre-test link',
        col1: data?.preTest?.link ? data?.preTest?.link : '',
        col2: 'Full score',
        col3: data?.preTest?.fullScore || data?.preTest?.fullScore === 0 ? data?.preTest?.fullScore : '',
        col4: 'Pass score',
        col5: data?.preTest?.passScore || data?.preTest?.passScore === 0 ? data?.preTest?.passScore : '',
      });
      excelRowInfo(worksheet, 3, 'A', 'B');
      excelRowInfo(worksheet, 3, 'C', 'D');
      excelRowInfo(worksheet, 3, 'E', 'F');

      worksheet.addRow({
        col0: 'Post-test link',
        col1: data?.postTest?.link ? data?.postTest?.link : '',
        col2: 'Full score',
        col3: data?.postTest?.fullScore || data?.postTest?.fullScore === 0 ? data?.postTest?.fullScore : '',
        col4: 'Pass score',
        col5: data?.postTest?.passScore || data?.postTest?.passScore === 0 ? data?.postTest?.passScore : '',
      });
      excelRowInfo(worksheet, 4, 'A', 'B');
      excelRowInfo(worksheet, 4, 'C', 'D');
      excelRowInfo(worksheet, 4, 'E', 'F');

      worksheet.addRow({
        col0: 'Overview',
        col1: data.overview ? data.overview : '',
      });
      worksheet.mergeCells(5, 2, 5, worksheet.columns.length);
      excelRowInfo(worksheet, 5, 'A', 'B');

      worksheet.addRow({
        col0: 'Learning outcomes',
        col1: data.learningOutcomes ? data.learningOutcomes : '',
      });
      worksheet.mergeCells(6, 2, 6, worksheet.columns.length);
      excelRowInfo(worksheet, 6, 'A', 'B');

      worksheet.addRow({
        col0: 'Training objectives',
        col1: data.trainingObjectives ? data.trainingObjectives : '',
      });
      worksheet.mergeCells(7, 2, 7, worksheet.columns.length);
      excelRowInfo(worksheet, 7, 'A', 'B');

      worksheet.addRow({
        col0: 'Topics',
        col1: data.topics ? data.topics : '',
      });
      worksheet.mergeCells(8, 2, 8, worksheet.columns.length);
      excelRowInfo(worksheet, 8, 'A', 'B');

      worksheet.addRow({
        col0: 'Target audience',
        col1: data.targetAudience,
        col2: 'Cost per person',
        col3: data.costPerPerson,
        col4: 'Total (persons)',
        col5: data.totalPersons,
      });
      excelRowInfo(worksheet, 9, 'A', 'B');
      excelRowInfo(worksheet, 9, 'C', 'D');
      excelRowInfo(worksheet, 9, 'E', 'F');

      worksheet.addRow({
        col0: 'Course duration (hours)',
        col1: data.courseDuration,
        col2: 'Version',
        col3: data.version,
        col4: 'Effective date',
        col5: printDate(courseByNoData.effectiveDate),
      });
      excelRowInfo(worksheet, 10, 'A', 'B');
      excelRowInfo(worksheet, 10, 'C', 'D');
      excelRowInfo(worksheet, 10, 'E', 'F');

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${data.courseName}.xlsx`);

      setExportReady(true);
    }
  };
  // END: Export Data

  return (
    <>
      {isSuperAdmin(currentUser?.userProfile) && <LoadEditCourseModal getUid={getUid as any} />}

      {store.courseByNoData.state === 'error' && <Alert severity="error">Page {detail} not found, or server error.</Alert>}
      {!size(courseByNoData) && store.courseByNoData.state !== 'error' ? (
        <LinearProgress />
      ) : (
        <>
          {!!size(store.courseByNoData.data?.data) && !!detail && <RedirectTo courseNo={detail} newCourseNo={store.courseByNoData.data?.data?.courseNo} />}

          <React.Fragment>
            {!!size(courseByNoData) && (
              <Paper elevation={0}>
                <Box className="flex">
                  {isFromTrainingsDetail ? (
                    <NavLink to={`${isFromTrainingsDetail}`}>
                      <IconButton color="primary" aria-label="View details" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                        <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
                      </IconButton>
                    </NavLink>
                  ) : (
                    <NavLink to="/courses">
                      <IconButton color="primary" aria-label="View details" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                        <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
                      </IconButton>
                    </NavLink>
                  )}

                  <Typography variant="h1" className="mb-6 m-1">
                    {courseByNoData?.courseName}
                  </Typography>
                </Box>
                <Paper elevation={4} className="p-6">
                  <Box className="flex items-center mb-6">
                    <Typography variant="h2">Course detail</Typography>
                    <Box className="flex items-center ml-auto">
                      {/* <Box className="flex items-center">
                        <Icon icon="chartColumn" viewBox="0 0 512 512" size={16} className="mr-2" />
                        <Typography variant="subtitle2">Total training time: 34</Typography>
                      </Box> */}
                      {isSuperAdmin(currentUser?.userProfile) && (
                        <Button
                          onClick={() => {
                            onGetCourseHandler(courseByNoData.id);
                          }}
                          className="ml-6"
                          variant="outlined"
                          startIcon={<Icon icon="penToSquare" color="#3155FF" viewBox="0 0 576 512" size={20} />}
                        >
                          Edit course
                        </Button>
                      )}
                      {/* <Button
                        className="ml-3"
                        variant="contained"
                        href={downloadLink(courseByNoData.downloadPdfLink, trainingIdFromState ? { trainingId: trainingIdFromState } : (null as any))}
                        download
                        startIcon={<Icon icon="arrowDownToLine" color="#ffffff" viewBox="0 0 384 512" size={16} />}
                      >
                        Download
                      </Button> */}
                      <Button
                        onClick={onExportExcel}
                        disabled={!exportReady}
                        className="ml-3"
                        variant="contained"
                        startIcon={<Icon icon="arrowDownToLine" color="#ffffff" viewBox="0 0 384 512" size={16} />}
                      >
                        Download
                      </Button>
                    </Box>
                  </Box>
                  <Table className="pdf">
                    <TableBody>
                      <TableRow>
                        <TableCell className="header">Course name</TableCell>
                        <TableCell colSpan={6}>{courseByNoData.courseName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Course ID</TableCell>
                        <TableCell width={'40%'}>{courseByNoData.courseNo}</TableCell>
                        <TableCell className="header">Training type</TableCell>
                        <TableCell width={'18%'}>{getByTrainingCode(courseByNoData?.trainingCode)?.trainingName}</TableCell>
                        <TableCell className="header">Instructor/Institution</TableCell>
                        <TableCell width={'18%'}>{printInstructionValue(courseByNoData?.instruction)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Pre-test link</TableCell>
                        <TableCell>
                          {courseByNoData?.preTest?.link ? (
                            <Link className="underline decoration-black" href={courseByNoData?.preTest?.link} sx={{ color: '#000' }} target="_blank">
                              View
                            </Link>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell className="header">Full score</TableCell>
                        <TableCell>
                          {courseByNoData?.preTest?.fullScore || courseByNoData?.preTest?.fullScore === 0 ? courseByNoData?.preTest?.fullScore : '-'}
                        </TableCell>
                        <TableCell className="header">Pass score</TableCell>
                        <TableCell>
                          {courseByNoData?.preTest?.passScore || courseByNoData?.preTest?.passScore === 0 ? courseByNoData?.preTest?.passScore : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Post-test link</TableCell>
                        <TableCell>
                          {courseByNoData?.postTest?.link ? (
                            <Link className="underline decoration-black" href={courseByNoData?.postTest?.link} sx={{ color: '#000' }} target="_blank">
                              View
                            </Link>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell className="header">Full score</TableCell>
                        <TableCell>
                          {courseByNoData?.postTest?.fullScore || courseByNoData?.postTest?.fullScore === 0 ? courseByNoData?.postTest?.fullScore : '-'}
                        </TableCell>
                        <TableCell className="header">Pass score</TableCell>
                        <TableCell>
                          {currentTrainingPassScore && currentTrainingPassScore !== courseByNoData?.postTest?.passScore ? (
                            <>
                              {currentTrainingPassScore}
                              <LightTooltip
                                title={<Box className=" text-center">Applied for the training on {trainingDate}</Box>}
                                arrow
                                // placement="bottom-end"
                              >
                                <IconButton className="p-0 ml-2">
                                  <Icon icon="circleInfo" color="#3155FF" size={18} className="" />
                                </IconButton>
                              </LightTooltip>
                            </>
                          ) : courseByNoData?.postTest?.passScore || courseByNoData?.postTest?.passScore === 0 ? (
                            courseByNoData?.postTest?.passScore
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Overview</TableCell>
                        <TableCell colSpan={6}>
                          <p style={{ whiteSpace: 'pre-line' }}>{courseByNoData.overview}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Learning outcomes</TableCell>
                        <TableCell colSpan={6}>
                          <p style={{ whiteSpace: 'pre-line' }}>{courseByNoData.learningOutcomes}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Training objectives</TableCell>
                        <TableCell colSpan={6}>
                          <p style={{ whiteSpace: 'pre-line' }}>{courseByNoData.trainingObjectives}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Topics</TableCell>
                        <TableCell colSpan={6}>
                          <p style={{ whiteSpace: 'pre-line' }}>{courseByNoData.topics}</p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Target audience</TableCell>
                        <TableCell>{courseByNoData.targetAudience}</TableCell>
                        <TableCell className="header">Cost per person</TableCell>
                        <TableCell>{courseByNoData.costPerPerson}</TableCell>
                        <TableCell className="header">Total (persons)</TableCell>
                        <TableCell>{courseByNoData.totalPersons}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="header">Course duration (hours)</TableCell>
                        <TableCell>{courseByNoData.courseDuration}</TableCell>
                        <TableCell className="header">Version</TableCell>
                        <TableCell>{courseByNoData.version}</TableCell>
                        <TableCell className="header">Effective date</TableCell>
                        <TableCell>{printDate(courseByNoData.effectiveDate)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Paper>
            )}
          </React.Fragment>
        </>
      )}
      <SnackBarAlert
        messages={{
          error: toastMessages.dataNotUpdated,
          success: toastMessages.dataUpdated,
        }}
        severity={alertSeverity}
        state={alertState as any}
        onClose={() => {
          setAlertState(false);
        }}
      />
    </>
  );
};
