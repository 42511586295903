// ? THIS IS AN EXAMPLE STORE SAMPLE CAN USE TO CREATE NEW STORE

import { useState, useEffect, useContext } from 'react';
import { ProfileContext } from './context';
import {
  GetProfileMyIncidentList,
  GetProfileMyTrainingList,
  HttpTokens,
  PostProfileAttendeeRequest,
  ProfileAttendeeRequestBody,
  ProfileMyTrainingQuery,
  // HttpTokens,
  ProfileStore,
  EmployeeByIdQuery,
  GetEmployeesById,
} from '@/src/types';
// import { getJwtToken } from '@/src/utils';
// import { Logger } from '@/logger/index';
import { AzureAuthProviderProps, NotificationStatus, StoreStateDefaultV3 } from '@/types/store';
import { getJwtToken } from '@/src/utils';
import { EmployeeIdGet, ProfileAttendeeRequestPost, ProfileMyIncidentGet, ProfileMyTrainingGet } from '@/services/http';

/**
 * to give us access to azure for admin
 */
interface ProviderProps {
  children: React.ReactNode;
  context: {
    [name: string]: any;
    azure: AzureAuthProviderProps;
  };
}

export const useProfileInitialState = () => useContext(ProfileContext);
const ProfileContextProvider = (props: ProviderProps) => {
  const contextInitialState = useProfileInitialState();
  const tokens: HttpTokens = { jwt: getJwtToken() };
  const {
    children,

    // azure   /** Access to azure  */
    // context
  } = props;

  const [profileIncidentsData, setProfileIncidents] = useState<StoreStateDefaultV3<GetProfileMyIncidentList>>(contextInitialState.store.profileIncidentsData);
  const [profileTrainingData, setProfileTrainingList] = useState<StoreStateDefaultV3<GetProfileMyTrainingList>>(contextInitialState.store.profileTrainingData);

  const [profileAttendeeRequestData, setProfileAttendeeRequest] = useState<StoreStateDefaultV3<PostProfileAttendeeRequest>>(
    contextInitialState.store.profileAttendeeRequestData
  );

  const [employeeByIdData, setEmployeeById] = useState<StoreStateDefaultV3<GetEmployeesById>>(contextInitialState.store.employeeByIdData);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const store: ProfileStore = {
    store: {
      profileIncidentsData,
      profileTrainingData,
      profileAttendeeRequestData,
      employeeByIdData,
      notify: {
        /**
         * call to action when status has changed
         */
        onStatusChange: (type: NotificationStatus, message?: string) => {},
        notificationStatus: 'initial',
      },
      resetStatus: (apiName: string) => {
        if (apiName === 'employees/profile/my-training') {
          setProfileTrainingList({ data: null as any, state: 'initial' });
        }
        if (apiName === 'employees/profile/my-incident') {
          setProfileIncidents({ data: null as any, state: 'initial' });
        }
        if (apiName === 'employees/profile/attendee/request') {
          setProfileAttendeeRequest({ data: null as any, state: 'initial' });
        }
      },
      setProfileIncidents: (employeeId: string) => {
        if (!isMounted) return;
        setProfileIncidents({ data: undefined as any, state: 'loading' });

        ProfileMyIncidentGet('employees/profile/my-incident', employeeId, tokens)
          .then((data) => {
            if (!isMounted) return;

            setProfileIncidents({ data: data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            setProfileIncidents({ data: undefined as any, error, state: 'error' });
          });
      },
      setProfileTrainingList: (employeeId: string, query?: ProfileMyTrainingQuery) => {
        if (!isMounted) return;
        setProfileTrainingList({ data: undefined as any, state: 'loading' });

        ProfileMyTrainingGet('employees/profile/my-training', employeeId, query || {}, tokens)
          .then((data) => {
            if (!isMounted) return;
            setProfileTrainingList({ data: data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            setProfileTrainingList({ data: undefined as any, error, state: 'error' });
          });
      },
      setProfileAttendeeRequest: (employeeId: string, attendeeId: string, body: ProfileAttendeeRequestBody, done?: (ok: boolean) => void) => {
        if (!isMounted) return;

        setProfileAttendeeRequest({ data: undefined as any, state: 'loading' });

        ProfileAttendeeRequestPost(`employees/profile/my-training`, employeeId, attendeeId, body, tokens)
          .then((data) => {
            if (!isMounted) return;
            let updated = false;

            const d = (profileTrainingData?.data?.data || []).map((n) => {
              if (n.attendeeId === attendeeId) {
                n.status = data.data.status;
                updated = true;
              }
              return n;
            });

            if (updated) {
              profileTrainingData.data.data = d;
              setProfileTrainingList(profileTrainingData);
            }
            setProfileAttendeeRequest({ data: data, state: 'ready' });
            if (done) done(true);
          })
          .catch((error) => {
            if (!isMounted) return;
            setProfileAttendeeRequest({ data: undefined as any, error, state: 'error' });
            if (done) done(false);
          });
      },

      setEmployeeById: (employeeId: string, query?: EmployeeByIdQuery) => {
        if (!isMounted) return;
        setEmployeeById({ data: undefined as any, state: 'loading' });

        EmployeeIdGet('employee', employeeId, query || {}, tokens)
          .then((data) => {
            if (!isMounted) return;
            setEmployeeById({ data: data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            setEmployeeById({ data: undefined as any, error, state: 'error' });
          });
      },
    },
  };

  return <ProfileContext.Provider value={store}>{children}</ProfileContext.Provider>;
};

const withProfileContext = (Component: any) => {
  return function ProfileComponent(props: any) {
    return <ProfileContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</ProfileContext.Consumer>;
  };
};

export { ProfileContextProvider, withProfileContext };
