import React, { useState, useEffect, Fragment } from 'react';
import ReactEChart from 'echarts-for-react';

import { Box, Typography, Select, MenuItem, OutlinedInput, debounce, Skeleton, CircularProgress } from '@mui/material';
import { trainingChartsOption } from './chart.option';
import { getYearArray } from '@/src/utils';
import { DashboardStore, PropDefaults, TrainingChartSchema } from '@/src/types';
import { Logger } from '@/logger/index';
interface Props extends PropDefaults {}

type ChartOptions = typeof trainingChartsOption;
const yearData = getYearArray().reverse();

export function DashboardTrainingChart(props: Props & DashboardStore) {
  const { store: dashboardStore } = props;
  const [chartInstance, setChartInstance] = useState<ReactEChart>(null as any);
  const [optionsData, setOptions] = useState<ChartOptions>(trainingChartsOption);
  const [onData, setData] = useState<TrainingChartSchema>(null as any);
  const [hasError, setError] = useState<boolean>(false);
  const [yearSelected, setYear] = useState<number>(new Date().getFullYear());
  const [mount, setMount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const onApiFetchReady = (ok: boolean, data: TrainingChartSchema) => {
    if (ok) {
      setData(data);
      setError(false);
      setLoading(false);
      Logger(['[DashboardLegalChart][setDashboardLegalCharts][onApiFetchReady]', data]);
    } else {
      setData(null as any);
      setError(true);
      setLoading(false);
    }
  };

  const callApi = (year: number) => {
    dashboardStore.setDashboardTrainingsChart({ year: year.toString() || new Date().getFullYear().toString() }, onApiFetchReady as any);
  };

  const updateChart = () => {
    if (chartInstance) {
      const inst = chartInstance.getEchartsInstance();
      inst.setOption(optionsData as any);
      inst.resize();
    }
  };

  const updateOptions = (onData: TrainingChartSchema, reset = false) => {
    optionsData.series.forEach((n) => {
      if (n.id === 'classroom') {
        n.data = (reset ? [] : onData.seriesData.classRoom) as any;
      }
      if (n.id === 'online') {
        n.data = (reset ? [] : onData.seriesData.online) as any;
      }
      if (n.id === 'e-learning') {
        n.data = (reset ? [] : onData.seriesData.elearning) as any;
      }
    });
  };

  /**
   * initiate debounced callback
   */
  const debouncedCallBack = debounce(
    (call: () => void) => {
      call();
    },
    200
    // { maxWait: 1000 }
  );

  useEffect(() => {
    if (!mount && !onData) {
      callApi(yearSelected);
      setMount(1);
      return;
    }

    if (onData && !hasError) {
      optionsData.title.show = false;
      optionsData.xAxis.data = onData.categoryList as any;
      updateOptions(onData, false);
      setOptions(optionsData);
      updateChart();
      return;
    }

    if (hasError) {
      optionsData.title.show = true;
      setOptions(optionsData);
      updateOptions(onData, true);
      updateChart();
    }
  }, [hasError, onData, optionsData, setOptions, chartInstance, setChartInstance, mount, setMount, yearSelected]);

  return (
    <Fragment>
      {hasError || onData ? (
        <Box className="shadow-md rounded-2xl border border-solid border-gray-200 bg-white relative h-full flex flex-col justify-end items-center">
          <Box className="flex absolute top-6 left-6">
            <Typography variant="h4" className=" text-black">
              Training summary
            </Typography>
          </Box>

          <Box className="flex items-center absolute top-[1rem] right-6" style={{ zIndex: 1 }}>
            {loading && <CircularProgress className="mr-2" size={20} />}
            <Select
              defaultValue={yearSelected}
              onChange={(...e) => {
                const target: { value: number; name?: string } = e[0].target as any;
                if (target.value) {
                  setYear(target.value);
                  setLoading(true);
                  debouncedCallBack(() => {
                    callApi(target.value);
                  });
                }
              }}
              className="bg-white w-[82px]"
              labelId="selectType"
              id="selectType"
              input={<OutlinedInput />}
            >
              {(yearData || []).map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {!!mount && (
            <ReactEChart
              ref={(r: any) => {
                if (r) {
                  r.inx = (r.inx || 0) + 1;
                  setChartInstance(r as any);
                }
              }}
              className={`pl-5 pb-0 ${loading && 'opacity-25'}`}
              style={{ position: 'relative', top: '25px', height: '100%', width: '105%' }}
              option={optionsData}
            />
          )}
        </Box>
      ) : (
        <Box className="shadow-md rounded-2xl border border-solid border-gray-200 bg-white relative h-full flex flex-col justify-center ">
          <Box className="flex absolute top-6 left-6 z-30">
            <Typography variant="h4" className=" text-black">
              Training summary
            </Typography>
          </Box>
          <Skeleton animation="wave" component={'div'} className="h-full w-full mr-6 transform-none rounded-2xl border-gray-200 bg-white" />
        </Box>
      )}
    </Fragment>
  );
}
