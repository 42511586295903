import { LegalStore } from '@/src/types';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Icon } from '@/src/components';
import React from 'react';

export const LegalInitialDetail = (props: LegalStore) => {
  const { store: legalStore } = props;
  const legalLorDetailData = legalStore?.legalLorDetailData?.data?.data;

  return (
    <>
      <Typography variant="h6" className="pl-8">
        {legalLorDetailData?.name ? legalLorDetailData?.name : ''}
      </Typography>
      <Box className="mt-6">
        <Typography variant="h2" color="primary">
          <Icon icon="squareFull" color="#3155FF" size={10} className="mr-5" />
          Summary
        </Typography>
        <Box className="border border-solid border-gray-300 px-8 py-6 mt-5">
          <Typography className="leading-7" sx={{ whiteSpace: 'pre-line' }}>
            {legalLorDetailData?.summary ? legalLorDetailData?.summary : ''}
          </Typography>
        </Box>
      </Box>
      <Box className="mt-8">
        <Typography variant="h2" color="primary">
          <Icon icon="squareFull" color="#3155FF" size={10} className="mr-5" />
          Required actions
        </Typography>
        <Box className="border border-solid border-gray-300 px-8 py-6 mt-5">
          <Typography className="leading-7" sx={{ whiteSpace: 'pre-line' }}>
            {legalLorDetailData?.actions ? legalLorDetailData?.actions : ''}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
