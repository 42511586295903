import { HeadCell, LegalRelatedBuListSchema } from '@/types/index';

export const test34456 = false;

export const headCellsLegals: HeadCell[] = [
  {
    id: 'draft',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 0.25 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'code',
    label: 'Code',
    disablePadding: false,
    sortAble: false,
    width: 1 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'name',
    label: 'Name',
    disablePadding: false,
    sortAble: false,
    width: 2 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'types',
    label: 'Type',
    disablePadding: false,
    sortAble: false,
    width: 0.6 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'evaluateDate',
    label: 'Evaluate year',
    disablePadding: false,
    sortAble: false,
    width: 0.75 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'promulgationDate',
    label: 'Promulgation date',
    disablePadding: false,
    sortAble: false,
    width: 1 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'effectiveDate',
    label: 'Effective date',
    disablePadding: false,
    sortAble: false,
    width: 1 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'hierarchy',
    label: 'Hierarchy',
    disablePadding: false,
    sortAble: false,
    width: 1.6 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'relatedBU',
    label: 'Related company',
    disablePadding: false,
    sortAble: false,
    width: 1 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'status',
    label: 'Status',
    disablePadding: false,
    sortAble: false,
    width: 0.95 / 11,
    textAlign: 'inherit',
  },
  {
    id: 'actions',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 0.95 / 11,
    textAlign: 'inherit',
  },
];

export const headCellsActionItemForm: HeadCell[] = [
  {
    id: 'no',
    label: 'No.',
    disablePadding: false,
    sortAble: false,
    width: 0.2 / 4,
    textAlign: 'inherit',
  },
  {
    id: 'actionParty',
    label: 'Action party',
    disablePadding: false,
    sortAble: false,
    width: 2.6 / 4,
    textAlign: 'inherit',
  },
  {
    id: 'dueDate',
    label: 'Due date',
    disablePadding: false,
    sortAble: false,
    width: 1 / 4,
    textAlign: 'inherit',
  },
  {
    id: 'actions',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 0.2 / 4,
    textAlign: 'inherit',
  },
];

export const headCellsActionItems: HeadCell[] = [
  {
    id: 'no',
    label: 'No.',
    disablePadding: false,
    sortAble: false,
    width: 0.2 / 3,
    textAlign: 'inherit',
  },
  {
    id: 'actionParty',
    label: 'Action party',
    disablePadding: false,
    sortAble: false,
    width: 2.3 / 3,
    textAlign: 'inherit',
  },
  {
    id: 'dueDate',
    label: 'Due date',
    disablePadding: false,
    sortAble: false,
    width: 0.5 / 3,
    textAlign: 'inherit',
  },
];

interface LegalRelatedBuListSchemaAlt extends LegalRelatedBuListSchema {
  /** internal validation */
  selected?: boolean;
}

export const legalRelatedBuList: LegalRelatedBuListSchemaAlt[] = [
  { buCode: 'cariva', buName: 'Cariva', selected: true },
  { buCode: 'arv', buName: 'ARV' },
  { buCode: 'rovula', buName: 'Rovula' },
  { buCode: 'skyller', buName: 'Skyller' },
  { buCode: 'varuna', buName: 'Varuna' },
  { buCode: 'AVR', buName: 'AVR Core' },
  { buCode: 'CRV', buName: 'Cariva' },
];

export const buReLatedFilterData = [
  { value: '1', label: 'Environment' },
  { value: '2', label: 'Security' },
  { value: '3', label: 'Safety' },
  { value: '4', label: 'Health' },
];
