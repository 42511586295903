import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Box, Checkbox, Chip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface TagsInputIncidentProps {
  selectKeyName: string;
  selectSubKeyName?: string;
  dropdownData: { value: number; label: string }[];
  selectedData: { value: number; label: string }[];
  onSelection: (type: 'select' | 'delete', value: any, keyName: string, subKeyName?: string) => void;
  disabledInput?: boolean;
}
export default function TagsInputIncident({
  selectKeyName,
  selectSubKeyName,
  dropdownData,
  selectedData,
  onSelection,
  disabledInput = false,
}: TagsInputIncidentProps): JSX.Element {
  const [value, setValue] = useState<{ value: number; label: string }[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [valueDelete, setValueDelete] = useState<number | undefined>();

  useEffect(() => {
    setValue(selectedData || ([] as { value: number; label: string }[]));
  }, [selectedData]);

  const onDelete = (valueDelete: any) => {
    if (selectSubKeyName) {
      onSelection('delete', valueDelete, selectKeyName, selectSubKeyName);
    } else {
      onSelection('delete', valueDelete, selectKeyName);
    }
  };

  useEffect(() => {
    if (valueDelete !== undefined) {
      onDelete(valueDelete);
      setValueDelete(undefined);
    }
  }, [valueDelete]);

  const onSelect = () => {
    if (selectSubKeyName) {
      onSelection('select', value, selectKeyName, selectSubKeyName);
    } else {
      onSelection('select', value, selectKeyName);
    }
  };

  return (
    <>
      <Autocomplete
        className="multiSelectDropdown"
        multiple
        id="checkboxes-tags-demo"
        options={dropdownData}
        disableCloseOnSelect
        disableClearable
        disabled={disabledInput}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.label}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        value={value}
        onChange={(e, newValue) => {
          if (e.type !== 'keydown' || (e.type === 'keydown' && (e as any).key !== 'Backspace')) {
            setValue(newValue);
          }
        }}
        open={isDropdownOpen}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => {
          setIsDropdownOpen(false);
          onSelect();
        }}
        renderTags={() => null}
        renderInput={(params) => <TextField {...params} placeholder="Please select or type keyword" disabled={disabledInput} />}
      />
      <Box>
        {value.map((v) => (
          <Chip
            className="delIcon mt-2 mr-2"
            key={v.value}
            label={v.label}
            onDelete={() => {
              setValue((previousState) => previousState.filter((item) => item.value !== v.value));
              // onDelete(v.value);
              setValueDelete(v.value);
            }}
            sx={{ background: '#E6EBFF', borderRadius: '6px' }}
          />
        ))}
      </Box>
    </>
  );
}
