import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, Dialog, DialogContent, DialogTitle, DialogProps } from '@mui/material';
import { Icon } from '@/src/components';
import LegalForm from './legalFromClass';
import { LegalStore } from '@/src/types';
import { AzureAuthProviderProps } from '@/types/store';
import { Logger } from '@/logger/index';
import { useGlobalContext } from '@/store/index';
import { delay, isSuperAdmin } from '@/src/utils';
import { cloneDeep } from 'lodash';

type LoadStatus = 'initial' | 'ready' | 'submit' | 'loading' | 'error';
interface Props extends LegalStore {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  updateId: { type: 'open' | 'close'; uid: string };
  onCloseModal: () => void;
  reloadChart: () => void;
}

// NOTE  every time component reloads we need to store last valid value

function CreateUpdateLegalModal(props: Props & LegalStore) {
  const { currentUser } = useGlobalContext();
  const { store: legalStore, updateId, onCloseModal, reloadChart } = props;
  const [open, setOpen] = useState(false);

  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
  const [onUpdateId, setUpdateId] = useState<{ type: 'open' | 'close'; uid: string }>({ type: 'close', uid: null as any });

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    // legalStore.resetStatus('legal/lor/create-update');
    // legalStore.resetStatus('legal/lor/detail');

    setScroll(scrollType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCloseModal();
    legalStore.resetStatus('legal/lor/create-update');
    legalStore.resetStatus('legal/lor/detail');
    setUpdateId(null as any);
  };

  useEffect(() => {
    if (updateId?.type === 'open' && updateId?.uid) {
      setUpdateId(updateId);
      setOpen(true);
    }
  }, [updateId, onUpdateId, setUpdateId]);

  useEffect(() => {
    if (open && legalStore.legalBuRelatedFilter.state === 'initial' && !legalStore.legalBuRelatedFilter?.data?.data) {
      legalStore.setLegalBuRelatedFilter();
    }

    if (open && onUpdateId?.type === 'open' && !legalStore.legalLorDetailData?.data?.data && legalStore.legalLorDetailData.state === 'initial') {
      legalStore.setLegalLorDetail(onUpdateId?.uid, {});
    }

    if (legalStore.legalLorDetailData.state === 'ready') {
      Logger(['[CreateUpdateLegalModal][legalLorDetailData]', legalStore.legalLorDetailData]);
    }

    // if (legalStore.legalLorUpdateCreateData.state === 'ready' && open) {
    //   setOpen(false);
  }, [legalStore, onUpdateId, updateId, open]);

  const loadStatus: LoadStatus = [
    onUpdateId?.type === 'close' && 'ready',
    onUpdateId?.type === 'open' && legalStore.legalLorDetailData.state === 'error' && 'error',
    onUpdateId?.type === 'open' && legalStore.legalLorDetailData.state === 'ready' && 'ready',
    onUpdateId?.type === 'open' && legalStore.legalLorDetailData.state !== 'ready' && legalStore.legalLorDetailData.state !== 'error' && 'loading',
  ].filter((n) => !!n)[0] as any;

  const onSubmitType = (submitType: 'submit-draft' | 'submit-publish'): 'published' | 'updated' | 'new' => {
    return [
      submitType === 'submit-draft' && onUpdateId?.type === 'open' && 'updated',
      submitType === 'submit-draft' && onUpdateId?.type === 'close' && 'new',
      submitType === 'submit-publish' && onUpdateId?.type === 'open' && 'published',
      submitType === 'submit-publish' && onUpdateId?.type === 'close' && 'published',
    ].filter((n) => !!n)[0] as any;
  };

  const Render = () => {
    return (
      <Box className="ml-auto">
        <Button
          className="w-28"
          variant="contained"
          onClick={handleClickOpen('body')}
          startIcon={<Icon icon="plus" color="#ffffff" viewBox="0 0 512 512" size={18} />}
        >
          Add
        </Button>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'} scroll={scroll}>
          <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
            <Typography className="font-medium text-2xl">{updateId?.type === 'open' ? <>Update LOR</> : <>Add new LOR</>}</Typography>
          </DialogTitle>
          <DialogContent className="p-0 border-0" dividers={scroll === 'paper'} sx={{ overflowX: 'hidden' }}>
            <LegalForm
              relatedBuData={cloneDeep(legalStore.legalBuRelatedFilter?.data?.data || [])}
              editData={legalStore.legalLorDetailData?.data?.data}
              editUid={onUpdateId?.uid}
              {...({ store: { setLegalFileUpload: props.store.setLegalFileUpload, setLegalFileRemove: props.store.setLegalFileRemove } } as any)}
              // {...(props as any)}
              // if we have uid we need to wait for data to load, so need to display loading icon
              loadStatus={loadStatus}
              mode={onUpdateId?.type === 'open' ? 'edit' : 'create'}
              onClose={handleClose}
              onSubmit={(data, submitType, uid) => {
                if (!isSuperAdmin(currentUser.userProfile)) {
                  Logger(['[CreateUpdateLegalModal][no access]'], 'warn');
                  return;
                }
                // Logger(['[CreateUpdateLegalModal][onSubmit]', data, submitType, uid]);
                // Logger(['[CreateUpdateLegalModal][onSubmit] 2', onSubmitType(submitType)]);
                const type = onSubmitType(submitType);
                legalStore.setLegalLorUpdateCreate(data, 'NO_STORE_UPDATE', null as any, (ok) => {
                  setUpdateId({ type: 'close', uid: null as any });
                  setOpen(false);

                  delay(200).then(() => {
                    if (type === 'published') {
                      if (ok) props.store.notify.onStatusChange('published');
                      else props.store.notify.onStatusChange('publish-error');
                    }
                    if (type === 'updated') {
                      if (ok) props.store.notify.onStatusChange('updated');
                      else props.store.notify.onStatusChange('update-error');
                    }
                    if (type === 'new') {
                      if (ok) props.store.notify.onStatusChange('created');
                      else props.store.notify.onStatusChange('create-error');
                    }
                    // NOTE: to recall API chart after create/update/published success
                    if (ok) reloadChart();
                    props.store.resetStatus('legal/lor/detail');
                    // NOTE: to reset all state after submit success
                    handleClose();
                  });
                });
              }}
              formStatus={open}
            />
          </DialogContent>
        </Dialog>
      </Box>
    );
  };
  return <Render />;
}

export default CreateUpdateLegalModal;
