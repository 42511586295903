import { AzureAuthProviderProps, IncidentStore, PropDefaults } from '@/src/types';
import { Box, Typography } from '@mui/material';
import { Icon } from '@/src/components';
interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export default function PartThreeReport(props: IncidentProps & IncidentStore) {
  // const { history, detailId, store } = props;
  const { store } = props;
  const {
    // incidentBusinessUnitsFilter,
    // // incidentCreateUpdateData,
    // incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    // setIncidentDetail,
    incidentDetailData,
  } = store;
  const injuredData = incidentDetailData?.data?.data?.injured;
  return (
    <>
      <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
        <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
        Injured/Illness person data
      </Typography>
      <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:mt-5 bg-white">
        <Box className="bg-gradient-to-r from-blue-600 to-blue-100 px-4 py-3 xl:py-4 xl:pl-10">
          <Typography className="text-white text-base">Injury Type</Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="lg:pl-6 w-full">
            {!injuredData?.injuryTypes?.length ? (
              <>-</>
            ) : (
              <>
                {(injuredData?.injuryTypes || []).map((injuryTypes: any, index: number) => {
                  return index === 0 ? injuryTypes.label : ', ' + injuryTypes.label;
                })}
                {injuredData?.injuryTypeOther ? `, Other (${injuredData?.injuryTypeOther})` : ''}
              </>
            )}
          </Typography>
        </Box>
        <Box className="bg-gradient-to-r from-blue-600 to-blue-100 px-4 py-3 xl:py-4 xl:pl-10">
          <Typography className="text-white text-base">Body Type - Right</Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="lg:pl-6 w-full">
            {!injuredData?.bodyRightTypes?.length ? (
              '-'
            ) : (
              <>
                {(injuredData?.bodyRightTypes || []).map((bodyRightTypes: any, index: number) => {
                  return index === 0 ? bodyRightTypes.label : ', ' + bodyRightTypes.label;
                })}
              </>
            )}
          </Typography>
        </Box>
        <Box className="bg-gradient-to-r from-blue-600 to-blue-100 px-4 py-3 xl:py-4 xl:pl-10">
          <Typography className="text-white text-base">Body Type - Left</Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="lg:pl-6 w-full">
            {!injuredData?.bodyLeftTypes?.length ? (
              '-'
            ) : (
              <>
                {' '}
                {(injuredData?.bodyLeftTypes || []).map((bodyLeftTypes: any, index: number) => {
                  return index === 0 ? bodyLeftTypes.label : ', ' + bodyLeftTypes.label;
                })}
              </>
            )}
          </Typography>
        </Box>
        <Box className="bg-gradient-to-r from-blue-600 to-blue-100 px-4 py-3 xl:py-4 xl:pl-10">
          <Typography className="text-white text-base">Specific body Part</Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Head and neck:
          </Typography>
          <Typography className="lg:pl-6 w-full">
            {!injuredData?.injuryTypes?.length ? (
              '-'
            ) : (
              <>
                {(injuredData?.headNeckParts || []).map((headNeckParts: any, index: number) => {
                  return index === 0 ? headNeckParts.label : ', ' + headNeckParts.label;
                })}
              </>
            )}
          </Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Upper limbs:
          </Typography>
          <Typography className="lg:pl-6 w-full">
            {!injuredData?.upperLimbParts?.length ? (
              '-'
            ) : (
              <>
                {(injuredData?.upperLimbParts || []).map((upperLimbParts: any, index: number) => {
                  return index === 0 ? upperLimbParts.label : ', ' + upperLimbParts.label;
                })}
              </>
            )}
          </Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Lower limbs:
          </Typography>
          <Typography className="lg:pl-6 w-full">
            {!injuredData?.lowerLimbParts?.length ? (
              '-'
            ) : (
              <>
                {(injuredData?.lowerLimbParts || []).map((lowerLimbParts: any, index: number) => {
                  return index === 0 ? lowerLimbParts.label : ', ' + lowerLimbParts.label;
                })}
              </>
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
