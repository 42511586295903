/* eslint-disable no-debugger */
import React, { Component } from 'react';
import { msalApp, requiresInteraction, useRedirectFlow, GRAPH_REQUESTS } from './auzure.auth.utils';
import { Account, AuthenticationParameters } from 'msal';
import { ENVS_CONFIG } from '@/constants/index';
import { AuthCache } from 'msal/lib-commonjs/cache/AuthCache';
import { AzureAccountStatus, EmployeeSchema, OverrideAccessRoute } from '@/src/types';
import { Logger } from '@/logger/index';
import { cookieOptions, CookieService, delay } from '@/src/utils';
import { AuthLoginPost, AuthLogoutPost } from '../http';
import { errorHandler } from '@/src/utils/axios';
import { AuthVerifyGet } from '../http/auth/auth.verify';
// import { useLocation, useNavigate } from 'react-router-dom';
import { BrowserHistory, createBrowserHistory } from 'history';
// import { any } from 'prop-types';
// {useRedirectFlow} => If you support IE, our recommendation is that you sign-in using Redirect APIs
// reset state: https://stackoverflow.com/questions/46617980/how-to-reload-a-page-state-in-a-react-app

const overrideAccessRoutes: OverrideAccessRoute[] = [
  { route: 'email', access: null, index: 0 },
  { route: '404', access: null, index: 1 },
  { route: '500', access: null, index: 2 },
];

export default (App: any) =>
  class AuthProvider extends Component {
    authCache = new AuthCache(ENVS_CONFIG.CLIENT_ID as any, 'sessionStorage', true);
    cookieService: CookieService = new CookieService(null, cookieOptions() as any);
    history: BrowserHistory = createBrowserHistory();
    _bypass: boolean = false;

    constructor(props: any) {
      super(props);
      this._bypass = this.cookieService.$get('bypass') === '1';

      this.state = {
        history: this.history,
        overrideAccessRoutes,
        msalApp: msalApp,
        account: null,
        error: null,
        emailMessages: null,
        /** we can use this status without reloading accountStatus> which would make app to go in loading state */
        hotUpdate: 'initial', // loading or ready
        accountStatus: this.includesByPass() ? 'no-access' : 'initial',
        /** get user provide detail from function api /login or /verify */
        userProfile: null,
        cookies: {
          jwt: null,
          accessToken: null,
        },
        userOptions: null,
      };
      // SAVE ANY initial url we tried to access before application was authenticated
      this.checkSetRedirectUrl('set');

      if (!sessionStorage.getItem(this.stateKey)) {
        sessionStorage.setItem(this.stateKey, 'initial');
        this.setState({
          accountStatus: 'initial',
        });
      }

      // on update is called when cookie is deleted
      this.cookieService.$onUpdated((opt) => {
        if (opt.name === 'jwt' || opt.name === 'accessToken') {
          const stateCookies: any = (this.state as any).cookies;
          // first set initial values and update with latest
          this.setState({
            cookies: {
              ...stateCookies,
              [opt.name]: opt.value,
            },
          });
        }
      });
    }

    /**
     * if user was not logged in there is no easy way to redirect to last ask url
     * - check user session, if not set store last asking url
     * - clear last asking url once user logged in
     */
    checkSetRedirectUrl(type: 'set' | 'clear' | 'get' = 'set'): string | null {
      const uid = ENVS_CONFIG.CLIENT_ID + 'last_url';
      const account = msalApp.getAccount();
      // ONLY set when  account is not ready, this indicates we tried to access url before being verified
      if (!account && type === 'set') {
        // set both in case one supported
        sessionStorage.setItem(uid, window.location.href);
        localStorage.setItem(uid, window.location.href);
        return null;
      }

      if (type === 'clear') {
        if (account) {
          sessionStorage.removeItem(uid);
          localStorage.removeItem(uid);
        }

        return null;
      }
      // get the last url we tried to access before we were verified
      if (type === 'get') {
        return sessionStorage.getItem(uid) || localStorage.getItem(uid);
      } else return null;
    }

    /**
     *
     * Azure AD shared urlRedirect state param
     */
    get azureSharedStatePathV2(): string {
      // const history: BrowserHistory = this.history;

      try {
        //  encodeURIComponent(new URL(history.location.pathname, ENVS_CONFIG.REACT_APP_BASE_URL).toString()
        const pthEncoded = encodeURIComponent(window.location.href).toString();
        return pthEncoded;
      } catch (err) {
        return null as any;
      }
    }

    includesByPass() {
      let noAuth = false;

      for (const routeType of overrideAccessRoutes) {
        if (routeType.access === 'allow') continue;
        if (this.history.location.pathname.includes(routeType.route)) {
          noAuth = true;
        }
      }
      return noAuth || this.allowedRouteList.length > 0;
    }

    /**
     * Check overrideAccessRoute conditions
     * - no authentication is required if OverrideAccessRoute is matched
     */
    byPassAuth(): boolean {
      let noAuth = false;
      const state: any = this.state;
      if (!state.history) return false;
      const currentHistory: BrowserHistory = state.history;
      let updated = false;
      const overrideAccessRoutes: OverrideAccessRoute[] = (this.state as any).overrideAccessRoutes;
      for (const routeType of overrideAccessRoutes) {
        if (routeType.access === 'allow') continue;
        if (currentHistory.location.pathname.includes(routeType.route)) {
          const d = overrideAccessRoutes.map((n) => {
            if (n.index === routeType.index) {
              routeType.access = 'allow';
              n = routeType as any;
              updated = true;
              noAuth = true;
            }
            return n;
          });
          if (updated) {
            this.setState({
              overrideAccessRoutes: d,
            });
          }
        }
      }
      return noAuth || this.allowedRouteList.length > 0;
    }

    get allowedRouteList(): OverrideAccessRoute[] {
      const _overrideAccessRoutes: OverrideAccessRoute[] = (this.state as any)?.overrideAccessRoutes;
      if (!_overrideAccessRoutes) return [];
      return _overrideAccessRoutes.filter((n) => n.access === 'allow');
    }

    async acquireToken(request: AuthenticationParameters, redirect: boolean) {
      if ((this.state as any).accountStatus === 'no-access') {
        return Promise.resolve(undefined);
      }

      return (
        msalApp
          .acquireTokenSilent(request)
          .catch((error) => {
            if (requiresInteraction(error.errorCode)) {
              return redirect
                ? msalApp.acquireTokenRedirect({
                    ...request,
                    redirectUri: ENVS_CONFIG.REACT_APP_BASE_URL as any,
                  })
                : msalApp
                    .acquireTokenPopup(request)
                    .then((n) => {
                      return n;
                    })
                    // eslint-disable-next-line handle-callback-err
                    .catch((err) => {
                      return undefined as any;
                    });
            } else Logger(['[acquireToken]', 'Non-interactive error', error], 'error');
            return undefined as any;
          })
          // eslint-disable-next-line handle-callback-err
          .catch((err) => {
            return undefined as any;
          })
      );
    }

    get stateKey() {
      return `${ENVS_CONFIG.CLIENT_ID}:azure.state.login`;
    }

    get accountStatus(): AzureAccountStatus {
      const state: any = this.state;
      const accountStatus = state?.accountStatus;
      return (sessionStorage.getItem(this.stateKey) || accountStatus || '') as AzureAccountStatus;
    }

    async accountLogin(redirect = true) {
      if ((this.state as any).accountStatus === 'no-access') {
        return Promise.resolve(undefined);
      }

      if (['loading', 'ready'].indexOf(this.accountStatus) !== -1) {
        return;
      } else {
        sessionStorage.setItem(this.stateKey, 'loading');
        this.setState({
          accountStatus: 'loading',
        });
      }

      if (!msalApp.getAccount()) {
        return msalApp.loginRedirect({
          ...GRAPH_REQUESTS.LOGIN,
          redirectUri: (ENVS_CONFIG.REACT_APP_BASE_URL + `${ENVS_CONFIG.REDIRECT_URI_ENDPOINT}`) as any,
        });
      } else return false as any;
    }

    /**
     * @accountLogout
     * - logout from application, access to azure and functions api will be removed
     */
    async accountLogout() {
      if ((this.state as any).accountStatus === 'no-access') {
        return Promise.resolve(undefined) as any;
      }
      const jwt = this.cookieService.$get('jwt');
      const expToken = this.cookieService.$get('jwt_expired');
      if (jwt) {
        try {
          // check if we already logged out before if jwt_expired was set use that instead
          const logoutData = await AuthLogoutPost('auth/verify', { jwt: expToken || jwt });
          const expiredToken = logoutData.token;
          this.cookieService.$renew('jwt_expired', expiredToken);
        } catch (err) {
          Logger(['[accountLogout][AuthLogoutPost]', err], 'error');
        }
      }

      try {
        // the process to logout
        // first to clear sessions and cookies
        // then logout
        sessionStorage.removeItem(this.stateKey);
        this.cookieService.$removeMany(['accessToken', 'jwt', 'bypass']);
        sessionStorage.clear();
        await delay(150);
        // this actions redirects away from our application
        msalApp.logout();
      } catch (err) {
        Logger(['[azure][accountLogout]', err], 'error');
      }
    }

    componentDidUpdate() {
      this.byPassAuth();
    }

    async componentDidMount() {
      if (this.byPassAuth()) {
        Logger(['[byPassAuth][noAuth][allowedRouteList]', this.allowedRouteList]);

        this.setState({
          accountStatus: 'no-access',
        });
        return;
      }

      msalApp.handleRedirectCallback((error) => {
        if (error) {
          const errorMessage = error.errorMessage ? error.errorMessage : 'Unable to acquire access token.';

          // setState works as long as navigateToLoginRequestUrl: false
          this.setState({
            error: errorMessage,
          });
          sessionStorage.setItem(this.stateKey, 'error');
          this.setState({
            accountStatus: 'error',
          });
        }
      });

      const account = msalApp.getAccount();
      if (account) {
        this.setState({
          account,
        });

        try {
          const tokenResponse = await this.acquireToken(GRAPH_REQUESTS.LOGIN, useRedirectFlow);
          await this.acquireAccount(tokenResponse?.accessToken);
        } catch (err) {
          Logger(['[acquireToken]', err], 'error');
        }
      } else if (this._bypass) {
        this.setState({
          account: new Account('', '', '', '', { '': '' }, '', ''),
        });
        await this.acquireAccount('bypass');
      }
    }

    /**
     * Conditionally Reverify user if you have self downgraded and update current user state
     * - will only call if employeeId match loggedin user
     *
     */
    async reVerify(employeeId: string): Promise<boolean> {
      if ((this.state as any).accountStatus === 'no-access') {
        Logger(['[reVerify][accountStatus]', 'no-access'], 'warn');
        return Promise.resolve(false) as any;
      }
      if (!employeeId) return false as any;
      const userProfile: EmployeeSchema = (this.state as any)?.userProfile;

      const loggedInUser = userProfile && employeeId.indexOf(userProfile.employeeId) !== -1;
      if (!loggedInUser) {
        return false;
      } else {
        Logger(['[reVerify]', 'updating my userProfile information', employeeId], 'attention');
      }

      try {
        const jwt = this.cookieService.$get('jwt');
        // const acToken = this.cookieService.$get('accessToken');
        const verifyUserData = await AuthVerifyGet('auth/verify', { jwt: jwt });
        this.cookieService.$remove('jwt_expired');

        this.setState({
          hotUpdate: 'updated',
          userProfile: verifyUserData.data?.profile,
          userOptions: {
            // incidentReports: verifyUserData.data?.incidentReports || null,
          },
        });

        delay(100).then(() => {
          this.setState({
            hotUpdate: 'initial',
          });
        });

        return true;
      } catch (err) {
        Logger(['[reVerify]', err], 'error');

        this.setState({
          error: 'didnt match any accessBy conditions ?',
          accountStatus: 'error',
        });

        return false;
      }
    }

    /**
     * Check if user is login in for first time, or if they already have jwt
     */
    async acquireAccount(accessToken: string): Promise<void> {
      if (this.byPassAuth()) {
        return;
      }
      if (!accessToken) throw new Error('No accessToken provided!');

      if (this._bypass) {
        this.cookieService.$renew('accessToken', 'bypass');
      } else {
        this.cookieService.$renew('accessToken', accessToken);
      }

      const jwt = this.cookieService.$get('jwt');
      const acToken = this.cookieService.$get('accessToken');
      Logger(['[navigateAccess][accessToken]', acToken], 'log');
      Logger(['[navigateAccess][jwt]', jwt], 'log');

      type AccessBy = 'LOGIN' | 'VERIFY';
      const accessBy: AccessBy = [!this._bypass && !jwt && 'LOGIN', (this._bypass || jwt) && 'VERIFY'].filter((n) => !!n)[0];

      sessionStorage.setItem(this.stateKey, 'loading');
      this.setState({
        accountStatus: 'loading',
      });

      try {
        let ok = false;
        switch (accessBy) {
          case 'LOGIN': {
            const userTokenData = await AuthLoginPost('auth/login', { accessToken });
            this.cookieService.$remove('jwt_expired');

            this.cookieService.$renew('jwt', userTokenData.data.token);
            Logger(['[AuthLoginPost]', userTokenData.data], 'log');
            ok = true;

            this.setState({
              userProfile: userTokenData.data?.profile,
              userOptions: {
                // incidentReports: userTokenData.data?.incidentReports || null,
              },
            });

            break;
          }
          case 'VERIFY': {
            const verifyUserData = await AuthVerifyGet('auth/verify', { jwt: jwt });
            this.cookieService.$remove('jwt_expired');
            Logger(['[AuthVerifyGet]', verifyUserData.data], 'log');
            ok = true;
            this.setState({
              userProfile: verifyUserData.data?.profile,
              userOptions: {
                //  incidentReports: verifyUserData.data?.incidentReports || null,
              },
            });
            break;
          }
          default: {
            Logger(['[accessBy]', `no condition met accessBy: ${accessBy}`], 'error');
            this.cookieService.$remove('bypass');
          }
        }

        if (ok) {
          sessionStorage.setItem(this.stateKey, 'ready');
          this.setState({
            accountStatus: 'ready',
          });
        } else {
          sessionStorage.setItem(this.stateKey, 'error');
          this.cookieService.$remove('bypass');
          this.setState({
            accountStatus: 'error',
            error: 'didnt match any accessBy conditions ?',
          });
        }
      } catch (err) {
        sessionStorage.setItem(this.stateKey, 'error');
        this.setState({
          error: err,
          accountStatus: 'api-error',
        });

        // remove current token
        this.cookieService.$remove('jwt');
        this.cookieService.$remove('bypass');

        Logger([`[navigateAccess][${accessBy}]`, `[functions api][post][error]`, errorHandler(err)], 'error');
      }
    }

    render() {
      const state: any = this.state;
      if (state.error) {
        Logger(['[azure][state][error]', state.error], 'error');
      }
      // alert(this.history.location.pathname);
      // debugger;

      const azure = {
        ...this.props,
        history: state.history,
        overrideAccessRoutes: state.overrideAccessRoutes,
        cookieService: this.cookieService,
        accountStatus: state.accountStatus,
        hotUpdate: state.hotUpdate,
        account: state.account,
        msalApp: state.msalApp,
        emailMessages: state.emailMessages,
        error: state.error,
        userProfile: state.userProfile,
        userOptions: state.userOptions,
        accountLogin: () => this.accountLogin(true),
        accountLogout: () => this.accountLogout(),
        reVerify: (employeeId: string): Promise<boolean> => this.reVerify(employeeId),
        checkSetRedirectUrl: (type: 'set' | 'clear' | 'get' = 'set'): string | null => {
          return this.checkSetRedirectUrl(type);
        },
      };
      return (
        <App
          // onAppLoad={(history: BrowserHistory) => {
          //   this.setState({
          //     currentHistory: history,
          //   });
          // }}
          azure={azure}
        />
      );
    }
  };
