import { ContextProviderProps, StoreStateDefaultV3, StoreStateV3 } from '@/types/store';
import { useState, useEffect, useContext } from 'react';
import { BusinessUnitsGet, ManHourListGet, ManHourUpdate, ManHourCharsGet } from '@/services/http';
import { ManhoursContext } from './context';
import {
  ManhoursStore,
  HttpTokens,
  GetManHourMonthList,
  GetBusinessUnits,
  QueryManHourList,
  QueryManHourUpdate,
  ManHourBodyData,
  PostManHourMonth,
  GetManHourCharts,
  GetManHourChartsQuery,
  ManHourChartBaseSchema,
} from '@/src/types';
import { getJwtToken } from '@/src/utils';
import { Logger } from '@/logger/index';

export const useManhoursContextInitialState = () => useContext(ManhoursContext);

const ManhoursContextProvider = (props: ContextProviderProps) => {
  const { children } = props;
  const contextInitialState = useManhoursContextInitialState();
  const tokens: HttpTokens = { jwt: getJwtToken() };
  const [manhoursListData, setManhoursList] = useState<StoreStateDefaultV3<GetManHourMonthList>>(contextInitialState.store.manhoursListData);
  const [manhoursBusinessUnitsFilter, setManhoursBusinessUnitsFilter] = useState<StoreStateDefaultV3<GetBusinessUnits>>(
    contextInitialState.store.manhoursBusinessUnitsFilter
  );

  const [manHourUpdateData, setManHourUpdate] = useState<StoreStateDefaultV3<PostManHourMonth>>(contextInitialState.store.manHourUpdateData);

  const [manHoursChartData, setManHoursChart] = useState<StoreStateDefaultV3<GetManHourCharts>>(contextInitialState.store.manHoursChartData);

  //
  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const store: ManhoursStore = {
    store: {
      manhoursListData,
      manHourUpdateData,
      manhoursBusinessUnitsFilter,
      manHoursChartData,
      resetStatus: (apiName: string) => {
        if (apiName === 'incidents/man-hours') {
          setManhoursList({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'incidents/man-hours/update') {
          setManHourUpdate({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'incidents/man-hours/charts') {
          setManHourUpdate({ data: undefined as any, state: 'initial' });
        }
      },
      resetState: (apiName: string, state: StoreStateV3) => {},
      setManhoursList: (params: QueryManHourList) => {
        if (!isMounted) return;
        setManhoursList({ data: undefined as any, state: 'loading' });

        ManHourListGet('incidents/man-hours', params, tokens)
          .then((data) => {
            if (isMounted) setManhoursList({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setManhoursList({ data: undefined as any, error, state: 'error' });
          });
      },

      /*
       * NOTE: this function for Business Units filter in Incident Page
       * it use shared API GET/business-units
       */
      setManhoursBusinessUnitsFilter: () => {
        if (!isMounted) return;
        setManhoursBusinessUnitsFilter({ data: undefined as any, state: 'loading' });
        BusinessUnitsGet('business-units', tokens)
          .then((data) => {
            if (isMounted) setManhoursBusinessUnitsFilter({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setManhoursBusinessUnitsFilter({ data: undefined as any, error, state: 'error' });
          });
      },

      setManHourUpdate: (id: string, query: QueryManHourUpdate, body: ManHourBodyData, action: 'update' | 'new' = 'new', done?: (ok: boolean) => void) => {
        if (!isMounted) return;
        if (action === 'new') setManHourUpdate({ data: undefined as any, state: 'loading' });

        ManHourUpdate('incidents/man-hours/update', id, query, body, tokens)
          .then((data) => {
            if (!isMounted) return;

            // console.log('ManHourUpdate/data', data);
            setManHourUpdate({ data, state: 'ready' });
            manhoursListData.data.calculations = data.calculations;
            const d = manhoursListData.data.data.map((n) => {
              if (n.id === data.data.id) {
                n = data.data;
                Logger(['[ManHourUpdate][manhoursListData]']);
              }
              return n;
            });
            manhoursListData.data.data = d;
            setManhoursList(manhoursListData);
            if (done) done(true);
          })
          .catch((error) => {
            if (!isMounted) return;
            setManHourUpdate({ data: undefined as any, error, state: 'error' });
            if (done) done(false);
          });
      },
      setManHoursChart: (params?: GetManHourChartsQuery, action?: 'update' | 'new', done?: (ok: boolean, data?: ManHourChartBaseSchema) => void) => {
        if (!isMounted) return;
        if ((!action || action === 'new') && !done) setManHoursChart({ data: undefined as any, state: 'loading' });
        ManHourCharsGet('incidents/man-hours/charts', tokens, params)
          .then((data) => {
            if (!isMounted) return;
            if (done) {
              done(true, data.data);
              return;
            }
            setManHoursChart({ data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            if (done) {
              done(false, null as any);
              return;
            }
            setManHoursChart({ data: undefined as any, error, state: 'error' });
          });
      },
    },
  };

  return <ManhoursContext.Provider value={store}>{children}</ManhoursContext.Provider>;
};

const withManhoursContext = (Component: any) => {
  return function IncidentComponent(props: any) {
    return <ManhoursContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</ManhoursContext.Consumer>;
  };
};

export { ManhoursContextProvider, withManhoursContext };
