import React, { useEffect } from 'react';
import { Typography, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { AdminStore } from '@/src/types/store';
import { withAdminContext } from '@/store/admins.store';
import { toastMessages } from '@/src/static';
import { SnackBarAlert } from '@/src/components';

interface PropsDeleteAdminModal {
  title: string;
  state: 'INITIAL' | 'OPEN' | 'DELETE';
  description: string;
  [currentid: string]: any;
  setState: Function;
  loadlistpage: any;
}

function DeleteAdminModal(props: PropsDeleteAdminModal & AdminStore) {
  const { title, state, setState, description, store, currentid } = props;
  const { resDeletedEmployees, deletedEmployees } = store || {};

  const [opensSnackbar, setOpensSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState<'error' | 'success'>('success');

  const handleOpenDelete = () => {
    deletedEmployees(currentid);
  };

  useEffect(() => {
    if (resDeletedEmployees.state === 'initial' || resDeletedEmployees.state === 'loading') return;
    if (resDeletedEmployees.state === 'ready') {
      setSeverity('success');
    } else {
      setSeverity('error');
    }

    setOpensSnackbar(true);
    setState('DELETE');
  }, [resDeletedEmployees.data]);

  const handleClose = () => {
    setState('INITIAL');
  };

  return (
    <>
      <Dialog open={state === 'OPEN'} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
        <Typography variant="h2" className="p-0 mb-6">
          {title}
        </Typography>
        <DialogContent className="p-0 mb-6">
          <Typography id="transition-modal-description">{description}</Typography>
        </DialogContent>
        <DialogActions className="p-0 justify-start">
          <Button className="mr-1 w-28" variant="contained" color="error" onClick={handleOpenDelete}>
            Delete
          </Button>
          <Button className="w-28" variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <SnackBarAlert
        messages={{
          error: toastMessages.adminNotDeleted,
          success: toastMessages.adminDeleted,
        }}
        severity={severity}
        state={opensSnackbar as any}
        onClose={() => {
          setOpensSnackbar(false);
        }}
      />
    </>
  );
}

export default withAdminContext(DeleteAdminModal);
