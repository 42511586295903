// import { Logger } from '@/logger/index';
import { PropDefaults, AzureAuthProviderProps, IncidentStore } from '@/src/types';
// import { withCourseContext } from '@/store/index';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import Incidents from './index';
import LinearProgress from '@mui/material/LinearProgress';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { INCIDENT_PAGE_TITLE } from '@/src/static';

interface IncidentsProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

const IncidentsRouter: React.FC<IncidentsProps & IncidentStore> = (props) => {
  setDocTitle(INCIDENT_PAGE_TITLE);
  const { updateId, detailId } = useParams();
  const location = useLocation();
  const [mount, setMount] = useState<number>(0);
  const { store } = props;
  const isIncidentUpdateRoute = !!updateId && location.pathname.indexOf('/incidents/update') !== -1;
  const isIncidentDetailRoute = !!detailId && location.pathname.indexOf('/incidents/detail') !== -1;
  useEffect(() => {
    if (!mount) {
      setMount(1);
    }

    if (isIncidentUpdateRoute) {
      if (store.incidentDetailData.state === 'ready' && store.incidentDetailData.data?.data?.id !== updateId) {
        store.resetStatus('incidents/report/parts');
      }

      if (store.incidentCreateUpdateData.state === 'ready') {
        store.resetStatus('incidents/create-update');
      }
    }

    if (isIncidentDetailRoute) {
      if (store.incidentDetailData.state === 'ready' && store.incidentDetailData.data?.data?.id !== detailId) {
        store.resetStatus('incidents/report/parts');
      }
    }
  }, [setMount, mount]);
  // NOTE for every route change to child we need to reset store state because it contains data from page we visited previously

  const pageType: 'incidents' | 'incidents-detail' | 'incidents-create' | 'loading' | 'incidents-update' | 'incidents-man-hour' = [
    mount === 0 && 'loading',
    isIncidentDetailRoute && 'incidents-detail',
    !updateId && location.pathname.indexOf('/incidents/create') !== -1 && 'incidents-create',
    isIncidentUpdateRoute && 'incidents-update',
    !updateId && !detailId && location.pathname.indexOf('/incidents/man-hour') !== -1 && 'incidents-man-hour',
    !updateId && !detailId && location.pathname.indexOf('/incidents') !== -1 && 'incidents',
    // to add more later
  ].filter((n) => !!n)[0] as any;

  // console.log('location.pathname', location.pathname, detailId);
  switch (pageType) {
    case 'loading': {
      return <LinearProgress style={{ margin: '0px 24px 0px 24px' }} />;
    }
    case 'incidents': {
      return <Incidents {...(props as any)} />;
    }
    case 'incidents-detail': {
      const outletProps = {
        ...props,
        detailId,
      };
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }
    case 'incidents-man-hour': {
      // we are passing IncidentsStore parent via context
      const outletProps = {
        // we are passing ManHourStore child via props, declared at App route
        ...props,
      };
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }

    case 'incidents-create': {
      const outletProps = {
        ...props,
      };
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }

    case 'incidents-update': {
      const outletProps = {
        ...props,
        updateId,
      };
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }

    default: {
      return <>No mach for incidents found</>;
    }
  }
};
export default IncidentsRouter;
