import { useState } from 'react';
import { CallBackFilterType } from '@/src/types';
import { SelectChangeEvent, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, SelectProps, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
    },
  },
};

interface FilterMultiSelectProp {
  // filterData: FilterDataType;
  filterData: any;
  selectedFilter: string[];
  isLoadingFilterData: boolean;
  dropdownSetting: {
    valueKey: string;
    labelKey: string;
    noSelectedText: string;
  };
  dropdownLabelConfig?: (_value?: any, _label?: string) => string;
  onCallBackFilter: (type: CallBackFilterType, payload?: any) => void;
  [name: string]: any;
}

export function FilterMultiSelect(props: FilterMultiSelectProp & SelectProps<string[]>) {
  const {
    filterData,
    selectedFilter,
    isLoadingFilterData,
    dropdownSetting,
    dropdownLabelConfig = (_value?, _label?) => _label,
    onCallBackFilter,
    className,
    sx,
    clearable,
  } = props;

  const [prevSelectedFilter, setPrevSelectedFilter] = useState<string[]>(selectedFilter);
  const [isOpenFilter, setOpenFilter] = useState<boolean>(false);

  const setOpenFilterEnhanced = (newState: boolean) => {
    setOpenFilter((prevState) => {
      if (prevState === false && newState === true) setPrevSelectedFilter(selectedFilter);
      else if (prevState === true && newState === false) {
        if (selectedFilter !== prevSelectedFilter) onCallBackFilter('finishSelectingFilter');
      }
      return newState;
    });
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedFilter>) => {
    const newValue = event.target.value as string[];
    onCallBackFilter('setSelectedFilter', newValue);
  };

  const handleOpen = () => {
    setOpenFilterEnhanced(true);
  };
  const handleClose = () => {
    setOpenFilterEnhanced(false);
  };

  const onClear = (e) => {
    e.preventDefault();
    onCallBackFilter('setSelectedFilter', []);
  };

  return (
    <div className={className}>
      <div className={`ss-select-multiple ${clearable ? 'clearable' : ''} ${clearable && selectedFilter && selectedFilter.length ? 'selected' : ''}`}>
        <Select
          sx={sx}
          multiple
          displayEmpty
          value={selectedFilter}
          onChange={handleChange}
          open={isOpenFilter}
          onOpen={handleOpen}
          onClose={handleClose}
          input={<OutlinedInput />}
          renderValue={(selectedValue) => {
            if (selectedValue.length === 0) return <span>{dropdownSetting.noSelectedText}</span>;
            return selectedValue
              .reduce((sumSelected: string[], eachSelected: string) => {
                const eachSelectedLabel: any = filterData.find((item: any) => item[dropdownSetting.valueKey] === eachSelected);
                return [...sumSelected, eachSelectedLabel[dropdownSetting.labelKey]];
              }, [])
              .join(', ');
          }}
          MenuProps={MenuProps}
        >
          {isLoadingFilterData ? (
            <MenuItem key="" value="">
              <ListItemText primary="Loading..." />
            </MenuItem>
          ) : (
            filterData.map((row: any, index: number) => (
              <MenuItem key={index} value={row[dropdownSetting.valueKey]}>
                <Checkbox checked={selectedFilter.indexOf(row[dropdownSetting.valueKey]) > -1} />
                <ListItemText primary={dropdownLabelConfig(row[dropdownSetting.valueKey], row[dropdownSetting.labelKey])} />
              </MenuItem>
            ))
          )}
        </Select>
        {clearable && selectedFilter && selectedFilter.length ? (
          <div className="icon">
            <IconButton aria-label="delete" size="small" onClick={onClear}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
