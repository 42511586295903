/* eslint-disable @typescript-eslint/no-use-before-define */

import { useEffect, useState, FC } from 'react';
import { Alert, Box, Button, IconButton, LinearProgress, Paper, Typography } from '@mui/material';
import { AzureAuthProviderProps, LegalStore, PropDefaults } from '@/src/types';
import { Navigate, NavLink, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { Icon, ProgressBarLegal, History, LegalEvaluationDetail, ReviewLegislationForm, LegalInitialDetail, TableHistoryLegals } from '@/src/components';
import { PAGE_NOT_FOUND_MESSAGE, PAGE_NO_PERMISSION_V2 } from '@/src/static';
import { Logger } from '@/logger/index';
import { checkParams, getClassNameLegalStatus, legalIsOverdue, userCanOnLegal } from '@/src/utils';
import { useGlobalContext } from '@/store/global.store';

interface LegalsProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

export const LegalDetail: FC<LegalsProps> = (props, context) => {
  const { detailId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const year = searchParams.get('year') || '';
  const [mount, setMount] = useState<number>(0);

  const { currentUser } = useGlobalContext();
  const contextProps: LegalsProps & LegalStore & { detailId: string } = useOutletContext();
  const { store: legalStore } = contextProps;

  const legalLorDetailData = legalStore?.legalLorDetailData?.data?.data;

  useEffect(() => {
    if (!mount) {
      if (legalStore?.legalLorDetailData?.state === 'initial' && !legalStore?.legalLorDetailData?.data?.data && detailId) {
        legalStore?.setLegalLorDetail(detailId, { year: year });
      }

      checkParams(searchParams, 'year', (d) => {
        setSearchParams(d);
      });

      setMount(1);
    }
    if (legalStore?.legalLorDetailData?.state === 'ready') {
      Logger(['[LegalDetail][legalLorDetailData]', legalStore?.legalLorDetailData]);
    }
    if (legalStore?.legalLorDetailData?.state === 'error') {
      Logger(['[LegalDetail][legalLorDetailData]', legalStore?.legalLorDetailData]);
    }
  }, [legalStore?.legalLorDetailData, mount, setMount]);

  useEffect(() => {
    if (legalStore.legalLorStartEvaData.state === 'ready' && legalStore.legalLorStartEvaData.data?.data) {
      legalStore.notify.onStatusChange('updated');
    }
  }, [legalStore.legalLorStartEvaData]);

  useEffect(() => {
    return () => {
      legalStore.resetStatus('legal/lor/detail');
      legalStore.resetStatus('legal/lor/evaluation');
      legalStore.resetStatus('legal/lor/progress');
      legalStore.resetStatus('legal/lor/start-eval');
      legalStore.resetStatus('legal/lor/history');
    };
  }, []);

  const lorState = legalStore?.legalLorDetailData.data?.data?.status;
  const canView = userCanOnLegal(currentUser, lorState as any, 'can_view', legalStore?.legalLorDetailData.data?.data);
  const canEvaluate = userCanOnLegal(currentUser, lorState as any, 'can_evaluate', legalStore?.legalLorDetailData.data?.data);

  const state: 'ready' | 'error' | 'loading' | 'deleted' | 'no_permission' = [
    !canView && legalStore?.legalLorDetailData?.state === 'ready' && 'no_permission',
    legalStore?.legalLorDetailData?.state === 'ready' && legalStore?.legalLorDetailData?.state === 'ready' && 'ready',
    ['loading', 'initial'].indexOf(legalStore?.legalLorDetailData?.state) !== -1 && 'loading',
    legalStore?.legalLorDetailData?.state === 'error' && 'error',
  ].filter((n) => !!n)[0] as any;

  switch (state) {
    case 'deleted': {
      return <Navigate to={`/legals`} />;
    }
    case 'error': {
      return <Alert severity="error">{PAGE_NOT_FOUND_MESSAGE}</Alert>;
    }
    case 'loading': {
      return <LinearProgress />;
    }
    case 'no_permission': {
      if (lorState === 1) {
        Logger(['[LEGAL][DETAIL][NO_PERMISSION]', `LOR for year:${year} status is in draft`], 'warn');
      }
      return <Alert severity="error">{PAGE_NO_PERMISSION_V2}</Alert>;
    }

    case 'ready': {
      return (
        <Paper elevation={0}>
          <Box className="flex items-center">
            <NavLink to={`/legals`}>
              <IconButton color="primary" aria-label="View details" className="" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
              </IconButton>
            </NavLink>
            <Typography className="flex text-xl md:text-[24px] lg:text-[28px] font-medium">
              {legalLorDetailData?.code ? legalLorDetailData?.code : ''}
              <span className={`py-[3px] px-2 text-sm my-auto font-normal rounded ml-3 ${getClassNameLegalStatus(legalLorDetailData as any)}`}>
                {legalIsOverdue(legalLorDetailData as any)
                  ? 'Overdue'
                  : legalLorDetailData?.status === 1
                  ? 'Draft'
                  : legalLorDetailData?.status === 2
                  ? 'Published'
                  : legalLorDetailData?.status === 3
                  ? 'In progress'
                  : legalLorDetailData?.status === 6
                  ? 'Evaluate'
                  : 'Completed'}
              </span>
            </Typography>
          </Box>
          <Paper elevation={0} className="flex bg-transparent mt-3">
            <Box className="w-full lg:max-w[calc(100% - 400px)]">
              <LegalInitialDetail store={legalStore} />
              {(legalLorDetailData?.status === 2 || legalLorDetailData?.status === 6) && canEvaluate && (
                <Box className="mt-9 flex">
                  <Button
                    disabled={legalStore.legalLorStartEvaData.state === 'loading'}
                    variant="contained"
                    onClick={() => {
                      legalStore.setLegalLorStartEva(detailId as any, { status: 3, evalStartYear: year }, (success) => {
                        if (success) legalStore.setLegalLorDetail(detailId as any, { year }, 'update', null as any, legalStore.legalEvaluationData.ref);
                      });
                    }}
                  >
                    Start evaluation
                  </Button>
                </Box>
              )}
              {legalLorDetailData?.status === 3 && canEvaluate && <ReviewLegislationForm {...(props as any)} {...(contextProps as any)} />}
              {legalLorDetailData?.status === 5 && <LegalEvaluationDetail store={legalStore} />}
              <TableHistoryLegals {...(contextProps as any)} detailId={detailId} year={year} canEvaluate={canEvaluate} />
            </Box>
            <Box className="bottom-0 overflow-y-auto overflow-x-hidden h-full w-full sticky ml-6 max-w-[374px]">
              <History {...(contextProps as LegalStore)} />
              <ProgressBarLegal {...(contextProps as LegalStore)} />
            </Box>
          </Paper>
        </Paper>
      );
    }

    default: {
      return <></>;
    }
  }
};
