import React from 'react';
import { Box, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Typography, TextField } from '@mui/material';
import { IncidentFormStoreParts } from '@/src/types';
import { initialReport as initialReportStaticData } from '@/src/static/incidents/form.data';
import { textFieldProps } from '@/utils/form/index';

interface PartOneSectionOneProps {
  formStore: IncidentFormStoreParts;
  isReadyToSaveDraftIncident: boolean;
}

export const PartOneSectionOne = (props: PartOneSectionOneProps) => {
  const {
    formStore: { formStore },
    isReadyToSaveDraftIncident,
  } = props;
  const initialReportData = formStore.store.formData.initialReport?.data || {};

  const handleChangeInitialReportData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('initialReport', 'updated').setup(value, keyName, subKeyName);
  };

  const handleChangeCheckboxDamageType = (checked: any, valueCheckbox: number) => {
    let arrayCheckbox: number[] = [];
    if (checked) {
      arrayCheckbox = initialReportData?.damageType ? [...initialReportData?.damageType, valueCheckbox] : [valueCheckbox];
    } else {
      arrayCheckbox = initialReportData?.damageType.filter((item) => item !== valueCheckbox);
    }
    arrayCheckbox.sort((a, b) => a - b);
    handleChangeInitialReportData(arrayCheckbox.length > 0 ? arrayCheckbox : null, 'damageType');
  };

  return (
    <Card variant="outlined" className=" rounded-2xl p-6 mb-6">
      <Box className="grid grid-cols-4 gap-6">
        <Box className="col-span-2">
          <FormControl className="w-full">
            <TextField
              label="Name"
              required
              error={!isReadyToSaveDraftIncident && !initialReportData?.name}
              onBlur={(e) => handleChangeInitialReportData((e.target.value || '').trim() || null, 'name')}
              {...textFieldProps(initialReportData, 'name')}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl className="w-full">
            <TextField
              label="Age"
              type="number"
              onBlur={(e) => handleChangeInitialReportData(parseInt(e.target.value) || null, 'age')}
              {...textFieldProps(initialReportData, 'age')}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl className="w-full">
            <TextField
              label="Employee ID"
              onBlur={(e) => handleChangeInitialReportData(e.target.value || null, 'employeeId')}
              {...textFieldProps(initialReportData, 'employeeId')}
            />
          </FormControl>
        </Box>
        <Box className="col-span-2 ">
          <FormControl className="w-full">
            <TextField
              label="Position"
              onBlur={(e) => handleChangeInitialReportData(e.target.value || null, 'position')}
              {...textFieldProps(initialReportData, 'position')}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl className="w-full">
            <TextField
              label="Job experience"
              onBlur={(e) => handleChangeInitialReportData(e.target.value || null, 'jobExperience')}
              {...textFieldProps(initialReportData, 'jobExperience')}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl className="w-full">
            <TextField
              label="Phone"
              onBlur={(e) => handleChangeInitialReportData(e.target.value || null, 'phone')}
              {...textFieldProps(initialReportData, 'phone')}
            />
          </FormControl>
        </Box>
        <Box className="col-span-4">
          <FormControl component="fieldset" variant="standard">
            <Typography
              className="font-medium mb-3"
              sx={{ color: !isReadyToSaveDraftIncident && (!initialReportData?.damageType || initialReportData?.damageType?.length === 0) ? 'red' : 'inherit' }}
            >
              Injury or occupational or property damage illness classification:
              <span className="text-red-500">{' *'}</span>
            </Typography>
            <FormGroup row>
              {initialReportStaticData.incidentDamageType?.map((injury) => (
                <Box className="flex flex-col relative w-36 h-36 mr-4" key={injury.value + injury.label}>
                  <img
                    src={require(`@/assets/icons/${injury.iconPath}`)}
                    alt={injury.label}
                    width={injury.value === 1 ? 51 : injury.value === 2 ? 45 : 43}
                    className="m-auto pb-2"
                  />
                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Checkbox
                        onChange={(e) => handleChangeCheckboxDamageType(e.target.checked, injury.value)}
                        checked={initialReportData?.damageType?.includes(injury.value) || false}
                      />
                    }
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                    label={injury.label}
                    className="checkBoxCustom"
                  />
                </Box>
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </Card>
  );
};
