export const trainingChartsOption = {
  title: {
    show: false,
    textStyle: {
      color: 'grey',
      fontSize: 17,
    },
    text: 'No data',
    left: '45%',
    top: 'center',
  },
  xAxis: {
    axisLabel: {
      show: true,
      interval: 0,
      // rotate: 45,
    },
    splitLine: { show: true },
    // data: ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    data: [],
  },
  yAxis: {
    type: 'value',
    //  name: 'y',
    minorSplitLine: {
      show: true,
    },
  },
  legend: {
    data: ['Classroom', 'Online', 'E-learning'],
    itemHeight: 6,
    itemWidth: 21,
    itemGap: 22,
    textStyle: {
      color: 'rgba(0, 0, 0, 1)',
      fontSize: 13,
    },
    top: '10%',
    selectedMode: false,
  },
  grid: {
    top: '21%',
  },
  tooltip: {},
  series: [
    {
      symbolSize: 20,
      data: 'scatter',
      type: 'scatter',
      itemStyle: { color: 'black' },
    },
    {
      data: [],
      type: 'bar',
      name: 'E-learning',
      id: 'e-learning',
      stack: 'total',
      itemStyle: {
        color: '#A368DC',
      },
    },
    {
      data: [],
      type: 'bar',
      name: 'Online',
      id: 'online',
      stack: 'total',
      itemStyle: {
        color: '#6872DC',
      },
    },
    {
      data: [],
      type: 'bar',
      name: 'Classroom',
      id: 'classroom',
      stack: 'total',
      itemStyle: {
        color: '#68B7DC',
      },
    },
  ],
};
