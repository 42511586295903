/** declare only http service related requests */

import { HttpTokens, PostProfileAttendeeRequest, ProfileAttendeeRequestBody } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Approve attendee related training directly from profile page by accept/reject cta
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const ProfileAttendeeRequestPost = (
  /**
   * @example api: `employees/profile/my-training/{employeeId}/request/{attendeeId}`
   */
  prefix: string = 'employees/profile/my-training',
  employeeId: string,
  attendeeId: string,
  body: ProfileAttendeeRequestBody,
  tokens: HttpTokens,
  hardRejection = true
): Promise<PostProfileAttendeeRequest> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };
  console.log('calling ?', `${prefix}/${employeeId}/request/${attendeeId}`);
  return instance.client
    .post(`${prefix}/${employeeId}/request/${attendeeId}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostProfileAttendeeRequest>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostProfileAttendeeRequest>;
};
