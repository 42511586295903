/** declare only http service related requests */

import { EmployeeSearchQuery, GetEmployeeBySearch, HttpTokens } from '@/src/types';
import { paramsQueryString } from '@/src/utils';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description get employee by search __( this is no the same as employees/filter! )__
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const EmployeeSearchGet = (
  prefix: string = 'employees/search',
  params: EmployeeSearchQuery,
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetEmployeeBySearch> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);
  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend

  const queryUri = paramsQueryString(prefix, params);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetEmployeeBySearch>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetEmployeeBySearch>;

  // const testError = new Promise<GetEmployeeBySearch>((resolve, reject) => {
  //   const x = true;
  //   if (x) {
  //     setTimeout(() => {
  //       reject(new Error('testError'));
  //     }, 2000);
  //   } else {
  //     resolve({
  //       status: { code: 200, message: 'test Error' },
  //       data: [],
  //     });
  //   }
  // });
  // return testError;
};
