import { PostIncidentReportApproval, HttpTokens, BodyIncidentReportApproval } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Approve incident by employee, by incidentId with special roleId
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const IncidentReportApproval = (
  prefix: string = 'incidents/report/approval',
  incidentId: string,
  body: BodyIncidentReportApproval,
  tokens: HttpTokens,
  hardRejection = true
): Promise<PostIncidentReportApproval> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  console.log('[incidents/report/approval][@body/json]', JSON.stringify(body));

  return instance.client
    .post(`${prefix}/${incidentId}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostIncidentReportApproval>) => {
      // return Promise.reject(new Error('ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostIncidentReportApproval>;
};
