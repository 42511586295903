import { Logger } from '@/logger/index';
import { AzureAuthProviderProps, IncidentCanApprove, IncidentStore, PropDefaults } from '@/src/types';
import { Box, Button } from '@mui/material';

import {
  PartEightReport,
  PartFourReport,
  PartOneReport,
  PartTwoReport,
  PartThreeReport,
  PartFiveReport,
  PartSixReport,
  PartSevenReport,
} from './reportParts/index';
import { AddRemark, ReportRemarkList } from './reviews';
import { isTeamLead, isHod, userCanOnIncident, exportLinkV2 } from '@/utils/index';
import { useGlobalContext } from '@/store/index';
import { approverTaskTitles } from '@/src/static';
import { Icon } from './../../icons/index';

interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

function IncidentDetailReport(props: IncidentProps & IncidentStore) {
  const { currentUser } = useGlobalContext();
  const { store } = props;
  const { incidentDetailData } = store;

  Logger(['[IncidentDetailReport][incidentDetailData]', incidentDetailData]);

  const remarkTextBy: string = (() => {
    if (isTeamLead(currentUser?.userProfile, incidentDetailData.data?.data?.reporters as any)) {
      return approverTaskTitles.tl;
    }
    if (isHod(currentUser?.userProfile as any)) {
      return approverTaskTitles.hod;
    } else return 'Invalid text';
  })();

  // const incidentStatus = isIncidentStatus(incidentDetailData.data?.data?.status as any);
  /** can add remark only if not yet submitted */
  // const canAddBeforeSubmit = !userIncidentApprovals(incidentDetailData.data?.data?.reviewers as any, currentUser, 'any').length;

  // Logger(['[IncidentDetailReport][canAddBeforeSubmit]', canAddBeforeSubmit]);
  // NOTE  and is not super_admin
  const canApprove: IncidentCanApprove = store.incidentDetailData.data?.data?.canApprove as any;
  return (
    <Box className="w-full lg:max-w[calc(100% - 400px)]">
      <Box className="mt-6 xl:mt-10">
        <PartOneReport {...(props as any)} />
      </Box>
      <Box className="mt-6 xl:mt-10">
        <PartTwoReport {...(props as any)} />
      </Box>
      <Box className="mt-6 lg:mt-10 xl:block">
        <PartThreeReport {...(props as any)} />
      </Box>
      <Box className="mt-6 lg:mt-10 hidden xl:block">
        <PartFourReport {...(props as any)} />
      </Box>
      <Box className="mt-6 xl:mt-10">
        <PartFiveReport {...(props as any)} />
      </Box>
      <Box className="mt-6 lg:mt-10 hidden xl:block">
        <PartSixReport {...(props as any)} />
      </Box>
      <Box className="mt-6 lg:mt-10 hidden xl:block">
        <PartSevenReport {...(props as any)} />
      </Box>
      <Box className="mt-6 xl:mt-10">
        <PartEightReport {...(props as any)} />
      </Box>

      <Box className="mt-6 lg:mt-10 hidden xl:block">
        <ReportRemarkList {...(props as any)} />
      </Box>

      {
        // NOTE valid logic to enable once api is ready
        // NOTE should work correctly once api is ready
      }
      {userCanOnIncident(currentUser, incidentDetailData.data?.data, incidentDetailData.data?.data?.status as any, 'can_submit_remark') &&
        canApprove === 'CAN_APPROVE' && (
          <Box className="border border-solid border-[#F2F2F2] rounded-3xl mt-8 bg-[#F5F7FF] shadow p-6 mb-8">
            <AddRemark {...(props as any)} title={remarkTextBy} />
          </Box>
        )}
      {incidentDetailData.data?.data?.status === 4 && (
        <Box
          className="sticky bottom-0 py-5 px-8 bg-white z-20 hidden xl:flex"
          sx={{ boxShadow: '0px 3px 10px #00000029', marginLeft: '-32px', marginBottom: '-32px', width: 'calc(100% + 400px)' }}
        >
          <Box className="ml-auto">
            <Button
              download
              href={exportLinkV2(`/incidents/export/${incidentDetailData.data.data.id}.pdf`)}
              className="w-36 ml-auto flex bg-white"
              variant="outlined"
              startIcon={<Icon icon="file" color="#3155FF" viewBox="0 0 384 512" size={18} />}
            >
              Export PDF
            </Button>
          </Box>
        </Box>
      )}
      <Box className="w-full h-14 xl:hidden">{/* // NOTE: do not delete this line, it's prevent View progress footer overlap when small screen */}</Box>
    </Box>
  );
}

export default IncidentDetailReport;
