import { TrainingBodyData, QueryTrainingList, QueryTrainingSearch, QueryTrainingDetail } from '@/src/types';
// import { ChildStore } from '@/src/utils';
import { TrainingStore } from '@/types/store';
import { Context, createContext } from 'react';

import { initialDataWrapper } from '../../common';
export const TrainingContext: React.Context<TrainingStore> = createContext<TrainingStore>({
  store: {
    resetStatus: (apiName: string) => {},
    setTrainingCreateUpdate: (body: TrainingBodyData, updateFor: 'list' | 'item', condition?: 'edit' | 'create') => {},
    trainingCreateUpdateData: {
      ...initialDataWrapper,
    },
    setTrainingList: (params: QueryTrainingList, type?: 'hidden_load_after_deleted') => {},
    trainingListData: {
      ...initialDataWrapper,
    },
    setTrainingSearch: (params: QueryTrainingSearch) => {},
    trainingSearchData: {
      ...initialDataWrapper,
    },
    setTrainingTypeFilter: () => {},
    trainingTypeFilter: {
      ...initialDataWrapper,
    },

    setTrainingCancel: (uid: string, updateFor: 'list' | 'item') => {},
    trainingCanceledData: {
      ...initialDataWrapper,
    },

    setTrainingDelete: (uid: string, updateFor: 'list' | 'item', delayed?: number) => {},
    trainingDeletedData: {
      ...initialDataWrapper,
    },

    setTrainingDetail: (id: string, param?: QueryTrainingDetail) => {},
    trainingDetailData: {
      ...initialDataWrapper,
    },
  },
} as TrainingStore) as Context<TrainingStore>;
