/** declare only http service related requests */

import { PostCreateUpdateTraining, TrainingBodyData, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
import { AxiosResponse } from 'axios';

/**
 * @description create/update Training, for draft or published status, we use the same api for both
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const TrainingCreateUpdatePost = (
  /** create can also refer to update for draft */
  prefix: string = 'training/create',
  tokens: HttpTokens,
  body: TrainingBodyData,
  hardRejection = true
): Promise<PostCreateUpdateTraining> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .post(`${prefix}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostCreateUpdateTraining>) => {
      // return Promise.reject(new Error('test')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostCreateUpdateTraining>;
};
