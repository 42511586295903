import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, FormControl, InputLabel, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { IncidentStore, IncidentFormStoreParts } from '@/src/types';
import { textFieldProps } from '@/src/utils/form';
import { TagsInputIncident } from '@/src/components';

interface PartSixSectionTwoProps {
  formStore: IncidentFormStoreParts;
  incidentStore: IncidentStore;
}

export const PartSixSectionTwo = (props: PartSixSectionTwoProps) => {
  const {
    formStore: { formStore },
    incidentStore: { store: incidentStore },
  } = props;

  const causesAssessmentData = formStore.store.formData.incidentCausesAssessment?.data || {};

  const handleChangeCausesAssessmentData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('incidentCausesAssessment', 'updated').setup(value, keyName, subKeyName);
  };

  const handleMultiSelection = (type: 'select' | 'delete', value: any, keyName: string, subKeyName?: string) => {
    if (type === 'select') handleChangeCausesAssessmentData(value.length > 0 ? value : null, keyName, subKeyName);
    if (type === 'delete') {
      let newValueArray = [];
      if (subKeyName && causesAssessmentData[keyName]) {
        newValueArray = causesAssessmentData[keyName][subKeyName].filter((item) => item.value !== value);
      }
      handleChangeCausesAssessmentData(newValueArray.length > 0 ? newValueArray : null, keyName, subKeyName);
    }
  };

  const [checkOtherArray, setCheckOtherArray] = useState<string[]>([]);
  const refOtherArray = useRef({} as any);
  const handleCheckboxOther = (checked: boolean, keyName: string, subKeyName: string) => {
    if (checked) {
      setCheckOtherArray((prevState) => prevState.concat([subKeyName]));
    } else {
      setCheckOtherArray((prevState) => prevState.filter((item) => item !== subKeyName));
      handleChangeCausesAssessmentData(null, keyName, subKeyName);
    }
    if (subKeyName && refOtherArray.current) refOtherArray.current[subKeyName].value = null;
  };

  const [mount, setMount] = React.useState<number>(0);
  useEffect(() => {
    if (formStore.store.formData.incidentCausesAssessment.state === 'updated' && formStore.store.formData.incidentCausesAssessment.data && !mount) {
      const newCheckOtherArray = [] as string[];
      if (causesAssessmentData.casualFactors.fiveOther) newCheckOtherArray.push('fiveOther');
      if (causesAssessmentData.casualFactors.sixOther) newCheckOtherArray.push('sixOther');
      if (causesAssessmentData.casualFactors.sevenOther) newCheckOtherArray.push('sevenOther');
      if (causesAssessmentData.casualFactors.eightOther) newCheckOtherArray.push('eightOther');
      if (causesAssessmentData.casualFactors.nineOther) newCheckOtherArray.push('nineOther');
      setCheckOtherArray(newCheckOtherArray);
      setMount(1);
    }
  }, [formStore.store.formData.incidentCausesAssessment]);

  return (
    <>
      <Box className=" border-0 border-l border-solid border-blue-600 mt-10">
        <Box>
          <Typography className="bg-gradient-to-r from-blue-600 text-white py-1 px-6 text-base">Process (Conditions)</Typography>
        </Box>
        <Box className="pl-6 mt-6">
          <Box className="grid grid-cols-5 gap-6">
            <Box className="col-span-3 relative">
              <FormControl className="w-full">
                <InputLabel shrink>5. Protective systems</InputLabel>
                <TagsInputIncident
                  selectKeyName="casualFactors"
                  selectSubKeyName="five"
                  dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.systems || ([] as { value: number; label: string }[])}
                  selectedData={causesAssessmentData?.casualFactors?.five || ([] as { value: number; label: string }[])}
                  onSelection={handleMultiSelection}
                />
              </FormControl>
            </Box>
            <Box className="col-span-2">
              <FormControl className="flex flex-row mt-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkOtherArray.includes('fiveOther')}
                      onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'fiveOther')}
                    />
                  }
                  label="Other"
                />
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Please enter detail"
                  inputRef={(el) => (refOtherArray.current.fiveOther = el)}
                  disabled={!checkOtherArray.includes('fiveOther')}
                  onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'fiveOther')}
                  {...textFieldProps(causesAssessmentData, 'casualFactors', 'fiveOther')}
                />
              </FormControl>
            </Box>
          </Box>
          <hr className="border-dashed border border-gray-200 my-6" />
          <Box className="grid grid-cols-5 gap-6">
            <Box className="col-span-3 relative">
              <FormControl className="w-full">
                <InputLabel shrink>6. Tools, equipment, materials and products, and vehicles</InputLabel>
                <TagsInputIncident
                  selectKeyName="casualFactors"
                  selectSubKeyName="six"
                  dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.tempv || ([] as { value: number; label: string }[])}
                  selectedData={causesAssessmentData?.casualFactors?.six || ([] as { value: number; label: string }[])}
                  onSelection={handleMultiSelection}
                />
              </FormControl>
            </Box>
            <Box className="col-span-2">
              <FormControl className="flex flex-row mt-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkOtherArray.includes('sixOther')}
                      onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'sixOther')}
                    />
                  }
                  label="Other"
                />
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Please enter detail"
                  inputRef={(el) => (refOtherArray.current.sixOther = el)}
                  disabled={!checkOtherArray.includes('sixOther')}
                  onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'sixOther')}
                  {...textFieldProps(causesAssessmentData, 'casualFactors', 'sixOther')}
                />
              </FormControl>
            </Box>
          </Box>
          <hr className="border-dashed border border-gray-200 my-6" />
          <Box className="grid grid-cols-5 gap-6">
            <Box className="col-span-3 relative">
              <FormControl className="w-full">
                <InputLabel shrink>7. Work place hazards</InputLabel>
                <TagsInputIncident
                  selectKeyName="casualFactors"
                  selectSubKeyName="seven"
                  dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.workHazards || ([] as { value: number; label: string }[])}
                  selectedData={causesAssessmentData?.casualFactors?.seven || ([] as { value: number; label: string }[])}
                  onSelection={handleMultiSelection}
                />
              </FormControl>
            </Box>
            <Box className="col-span-2">
              <FormControl className="flex flex-row mt-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkOtherArray.includes('sevenOther')}
                      onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'sevenOther')}
                    />
                  }
                  label="Other"
                />
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Please enter detail"
                  inputRef={(el) => (refOtherArray.current.sevenOther = el)}
                  disabled={!checkOtherArray.includes('sevenOther')}
                  onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'sevenOther')}
                  {...textFieldProps(causesAssessmentData, 'casualFactors', 'sevenOther')}
                />
              </FormControl>
            </Box>
          </Box>
          <hr className="border-dashed border border-gray-200 my-6" />
          <Box className="grid grid-cols-5 gap-6">
            <Box className="col-span-3 relative">
              <FormControl className="w-full">
                <InputLabel shrink>8. Organisational</InputLabel>
                <TagsInputIncident
                  selectKeyName="casualFactors"
                  selectSubKeyName="eight"
                  dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.organizations || ([] as { value: number; label: string }[])}
                  selectedData={causesAssessmentData?.casualFactors?.eight || ([] as { value: number; label: string }[])}
                  onSelection={handleMultiSelection}
                />
              </FormControl>
            </Box>
            <Box className="col-span-2">
              <FormControl className="flex flex-row mt-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkOtherArray.includes('eightOther')}
                      onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'eightOther')}
                    />
                  }
                  label="Other"
                />
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Please enter detail"
                  inputRef={(el) => (refOtherArray.current.eightOther = el)}
                  disabled={!checkOtherArray.includes('eightOther')}
                  onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'eightOther')}
                  {...textFieldProps(causesAssessmentData, 'casualFactors', 'eightOther')}
                />
              </FormControl>
            </Box>
          </Box>
          <hr className="border-dashed border border-gray-200 my-6" />
        </Box>
      </Box>
      <Box className="grid grid-cols-5 gap-6">
        <Box className="col-span-3 relative">
          <FormControl className="w-full">
            <InputLabel shrink>9. Management of Change</InputLabel>
            <TagsInputIncident
              selectKeyName="casualFactors"
              selectSubKeyName="nine"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.managements || ([] as { value: number; label: string }[])}
              selectedData={causesAssessmentData?.casualFactors?.nine || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
            />
          </FormControl>
        </Box>
        <Box className="col-span-2">
          <FormControl className="flex flex-row mt-6">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!checkOtherArray.includes('nineOther')}
                  onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'nineOther')}
                />
              }
              label="Other"
            />
            <TextField
              className="w-full"
              id="outlined-basic"
              variant="outlined"
              placeholder="Please enter detail"
              inputRef={(el) => (refOtherArray.current.nineOther = el)}
              disabled={!checkOtherArray.includes('nineOther')}
              onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'nineOther')}
              {...textFieldProps(causesAssessmentData, 'casualFactors', 'nineOther')}
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
};
