import { AzureAuthProviderProps, IncidentStore, PropDefaults } from '@/src/types';

import { Icon } from '@/src/components';
import { Box, Typography, ListItem, ListItemIcon, ListItemText } from '@mui/material';

interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export default function PartSixReport(props: IncidentProps & IncidentStore) {
  // const { history, detailId, store } = props;
  const { store } = props;
  const {
    // incidentBusinessUnitsFilter,
    // // incidentCreateUpdateData,
    // incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    // setIncidentDetail,
    incidentDetailData,
  } = store;

  const incidentDetailCasualFactors = incidentDetailData?.data?.data?.incidentCausesAssessment?.casualFactors;

  const getListItem = (label, key) => (
    <ListItem key={key} className="p-0 items-start">
      <ListItemIcon className="min-w-0 mr-1 pt-1">-</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  );

  return (
    <>
      <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
        <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
        Incident causes assessment
      </Typography>
      <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:mt-5 bg-white">
        <Box className=" bg-blue-600 text-center">
          <Typography className="text-white text-lg p-3">Causal Factors</Typography>
        </Box>
        <Box className="grid grid-cols-2 gap-0">
          <Box className=" bg-blue-600 text-center border border-solid border-gray-200">
            <Typography className="text-white text-lg p-3">People (Acts)</Typography>
          </Box>
          <Box className=" bg-blue-600 text-center border border-solid border-gray-200">
            <Typography className="text-white text-lg p-3">Process (Conditions)</Typography>
          </Box>
          <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
            <Typography className="font-medium">1. Following procedure/rules/permit to work/JSA</Typography>
            <Box className="pt-2 pb-3">
              {incidentDetailCasualFactors?.one?.length
                ? (incidentDetailCasualFactors?.one || []).map((data: any, index: any) => getListItem(data.label, index))
                : !incidentDetailCasualFactors?.oneOther && <Typography>- None</Typography>}
              {incidentDetailCasualFactors?.oneOther && getListItem(`Other (${incidentDetailCasualFactors?.oneOther})`, 'oneOther')}
            </Box>
          </Box>
          <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
            <Typography className="font-medium">5. Protective systems</Typography>
            <Box className="pt-2 pb-3">
              {incidentDetailCasualFactors?.five?.length
                ? (incidentDetailCasualFactors?.five || []).map((data: any, index: any) => getListItem(data.label, index))
                : !incidentDetailCasualFactors?.fiveOther && <Typography>- None</Typography>}
              {incidentDetailCasualFactors?.fiveOther && getListItem(`Other (${incidentDetailCasualFactors?.fiveOther})`, 'fiveOther')}
            </Box>
          </Box>
          <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
            <Typography className="font-medium">2. Use of tools, equipment, materials and products</Typography>
            <Box className="pt-2 pb-3">
              {incidentDetailCasualFactors?.two?.length
                ? (incidentDetailCasualFactors?.two || []).map((data: any, index: any) => getListItem(data.label, index))
                : !incidentDetailCasualFactors?.twoOther && <Typography>- None</Typography>}
              {incidentDetailCasualFactors?.twoOther && getListItem(`Other (${incidentDetailCasualFactors?.twoOther})`, 'twoOther')}
            </Box>
          </Box>
          <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
            <Typography className="font-medium">6. Tools, equipment, materials and products, and vehicles</Typography>
            <Box className="pt-2 pb-3">
              {incidentDetailCasualFactors?.six?.length
                ? (incidentDetailCasualFactors?.six || []).map((data: any, index: any) => getListItem(data.label, index))
                : !incidentDetailCasualFactors?.sixOther && <Typography>- None</Typography>}
              {incidentDetailCasualFactors?.sixOther && getListItem(`Other (${incidentDetailCasualFactors?.sixOther})`, 'sixOther')}
            </Box>
          </Box>
          <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
            <Typography className="font-medium">3. Use protective methods</Typography>
            <Box className="pt-2 pb-3">
              {incidentDetailCasualFactors?.three?.length
                ? (incidentDetailCasualFactors?.three || []).map((data: any, index: any) => getListItem(data.label, index))
                : !incidentDetailCasualFactors?.threeOther && <Typography>- None</Typography>}
              {incidentDetailCasualFactors?.threeOther && getListItem(`Other (${incidentDetailCasualFactors?.threeOther})`, 'threeOther')}
            </Box>
          </Box>
          <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
            <Typography className="font-medium">7. Work place hazards</Typography>
            <Box className="pt-2 pb-3">
              {incidentDetailCasualFactors?.seven?.length
                ? (incidentDetailCasualFactors?.seven || []).map((data: any, index: any) => getListItem(data.label, index))
                : !incidentDetailCasualFactors?.sevenOther && <Typography>- None</Typography>}
              {incidentDetailCasualFactors?.sevenOther && getListItem(`Other (${incidentDetailCasualFactors?.sevenOther})`, 'sevenOther')}
            </Box>
          </Box>
          <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
            <Typography className="font-medium">4. Inattention/Lack of awareness</Typography>
            <Box className="pt-2 pb-3">
              {incidentDetailCasualFactors?.four?.length
                ? (incidentDetailCasualFactors?.four || []).map((data: any, index: any) => getListItem(data.label, index))
                : !incidentDetailCasualFactors?.fourOther && <Typography>- None</Typography>}
              {incidentDetailCasualFactors?.fourOther && getListItem(`Other (${incidentDetailCasualFactors?.fourOther})`, 'fourOther')}
            </Box>
          </Box>
          <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
            <Typography className="font-medium">8. Organisational</Typography>
            <Box className="pt-2 pb-3">
              {incidentDetailCasualFactors?.eight?.length
                ? (incidentDetailCasualFactors?.eight || []).map((data: any, index: any) => getListItem(data.label, index))
                : !incidentDetailCasualFactors?.eightOther && <Typography>- None</Typography>}
              {incidentDetailCasualFactors?.eightOther && getListItem(`Other (${incidentDetailCasualFactors?.eightOther})`, 'eightOther')}
            </Box>
          </Box>
        </Box>
        <Box className="pt-5 pb-1 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
          <Typography className="font-medium">9. Management of Change</Typography>
          <Box className="pt-2 pb-3">
            {incidentDetailCasualFactors?.nine?.length
              ? (incidentDetailCasualFactors?.nine || []).map((data: any, index: any) => getListItem(data.label, index))
              : !incidentDetailCasualFactors?.nineOther && <Typography>- None</Typography>}
            {incidentDetailCasualFactors?.nineOther && getListItem(`Other (${incidentDetailCasualFactors?.nineOther})`, 'nineOther')}
          </Box>
        </Box>
        <Box className=" bg-blue-600 text-center">
          <Typography className="text-white text-lg p-3">Root Cause Categories</Typography>
        </Box>
        <Box className="py-3 px-4 pl-10 border-0 border-b border-solid border-gray-200 border-r">
          <Box className="py-2">
            {incidentDetailData?.data.data.incidentCausesAssessment?.rootCause?.categories?.length ? (
              (incidentDetailData?.data.data.incidentCausesAssessment?.rootCause?.categories || []).map((data: any, index: any) =>
                getListItem(data.label, index)
              )
            ) : (
              <Typography>- None</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
