import React from 'react';
import PropTypes from 'prop-types';
import iconsLib from '@/src/static/common/icons.lib';

const defaultStyles = { display: 'inline-block', verticalAlign: 'middle' };
interface iconType {
  size: number;
  color: string;
  icon: keyof typeof iconsLib;
  className: string;
  viewBox: string;
}

export const Icon = ({ size, color, icon, className, viewBox }: iconType) => {
  const styles = { ...defaultStyles };
  return (
    <svg
      className={className}
      style={styles}
      viewBox={viewBox}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill={color} d={iconsLib[icon]} />
    </svg>
  );
};

Icon.defaultProps = {
  size: 16,
  color: 'inherit',
  viewBox: '0 0 512 512',
  className: '',
};

Icon.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
  className: PropTypes.string,
};
