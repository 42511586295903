import { TableBody, TableRow, Table, TableContainer } from '@mui/material';
// import { AdminStore, AzureAuthProviderProps } from '@/src/types/store';
// import { ManHoursSchema } from '@/static/manHours/mockupData';
import { ManHourEnhancedTableHead } from '@/src/components';
import { BusinessUnitsSchema, GetManHourMonthList, ManHourCalculations, ManHourSchema } from '@/src/types';
import { IncidentStore, ManhoursStore } from '@/types/store/index';
import { ManhoursMonthSegment } from '../manhoursMonthSegment';
import { Total } from './total';
interface ManHoursProps {
  parentStore: IncidentStore;
  manhoursStore: ManhoursStore;
  data: GetManHourMonthList;
  businessUnitsData: BusinessUnitsSchema[];
  buColumn: string[];
  total?: number;
  onInputChange: () => void;
}

export function EnhancedTableManHours(props: ManHoursProps) {
  const {
    manhoursStore: { store },
    parentStore,
    data,
    businessUnitsData,
    buColumn,
    onInputChange,
  } = props;

  let calculations: ManHourCalculations = null as any;
  if (store.manhoursListData.state === 'ready') {
    calculations = store.manhoursListData.data.calculations;
  }

  const cleanBuColumn = buColumn.sort((a, b) => ('' + a).localeCompare(b));

  return (
    <TableContainer className="overflow-y-auto scrollbar">
      <Table aria-labelledby="tableTitle" size="small">
        <ManHourEnhancedTableHead businessUnitsData={businessUnitsData} buColumn={cleanBuColumn} />
        <TableBody>
          {!!data.data?.length &&
            data.data &&
            data.data.map((row: ManHourSchema, index: number) => {
              return (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <ManhoursMonthSegment store={store} data={row} buColumn={cleanBuColumn} parentStore={parentStore} onInputChange={onInputChange} />
                </TableRow>
              );
            })}
          {calculations && <Total calculations={calculations} buColumn={cleanBuColumn} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
