/** declare only http service related requests */

import { DeleteCourse, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description delete existing course by id/uid
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const CourseDelete = (prefix: string = 'courses/delete', uid: string, tokens: HttpTokens, hardRejection = true): Promise<DeleteCourse> => {
  const instance = new AxiosService({ method: 'delete', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend
  // const queryUri = paramsQueryString(prefix, params);
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };
  return (
    instance.client
      // TODO
      .delete(`${prefix}/${uid}`, config)
      .then((d: AxiosResponse<DeleteCourse>) => {
        // return Promise.reject(new Error('ups!')) as any;
        return d.data;
      })
      .catch(instance.defaultHandleError) as Promise<DeleteCourse>
  );
};
