import React from 'react';
import { IncidentSchema, AzureAuthProviderProps, PropDefaults, IncidentStore, IncidentCanApprove } from '@/src/types';
import { Box, Typography, Stepper, StepLabel, Step } from '@mui/material';
import { useGlobalContext } from '@/store/index';
import { Logger } from '@/logger/index';
import { approverTaskTitles } from '@/src/static';
import { isTeamLead, isHod, isGM, isIncidentStatus, userIncidentApprovals, userIncidentRemarks } from '@/src/utils';
// import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
interface Props extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

interface TasksList {
  /** any remarks added */
  remarks?: {
    text: string;
    complete: boolean;
  };
  /**
   * Completed: submitted for approval or rejection
   */
  approval: {
    complete: boolean;
    text: string;
    subText: string;
  };
}

export function TaskCompletion(props: Props & IncidentStore): any {
  const { currentUser } = useGlobalContext();
  const { store } = props;
  const incidentDetailData = (store.incidentDetailData.data?.data || {}) as IncidentSchema;
  /**
   * super_admin does not perform this action on /detail page, they already done it when approving the report from /update pages
   */
  const excludes = 'user_admin';
  const userApprovals = userIncidentApprovals(incidentDetailData?.reviewers as any, currentUser, 'current', excludes)[0];

  const userRemarks = incidentDetailData?.reviewers?.remarks?.filter((n) => {
    // match by current user, and if approve has been set
    return n?.employee?.employeeId === currentUser?.userProfile?.employeeId && !!n.remark;
  })[0];

  const incidentStatus = isIncidentStatus(incidentDetailData?.status as any);
  /**
   * we identify latest remarks from all remark history by {ref}, approvals and remarks both share common {ref}
   * - current remarks can be added before approval is submitted, so if we have remarks that do not match with {ref} > those are the current remarks
   */
  const latestRemarksBy = userIncidentRemarks(incidentDetailData?.reviewers as any, currentUser);
  // current tasks depend on user status
  const teamLead = isTeamLead(currentUser.userProfile, incidentDetailData?.reporters);
  const hod = isHod(currentUser.userProfile);

  const tasksList: TasksList = [
    teamLead || hod
      ? {
          remarks: { text: teamLead ? approverTaskTitles.tl : hod ? approverTaskTitles.hod : null, complete: !!latestRemarksBy.length },
          approval: { complete: !!userApprovals, text: approverTaskTitles.approveNote, subText: approverTaskTitles.addNote },
        }
      : null,
    isGM(currentUser.userProfile)
      ? { approval: { complete: !!userApprovals, text: approverTaskTitles.approveNote, subText: approverTaskTitles.addNote } }
      : null,
  ]
    .filter((n) => {
      if (incidentStatus === 'ongoing' || incidentStatus === 'closed') return true;
      else return false;
    })
    .filter((n) => !!n)[0] as TasksList;

  const completedSteps = [!!tasksList?.remarks, !!tasksList?.approval].filter((n) => !!n).length;

  Logger(['[TaskCompletion][tasksList]', tasksList]);
  Logger(['[TaskCompletion][latestRemarksBy]', latestRemarksBy]);
  Logger(['[TaskCompletion][userRemarks]', userRemarks]);
  Logger(['[TaskCompletion][completedSteps]', completedSteps]);
  Logger(['[TaskCompletion][userApprovals][current]', userApprovals]);

  /**
    

     <Stepper activeStep={complete} orientation="vertical" className="progressStep">
          {incidentProgress.map((progress, index) => (
            <Step key={index}>
              {progress?.approved === true ? (
                <LightTooltip
                  arrow
                  followCursor
                  title={
                    <>
                      <Typography className="text-xs text-black">{`By ${
                        (progress?.employee?.firstName ? progress?.employee?.firstName : '') +
                        ' ' +
                        (progress?.employee?.middleName ? progress?.employee?.middleName : '') +
                        ' ' +
                        (progress?.employee?.lastName ? progress?.employee?.lastName : '')
                      }`}</Typography>
                      <Typography className="text-xs text-black mt-1">
                        {progress?.approvalDate ? printDate(progress?.approvalDate, 'DD MMM YYYY, HH:mm') : '-'}
                      </Typography>
                    </>
                  }
                >
                  <StepLabel>{progresses[index].label}</StepLabel>
                </LightTooltip>
              ) : (
                <StepLabel>{progresses[index].label}</StepLabel>
              )}

              {progress.note && (
                <Box className="border-0 border-l border-solid border-gray-300 ml-[10px]">
                  <Button
                    variant="outlined"
                    className="ml-5 mt-3 py-0 h-9"
                    startIcon={<Icon icon="fileLines" color="#3155FF" size={16} viewBox="0 0 384 512" />}
                    onClick={() => handleClickOpenReadNoteDialog(progress)}
                  >
                    Read note
                  </Button>
                </Box>
              )}
            </Step>
          ))}
        </Stepper>


     */
  // NOTE  and is not super_admin
  const canApprove: IncidentCanApprove = store.incidentDetailData.data?.data?.canApprove as any;
  return (
    <>
      <Box className=" hidden xl:block">
        <img src={require('@/assets/icons/ill-approve4x.png')} alt="Occupational" width={114} className=" absolute right-2 top-0" />
        <Typography variant="h2" color="primary">
          Your tasks
        </Typography>
        {(canApprove === 'CAN_APPROVE' || canApprove === 'ALREADY_APPROVED') && (
          <Box className="mt-6">
            <Stepper activeStep={completedSteps} orientation="vertical" className="progressStep">
              {!!tasksList?.remarks && (
                <Step
                  key={1}
                  sx={{ '&:first-of-type': { width: '200px' } }}
                  completed={tasksList?.remarks?.complete || false}
                  active={tasksList?.remarks?.complete === false}
                >
                  <StepLabel className="word-break: break-all " sx={{ '&:first-of-type': { width: '200px' } }}>
                    {tasksList?.remarks?.text}
                  </StepLabel>
                </Step>
              )}
              {!!tasksList?.approval && (
                <Step
                  key={2}
                  sx={{ '&:first-of-type': { width: '200px' } }}
                  completed={tasksList?.approval?.complete || false}
                  active={tasksList?.approval?.complete === false}
                >
                  <StepLabel
                    optional={
                      <Typography variant="caption" className="text-gray-400 ">
                        {tasksList?.approval?.subText}
                      </Typography>
                    }
                  >
                    {tasksList?.approval?.text}
                  </StepLabel>
                </Step>
              )}
            </Stepper>

            {isGM(currentUser?.userProfile) && (
              <Box className="ml-1 w-[220px] mt-5">
                <Typography>Please review this incident and give approval or reject. You can add note after click on the button.</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}
