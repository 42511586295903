import React, { useEffect, useState } from 'react';
import { AzureAuthProviderProps, ManHourChartBaseSchema, ManhoursStore, PropDefaults, ReducerActionType } from '@/src/types';

import { ManHourChartOne } from './mh.chart';

import { Logger } from '@/logger/index';
import { Box, Skeleton } from '@mui/material';

interface Props extends PropDefaults {
  chartQueryState: ReducerActionType;
  [name: string]: any;
  azure: AzureAuthProviderProps;
  onReadyChart: () => void;
}

export const ManHourChartSections = (props: Props & ManhoursStore) => {
  const { store: manHourStore, chartQueryState } = props;
  const [chardData, setChartData] = useState<ManHourChartBaseSchema>(null as any);
  const [hasError, setError] = useState<boolean>(false);
  const { onReadyChart } = props;
  const [mount, setMount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const onApiFetchReady = (ok: boolean, data: any) => {
    if (ok) {
      setChartData(data);
      setError(false);
      setLoading(false);
      Logger(['[ManHourChartSections][setManHoursChart][onApiFetchReady]', data]);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (!mount) {
      manHourStore.setManHoursChart(null as any, 'new', onApiFetchReady);
      setMount(1);
    }
    if (chartQueryState.state === 'updated' && !chartQueryState.checked) {
      setLoading(true);
      manHourStore.setManHoursChart(chartQueryState.value || {}, 'update', onApiFetchReady);
      chartQueryState.checked = true;
    }
  }, [mount, setMount, chartQueryState]);

  return (
    <React.Fragment>
      {hasError || chardData ? (
        <>
          <ManHourChartOne {...(props as any)} onChartReady={onReadyChart} error={hasError} data={chardData} loading={loading} />
        </>
      ) : (
        <>
          {!hasError && (
            <Box className="h-[314px] min-w-[600px] max-w-[800px] rounded-2xl shadow-md relative">
              <Box className="w-full flex absolute top-7 z-30 justify-center">
                <Box className="w-6 mr-1 relative">
                  <Box className="w-full h-[2px] top-[calc(50%-1px)] bg-[#6D9BE6] absolute"></Box>
                  <Box className="w-[11px] h-[11px] rounded-full bg-[#6D9BE6] absolute left-[calc(50%-5.5px)] top-[calc(50%-5.5px)]"></Box>
                </Box>
                Man-hours
              </Box>
              <Skeleton animation="wave" component={'div'} className="h-full w-full transform-none rounded-2xl  border-gray-200 bg-white" />
            </Box>
          )}
        </>
      )}
    </React.Fragment>
  );
};
