import { HttpTokens, BodyUploadLegalFile, PostLegalFileUpload } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { paramsQueryString, requestResponseUrl } from '@/src/utils';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description upload image to cache on sever
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const UploadFileUpload = (
  prefix: string = 'legal/cache/file/upload',
  ref: string,
  body: BodyUploadLegalFile,
  tokens: HttpTokens,
  hardRejection = true
): Promise<PostLegalFileUpload> => {
  /** provide higher timeout for upload */
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };
  const queryUri = paramsQueryString(prefix, { ref });

  return instance.client
    .post(queryUri, body.file, config)
    .then((d: AxiosResponse<PostLegalFileUpload>) => {
      // return Promise.reject(new Error('ups')) as any;
      const responseUrl = requestResponseUrl(d.request as any, 'ref');
      if (responseUrl.ref) d.data.data.ref = responseUrl.ref;
      return d.data;
    })
    .catch((err) => {
      console.log('IncidentFileUpload/err', err);
      return instance.defaultHandleError(err);
    }) as Promise<PostLegalFileUpload>;
};
