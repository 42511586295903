/** declare only http service related requests */

import { HttpTokens, GetIncidentsCharts, QueryIncidentsCharts } from '@/src/types';
import { paramsQueryString } from '@/src/utils';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get incidents two chart data statistics
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const IncidentChartsGet = (
  prefix: string = 'incidents/charts/incidents',
  params: QueryIncidentsCharts,
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetIncidentsCharts> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const queryUri = paramsQueryString(prefix, params || {});

  // token not yet set on backend
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetIncidentsCharts>) => {
      // return Promise.reject(new Error('ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetIncidentsCharts>;
};
