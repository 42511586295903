//
// ?? OPTIONAL we may need to implement functions api session cookie in case user is in private/incognito and cannot store any data, solution: https://stackoverflow.com/questions/61630263/how-to-create-sessions-and-cookies-in-azure-functions

/** declare only http service related requests */

import { HttpTokens, PostAuthVerify } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Verify logged in user by jwt token from session cookie
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const AuthVerifyGet = (prefix: string = 'auth/verify', tokens: HttpTokens, hardRejection = true): Promise<PostAuthVerify> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT + 5000, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);
  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<PostAuthVerify>) => {
      // return Promise.reject(new Error('Ups error with Verify')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostAuthVerify>;
};
