/* eslint-disable spaced-comment */

/**
 * @assets: https://stackoverflow.com/questions/52752552/echarts-for-react-call-component-on-clicking-a-data-point-on-chart
 */

import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, Select, OutlinedInput, MenuItem, Skeleton, CircularProgress } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { DashboardStore, IncidentDashboardChartSchema, PropDefaults } from '@/src/types';
import { IncidentOverviewOptions } from './chart.options';
import { genYears } from '@/src/utils';
import { debounce } from 'lodash';
import { Logger } from '@/logger/index';
// import { withComponentState } from '@/store/component.store';
// import { ComponentStore } from '@/types/store';

type ChartOptions = typeof IncidentOverviewOptions;
interface Props extends PropDefaults {}

const now = new Date();
const yearData = genYears(now, 9, 1).reverse();

export function DashboardIncidentChart(props: Props & DashboardStore) {
  const { store: dashboardStore, onChartReady } = props;
  const [mount, setMount] = useState<number>(0);
  const [optionsData, setOptions] = useState<ChartOptions>(IncidentOverviewOptions);
  const [chartInstance, setChartInstance] = useState<ReactECharts>(null as any);
  const [onData, setData] = useState<IncidentDashboardChartSchema>(null as any);
  const [yearSelected, setYear] = useState<number>(new Date().getFullYear());
  const [hasError, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const onApiFetchReady = (ok: boolean, data: IncidentDashboardChartSchema) => {
    if (ok) {
      setData(data);
      setError(false);
      setLoading(false);
      Logger(['[DashboardLegalChart][setDashboardLegalCharts][onApiFetchReady]', data]);
    } else {
      setData(null as any);
      setError(true);
      setLoading(false);
    }
  };

  const callApi = (year: number) => {
    dashboardStore.setDashboardIncidentsChart({ year: year.toString() || new Date().getFullYear().toString() }, onApiFetchReady as any);
  };

  const updateOptions = (onData: IncidentDashboardChartSchema, reset = false) => {
    optionsData.series.forEach((n) => {
      if (n.id === 'trir') {
        n.data = (reset ? [] : onData.seriesData.trir) as any;
      }
      if (n.id === 'ltift') {
        n.data = (reset ? [] : onData.seriesData.ltifr) as any;
      }
      if (n.id === 'man-hours') {
        n.data = (reset ? [] : onData.seriesData.manHours) as any;
      }
    });

    const _labels = optionsData.xAxis.data;
    optionsData.series.forEach((d, i) => {
      if (d.data) {
        const _temp: never[] = _labels.map((k, j) => {
          return (d.data[j] ? d.data[j] : 0) as never;
        });
        d.data = _temp;
      }
    });
    const _now = new Date();
    if (_now.getFullYear() === yearSelected) {
      const _months = _now.getMonth() + 1;
      optionsData.xAxis.data = optionsData.xAxis.data.slice(0, _months);
      optionsData.series.forEach((d) => {
        d.data = d.data.slice(0, _months);
      });
    }
  };

  /**
   * initiate debounced callback
   */
  const debouncedCallBack = debounce(
    (call: () => void) => {
      call();
    },
    200
    // { maxWait: 1000 }
  );

  const updateChart = () => {
    if (chartInstance) {
      const inst = chartInstance.getEchartsInstance();
      inst.setOption(optionsData as any);
      inst.resize();
    }
  };
  useEffect(() => {
    if (!mount && !onData) {
      callApi(yearSelected);
      setMount(1);
    }

    if (onData && !hasError) {
      optionsData.title.show = false;
      optionsData.xAxis.data = onData.categoryList as any;
      updateOptions(onData, false);
      setOptions(optionsData);
      console.log(optionsData);
      updateChart();
      return;
    }

    if (hasError) {
      optionsData.title.show = true;
      optionsData.xAxis.data = [];
      updateOptions(onData, true);
      setOptions(optionsData);
      updateChart();
    }
  }, [hasError, onData, optionsData, setOptions, chartInstance, setChartInstance, mount, setMount, yearSelected]);

  return (
    <Fragment>
      {hasError || onData ? (
        <Box className="shadow-md rounded-2xl border border-solid border-gray-200 bg-white relative h-full flex flex-col justify-end items-center">
          <Box className="flex absolute top-6 left-6">
            <Typography variant="h4" className=" text-black">
              Incidents
            </Typography>
          </Box>

          <Box className="flex items-center absolute top-[1rem] right-6" style={{ zIndex: 1 }}>
            {loading && <CircularProgress className="mr-2" size={20} />}
            <Select
              value={yearSelected}
              onChange={(...e) => {
                const target: { value: number; name?: string } = e[0].target as any;
                if (target.value) {
                  setYear(target.value);
                  setLoading(true);
                  debouncedCallBack(() => {
                    callApi(target.value);
                  });
                }
              }}
              className="bg-white w-[82px]"
              labelId="selectType"
              id="selectType"
              input={<OutlinedInput />}
            >
              {(yearData || []).map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {!!mount && (
            <ReactECharts
              className={`${loading && 'opacity-25'}`}
              ref={(r: any) => {
                if (r) {
                  r.inx = (r.inx || 0) + 1;
                  setChartInstance(r as any);
                }
              }}
              style={{ height: '90%', width: '90%', padding: '16px' }}
              onChartReady={onChartReady}
              option={optionsData}
            />
          )}
        </Box>
      ) : (
        <Box className="shadow-md rounded-2xl border border-solid border-gray-200 bg-white relative h-full flex flex-col justify-end items-center">
          <Box className="flex absolute top-6 left-6 z-30">
            <Typography variant="h4" className=" text-black">
              Incidents
            </Typography>
          </Box>
          <Skeleton animation="wave" component={'div'} className="h-[330px] w-[100%] transform-none rounded-2xl  border-gray-200 bg-white" />
        </Box>
      )}
    </Fragment>
  );
}

// export default withComponentState(DashboardIncidentChart, 'a'); // to investigate small issue
