/** declare only http service related requests */

import { HttpTokens, GetLegalLorProgressBar, LegalLorProgressBarQuery } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { paramsQueryString } from '@/utils/index';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get /legal/lor/progress-bar/{lorId}/?year
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const LegalLorProgressGet = (
  prefix: string = 'legal/lor/progress-bar',
  id: string,
  params: LegalLorProgressBarQuery,
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetLegalLorProgressBar> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const queryUri = paramsQueryString(`${prefix}/${id}`, params);
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetLegalLorProgressBar>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetLegalLorProgressBar>;
};
