/** declare only http service related requests */

import { PutUpdateCourse, CourseBodyDataUpdate, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description update existing course and return new CourseSchema, including course id/uid
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const CourseUpdatePut = (
  prefix: string = 'course/update',
  uid: string,
  body: CourseBodyDataUpdate,
  tokens: HttpTokens,
  hardRejection = true
): Promise<PutUpdateCourse> => {
  const instance = new AxiosService({ method: 'put', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend
  // const queryUri = paramsQueryString(prefix, params);
  // new URLSearchParams();
  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };
  // console.log('update calling with', body);
  return (
    instance.client
      // TODO
      .put(`${prefix}/${uid}`, JSON.stringify(body), config)
      .then((d: AxiosResponse<PutUpdateCourse>) => {
        // return Promise.reject(new Error('ups!')) as any;
        return d.data;
      })
      .catch(instance.defaultHandleError) as Promise<PutUpdateCourse>
  );
};
