// ! this modal can only be used in admins, required by: {AdminContextProvider}

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormGroup,
} from '@mui/material';

import { Icon, SearchBoxAutocomplete } from '@/src/components';
import { AdminStore } from '@/types/store';
import { delay, isSuperAdmin, printRoleNameById } from '@/src/utils';
import { GetEmployeeByFilter, AddUserActionSignal, EmployeesByFilter } from '@/src/types';
import { merge } from 'lodash';
import { withRxAddModal } from '@/src/components/core/hocs';
import { useGlobalContext } from '@/store/index';
import { SnackBarAlert } from '../../variants/snackbar';
import { toastMessages } from '@/static/common';
// import { Logger } from '@/logger/index';

interface Props extends AdminStore {
  ready: boolean;
  onAdded: (create: EmployeesByFilter) => {};
  setActionSignal: (payload: AddUserActionSignal) => void;
  useAction: () => AddUserActionSignal;
}

const mergeResults = (a, b) => {
  if (a?.type === 'ERROR') {
    delete a.type;
    delete a.error;
  }
  return merge(a, b);
};

const formSubmit = async (event) => {
  event.preventDefault();
};

function CreateAdminModal(props: Props) {
  const { currentUser } = useGlobalContext();
  const userRoleId: any = Number(currentUser?.userProfile?.roleId) || 3;
  const defaultRoleId = userRoleId;
  const { onAdded, useAction, setActionSignal, ready } = props;
  const actionValue: AddUserActionSignal = useAction() as any;
  const filterResults = (actionValue?.results || { data: [] }) as GetEmployeeByFilter;
  const keepDialogOpen = ['ERROR', 'CLOSE', 'ADD'].indexOf(actionValue.type) === -1;
  const [alertState, setAlertState] = useState<boolean>(undefined as any);
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success');
  const [addIndex, setAdd] = useState<number>(0);

  useEffect(() => {
    if (addIndex) setAdd(0);
  }, [addIndex, setAdd]);

  if (actionValue?.type === 'ADD' && !addIndex) {
    onAdded(actionValue?.results as any);
    setAlertSeverity('success');
    setAlertState(true);
    setAdd(1);
  }

  if (actionValue.type === 'ERROR' && !addIndex) {
    setActionSignal({ type: 'CLOSE', error: undefined });
    setAlertSeverity('error');
    setAlertState(true);
    setAdd(1);
  }

  const HiddenInput = ({ value }) => {
    // only load for bu admin
    if (userRoleId !== 1 && keepDialogOpen) {
      setActionSignal(mergeResults(actionValue, { type: 'ROLE_CHANGE', value: { roleId: Number(value as any) } as any }));
    }
    return null;
  };

  return (
    <Box className="ml-auto">
      <Button
        className="w-28"
        variant="contained"
        onClick={() => {
          setActionSignal({ type: 'OPEN' });
          delay(500).then(() => {
            // REVIEW this fix little bug
            if (actionValue.type !== 'OPEN') setActionSignal({ type: 'OPEN' });
          });
        }}
        startIcon={<Icon icon="plus" color="#ffffff" viewBox="0 0 512 512" size={18} />}
      >
        Add
      </Button>
      <Dialog sx={{ overflow: 'unset' }} open={keepDialogOpen} onClose={() => setActionSignal({ type: 'CLOSE' })} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className="mb-4 p-0">
          {defaultRoleId === 1 ? (
            <Typography className="font-medium text-2xl">Add admin</Typography>
          ) : (
            <Typography className="font-medium text-2xl">Add BU admin</Typography>
          )}
        </DialogTitle>
        <DialogContent className="p-0" sx={{ overflow: 'unset' }}>
          <form onSubmit={formSubmit} noValidate>
            <FormControl className="mb-5 w-full">
              {isSuperAdmin(currentUser?.userProfile) ? (
                <FormGroup>
                  <FormLabel id="demo-row-radio-buttons-group-label">Select role</FormLabel>

                  <RadioGroup
                    defaultValue={defaultRoleId}
                    onChange={(e, value) => {
                      setActionSignal(mergeResults(actionValue, { type: 'ROLE_CHANGE', value: { roleId: Number(value) } as any }));
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {/** to decide should new user be able to see both options if they are only BU Admin ? */}
                    <FormControlLabel className="my-0" value={1} control={<Radio />} label={printRoleNameById(1)} />
                    <FormControlLabel className="my-0" value={2} control={<Radio />} label={printRoleNameById(2)} />
                    <FormControlLabel className="my-0" value={4} control={<Radio />} label={printRoleNameById(4)} />
                    <FormControlLabel className="my-0" value={5} control={<Radio />} label={printRoleNameById(5)} />
                  </RadioGroup>
                </FormGroup>
              ) : (
                <HiddenInput value={userRoleId} />
              )}
            </FormControl>
            <FormControl className="w-full mb-2">
              <FormGroup row className="mb-3">
                <InputLabel shrink>Enter name or email</InputLabel>
                <SearchBoxAutocomplete
                  format="ADD-ADMIN"
                  setNameSearching={(search, type) => {
                    if (type === 'input' && search) setActionSignal(mergeResults(actionValue, { type: 'SEARCH', value: { q: search } }));
                  }}
                  onSelectedOption={(employee: any) => {
                    const _actionValue: AddUserActionSignal | any = actionValue as any;
                    if (_actionValue.type) delete _actionValue.type;
                    if (!actionValue.value.roleId) _actionValue.value.roleId = defaultRoleId;
                    if (employee?.employeeId && _actionValue.results) {
                      // NOTE replace SEARCH result with SELECT results
                      _actionValue.results.data = [employee];
                      setActionSignal(mergeResults(_actionValue, { type: 'SELECT', value: {} }));
                    }
                  }}
                  placeholder="Enter name or email who you want to assign as admin"
                  data={(keepDialogOpen && ready ? filterResults?.data : null) as any}
                />
              </FormGroup>
              <DialogActions className="p-0 justify-start">
                <Button
                  type="submit"
                  className="mr-1 w-28"
                  variant="contained"
                  onClick={() => {
                    const results: AddUserActionSignal = mergeResults(actionValue, { type: 'ADD' });
                    if (results?.results?.data[0]) setActionSignal(results);
                  }}
                >
                  Add
                </Button>
                <Button type="button" className="w-28" variant="outlined" onClick={() => setActionSignal({ type: 'CLOSE' })}>
                  Cancel
                </Button>
              </DialogActions>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>

      <SnackBarAlert
        messages={{
          error: toastMessages.adminNotAdded,
          success: toastMessages.adminAdded,
        }}
        severity={alertSeverity}
        state={alertState as any}
        onClose={() => {
          setAlertState(false);
        }}
      />
    </Box>
  );
}

export default withRxAddModal(CreateAdminModal);
