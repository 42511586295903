import { PostIncidentReportRemarks, HttpTokens, BodyIncidentReportRemarks } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Provide incident report remarks by incidentId
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const IncidentReportRemarks = (
  prefix: string = 'incidents/report/remarks',
  incidentId: string,
  body: BodyIncidentReportRemarks,
  tokens: HttpTokens,
  hardRejection = true
): Promise<PostIncidentReportRemarks> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  console.log('[incidents/report/remarks][@body/json]', JSON.stringify(body));

  return instance.client
    .post(`${prefix}/${incidentId}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostIncidentReportRemarks>) => {
      // return Promise.reject(new Error('ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostIncidentReportRemarks>;
};
