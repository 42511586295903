import { Snackbar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface Props {
  state: boolean;
  severity: 'success' | 'error';
  onClose: () => void;
  messages?: {
    success: string;
    error: string;
  };
}

export const SnackBarAlert = (props: Props, context) => {
  const { messages, severity, state, onClose } = props || {};
  const [alertState, setAlertState] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success');
  const [alertMessage, setAlertMessage] = useState('');
  const successDefault = messages?.success ? messages?.success : 'Saved!';
  const errorDefault = messages?.error ? messages?.error : 'Not saved!';

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (state !== undefined) {
      setAlertState(state || false);
    }

    if (severity) {
      setAlertSeverity(severity);
      setAlertMessage(severity === 'error' ? errorDefault : successDefault);
    }
  }, [messages, severity, state, setAlertState, setAlertSeverity, props]);
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={alertState}
      autoHideDuration={5000}
      onClose={() => {
        setAlertState(false);
        onClose();
      }}
      style={{ zIndex: '10000' }}
    >
      <Alert
        onClose={() => {
          setAlertState(false);
          onClose();
        }}
        severity={alertSeverity}
        sx={{ width: '100%' }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

// SnackBarAlert.propTypes = {
//   messages: {
//     success: 'Saved!',
//     error: 'Not saved!',
//   },
// };
