import { useEffect, useState, useRef, ComponentState } from 'react';
import { GetBusinessUnits } from '@/src/types';
import { SelectChangeEvent, Select, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
    },
  },
};

function usePreviousState(value: ComponentState) {
  const ref = useRef();
  useEffect(() => (ref.current = value));
  return ref.current;
}

interface FilterUserCompanyProp {
  [name: string]: any;
  data: GetBusinessUnits;
  companyValue: string[];
  loading: boolean;
  onCallBackFilter: Function;
}

export function FilterUserCompany(props: FilterUserCompanyProp) {
  const { data, companyValue, loading, onCallBackFilter } = props;

  const [prevCompanyValue, setPrevCompanyValue] = useState<string[]>(companyValue);
  const [isSelectedCompany, setSelectedCompany] = useState<boolean>(false);

  const prevIsSelectedCompany = usePreviousState(isSelectedCompany);

  useEffect(() => {
    if (data) {
      if (prevIsSelectedCompany === true && isSelectedCompany === false) setPrevCompanyValue(companyValue);
      else if (prevIsSelectedCompany === false && isSelectedCompany === true) {
        if (companyValue !== prevCompanyValue) onCallBackFilter('reloadByParams');
      }
    }
  }, [isSelectedCompany]);

  const handleChange = (event: SelectChangeEvent<typeof companyValue>) => {
    // clone to change type so can use .filter without error (value: 'string | string[]' > newValue: 'any')
    const newValue: any = event.target.value;
    const result = newValue.filter((e: any) => e);

    onCallBackFilter('setCompanyValue', typeof result === 'string' ? result.split(',') : result);
  };

  const handleOpen = () => {
    setSelectedCompany(false);
  };
  const handleClose = () => {
    setSelectedCompany(true);
  };

  return (
    <Select
      sx={{ width: '200px' }}
      id="demo-multiple-checkbox"
      multiple
      displayEmpty
      value={companyValue}
      onChange={handleChange}
      onOpen={handleOpen}
      onClose={handleClose}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <span>All company</span>;
        }

        return selected.join(', ');
      }}
      MenuProps={MenuProps}
    >
      {loading ? (
        <MenuItem key="" value="">
          <ListItemText primary="Loading..." />
        </MenuItem>
      ) : (
        data?.data.map((row, index) => (
          <MenuItem key={index} value={row.buName}>
            <Checkbox checked={companyValue.indexOf(row.buName) > -1} />
            <ListItemText primary={`${row.buName} (${row.buCode})`} />
          </MenuItem>
        ))
      )}
    </Select>
  );
}
