import { GlobalStore, NotificationStatus, UserModelData } from '@/src/types';
import { createContext } from 'react';
import { userModel } from '../users';

export const GlobalContext: React.Context<GlobalStore> = createContext<GlobalStore>({
  // eslint-disable-next-line no-new-func
  currentUser: userModel(new Function() as any),
  setCurrentUser: (val: UserModelData, userType: 'nonUser' | 'user' = 'user') => {},
  /**
   * call to action when status has changed
   * @param message provide optional message
   */
  notify: {
    changeNotificationStatus: (type: NotificationStatus, message?: string) => {},
    notificationStatus: 'initial' as any,
  },
  persistanceStore: {
    setPersistance: (storeName: 'legals', data: any, action?: 'update' | 'new') => {},
    persistance: {
      legals: {},
    },
  },
} as GlobalStore);

// interface PersistanceStore {
//   setPersistance: (storeName: 'legals', data: any) => void;
//   persistance: {
//     legals: { [name]: any };
//   };
// }
