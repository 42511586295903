import { createContext } from 'react';
import { StoreStateV3, ManhoursStore } from '@/types/store';
import { ManHourBodyData, QueryManHourList, QueryManHourUpdate, GetManHourChartsQuery, ManHourChartBaseSchema } from '@/src/types';
import { initialDataWrapper } from '../../common';

export const ManhoursContext: React.Context<ManhoursStore> = createContext<ManhoursStore>({
  store: {
    resetStatus: (apiName: string) => {},
    resetState: (apiName: string, state: StoreStateV3) => {},
    setManhoursList: (params: QueryManHourList) => {},
    manhoursListData: {
      ...initialDataWrapper,
    },
    setManhoursBusinessUnitsFilter: () => {},
    manhoursBusinessUnitsFilter: {
      ...initialDataWrapper,
    },

    setManHourUpdate: (id: string, query: QueryManHourUpdate, body: ManHourBodyData, action: 'update' | 'new' = 'new', done?: (ok: boolean) => void) => {},
    manHourUpdateData: {
      ...initialDataWrapper,
    },
    setManHoursChart: (params?: GetManHourChartsQuery, action?: 'update' | 'new', done?: (ok: boolean, data?: ManHourChartBaseSchema) => void) => {},
    manHoursChartData: {
      ...initialDataWrapper,
    },
  },
} as ManhoursStore);
