import React, { useState } from 'react';
import { Container } from '@/components/index';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { LOGIN_PAGE_TITLE } from '@/src/static';
import { AuthLoginByPass } from '@/src/services/http';
import { cookieOptions, CookieService } from '@/src/utils';
import { ENVS } from '@/src/constants';

interface Values {
  [key: string]: any;
}

interface LoginByPassProps {
  [name: string]: any;
}

export const LoginByPass: React.FC = (LoginProps: LoginByPassProps) => {
  setDocTitle(LOGIN_PAGE_TITLE);

  const cookieService: CookieService = new CookieService(null, cookieOptions() as any);

  const [values, setValues] = useState<Values>({ username: '', password: '' });
  const onChangeValues = (key: string, val: string) => {
    setValues({ ...values, [key]: val });
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (ENVS.ALLOW_BY_PASS) {
      const res = await AuthLoginByPass('auth/login-by-pass', values.username, values.password);
      if (res) {
        cookieService.$removeMany(['jwt_expired', 'accessToken', 'jwt', 'bypass']);
        cookieService.$set('jwt', res.data.token);
        console.log(res.data);
        cookieService.$set('bypass', '1');
        window.location.href = '/home';
      }
    }
  };

  if (!ENVS.ALLOW_BY_PASS) {
    window.location.href = '/';
    return <></>;
  } else {
    return (
      <Container>
        <div style={{ display: 'block', width: '100%', maxWidth: '16rem', margin: '0 auto', padding: '2rem' }}>
          <h2 style={{ textAlign: 'center' }}>Login by Pass Page</h2>
          <form onSubmit={onSubmit}>
            <label htmlFor="username" style={{ display: 'block', marginTop: '.75rem' }}>
              Email
            </label>
            <input
              type="email"
              required={true}
              style={{ display: 'block', width: '100%', padding: '.5rem', marginTop: '.5rem' }}
              value={values.username}
              onChange={(e: any) => onChangeValues('username', e.target.value)}
            />
            <label htmlFor="password" style={{ display: 'block', marginTop: '.75rem' }}>
              Password
            </label>
            <input
              type="password"
              required={true}
              style={{ display: 'block', width: '100%', padding: '.5rem', marginTop: '.5rem' }}
              value={values.password}
              onChange={(e: any) => onChangeValues('password', e.target.value)}
            />
            <div style={{ marginTop: '1rem' }}>
              <button type="submit">Sign In By Pass</button>
            </div>
          </form>
        </div>
      </Container>
    );
  }
};
