import React, { useState, useEffect, useRef } from 'react';
import { TextField, Autocomplete, Box, Chip, AutocompleteInputChangeReason, AutocompleteChangeReason } from '@mui/material';
import { EmployeeSchema } from '@/src/types';
import { debounce } from 'lodash';

interface TagInputSearchEmployeesProps {
  optionData: EmployeeSchema[];
  selectedData: EmployeeSchema[];
  isLoading: boolean;
  isErrorSearch: boolean;
  handleKeyFilter: (searchText: string) => void;
  handleOnSelect: (employeesSelected: EmployeeSchema[]) => void;
}

export default function TagInputSearchEmployees(props: TagInputSearchEmployeesProps) {
  const { optionData, selectedData, isLoading, isErrorSearch, handleKeyFilter, handleOnSelect } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [temporaryOptionData, setTemporaryOptionData] = useState<EmployeeSchema[]>([]);

  useEffect(() => {
    if (isLoading) {
      setTemporaryOptionData([]);
    } else {
      const optionDataNoSelected = optionData.reduce((previousValue: EmployeeSchema[], currentValue: EmployeeSchema) => {
        let isCurrentSelected = false;
        selectedData.forEach((selectedItem) => {
          if (selectedItem.employeeId === currentValue.employeeId) isCurrentSelected = true;
        });
        return isCurrentSelected ? previousValue : previousValue.concat(currentValue);
      }, []);
      setTemporaryOptionData(optionDataNoSelected);
    }
  }, [isLoading]);

  const handleDelete = (deleteEmployee: EmployeeSchema): void => {
    const newSelected = selectedData.filter((employee) => employee.employeeId !== deleteEmployee.employeeId);
    handleOnSelect(newSelected);
  };

  const getLabel = (i: EmployeeSchema) => `${i.firstName} ${i.lastName} - ${i.position ? i.position : ''} (${i.buName})`;
  const debouncedSearchText = useRef(debounce(async (value) => handleKeyFilter(value), 1000)).current;

  useEffect(() => {
    return () => debouncedSearchText.cancel();
  }, [debouncedSearchText]);

  return (
    <>
      <Autocomplete
        className="multiSelectDropdown"
        popupIcon=""
        multiple
        disableClearable
        value={[]}
        options={temporaryOptionData}
        inputValue={searchText}
        loading={searchText ? isLoading : false}
        noOptionsText={isErrorSearch ? 'Loading Failed' : 'No options'}
        isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
        getOptionLabel={(option) => getLabel(option)}
        filterOptions={(options) => options}
        renderTags={() => null}
        renderOption={(props, option: EmployeeSchema) => (
          <Box component="li" {...props} key={option.employeeId} sx={{ flexWrap: 'wrap', display: 'flex', flexDirection: 'column' }}>
            <span className="mr-auto">{getLabel(option)}</span>
            <span className="text-gray-400 mr-auto">{option.email}</span>
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder="Enter name or email who you want to select" InputProps={{ ...params.InputProps, type: 'search' }} />
        )}
        onChange={(_event, value: EmployeeSchema[], reason: AutocompleteChangeReason) => {
          if (reason === 'selectOption') {
            handleOnSelect(selectedData.concat(value));
            setSearchText('');
            setTemporaryOptionData([]);
          }
        }}
        onInputChange={(_event, value: string, reason: AutocompleteInputChangeReason) => {
          if (reason === 'input') {
            if (!value) setTemporaryOptionData([]);
            setSearchText(value);
            debouncedSearchText(value);
          }
        }}
      />
      <Box>
        {selectedData
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map((selectedItem, i) => (
            <Chip className="delIcon mt-2 mr-2 rounded-md bg-[#E6EBFF]" key={i} label={getLabel(selectedItem)} onDelete={() => handleDelete(selectedItem)} />
          ))}
      </Box>
    </>
  );
}
