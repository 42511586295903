import { Box, Card, Checkbox, FormControl, FormControlLabel, Typography, TextField, InputLabel, TextFieldProps } from '@mui/material';
import React, { useRef } from 'react';
import { TagsInputIncident } from '@/src/components';
import { IncidentFormStoreParts, IncidentStore } from '@/src/types';
import { textFieldProps } from '@/utils/form/index';
interface PartOneSectionThreeProps {
  formStore: IncidentFormStoreParts;
  incidentStore: IncidentStore;
}

export const PartOneSectionThree = (props: PartOneSectionThreeProps) => {
  const {
    formStore: { formStore },
    incidentStore: { store: incidentStore },
  } = props;
  const initialReportData = formStore.store.formData.initialReport?.data || {};
  const [isCheckActivityTypeOther, setIsCheckActivityTypeOther] = React.useState<boolean>(false);
  const [isCheckCategoryEventOther, setIsCheckCategoryEventOther] = React.useState<boolean>(false);
  const [mount, setMount] = React.useState<number>(0);

  const handleChangeInitialReportData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('initialReport', 'updated').setup(value, keyName, subKeyName);
  };

  const handleMultiSelection = (type: 'select' | 'delete', value: any, keyName: string) => {
    if (type === 'select') {
      handleChangeInitialReportData(value.length > 0 ? value : null, keyName);
    }
    if (type === 'delete') {
      const newValueArray = initialReportData[keyName].filter((item) => item.value !== value);
      handleChangeInitialReportData(newValueArray.length > 0 ? newValueArray : null, keyName);
    }
  };

  const refActivityTypeOther = useRef<TextFieldProps>(null);
  const refCategoryEventOther = useRef<TextFieldProps>(null);
  const handleCheckboxOther = (checked: boolean, keyName: string) => {
    if (!checked) handleChangeInitialReportData(null, keyName);
    if (keyName === 'activityTypeOther' && refActivityTypeOther.current) refActivityTypeOther.current.value = null;
    if (keyName === 'categoryEventOther' && refCategoryEventOther.current) refCategoryEventOther.current.value = null;
  };

  React.useEffect(() => {
    if (formStore.store.formData.initialReport.state === 'updated' && formStore.store.formData.initialReport.data && !mount) {
      if (formStore.store.formData.initialReport.data.activityTypeOther) setIsCheckActivityTypeOther(true);
      if (formStore.store.formData.initialReport.data.categoryEventOther) setIsCheckCategoryEventOther(true);
      // setMount to 1 because want to trigger this checkboxOther only one time after get data from API
      setMount(1);
    }
  }, [formStore.store.formData.initialReport, mount]);

  return (
    <Card variant="outlined" className=" rounded-2xl p-6">
      <Box className="grid grid-cols-3 gap-6">
        <Box className="col-span-2 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Type of activity</InputLabel>
            <TagsInputIncident
              selectKeyName="activityTypes"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.activities || ([] as { value: number; label: string }[])}
              selectedData={initialReportData?.activityTypes || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl className="flex flex-row mt-6">
            <FormControlLabel
              control={
                <Checkbox
                  name="other"
                  checked={isCheckActivityTypeOther}
                  onChange={(e) => {
                    setIsCheckActivityTypeOther(e.target.checked);
                    handleCheckboxOther(e.target.checked, 'activityTypeOther');
                  }}
                />
              }
              label="Other"
            />
            <TextField
              inputRef={refActivityTypeOther}
              className="w-full"
              id="outlined-basic"
              variant="outlined"
              placeholder="Please enter detail"
              disabled={!isCheckActivityTypeOther}
              onBlur={(e) => handleChangeInitialReportData(e.target.value || null, 'activityTypeOther')}
              {...textFieldProps(initialReportData, 'activityTypeOther')}
            />
          </FormControl>
        </Box>
      </Box>
      <hr className="border-dashed border border-gray-200 my-5" />
      <Box className="grid grid-cols-3 gap-6">
        <Box className="col-span-2 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Event category</InputLabel>
            <TagsInputIncident
              selectKeyName="categoryEvents"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.events || ([] as { value: number; label: string }[])}
              selectedData={initialReportData?.categoryEvents || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl className="flex flex-row mt-6">
            <FormControlLabel
              control={
                <Checkbox
                  name="other"
                  checked={isCheckCategoryEventOther}
                  onChange={(e) => {
                    setIsCheckCategoryEventOther(e.target.checked);
                    handleCheckboxOther(e.target.checked, 'categoryEventOther');
                  }}
                />
              }
              label="Other"
            />
            <TextField
              inputRef={refCategoryEventOther}
              className="w-full"
              id="outlined-basic"
              variant="outlined"
              placeholder="Please enter detail"
              disabled={!isCheckCategoryEventOther}
              onBlur={(e) => handleChangeInitialReportData(e.target.value || null, 'categoryEventOther')}
              {...textFieldProps(initialReportData, 'categoryEventOther')}
            />
          </FormControl>
        </Box>
      </Box>
      <hr className="border-dashed border border-gray-200 my-5" />
      <Box className="flex items-center">
        <img src={require('@/assets/icons/money-sack.png')} alt="Occupational" width={27} className="mr-3" />
        <Typography variant="h5">Cost estimate in THB</Typography>
      </Box>
      <Box className="mt-5">
        <FormControl className="w-44 mr-6">
          <InputLabel>Equipment and Material</InputLabel>
          <TextField
            placeholder="0.00"
            type="number"
            onBlur={(e) =>
              handleChangeInitialReportData(isNaN(parseFloat(e.target.value)) ? null : parseFloat(e.target.value), 'costEstimate', 'equipmentMaterials')
            }
            {...textFieldProps(initialReportData, 'costEstimate', 'equipmentMaterials', 2)}
            inputProps={{ step: '.01', className: 'text-right' }}
          />
        </FormControl>
        <FormControl className="w-44 mr-6">
          <InputLabel>Production</InputLabel>
          <TextField
            placeholder="0.00"
            type="number"
            onBlur={(e) => handleChangeInitialReportData(isNaN(parseFloat(e.target.value)) ? null : parseFloat(e.target.value), 'costEstimate', 'production')}
            {...textFieldProps(initialReportData, 'costEstimate', 'production', 2)}
            inputProps={{ step: '.01', className: 'text-right' }}
          />
        </FormControl>
        <FormControl className="w-44 mr-6">
          <InputLabel>Admin/Laborer</InputLabel>
          <TextField
            placeholder="0.00"
            type="number"
            onBlur={(e) => handleChangeInitialReportData(isNaN(parseFloat(e.target.value)) ? null : parseFloat(e.target.value), 'costEstimate', 'adminLaborer')}
            {...textFieldProps(initialReportData, 'costEstimate', 'adminLaborer', 2)}
            inputProps={{ step: '.01', className: 'text-right' }}
          />
        </FormControl>
      </Box>
    </Card>
  );
};
