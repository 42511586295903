// import React from 'react';
// export class BuildNewSVG extends React.Component {
//     constructor(props) {
//         super(props);
//     }
//     render() {
//         // let bg = props.color || '#000';
//         // let height = props.height || props.size || '24';
//         // let width = props.width || props.size || '24';
//         // let path1 = props.path1 || 'M7 10l5 5 5-5z';
//         // let path2 = props.path2;
//         // let fill1 = props.fill1 || '';
//         // let fill2 = props.fill2 || 'none';
//         // let viewBox = props.viewBox || '0 0 24 24';

//         return (
//             <svg fill={bg} className={props.class} height={height} viewBox={viewBox} width={width}>
//                 <path d={path1} fill={fill1} />
//                 <path d={path2} fill={fill2} />
//             </svg>
//         );
//     }
// }

export const ErrorMessageSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="81" viewBox="0 0 82 81">
      <g id="warning_1_" data-name="warning (1)" transform="translate(0 -0.5)">
        <rect id="Rectangle_3418" data-name="Rectangle 3418" width="82" height="81" transform="translate(0 0.5)" fill="none" />
        <path
          id="Line_323"
          data-name="Line 323"
          d="M-4.087,10.578A1.913,1.913,0,0,1-6,8.665V-4.087A1.913,1.913,0,0,1-4.087-6,1.913,1.913,0,0,1-2.174-4.087V8.665A1.913,1.913,0,0,1-4.087,10.578Z"
          transform="translate(44.894 37.129)"
          fill="#acacac"
        />
        <path
          id="Path_1207"
          data-name="Path 1207"
          d="M53.091,26a7.034,7.034,0,0,1,6.07,3.5L87.208,77.955a7.014,7.014,0,0,1-6.07,10.527H25.044a7.014,7.014,0,0,1-6.07-10.527L47.02,29.5A7.034,7.034,0,0,1,53.091,26ZM81.138,84.657A3.188,3.188,0,0,0,83.9,79.872L55.85,31.416a3.188,3.188,0,0,0-5.519,0L22.284,79.872a3.188,3.188,0,0,0,2.759,4.785Z"
          transform="translate(-12.284 -17.824)"
          fill="#acacac"
        />
        <circle id="Ellipse_222" data-name="Ellipse 222" cx="3" cy="3" r="3" transform="translate(38 54.5)" fill="#acacac" />
      </g>
    </svg>
  );
};
