/* eslint-disable spaced-comment */

/**
 * @assets: https://stackoverflow.com/questions/52752552/echarts-for-react-call-component-on-clicking-a-data-point-on-chart
 */

import React, { Fragment, useEffect, useState } from 'react';

import { Box, Typography, Skeleton } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { BuOverallSchema, DashboardStore, LegalChartSchema, PropDefaults } from '@/src/types';

import { overallStatusOptions } from './chart.options';
import { Logger } from '@/logger/index';

type ChartOptions = typeof overallStatusOptions;

interface Props extends PropDefaults {}

export function DashboardLegalChart(props: Props & DashboardStore) {
  const { store: dashboardStore, onChartReady } = props;
  const [mount, setMount] = useState<number>(0);
  const [onData, setData] = useState<BuOverallSchema>(null as any);
  const [hasError, setError] = useState<boolean>(false);
  const [optionsData, setOptions] = useState<ChartOptions>(overallStatusOptions);
  const [chartInstance, setChartInstance] = useState<ReactECharts>(null as any);

  const onApiFetchReady = (ok: boolean, data: LegalChartSchema) => {
    if (ok) {
      setData(data?.overall);
      setError(false);
      Logger(['[DashboardLegalChart][setDashboardLegalCharts][onApiFetchReady]', data]);
    } else {
      setError(true);
    }
  };

  const updateChart = () => {
    if (chartInstance) {
      const inst = chartInstance.getEchartsInstance();
      inst.setOption(optionsData as any);
      inst.resize();
    }
  };

  useEffect(() => {
    if (!mount && !onData) {
      dashboardStore.setDashboardLegalCharts(null as any, null as any, onApiFetchReady as any);
      setMount(1);
      return;
    }

    if (onData) {
      const overall = onData;
      optionsData.series[0].data = [
        { value: overall?.complete || 0, name: `Completed (${overall?.complete || 0})` },
        { value: overall?.progress || 0, name: `In progress (${overall?.progress || 0})` },
        { value: overall?.overdue || 0, name: `Overdue (${overall?.overdue || 0})` },
      ] as any;
      setOptions(optionsData);
      updateChart();
      return;
    }

    if (hasError) {
      optionsData.title.show = true;
      optionsData.series[0].data = [];
      setOptions(optionsData);
      updateChart();
    }
  }, [mount, setMount, hasError, onData, setData, optionsData, setOptions, onApiFetchReady]);

  return (
    <Fragment>
      {hasError || onData ? (
        <Box className="shadow-md rounded-2xl border border-solid border-gray-200 bg-white relative h-full flex flex-col justify-center ">
          <Typography variant="h4" className="mb-3 flex absolute top-6 left-6 text-black">
            Legal: Overall status
          </Typography>
          {!!mount && (
            <div className="mt-4">
              <ReactECharts
                ref={(r: any) => {
                  if (r) {
                    r.inx = (r.inx || 0) + 1;
                    setChartInstance(r as any);
                  }
                }}
                style={{ height: '214px' }}
                onChartReady={onChartReady}
                option={optionsData}
              />
            </div>
          )}
        </Box>
      ) : (
        <Box className="shadow-md rounded-2xl border border-solid border-gray-200 bg-white relative h-full flex flex-col justify-center ">
          <Typography variant="h4" className="mb-3 flex absolute top-6 left-6 text-black z-30">
            Legal: Overall status
          </Typography>
          <Skeleton animation="wave" component={'div'} className="h-full w-full mr-6 transform-none rounded-2xl  border-gray-200 bg-white" />
        </Box>
      )}
    </Fragment>
  );
}
