/* eslint-disable no-prototype-builtins */
import React from 'react';

import { styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export const AccordionBase = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square TransitionProps={{ timeout: 300 }} {...props} />
))(({ theme }) => ({
  backgroundColor: '#fff',
  marginBottom: '28px',
  border: '0 !important',
  boxShadow: '0px 3px 10px #AAAAAA29',
  padding: '20px 30px',
  borderRadius: '20px',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    backgroundColor: '#F5F7FF',
  },
}));

export const AccordionSummaryBase = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '18px', backgroundColor: '#3155FF', padding: '10px', color: '#fff', borderRadius: '30px' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  padding: '0',
}));

export const AccordionDetailsBase = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));
