import { CalendarSchema, CalendarSchemaData } from '@/src/types';

const assignOnly = ['title', 'extendedProps', 'start', 'end', 'id', 'data'];

interface CalendarSchemaExt extends CalendarSchema {
  data?: CalendarSchemaData;
  /** from {data} to {extendedProps} */
  extendedProps: CalendarSchemaData;
}

const restructure = (d: CalendarSchemaExt): CalendarSchemaExt => {
  if (!d) return undefined as any;
  if (d.data) {
    d.extendedProps = d.data;
    delete d.data;
    // todo add more dto validation for extendedProps data
  }
  return d;
};

export class CalendarDto implements CalendarSchemaExt {
  id: string = null as any;
  data?: CalendarSchemaData = {} as any;
  extendedProps: CalendarSchemaData = {} as any;
  title: string = null as any;
  start: string | Date = null as any;
  end: string | Date = null as any;
  constructor(payload: CalendarSchema, loadMock = false) {
    let data: CalendarSchemaExt;
    // const _payload = restructure(payload as any);
    // assign only required data
    if (Object.keys(payload || {}).length) {
      data = payload as any;
    } else if (loadMock) {
      // data = loadExampleData();
    }

    data = restructure(payload as any);

    if (data) {
      assignOnly.forEach((key) => {
        try {
          this[key] = data[key];
        } catch (err) {}
      });
    }
  }
}
