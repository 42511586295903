/** declare only http service related requests */

import { HttpTokens, GetLegalBuRelated } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get /legal/bu-related filter list results
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const LegalBuRelatedFilterGet = (prefix: string = 'legal/bu-related', tokens: HttpTokens, hardRejection = true): Promise<GetLegalBuRelated> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<GetLegalBuRelated>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetLegalBuRelated>;
};
