import { Box, Card, FormControl, InputLabel, TextField } from '@mui/material';
import React, { Component } from 'react';
import { IncidentFormStoreParts, GetEmployeeBySearch, HttpTokens, IncidentStore } from '@/src/types';
import { textFieldProps } from '@/utils/form/index';
import { getJwtToken } from '@/src/utils';
import { EmployeeSchema } from '@/types/schema/employee.schema.d';
import { filter, switchMap, from, Subject, Observable, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EmployeeSearchGet } from '@/services/http';
import { clone } from 'lodash';
import AddImageSection from './addImageSection/index';
import { TagInputSearchEmployees } from '@/src/components';
import { Logger } from '@/logger/index';
interface DescriptionPartTwoProps extends IncidentFormStoreParts {
  incidentStore: IncidentStore;
}

export default class DescriptionPartTwo extends Component<DescriptionPartTwoProps, {}, any> {
  subs$: Array<any> = [];
  subSearch$: Subject<{ q: string }> = new Subject();
  state: any;
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      employeeList: [],
      submitType: undefined,
      isLoading: false,
    };
  }

  get initSubSearch$(): Observable<GetEmployeeBySearch> {
    const tokens: HttpTokens = { jwt: getJwtToken() };
    return this.subSearch$
      .pipe(tap(() => this.setState({ isLoading: true, error: undefined })))
      .pipe(debounceTime(500))
      .pipe(filter((n) => !!n?.q && n?.q.length > 0))
      .pipe(switchMap((val) => from(EmployeeSearchGet('employees/search', { q: val.q }, tokens))));
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    const s1 = this.initSubSearch$.subscribe({
      error: (err) => {
        Logger(['[DescriptionPartTwo][initSubSearch$][subscribe][error]', err], 'error');
        this.setState({ isLoading: false, error: err });
      },
      next: (n) => {
        Logger(['[DescriptionPartTwo][initSubSearch$][subscribe][next]', n]);
        this.setState({ employeeList: clone(n.data), isLoading: false, error: undefined });
      },
      complete: () => {},
    });

    this.subs$.push(...[s1]);
  }

  componentWillUnmount() {
    this.subs$.forEach((sub, inx) => {
      try {
        sub.unsubscribe(sub);
        console.log('will componentWillUnmount', sub);
      } catch (err) {}
    });
  }

  handleChangeStoreUpdate(value: any, keyName: string, subKeyName?: string) {
    if (!this.props.formStore) return;
    this.props.formStore.setUpdateFormItem('description', 'updated').setup(value, keyName, subKeyName);
  }

  get incidentWhoInvolved(): any {
    try {
      return this.props.formStore.store.formData.description.data;
    } catch (err) {
      return null as any;
    }
  }

  render() {
    return (
      <>
        <Card variant="outlined" className=" rounded-2xl p-6 mb-6">
          <Box className="mb-6">
            <FormControl className="w-full">
              <TextField
                id="outlined-textarea"
                label="What happened?"
                multiline
                onBlur={(e) => this.handleChangeStoreUpdate(!e.target.value ? null : e.target.value, 'whatDesc')}
                {...textFieldProps(this.props.formStore.store.formData.description.data, 'whatDesc')}
              />
            </FormControl>
          </Box>
          <Box className="mb-6">
            <FormControl className="w-full">
              <TextField
                id="outlined-textarea"
                label="Why did it happen?"
                multiline
                onBlur={(e) => this.handleChangeStoreUpdate(!e.target.value ? null : e.target.value, 'whyDesc')}
                {...textFieldProps(this.props.formStore.store.formData.description.data, 'whyDesc')}
              />
            </FormControl>
          </Box>
          <Box className="mb-6">
            <FormControl className="w-full">
              <TextField
                id="outlined-textarea"
                label="When and Where did happen?"
                multiline
                onBlur={(e) => this.handleChangeStoreUpdate(!e.target.value ? null : e.target.value, 'whenDesc')}
                {...textFieldProps(this.props.formStore.store.formData.description.data, 'whenDesc')}
              />
            </FormControl>
          </Box>
          <Box className="mb-6">
            <FormControl className="w-full">
              <TextField
                id="outlined-textarea"
                label="How did it happened?"
                multiline
                onBlur={(e) => this.handleChangeStoreUpdate(!e.target.value ? null : e.target.value, 'howDesc')}
                {...textFieldProps(this.props.formStore.store.formData.description.data, 'howDesc')}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full">
              <InputLabel shrink>Who was involved?</InputLabel>
              <TagInputSearchEmployees
                optionData={this.state.employeeList || ([] as EmployeeSchema[])}
                selectedData={this.props.formStore.store.formData.description?.data?.whoInvolved || ([] as EmployeeSchema[])}
                isLoading={this.state.isLoading}
                isErrorSearch={!!this.state.error}
                handleKeyFilter={(searchText: string) => this.subSearch$.next({ q: searchText })}
                handleOnSelect={(selected: EmployeeSchema[]) => this.handleChangeStoreUpdate(selected.length ? selected : null, 'whoInvolved')}
              />
            </FormControl>
          </Box>
        </Card>
        <AddImageSection formStore={this.props.formStore} incidentStore={this.props.incidentStore} />
      </>
    );
  }
}
