import React from 'react';
import { AppBar, Box, IconButton, Avatar, Menu, MenuItem, Tooltip, colors, Divider } from '@mui/material';
import { PropDefaults, AzureAuthProviderProps } from '@/types/index';
import { useGlobalContext } from '@/store/index';

import { isSuperAdmin, isBuAdmin, isUser, isHod, isGM, exportLinkV2, urlWithToken, windowLocationRelativeUrl } from '@/utils/index';
import { useNavigate } from 'react-router-dom';

interface HeaderProps extends PropDefaults {
  azure: AzureAuthProviderProps;
}
export const Header: React.FC<HeaderProps> = (props) => {
  // const [avatarRef, setAvatarRef] = React.useState<HTMLDivElement & { src?: string }>({} as any);
  const { accountLogout } = props.azure;
  const { currentUser } = useGlobalContext();
  const navigate = useNavigate();
  // currentUser.userProfile

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type: 'close' | 'click') => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    accountLogout();
  };
  const DisplayInDev = (props): any => {
    let text = '';
    // if (ENVS_CONFIG.REACT_APP_ENVIRONMENT === 'DEV' || ENVS_CONFIG.REACT_APP_ENVIRONMENT === 'LOCAL') {
    if (isSuperAdmin(currentUser.userProfile)) text = 'Super Admin';
    if (isBuAdmin(currentUser.userProfile)) text = 'Bu Admin';
    if (isGM(currentUser.userProfile)) text = 'General Manager';
    if (isHod(currentUser.userProfile)) text = 'Head of Department/Co-founder';
    // if (isUser(currentUser.userProfile)) text = '( User )';
    // }
    if (text) {
      return (
        <>
          <small>{text}</small>
        </>
      );
    } else return <></>;
  };

  const generalUser = isUser(currentUser.userProfile);

  // exportLinkV2 urlWithToken

  /**
   * Check if profile picture is available, otherwise provide default
   */
  const validProfilePicture = (): string => {
    const url = exportLinkV2(currentUser?.userProfile?.iconUrl as any, true);
    if (!url) return windowLocationRelativeUrl('icons/profile-icon.svg');
    else return urlWithToken(url, 'accessToken');
  };

  return (
    <AppBar position="sticky" color="inherit" className="px-4 pb-2 md:px-6 lg:px-8">
      <Box className="justify-end items-center flex relative" style={{ marginBottom: !generalUser ? -7 : 0 }}>
        <Box sx={{ color: '#505050' }} className={`text-sm mr-3  ${!generalUser ? 'mt-1' : ''} `}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ fontWeight: 500, textAlign: 'right' }}>{currentUser.displayName}</div>
            <div style={{ marginTop: -5, color: colors.grey[500], textAlign: 'right', fontSize: 'smaller' }}>
              <DisplayInDev />
            </div>
          </Box>
        </Box>
        <Box>
          <Tooltip title="Open settings">
            <IconButton onClick={handleClick} sx={{ p: 0 }}>
              <Avatar
                // ref={(e) => {
                //   if (e) setAvatarRef(e as any);
                // }}
                // imgProps={
                //   {
                //     onError: () => {
                //       avatarRef.src = windowLocationRelativeUrl('icons/profile-icon.svg');
                //       setAvatarRef(avatarRef);
                //     },
                //   } as any
                // }
                sx={{ width: 33, height: 33 }}
                alt={currentUser.nameAbbr}
                src={validProfilePicture()}
              />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => handleClose('close')}
            onClick={() => handleClose('click')}
            PaperProps={{
              elevation: 0,
              sx: {
                width: '144px',
                overflow: 'visible',
                filter: 'drop-shadow(0px 3px 6px #00000029)',
                borderRadius: 3,
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem className="text-left" onClick={() => navigate(`/profile/detail/${currentUser?.userProfile?.employeeId}`)}>
              My profile
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleSignOut()}>
              {/* <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon> */}
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </AppBar>
  );
};
