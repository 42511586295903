import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { AttendeeStore } from '@/types/store/index';
import { ENVS_CONFIG } from '@/constants/index';
import LoadingButton from '@mui/lab/LoadingButton';
import { FileUploader } from 'react-drag-drop-files';
import { AttendeeSchema } from '@/src/types';
import { useGlobalContext } from '@/store/global.store';
import { isSuperAdmin } from '@/src/utils';

type _FileUploader = typeof FileUploader;

const FileLoadUpload = (): _FileUploader => {
  return require('react-drag-drop-files').FileUploader as _FileUploader;
};

interface UploadCertificateProps extends AttendeeStore {
  index: number;
  data: AttendeeSchema;
}

export function UploadCertificate(props: UploadCertificateProps) {
  const { currentUser } = useGlobalContext();
  const { store, index, data } = props;

  const [file, setFile] = useState<Blob>(null as any);
  const [attendeeData, setAttendeeData] = useState<AttendeeSchema>(data);

  const fileTypes = ['JPG', 'PNG', 'PDF'];

  const handleSubmit = (newFile: Blob, newIndex: number) => {
    setFile(newFile);

    const formData: FormData = new FormData();
    formData.append('file', newFile as any);
    if (formData) {
      store.setAttendeeUpload(attendeeData.id, { file: formData as any });
    }

    attendeeData.certificateFileState = 'loading';
    setAttendeeData(attendeeData);

    // because we cannot reset this component state we have to cheat by re/seting our selfs
    // delay(3000).then(() => {
    //   setFile(null as any);
    //   //  attendeeData.certificateFileState = 'initial';
    // });
  };

  const onTypeError = (type) => {
    attendeeData.certificateFileState = 'initial';
    setAttendeeData(attendeeData);
    setFile(null as any);
  };
  const handleRemove = (AttendeeId) => {
    delete attendeeData.certificateFileState;
    store.setAttendeeDelete(AttendeeId);
    attendeeData.certificateFileState = 'initial';
    setFile(null as any);
  };

  useEffect(() => {
    if (attendeeData.certificateFileState === 'loading') {
      setFile(null as any);
      return;
    }
    if (attendeeData.certificateFileState === 'error') {
      attendeeData.certificateFileState = 'initial';
      setAttendeeData(attendeeData);
    }
  }, [attendeeData, data, setAttendeeData, setFile]);

  const FileLoaderWrapper = (): any => {
    const FileLoaderr = FileLoadUpload();
    return (
      <>
        {isSuperAdmin(currentUser?.userProfile) && (
          <FileLoaderr
            classes={'file-uploader'}
            fileOrFiles={file}
            multiple={false}
            handleChange={(file: Blob) => handleSubmit(file, index)}
            name="file"
            maxSize={2}
            label="Upload"
            key={index}
            onTypeError={onTypeError}
            types={fileTypes}
          />
        )}
      </>
    );
  };

  switch (attendeeData.certificateFileState) {
    case 'ready': {
      return (
        <>
          <Button
            target={'_blank'}
            href={`${ENVS_CONFIG.REACT_APP_API_BASE}/${attendeeData.certificateLink}`}
            size="small"
            className="text-black underline p-0 min-w-0 hover:bg-transparent"
          >
            View
          </Button>

          {isSuperAdmin(currentUser?.userProfile) && (
            <>
              <span className="text-gray-400 px-2">|</span>
              <Button href="#" size="small" className="text-red-500 underline p-0 min-w-0 hover:bg-transparent" onClick={() => handleRemove(attendeeData.id)}>
                Remove
              </Button>
            </>
          )}
        </>
      );
    }
    case 'loading': {
      return (
        <LoadingButton loading variant="outlined">
          Submit
        </LoadingButton>
      );
    }
    case 'initial': {
      return <FileLoaderWrapper />;
    }
    case 'error': {
      attendeeData.certificateFileState = 'initial';
      setAttendeeData(attendeeData);
      return <FileLoaderWrapper />;
    }
    default: {
      return <FileLoaderWrapper />;
    }
  }
}
