import React, { useState, useEffect } from 'react';
import { Icon } from '@/src/components';
import { Box, IconButton, styled, CircularProgress } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ReactECharts from 'echarts-for-react';
import { PropDefaults, LegalStore, BuComplianceSchema, ComplianceGraphSelectedValue, ReducerActionType } from '@/src/types';
import { Logger } from '@/logger/index';
import { complianceOptions } from './chart.options';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    width: '126px',
    padding: '8px 14px',
    lineHeight: 1.6,
  },
}));

interface Props extends PropDefaults {
  chartQueryState: ReducerActionType;
  // [name: string]: any;
  // azure: AzureAuthProviderProps;
  // title?: string;
  onChartReady: () => void;
  onChartClick: (data: ComplianceGraphSelectedValue) => void;
}

type ChartOptions = typeof complianceOptions;

export function ComplianceChart(props: Props & LegalStore) {
  const { store: legalStore, onChartClick, onChartReady, chartQueryState } = props;
  const [mount, setMount] = useState<number>(0);
  const [chartInstance, setChartInstance] = useState<ReactECharts>(null as any);
  const [complianceData, setCompliance] = useState<BuComplianceSchema[]>([] as any);
  const [optionsData, setOptions] = useState<ChartOptions>(complianceOptions);
  const updateChart = () => {
    if (chartInstance) {
      const inst = chartInstance.getEchartsInstance();
      inst.setOption(optionsData as any);
      inst.resize();
    }
  };
  useEffect(() => {
    if (legalStore.legalChartsData.state === 'ready' && !!legalStore.legalChartsData.data?.data) {
      // NOTE there is no way to provide reference key to the chart so we will use the array index position instead
      const comp = legalStore.legalChartsData.data.data.compliance;

      const relatedBu = comp.map((n) => n.relatedBU);
      const compliance = comp.map((n) => n.compliance);
      const nonCompliance = comp.map((n) => n.nonCompliance);

      // assign bu names
      if (optionsData.yAxis.type === 'category') {
        optionsData.yAxis.data = relatedBu.map((n) => n.buName) as any;
      }
      if (optionsData.yAxis.type === 'category') {
        optionsData.series.forEach((n, inx) => {
          if (n.id === 'compliance') {
            optionsData.series[inx].data = compliance as any;
          }
          if (n.id === 'non-compliance') {
            optionsData.series[inx].data = nonCompliance as any;
          }
        });
      }

      let _maxLength = 0;
      optionsData.yAxis.data.forEach((d) => {
        _maxLength = Math.max(_maxLength, `${d}`.length);
      });
      optionsData.grid.left = `${_maxLength * 0.75}`;

      setCompliance(comp);
      setOptions(optionsData);
      updateChart();
    }

    if (legalStore.legalChartsData.state === 'error') {
      optionsData.series[0].name = '';
      optionsData.series[0].data = [];
      optionsData.series[1].name = '';
      optionsData.series[1].data = [];
      optionsData.title.show = true;
      optionsData.yAxis.data = [];
      setOptions(optionsData);
      updateChart();
    }

    if (!mount) {
      setMount(1);
    }
  }, [setMount, mount, legalStore, optionsData, chartQueryState, chartInstance, setChartInstance]);

  const onEvents = {
    click: (e: any) => {
      let found = false;
      // NOTE this os not a nice way to compare raw strings by label, but i dont see any other option
      const seriesId: string = e.seriesId;
      const value: number = e.value as any;
      const index: number = e.dataIndex;
      for (let inx = 0; inx < complianceData.length; inx++) {
        if (index === inx) {
          if (complianceData[inx].compliance === value && seriesId === 'compliance') {
            onChartClick({ relatedBU: complianceData[inx].relatedBU, value, type: 'C' });
            found = true;
            break;
          }
          if (complianceData[inx].nonCompliance === value && seriesId === 'non-compliance') {
            onChartClick({ relatedBU: complianceData[inx].relatedBU, value, type: 'NC' });
            found = true;
            break;
          }
        }
      }
      if (!found) {
        Logger(['[ComplianceChart][click][not-found]', 'on chart click no index match found', e], 'warn');
      }
    },
  };

  return (
    <Box className="p-6 rounded-2xl border border-solid border-gray-200 bg-white relative h-auto w-1/2 shadow-md">
      <LightTooltip title="Click on each data to filter the list below." arrow>
        <IconButton className="p-0 ml-auto absolute top-2 right-2 z-50">
          <Icon icon="circleInfo" color="#3155FF" size={22} className="ml-auto" />
        </IconButton>
      </LightTooltip>
      {chartQueryState?.value?.loading && (
        <CircularProgress className="mr-2 absolute z-40" sx={{ top: 'calc(50% - 15px)', left: 'calc(50% - 15px)' }} size={40} />
      )}
      <ReactECharts
        ref={(r: any) => {
          if (r) {
            r.inx = (r.inx || 0) + 1;
            setChartInstance(r as any);
          }
        }}
        className={`${chartQueryState?.value?.loading && 'opacity-25'}`}
        option={optionsData}
        onChartReady={onChartReady}
        onEvents={onEvents}
      />
    </Box>
  );
}
