import React, { useEffect } from 'react';

import { Alert, Box, IconButton, LinearProgress, Paper, Skeleton, Typography } from '@mui/material';
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ChecklistIncident, Icon, IncidentForm, PreloaderIncidentForm, ProgressBarIncident } from '@/src/components';
import { NavLink, useOutletContext } from 'react-router-dom';

import { AzureAuthProviderProps, IncidentStore } from '@/types/store';
import { PropDefaults } from '@/types/common.d';
import { Logger } from '@/logger/index';
import { QueryIncidentTypes } from '@/src/types';
import { checkAllApiDataReady, getClassNameStatusReport } from '@/src/utils/form';
import { incidentStatusText, PAGE_NOT_FOUND_MESSAGE, PAGE_NO_PERMISSION } from '@/src/static';
import { userCanOnIncident } from '@/src/utils';
import { useGlobalContext } from '@/store/index';

interface IncidentProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
  pageType: 'update' | 'create';
}

const PAGE_TITLE_CREATE = 'Create incident report';
// const PAGE_TITLE_UPDATE = 'Update incident report';
const queryTypes = [
  // use in part 1 Initial Report
  'activities',
  'events',
  // use in part 3 Injured
  'injury',
  'bodyRight',
  'bodyLeft',
  'headNeck',
  'upperLimbs',
  'lowerLimbs',
  // use in part 6 Causes Assessment
  'rulesPermits',
  'temp',
  'methods',
  'inattentionLack',
  'systems',
  'tempv',
  'workHazards',
  'organizations',
  'managements',
  'rootCauses',
];

interface IncidentCreateUpdateContextProps extends IncidentProps {
  /** incident report by id only available from pageType:update on this component */
  updateId?: string;
}

export const IncidentCreateUpdate: React.FC<IncidentProps> = (props, context) => {
  const { currentUser } = useGlobalContext();
  const { pageType, history } = props;
  const contextProps: IncidentCreateUpdateContextProps & IncidentStore = useOutletContext();
  const { store, updateId } = contextProps;
  const {
    incidentBusinessUnitsFilter,
    // incidentCreateUpdateData,
    incidentQueryTypesData,
    setIncidentBusinessUnitsFilter,
    // setIncidentCreateUpdate,
    setIncidentQueryTypes,
    setIncidentDetail,
    setIncidentProgress,
    incidentProgressData,
    incidentDetailData,
  } = store;

  const [mount, setMount] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const handleChange = (step: number, checkRequired?: string) => {
    if (checkRequired === 'openRequiredField') {
      setActiveStep(0);
    } else {
      if (step === activeStep) setActiveStep(-1);
      else setActiveStep(step);
    }
  };

  useEffect(() => {
    // get initial data from API
    if (!mount) {
      if (pageType === 'update') {
        if (incidentDetailData.state === 'ready' && incidentDetailData.data?.data?.id !== updateId) {
          store.resetStatus('incidents/report/parts');
          return;
        }

        if (incidentDetailData.state === 'initial' /* && !incidentDetailData.data*/) {
          // NOTE {updateId} can be used instead if qwe already have it available
          // const mockUid = '89df2c24-3f1d-4ed2-87f2-b42bd38f3918';
          setIncidentDetail(updateId as any);
        }
      }

      // NOTE  do we need any logic here for pageType ?
      if (incidentQueryTypesData.state === 'initial' && !incidentQueryTypesData.data) {
        setIncidentQueryTypes({
          type: queryTypes,
        } as QueryIncidentTypes);
      }
      if (incidentBusinessUnitsFilter.state === 'initial' && !incidentBusinessUnitsFilter.data) {
        setIncidentBusinessUnitsFilter();
      }
      setMount(1);
    }
  }, [mount, setMount, updateId, incidentDetailData, setIncidentBusinessUnitsFilter]);

  useEffect(() => {
    if (pageType === 'update') {
      if (incidentProgressData.state === 'initial' && !incidentProgressData.data?.data && updateId) {
        setIncidentProgress(updateId);
      }
    }
  }, [incidentProgressData, setIncidentProgress]);

  useEffect(() => {
    return () => {
      store.resetStatus('incidents/progress');
    };
  }, []);

  Logger([`[IncidentCreateUpdate][pageType][${pageType}]`]);
  if (pageType === 'create') {
    Logger(['[IncidentCreate][incidentBusinessUnitsFilter]', incidentBusinessUnitsFilter]); // this state use in part 1 section 2
    Logger(['[IncidentCreate][incidentQueryTypesData]', incidentQueryTypesData]); // this state use in part 1 section 3
  }

  if (pageType === 'update') {
    Logger(['[IncidentUpdate][incidentBusinessUnitsFilter]', incidentBusinessUnitsFilter]); // this state use in part 1 section 2
    Logger(['[IncidentUpdate][incidentQueryTypesData]', incidentQueryTypesData]); // this state use in part 1 section 3
    if (incidentDetailData.state === 'error') {
      Logger(['[IncidentCreateUpdate][error]', incidentDetailData], 'error');
    }
    if (incidentDetailData.state === 'ready') {
      Logger(['[IncidentCreateUpdate]', incidentDetailData]);
    }
  }

  const isApisReady = checkAllApiDataReady(
    [
      { apiName: 'incidentBusinessUnitsFilter', ...incidentBusinessUnitsFilter },
      { apiName: 'incidentQueryTypesData', ...incidentQueryTypesData },
      (pageType === 'update' ? { apiName: 'incidentDetailData', ...incidentDetailData } : null) as any,
    ].filter((n) => !!n)
  );

  if (incidentBusinessUnitsFilter.state === 'error') {
    Logger(['[store][incidentBusinessUnitsFilter]', incidentBusinessUnitsFilter.error], 'error');
  }
  if (incidentQueryTypesData.state === 'error') {
    Logger(['[store][incidentQueryTypesData]', incidentQueryTypesData.error], 'error');
  }

  const noPermissionsToAccess =
    pageType === 'update' &&
    !!store.incidentDetailData.data?.data &&
    ['ready'].indexOf(store.incidentDetailData.state) !== -1 &&
    // if cannot access after data loaded
    userCanOnIncident(currentUser, store.incidentDetailData.data?.data, store.incidentDetailData.data?.data?.status as any, 'can_edit') === false;

  const state: 'ready' | 'error' | 'loading' | 'not_found' | 'no_access' = [
    pageType === 'create' && 'ready',
    pageType === 'update' && ['error'].indexOf(store.incidentDetailData.state) !== -1 && 'not_found',
    // REVIEW temporarily disabled the logic because api is missing initialReport/employeeId so cannot verify report belong to ...
    noPermissionsToAccess && 'no_access',
    pageType === 'update' && ['error'].indexOf(store.incidentDetailData.state) === -1 && 'ready',
  ].filter((n) => !!n)[0] as any;

  // for now the status is invalid
  const DisplayStatus = (props: any) => {
    const status = incidentDetailData.data?.data?.status;
    if (!status) {
      return <></>;
    } else {
      return <span className={`py-[3px] px-2 text-sm my-auto font-normal rounded ml-3 ${getClassNameStatusReport(status)}`}>{incidentStatusText[status]}</span>;
    }
  };

  // console.log('noPermissionsToAccess', noPermissionsToAccess, 'getEmployeeId', getEmployeeId(store.incidentDetailData.data));

  switch (state) {
    /**
     * User try to access edit draft when status is not in draft
     * any other user try to access this page that is not an admin
     * Super admin try to access this page page when its already in ongoing mode
     */
    case 'no_access': {
      return <Alert severity="error">{PAGE_NO_PERMISSION}</Alert>;
    }
    case 'ready': {
      return (
        <Paper elevation={0}>
          {/** NOTE added to help debug we are using correct data */}
          {/* {pageType === 'update' && <> debug/uid: {store.incidentDetailData.data?.data?.id}</>} */}
          <Box className="flex items-center mb-6 ">
            <NavLink to={`/incidents`}>
              <IconButton color="primary" aria-label="View details" className="" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
              </IconButton>
            </NavLink>
            <Typography variant="h1" className="flex">
              {pageType === 'update' ? store.incidentDetailData?.data?.data?.incidentNo : PAGE_TITLE_CREATE}
              <DisplayStatus />
            </Typography>
          </Box>
          <Paper elevation={0} className="flex bg-transparent">
            <Box className="w-full" sx={{ maxWidth: 'calc(100% - 400px)' }}>
              {isApisReady ? (
                <IncidentForm
                  updateId={updateId}
                  incidentStore={{ store }}
                  pageType={pageType}
                  history={history}
                  checklistProps={{ activeStep, handleChange }}
                />
              ) : (
                <PreloaderIncidentForm />
              )}
            </Box>
            <Box
              className="overflow-y-auto overflow-x-hidden h-full w-full sticky ml-6 right-8 scrollbar"
              sx={{
                maxWidth: '374px',
                maxHeight: 'calc(100vh - 212px)',
                top: '124px',
              }}
            >
              {isApisReady ? (
                <>
                  <ChecklistIncident checklistProps={{ activeStep, handleChange }} />
                  {pageType === 'update' ? <ProgressBarIncident {...(contextProps as any)} {...(props as any)} /> : ''}
                </>
              ) : (
                <>
                  <Skeleton animation="wave" className="w-full h-[428px] mb-5 rounded-2xl transform-none bg-gradient-to-t from-blue-100 to-white" />
                  {pageType === 'update' ? (
                    <Skeleton animation="wave" className="w-full h-[382px] mb-5 rounded-2xl transform-none bg-gradient-to-t from-blue-100 to-white" />
                  ) : (
                    ''
                  )}
                </>
              )}
            </Box>
          </Paper>
        </Paper>
      );
    }

    // case 'not_found': {
    //   // when item deleted redirect to trainings
    //   return <Navigate to={`/incidents`} />;
    //   // return <Alert severity="error">{PAGE_NOT_EXIST_MESSAGE}</Alert>;
    // }

    // NOTE for not found will display if api error, because not found by id, or any server error
    case 'not_found': {
      return <Alert severity="error">{PAGE_NOT_FOUND_MESSAGE}</Alert>;
    }
    case 'loading': {
      return <LinearProgress />;
    }

    default: {
      return null;
    }
  }
};
