/** declare only http service related requests */

import { HttpTokens, GetAttendeeList, QueryAttendeeList } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { paramsQueryString } from '@/utils/index';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get attendee list, paginated results
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const AttendeeListGet = (
  prefix: string = 'training/attendees',
  trainingId: string,
  params: QueryAttendeeList,
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetAttendeeList> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const queryUri = paramsQueryString(`${prefix}/${trainingId}`, params);
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetAttendeeList>) => {
      // NOTE  mock file status type non existent on api
      const data = d.data.data.map((n) => {
        if (n.certificateLink) {
          n.certificateFileState = 'ready';
        } else {
          n.certificateFileState = 'initial';
        }
        return n;
      });
      d.data.data = data;

      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetAttendeeList>;
};
