import React, { useEffect } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { EmployeeSchema } from '@/src/types/schema';
import { withAdminContext } from '@/store/admins.store';
import { AdminStore } from '@/src/types/store';
import { toastMessages } from '@/src/static';
import { SnackBarAlert } from '@/src/components';
import { printRoleNameById } from '@/src/utils';
// test
interface prop {
  onCAllBack: any;
  openEdit: boolean;
  employee: EmployeeSchema;
}

function EditAdminModal(props: prop & AdminStore) {
  const { onCAllBack, openEdit, employee, store } = props;

  const { resUpdateEmployees, updateEmployees } = store || {};

  const [opensSnackbar, setOpensSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState<'error' | 'success'>('success');
  const [roleId, setRoleId] = React.useState('');

  const handleSave = () => {
    updateEmployees(employee.employeeId, { roleId: roleId });
  };

  const handleClose = () => {
    onCAllBack('setOpenEdit', false);
    store.resetStatus('employee/update');
  };

  useEffect(() => {
    if (employee.roleId) {
      setRoleId(`${employee.roleId}`);
    }
  }, [employee.roleId]);

  useEffect(() => {
    if (resUpdateEmployees.state === 'initial' || resUpdateEmployees.state === 'loading') return;
    if (resUpdateEmployees.state === 'error') {
      setSeverity('error');
    }
    if (resUpdateEmployees.state === 'ready') {
      setSeverity('success');
    }

    setOpensSnackbar(true);
    onCAllBack('setOpenEdit', false);
  }, [resUpdateEmployees]);

  return (
    <>
      <SnackBarAlert
        messages={{
          error: toastMessages.adminNotUpdated,
          success: toastMessages.adminUpdated,
        }}
        severity={severity}
        state={opensSnackbar as any}
        onClose={() => {
          setOpensSnackbar(false);
        }}
      />

      <Dialog open={openEdit} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle className="mb-4 p-0">
          <Typography className="font-medium text-2xl">Edit admin</Typography>
        </DialogTitle>
        <DialogContent className="mb-5 p-0">
          <FormControl className="mb-6 w-full">
            <InputLabel shrink>Name</InputLabel>
            <Typography variant="body2">{`${employee.firstName} ${employee.lastName} (${employee.buName})`}</Typography>
          </FormControl>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Select role</FormLabel>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="roleId"
              value={roleId}
              onChange={(e) => {
                setRoleId(`${e.target.value}`);
              }}
            >
              {/** to decide should new user be able to see both options if they are only BU Admin ? */}
              <FormControlLabel className="my-0" value={1} control={<Radio />} label={printRoleNameById(1)} />
              <FormControlLabel className="my-0" value={2} control={<Radio />} label={printRoleNameById(2)} />
              <FormControlLabel className="my-0" value={4} control={<Radio />} label={printRoleNameById(4)} />
              <FormControlLabel className="my-0" value={5} control={<Radio />} label={printRoleNameById(5)} />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions className="p-0 justify-start">
          <Button className="mr-1 w-28" variant="contained" onClick={handleSave}>
            SAVE
          </Button>
          <Button className="w-28" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withAdminContext(EditAdminModal);
