import { CourseTrainingTypeSchema, HeadCell, InstructionsList } from '@/types/index';

export const instructionsList: InstructionsList = {
  1: 'Internal',
  2: 'External',
};

/**
 * The server will provide training list from api that will always match by code
 * trainingName may always change never reference by it
 */
export const courseTrainingList: CourseTrainingTypeSchema[] = [
  {
    trainingCode: 'classroom',
    trainingName: 'Classroom',
  },
  {
    trainingCode: 'online',
    trainingName: 'Online',
  },
  {
    trainingCode: 'e-learning',
    trainingName: 'E-learning',
  },
  {
    trainingCode: 'classroom',
    trainingName: 'Classroom',
  },
];

export const headCellsCourses: HeadCell[] = [
  {
    id: 'id',
    label: 'Course no.',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 6,
    textAlign: 'inherit',
  },
  {
    id: 'name',
    label: 'Course name',
    disablePadding: false,
    sortAble: false,
    width: 2.8 / 6,
    textAlign: 'inherit',
  },
  {
    id: 'type',
    label: 'Training type',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 6,
    textAlign: 'inherit',
  },
  {
    id: 'totalTrainingTime',
    label: 'Total training time',
    disablePadding: false,
    sortAble: false,
    width: 0.9 / 6,
    textAlign: 'right',
  },
  {
    id: 'download',
    label: 'Download',
    disablePadding: false,
    sortAble: false,
    width: 0.7 / 6,
    textAlign: 'center',
  },
  {
    id: 'actions',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 1 / 6,
    textAlign: 'inherit',
  },
];
