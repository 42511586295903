export const mhOptions = {
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}',
  },
  legend: {
    left: 'center',
    textStyle: {
      fontSize: 16,
    },
  },
  xAxis: {
    type: 'category',
    splitLine: { show: true },
    data: [],
    boundaryGap: true,
  },
  grid: {
    top: '14%',
    bottom: '12%',
    left: '5%',
    right: '5%',
    containLabel: true,
  },
  yAxis: {
    type: 'value',
    minorSplitLine: {
      show: true,
    },
  },
  series: [
    {
      name: 'Man-hours',
      type: 'line',
      data: [],
      color: '#6D9BE6',
      symbol: 'circle',
      label: {
        show: true,
        position: 'top',
      },
    },
  ],
};
