/** declare only http service related requests */

import { HttpTokens, GetTrainingsThisMonth } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
// import { paramsQueryString } from '@/utils/index';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get training updates for this month
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const TrainingThisMonthGet = (prefix: string = 'training/this-month', tokens: HttpTokens, hardRejection = true): Promise<GetTrainingsThisMonth> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // token not yet set on backend
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<GetTrainingsThisMonth>) => {
      // return Promise.reject(new Error('ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetTrainingsThisMonth>;
};
