import React, { useState, useEffect } from 'react';
import { PropDefaults } from '@/types/common';
import { Paper, Typography, Box, LinearProgress } from '@mui/material';
import { CreateEditCourseModal, EnhancedTableCourses, EnhancedTablePagination, FilterMultiSelect, SearchBoxInput, SnackBarAlert } from '@/components/index';
import { AzureAuthProviderProps, CourseStore } from '@/src/types/store';
import { CallBackFilterType, QueryCourseList, CourseTrainingTypeSchema } from '@/src/types';
import { useGlobalContext } from '@/store/index';
import { toastMessages } from '@/src/static';
import { checkIsLastPage, isSuperAdmin } from '@/src/utils';

const PAGE_TITLE = 'Courses';
interface CoursesProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

function Courses(props: CoursesProps & CourseStore) {
  const { currentUser } = useGlobalContext();
  const { store } = props;
  const { courseList, setCourseList, courseTrainingTypeFilter, setCourseTrainingTypes, courseDeletedData } = store;

  const [mount, setMount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [trainingTypeFilter, setTrainingTypeFilter] = React.useState<string[]>([]);
  const [nameSearching, setNameSearching] = React.useState<string>('');
  const [alertState, setAlertState] = useState<boolean | undefined>(undefined as any);
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success');
  const [toastDisplayMessages, setToastMessages] = useState<{ error?: string; success?: string }>({ success: '', error: '' });

  useEffect(() => {
    if (!mount) {
      if (courseList.state === 'initial' && !courseList.data) setCourseList({ pageNumber: page, itemPerPage: rowsPerPage });
      if (courseTrainingTypeFilter.state === 'initial' && !courseTrainingTypeFilter.data) setCourseTrainingTypes();
      setMount(1);
    }
  }, [mount, setMount]);

  useEffect(() => {
    return () => {
      // NOTE: to reset store.courseList back to the data with initial filter after unmount
      store.resetStatus('courses/list');
    };
  }, []);

  useEffect(() => {
    if (courseDeletedData.state === 'ready') {
      // to check if the current page is the last page
      const isLastPage = checkIsLastPage(courseList.data.pagination.pageNumber, courseList.data.pagination.totalItems, courseList.data.pagination.itemPerPage);

      const params: QueryCourseList = {
        pageNumber: page,
        itemPerPage: rowsPerPage,
      };
      if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
      if (nameSearching) params.filter = nameSearching;

      // to check if the row that has been deleted is only a last one item in the last list page then paginate to previous pageNumber (except for last page = 0)
      if (courseList.data.data.length === 0 && isLastPage && courseList.data.pagination.pageNumber !== 0) {
        params.pageNumber = page - 1;
        setPage(page - 1);
        setCourseList(params);
      } else {
        setCourseList(params);
      }
    }
  }, [courseDeletedData]);

  useEffect(() => {
    // console.log('[Courses] get in useEffect store.courseUpdatedData.state >>>', store.courseUpdatedData.state);
    // console.log('[Courses] get in useEffect alertState >>>', alertState);
    // we have update the date on the form we can not close the modal
    if (store.courseUpdatedData.state === 'ready') {
      if (!alertState) {
        setToastMessages({ success: toastMessages.dataUpdated } as any);
        setAlertSeverity('success');
        setAlertState(true);
        return;
      }
    }

    if (store.courseUpdatedData.state === 'error') {
      // Logger(['[store][courseUpdatedData]', store.courseUpdatedData.error], 'error');
      setToastMessages({ error: toastMessages.dataNotUpdated });
      if (!alertState) {
        setAlertSeverity('error');
        setAlertState(true);
        return;
      }
    }

    if (store.courseCreatedData.state === 'error') {
      setToastMessages({ error: toastMessages.dataNotAdded });
      if (!alertState) {
        setAlertSeverity('error');
        setAlertState(true);
        return;
      }
    }

    if (store.courseCreatedData.state === 'ready') {
      if (!alertState) {
        setToastMessages({ success: toastMessages.dataAdded } as any);
        setAlertSeverity('success');
        setAlertState(true);
      }
    }
  }, [store, alertState, setToastMessages, setAlertState, setAlertSeverity]);

  // Logger(['[courseList][data]', courseList], 'log');
  // Logger(['[courseTrainingTypeFilter][data]', courseTrainingTypeFilter], 'log');
  // Logger(['[courseFilterByList][data]', courseFilterByList], 'log');

  const onCallBackFilter = (type: CallBackFilterType, payload?: any) => {
    setPage(0);
    const params: QueryCourseList = {
      pageNumber: 0,
      itemPerPage: rowsPerPage,
    };
    if (nameSearching) params.filter = nameSearching;
    if (type === 'setSelectedFilter' && payload !== undefined) {
      setTrainingTypeFilter(payload);
      if (payload.length) params.trainingCodes = payload.join(',');
    } else if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
    if (type !== 'finishSelectingFilter') {
      setCourseList(params);
    }
  };

  const onCallBackPagination = (type: 'setRowsPerPage' | 'setPage' | 'setNewPage', payload: any) => {
    if (type === 'setPage' && payload !== undefined) setPage(payload);
    if (type === 'setRowsPerPage' && payload !== undefined) setRowsPerPage(payload);
    if (type === 'setNewPage' && payload !== undefined) {
      const params: QueryCourseList = {
        pageNumber: payload.newPage,
        itemPerPage: payload.rowsPerPage,
      };
      if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
      if (nameSearching) params.filter = nameSearching;
      setCourseList(params);
    }
  };

  const onEnterSearch = (searchText: string) => {
    setPage(0);
    setNameSearching(searchText);
    const params: QueryCourseList = {
      pageNumber: 0,
      itemPerPage: rowsPerPage,
    };
    if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
    if (searchText) params.filter = searchText;
    setCourseList(params);
  };

  const onClearSearch = () => {
    if (nameSearching) {
      setPage(0);
      setNameSearching('');
      const params: QueryCourseList = {
        pageNumber: 0,
        itemPerPage: rowsPerPage,
      };
      if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
      setCourseList(params);
    }
  };

  const paginationState =
    courseList.state === 'ready'
      ? {
          // if store ready, set value from store
          totalItems: courseList.data?.pagination.totalItems,
          page: courseList.data?.pagination.pageNumber,
          rowsPerPage: courseList.data?.pagination.itemPerPage,
        }
      : {
          // if store not ready, set default value for show pagination
          totalItems: rowsPerPage * (page + 1),
          page: page,
          rowsPerPage: rowsPerPage,
        };

  return (
    <Paper elevation={0}>
      <Typography variant="h1" className="mb-6">
        {PAGE_TITLE}
      </Typography>
      <Paper elevation={4}>
        <Box className="flex  p-6">
          <SearchBoxInput onEnterSearch={onEnterSearch} onClearSearch={onClearSearch} />

          <FilterMultiSelect
            clearable
            filterData={courseTrainingTypeFilter?.data?.data || ([] as CourseTrainingTypeSchema[])}
            isLoadingFilterData={courseTrainingTypeFilter.state !== 'ready'}
            selectedFilter={trainingTypeFilter}
            onCallBackFilter={onCallBackFilter}
            dropdownSetting={{
              valueKey: 'trainingCode',
              labelKey: 'trainingName',
              noSelectedText: 'All type',
            }}
            sx={{ width: '200px' }}
          />
          {isSuperAdmin(currentUser?.userProfile) && <CreateEditCourseModal store={store} />}
        </Box>
        <Box className="px-6">{store && <EnhancedTableCourses store={store} isFiltered={!!nameSearching || trainingTypeFilter.length > 0} />}</Box>
        <Box>
          {(courseList.state === 'initial' || courseList.state === 'loading' || courseDeletedData.state === 'ready') && (
            <LinearProgress style={{ margin: '0px 24px 0px 24px' }} />
          )}
        </Box>
        {courseList.state !== 'error' && (
          <EnhancedTablePagination
            totalItems={paginationState.totalItems}
            page={paginationState.page}
            rowsPerPage={paginationState.rowsPerPage}
            onCallBackPagination={onCallBackPagination}
            isSoftReload={courseDeletedData && courseDeletedData.state === 'ready'}
          />
        )}
        <SnackBarAlert
          messages={toastDisplayMessages as any}
          severity={alertSeverity}
          state={alertState as any}
          onClose={() => {
            setAlertState(false);
          }}
        />
      </Paper>
    </Paper>
  );
}

export default Courses;
