import { AzureAuthProviderProps, NavRoute, PropDefaults, TrainingStore } from '@/src/types';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import Trainings from './index';
import { Alert, LinearProgress } from '@mui/material';
import { PAGE_NOT_EXIST_MESSAGE, PAGE_NOT_FOUND_MESSAGE } from '@/src/static';

interface TrainingsProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
}

function TrainingRouter(_props: TrainingsProps & TrainingStore) {
  const { store } = _props;

  const { detail } = useParams();
  const location = useLocation();
  const [mount, setMount] = useState<number>(0);
  const [pageError, setPageError] = useState<'deleted' | 'not_found'>(null as any);

  const calendarLocation: NavRoute =
    location.pathname.indexOf('trainings/calendar') !== -1 ? { ...location, path: location.pathname, id: '3.2', name: 'Calendar' } : (null as any);

  useEffect(() => {
    // reset error if calling again
    if (store.trainingDetailData.state === 'error' && !pageError && !!detail) {
      setPageError('not_found');
    }

    if (!!store.trainingDetailData.data?.data && !!detail && store.trainingDetailData.data?.data?.id !== detail) {
      // NOTE clear state for new detail route, so it doesnt default to old data
      store.resetStatus('training/detail');
      setMount(0);
      // dont allow routing with not updated data
    } else if (['ready', 'deleted'].includes(store.trainingDetailData.state) && detail === store.trainingDetailData.data?.data?.id) {
      setPageError(null as any);
    }
    if (!mount) {
      setMount(1);
    }
  }, [store, detail, setMount, mount, pageError, setPageError]);
  // NOTE for every route change to child we need to reset store state because it contains data from page we visited previously

  const pageType:
    | 'trainings-parent'
    | 'detail-child'
    | 'detail-draft-child'
    | 'detail-loading'
    | 'detail-child-deleted'
    | 'detail-child-not-found'
    | 'calendar'
    | 'detail-child-error' = [
    // NOTE NEED TO COME FIRST
    mount === 0 && 'detail-loading',
    !!calendarLocation && 'calendar',
    !!detail && pageError === 'deleted' && 'detail-child-deleted',
    !!detail && store.trainingDetailData.state === 'error' && 'detail-child-not-found',
    !!detail && store.trainingDetailData?.data?.data?.id === detail && store.trainingDetailData?.data?.data?.status === 1 && 'detail-draft-child',

    !!detail && 'detail-child',
    !detail && 'trainings-parent',
  ].filter((n) => !!n)[0] as any;

  switch (pageType) {
    case 'calendar': {
      const outletProps = {
        // TODO add Calendar own store later
        ..._props,
      };
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }
    case 'detail-loading': {
      return <LinearProgress />;
    }

    case 'detail-child-deleted': {
      return <Alert severity="error">{PAGE_NOT_EXIST_MESSAGE}</Alert>;
    }

    case 'detail-child-not-found': {
      return <Alert severity="error">{PAGE_NOT_FOUND_MESSAGE}</Alert>;
    }

    case 'trainings-parent': {
      return <Trainings {...(_props as any)} />;
    }
    case 'detail-draft-child': {
      return <Alert severity="error">Cannot view draft page</Alert>;
    }

    case 'detail-child': {
      const outletProps = {
        ..._props,
        detail,
      };
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }
    default: {
      return <>No mach for courses found</>;
    }
  }
}

export default TrainingRouter;
