/** @type {import('../../types/common').MockApiData} */
exports.businessUnits = {
  // what type of request: [get,put,post], mainly using get
  type: 'get',
  // api name after ./api prefix, api/example
  uniqId: 'get-business-units',
  /**
   * any data: can be object, array etc, depending what you want to return
   * - data is added thru AxiosService > interceptors.response after https://httpbin.org/ request was successful
   * */
  response: {
    status: {
      code: 200,
      message: 'success',
    },
    data: [
      {
        buCode: 'ROV',
        buName: 'Rovula',
      },
      {
        buCode: 'VRN',
        buName: 'Varuna',
      },
      {
        buCode: 'ARV',
        buName: 'ARV Core',
      },
    ],
  },
};
