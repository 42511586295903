/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @api examples: https://javascript.plainenglish.io/building-a-rich-text-editor-with-react-draft-wysiwyg-2b2f99a57907
 */

import { Card, Box, Typography, FormControl } from '@mui/material';
import React, { useState, useEffect, Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { IncidentFormStoreParts, IncidentStore } from '@/src/types';
import draftToHtml from 'draftjs-to-html';
// import { Logger } from '@/logger/index';
import { DeleteImage } from '@/static/index';

interface IncidentInvestigationPartFiveProps {
  formStore: any;
  incidentStore: IncidentStore;
}

interface CustomOptionProps {
  onClear: () => void;
}
class CustomOption extends Component<CustomOptionProps> {
  render() {
    return (
      <>
        {
          <div
            className="mt-2 ml-1"
            onClick={() => {
              this.props.onClear();
            }}
          >
            <DeleteImage />
          </div>
        }
      </>
    );
  }
}

export const IncidentInvestigationPartFive = (props: IncidentInvestigationPartFiveProps & IncidentFormStoreParts) => {
  const {
    formStore,
    // incidentStore: { store: incidentStore },
  } = props;
  const incidentInvestigationData = formStore.store.formData.incidentInvestigation?.data || null;
  const [mount, setMount] = useState<number>(0);
  const [editorState, setEditorState] = useState<any>(() => EditorState.createEmpty());
  const handleChangeIncidentInvestigationData = (value: any, keyName: string) => {
    formStore.setUpdateFormItem('incidentInvestigation', 'updated').setup(value, keyName);
  };
  const onShapeBlur = (htmlText) => {
    htmlText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    handleChangeIncidentInvestigationData(htmlText, 'htmlText');
  };

  useEffect(() => {
    if (!mount) setMount(1);

    if (mount) {
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(incidentInvestigationData?.htmlText || '') as any)));
      // }
    }
  }, [mount, setMount]);

  return (
    <React.Fragment>
      {mount && editorState && (
        <Card variant="outlined" className=" rounded-2xl p-6">
          <Box className="flex">
            <img src={require('@/assets/icons/folder.png')} alt="Occupational" width={29} height={29} className="mr-3" />
            <Typography variant="h5">Required in preliminary report, summarize the sequence of events, the unsafe factors and the resulting injury.</Typography>
          </Box>
          <Box className="mt-4">
            <Box className="RichEditor-root rounded-xl " style={{ height: 'auto' }}>
              <FormControl className="w-full">
                <Editor
                  editorState={editorState}
                  toolbarCustomButtons={[
                    <CustomOption
                      key={1}
                      onClear={() => {
                        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray([] as any) as any));
                        handleChangeIncidentInvestigationData(null, 'htmlText');
                      }}
                    />,
                  ]}
                  onEditorStateChange={(e) => {
                    setEditorState(e as any);
                  }}
                  wrapperClassName="wrapper-class"
                  toolbar={{
                    options: ['inline', 'link', 'list' /* 'history'*/],

                    inline: {
                      options: ['bold', 'italic'],
                    },
                    list: {
                      options: ['unordered', 'ordered'],
                    },
                  }}
                  onBlur={(e) => onShapeBlur(e.target)}
                />
              </FormControl>
            </Box>
          </Box>
        </Card>
      )}
    </React.Fragment>
  );
};
