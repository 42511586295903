import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, FormControl, InputLabel, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { IncidentStore, IncidentFormStoreParts } from '@/src/types';
import { textFieldProps } from '@/src/utils/form';
import { TagsInputIncident } from '@/src/components';

interface PartSixSectionOneProps {
  formStore: IncidentFormStoreParts;
  incidentStore: IncidentStore;
}

export const PartSixSectionOne = (props: PartSixSectionOneProps) => {
  const {
    formStore: { formStore },
    incidentStore: { store: incidentStore },
  } = props;

  const causesAssessmentData = formStore.store.formData.incidentCausesAssessment?.data || {};

  const handleChangeCausesAssessmentData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('incidentCausesAssessment', 'updated').setup(value, keyName, subKeyName);
  };

  const handleMultiSelection = (type: 'select' | 'delete', value: any, keyName: string, subKeyName?: string) => {
    if (type === 'select') handleChangeCausesAssessmentData(value.length > 0 ? value : null, keyName, subKeyName);
    if (type === 'delete') {
      let newValueArray = [];
      if (subKeyName && causesAssessmentData[keyName]) {
        newValueArray = causesAssessmentData[keyName][subKeyName].filter((item) => item.value !== value);
      }
      handleChangeCausesAssessmentData(newValueArray.length > 0 ? newValueArray : null, keyName, subKeyName);
    }
  };

  const [checkOtherArray, setCheckOtherArray] = useState<string[]>([]);
  const refOtherArray = useRef({} as any);
  const handleCheckboxOther = (checked: boolean, keyName: string, subKeyName: string) => {
    if (checked) {
      setCheckOtherArray((prevState) => prevState.concat([subKeyName]));
    } else {
      setCheckOtherArray((prevState) => prevState.filter((item) => item !== subKeyName));
      handleChangeCausesAssessmentData(null, keyName, subKeyName);
    }
    if (subKeyName && refOtherArray.current) refOtherArray.current[subKeyName].value = null;
  };

  const [mount, setMount] = React.useState<number>(0);
  useEffect(() => {
    if (formStore.store.formData.incidentCausesAssessment.state === 'updated' && formStore.store.formData.incidentCausesAssessment.data && !mount) {
      const newCheckOtherArray = [] as string[];
      if (causesAssessmentData.casualFactors.oneOther) newCheckOtherArray.push('oneOther');
      if (causesAssessmentData.casualFactors.twoOther) newCheckOtherArray.push('twoOther');
      if (causesAssessmentData.casualFactors.threeOther) newCheckOtherArray.push('threeOther');
      if (causesAssessmentData.casualFactors.fourOther) newCheckOtherArray.push('fourOther');
      setCheckOtherArray(newCheckOtherArray);
      setMount(1);
    }
  }, [formStore.store.formData.incidentCausesAssessment]);

  return (
    <>
      <Box className=" border-0 border-l border-solid border-blue-600">
        <Box>
          <Typography className="bg-gradient-to-r from-blue-600 text-white py-1 px-6 text-base">People (Acts)</Typography>
        </Box>
        <Box className="pl-6 mt-6">
          <Box className="grid grid-cols-5 gap-6">
            <Box className="col-span-3 relative">
              <FormControl className="w-full">
                <InputLabel shrink>1. Following procedure/rules/permit to work/JSA</InputLabel>
                <TagsInputIncident
                  selectKeyName="casualFactors"
                  selectSubKeyName="one"
                  dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.rulesPermits || ([] as { value: number; label: string }[])}
                  selectedData={causesAssessmentData?.casualFactors?.one || ([] as { value: number; label: string }[])}
                  onSelection={handleMultiSelection}
                />
              </FormControl>
            </Box>
            <Box className="col-span-2">
              <FormControl className="flex flex-row mt-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkOtherArray.includes('oneOther')}
                      onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'oneOther')}
                    />
                  }
                  label="Other"
                />
                <TextField
                  inputRef={(el) => (refOtherArray.current.oneOther = el)}
                  className="w-full"
                  placeholder="Please enter detail"
                  disabled={!checkOtherArray.includes('oneOther')}
                  onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'oneOther')}
                  {...textFieldProps(causesAssessmentData, 'casualFactors', 'oneOther')}
                />
              </FormControl>
            </Box>
          </Box>
          <hr className="border-dashed border border-gray-200 my-6" />
          <Box className="grid grid-cols-5 gap-6">
            <Box className="col-span-3 relative">
              <FormControl className="w-full">
                <InputLabel shrink>2. Use of tools, equipment, materials and products</InputLabel>
                <TagsInputIncident
                  selectKeyName="casualFactors"
                  selectSubKeyName="two"
                  dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.temp || ([] as { value: number; label: string }[])}
                  selectedData={causesAssessmentData?.casualFactors?.two || ([] as { value: number; label: string }[])}
                  onSelection={handleMultiSelection}
                />
              </FormControl>
            </Box>
            <Box className="col-span-2">
              <FormControl className="flex flex-row mt-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkOtherArray.includes('twoOther')}
                      onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'twoOther')}
                    />
                  }
                  label="Other"
                />
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Please enter detail"
                  inputRef={(el) => (refOtherArray.current.twoOther = el)}
                  disabled={!checkOtherArray.includes('twoOther')}
                  onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'twoOther')}
                  {...textFieldProps(causesAssessmentData, 'casualFactors', 'twoOther')}
                />
              </FormControl>
            </Box>
          </Box>
          <hr className="border-dashed border border-gray-200 my-6" />
          <Box className="grid grid-cols-5 gap-6">
            <Box className="col-span-3 relative">
              <FormControl className="w-full">
                <InputLabel shrink>3. Use protective methods</InputLabel>
                <TagsInputIncident
                  selectKeyName="casualFactors"
                  selectSubKeyName="three"
                  dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.methods || ([] as { value: number; label: string }[])}
                  selectedData={causesAssessmentData?.casualFactors?.three || ([] as { value: number; label: string }[])}
                  onSelection={handleMultiSelection}
                />
              </FormControl>
            </Box>
            <Box className="col-span-2">
              <FormControl className="flex flex-row mt-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkOtherArray.includes('threeOther')}
                      onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'threeOther')}
                    />
                  }
                  label="Other"
                />
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Please enter detail"
                  inputRef={(el) => (refOtherArray.current.threeOther = el)}
                  disabled={!checkOtherArray.includes('threeOther')}
                  onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'threeOther')}
                  {...textFieldProps(causesAssessmentData, 'casualFactors', 'threeOther')}
                />
              </FormControl>
            </Box>
          </Box>
          <hr className="border-dashed border border-gray-200 my-6" />
          <Box className="grid grid-cols-5 gap-6">
            <Box className="col-span-3 relative">
              <FormControl className="w-full">
                <InputLabel shrink>4. Inattention/Lack of awareness</InputLabel>
                <TagsInputIncident
                  selectKeyName="casualFactors"
                  selectSubKeyName="four"
                  dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.inattentionLack || ([] as { value: number; label: string }[])}
                  selectedData={causesAssessmentData?.casualFactors?.four || ([] as { value: number; label: string }[])}
                  onSelection={handleMultiSelection}
                />
              </FormControl>
            </Box>
            <Box className="col-span-2">
              <FormControl className="flex flex-row mt-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkOtherArray.includes('fourOther')}
                      onChange={(e) => handleCheckboxOther(e.target.checked, 'casualFactors', 'fourOther')}
                    />
                  }
                  label="Other"
                />
                <TextField
                  className="w-full"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Please enter detail"
                  inputRef={(el) => (refOtherArray.current.fourOther = el)}
                  disabled={!checkOtherArray.includes('fourOther')}
                  onBlur={(e) => handleChangeCausesAssessmentData(e.target.value, 'casualFactors', 'fourOther')}
                  {...textFieldProps(causesAssessmentData, 'casualFactors', 'fourOther')}
                />
              </FormControl>
            </Box>
          </Box>
          <hr className="border-dashed border border-gray-200 my-6" />
        </Box>
      </Box>
    </>
  );
};
