/* eslint-disable spaced-comment */

/**
 * @assets: https://stackoverflow.com/questions/52752552/echarts-for-react-call-component-on-clicking-a-data-point-on-chart
 */

import React, { useEffect, useState } from 'react';
import { Icon } from '@/src/components';
import { Box, IconButton, styled, Typography, CircularProgress } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ReactECharts from 'echarts-for-react';
import { BuOverallSchema, LegalStore, OverallGraphSelectedValue, PropDefaults, ReducerActionType } from '@/src/types';
// import { Logger } from '@/logger/index';
import { overallStatusOptions } from './chart.options';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    width: '126px',
    padding: '8px 14px',
    lineHeight: 1.6,
  },
}));

type ChartOptions = typeof overallStatusOptions;

// eslint-disable-next-line no-unused-vars
enum GraphIndex {
  progress = 0,
  complete = 1,
  overdue = 2,
  evaluate = 3,
}

interface Props extends PropDefaults {
  chartQueryState: ReducerActionType;
  // [name: string]: any;
  // azure: AzureAuthProviderProps;
  // title?: string;
  onChartClick: (data: OverallGraphSelectedValue) => void;
  /**
   * called when chart is ready
   */
  onChartReady: () => void;
}

export function OverallStatus(props: Props & LegalStore) {
  const { store: legalStore, onChartClick, onChartReady, chartQueryState } = props;
  const [mount, setMount] = useState<number>(0);
  const [chartInstance, setChartInstance] = useState<ReactECharts>(null as any);
  const [overallData, setOverallData] = useState<BuOverallSchema>(null as any);
  const [optionsData, setOptions] = useState<ChartOptions>(overallStatusOptions);

  const updateChart = () => {
    if (chartInstance) {
      const inst = chartInstance.getEchartsInstance();
      inst.setOption(optionsData as any);
      inst.resize();
    }
  };

  useEffect(() => {
    if (legalStore.legalChartsData.state === 'ready' && !!legalStore.legalChartsData.data.data?.overall) {
      const overall = legalStore.legalChartsData.data.data.overall;
      optionsData.series[0].data = [
        { value: overall?.complete || 0, name: `Completed (${overall?.complete || 0})` },
        { value: overall?.progress || 0, name: `In progress (${overall?.progress || 0})` },
        { value: overall?.overdue || 0, name: `Overdue (${overall?.overdue || 0})` },
      ] as any;
      setOverallData(overall);
      setOptions(optionsData);
      updateChart();
    }

    if (legalStore.legalChartsData.state === 'error') {
      optionsData.series[0].data = [];
      optionsData.title.show = true;
      setOptions(optionsData);
      updateChart();
    }

    if (!mount) {
      setMount(1);
    }
  }, [chartInstance, setChartInstance, mount, setMount, legalStore, overallData, setOverallData, optionsData, setOptions, chartQueryState, updateChart]);

  const onEvents = {
    click: (e: any) => {
      const index: number = e.dataIndex;
      if (GraphIndex.progress === index) {
        onChartClick({ value: overallData.complete || 0, type: 'complete' });
      }
      if (GraphIndex.complete === index) {
        onChartClick({ value: overallData.progress || 0, type: 'progress' });
      }
      // if (GraphIndex.overdue === index) {
      //   onChartClick({ value: overallData.overdue || 0, type: 'overdue' });
      // }
    },
  };

  return (
    <Box className="p-6 pt-8 rounded-2xl border border-solid border-gray-200 bg-white relative h-auto w-1/2 mr-6 shadow-md">
      <Typography variant="h2" color="primary" className="mb-3 flex absolute top-6 left-6">
        Overall status
      </Typography>
      <LightTooltip title="Click on each data to filter the list below." arrow>
        <IconButton className="p-0 ml-auto absolute top-2 right-2 z-50">
          <Icon icon="circleInfo" color="#3155FF" size={22} className="ml-auto" />
        </IconButton>
      </LightTooltip>
      {chartQueryState?.value?.loading && (
        <CircularProgress className="mr-2 absolute z-40" sx={{ top: 'calc(50% - 15px)', left: 'calc(50% - 15px)' }} size={40} />
      )}
      <ReactECharts
        ref={(r: any) => {
          if (r) {
            r.inx = (r.inx || 0) + 1;
            setChartInstance(r as any);
          }
        }}
        className={`${chartQueryState?.value?.loading && 'opacity-25'}`}
        onChartReady={onChartReady}
        onEvents={onEvents}
        option={optionsData}
      />
    </Box>
  );
}
