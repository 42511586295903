// ?! ATTENTION routing for {Calendar} is managed by training.page\trainingsPage.tsx
import React, { useState, useEffect } from 'react';
import { CalendarStore } from '@/src/types/store';
import { PropDefaults } from '@/src/types';
// import { Logger } from '@/logger/index';
import {
  NavLink,
  //  useOutletContext
} from 'react-router-dom';
import { Paper, Typography, Box } from '@mui/material';
import { Icon, CalendarUI } from '@/components/index';
import { withCalendarContext } from '@/store/index';
const PAGE_TITLE = 'Calendar';

function Calendar(props: PropDefaults & CalendarStore) {
  // const contextProps: CalendarProps & TrainingStore = useOutletContext();
  const [mounded, setMount] = useState<number>(0);
  // const contextProps: CalendarProps & TrainingStore = useOutletContext();
  //  const { store } = props;

  // context props will include new store from Calendar only, to add later
  // Logger(['[Calendar][props]', contextProps], 'log'); // NOTE reliable props
  // console.log('store is', store);
  // Logger(['[Calendar][props]2', props], 'log');
  // const [view, setView] = React.useState('calender');
  // const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
  //   setView(newView);
  // };

  useEffect(() => {
    if (!mounded) setMount(1);
  }, [mounded, setMount]);

  return (
    <Paper elevation={0}>
      <Typography variant="h1" className="mb-6">
        {PAGE_TITLE}
      </Typography>
      <Paper elevation={4} sx={{ height: 'calc(100vh - 170px)' }} className="relative">
        <Box className="flex flex-wrap p-6 pb-0 absolute right-0 top-0">
          <Box sx={{ minHeight: 40, display: 'flex', justifyContent: 'flex-end' }} className="mr-5 flex ml-auto border border-gray-300 border-solid rounded-lg">
            <NavLink to="/trainings/calendar" className="font-medium px-3  flex">
              <Icon icon="calendarDays" size={20} color="#3155FF" className="m-auto" />
            </NavLink>
            <NavLink to="/trainings" className="font-medium px-3 border-0 border-l border-gray-300 border-solid flex">
              <Icon icon="listUl" size={20} className="m-auto" />
            </NavLink>
          </Box>
          {/* <Button className="w-28" variant="contained" startIcon={<Icon icon="plus" color="#ffffff" viewBox="0 0 512 512" size={18} />}>
            Add
          </Button> */}
        </Box>
        {mounded && <CalendarUI {...props}></CalendarUI>}
      </Paper>
    </Paper>
  );
}

export default withCalendarContext(Calendar);
