import React from 'react';

import { Typography, Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface PropsDeleteIncidentModal {
  title: string;
  description: string;
  onAction(id: string, status: 'confirm' | 'cancel'): void;
  /** id used for deletion if onAction callback, if not set close modal,  */
  id: string | null;
}

function DeleteCustomModal(props: PropsDeleteIncidentModal) {
  const { title, description, onAction, id } = props;
  const [opensSnackbar, setOpensSnackbar] = React.useState(false);
  // const [severity, setSeverity] = React.useState<'error' | 'success'>('success');
  const severity: 'error' | 'success' = 'success';
  // const [titleSnackbar, setTitleSnackbar] = React.useState<string>('');
  const titleSnackbar: string = '';
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbar = () => {
    setOpensSnackbar(false);
  };

  const isOpen = !!id;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          onAction(id as any, 'cancel');
        }}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <Typography variant="h2" className="p-0 mb-6">
          {title}
        </Typography>
        <DialogContent className="p-0 mb-6">
          <Typography id="transition-modal-description">{description}</Typography>
        </DialogContent>
        <DialogActions className="p-0 justify-start">
          <Button
            className="mr-1 w-28"
            variant="contained"
            color="error"
            onClick={() => {
              onAction(id as any, 'confirm');
            }}
          >
            Delete
          </Button>
          <Button
            className="w-28"
            variant="outlined"
            color="error"
            onClick={() => {
              onAction(id as any, 'cancel');
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={opensSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbar}
        style={{ zIndex: '10000' }}
      >
        {/**
         * NOTE
         *  not being used  but needed by <Snackbar>*/}
        <Alert onClose={handleSnackbar} severity={severity} sx={{ width: '100%' }}>
          {titleSnackbar}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DeleteCustomModal;
