import React, { useState, useEffect } from 'react';
import { Typography, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { CourseStore } from '@/src/types/store';
import { withCourseContext } from '@/store/index';
import { toastMessages } from '@/src/static';
import { SnackBarAlert } from '@/src/components';

interface PropsDeleteCourseModal {
  title: string;
  description: string;
  isOpen: boolean;
  setOpen: Function;
  currentid: string;
}

function DeleteCourseModal(props: PropsDeleteCourseModal & CourseStore) {
  const { title, description, isOpen, setOpen, store, currentid } = props;
  const { courseDeletedData, courseDelete } = store || {};

  const [opensSnackbar, setOpensSnackbar] = useState(false);
  // in the end i still need this state because courseDeletedData.state update to 'initial' after delete completed, that not accepted by the severity types
  const [severity, setSeverity] = useState<'error' | 'success'>('success');

  const handleOpenDelete = () => {
    courseDelete(currentid);
  };

  useEffect(() => {
    if (['ready', 'error'].includes(courseDeletedData.state) && !opensSnackbar) {
      setSeverity(courseDeletedData.state === 'ready' ? 'success' : 'error');
      setOpensSnackbar(true);
      setOpen(false);
    }
  }, [courseDeletedData.data]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
        <Typography variant="h2" className="p-0 mb-6">
          {title}
        </Typography>
        <DialogContent className="p-0 mb-6">
          <Typography id="transition-modal-description">{description}</Typography>
        </DialogContent>
        <DialogActions className="p-0 justify-start">
          <Button className="mr-1 w-28" variant="contained" color="error" onClick={handleOpenDelete}>
            Delete
          </Button>
          <Button className="w-28" variant="outlined" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <SnackBarAlert
        messages={{
          error: toastMessages.adminNotDeleted,
          success: toastMessages.adminDeleted,
        }}
        severity={severity}
        state={opensSnackbar as any}
        onClose={() => {
          setOpensSnackbar(false);
        }}
      />
    </>
  );
}

export default withCourseContext(DeleteCourseModal);
