import { UserRoleIdConst } from '../constants';

/**
 * 1: Super Admin
 * 2: Admin
 * 3: General User
 * 4: General Manager
 * 5: Head of Department/Co-founder
 */
export const userRoles = (() => {
  return {
    [UserRoleIdConst.super_admin]: 'Super Admin',
    [UserRoleIdConst.bu_admin]: 'BU Admin',
    [UserRoleIdConst.user]: 'User',
    [UserRoleIdConst.gm]: 'General Manager',
    [UserRoleIdConst.hod]: 'Head of Department/Co-founder',
  };
})();

export * from './admins';
export * from './common';
export * from './dashboard';
export * from './legal';
export * from './courses';
export * from './trainings';
// export * from './calendar';
export * from './incidents';
// export * from './manHours';
export * from './incidents/assets';
export * from './svg';
export * from './profile';
