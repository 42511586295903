/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-use-before-define */
import './i18n';
import './debug'; // Logging information for intellisense
import './styles/global.scss';
import './production'; // Production setting
import React, { useEffect, useState } from 'react';

import { Alert, LinearProgress, StyledEngineProvider } from '@mui/material';
import { theme } from './styles/theme/index';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import {
  Login,
  LoginByPass,
  Dashboard,
  Admins,
  CoursesPage,
  CourseDetail,
  TrainingDetail,
  TrainingsPage,
  Calendar,
  ResponseMessage,
  Error404,
  Error500,
  IncidentsPage,
  IncidentCreateUpdate,
  IncidentDetailPage,
  ManHours,
  LegalsPage,
  LegalDetail,
  ProfileDetail,
  ProfilePage,
} from '@/src/pages';

import { Layout } from './components';
import { Logger } from '@/logger/index';
import {
  CourseContextProvider,
  useGlobalContext,
  AdminContextProvider,
  TrainingContextProvider,
  CalendarContextProvider,
  IncidentContextProvider,
  ManhoursContextProvider,
  IncidentFormContextProvider,
  LegalContextProvider,
  DashboardContextProvider,
  ProfileContextProvider,
} from '@/store/index';

import { AzureAuthProvider } from '@/services/azure';
import { AzureAuthProviderProps } from './types';
import { isAdmin, cookieOptions, CookieService } from '@/utils/index';
import { AppWrapper } from './components/core/hocs';
import { ComponentContextStore } from '@/store/component.store';
import { SERVER_TO_CLIENT_ERROR } from './constants';

interface AppProps {
  azure: AzureAuthProviderProps;
  [name: string]: any;
}

(window as any).componentContextStore = new ComponentContextStore();

function App(props: AppProps): JSX.Element {
  const { checkSetRedirectUrl, hotUpdate, account, error, accountLogin, accountStatus, userProfile, history, userOptions } = props?.azure || {};

  const cookieService: CookieService = new CookieService(null, cookieOptions() as any);
  const authBypass = cookieService.$get('bypass') === '1';
  const bypass = history.location.search && history.location.search.includes('bypass=1');

  // START: Initial Azure Sign-In
  if (!authBypass && !bypass && (!account || accountStatus !== 'ready') && accountStatus !== 'no-access') {
    accountLogin();
  }
  // -- END

  // -------------------------
  // If you dont implement accountLogout yet, need to clear browser cache for last 24 hours
  // WARN AZURE SIGN-OUT from account
  // this should be added to on CTA handler
  // accountLogout();
  // -- END
  // ------------------------
  type Conditions = 'USER_WITH_PROFILE' | 'USER_NO_PROFILE' | 'NO_USER' | 'LOADING' | 'NO_ACCESS' | '500_ERROR' | 'BYPASS';
  const { setCurrentUser } = useGlobalContext();
  const [userMount, setUserMount] = useState(0);

  useEffect(() => {
    if (accountStatus === 'no-access') {
      setCurrentUser({ azure: props?.azure }, 'nonUser');
      return;
    }
    if (hotUpdate === 'updated') {
      setCurrentUser({ azure: props?.azure });
    }
    if (accountStatus === 'ready') {
      if (!userMount) {
        setCurrentUser({ azure: props?.azure });
        setUserMount(1);
      }
    }
  }, [setUserMount, userMount, accountStatus, hotUpdate]);

  Logger([`[azure][accountStatus][${accountStatus}]`], 'notice');

  // User information
  if (accountStatus === 'ready') {
    Logger([`[azure][account]`, account], 'attention');
    Logger([`[azure][account][profile]`, userProfile], 'attention');
    Logger([`[azure][account][profile][options]`, userOptions], 'attention');
    Logger([`[azure][account][hotUpdate]`, hotUpdate], 'attention');
  }
  if (accountStatus === 'no-access') {
    Logger([`[no-access][userProfile]`, userProfile], 'attention');
  }
  if (error) {
    Logger(['[azure][error]', error], 'error');
    // cookieService.$removeMany(['accessToken', 'jwt', 'bypass']);
    // window.location.href = '/';
  }

  const AuthRedirectDestination = (props: any) => {
    // Check if history url exists
    let initialUrl = checkSetRedirectUrl('get');

    if (initialUrl) {
      checkSetRedirectUrl('clear'); // Clear url after it was set
      initialUrl = new URL(initialUrl).pathname;
      // Push url to history so navigation can be handled correctly
      history.push(initialUrl);
    }
    return <Navigate to={history.location.pathname || '/'} />;
  };

  // ATTENTION we will need to add more conditional validation for the roles when we start supporting new user roles/roleId: 4 | 5
  // please use these METHODS : { isSuperAdmin / isUser / isHod / isGM / isHodOrGm }
  // form @/utils for permission validation:
  const Render = (): JSX.Element => {
    return (
      <React.Fragment>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              <Routes>
                {accountStatus === 'ready' && (
                  <>
                    <Route element={<Layout azure={props?.azure} history={history} />}>
                      <Route
                        path="/admins"
                        element={
                          <>
                            {isAdmin(userProfile) ? (
                              <AdminContextProvider context={{ azure: props?.azure }}>
                                <Admins azure={props?.azure} history={history} title={`Welcome dashboard ${userProfile.roleId}`} />
                              </AdminContextProvider>
                            ) : (
                              <Navigate to="/dashboard" replace />
                            )}
                          </>
                        }
                      ></Route>
                      <Route
                        path="profile"
                        element={
                          <ProfileContextProvider context={{ azure: props?.azure }}>
                            <ProfilePage azure={props?.azure} history={history} title={`Welcome Profile`} />
                          </ProfileContextProvider>
                        }
                      >
                        <Route path="detail/:detailId" element={<ProfileDetail azure={props?.azure} history={history} />} />
                      </Route>

                      {/** there will be no /training route > currently set default to courses*/}
                      {/* {!isHodOrGm(userProfile) && ( */}
                      <Route
                        path="courses"
                        element={
                          <CourseContextProvider context={{ azure: props?.azure }}>
                            <CoursesPage azure={props?.azure} history={history} />
                          </CourseContextProvider>
                        }
                      >
                        <Route path="detail/:detail" element={<CourseDetail />} />
                      </Route>
                      {/* )} */}

                      {/* {!isHodOrGm(userProfile) && ( */}
                      <Route
                        path="trainings"
                        element={
                          <TrainingContextProvider context={{ azure: props?.azure }}>
                            <TrainingsPage azure={props?.azure} history={history} />
                          </TrainingContextProvider>
                        }
                      >
                        <Route path="detail/:detail/:backUrl" element={<TrainingDetail />} />
                        <Route path="detail/:detail" element={<TrainingDetail />} />
                        <Route
                          path="calendar"
                          element={
                            <CalendarContextProvider context={{ azure: props?.azure }}>
                              <Calendar azure={props?.azure} history={history} />
                            </CalendarContextProvider>
                          }
                        />
                      </Route>
                      {/* )} */}

                      <Route
                        path="incidents"
                        element={
                          <IncidentContextProvider context={{ azure: props?.azure }}>
                            <IncidentFormContextProvider context={{ azure: props?.azure }}>
                              <IncidentsPage azure={props?.azure} history={history} />
                            </IncidentFormContextProvider>
                          </IncidentContextProvider>
                        }
                      >
                        <Route path="create" element={<IncidentCreateUpdate azure={props?.azure} history={history} pageType="create" />} />
                        <Route path="update/:updateId" element={<IncidentCreateUpdate azure={props?.azure} history={history} pageType="update" />} />

                        <Route path="detail/:detailId" element={<IncidentDetailPage azure={props?.azure} history={history} />} />
                        <Route
                          path="man-hour"
                          element={
                            <ManhoursContextProvider context={{ azure: props?.azure }}>
                              <ManHours />
                            </ManhoursContextProvider>
                          }
                        />
                      </Route>

                      {/* {!isHodOrGm(userProfile) && ( */}
                      <Route
                        path="legals"
                        element={
                          <LegalContextProvider context={{ azure: props?.azure }}>
                            <LegalsPage azure={props?.azure} history={history} />
                          </LegalContextProvider>
                        }
                      >
                        <Route path="detail/:detailId" element={<LegalDetail azure={props?.azure} history={history} />} />
                      </Route>
                      {/* )} */}

                      <Route
                        path="/home"
                        element={
                          <DashboardContextProvider context={{ azure: props?.azure }}>
                            <Dashboard azure={props?.azure} history={history} />
                          </DashboardContextProvider>
                        }
                      />
                      <Route path="/login" element={<Login />} />
                      <Route path="*" element={<div>No route found</div>} />
                    </Route>
                    <Route path="/" element={<Navigate to="/dashboard" replace />} />
                    <Route path="/dashboard" element={<Navigate to="/home" replace />} />
                  </>
                )}

                {!bypass && (
                  <>
                    {/** For /admins otherwise redirect to /users */}
                    {/* <Route path="/users" element={<Layout azure={props?.azure} history={history} />}>
            
                      <Route index element={<div>You are not an admin. Un-styled users page</div>}></Route>
                    </Route> */}

                    {/* <Route path="/dashboard" element={<Layout azure={props?.azure} history={history} />}>
                      <Route index element={<div>Un-styled dashboard page</div>}></Route>
                    </Route> */}

                    <Route path="/auth" element={<AuthRedirectDestination />} />

                    <Route path="/email/thank-you" element={<ResponseMessage />} />
                    <Route path="/500" element={<Error500 />} />
                    <Route path="/404" element={<Error404 />} />

                    {/* <Route path="/training" element={<Navigate to="/courses" replace />} /> */}
                  </>
                )}

                {bypass && accountStatus !== 'ready' && (
                  <>
                    <Route path="*" element={<LoginByPass />} />
                  </>
                )}
              </Routes>
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </React.Fragment>
    );
  };

  // TODO move to preload component later or Suspend component
  const condition: Conditions = [
    bypass && accountStatus !== 'ready' && 'BYPASS',
    !bypass && (accountStatus === 'api-error' || !!error) && '500_ERROR',
    !bypass && accountStatus === 'no-access' && 'NO_ACCESS',
    !bypass && accountStatus === 'error' && 'NO_USER',
    !bypass && !account && !userProfile && 'LOADING',
    !bypass && account && userProfile && accountStatus === 'ready' && 'USER_WITH_PROFILE',
    !bypass && account && !userProfile && !error && 'USER_NO_PROFILE',
  ].filter((n) => !!n)[0] as any;

  switch (condition) {
    case 'BYPASS': {
      return <Render />;
    }

    case 'LOADING': {
      const outerProps = { theme };
      return (
        <AppWrapper outerProps={outerProps}>
          <LinearProgress />
          <Alert severity="info">Authorizing</Alert>
        </AppWrapper>
      );
    }

    case '500_ERROR': {
      const outerProps = { theme };
      return (
        <AppWrapper outerProps={outerProps}>
          <Alert severity="error">{SERVER_TO_CLIENT_ERROR}</Alert>
        </AppWrapper>
      );
    }

    case 'USER_NO_PROFILE': {
      const outerProps = { theme };
      return (
        <AppWrapper outerProps={outerProps}>
          <LinearProgress />
          <Alert severity="info">Authorizing</Alert>
        </AppWrapper>
      );
      // return <>User account without profile</>;
    }

    case 'NO_ACCESS': {
      return <Render />;
    }
    case 'USER_WITH_PROFILE': {
      return <Render />;
    }

    case 'NO_USER': {
      return <>Error login to account, no user profile or account</>;
    }

    default: {
      return <>No condition met</>;
    }
  }
}

export default AzureAuthProvider(App);
