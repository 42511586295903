import { CourseBodyData, QueryCourseList, CourseBodyDataUpdate, CoursesFilterQuery } from '@/src/types';
import { CourseStore } from '@/types/store';
import { createContext } from 'react';

const initialDataWrapper = {
  error: undefined,
  data: undefined,
  state: 'initial',
};

export const CourseContext: React.Context<CourseStore> = createContext({
  store: {
    resetStatus: (apiName: string) => {},
    setCourseByFilter: (params?: CoursesFilterQuery) => {},
    courseFilterByList: {
      ...initialDataWrapper,
    },

    courseCreate: (formData: CourseBodyData) => {},
    courseCreatedData: {
      ...initialDataWrapper,
    },
    courseList: {
      ...initialDataWrapper,
    },
    setCourseList: (params: QueryCourseList) => {},
    courseUpdate: (id: string, formData: CourseBodyDataUpdate, updateFor: 'list' | 'item') => {},
    courseUpdatedData: {
      ...initialDataWrapper,
    },
    courseByIdData: {
      ...initialDataWrapper,
    },
    courseById: (id: string) => {},
    courseByNo: (trainingCode: string) => {},
    courseByNoData: {
      ...initialDataWrapper,
    },
    courseTrainingTypeFilter: {
      ...initialDataWrapper,
    },
    courseSelect: () => {},
    courseSelectData: {
      ...initialDataWrapper,
    },
  },
} as any);
