/* eslint-disable no-useless-escape */
// ? all logical/functional operators go here

// import { ENVS } from '../constants';
import {
  PropDefaults,
  MockApiData,
  ReactType,
  CookieName,
  Defer,
  UserRole,
  UserRoleId,
  CookieNames,
  UserProfile,
  GetBusinessUnits,
  QueryEmployeeList,
  CourseTrainingTypeSchema,
  CourseTrainingCode,
  InstructionType,
  CourseSchema,
  CourseSmallSchema,
  TrainingSmallSchema,
  TrainingDetailSchema,
  TrainingStatus,
  GetTrainingList,
  AttendeeSchema,
  ChildStoreType,
  CalendarEventsRange,
  ManHourCompanySchema,
  FileDropped,
  IncidentSchemaParts,
  IncidentSchema,
  IncidentSmallSchema,
  IncidentStatusReport,
  IncidentReporters,
  IncidentStatusType,
  EmployeeSchema,
  ReviewerSchema,
  IncidentReportApprovalSchema,
  IncidentReportRemarkSchema,
  IncidentMemberTypes,
  LegalSmallSchema,
  LegalLorSchema,
  LegalStatusSchema,
  LegalStates,
  LegalLorTypeSchema,
  IncidentDamageType,
} from '@/types/index';
import Cookies, { CookieChangeOptions, CookieParseOptions, CookieSetOptions } from 'universal-cookie';
import { stringify } from 'query-string';
import { courseTrainingList, incidentDamageTypes, instructionsList, userRoles } from '@/static/index';
import { Logger } from './logger/index';
import moment from 'moment';
import { ENVS_CONFIG, SERVER_TO_CLIENT_ERROR, UserRoleIdConst, IncidentStatusReport as IncidentStatusReportConst, LegalStatusConst } from '@/constants/index';
// import { errorHandler } from './axios/index';
import { clone, identity, isArray, isDate, isEmpty, isNumber, pickBy } from 'lodash';
// import { TrainingsSchema } from '../static/trainings/mockupData';
import { CalendarEventViewType, ToastMessage } from '@/types/common';
import { ViewApi } from '@fullcalendar/react';
import { IncidentFormDataType, IncidentFormPartStoreState } from '@/types/store/index';

import { UserSchema } from '@/types/schema/user.schema';
import { useLocation } from 'react-router-dom';

export const niceUrl = (base: string, prefix: string): string => {
  try {
    return new URL(prefix, base).toString();
  } catch (err: any) {
    console.error('[niceUrl]', err.toString());
  }
  return undefined as any;
};

/**
 * @description Remove /slashes/ from start and end, except for between
 * @example  /abc/def/ghi/ >> abc/def/ghi
 */
export const noSlash = (str = '') => {
  let _str = str;
  if (_str.startsWith('/')) _str = _str.substring(1, _str.length);
  if (_str.endsWith('/')) _str = _str.substring(0, _str.length - 1);
  return _str;
};

/**
 * get one mocked data by name
 */
export const getMockApiDataByName = (apiNameRef: string, mockApiDataList: MockApiData[]): MockApiData => {
  return mockApiDataList
    .filter((n) => !!n)
    .filter((n) => {
      if (!n?.uniqId) {
        console.error('missing', n);
      }
      return n.uniqId === apiNameRef;
    })[0];
};

/** assign props when passing children
 * we are deleting props.children from props to avoid circular issue
 */
export const childrenWithProps = (React: ReactType, children: React.ReactChildren, props: PropDefaults) => {
  if (props.children) delete (props as any).children;
  return React.Children.map(
    children,
    (
      child: any
      // , index
    ) => {
      return React.cloneElement(child as any, {
        // merge with latest props
        ...props,
      });
    }
  );
};

/** extract cookie value by name */
export const getCookies = (cookieName: CookieName, cookies: any): string => {
  if (!cookies) return '';
  else return cookies[cookieName] as string;
};

/** delay something */
export const delay = (time: number = 0): Promise<boolean> => {
  const isNum = typeof time === 'number' && time >= 0; // must provide number
  if (!isNum) return Promise.resolve(true); // or resolve
  // @ts-ignore
  return new Promise(
    (
      resolve
      // , reject
    ) => {
      const t = setTimeout(() => {
        clearTimeout(t);
        resolve(true);
      }, time);
    }
  );
};

export const defer = (): Defer<any> => {
  const deferred: Defer<any> = {} as any;
  const promise = new Promise(function (resolve, reject) {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  deferred.promise = promise;
  return deferred;
};

/**
 *
 *  is super_admin or bu_admin
 */
export const isAdmin = (profile?: UserProfile): boolean => {
  if (!profile) return false;
  return profile.roleId === UserRoleIdConst.super_admin || profile.roleId === UserRoleIdConst.bu_admin;
};

/**
 *
 *  is  bu_admin
 */
export const isBuAdmin = (profile?: UserProfile): boolean => {
  if (!profile) return false;
  return profile.roleId === UserRoleIdConst.bu_admin;
};
/**
 * - Check if current BU is a member of company
 * - company related bu
 */
export const isBuAdminByCompany = (profile: UserProfile, employee: EmployeeSchema): boolean => {
  if (!profile) return false;
  return profile?.roleId === UserRoleIdConst.bu_admin && profile?.employeeId === employee?.employeeId;
};

/**
 *
 *  is super_admin
 */
export const isSuperAdmin = (profile: UserProfile): boolean => {
  if (!profile) return false;
  return profile?.roleId === UserRoleIdConst.super_admin;
};

/**
 * is user/reporter
 */
export const isUser = (profile?: UserProfile): boolean => {
  if (!profile) return false;
  return profile.roleId === UserRoleIdConst.user;
};

/**
 * - is user/reporter or bu_admin/reporter
 * -in terms of incident/report creation it is possible to bu_admin to also create
 */
export const isUserOrBuAdmin = (profile?: UserProfile): boolean => {
  if (!profile) return false;
  return profile.roleId === UserRoleIdConst.user || profile.roleId === UserRoleIdConst.bu_admin;
};

/**
 * - is Team Lead
 * - matched by reporters?.approveBy object, its also a general user with roleId:3
 */
export const isTeamLead = (profile?: UserProfile, reporters?: IncidentReporters): boolean => {
  if (!profile) return false;
  if (!reporters?.approveBy) return false;
  return profile.roleId === UserRoleIdConst.user && profile.employeeId === reporters?.approveBy?.employeeId;
};

/**
 * is General Manager
 */
export const isGM = (profile?: UserProfile): boolean => {
  if (!profile) return false;
  return profile.roleId === UserRoleIdConst.gm;
};

/**
 * is Head of Department/Co-founder
 */
export const isHod = (profile?: UserProfile): boolean => {
  if (!profile) return false;
  return profile.roleId === UserRoleIdConst.hod;
};

/**
 * is Head of Department/Co-founder or General Manager
 */
export const isHodOrGm = (profile?: UserProfile): boolean => {
  if (!profile) return false;
  return profile.roleId === UserRoleIdConst.hod || profile.roleId === UserRoleIdConst.gm;
};

/**
 *
 * All other users roles: 3,4,5 , excluding user_admin and bu_admin
 */
export const isGeneral = (profile?: UserProfile): boolean => {
  if (!profile) return false;
  return profile.roleId === UserRoleIdConst.user || profile.roleId === UserRoleIdConst.hod || profile.roleId === UserRoleIdConst.gm;
};

/**
 * Check the role of current employee by RoleId
 */
export const getEmployeeRole = (employee: EmployeeSchema): UserRole | 'not_defined' => {
  const role = employee.roleId;
  if (role === UserRoleIdConst.super_admin) return 'super_admin';
  if (role === UserRoleIdConst.gm) return 'gm';
  if (role === UserRoleIdConst.hod) return 'hod';
  if (role === UserRoleIdConst.user) return 'user';
  else return 'not_defined';
};

/**
 * Check current status of incident by IncidentStatusReport
 */
export const isIncidentStatus = (status: IncidentStatusReport): IncidentStatusType | 'not_defined' => {
  if (status === IncidentStatusReportConst.draft) return 'draft';
  if (status === IncidentStatusReportConst.review) return 'review';
  if (status === IncidentStatusReportConst.ongoing) return 'ongoing';
  if (status === IncidentStatusReportConst.closed) return 'closed';
  else return 'not_defined';
};

type DamageType = typeof incidentDamageTypes[0];
export const incidentDamageTypeLabel = (damageType: IncidentDamageType): DamageType => {
  return incidentDamageTypes.filter((n) => n.value === damageType)[0] as any;
};

/** merge param vars to readable query string
 *  /abc + ? + query
 * @param prefix
 * @param params
 * @param serialized optionals replaces params object
 */
export const paramsQueryString = (prefix: string, params: {}, serialized: string = ''): string => {
  const _serialized = serialized || stringify(params, { arrayFormat: 'separator', arrayFormatSeparator: ',' });
  return prefix + '?' + _serialized;
};

export const printRoleNameById = (roleId: UserRoleId): UserRole => {
  return (userRoles[Number(roleId)] || 'Invalid') as any;
};

export const printInstructionValue = (inst: InstructionType): string => {
  return (instructionsList[inst] || undefined) as any;
};

/**
 * remove last url suffix to be replaced with static from mock data api/ child
 */
// export const updateStaticSuffix = (sufix: string) => {
//   const list = sufix.split('/');

//   /** method prefix offset */
//   const offset = 1;
//   if (list.length - offset === 2) {
//     list.splice(list.length - 1, 1);
//   }

//   return list.toString().replace(',', '/');
// };

/** Universal cookie options */
export const cookieOptions = (expiryOptions?: Date): CookieSetOptions => {
  const date = expiryOptions ? new Date(expiryOptions) : new Date();

  // set cookie expiry
  if (!expiryOptions) date.setFullYear(date.getFullYear() + 1);
  // NOTE {sameSite} may fix incognito issue?
  const opts: CookieSetOptions = { path: '/', expires: date, sameSite: true };
  return opts;
};

/**
 * @CookieService
 * @description All methods prefixed with $ are our methods, and all others are from Cookies class
 */
export class CookieService extends Cookies {
  options?: CookieParseOptions = undefined as any;
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(cookies?: string | object | null | undefined, opts?: CookieParseOptions) {
    super(cookies, opts);
    this.options = opts;
  }

  $get(name: CookieNames, opts = this.options): any {
    const val = super.get(name, opts as any);
    if (['null', 'undefined'].indexOf(val) !== -1) return null;
    return super.get(name, opts as any);
  }

  $getAll(opts = this.options) {
    return this.getAll(opts as any);
  }

  $set(name: CookieNames, val: any, opts = this.options): void {
    if (!val) return undefined;
    return this.set(name as any, val, opts as any);
  }

  /** to override existing token with new */
  $renew(name: CookieNames, val: any, opts = this.options): void {
    if (!val) return undefined;
    this.remove(name, opts as any);
    return this.set(name as any, val, opts as any);
  }

  $remove(name: CookieNames, opts = this.options): void {
    return this.remove(name as any, opts as any);
  }

  $removeMany(names: CookieNames[], opts = this.options): void {
    names.forEach((name) => {
      this.remove(name as any, opts as any);
    });
    return undefined;
  }

  /** callback on cookie set or remove */
  $onUpdated(callback: (opts: CookieChangeOptions) => void) {
    this.addChangeListener((opts) => {
      if (typeof callback === 'function') callback(opts);
    });
  }
}

export const getJwtToken = (cookies?: string) => {
  const c = new CookieService(cookies, cookieOptions() as any);
  return c.$get('jwt');
};

export const getAccessToken = (cookies?: string) => {
  const c = new CookieService(cookies, cookieOptions() as any);
  return c.$get('accessToken');
};

/* get params filter before sent to call new admin data */
export const getAdminParamsFilter = (data: GetBusinessUnits, pageNumber: number, itemPerPage: number, companyValue: string[], nameSearching: string) => {
  const businessUnitCodes = data.data.filter((a) => companyValue.includes(a.buName)).map((i) => i.buCode);

  const params: QueryEmployeeList = {
    pageNumber: pageNumber,
    itemPerPage: itemPerPage,
  };

  if (businessUnitCodes.length) params.businessUnits = businessUnitCodes.join(',');
  if (nameSearching) params.filter = nameSearching;

  return params;
};

/**
 *
 * Return valid date or message with invalid date
 */
export const validData = (date: string | Date): Date => {
  if (!date) return null as any;
  const d = new Date(date);
  if (isNaN(d.valueOf())) {
    Logger(['validData', 'Invalid date provided', date], 'error');
    return null as any;
  } else {
    return d;
  }
};

/**
 * Test current Object has second level and return it
 */
export const stateLeveledValue = (obj: {}, arr: any[]) => {
  try {
    return obj[arr[0]];
  } catch (err) {
    return {};
  }
};

/**
 * Check if value is en error
 */
export const isError = (err) => {
  try {
    return err instanceof Error;
  } catch (err) {
    return false;
  }
};

// validate thai chars: https://www.regextester.com/116401

export const URL_REGEX: RegExp = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
export const TEXT_REGEX: RegExp = /^[ \u0E00-\u0E7FA-Za-z0-9_@.%?/#&+-=:;<>"'|,*^()@!\n\r]*$/;

export const getByTrainingCode = (trainingCode: CourseTrainingCode): CourseTrainingTypeSchema => {
  return courseTrainingList?.filter((n) => trainingCode === n.trainingCode)[0];
};

/**
 *
 * check if its an error from server response
 * NOTE we disabled actual server error with client error message
 *
 */
export const serverError = (error: any) => {
  if (error || isError(error)) {
    const er = error.toString() || undefined;
    return er ? SERVER_TO_CLIENT_ERROR : undefined;
  } else return undefined;
};

export const printDate = (date?: Date | string, format?: string, returnNull?: boolean): string | null => {
  const m = moment(date ? new Date(date) : date);
  if (!m.isValid()) {
    if (returnNull) return null;
    else return 'invalid date';
  }
  return moment(date).format(format || 'DD MMM YYYY');
};

/**
 * Check provided value is a validate or valid format that can conver5 to valid date
 */
export const validDateFormat = (val: Date | string) => {
  if (typeof val === 'string') {
    if (isNaN(new Date(val).valueOf())) return false;
    else {
      return true;
    }
  }
  return !!val && isDate(val) && val.toString() !== 'Invalid Date';
};

export const downloadLink = (link: string, query?: { trainingId: string }): string => {
  if (!link) return undefined as any;

  try {
    if (query?.trainingId) {
      return new URL(`${link}?trainingId=${query?.trainingId}`, ENVS_CONFIG.REACT_APP_API_BASE).toString();
    } else return new URL(link, ENVS_CONFIG.REACT_APP_API_BASE).toString();

    // NOTE disabled jwt support for now
    // use the method {urlWithToken} when it is supported again!
    // return url + `?jwt=${getJwtToken()}`;
  } catch (err) {
    Logger(['[downloadLink]', 'invalid link', link], 'error');
    return undefined as any;
  }
};

export const exportLink = (link: any): string => {
  if (!link) return undefined as any;
  const apiName = '/training/export/';
  try {
    const url = new URL(link, `${ENVS_CONFIG.REACT_APP_API_BASE}${ENVS_CONFIG.REACT_APP_API_PREFIX}${apiName}`).toString();
    return url + `.pdf`;
  } catch (err) {
    Logger(['[exportLink]', 'invalid link', link], 'error');
    return undefined as any;
  }
};

/**
 * @param suffixLink should include full suffix path and any file extension, example: /a/b/file.pdf
 * @param hasApiPrefix if response object already provide prefix, then ignore it
 */
export const exportLinkV2 = (suffixLink: string, hasApiPrefix: boolean = false): string => {
  if (!suffixLink) return null as any;
  try {
    if (hasApiPrefix) {
      // eslint-disable-next-line no-throw-literal
      if (!suffixLink) throw 'empty suffixLink)';
      const url = new URL(`${suffixLink}`, `${ENVS_CONFIG.REACT_APP_API_BASE}`).toString();
      return url;
    } else {
      // eslint-disable-next-line no-throw-literal
      if (!suffixLink) throw 'empty suffixLink)';
      const url = new URL(`${ENVS_CONFIG.REACT_APP_API_PREFIX}/${suffixLink}`, `${ENVS_CONFIG.REACT_APP_API_BASE}`).toString();
      return url;
    }
  } catch (err) {
    Logger(['[downloadLink]', 'invalid link'], 'error');
    return undefined as any;
  }
};

/**
 * return relative url based on domain origin
 */
export const windowLocationRelativeUrl = (path: string): string => {
  try {
    const uri = new URL(window.location.href);
    return new URL(path || '', uri.origin).toString();
  } catch (err) {}
  return '';
};

/**
 * To protect all download/export assets we need to wrap it with {?token=}
 */
export const urlWithToken = (fullUrl: string, tokenName: 'jwt' | 'accessToken'): string => {
  try {
    const uri = new URL(fullUrl);
    if (tokenName === 'accessToken') {
      return new URL(`${uri.pathname}?${tokenName}=${getAccessToken()}`, uri.origin).toString();
    }
    if (tokenName === 'jwt') {
      return new URL(`${uri.pathname}?${tokenName}=${getJwtToken()}`, uri.origin).toString();
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 'invalid {tokenName}';
    }
  } catch (err) {
    Logger(['[urlWithToken]', err], 'error');
    return fullUrl;
  }
};

/**
 * Empty but not undefined
 */
export const empty = (val) => {
  return val !== undefined && !!val === false;
};

/**
 * update one item by id to course list array CourseSmallSchema[]
 */
export const updateCourseList = (source: CourseSmallSchema[], item: CourseSchema): { data: CourseSmallSchema[]; updated: boolean } => {
  let updated = false;

  const data = (source || []).map((x) => {
    if (x.id === item.id) {
      x = item as any;
      updated = true;
    }
    return x;
  }) as any;

  return { data, updated };
};

/**
 * update training detail
 */
export const updateTrainingDetail = (source: TrainingDetailSchema, item: TrainingDetailSchema): { data: TrainingDetailSchema; updated: boolean } => {
  let updated = false;
  let data: any;
  if (source.id === item.id) {
    data = item;
    updated = true;
  } else {
    data = source;
  }

  return { data, updated };
};

/**
 * Extract param from url
 */
export const getUrlPramValue = () => {
  return new URLSearchParams(useLocation().search) || '';
};

export const createUpdateTrainingList = (source: TrainingSmallSchema[], item: TrainingDetailSchema): { data: TrainingSmallSchema[]; updated: boolean } => {
  let updated = false;

  const data = (source || []).map((x) => {
    if (x.id === item.id) {
      x = item as any;
      updated = true;
    }
    return x;
  }) as any;
  // if not updated because its new lets add it to top of the list
  if (!updated) {
    data.unshift(item);
    updated = true;
  }

  return { data, updated };
};

/**
 * update one item by id to course list array CourseSmallSchema[]
 */
export const updateTrainingList = (source: TrainingSmallSchema[], item: TrainingDetailSchema): { data: TrainingSmallSchema[]; updated: boolean } => {
  let updated = false;

  const data = (source || []).map((x) => {
    if (x.id === item.id) {
      x = item as any;
      updated = true;
    }
    return x;
  }) as any;

  return { data, updated };
};

/**
 * update one item by id to attendee list
 */
export const updateAttendeeList = (source: AttendeeSchema[], item: AttendeeSchema): { data: AttendeeSchema[]; updated: boolean } => {
  let updated = false;

  const data = (source || []).map((x) => {
    if (x.id === item.id) {
      x = item as any;
      updated = true;
    }
    return x;
  }) as any;

  return { data, updated };
};

/** Append item to top of list
 * NOTE, we cannot perform sorting safely due to some fields are missing in draft mode, so we always add it to to of the list, on reload, it is relocated by the server
 */
export const createTrainingList = (source: TrainingSmallSchema[], item: TrainingDetailSchema): TrainingSmallSchema[] => {
  const d = source;
  d.unshift(item as any);
  return d;
};

/**
 * On empty and not a number return true
 */
export const isEmptyAndUnset = (val: any): boolean => {
  return !isNumber(val) && isEmpty(val);
};

/**
 * check if any data is set
 */
export const isSet = (data: any) => {
  return (!!data || !!data?.length || !!Object.entries(data || []).length) === true;
};

/**
 * @description display toast message based on condition
 */
export const displayToastMessage = (
  toastMessage: ToastMessage,
  isSuccess: boolean,
  submitType: 'submit-publish' | 'submit-draft' | 'submit-draft-new' | 'submit-publish-new' | 'submit-edit-publish'
): string => {
  if (isSuccess) {
    return [
      (submitType === 'submit-draft-new' || submitType === 'submit-publish-new') && toastMessage.dataAdded,
      submitType === 'submit-draft' && toastMessage.dataUpdated,
      submitType === 'submit-publish' && toastMessage.dataAdded,
      submitType === 'submit-edit-publish' && toastMessage.dataUpdated,
    ].filter((n) => !!n)[0] as any;
  } else {
    return [
      (submitType === 'submit-draft-new' || submitType === 'submit-publish-new') && toastMessage.dataNotAdded,
      submitType === 'submit-draft' && toastMessage.dataNotUpdated,
      submitType === 'submit-publish' && toastMessage.dataNotUpdated,
      submitType === 'submit-edit-publish' && toastMessage.dataNotUpdated,
    ].filter((n) => !!n)[0] as any;
  }
};

export const checkIsLastPage = (pageNumber: number, totalItems: number, itemPerPage: number): boolean => {
  let isLastPage = false;
  const pageCount = totalItems / itemPerPage;
  if (pageCount < 1) {
    isLastPage = true;
  } else {
    if (pageNumber + 1 >= pageCount) {
      isLastPage = true;
    }
  }
  return isLastPage;
};

/**
 * - Sort training list items by status filter
 * - return sorted data
 */
export const sortedTrainingList = (list: TrainingSmallSchema[], sortBy: TrainingStatus[]): TrainingSmallSchema[] => {
  return list.filter((n) => {
    // provide all results if no filter is set
    if (!sortBy.length) return true;
    else return (sortBy || []).indexOf(n.status) !== -1;
  });
};

/**
 * - Sort incidents list items by status filter
 * - return sorted data
 */
export const sortedIncidentsList = (list: IncidentSmallSchema[], sortBy: IncidentStatusReport[]): IncidentSmallSchema[] => {
  return (list || []).filter((n) => {
    // provide all results if no filter is set
    if (!sortBy.length) return true;
    else return (sortBy || []).indexOf(n.status) !== -1;
  });
};

/**
 * Update pagination number when new items are added to the list in real/time before reload
 */
export const updateTrainingPaginationList = (list: GetTrainingList, data: TrainingSmallSchema[], type: 'one' | 'many' = 'many'): GetTrainingList => {
  list.data = data;

  // check if last row is on correct page
  const isLastPage = checkIsLastPage(list.pagination.pageNumber, list.pagination.totalItems, list.pagination.itemPerPage);
  if (!isLastPage) {
    list.data.splice(list.data.length - 1, 1);
  }
  list.pagination.totalItems = list.pagination.totalItems + 1;
  return list;
};

export const gthPreTestScore = (score: number, val: any): boolean => {
  if (!isNumber(val)) return false;

  if (Number(val) > score) return true;
  else return false;
};
export const gthPostTestScore = (score: number, val: any): boolean => {
  if (!isNumber(val)) return false;
  if (Number(val) > score) return true;
  else return false;
};

export class ChildStore implements ChildStoreType {
  init = {
    ready: false,
    defers: [] as Array<{ storeName: string; q: Defer<{ storeName: string; store: any }>; store?: { storeName: string; store: any } }>,
  };

  store?: { [name: string]: any } | undefined = undefined as any;

  constructor(storeName?: string) {
    if (storeName) {
      this.init.defers.push({ storeName, q: defer() });
    }

    this.init.defers.forEach((n) => {
      n.q.promise.then((x: any) => {
        if (x.store) {
          this.init.ready = true;
          n.store = x.store;
          this.store = n.store;
        }
      });
    });
  }
}

export const getCalendarViewType = (val: string): CalendarEventViewType => {
  return [val === 'dayGridMonth' && 'month', val === 'timeGridWeek' && 'week', val === 'timeGridDay' && 'day'].filter((n) => !!n)[0] as any;
};

export const calendarRange = (calendarRange: CalendarEventsRange): { from: number; to: number } | null => {
  if (!calendarRange) return null;
  const from = new Date(calendarRange.from);
  const to = new Date(calendarRange.to);
  return {
    from: from.getTime(),
    to: to.getTime(),
  };
};

export const calendarViewApiRange = (view: ViewApi): { currentStart: number; currentEnd: number } => {
  return {
    currentStart: view.currentStart.getTime(),
    currentEnd: view.currentEnd.getTime(),
  };
};

/**
 * update manhours[] by buCode with updateWith{}
 */
export const updateManHours = (buCode: string, manhours: ManHourCompanySchema[], updateWith: { employeeCount: number }) => {
  const i = manhours.findIndex((d) => d.buCode === buCode);
  if (i > -1) manhours[i].employeeCount = updateWith.employeeCount;
  else manhours.push({ buCode: buCode, employeeCount: updateWith.employeeCount, manHour: 0 });
  return manhours;
};

export const getInputValue = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
  const val = e?.target?.value;

  if (!val || !Number(val)) {
    return 0;
  } else {
    return isNaN(Number(val)) ? 0 : Number(val) < 0 ? 0 : Number(val);
  }
};

export const fileMatch = (a: FileDropped, b: FileDropped): boolean => {
  return a.refer === b.refer;
};

/**
 * get responseURL information and any query params upon http response
 * @param searchParamName the param name will be returned in the response
 */
export const requestResponseUrl = (request: XMLHttpRequest, searchParamName?: string): { [name: string]: string; responseURL: string } => {
  try {
    const urlObj = new URL(request.responseURL);

    return {
      responseURL: urlObj.toString(),
      ...(searchParamName ? { [searchParamName]: urlObj.searchParams.get(searchParamName) } : {}),
    } as any;
  } catch (err) {
    return {} as any;
  }
};

export const genNewFile = (fileUrl: string, uid: string): FileDropped => {
  const getExtension = fileUrl.split('.').pop();
  const file = new File([fileUrl], uid, { lastModified: new Date().getTime(), type: 'image/' + getExtension });
  Object.assign(file, {
    preview: URL.createObjectURL(file),
    serverUrl: fileUrl,
  });
  return file as FileDropped;
};

/**
 *
 * @param maxSize represents mega bites
 */
export const validFileSize = (file: File, maxSize = 5) => {
  const fileSize = file.size / 1024 / 1024; // in MiB
  return fileSize < maxSize;
};

export const objectWithProp = (formParts: any): { [name: string]: any } => {
  const data: any = {} as any;
  for (const key in formParts) {
    // eslint-disable-next-line no-prototype-builtins
    if (formParts.hasOwnProperty(key)) {
      data[key] = formParts[key].data;
    }
  }
  return data as any;
};

/**
 *
 * transform each from part to from store format
 */
export const formStoreFormat = (formParts: { [name: string]: any }, state?: IncidentFormPartStoreState): IncidentSchemaParts => {
  const data: { [name: string]: IncidentFormDataType<any> } = {} as any;
  const _formParts = clone(formParts);
  try {
    for (const key in _formParts) {
      // eslint-disable-next-line no-prototype-builtins
      if (_formParts.hasOwnProperty(key)) {
        data[key] = {
          data: _formParts[key],
          ...(state ? { state } : {}),
        } as any;
      }
    }
  } catch (err) {
    return null as any;
  }

  return data as any;
};

/**
 * - extract data from LegalLorSchema to LegalSmallSchema
 * - or provide new if it doesnt exist yet
 */
export const transformLegalDataToListPageStore = (smallData: LegalSmallSchema, fullData: LegalLorSchema, type: 'new' | 'update'): LegalSmallSchema => {
  let data = smallData || {};

  try {
    if (type === 'new') {
      data = {
        id: fullData.id,
        code: fullData.code || '',
        status: fullData.status,
        shortName: (fullData.name || '')?.substring(0, 40) || '',
        fullName: fullData?.name || '',
        promulgationDate: fullData?.promulgationDate,
        effectiveDate: fullData?.effectiveDate,
        hierarchy: fullData?.hierarchy || '',
        relatedBu: fullData?.relatedBu || [],
        url: fullData?.file?.url || '',
        /**
         * always returns single item from array anyway
         */
        lorType: (fullData.lorTypes || [])[0],

        overdueState: fullData?.overdueState,
        evaluateDate: fullData?.evaluateDate,
        deadline: fullData?.deadline,
        evaluationId: fullData?.evaluationId,
      } as any;
    } else {
      data = {
        ...data,
        id: fullData.id,
        code: fullData?.code || '',
        status: fullData?.status,
        shortName: (fullData.name || '')?.substring(0, 40) || '',
        fullName: fullData?.name,
        promulgationDate: fullData?.promulgationDate,
        effectiveDate: fullData.effectiveDate,
        hierarchy: fullData?.hierarchy,
        relatedBu: fullData?.relatedBu || [],
        url: fullData?.file?.url,
        /**
         * always returns single item from array anyway
         */
        lorType: (fullData.lorTypes || [])[0],

        overdueState: fullData?.overdueState,
        evaluateDate: fullData?.evaluateDate,
        deadline: fullData?.deadline,
        evaluationId: fullData?.evaluationId,
      } as any;
    }
  } catch (err) {}

  return (data || {}) as LegalSmallSchema;
};

/**
 * - extract data from IncidentSchema to IncidentSmallSchema
 * - or provide new if it doesnt exist yet
 */
export const transformIncidentDataToListPageStore = (smallData: IncidentSmallSchema, fullData: IncidentSchema, isNew = false): IncidentSmallSchema => {
  let data = smallData || {};
  try {
    if (isNew) {
      data = {
        id: fullData.id as any,
        employeeId: fullData?.reporters?.reportBy?.employeeId, // < creator of incident/report
        name: fullData?.initialReport?.name,
        buName: fullData?.initialReport?.company?.buName,
        buCode: fullData?.initialReport?.company?.buCode,
        ...(fullData?.initialReport?.damageType?.length ? { damageType: fullData?.initialReport?.damageType } : {}),
        incidentDate: fullData?.initialReport.dateOfIncident,
        status: fullData?.status as any,
        incidentNo: fullData.incidentNo,
      } as any;

      return pickBy(data, identity) as any;
    }

    data.employeeId = fullData?.reporters?.reportBy?.employeeId || data.employeeId; // < creator of incident/report
    data.buName = fullData?.initialReport?.company?.buName || data.buName;
    data.buCode = fullData?.initialReport?.company?.buCode || data.buCode;
    data.damageType = fullData?.initialReport?.damageType?.length ? fullData?.initialReport?.damageType : data.damageType;
    data.incidentDate = fullData?.initialReport?.dateOfIncident ? fullData?.initialReport?.dateOfIncident : data.incidentDate;
    //  generated on creation
    data.incidentNo = (data.incidentNo || fullData.incidentNo) as any;

    data.name = fullData?.initialReport?.name || data.name;
    data.status = (fullData?.status || data.status) as any;
  } catch (err) {}

  return (data || {}) as IncidentSmallSchema;
};

/**
 * match current user by specific prop
 * @param o exactly match the current user to employee, in case same user logged in with different role, performing the same action
 */
const userMatchBy = (o: { roleId: boolean; employeeId: boolean }, user: UserSchema, employee: EmployeeSchema): EmployeeSchema | null => {
  let ok = false;
  if (o.employeeId) {
    ok = user?.userProfile?.employeeId === employee?.employeeId;
  }
  if (o.roleId) {
    ok = user?.userProfile?.roleId === employee?.roleId;
  }
  if (ok) return user?.userProfile;
  else return null;
};

/**
 * List incident approvals for all or bu current user
 *
 */
export const userIncidentApprovals = (
  reviewers: ReviewerSchema,
  user: UserSchema,
  approvedBy?: 'any' | 'current',
  /** if set exclude the member from results */
  excludeMember?: IncidentMemberTypes
): IncidentReportApprovalSchema[] => {
  // const employee = user?.userProfile;
  return (reviewers?.approvals || [])
    .filter((n) => {
      if (excludeMember === n.memberType && !!n?.memberType) return false;
      return true;
    })
    .filter((n) => {
      // WARN backend need to make sure if the same user logged in  with different role, the {approvals} object keeps history of that roleId and not the current role, otherwise we cannot validate approvals correctly
      // match by current user, and if approve has been set
      return approvedBy === 'current' ? !!userMatchBy({ roleId: true, employeeId: true }, user, n.employee) && !!n.approve : !!n.approve;
    }) as any;
};

/**
 * - Check and return only remarks that belong to current user with the same roleId, because each member can change its role to different one
 *
 */
export const userIncidentRemarks = (reviewers: ReviewerSchema, user: UserSchema): IncidentReportRemarkSchema[] => {
  return (reviewers?.remarks || []).filter((n) => !!userMatchBy({ roleId: true, employeeId: true }, user, n?.employee as any));
  // return (reviewers?.remarks || []).filter((n) => n?.ref !== userApprovals?.ref /* && (!!n.ref || !!userApprovals?.ref)*/) as any;
};

/**
 * Find out status of the report without calling the api
 * @param byId get incident status by id from current user (employeeId) or by incident (incidentId)
 * @param incidentReportList we can use currentUser.userOptions.incidentReports[]
 *
 */
// export const incidentStatusById = (
//   byId: { incidentId?: string; employeeId?: string } = {} as any,
//   incidentReportList: UserIncidentReports[]
// ): UserIncidentReports => {
//   return ((incidentReportList || []).filter((n) => {
//     if (byId.incidentId) return byId.incidentId === n.incidentId;
//     if (byId.employeeId) return byId.employeeId === n.employeeId;
//     else return false;
//   })[0] || undefined) as any;
// };

/**
 * generate years from to in range
 */
export const genYears = (now: Date, from: number = 10, to: number = 10): number[] => {
  const years: number[] = [];
  for (let year = now.getFullYear() - from; year < now.getFullYear() + to; year++) {
    years.push(year);
  }
  return years;
};

export const genYearsPastToPresent = (now: Date): number[] => {
  const years: number[] = [];
  const diffYear = Math.abs(now.getFullYear() - new Date().getFullYear());
  for (let year = now.getFullYear(); year <= now.getFullYear() + diffYear; year++) {
    years.push(year);
  }
  return years;
};

/**
 * @description approver of incident/employeeId
 * @param incident we can check for data from `{ IncidentSchema }` or from `{ IncidentSmallSchema }`
 */
export const incidentApproverByEmployeeId = (incident?: IncidentSchema): string => {
  try {
    if (!incident?.reporters && (incident as any)?.approvedByEmployeeId) {
      return (incident as any)?.approvedByEmployeeId;
    } else if (incident?.reporters) {
      return incident?.reporters?.approveBy?.employeeId as any;
    }
  } catch (err) {}
  return null as any;
};

/**
 * @description reporter/owner of incident/employeeId
 * @param incident we can check for data from `{ IncidentSchema }` or from `{ IncidentSmallSchema }`
 */
export const incidentReportByEmployeeId = (incident?: IncidentSchema): string => {
  try {
    if (!incident?.reporters && (incident as any)?.employeeId) {
      return (incident as any)?.employeeId;
    } else if (incident?.reporters) {
      return incident?.reporters?.reportBy?.employeeId as any;
    }
  } catch (err) {}
  return null as any;
};

/**
 *
 * User/logic permissions on incident page's
 */
export function userCanOnIncident(
  user: UserSchema,
  incident: IncidentSchema,
  status: IncidentStatusReport,
  actions: 'can_delete' | 'can_edit' | 'can_view' | 'can_submit_approval' | 'can_submit_remark'
  /** optional only required for actions/can_submit_approval and  actions/can_submit_approval/can_submit_remark*/

  /** conditional validation for different destination routes */
  // destination?: 'update' | 'detail'
): boolean {
  const emptyIncident = (() => {
    let ok = true;
    const d: any = incident;
    if (!d?.reporters) ok = false;
    if (!d?.employeeId) ok = false;
    return ok;
  })();

  if (!user?.userProfile) return false;
  // if (['can_submit_approval', 'can_submit_remark', 'can_view'].indexOf(actions) === -1 && !ownerEmployeeId) return false;
  if (!isNumber(status)) return false;
  if (['can_submit_approval', 'can_submit_remark', 'can_view'].indexOf(actions) !== -1 && emptyIncident) {
    Logger([`[userCanOnIncident][actions][${actions}]`, 'reporters object is required for this action'], 'warn');
    return false;
  }

  if (actions === 'can_delete') {
    if (
      user.userProfile.roleId === UserRoleIdConst.super_admin &&
      (IncidentStatusReportConst.draft === status || IncidentStatusReportConst.review === status)
    ) {
      // can delete draft if you are super_admin and status is draft
      return true;
    }

    // can delete in draft/review if you are the super_admin
    if (
      user.userProfile.employeeId === incidentReportByEmployeeId(incident as any) &&
      (IncidentStatusReportConst.draft === status || IncidentStatusReportConst.review === status) &&
      // if creator is bu/super or general user
      isAdmin(user.userProfile)
    ) {
      return true;
    }

    // can delete in draft only if you are the owner
    if (
      user.userProfile.employeeId === incidentReportByEmployeeId(incident as any) &&
      IncidentStatusReportConst.draft === status &&
      // if creator is bu/super or general user
      isUserOrBuAdmin(user.userProfile)
    ) {
      return true;
    }
    Logger([`[userCanOnIncident][actions][${actions}]`, 'user cannot perform this action'], 'warn');
    return false;
  }

  if (actions === 'can_edit') {
    // can edit page as super admin in draft mode
    // super_admin who does not own the report cannot edit it in draft mode
    // if (isSuperAdmin(user.userProfile) && IncidentStatusReportConst.draft === status) {
    //   // can delete draft if you are super_admin and status is draft
    //   return true;
    // }

    // super_admin can edit in review and approve or reject
    if (isSuperAdmin(user.userProfile) && IncidentStatusReportConst.review === status) {
      // can delete draft if you are super_admin and status is draft
      return true;
    }

    // NOTE  if creator is bu/super or general user
    if (
      user.userProfile.employeeId === incidentReportByEmployeeId(incident as any) &&
      IncidentStatusReportConst.draft === status &&
      (isUser(user.userProfile) || isAdmin(user.userProfile))
    ) {
      return true;
    }
    Logger([`[userCanOnIncident][actions][${actions}]`, 'user cannot perform this action'], 'warn');
    return false;
  }

  // these users can view detail page and icon on list page
  if (actions === 'can_view') {
    // super admin can see in any status
    if (isSuperAdmin(user.userProfile)) {
      return true;
    }
    // all other members and users and see when not in draft status
    if (
      (isGM(user.userProfile) ||
        isHod(user.userProfile) ||
        isUserOrBuAdmin(user.userProfile) ||
        isTeamLead(user.userProfile, { approveBy: incidentApproverByEmployeeId(incident) } as any)) &&
      IncidentStatusReportConst.draft !== status
    ) {
      return true;
    }
    // NOTE  above condition supersede this statement
    // general user/ report creator can only view detail page when not draft
    // if (user.userProfile.employeeId === incidentReportByEmployeeId(incident as any) && IncidentStatusReportConst.draft !== status && isUser(user.userProfile)) {
    //   return true;
    // }

    // NOTE this will not allow other users to see
    // any other users cannot view the detail page

    // if (user.userProfile.employeeId !== incidentReportByEmployeeId(incident as any)) {
    //   return false;
    // }

    Logger([`[userCanOnIncident][actions][${actions}]`, 'user cannot perform this action'], 'warn');
    return false;
  }

  if (actions === 'can_submit_approval') {
    // no one can approve already closed report
    if (IncidentStatusReportConst.closed === status) {
      Logger([`[userCanOnIncident][actions][${actions}]`, 'report already closed cannot approve'], 'warn');
      return false;
    }
    // current user can approve in review
    // {employeeId} is general user but a Team Lead from reporters/approveBy object under IncidentSchema{}
    // should not be able to re/approve the same again, if the employee is found under reviewers/approvals should not be able to approve again

    // if current user has no approvals on this report, they can approve
    // if user rejected the report, all reviewers.approvals are cleared to empty array, so reviewers.approvals[]

    const noApprovals = !userIncidentApprovals(incident?.reviewers as any, user, 'current', 'user_admin')[0];
    if (noApprovals) {
      if (IncidentStatusReportConst.ongoing === status && isTeamLead(user.userProfile, incident?.reporters)) {
        return true;
      }

      // current user can approve in review
      // current user is Head of department identified by its role and can approve
      if (IncidentStatusReportConst.ongoing === status && isHod(user.userProfile)) {
        return true;
      }
      // current user can approve in review
      // current user is GM identified by its role and can approve to closed status
      if (IncidentStatusReportConst.ongoing === status && isGM(user.userProfile)) {
        return true;
      }
    }

    Logger([`[userCanOnIncident][actions][${actions}]`, 'user cannot perform this action'], 'warn');
  }

  if (actions === 'can_submit_remark') {
    // console.log('can submit remark 1');
    if (
      (isTeamLead(user?.userProfile, incident?.reporters as any) || isHod(user?.userProfile)) &&
      IncidentStatusReportConst.ongoing === status &&
      // if current user has no approvals on this report, they send remarks
      // if user rejected the report, all reviewers.approvals are cleared to empty array, so reviewers.approvals[]
      !userIncidentApprovals(incident?.reviewers as any, user, 'current', 'user_admin')[0]
    ) {
      return true;
    }
  }

  Logger(
    [
      `[userCanOnIncident][actions][${actions}]`,
      `user cannot perform this action, also...`,
      `no condition met for current user/employeeId:${user.userProfile.employeeId} `,
      `with incident/employeeId: ${incidentReportByEmployeeId(incident as any)}`,
    ],
    'warn'
  );

  return false;
}

export const legalRelatedBuAdmin = (user: UserProfile, legal: LegalLorSchema): boolean => {
  if (!user) return false;
  if (!legal.relatedBu) return false;
  const isRelatedBu = (legal.relatedBu || []).filter((n) => n.buCode === user?.buCode).length > 0;
  return isBuAdmin(user as any) && isRelatedBu;
};

/**
 *
 * User logic permissions on Legal page's
 */
export function userCanOnLegal(
  user: UserSchema,
  /**
   * can assign LegalSmallSchema also but need to check if conditions match, because its not all the same
   */

  status: LegalStatusSchema,
  actions: 'can_view' | 'can_create' | 'can_edit' | 'can_delete' | 'can_evaluate',
  legal?: LegalLorSchema
): boolean {
  if (!user?.userProfile) return false;
  if (!isNumber(status)) return false;

  switch (actions) {
    case 'can_view': {
      // only super_admin, bu_admin, and all general user can view, when not in draft
      return (isSuperAdmin(user.userProfile) || isBuAdmin(user.userProfile) || isGeneral(user.userProfile)) && LegalStatusConst.draft !== status;
    }

    case 'can_create': {
      // only super_admin can create publish it
      return isSuperAdmin(user.userProfile);
    }

    case 'can_edit': {
      // only super_admin can create/update draft and publish it
      return isSuperAdmin(user.userProfile) && LegalStatusConst.draft === status;
    }

    case 'can_delete': {
      // only super_admin can delete when in draft
      return LegalStatusConst.draft === status && isSuperAdmin(user.userProfile);
    }

    case 'can_evaluate': {
      return (
        !!legal &&
        (LegalStatusConst.progress === status || LegalStatusConst.published === status) &&
        (isSuperAdmin(user.userProfile) || legalRelatedBuAdmin(user.userProfile, legal))
      );
    }

    // eslint-disable-next-line no-fallthrough
    default: {
      Logger([`[userCanOnLegal][actions][${actions}]`, 'no condition met']);
      return false;
    }
  }
}

/**
 * - Assign data by valid array props in to `this[item]=data[item]`
 * - updates external _this object
 */
export const assignPropsToThis = (propsArr: any[], data: any, _this: any) => {
  for (const item of propsArr) {
    try {
      if (isArray(item)) {
        // go through each form part, second level
        const part = item as any;
        const key1: string = part[0];
        const arr: string[] = part[1];

        if (!data[key1]) return; // form part is empty or doesnt exist

        if (!_this[key1]) _this[key1] = {};
        // if (isEmpty(_this[key1])) {
        //   if (isArray(_this[key1])) {
        //     _this[key1] = [];
        //   }
        //   if (isObject(_this[key1])) {
        //     _this[key1] = {};
        //   } else {
        //     // REVIEW DOES IT NEED TO BE AN OBJECT OR STRING ?
        //     _this[key1] = {};
        //   }
        //   // _this[key1] = {};
        // }
        for (const key2 of arr) {
          try {
            if (data[key1][key2] === undefined) continue; // from part property doesnt exist
            if (!_this[key1][key2]) _this[key1][key2] = {};
            _this[key1][key2] = data[key1][key2];
          } catch (err) {}
        }
      } else {
        if (data[item] !== undefined) _this[item as any] = data[item];
      }
    } catch (err) {}
  }
};

export const getClassNameLegalStatus = (legalStates: LegalStates): string => {
  type overdueType = 'publish-overdue' | 'progress-overdue' | 'evaluate-overdue';
  const status: LegalStatusSchema | overdueType = [
    legalStates.overdueState === 2 && 'publish-overdue',
    legalStates.overdueState === 3 && 'progress-overdue',
    legalStates.overdueState === 6 && 'evaluate-overdue',
    legalStates.status,
  ].filter((n) => !!n)[0] as any;
  switch (status) {
    case 1:
      return 'draft';
    case 2:
      return 'published';
    case 3:
      return 'inProgress';

    // can be overdue
    case 'progress-overdue':
      return 'overdue';
    case 'publish-overdue':
      return 'overdue';
    case 'evaluate-overdue':
      return 'overdue';
    // overdue end

    // evaluate state was added lated, so thats why its in wrong order
    case 6:
      return 'onEvaluate';
    // end
    case 5:
      return 'completed';
    default:
      return '';
  }
};
/**
 * - Check if Legal/lor is overdue or not
 * - we need {state, overdueState} to check the condition of each lor
 */
export const legalIsOverdue = (legalStates: LegalStates): boolean => {
  if (!legalStates) return false;

  /** not overdue */
  if (legalStates.overdueState === null || legalStates.overdueState === undefined || legalStates.overdueState === 0) return false;

  /** published state, not started evaluation now overdue */
  if (legalStates.overdueState === 2) return true;
  /** in-progress state, not submitted for evaluation now overdue */
  if (legalStates.overdueState === 3) return true;
  if (legalStates.overdueState === 6) return true;
  else {
    Logger(['[legalIsOverdue][overdueState]', `Invalid overdueState: ${legalStates.overdueState}`], 'warn');
    return false;
  }
};

export const legalNiceType = (type: LegalLorTypeSchema): string => {
  if (!type) return '-';
  if (type.value === 1) return 'Env';
  if (type.value === 2) return 'Sec';
  if (type.value === 3) return 'Saf';
  if (type.value === 4) return 'Hea';
  else return '-';
};

interface CheckParams {
  [name: string]: any;
}

/**
 * - correct invalid params
 * - issue callback if param is invalid
 */
export const checkParams = (searchParams: URLSearchParams, paramName: 'year', onUpdate: (d: CheckParams) => void): CheckParams => {
  const d = {
    year: null,
  } as any;

  if (paramName === 'year') {
    const year = searchParams.get(paramName) || '';
    const num = Number(year);
    const validLen = (num || '').toString().length === 4;
    if (isNaN(num) || !num || !validLen) {
      d.year = new Date().getFullYear().toString();
      onUpdate(d);
    } else {
      d.year = year;
    }
    return d;
  }
  // if (paramName === 'something') {}
  else return {} as any;
};

export const isTheSameDate = (date1: string | Date, date2: string | Date): boolean => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);
  if (d1.getTime() === d2.getTime()) return true;
  else return false;
};

export const getYearArray = (endYear = 2014) => {
  const now = new Date();
  const temp: number[] = [];
  for (let i = endYear; i < now.getFullYear() + 1; i++) {
    temp.push(i);
  }
  return temp;
};

// Excel Style
export const excelStyleFilter = (worksheet: any, rowIds: number[] = [], mergeCols: number = 1) => {
  rowIds.forEach((i) => {
    worksheet.mergeCells(i, 2, i, 2 + mergeCols);
    const _cells = worksheet.getCell(`A${i}`);
    _cells.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    _cells.font = { bold: true };
    _cells.border = {
      top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    };
    _cells.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD6E8CF' },
    };
    worksheet.getCell(`B${i}`).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    worksheet.getCell(`B${i}`).border = {
      top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    };
  });
};
export const excelStyleHeader = (worksheet: any, rowIds: number[] = [], mergeCols: number = 2) => {
  rowIds.forEach((i) => {
    worksheet.mergeCells(i, 1, i, 1 + mergeCols);
    const _cells = worksheet.getCell(`A${i}`);
    _cells.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    _cells.font = { bold: true };
    _cells.border = {
      top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    };
    _cells.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD6E8CF' },
    };
  });
};
export const excelStyleBody = (worksheet: any, rowIds: number[] = [], mergeCols: number = 2) => {
  rowIds.forEach((i) => {
    worksheet.mergeCells(i, 1, i, 1 + mergeCols);
    const _cells = worksheet.getCell(`A${i}`);
    _cells.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    _cells.border = {
      bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    };
  });
};
export const excelTableHeader = (worksheet: any, rowId: number, limitCols: number = 3) => {
  const _rows = worksheet.getRow(rowId);
  _rows.font = { bold: true };
  _rows.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
  _rows.eachCell((cell, j) => {
    if (j <= limitCols) {
      cell.border = {
        bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
        left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
        right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD6E8CF' },
      };
    }
  });
};
export const excelTableBody = (worksheet: any, rowId: number, limitCols: number = 3) => {
  const _rows = worksheet.getRow(rowId);
  _rows.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
  _rows.eachCell((cell, j) => {
    if (j <= limitCols) {
      cell.border = {
        bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
        left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
        right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      };
    }
  });
};
export const excelRowInfo = (worksheet: any, rowId: number, startCol: string, endCol: string) => {
  const _startCol = worksheet.getCell(`${startCol}${rowId}`);
  _startCol.font = { bold: true };
  _startCol.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
  _startCol.border = {
    top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
  };
  _startCol.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFD6E8CF' },
  };
  const _endCol = worksheet.getCell(`${endCol}${rowId}`);
  _endCol.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
  _endCol.border = {
    top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
  };
};
export const excelAllBorders = (worksheet: any, rowId: number, limitCols: number = 0, withBg: boolean = true, type: number = 1) => {
  if (limitCols > 0) {
    const _rows = worksheet.getRow(rowId);
    _rows.eachCell({ includeEmpty: true }, (cell, j) => {
      if (j <= limitCols) {
        if (withBg) {
          cell.font = { bold: true };
          cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          cell.border = {
            top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
            bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
            left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
            right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD6E8CF' },
          };
        } else {
          if (type === 2 && j >= 3 && j <= 8) {
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          } else {
            cell.alignment = { wrapText: true, vertical: 'middle' };
          }
          cell.border = {
            top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
            bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
            left: { style: 'thin', color: { argb: 'FFAAAAAA' } },
            right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
          };
        }
      }
    });
  }
};
export const excelRowOne = (worksheet: any, rowId: number, endColNum: number) => {
  const _rows = worksheet.getRow(rowId);
  _rows.alignment = { wrapText: true, vertical: 'middle' };
  _rows.eachCell({ includeEmpty: true }, (cell, j) => {
    if (j <= 3 || j === endColNum) cell.font = { bold: true };
    if (j === 1) cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
    cell.border = {
      right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    };
  });
};
export const excelRowEnd = (worksheet: any, rowId: number, endColNum: number) => {
  const _rows = worksheet.getRow(rowId);
  _rows.alignment = { wrapText: true, vertical: 'middle' };
  _rows.font = { bold: true };
  _rows.eachCell({ includeEmpty: true }, (cell, j) => {
    if (j === 1) cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
    cell.border = {
      top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
      right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
    };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD6E8CF' },
    };
  });
};
export const excelTrainingInfo = (worksheet: any, rowIds: number[]) => {
  rowIds.forEach((rowId) => {
    const _rows = worksheet.getRow(rowId);
    _rows.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    _rows.eachCell({ includeEmpty: true }, (cell, j) => {
      if (j > 6) cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
      if (j % 2 === 1) {
        cell.font = { bold: true };
        cell.border = {
          top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
          bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD6E8CF' },
        };
      } else {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FFAAAAAA' } },
          bottom: { style: 'thin', color: { argb: 'FFAAAAAA' } },
          right: { style: 'thin', color: { argb: 'FFAAAAAA' } },
        };
      }
    });
  });
  worksheet.mergeCells(rowIds[0], 7, rowIds[rowIds.length - 1], 8);
};
