import { IconButton, TableBody, TableCell, TableRow, Box, Table, TableContainer } from '@mui/material';
// import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { Icon } from '../../icons';

import { delay, isSuperAdmin, printRoleNameById } from '@/src/utils';
import { GetEmployeeList, StoreState } from '@/src/types';
import { headCellsAdmins } from '@/src/static';
import { EnhancedTableHead } from '../../tableHead';
import { useGlobalContext } from '@/store/index';
import { EmployeeSchema } from '@/types/schema';
import { TableDataError } from '../../dataError';
import { TableDataNotFound } from '../../dataNotFound';
import EditAdminModal from '../editAdminModal';
import DeleteAdminModal from '../deleteAdminModal';

interface Props {
  [name: string]: any;
  data: GetEmployeeList;
  loadlistpage: Function;
  stateData: StoreState;
  isFiltered: boolean;
}

export function EnhancedTableAdmin(props: Props) {
  const { currentUser } = useGlobalContext();
  // const userRoleId: any = currentUser?.userProfile?.roleId;
  const { data, loadlistpage, stateData, isFiltered } = props;
  const [currentid, setCurrentid] = useState('');
  const [selData, setSelData] = useState({});
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [confirmDeleteModalState, setConfirmDeleteModalState] = useState<'INITIAL' | 'OPEN' | 'DELETE'>('INITIAL');

  const handleOpenDelete = (employeeId) => {
    setConfirmDeleteModalState('OPEN');
    setCurrentid(employeeId);
  };

  const handleOpenEdit = (row) => {
    setOpenEdit(true);
    setSelData(row);
  };
  const onCAllBack = (type: 'setOpenEdit' | 'loadlistpage', data?: boolean) => {
    if (type === 'setOpenEdit' && data !== undefined) {
      setOpenEdit(data);
    }
    if (type === 'loadlistpage') {
      delay(500).then(() => {
        loadlistpage();
      });
    }
  };

  /**
   * Super Admin can Add, update, delete everyone at top level
   * Bu Admin can:
   *  - add: new BU admin from same company
   *  - update: BU ADMIN from same company
   *  - delete: BU Admin from same company
   * Bu Admin cannot:
   * - update/delete from different company
   * @param employee
   */
  const showConditions = (userProfile: EmployeeSchema, employee: EmployeeSchema) => {
    if (!userProfile) return false;
    let pass = false;
    if (
      // same role
      (userProfile.roleId === employee.roleId &&
        // same company
        userProfile.buCode === employee.buCode) ||
      // or Super Admin
      isSuperAdmin(userProfile)
    ) {
      pass = true;
    }

    if (userProfile.roleId === 2 && employee.roleId === 2 && userProfile.buCode === employee.buCode) {
      pass = true;
    } else if (!isSuperAdmin(userProfile)) {
      pass = false;
    }

    return pass;
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead headCells={headCellsAdmins} />

          {stateData === 'error' && <TableDataError col={5} description={`Please reload the page again.`}></TableDataError>}

          {stateData === 'ready' && (
            <TableBody>
              {data.data.map((row, inx) => (
                <TableRow key={`${row.firstName}+${inx}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.firstName}&nbsp;{row.lastName}
                  </TableCell>
                  <TableCell>{printRoleNameById(row.roleId)}</TableCell>
                  <TableCell>{row.buName + ' (' + row.buCode + ')'}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell align="right">
                    <Box className="flex justify-end">
                      {/** Super Admin can > Bu Admin can === Bu Admin */}

                      {showConditions(currentUser?.userProfile, row) ? (
                        <>
                          {isSuperAdmin(currentUser?.userProfile) ? (
                            <IconButton color="primary" aria-label="add to shopping cart" className="" onClick={() => handleOpenEdit(row)}>
                              <Icon icon="penToSquare" color="#4958CC" viewBox="0 0 512 512" size={18} />
                            </IconButton>
                          ) : null}

                          <IconButton color="error" aria-label="add to shopping cart" className="mx-4" onClick={() => handleOpenDelete(row.employeeId)}>
                            <Icon icon="trash" color="#F14242" viewBox="0 0 448 512" size={18} />
                          </IconButton>
                        </>
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {stateData === 'ready' && data.data.length === 0 && (
            <TableDataNotFound
              col={5}
              description={isFiltered ? 'Please change search keyword or select another company' : 'No admin information is provided to you'}
            ></TableDataNotFound>
          )}
        </Table>
      </TableContainer>

      <EditAdminModal onCAllBack={onCAllBack} employee={selData} openEdit={openEdit} ready={stateData === 'ready'} />
      <DeleteAdminModal
        title={'Are you sure you want to delete this admin?'}
        description={'This action will permanently delete the admin and cannot be undone.'}
        state={confirmDeleteModalState}
        setState={setConfirmDeleteModalState}
        currentid={currentid}
        loadlistpage={loadlistpage}
      ></DeleteAdminModal>
    </>
  );
}
