import {
  IncidentBodyData,
  IncidentStatusReport,
  IncidentInitialReportPartial,
  IncidentDescriptionPartial,
  IncidentInjuredPartial,
  IncidentPropertyDamagePartial,
  IncidentInvestigationPartial,
  IncidentCausesAssessmentPartial,
  IncidentInvestigationTeamPartial,
  IncidentCorrectiveActionPartial,
  IncidentFileSchema,
  ReviewerSchema,
} from '@/src/types';

import { isArray } from 'lodash';
import { ENVS_CONFIG } from '@/src/constants';

// const loadExampleData = () => {
//   try {
//     return require('@/static/trainings/createform.training.example.json');
//   } catch (err) {
//     Logger(['[loadExampleData]', err], 'error');
//     return {};
//   }
// };

const legalPropsAssignments = [
  'id',
  'incidentNo',
  'status',
  ['reviewers', ['remarks', 'approvals']],
  [
    'initialReport',
    [
      'name',
      'age',
      'employeeId',
      'position',
      'jobExperience',
      'phone',
      'damageType',
      'dateOfIncident',
      'dateOfReport',
      'company',
      'location',
      'functions',
      'jobClassification',
      'activityTypes',
      'activityTypeOther',
      'categoryEvents',
      'categoryEventOther',
      'costEstimate',
    ],
  ],
  ['description', ['whatDesc', 'whyDesc', 'whyDesc', 'whenDesc', 'howDesc', 'whoInvolved', 'images']],
  ['injured', ['noDataSkip', 'injuryTypes', 'injuryTypeOther', 'bodyLeftTypes', 'bodyRightTypes', 'headNeckParts', 'upperLimbParts', 'lowerLimbParts']],
  ['propertyDamage', ['noDataSkip', 'propertyType', 'propertyTypeOther', 'arrivalDate', 'treatmentDetail', 'deposition', 'incidentClassification']],
  ['incidentInvestigation', ['htmlText']],
  ['incidentCausesAssessment', ['casualFactors', 'rootCause']],
  ['incidentInvestigationTeam', ['investigators']],
  ['correctiveAction', ['actionItems']],
];

/**
 * should only send one training type upon submit
 */
const conditionedData = (formStateDate: IncidentBodyData): IncidentBodyData => {
  const data: any = formStateDate;
  if (!data) return undefined as any;
  //   if (!formStateDate?.employeeList?.length) {
  //     delete data.employeeList;
  //   }

  return data as IncidentBodyData;
};

export class IncidentRequestDto implements IncidentBodyData {
  id?: string;
  incidentNo?: string;
  status?: IncidentStatusReport;
  reviewers?: ReviewerSchema[];
  // one
  initialReport: IncidentInitialReportPartial = undefined as any;
  // initialReport: IncidentInitialReportPartial = {
  //   /* part 1 section 1 */
  //   name: undefined,
  //   age: undefined,
  //   employeeId: undefined,
  //   position: undefined,
  //   jobExperience: undefined,
  //   phone: undefined,
  //   damageType: undefined,

  //   /* part 1 section 2 */
  //   /** includes data and time */
  //   dateOfIncident: undefined,
  //   dateOfReport: undefined,
  //   company: undefined,
  //   location: undefined,
  //   functions: undefined,
  //   jobClassification: undefined,

  //   /* part 1 section 3 */
  //   activityTypes: undefined,
  //   /* this is optional and nullable */
  //   activityTypeOther: undefined,
  //   categoryEvents: undefined,
  //   /* this is optional and nullable */
  //   categoryEventOther: undefined,
  //   costEstimate: undefined,
  // } as any;

  /**
   * TODO
   * @part two
   *
   * - Description, part:2 schema
   * - nullable is not set
   */
  description?: IncidentDescriptionPartial = undefined as any;

  /**
   * TODO
   * @part three
   *
   * - Injured/illness personal data, part:3 schema
   * - nullable is not set
   */
  injured?: IncidentInjuredPartial = undefined as any;

  /**
   * TODO
   * @part four
   *
   * - Property damage, part:4 schema
   * - nullable is not set
   */
  propertyDamage?: IncidentPropertyDamagePartial = undefined as any;

  /**
   * TODO
   * @part five
   *
   * - Incident investigation, part:5 schema
   * -  nullable is not set
   */
  incidentInvestigation?: IncidentInvestigationPartial = undefined as any;

  /**
   * TODO
   * @part six
   *
   * - Incident cases assessment, part:six schema
   * - nullable is not set
   */
  incidentCausesAssessment?: IncidentCausesAssessmentPartial = undefined as any;

  /**
   * TODO
   * @part seven
   *
   * - Incident investigation team, part:seven schema
   * - nullable is not set
   */
  incidentInvestigationTeam?: IncidentInvestigationTeamPartial = undefined as any;

  /**
   * TODO
   * @part eight
   *
   * - Corrective action, part:eight schema
   * - nullable is not set
   */
  correctiveAction?: IncidentCorrectiveActionPartial = undefined as any;

  constructor(data?: IncidentBodyData, loadMock?: boolean) {
    let _data;
    // assign only required data
    if (Object.keys(data || {}).length) {
      _data = data;
    } else if (loadMock) {
      // todo
      // _data = loadExampleData();
    }

    _data = conditionedData(_data);
    if (_data) {
      legalPropsAssignments.forEach((item) => {
        try {
          if (isArray(item)) {
            // go through each form part, second level
            const part = item as any;
            const key1: string = part[0];
            const arr: string[] = part[1];

            if (!_data[key1]) return; // form part is empty or doesnt exist

            if (!this[key1]) this[key1] = {};
            for (const key2 of arr) {
              try {
                if (_data[key1][key2] === undefined) continue; // from part property doesnt exist
                if (!this[key1][key2]) this[key1][key2] = {};
                this[key1][key2] = _data[key1][key2];
              } catch (err) {}
            }
          } else {
            if (_data[item] !== undefined) this[item as any] = _data[item];
          }
        } catch (err) {}
      });
    }
  }
}

export class AddImageSectionDto implements IncidentFileSchema {
  cached: boolean = false;
  ref?: string = null as any;
  uid: string = null as any;
  url: string = null as any;
  testImage: boolean = false as any;
  constructor(image: IncidentFileSchema & { testImage?: boolean }) {
    const legalProps = ['cached', 'uid', 'url', 'testImage', 'file'];
    legalProps.forEach((n) => {
      if (n === 'url') {
        let url;
        if (image.testImage) {
          url = image[n];
        } else {
          url = new URL(`${ENVS_CONFIG.REACT_APP_API_PREFIX}/${image[n]}`, `${ENVS_CONFIG.REACT_APP_API_BASE}`).toString();
        }
        this[n] = url;
      } else {
        this[n] = image[n];
      }
    });
  }
}
