/* eslint-disable spaced-comment */

import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { AzureAuthProviderProps, IncidentStore, PropDefaults, ReducerActionType } from '@/src/types';
//import { Logger } from '@/logger/index';
import { TRIRoptions } from './chart.options';
import { clone } from 'lodash';

type ChartOptions = typeof TRIRoptions;

interface Props extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
  //onChartClick: (data: OverallGraphSelectedValue) => void;
  /**
   * called when chart is ready
   */
  onChartReady: () => void;
  chartQueryState: ReducerActionType;
}

/**
 *
 * Left side chart
 */
export function TRIRchartLeft(props: Props & IncidentStore) {
  const { store: incidentStore, onChartReady, chartQueryState } = props;
  const [mount, setMount] = useState<number>(0);
  const [optionsData, setOptions] = useState<ChartOptions>(TRIRoptions);
  const [chartInstance, setChartInstance] = useState<ReactECharts>(null as any);
  const updateChart = () => {
    if (chartInstance) {
      const inst = chartInstance.getEchartsInstance();
      inst.setOption(optionsData as any);
      inst.resize();
    }
  };

  useEffect(() => {
    if (incidentStore.incidentChartData.state === 'ready') {
      optionsData.xAxis.data = incidentStore.incidentChartData?.data?.data?.trir?.categoryList as any;
      optionsData.series[0].data = incidentStore.incidentChartData?.data?.data?.trir?.seriesData as any;

      optionsData.series[0].data = optionsData.xAxis.data.map((d, i) => {
        if (optionsData.series[0].data[i]) {
          return optionsData.series[0].data[i] as never;
        } else {
          return 0 as never;
        }
      });

      setOptions(clone(optionsData));
      updateChart();
    }

    if (incidentStore.incidentChartData.state === 'error') {
      optionsData.title = {
        ...optionsData.title,
        color: 'grey',
        fontSize: 17,
        text: 'No data',
        left: '25%',
        top: 'center',
      } as any;
      optionsData.xAxis.data = [];
      optionsData.series[0].data = [];
      setOptions(clone(optionsData));
      updateChart();
    }

    if (!mount) setMount(1);
  }, [mount, setMount, incidentStore, chartInstance, setChartInstance]);

  return (
    <Box className="p-6 pb-10 rounded-2xl border border-solid border-gray-200 bg-white relative max-h-[280px] shadow-md">
      {chartQueryState?.value?.loading && (
        <CircularProgress className="mr-2 absolute z-40" sx={{ top: 'calc(50% - 15px)', left: 'calc(50% - 15px)' }} size={40} />
      )}
      {mount && (
        <ReactECharts
          ref={(r: any) => {
            if (r) {
              r.inx = (r.inx || 0) + 1;
              setChartInstance(r as any);
            }
          }}
          onChartReady={onChartReady}
          option={optionsData}
          className={`mb-3 ${chartQueryState?.value?.loading && 'opacity-25'}`}
        />
      )}
    </Box>
  );
}
