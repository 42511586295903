import {
  IncidentFunctions,
  IncidentJobClassification,
  IncidentDamageType,
  // IncidentActivityType,
  // IncidentCategoryEvent,
  PropertyDamageCategory,
  PropertyDamageDeposition,
  PropertyDamageIncident,
} from '@/src/types';
import { incidentDamageTypes } from '..';

interface InitialReportType {
  incidentDamageType: Array<{ value: IncidentDamageType; label: string; iconPath?: string }>;
  functions: Array<{ value: IncidentFunctions; label: string }>;
  jobClassification: Array<{ value: IncidentJobClassification['choice']; label: string }>;
  // incidentActivityType: Array<IncidentActivityType>;
  // incidentCategoryEvent: Array<IncidentCategoryEvent>;
}
interface propertyDamageType {
  propertyDamageCategory?: Array<PropertyDamageCategory>;
  propertyDamageDeposition: Array<PropertyDamageDeposition>;
  propertyDamageIncident: Array<PropertyDamageIncident>;
}
export const initialReport: InitialReportType = {
  incidentDamageType: incidentDamageTypes as any,

  functions: [
    { value: 1, label: 'Exploration' },
    { value: 2, label: 'Production' },
    { value: 3, label: 'Service' },
    { value: 4, label: 'Unspecified' },
  ],
  jobClassification: [
    { value: 1, label: 'Company' },
    { value: 2, label: 'Contractor' },
    { value: 3, label: 'Third Party' },
  ],
  // incidentActivityType: [
  //   { value: 1, label: 'Maintenance, Inspection, Testing' },
  //   { value: 2, label: 'Drone activity' },
  //   { value: 3, label: 'Diving, Subsea, ROV' },
  //   { value: 4, label: 'Office, Warehouse' },
  //   { value: 5, label: 'Transport-Water/Marine activity' },
  //   { value: 6, label: 'Off-duty, Recreation' },
  //   { value: 7, label: 'Transport-Land' },
  //   { value: 8, label: 'Other' },
  // ],
  // incidentCategoryEvent: [
  //   { value: 1, label: 'Vehicle related' },
  //   { value: 2, label: 'Fire/Explosion/Burn' },
  //   { value: 3, label: 'Fall form height' },
  //   { value: 4, label: 'Electrical related' },
  //   { value: 5, label: 'Water-related' },
  //   { value: 6, label: 'Confined space' },
  //   { value: 7, label: 'Security incident' },
  //   { value: 8, label: 'AUV, ROV  ' },
  //   { value: 9, label: 'UAS ' },
  //   { value: 10, label: 'UAV' },
  //   { value: 11, label: 'Robotics' },
  //   { value: 12, label: 'Other' },
  // ],
};
export const propertyDamage: propertyDamageType = {
  propertyDamageCategory: [
    { value: 1, label: 'UAS' },
    { value: 2, label: 'UAV' },
    { value: 3, label: 'AUV' },
    { value: 4, label: 'ROV' },
    { value: 5, label: 'Robotics' },
  ],
  propertyDamageDeposition: [
    { value: 1, label: 'Treatment Onsite' },
    { value: 2, label: 'Hospital' },
  ],
  propertyDamageIncident: [
    { value: 1, label: 'First Aid Case' },
    { value: 2, label: 'Lost Work Day Case' },
    { value: 3, label: 'Permanent Disability' },
    { value: 4, label: 'Fatality Case' },
  ],
};
