import { Logger } from '@/logger/index';
import { Icon, ProgressBarIncident } from '@/src/components';
import { LegalStore } from '@/src/types';
import {
  exportLinkV2,
  genYearsPastToPresent,
  printDate,
  isSuperAdmin,
  delay,
  excelStyleFilter,
  excelStyleHeader,
  excelStyleBody,
  excelTableHeader,
  excelTableBody,
} from '@/src/utils';
import {
  Box,
  Typography,
  Button,
  Modal,
  IconButton,
  SelectChangeEvent,
  MenuItem,
  OutlinedInput,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogProps,
  DialogActions,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '@/store/index';
import { LegalStatusConst } from '@/constants/index';
import { cloneDeep } from 'lodash';
import LegalForm from '@/components/ui/legals/createUpdateLegalModal/legalFromClass';

import ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';

export default function HistoryUI(props: LegalStore) {
  const { currentUser } = useGlobalContext();
  const [searhParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const now = new Date();
  const currentYear = now.getFullYear();

  const { detailId } = useParams();
  const year = searhParams.get('year') || '';
  const [arrYears, setArrYears] = useState<number[]>([]);
  const [mount, setMount] = useState<number>(0);
  const { store: legalStore } = props;
  const { legalLorHistoryData, legalLorDetailData } = legalStore;

  const [openProgressDialog, setOpenProgressDialog] = useState(false);

  const handleCloseProgressDialog = () => {
    setOpenProgressDialog(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSearchParams({ year: event.target.value });
    legalStore.setLegalLorHistory(detailId as any, { year: event.target.value || undefined });
    legalStore.setLegalLorDetail(detailId as any, { year: event.target.value || undefined });
    legalStore.setLegalLorProgress(detailId as any, { year: event.target.value || undefined });
  };

  const secondProgress = () => {
    try {
      return legalStore?.legalLorProgressData?.data?.data?.progress[2]?.done;
    } catch (err) {}
    return false;
  };

  useEffect(() => {
    if (!mount) {
      if (legalLorHistoryData?.state === 'initial' && !legalLorHistoryData?.data?.data && detailId) {
        legalStore.setLegalLorHistory(detailId, { year: searhParams.get('year') || undefined });
      }
      setMount(1);
    }
    if (legalLorHistoryData?.state === 'ready') {
      Logger(['[LegalDetail][legalLorHistoryData]', legalLorHistoryData]);
      const arrYear = genYearsPastToPresent(new Date(legalLorDetailData?.data?.data?.effectiveDate as any)).reverse();
      setArrYears(arrYear);
    }
    if (legalLorHistoryData?.state === 'error') {
      Logger(['[LegalDetail][legalLorHistoryData]', legalLorHistoryData], 'error');
    }
  }, [legalLorHistoryData, mount, setMount]);

  // START: Update Process
  const scroll: DialogProps['scroll'] = 'paper';
  const [modalUid, setModalUid] = useState<string>(null as any);
  const toggleModalProcess = (e: any = null, uid: string = '') => {
    if (e) e.preventDefault();
    if (uid) {
      setModalUid(uid);
    } else {
      setModalUid(null as any);
    }
  };
  useEffect(() => {
    if (modalUid) {
      if (legalStore.legalBuRelatedFilter.state === 'initial' && !legalStore.legalBuRelatedFilter?.data?.data) {
        legalStore.setLegalBuRelatedFilter();
      }
      if (legalStore.legalLorDetailData.state === 'ready') {
        Logger(['[CreateUpdateLegalModal][legalLorDetailData]', legalStore.legalLorDetailData]);
      }
    }
  }, [modalUid]);
  // END: Update Process

  // START: Recall Process
  const [recalling, setRecalling] = useState<boolean>(false);
  const [modalRecall, setModalRecall] = useState<boolean>(false);
  const toggleModalRecall = (e: any = null) => {
    if (e) e.preventDefault();
    setModalRecall(!modalRecall);
    setRecalling(false);
  };
  const onRecall = (e: any) => {
    e.preventDefault();
    if (!recalling) {
      setRecalling(true);
      const data = legalStore.legalLorDetailData?.data?.data;
      data.status = 1;
      legalStore.setLegalLorUpdateCreate(data, 'NO_STORE_UPDATE', 'update', (ok) => {
        delay(200).then(() => {
          if (ok) {
            props.store.notify.onStatusChange('updated');
            navigate('/legals');
          } else {
            props.store.notify.onStatusChange('update-error');
            legalStore.setLegalLorDetail(data.id, {});
            toggleModalRecall();
          }
        });
      });
    }
  };
  // END: Recall Process

  // START: Export Data
  const [exportReady, setExportReady] = useState(true);
  const onExportExcel = async (e: any) => {
    e.preventDefault();
    const data = legalLorDetailData?.data?.data;
    if (exportReady && data && data.code) {
      setExportReady(false);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet();
      worksheet.views.push({ showGridLines: false });
      worksheet.properties.defaultRowHeight = 25;

      worksheet.columns = [
        { key: 'col0', width: 16, header: 'LOR Name' },
        { key: 'col1', width: 80, header: data.name },
        { key: 'col2', width: 16, header: '' },
      ];
      worksheet.addRow({ col0: 'LOR Code', col1: data.code });
      excelStyleFilter(worksheet, [1, 2]);

      worksheet.addRow({});
      worksheet.addRow({ col0: 'Summary' });
      excelStyleHeader(worksheet, [4]);
      worksheet.addRow({ col0: data.summary ? data.summary : '' });
      excelStyleBody(worksheet, [5]);

      worksheet.addRow({});
      worksheet.addRow({ col0: 'Required actions' });
      excelStyleHeader(worksheet, [7]);
      worksheet.addRow({ col0: data.actions ? data.actions : '' });
      excelStyleBody(worksheet, [8]);

      let rowId = 8;
      if (data.evaluation) {
        rowId += 1;
        worksheet.addRow({});
        rowId += 1;
        worksheet.addRow({ col0: 'Review legislation' });
        excelStyleHeader(worksheet, [rowId]);
        rowId += 1;
        worksheet.addRow({ col0: 'No.', col1: 'Action party', col2: 'Due date' });
        excelTableHeader(worksheet, rowId);
        if (data.evaluation.actionItems) {
          data.evaluation.actionItems.forEach((d, i) => {
            rowId += 1;
            worksheet.addRow({ col0: `${i + 1}`, col1: d.action, col2: printDate(d.date) });
            excelTableBody(worksheet, rowId);
          });
        }
        rowId += 1;
        worksheet.addRow({
          col0: 'Team members',
          col1:
            data.evaluation.teamMembers && data.evaluation.teamMembers.length
              ? data.evaluation.teamMembers.map((d) => `${d.firstName} ${d.lastName} - ${d.position}`).join(', ')
              : '-',
        });
        worksheet.mergeCells(rowId, 2, rowId, 3);
        excelTableBody(worksheet, rowId, 2);
        worksheet.getCell(`A${rowId}`).font = { bold: true };

        worksheet.addRow({});
        worksheet.addRow({ col0: 'Report' });
        excelStyleHeader(worksheet, [rowId + 2]);
        worksheet.addRow({ col0: data.evaluation.report ? data.evaluation.report : '' });
        excelStyleBody(worksheet, [rowId + 3]);
      }

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${data.code} Evaluation ${year}.xlsx`);

      setExportReady(true);
    }
  };
  // END: Export Data

  return (
    <>
      <Box
        className="w-full pt-6 px-0 bg-white rounded-3xl bg-gradient-to-t from-blue-100 to-white "
        sx={{
          boxShadow: '0px 3px 10px #AAAAAA29',
        }}
      >
        <Box className="px-6">
          <img src={require('@/assets/icons/law4x.png')} alt="Occupational" width={108} className=" absolute right-6 top-3" />
          <Typography variant="h2" color="primary">
            History
          </Typography>
          <Box className="mt-4">
            <Select className="bg-white w-[164px]" labelId="selectType" id="selectType" value={year} onChange={handleChange} input={<OutlinedInput />}>
              {(arrYears || []).map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box className="mt-7 p-4 border-0 border-t border-solid border-gray-200">
          {legalLorDetailData.data && legalLorDetailData.data.data && (
            <div className="px-2">
              <Typography variant="h6" color="primary">
                Updated by :{' '}
                <span style={{ color: '#000000', fontWeight: 300 }}>
                  {legalLorDetailData.data.data?.lastUpdatedBy ? (
                    <>
                      {legalLorDetailData.data.data.lastUpdatedBy?.firstName} {legalLorDetailData.data.data.lastUpdatedBy?.lastName}
                    </>
                  ) : (
                    <>
                      {legalLorDetailData.data.data.lastCreatedBy?.firstName} {legalLorDetailData.data.data.lastCreatedBy?.lastName}
                    </>
                  )}
                </span>
              </Typography>
              <Typography variant="h6" color="primary" className="mt-1">
                Updated date :{' '}
                <span style={{ color: '#000000', fontWeight: 300 }}>
                  {printDate(
                    legalLorDetailData.data.data?.updated ? legalLorDetailData.data.data?.updated : legalLorDetailData.data.data?.created,
                    'DD MMM YYYY HH:mm:ss'
                  )}
                </span>
              </Typography>
            </div>
          )}
          {(legalLorDetailData.data?.data?.file || secondProgress()) && <div className="pt-2"></div>}
          {legalLorDetailData.data?.data?.file && (
            <div className="mt-1 pl-2">
              <Button
                download
                href={exportLinkV2(`${legalLorHistoryData?.data?.data?.lorUrl}`)}
                variant="text"
                startIcon={<Icon icon="filePdf" color="#3155FF" viewBox="0 0 384 512" size={20} />}
              >
                <Typography className="underline font-medium">Download LOR</Typography>
              </Button>
            </div>
          )}
          {secondProgress() && (
            <div className="mt-1 pl-2">
              {/* <Button
                download
                href={exportLinkV2(`${legalLorHistoryData?.data?.data?.evalUrl}`)}
                variant="text"
                startIcon={<Icon icon="filePdf" color="#3155FF" viewBox="0 0 384 512" size={20} />}
              >
                <Typography className="underline font-medium">Export evaluation</Typography>
              </Button> */}
              <Button
                onClick={onExportExcel}
                disabled={!exportReady}
                variant="text"
                startIcon={<Icon icon="filePdf" color="#3155FF" viewBox="0 0 384 512" size={20} />}
              >
                <Typography className="underline font-medium">Export evaluation</Typography>
              </Button>
            </div>
          )}
          {Number(year) === currentYear && legalLorDetailData.data && legalLorDetailData.data.data && isSuperAdmin(currentUser.userProfile) && (
            <div className="mt-4 pl-2">
              {LegalStatusConst.complete === legalLorDetailData.data.data.status ? (
                <Button variant="contained" onClick={toggleModalRecall}>
                  Recall LOR
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={(e) => {
                    toggleModalProcess(e, legalLorDetailData.data.data.id);
                  }}
                >
                  Update LOR
                </Button>
              )}
            </div>
          )}
        </Box>
      </Box>
      <Modal open={openProgressDialog} onClose={handleCloseProgressDialog} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="left-[50%] top-[50%] absolute w-[374px]" sx={{ transform: 'translate(-50%, -50%)' }}>
          <IconButton aria-label="close" onClick={handleCloseProgressDialog} className=" absolute right-[-18px] top-[1px]">
            <Icon icon="circleXmark" color="#3155FF" size={34} />
          </IconButton>
          <Box className="rounded-3xl rounded-tr-none bg-white">
            <ProgressBarIncident></ProgressBarIncident>
          </Box>
        </Box>
      </Modal>

      <Dialog open={modalUid !== null && modalUid !== ''} onClose={toggleModalProcess} fullWidth={true} maxWidth={'lg'} scroll={scroll}>
        <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
          <Typography className="font-medium text-2xl">Update LOR</Typography>
        </DialogTitle>
        <DialogContent className="p-0 border-0" dividers={scroll === 'paper'} sx={{ overflowX: 'hidden' }}>
          <LegalForm
            relatedBuData={cloneDeep(legalStore.legalBuRelatedFilter?.data?.data || [])}
            editData={legalStore.legalLorDetailData?.data?.data}
            editUid={modalUid}
            {...({ store: { setLegalFileUpload: props.store.setLegalFileUpload, setLegalFileRemove: props.store.setLegalFileRemove } } as any)}
            loadStatus={'ready'}
            mode={'edit'}
            noStatusChange={true}
            onClose={toggleModalProcess}
            onSubmit={(data, submitType, uid) => {
              if (!isSuperAdmin(currentUser.userProfile)) {
                Logger(['[CreateUpdateLegalModal][no access]'], 'warn');
                return;
              } else if (LegalStatusConst.complete === data.status) {
                Logger(['[CreateUpdateLegalModal][cannot update]'], 'warn');
                return;
              }
              data.status = legalStore.legalLorDetailData?.data?.data.status;
              legalStore.setLegalLorUpdateCreate(data, 'NO_STORE_UPDATE', 'update', (ok) => {
                delay(200).then(() => {
                  if (ok) {
                    props.store.notify.onStatusChange('updated');
                    legalStore.setLegalLorDetail(modalUid, {});
                  } else {
                    props.store.notify.onStatusChange('update-error');
                  }
                  toggleModalProcess();
                });
              });
            }}
            formStatus={modalUid !== null && modalUid !== ''}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={modalRecall} onClose={toggleModalRecall} fullWidth={true} maxWidth={'sm'}>
        <Typography variant="h2" className="p-0 mb-6">
          Do you want to recall LOR?
        </Typography>
        <DialogContent className="p-0 mb-6">
          <Typography>LOR status will be rolled back to DRAFT, and you need to redo the process again.</Typography>
        </DialogContent>
        <DialogActions className="p-0 justify-start">
          <Button className="mr-1 w-28" variant="contained" color="primary" onClick={onRecall} disabled={recalling}>
            Recall
          </Button>
          <Button className="w-28" variant="outlined" color="primary" onClick={toggleModalRecall}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
