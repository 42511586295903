import React, { Fragment } from 'react';
import { Box, Divider, LinearProgress, Paper, Typography } from '@mui/material';
import { incidentDamageTypeLabel, printDate } from '@/utils/index';
import { NavLink, useParams } from 'react-router-dom';
import { ProfileStore, PropDefaults } from '@/src/types';
import { DataError } from '../dataError';
interface MyIncidentProps extends PropDefaults {}

export function MyIncident(props: MyIncidentProps & ProfileStore) {
  const { detailId: employeeId } = useParams<{ detailId: string }>();
  const { store: profileStore } = props;

  const loading = profileStore.profileIncidentsData.state !== 'error' && profileStore.profileIncidentsData.state !== 'ready';
  const hasError = profileStore.profileIncidentsData.state === 'error';
  const ready = profileStore.profileIncidentsData.state === 'ready';
  const data = profileStore.profileIncidentsData?.data?.data || [];

  return (
    <Fragment>
      <Paper
        elevation={4}
        className="p-6 px-10 relative h-auto mt-12 min-h-[200px] max-h-[490px]"
        sx={{ background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(235,251,255,1) 100%)' }}
      >
        <img src={require('@/assets/icons/report4x.png')} alt="legal" width={75} height={75} className="absolute top-[-1.25rem]" />
        <Typography className="ml-[100px] text-blue-600 text-2xl font-medium" color={'primary'}>
          Incident report
        </Typography>

        {hasError && <DataError description={`Please reload the page again.`} />}
        {loading && <LinearProgress />}

        {!ready && !data.length && <Divider className="border-0 border-dashed border-b border-b-[#E0E0E0]" />}
        {ready && !data.length ? (
          <Box className={`mt-4 min-h-[150px] max-h-[180px] flex justify-center items-center ${data.length > 2 && 'overflow-auto'}`}>
            <Typography className="text-sm font-medium my-4 ml-2 " color={'#98999B'}>
              You have no incident.
            </Typography>
          </Box>
        ) : (
          <Box className={`mt-4 overflow-y-auto scrollbar scrollbar-offset-5 h-[93%] w-full overflow-x-hidden`} style={{ maxHeight: 300 }}>
            {data.map((data, index) => (
              <Box key={index} className="flex flex-wrap w-full border-0 border-dashed border-b border-b-[#E0E0E0] items-center">
                <img src={require('@/assets/icons/file.png')} height={16} width={12} alt="File" className="" />
                <Typography className="text-sm font-medium my-4 ml-3">{printDate(data.dateOfIncident as any)}</Typography>
                <Typography className="text-sm font-medium my-4 ml-3">
                  {data.damageType.map((n, inx, all) => (inx !== 0 ? `, ${incidentDamageTypeLabel(n)?.label}` : incidentDamageTypeLabel(n)?.label))} &nbsp;|
                </Typography>
                <NavLink
                  to={`/incidents/detail/${data.incidentId}`}
                  state={{
                    from: `/profile/detail/${employeeId}`,
                  }}
                  className="text-sm font-medium my-4 ml-2 text-black"
                >
                  View details
                </NavLink>
              </Box>
            ))}
          </Box>
        )}
      </Paper>
    </Fragment>
  );
}
