import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';
const primary = '#3155FF';
const borderColor = '#E0E0E0';
const danger = '#F14242';
const success = '#00C083';

export default {
  primary: {
    contrastText: white,
    dark: '#2542D3',
    main: primary,
    light: '#526FFF',
    text: 'black',
  },
  error: {
    main: danger,
  },
  success: {
    main: success,
  },
  text: {
    primary: black,
    link: primary,
  },
  background: {
    default: white,
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  border: borderColor,
};
