import React from 'react';
import { Box, FormControl, InputLabel } from '@mui/material';
import { IncidentStore, IncidentFormStoreParts } from '@/src/types';
import { TagsInputIncident } from '@/src/components';

interface PartSixSectionThreeProps {
  formStore: IncidentFormStoreParts;
  incidentStore: IncidentStore;
}

export const PartSixSectionThree = (props: PartSixSectionThreeProps) => {
  const {
    formStore: { formStore },
    incidentStore: { store: incidentStore },
  } = props;

  const causesAssessmentData = formStore.store.formData.incidentCausesAssessment?.data || {};

  const handleChangeCausesAssessmentData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('incidentCausesAssessment', 'updated').setup(value, keyName, subKeyName);
  };

  const handleMultiSelection = (type: 'select' | 'delete', value: any, keyName: string, subKeyName?: string) => {
    if (type === 'select') handleChangeCausesAssessmentData(value.length > 0 ? value : null, keyName, subKeyName);
    if (type === 'delete') {
      let newValueArray = [];
      if (subKeyName && causesAssessmentData[keyName]) {
        newValueArray = causesAssessmentData[keyName][subKeyName].filter((item) => item.value !== value);
      }
      handleChangeCausesAssessmentData(newValueArray.length > 0 ? newValueArray : null, keyName, subKeyName);
    }
  };

  return (
    <>
      <Box className="grid grid-cols-5 gap-6">
        <Box className="col-span-3 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Please select</InputLabel>
            <TagsInputIncident
              selectKeyName="rootCause"
              selectSubKeyName="categories"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.rootCauses || ([] as { value: number; label: string }[])}
              selectedData={causesAssessmentData?.rootCause?.categories || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
};
