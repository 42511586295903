import { PartThreeSectionOne, PartThreeSectionTwo } from '@/src/components';
import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { IncidentFormStoreParts, IncidentStore } from '@/src/types';

interface InjuredPartThreeProps {
  incidentStore: IncidentStore;
  disabledInput: boolean;
}

export const InjuredPartThree = (props: InjuredPartThreeProps & IncidentFormStoreParts) => {
  const {
    formStore,
    incidentStore: { store: incidentStore },
    disabledInput,
  } = props;

  // Logger(['[InjuredPartThree][store][incidentQueryTypesData][data]', incidentStore.incidentQueryTypesData?.data?.data]);
  // Logger(['[InjuredPartThree][formStore][injured]', formStore.store.formData.injured]);

  return (
    <>
      <Card variant="outlined" className=" rounded-2xl p-6">
        <Typography variant="h5" className="mb-6 mt-2 border-0 border-l-8 border-solid border-blue-600 pl-4" sx={{ marginLeft: '-26px;' }}>
          Injury type
        </Typography>
        <Box className="flex">
          <Box className="w-full">
            {/* part 3 section 1 */}
            <PartThreeSectionOne formStore={{ formStore }} incidentStore={{ store: incidentStore }} disabledInput={disabledInput} />

            {/* part 3 section 2 */}
            <PartThreeSectionTwo formStore={{ formStore }} incidentStore={{ store: incidentStore }} disabledInput={disabledInput} />
          </Box>
          <Box className="w-auto pr-5 pl-10 py-1">
            <img src={require('@/assets/icons/body.png')} alt="body" width={122} className="" />
          </Box>
        </Box>
      </Card>
    </>
  );
};
