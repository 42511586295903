/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState, Fragment } from 'react';
import { TableBody, TableCell, TableRow, Box, Table, TableContainer, Typography, Paper, Skeleton } from '@mui/material';
// import { MockrowsData } from '@/src/static';
import { DashboardStore, PropDefaults, TrainingThisYearSchema } from '@/src/types';
import { Logger } from '@/logger/index';
import { getByTrainingCode, printDate, isTheSameDate } from '@/src/utils';
import { DataError } from '../../dataError';

interface Props extends PropDefaults {}

export function DashboardTrainingsThisMonth(props: Props & DashboardStore) {
  const { store: dashboardStore } = props;
  const [mount, setMount] = useState<number>(0);
  const [onData, setData] = useState<TrainingThisYearSchema[]>(null as any);
  const [hasError, setError] = useState<boolean>(false);

  const onApiFetchReady = (ok: boolean, data: TrainingThisYearSchema[]) => {
    if (ok) {
      setData(data);
      setError(false);
      Logger(['[DashboardTrainingsThisYear][setDashboardTrainingThisYear]', data]);
    } else {
      setData(null as any);
      setError(true);
    }
  };

  const callApi = () => {
    dashboardStore.setDashboardTrainingThisYear(onApiFetchReady as any);
  };

  useEffect(() => {
    if (!mount && !onData) {
      callApi();
      setMount(1);
    }
  }, [mount, onData, setMount, hasError]);

  const ready = !!onData && !hasError;

  return (
    <Fragment>
      <Box
        className={`${ready && 'shadow-md'} rounded-2xl border border-solid border-gray-200 bg-white relative h-full flex flex-col`}
        style={{ overflow: 'hidden' }}
      >
        {ready && (
          <Typography variant="h4" className="mb-1 flex top-6 left-6 text-black p-5">
            This year trainings
          </Typography>
        )}
        {hasError && <DataError description={`Please reload the page again.`} />}

        {ready ? (
          <Box className="px-6">
            <TableContainer component={Paper} sx={{ overflowY: 'auto', boxShadow: '0' }} className="scrollbar max-h-[210px]">
              <Table>
                <TableBody>
                  {onData.map((row, index) => (
                    <TableRow key={row.startDate.toString()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="right" className=" w-[5px] px-0">
                        <img src="/icons/calendar-small.svg" alt="calendar icon" width={23} height={16} />
                      </TableCell>
                      <TableCell align="left" className=" whitespace-nowrap min-w-fit font-medium">
                        {isTheSameDate(row.startDate || '', row.endDate || '')
                          ? `${printDate(row.startDate, 'DD MMM')}`
                          : `${printDate(row.startDate, 'DD MMM')} - ${printDate(row.endDate, 'DD MMM')}`}
                      </TableCell>
                      <TableCell align="right" className=" w-[5px] px-1">
                        {row.trainingCode === 'classroom' ? (
                          <div
                            style={{
                              width: '6px',
                              height: '6px',
                              backgroundColor: '#68B7DC',
                              borderRadius: '50%',
                            }}
                          ></div>
                        ) : row.trainingCode === 'online' ? (
                          <div
                            style={{
                              width: '6px',
                              height: '6px',
                              backgroundColor: '#6872DC',
                              borderRadius: '50%',
                            }}
                          ></div>
                        ) : row.trainingCode === 'e-learning' ? (
                          <div
                            style={{
                              width: '6px',
                              height: '6px',
                              backgroundColor: '#A368DC',
                              borderRadius: '50%',
                            }}
                          ></div>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell align="left" className=" whitespace-nowrap min-w-fit pl-1">
                        {getByTrainingCode(row.trainingCode).trainingName}
                      </TableCell>
                      <TableCell align="left">{row.courseName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {!onData.length && <TableDataNotFound col={4} />}
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <>
            {!hasError && (
              <Box className="shadow-md rounded-2xl border-gray-200 bg-white relative h-full flex flex-col justify-center ">
                <Typography variant="h4" className="mb-1 flex top-6 left-6 text-black p-5">
                  This year trainings
                </Typography>
                <Skeleton animation="wave" component={'div'} className="h-full w-full mr-6 transform-none rounded-2xl  border-gray-200 bg-white" />
              </Box>
            )}
          </>
        )}
      </Box>
    </Fragment>
  );
}

interface PropsDataNotFound {
  description?: string;
}

interface PropsTableDataNotFound {
  description?: string;
  col: number;
}

function DataNotFound(props: PropsDataNotFound) {
  const { description } = props;
  return (
    <Box className="flex flex-col w-full items-center py-14">
      <img className="w-15 mb-4" src="/icons/calendar-small.svg" alt="calendar icon" width={45} />
      <Typography variant="body2" className="p-0 text-gray-400 font-medium mb-1">
        No training for this month.
      </Typography>
      {description ? (
        <Typography variant="body2" className="p-0 text-gray-400 whitespace-nowrap">
          {description}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
}

function TableDataNotFound(props: PropsTableDataNotFound) {
  const { col } = props;
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={col} className="border-0">
          <DataNotFound {...(props as any)} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
