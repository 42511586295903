/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-useless-escape */

import { TrainingDetailSchema, EmployeeSchema, HttpTokens, GetCoursesSelect, GetEmployeeBySearch, CoursesSelect, CourseTrainingCode } from '@/src/types';
import React, { Component } from 'react';

import { Alert, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Input, InputLabel, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Logger } from '@/logger/index';
import { stateLeveledValue, getJwtToken, delay } from '@/src/utils';
import { TrainingRequestDto } from '@/dto/index';
import { clone, cloneDeep, identity, pickBy } from 'lodash';

// import { FIELD_INVALID, FIELD_REQUIRED } from '@/static/index';
import { SearchBoxAutocomplete, TagsInput } from '@/src/components';
import { TimePicker } from '@mui/x-date-pickers';
import { filter, switchMap, from, of, catchError, Subject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CourseSelectGet, EmployeeSearchGet } from '@/services/http';

import { SERVER_API_ERROR } from '@/src/static';

/**
 * regex validation
 * https://www.cluemediator.com/regular-expression-examples-in-javascript
 */
type FormStatus = 'open' | 'close' | 'error' | 'submit';

interface StateProps extends TrainingRequestDto {
  [name: string]: any;
  /** count each sub$ call */
  httpIndexes: number;
  courseList?: CoursesSelect[];
  attendeeList?: EmployeeSchema[];
  /** dto FORM status  */
  formStatus: FormStatus;
  serverError: string | undefined;
  onSubmit?: (data) => void;
  /** any error from http requests */
  error?: boolean;
  /** we need this code to delegate of what is optional and what is required */
  trainingCode: CourseTrainingCode;
  loading?: boolean;
  // onClose: (data: any) => void;
}

interface FormStateProps {
  /** Used for update courses only */
  editUid?: string;
  editData?: TrainingDetailSchema;
  loading?: boolean;

  // end
  /**
   * edit can be a draft from which you can publish
   * create can be a publish if you dont draft first!
   * draft can be edit work in the same way
   * */
  mode: 'create' | 'edit' | 'draft' | 'edit-publish';
  formStatus: FormStatus;
  error?: boolean;
  onClose: () => void;
  /** {uid} is optional and only required in mode=edit  */
  onSubmit: (data, submitType: 'submit-publish' | 'submit-draft' | 'submit-edit-publish', uid?: string) => void;
  allRequired?: boolean;
  allRequiredMessage?: string;
  /** if server return error after call */
  serverErrorResponse?: string;
  submitType?: 'submit-publish' | 'submit-draft' | 'submit-edit-publish';
}

interface FormErrors {
  [name: string]: any;
}

export default class TrainingForm extends Component<FormStateProps, {}, any> {
  editDataLoaded = false;
  subs$: Array<any> = [];
  subSelect$: Subject<any> = new Subject();
  subSearch$: Subject<{ q: string }> = new Subject();
  subSubmitAction$: Subject<{ data: TrainingRequestDto; submitType: 'submit-publish' | 'submit-draft' | 'submit-edit-publish' }> = new Subject();
  // courseList: CoursesSelect[] = [] as any;
  // attendeeList: EmployeeSchema[] = [] as any;
  formRef: HTMLFormElement & { submitType: 'submit-publish' | 'submit-draft' | 'submit-edit-publish'; endDateError?: boolean } = {} as any;
  allRequired = false;
  state: StateProps;
  /** how many times was set */
  formIndex = 0;
  lastTimeout: any = undefined;
  /** initial data for the form **/
  formData: TrainingRequestDto = undefined as any;

  /** form errors has the same structure as TrainingRequestDto, but it provides errors on each property */
  formErrors: FormErrors = {} as any;
  constructor(props) {
    super(props);

    // if (this.props.mode === 'edit' && (!this.props.editData || !this.props.editUid)) {
    //   throw new Error('[FormErrors] in edit mode you should provide [editData] and [editUid');
    // }

    if (Object.keys(this.props?.editData || {}).length) {
      // this.formData = this.props.editData as any;
      // const useMockData = true;
      this.formData = new TrainingRequestDto(this.props.editData);
    } else {
      const useMockData = false;
      this.formData = new TrainingRequestDto(null as any, useMockData);
    }

    if (['edit', 'edit-publish'].indexOf(this.props.mode) !== -1) {
      Logger(['[TrainingForm][editUid]', this.props.editUid], 'log');
    }

    this.state = {
      loading: this.props.loading,
      trainingCode: undefined as any,
      error: !!this.props.error,
      httpIndexes: 0,
      serverError: undefined,
      formStatus: this.props.formStatus || 'open',
      courseList: [], // should be array
      attendeeList: [],
      employeeList: [],
      submitType: undefined,

      // any other values we want to add to the state
      ...(this.formData as any),
    };
  }

  componentDidMount() {
    const s1 = this.initSubSearch$
      .pipe(
        filter((n: any) => {
          this.setState({
            error: n.error,
          });
          return !n.error;
        })
      )
      .subscribe((n) => {
        this.setState({
          httpIndexes: this.state.httpIndexes + 1,
          attendeeList: clone(n.data),
        });
      });

    const s2 = this.initSubSelect$
      .pipe(
        filter((n: any) => {
          this.setState({
            error: n.error,
          });
          return !n.error;
        })
      )
      .subscribe((n) => {
        this.setState({
          httpIndexes: this.state.httpIndexes + 1,
          courseList: n.data,
        });
      });
    const s3 = this.onSubmit$.pipe(filter((n: any) => !n.error)).subscribe((n) => {
      if (this.props.mode === 'create') this.props.onSubmit(n.data, this.state.submitType);
      if (['edit', 'edit-publish'].indexOf(this.props.mode) !== -1) this.props.onSubmit(n.data, this.state.submitType, this.props.editUid);
      delay(100).then(() => {
        this.setState({ formStatus: 'submit' });
      });
      Logger([`[TrainingForm][formData][${n.submitType}]`, n.data], 'attention');
    });

    this.subSelect$.next(null);
    this.subs$.push(...[s1, s2, s3]);
  }

  componentDidUpdate(prevProps) {
    if (!this.editDataLoaded) {
      if (Object.keys(this.props?.editData || {}).length) {
        this.formData = new TrainingRequestDto(this.props.editData);
        this.editDataLoaded = true;
        this.setState({
          ...this.formData,
        });
      }
    }
  }

  componentWillUnmount() {
    this.subs$.forEach((sub, inx) => {
      try {
        sub.unsubscribe(sub);
        Logger(['[TrainingForm][unsubscribe][index]', inx], 'debug');
      } catch (err) {}
    });
  }

  get initSubSelect$(): Observable<GetCoursesSelect> {
    const tokens: HttpTokens = { jwt: getJwtToken() };
    return this.subSelect$
      .pipe(debounceTime(300))
      .pipe(
        switchMap((val) => {
          return from(CourseSelectGet('courses/select', tokens));
        })
      )
      .pipe(catchError((error) => of(error))) as Observable<GetCoursesSelect>;
  }

  get initSubSearch$(): Observable<GetEmployeeBySearch> {
    const tokens: HttpTokens = { jwt: getJwtToken() };
    return (
      this.subSearch$
        .pipe(debounceTime(300))
        // must not be empty and gth 1
        .pipe(filter((n) => !!n?.q && n?.q.length > 1))
        .pipe(
          switchMap((val) => {
            return from(EmployeeSearchGet('employees/search', { q: val.q }, tokens));
          })
        )
        .pipe(catchError((error) => of(error))) as Observable<GetEmployeeBySearch>
    );
  }

  get onSubmit$(): Observable<{ data: TrainingRequestDto; submitType: 'submit-publish' | 'submit-draft' | 'submit-edit-publish' }> {
    return this.subSubmitAction$.pipe(debounceTime(300));
  }

  /**
   *
   * @param field  setting of field >.< indicate next level object
   * @param value
   */
  handleChange = (field: string, value: any) => {
    const leveledField = field.split('.');
    const onChange = () => {
      if (leveledField.length === 2) {
        this.setState({
          [leveledField[0]]: {
            ...stateLeveledValue(this.state, leveledField),
            [leveledField[1]]: value,
          },
        });
      } else {
        this.setState({ [leveledField[0]]: value });
      }
    };
    onChange();
    this.formIndex++;
  };

  /**
   *  REVIEW  investigate required fields on edit mode, does not update correctly
      location : online (optional), classroom (requires), e-learning (optional)
      links: online (required), classroom (optional), e-learning (required)
     */
  requiredField(fieldName: 'link' | 'location', trainingCode: CourseTrainingCode): boolean {
    /** if not yet set  is not required*/
    if (!this.state.trainingCode) return false;
    if (fieldName === 'location') {
      // required
      return trainingCode === 'classroom';
    }

    if (fieldName === 'link') {
      // required
      return trainingCode === 'e-learning' || trainingCode === 'online';
    } else return false;
  }

  hasError(required = false): boolean {
    let error = false;
    if (this.formRef?.endDateError) {
      error = true;
    }
    if (required) {
      if (!(this.state.employeeIds || []).length) {
        error = true;
      }
    }
    return error;
  }

  submit(e: React.FormEvent<HTMLFormElement>, isInvalid = false) {
    const stateCopy = cloneDeep(this.state);

    // in edit mode we need id
    if (this.props.editUid) stateCopy.id = this.props.editUid;

    let formData = new TrainingRequestDto(stateCopy as any);
    formData = pickBy(formData, identity) as any;

    // NOTE submitter?.name is not available from <Form onInvalid callback
    let submitType: 'submit-publish' | 'submit-draft' | 'submit-edit-publish' | 'invalid' = (e.nativeEvent as any)?.submitter?.name || this.formRef.submitType;
    const invalid =
      (submitType === 'submit-publish' && isInvalid) ||
      (submitType === 'submit-draft' && !formData.courseId) ||
      (submitType === 'submit-edit-publish' && !formData.courseId);

    if (invalid) submitType = 'invalid';

    if (submitType === 'submit-publish' || submitType === 'submit-edit-publish') {
      if (!(formData.employeeIds || []).length) {
        submitType = 'invalid';
      }
    }

    switch (submitType) {
      case 'submit-draft': {
        // NOTE to be able to send draft data while not all props are valid we have to disable preventDefault not to show warning, then we manually validate for minimum requirement to draft submit
        e.preventDefault();

        formData.status = 1;
        this.subSubmitAction$.next({ data: formData, submitType });
        return false;
      }
      case 'submit-publish': {
        e.preventDefault();

        formData.status = 2;
        this.subSubmitAction$.next({ data: formData, submitType });
        return false;
      }
      case 'submit-edit-publish': {
        e.preventDefault();

        formData.status = 2;
        this.subSubmitAction$.next({ data: formData, submitType });
        return false;
      }
      case 'invalid': {
        e.preventDefault();

        Logger(['[TrainingForm][onSubmitValidate][invalid]', formData], 'error');
        return false;
      }
      default:
        e.preventDefault();

        Logger(['[TrainingForm][onSubmitValidate]', 'no match'], 'warn');
    }

    return false;
  }

  formOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, lastSearch?: string) => {
    if (e.key === 'Enter') {
      // const params = getParamsFilter(0, rowsPerPage, companyValue, nameSearching);
      // store.setEmployees(params);
      Logger(['[TrainingForm][formOnKeyPress]', e]);
    }
  };

  resetStates() {
    this.setState({});
  }

  render() {
    if (this.state.error || this.props.error) {
      return <Alert severity="error">{SERVER_API_ERROR}</Alert>;
    }

    if (['edit', 'edit-publish'].indexOf(this.props.mode) !== -1) {
      if (this.props.loading || !this.state.httpIndexes || !this.state.courseId) {
        return <CircularProgress className="mt-5" />;
      }
    }

    if (this.props.serverErrorResponse && this.state.formStatus !== 'error') {
      this.setState({ formStatus: 'error' });
    }

    // NOTE we need to valid for data to become available in edit mode before display the form
    // to avoid issues like :
    // A component is changing the uncontrolled value state of ...
    // AutoSelect requires defaultValue to be available before loading the component

    // if (this.props.mode === 'edit') {
    //   if (!this.state.courseId || !this.state.httpIndexes) {
    //     return <CircularProgress />;
    //   }
    // }

    return (
      <form
        ref={(ref) => (this.formRef = ref as any)}
        onSubmit={(e) => {
          this.submit(e);
        }}
        onInvalid={(e) => {
          this.submit(e, true);
        }}
      >
        <Box className="w-full flex">
          <Box className="w-1/2 border-0 border-r border-dashed border-gray-300 pr-8 pt-7">
            <FormControl className="mb-6 w-full" required>
              <InputLabel shrink>Select course</InputLabel>
              {this.state.courseList?.length ? (
                <SearchBoxAutocomplete
                  error={this.state.formStatus === 'error'}
                  mode={this.props.mode as any}
                  selected={this.state.courseId}
                  data={this.state.courseList || []}
                  format="ADD-TRAINING"
                  handleKeyPress={this.formOnKeyPress}
                  onSelectedOption={(item: CoursesSelect) => {
                    Logger(['[TrainingForm][selected][courseId]', item]);

                    this.setState({ trainingCode: item.trainingCode });
                    this.handleChange('courseId', item.id);
                  }}
                  setNameSearching={(inputValue?: any, type?: 'input' | 'change' | 'reset') => {
                    // NOTE  value fro courseNo/CoursesSelect/selection has been cleared
                    if (['input', 'reset'].indexOf(type as any) !== -1 && !inputValue) {
                      this.handleChange('courseId', null);
                    }
                  }}
                  handleKeyFilter={(val) => {
                    Logger(['[TrainingForm][handleKeyFilter]', val]);
                  }}
                  placeholder="Enter keyword or select from dropdown"
                />
              ) : ['edit', 'edit-publish'].indexOf(this.props.mode) !== -1 ? (
                <CircularProgress />
              ) : null}
              <Input sx={{ display: 'none' }} inputProps={{ inputMode: 'text' }} type="hidden" value={this.state.courseNo} name="courseNo" required={true} />
            </FormControl>
            <FormControl className="mb-6 w-full" required>
              <TextField
                inputProps={{ inputMode: 'url' }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Online link"
                placeholder="Enter Microsoft team meeting link or other online link"
                value={this.state.onlineLink}
                onChange={(e) => {
                  // TODO  add later
                  // const rgx = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
                  // rgx.test(e.target.value);
                  this.handleChange('onlineLink', e.target.value);
                }}
                required={this.requiredField('link', this.state.trainingCode)}
              />
            </FormControl>
            <FormControl className="mb-6 w-full" required>
              <TextField
                required={this.requiredField('location', this.state.trainingCode)}
                value={this.state.location}
                onChange={(e) => this.handleChange('location', e.target.value)}
                InputLabelProps={{ shrink: true }}
                label="Location"
                placeholder="Enter a location name for hybrid meeting"
              />
            </FormControl>
            <Box className="flex">
              <FormControl className="mb-6 w-1/2  mr-3" required>
                <InputLabel shrink>Start</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableMaskedInput={true}
                    views={['year', 'month', 'day']}
                    inputFormat="dd MMM yyyy"
                    value={this.state.startDate}
                    onChange={(newValue) => {
                      this.handleChange('startDate', newValue);
                      Logger(['[DatePicker][startDate][day]', newValue], 'log');
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Select date',
                        }}
                        InputLabelProps={{ shrink: true }}
                        className="calenderIcon"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl className="mb-6 w-1/2 no-label">
                {/* <TextField InputLabelProps={{ shrink: true }} label="Effective date" /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="Time "
                    ampm={false}
                    value={this.state.startDate}
                    onChange={(newValue) => {
                      this.handleChange('startDate', newValue);
                      // Logger(['[DatePicker][startDate][Time]', newValue], 'log');
                      // Logger(['[DatePicker][startDate][Time][moment]', printDate(newValue as any, 'hh:mm')], 'log');
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Time',
                        }}
                        InputLabelProps={{ shrink: true }}
                        className="timeIcon"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <Box className="flex">
              <FormControl className="mb-6 w-1/2  mr-3" required error={this.formRef?.endDateError === true}>
                <InputLabel shrink>End</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    // ref={(r) => {
                    //   if (!this.formRef) this.formRef = {} as any;
                    //   this.formRef.endDate = r as any;
                    // }}
                    disableMaskedInput={true}
                    views={['year', 'month', 'day']}
                    inputFormat="dd MMM yyyy"
                    value={this.state.endDate}
                    onChange={(newValue) => {
                      this.formRef.endDateError = false;
                      if (this.state.startDate) {
                        if (new Date(this.state.startDate).getTime() > new Date(newValue as any).getTime()) {
                          this.formRef.endDateError = true;
                        }
                      }
                      this.handleChange('endDate', newValue);
                      // Logger(['[DatePicker][endDate][day]', newValue], 'log');
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={this.formRef?.endDateError === true}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Select date',
                        }}
                        InputLabelProps={{ shrink: true }}
                        className="calenderIcon"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl className="mb-6 w-1/2 no-label">
                {/* <TextField InputLabelProps={{ shrink: true }} label="Effective date" /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    ampm={false}
                    label="Time "
                    value={this.state.endDate}
                    onChange={(newValue) => {
                      this.handleChange('endDate', newValue);
                      // Logger(['[DatePicker][endDate][Time]', newValue], 'log');
                      // Logger(['[DatePicker][startDate][Time][moment]', printDate(newValue as any, 'hh:mm')], 'log');
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Time',
                        }}
                        InputLabelProps={{ shrink: true }}
                        className="timeIcon"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <FormControl className="mb-6 w-full">
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Evaluation of training link"
                placeholder="Enter evaluation form link for this training"
                required
                value={this.state.evaluationLink}
                onChange={(e) => this.handleChange('evaluationLink', e.target.value)}
              />
            </FormControl>
          </Box>
          <Box className="w-1/2 pl-8 pt-7">
            <Box className="flex">
              <FormControl className="mb-6 w-1/2 mr-3">
                <InputLabel shrink>Certificate expiration date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableMaskedInput={true}
                    views={['year', 'month', 'day']}
                    inputFormat="dd MMM yyyy"
                    value={this.state.certExpirationDate ? this.state.certExpirationDate : null}
                    onChange={(newValue) => {
                      this.handleChange('certExpirationDate', newValue);
                      Logger(['[DatePicker][certExpirationDate][day]', newValue], 'log');
                    }}
                    renderInput={(params) => (
                      <TextField
                        required={false}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Select date',
                        }}
                        InputLabelProps={{ shrink: true }}
                        className="calenderIcon"
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <FormControl className="mb-8 w-full" required>
              {/**
               ATTENTION for reference only
               **/}
              {/* <TagsInputAlpha />
              <br />
              <br />
              <br /> */}
              <TagsInput
                error={this.state.formStatus === 'error'}
                mode={this.props.mode as any}
                action={this.state.submitType}
                selectedAttendees={this.state.employeeList || []}
                /** employee data to search from */
                data={this.state.attendeeList as any}
                handleReset={(selected: EmployeeSchema[]) => {
                  this.setState({ attendeeList: selected });
                }}
                handleOnSelect={(employees: EmployeeSchema[]) => {
                  // NOTE in mode='edit' preselected attendeeList[] is rendered down to employeeIds /list only
                  // we need attendeeList[] detail to display in dropdown list
                  // works as a delete also!
                  this.handleChange(
                    'employeeIds',
                    employees.map((n) => n.employeeId)
                  );
                }}
                // handleDelete={(e: React.KeyboardEvent<HTMLDivElement>) => {
                //   Logger(['[TrainingForm][handleDelete]', e], 'log');
                // }}
                handleKeyPress={(val: any, lastSearch?: string) => {
                  // subSearch$
                  //   Logger(['[TrainingForm][handleKeyPress]', val, lastSearch], 'log');
                }}
                handleKeyFilter={(q) => {
                  this.subSearch$.next({ q });
                  //   Logger(['[TrainingForm][handleKeyFilter]', q], 'log');
                }}
              />
              <Input sx={{ display: 'none' }} type="hidden" value={this.state.courseList} name="courseList" required={true} />
            </FormControl>
          </Box>
        </Box>

        <Box
          className="px-8 pt-7 pb-0 border-0 border-t border-solid border-gray-300 w-full max-w-full"
          sx={{ margin: '0 -30px', display: 'flex', alignItems: 'flex-start' }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {this.props.mode !== 'edit-publish' && (
              <Button
                disabled={this.state.formStatus === 'submit' || this.hasError(false)}
                onClick={(e) => {
                  // eslint-disable-next-line dot-notation
                  this.formRef['submitType'] = 'submit-draft';
                  this.setState({
                    submitType: 'submit-draft',
                  });
                }}
                name="submit-draft"
                type="submit"
                className="w-28 mr-3"
                variant="contained"
              >
                Save draft
              </Button>
            )}

            <Button
              type="button"
              onClick={() => {
                this.props.onClose();
              }}
              className="w-28"
              variant="outlined"
            >
              Close
            </Button>
          </Box>
          <Box>
            <FormControlLabel
              className="ml-auto"
              control={<Checkbox checked={!!this.state.sendEmail} onChange={(e) => this.handleChange('sendEmail', !this.state.sendEmail)} />}
              label="Send emails to attendees"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
            />
            {this.props.mode !== 'draft' && (
              <Button
                disabled={this.state.formStatus === 'submit' || this.hasError(true)}
                onClick={(e) => {
                  if (this.props.mode === 'edit-publish') {
                    // eslint-disable-next-line dot-notation
                    this.formRef['submitType'] = 'submit-edit-publish';
                    this.setState({
                      submitType: 'submit-edit-publish',
                    });
                  } else {
                    // eslint-disable-next-line dot-notation
                    this.formRef['submitType'] = 'submit-publish';
                    this.setState({
                      submitType: 'submit-publish',
                    });
                  }
                }}
                type="submit"
                name={this.props.mode === 'edit-publish' ? 'submit-edit-publish' : 'submit-publish'}
                className="w-28 ml-4 bg-emerald-500 hover:bg-emerald-600"
                variant="contained"
              >
                Publish
              </Button>
            )}
          </Box>
        </Box>
        {this.props.serverErrorResponse && this.state.formStatus !== 'submit' && <Alert severity="error">{this.props.serverErrorResponse}</Alert>}
      </form>
    );
  }
}
