import { createContext } from 'react';
import { AttendeeStore } from '@/types/store/index';
import { AttendeeUpdateBodyData, QueryAttendeeList, QueryAttendeeSearch, AttendeeUploadBodyData } from '@/src/types';
const initialDataWrapper = {
  error: undefined,
  data: undefined,
  state: 'initial',
};

export const AttendeeContext: React.Context<AttendeeStore> = createContext({
  store: {
    resetStatus: (apiName: string) => {},
    setAttendeeList: (trainingId: string, params: QueryAttendeeList) => {},
    attendeeListData: {
      ...initialDataWrapper,
    },
    setAttendeeSearch: (trainingId: string, params: QueryAttendeeSearch) => {},
    attendeeSearchData: {
      ...initialDataWrapper,
    },
    setAttendeeUpdate: (id: string, body: AttendeeUpdateBodyData) => {},
    attendeeUpdateData: {
      ...initialDataWrapper,
    },
    setAttendeeUpload: (id: string, body: AttendeeUploadBodyData) => {},
    attendeeUploadData: {
      ...initialDataWrapper,
    },
    setAttendeeDelete: (id: string) => {},
    attendeeDeleteData: {
      ...initialDataWrapper,
    },
  },
} as any);
