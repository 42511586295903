import React, { useEffect, useState } from 'react';
import { PropDefaults } from '@/types/common';
import { useGlobalContext, withDashboardContext } from '@/services/store';
import { AzureAuthProviderProps, DashboardStore } from '@/src/types';
import { Logger } from '@/logger/index';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { DASHBOARD_PAGE_TITLE } from '@/src/static';
import { NavLink } from 'react-router-dom';
import { DashboardIncidentChart, DashboardLegalChart, DashboardTrainingChart, DashboardTrainingsThisMonth } from '@/src/components';
import { isAdmin } from '@/src/utils';

interface HomeProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

const PAGE_TITLE = 'Welcome to SSHE Intelligence Platform';
const Home: React.FC<HomeProps & DashboardStore> = (props) => {
  setDocTitle(DASHBOARD_PAGE_TITLE);
  const [mount, setMount] = useState(0);
  const { store } = props;
  const { currentUser } = useGlobalContext();

  // NOTE use this example as base for creating other store/action http calls
  useEffect(() => {
    if (!mount) {
      setMount(1);
      // call from store on initial state to get data from http request
      // if (store.example.state === 'initial') store.setExample();
    }
  }, [mount, setMount]);

  Logger(['[home][store]', store], 'debug');

  return (
    <Paper elevation={0}>
      <Typography variant="h1" className="mb-10">
        {PAGE_TITLE}
      </Typography>
      <Box className="flex flex-wrap">
        <NavLink to="/trainings" className="no-underline mb-8 mr-8">
          <Box className="bg-[#009AF6] w-[262px] h-[220px] rounded-tr-2xl rounded-bl-2xl">
            <Box className="flex " style={{ justifyContent: 'space-between' }}>
              <Typography className=" text-white mt-5 ml-5  text-xl  ">Trainings</Typography>
              <img src={require('@/assets/icons/right-arrow.png')} alt="legal" className=" mt-5 mr-5 " width={23} height={26} />
            </Box>

            <img src={require('@/assets/icons/mentor4x.png')} alt="legal" width={136} height={136} className=" mt-12 ml-[-14px]" />
          </Box>
        </NavLink>
        <NavLink to="/incidents" className="no-underline mb-8 mr-8">
          <Box className="bg-[#00CFED] w-[262px] h-[220px] rounded-tr-2xl rounded-bl-2xl">
            <Box className="flex" style={{ justifyContent: 'space-between' }}>
              <Typography className="  text-white mt-5 ml-5 text-xl">Incident report</Typography>
              <img src={require('@/assets/icons/right-arrow.png')} alt="legal" className=" mt-5  mr-5" width={23} height={26} />
            </Box>
            <img src={require('@/assets/icons/report4x.png')} alt="legal" width={136} height={136} className=" mt-10 ml-[-10px]" />
          </Box>
        </NavLink>
        <NavLink to="/legals" className="no-underline mb-8 mr-8">
          <Box className="bg-[#00B282] w-[262px] h-[220px] rounded-tr-2xl rounded-bl-2xl">
            <Box className="flex" style={{ justifyContent: 'space-between' }}>
              <Typography className="  text-white mt-5 ml-5 text-xl">Legals</Typography>
              <img src={require('@/assets/icons/right-arrow.png')} alt="legal" className=" mt-5 mr-5 " width={23} height={26} />
            </Box>
            <img src={require('@/assets/icons/law4x.png')} alt="legal" width={136} height={136} className=" mt-10" />
          </Box>
        </NavLink>
        {isAdmin(currentUser.userProfile) && (
          <NavLink to="/admins" className="no-underline mb-8 mr-8">
            <Box className="bg-[#0098B5] w-[262px] h-[220px] rounded-tr-2xl rounded-bl-2xl">
              <Box className="flex" style={{ justifyContent: 'space-between' }}>
                <Typography className="  text-white mt-5 ml-5 text-xl">Manage roles</Typography>
                <img src={require('@/assets/icons/right-arrow.png')} alt="legal" className=" mt-5 mr-5 " width={23} height={26} />
              </Box>
              <img src={require('@/assets/icons/role4x.png')} alt="legal" width={136} height={136} className=" mt-12" />
            </Box>
          </NavLink>
        )}
      </Box>

      <Grid container spacing={2} className="mt-5">
        <Grid item xs={6} xl={6} md={6} lg={6} style={{ height: '323px' }}>
          <DashboardTrainingChart store={props.store} />
        </Grid>
        <Grid item xs={6} xl={6} md={6} lg={6} style={{ height: '323px' }}>
          <DashboardTrainingsThisMonth store={props.store} />
        </Grid>
        <Grid item xs={6} xl={6} md={6} lg={6} style={{ height: '323px' }}>
          <DashboardIncidentChart store={props.store} />
        </Grid>
        <Grid item xs={6} xl={6} md={6} lg={6} style={{ height: '323px' }}>
          <DashboardLegalChart store={props.store} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withDashboardContext(Home);
