import { PostLegalCreateUpdateLor, BodyLegalCreateUpdateLor, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description create/update LOR, for draft or published status, we use the same api for both
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const LegalLORCreateUpdatePost = (
  /** create can also refer to update for draft */
  prefix: string = 'legal/lor/create-update',
  tokens: HttpTokens,
  body: BodyLegalCreateUpdateLor,
  hardRejection = true
): Promise<PostLegalCreateUpdateLor> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  console.log('[legal/lor/create-update][@body/json]', JSON.stringify(body));

  return instance.client
    .post(`${prefix}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostLegalCreateUpdateLor>) => {
      // /return Promise.reject(new Error('ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostLegalCreateUpdateLor>;
};
