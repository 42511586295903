import { AzureAuthProviderProps, DashboardStore, NotificationStatus, StoreStateDefaultV3 } from '@/types/store';
import { useContext, useEffect, useState } from 'react';
import { IncidentDashboardChartsGet, LegalChartsGet, TrainingChartsGet, TrainingThisMonthGet } from '@/services/http';
import { DashboardContext } from './context';
import { getJwtToken } from '@/src/utils';
import {
  GetIncidentsDashboardCharts,
  GetLegalCharts,
  GetTrainingChart,
  GetTrainingsThisMonth,
  HttpTokens,
  LegalChartSchema,
  LegalChartsQuery,
  QueryIncidentsDashboardCharts,
  QueryTrainingChart,
  IncidentDashboardChartSchema,
  TrainingChartSchema,
  TrainingThisMonthSchema,
  GetTrainingsThisYear,
  TrainingThisYearSchema,
} from '@/src/types';
import { Logger } from '@/logger/index';

/**
 * to give us access to azure for admin
 */
interface ProviderProps {
  children: React.ReactNode;
  context: {
    [name: string]: any;
    azure: AzureAuthProviderProps;
  };
}
export const useDashboardInitialState = () => useContext(DashboardContext);
const DashboardContextProvider = (props: ProviderProps) => {
  const { children } = props;

  /** Access to azure  */
  // const { azure } = context;
  const contextInitialState = useDashboardInitialState();
  const tokens: HttpTokens = { jwt: getJwtToken() };
  const [notificationStatus, setNotificationStatus] = useState<NotificationStatus>(contextInitialState.store.notify.notificationStatus);
  const [dashboardIncidentsChartData, setDashboardIncidentsChart] = useState<StoreStateDefaultV3<GetIncidentsDashboardCharts>>(
    contextInitialState.store.dashboardIncidentsChartData
  );

  const [dashboardTrainingThisMonthData, setDashboardTrainingThisMonth] = useState<StoreStateDefaultV3<GetTrainingsThisMonth>>(
    contextInitialState.store.dashboardTrainingThisMonthData
  );
  const [dashboardTrainingThisYearData, setDashboardTrainingThisYear] = useState<StoreStateDefaultV3<GetTrainingsThisYear>>(
    contextInitialState.store.dashboardTrainingThisYearData
  );
  const [dashboardTrainingsChartData, setDashboardTrainingsChart] = useState<StoreStateDefaultV3<GetTrainingChart>>(
    contextInitialState.store.dashboardTrainingsChartData
  );
  const [dashboardLegalChartsData, setDashboardLegalCharts] = useState<StoreStateDefaultV3<GetLegalCharts>>(contextInitialState.store.dashboardLegalChartsData);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const homeStore: DashboardStore = {
    store: {
      notify: {
        notificationStatus,
        /**
         * call to action when status has changed
         */
        onStatusChange: (type: NotificationStatus, message?: string) => {
          if (!isMounted) return;
          setNotificationStatus(type);
          if (message) {
            Logger([`[notify][onStatusChange][${type}]`, message]);
          }
        },
      },
      dashboardIncidentsChartData,
      dashboardTrainingThisMonthData,
      dashboardTrainingThisYearData,
      dashboardTrainingsChartData,
      dashboardLegalChartsData,
      resetStatus: (apiName: string) => {
        if (apiName === 'incidents/dashboard/charts') {
          setDashboardIncidentsChart({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'trainings/this-month') {
          setDashboardTrainingThisMonth({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'trainings/charts') {
          setDashboardTrainingsChart({ data: undefined as any, state: 'initial' });
        }
      },
      setDashboardIncidentsChart: (query?: QueryIncidentsDashboardCharts, done?: (ok: boolean, data?: IncidentDashboardChartSchema) => void) => {
        if (!isMounted) return;
        if (!done) setDashboardIncidentsChart({ data: undefined as any, state: 'loading' });

        IncidentDashboardChartsGet('incidents/dashboard/charts', query || {}, tokens)
          .then((data) => {
            if (!isMounted) return;
            if (done) {
              done(true, data.data);
              return;
            }
            setDashboardIncidentsChart({ data: data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            if (done) {
              done(false, null as any);
              return;
            }
            setDashboardIncidentsChart({ data: undefined as any, error, state: 'error' });
          });
      },
      setDashboardTrainingThisMonth: (done?: (ok: boolean, data?: TrainingThisMonthSchema[]) => void) => {
        if (!isMounted) return;
        if (!done) setDashboardTrainingThisMonth({ data: undefined as any, state: 'loading' });

        TrainingThisMonthGet('training/this-month', tokens)
          .then((data) => {
            if (!isMounted) return;
            if (done) {
              done(true, data.data);
              return;
            }
            setDashboardTrainingThisMonth({ data: data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            if (done) {
              done(false, null as any);
              return;
            }
            setDashboardTrainingThisMonth({ data: undefined as any, error, state: 'error' });
          });
      },
      setDashboardTrainingThisYear: (done?: (ok: boolean, data?: TrainingThisYearSchema[]) => void) => {
        if (!isMounted) return;
        if (!done) setDashboardTrainingThisYear({ data: undefined as any, state: 'loading' });

        TrainingThisMonthGet('training/this-year', tokens)
          .then((data) => {
            if (!isMounted) return;
            if (done) {
              done(true, data.data);
              return;
            }
            setDashboardTrainingThisYear({ data: data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            if (done) {
              done(false, null as any);
              return;
            }
            setDashboardTrainingThisYear({ data: undefined as any, error, state: 'error' });
          });
      },
      setDashboardTrainingsChart: (query?: QueryTrainingChart, done?: (ok: boolean, data?: TrainingChartSchema) => void) => {
        if (!isMounted) return;

        setDashboardTrainingsChart({ data: undefined as any, state: 'loading' });

        TrainingChartsGet('trainings/charts', query || {}, tokens)
          .then((data) => {
            if (!isMounted) return;
            if (done) {
              done(true, data.data);
              return;
            }
            setDashboardTrainingsChart({ data: data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            if (done) {
              done(false, null as any);
              return;
            }
            setDashboardTrainingsChart({ data: undefined as any, error, state: 'error' });
          });
      },
      setDashboardLegalCharts: (query?: LegalChartsQuery, action?: 'update' | 'new', done?: (ok: boolean, data?: LegalChartSchema) => void) => {
        if (!isMounted) return;
        if ((!action || action === 'new') && !done) setDashboardLegalCharts({ data: undefined as any, state: 'loading' });

        LegalChartsGet('legal/charts', query || {}, tokens)
          .then((data) => {
            if (!isMounted) return;
            if (done) {
              done(true, data.data);
              return;
            }
            setDashboardLegalCharts({ data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;
            if (done) {
              done(false, null as any);
              return;
            }
            setDashboardLegalCharts({ data: undefined as any, error, state: 'error' });
          });
      },
    },
  };

  return <DashboardContext.Provider value={homeStore}>{children}</DashboardContext.Provider>;
};

const withDashboardContext = (Component: any) => {
  return function DashboardComponent(props: any) {
    return <DashboardContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</DashboardContext.Consumer>;
  };
};

export { DashboardContextProvider, withDashboardContext };
