import { createContext } from 'react';
import {
  FormItemProp,
  // FormItemSetup,
  FormStoreActionState,
  FromStoreCtaState,
  IncidentFormPartStoreState,
  // IncidentFormStore,
  IncidentFormStoreParts,
  IncidentFormStoreState,
} from '@/types/store';
import {
  IncidentSchemaParts,
  IncidentFormPartNames,
  IncidentFormDataType,
  // CommonObject,
  // IncidentInjuredPartial,
  // IncidentPropertyDamagePartial,
  // IncidentInitialReportPartial,
  // IncidentDescriptionPartial,
  // IncidentInvestigationTeamPartial,
  // IncidentCorrectiveActionPartial,
  // IncidentCausesAssessmentPartial,
} from '@/src/types';
import // mockPart1Data,
// mockPart2Data,
// mockPart3Data,
// mockPart4Data,
// mockPart6Data,
// mockPart7Data,
// mockPart8Data,
'@/src/static/incidents/store/mockupData';

const initialFormData: IncidentFormDataType<any> = {
  data: null,
  state: 'pristine',
};
// // ----- PART 1 -----
// const initialFormDataInitialReport: IncidentFormDataType<IncidentInitialReportPartial> = {
//   state: 'updated',
//   // data: { ...mockPart1Data },

//   // NOTE: for test required fields before save draft
//   data: {
//     name: 'new_test_edit_',
//     damageType: [1, 2, 3],
//     dateOfIncident: new Date(),
//     company: { buCode: 'VRN', buName: 'Varuna' },
//   } as IncidentInitialReportPartial,
// };
// // ----- PART 2 -----
// const initialFormDataDescription: IncidentFormDataType<IncidentDescriptionPartial> = {
//   state: 'updated',
//   data: { ...mockPart2Data },
// };
// // ----- PART 3 -----
// const initialFormDataInjured: IncidentFormDataType<IncidentInjuredPartial> = {
//   state: 'updated',
//   // NOTE: for test checked noDataSkip
//   // data: { noDataSkip: true } as IncidentInjuredPartial,

//   // NOTE: for test all input already filled, and data come from save draft
//   data: { ...mockPart3Data },
// };
// // ----- PART 4 -----
// const initialFormDataPropertyDamage: IncidentFormDataType<IncidentPropertyDamagePartial> = {
//   state: 'updated',
//   // NOTE: for test checked noDataSkip
//   // data: { noDataSkip: true } as IncidentPropertyDamagePartial,

//   // NOTE: for test all input already filled, and data come from save draft
//   data: { ...mockPart4Data },
// };
// // ----- PART 5 -----
// const initialFormDataInvestigation: IncidentFormDataType<any> = {
//   data: {
//     htmlText: '<p>initialFormDataPart5</p>',
//   },
//   state: 'pristine',
// };
// // ----- PART 6 -----
// const initialFormDataCausesAssessment: IncidentFormDataType<IncidentCausesAssessmentPartial> = {
//   state: 'updated',
//   data: { ...mockPart6Data },
// };
// // ----- PART 7 -----
// const incidentInvestigationTeam: IncidentFormDataType<IncidentInvestigationTeamPartial> = {
//   state: 'updated',
//   data: { ...mockPart7Data },
// };
// // ----- PART 8 -----
// const correctiveAction: IncidentFormDataType<IncidentCorrectiveActionPartial> = {
//   state: 'updated',
//   data: { ...mockPart8Data },
// };
// console.log({
//   initialFormDataInitialReport,
//   initialFormDataDescription,
//   initialFormDataInjured,
//   initialFormDataPropertyDamage,
//   initialFormDataInvestigation,
//   initialFormDataCausesAssessment,
//   incidentInvestigationTeam,
//   correctiveAction,
// });

function formItemSetup(value: any, keyName: string, subKeyName?: string) {}

export const IncidentFormContext: React.Context<IncidentFormStoreParts> = createContext({
  formStore: {
    actionState: 'initial',
    ctaState: 'initial',
    store: {
      state: 'pristine',
      formData: {
        /** this is NOT a form part */
        reviewers: { ...initialFormData },
        /** one */
        initialReport: { ...initialFormData },
        // initialReport: { ...initialFormDataInitialReport },

        /** two */
        description: { ...initialFormData },
        // description: { ...initialFormDataDescription },

        /** three */
        injured: { ...initialFormData },
        // injured: { ...initialFormDataInjured },

        /** four */
        propertyDamage: { ...initialFormData },
        // propertyDamage: { ...initialFormDataPropertyDamage },

        /** five */
        incidentInvestigation: { ...initialFormData },
        // incidentInvestigation: { ...initialFormDataInvestigation },
        /** six */
        incidentCausesAssessment: { ...initialFormData },
        // incidentCausesAssessment: { ...initialFormDataCausesAssessment },
        /** seven */
        incidentInvestigationTeam: { ...initialFormData },
        // incidentInvestigationTeam: { ...incidentInvestigationTeam },
        /** eight */
        correctiveAction: { ...initialFormData },

        // correctiveAction: { ...correctiveAction },
      },
    },
    setCta: (action: FromStoreCtaState) => {},
    setFormActionState: (action: FormStoreActionState) => {},
    /**
     * set one or multiple form parts with one call
     */
    setFormParts: (formData: IncidentSchemaParts, state?: IncidentFormPartStoreState) => {},

    /**
     * set one property for specific from part
     */
    setFormItem: (formPartName: IncidentFormPartNames, prop: FormItemProp, state?: IncidentFormPartStoreState) => {},

    setUpdateFormItem: (formPartName: IncidentFormPartNames, state?: IncidentFormPartStoreState) => {
      return {
        setup: formItemSetup,
      };
    },

    /**
     * reset entire from
     */
    setResetFormStore: () => {},
    /**
     * setState of each either form/part manually without affecting data
     */
    setPartState: (formPartName: IncidentFormPartNames, state: IncidentFormPartStoreState) => {},

    setMainState: (state: IncidentFormStoreState) => {},
  },
} as IncidentFormStoreParts);
