import { BodyLegalCreateUpdateLor, LegalStatusSchema, LegalRelatedBuListSchema, LegalFileSchema, LegalLorTypeSchema } from '@/src/types';

import { assignPropsToThis } from '@/src/utils';

// const loadExampleData = () => {
//   try {
//     return require('@/static/trainings/createform.training.example.json');
//   } catch (err) {
//     Logger(['[loadExampleData]', err], 'error');
//     return {};
//   }
// };

const legalPropsAssignments = [
  'id',
  'status',
  'code',
  'summary',
  'actions',
  'lorTypes',
  'hierarchy',
  'relatedBu',
  'file',
  'promulgationDate',
  'effectiveDate',
  'name',
];

/**
 * should only send one training type upon submit
 */
const conditionedData = (formStateDate: BodyLegalCreateUpdateLor): BodyLegalCreateUpdateLor => {
  const data: any = formStateDate;
  if (!data) return undefined as any;
  //   if (!formStateDate?.employeeList?.length) {
  //     delete data.employeeList;
  //   }

  return data as BodyLegalCreateUpdateLor;
};

export class LegalLorRequestDto implements BodyLegalCreateUpdateLor {
  id?: string;
  status: LegalStatusSchema = null as any;
  code: string = '' as any;
  summary: string = '';
  name: string = '';
  actions: string = '';
  lorTypes: LegalLorTypeSchema[] = [] as any; // [{ value: 1, label: 'Environment' }] as any;
  hierarchy: string = '' as any;
  relatedBu: LegalRelatedBuListSchema[] = [] as any; // [{ buCode: 'cariva', buName: 'Cariva' }] as any;
  file: LegalFileSchema = null as any;
  promulgationDate: string | Date = new Date();
  effectiveDate: string | Date = new Date();

  constructor(data?: BodyLegalCreateUpdateLor, loadMock?: boolean) {
    let _data;
    // assign only required data
    if (Object.keys(data || {}).length) {
      _data = data;
    } else if (loadMock) {
      // _data = loadExampleData();
    }

    _data = conditionedData(_data);
    if (_data) /** updates > this= */ assignPropsToThis(legalPropsAssignments, _data, this);
  }
}
