/* eslint-disable react-hooks/rules-of-hooks */
// ? this is a new version of SnackBarAlert that use store and internaly detect when to display messages

import { Snackbar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { DefaultStore, TrainingStore, AttendeeStore, IncidentStore, LegalStore } from '@/types/store';
import { Logger } from '@/utils/logger/index';
import { toastMessages } from '@/src/static';
import { delay, displayToastMessage } from '@/utils/index';
import { SnackBarStatus, SnackBarStorSupport, TrainingSubmitType } from '@/src/types';

interface Props {
  /** add any custom references to help with condition statements  */
  submitReference?: TrainingSubmitType;
  /**
   * once message is cleared old store data is also cleared and state is reloaded for this page
   */
  autoHideDurationAndClearStore?: number;
  /** to make sure toast message doesnt close to early */
  status?: SnackBarStatus;
  /**
   * - provide store name for which to use this Snackbar
   * - we can add support for more stores
   * - currently available stores are listed
   * - added support for global notifications without declaring store, you need to add: [`__Notifications`]
   *  */
  storeName: SnackBarStorSupport[];
  /** on modal close */
  onClose?: () => void;

  /**
   * on delete is dispatched before the delete data is cleared so we have enough time to display toast message
   */
  onDeleted?: (del: boolean) => void;
  /** we can add support for more stores  */
  store: DefaultStore;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackBarAlertWithStore = (props: Props, context) => {
  // const { notify } = useGlobalContext();
  const { onClose, store, storeName, status, autoHideDurationAndClearStore, onDeleted, submitReference } = props || {};

  const [alertOpenState, setOpenAlertState] = useState(false);
  const [onStatus, setStatus] = useState<SnackBarStatus>(null as any);
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success');
  const [alertMessage, setAlertMessage] = useState('');
  const _autoHideDurationAndClearStore = autoHideDurationAndClearStore || 4000;
  useEffect(() => {
    if (status && !onStatus) {
      setStatus(status);
    }
  }, [onStatus, setStatus]);

  /**
   * we need to use this timer to clear state only after this time, because <SnackBarAlertWithStore/> is also part of the same page and will reload before its own autoHideDuration is expired
   */
  const clearStoreTimeout = _autoHideDurationAndClearStore;

  const forSwitch = (storeName: SnackBarStorSupport): void => {
    let _store;
    switch (storeName) {
      case 'TrainingStore': {
        const { store: __store }: TrainingStore = (_store = store) as any;
        useEffect(() => {
          if (!alertOpenState) {
            // will avoid infinite loops

            if (__store.trainingCanceledData.state === 'ready') {
              // Logger(['[trainingCanceledData]', __store.trainingCanceledData.data], 'log');
              setOpenAlertState(true);
              setAlertSeverity('success');
              setAlertMessage(toastMessages.dataUpdated);

              // NOTE: cannot reset status of deleteData in store yet, because have to wait for hidden load new trainingListData after delete success
              delay(500).then((n) => {
                __store.resetStatus('training/cancel');
              });

              return;
            }

            if (__store.trainingCanceledData.state === 'error') {
              Logger(['[trainingCanceledData]', __store.trainingCanceledData.error], 'error');
              setOpenAlertState(true);
              setAlertSeverity('error');
              setAlertMessage(toastMessages.dataNotUploaded);
              delay(500).then((n) => {
                __store.resetStatus('training/cancel');
              });
              return;
            }

            if (__store.trainingDeletedData.state === 'ready') {
              // Logger(['[trainingDeletedData]', __store.trainingDeletedData.data], 'log');

              setOpenAlertState(true);
              setAlertSeverity('success');
              setAlertMessage(toastMessages.dataDeleted);
              if (onDeleted) onDeleted(true);
              // NOTE: cannot reset status of deleteData in store yet, because have to wait for hidden load new trainingListData after delete success
              // delay(clearStoreTimeout).then((n) => {
              //   __store.resetStatus('training/delete');
              // });
              return;
            }

            if (__store.trainingDeletedData.state === 'error') {
              Logger(['[trainingDeletedData]', __store.trainingDeletedData.error], 'error');

              setOpenAlertState(true);
              setAlertSeverity('error');
              setAlertMessage(toastMessages.dataNotDeleted);
              if (onDeleted) onDeleted(false);
              delay(clearStoreTimeout).then((n) => {
                __store.resetStatus('training/delete');
              });
              return;
            }
            if (submitReference) {
              if (__store.trainingCreateUpdateData.state === 'ready') {
                setOpenAlertState(true);
                setAlertSeverity('success');
                setAlertMessage(displayToastMessage(toastMessages, true, submitReference) as any);

                return;
              }

              if (__store.trainingCreateUpdateData.state === 'error') {
                setOpenAlertState(true);
                setAlertSeverity('error');
                setAlertMessage(displayToastMessage(toastMessages, false, submitReference) as any);
              }
            }
          }
        }, [__store, setOpenAlertState, setAlertSeverity, alertSeverity, setAlertMessage]);

        break;
      }

      case 'AttendeeStore': {
        const { store: __store }: AttendeeStore = (_store = store) as any;
        useEffect(() => {
          if (!alertOpenState) {
            // will avoid infinite loops
          }

          if (__store.attendeeUpdateData.state === 'ready') {
            Logger(['[attendeeUpdateData]', __store.attendeeUpdateData.data], 'log');
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataUpdated);

            delay(500).then((n) => {
              __store.resetStatus('training/attendee/update');
            });

            return;
          }

          if (__store.attendeeUpdateData.state === 'error') {
            Logger(['[attendeeUpdateData]', __store.attendeeUpdateData.error], 'error');

            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotDeleted);
            if (onDeleted) onDeleted(false);
            delay(clearStoreTimeout).then((n) => {
              __store.resetStatus('training/attendee/update');
            });
          }

          if (__store.attendeeUploadData.state === 'error') {
            Logger(['[attendeeUploadData]', __store.attendeeUploadData.error], 'error');

            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.fileNotUpload);
            if (onDeleted) onDeleted(false);
            delay(clearStoreTimeout).then((n) => {
              __store.resetStatus('training/attendee/upload');
            });
          }
          if (__store.attendeeUploadData.state === 'ready') {
            Logger(['[attendeeUploadData]', __store.attendeeUploadData.data], 'log');
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.fileUpload);

            delay(500).then((n) => {
              __store.resetStatus('training/attendee/upload');
            });
          }

          if (__store.attendeeDeleteData.state === 'ready') {
            Logger(['[attendeeDeleteData]', __store.attendeeUploadData.data], 'log');
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataDeleted);

            delay(500).then((n) => {
              __store.resetStatus('training/attendee/remove-file');
            });
          }
          if (__store.attendeeDeleteData.state === 'error') {
            Logger(['[attendeeDeleteData]', __store.attendeeUploadData.data], 'log');
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotDeleted);

            delay(500).then((n) => {
              __store.resetStatus('training/attendee/remove-file');
            });
          }
        }, [__store, setOpenAlertState, setAlertSeverity, alertSeverity, setAlertMessage]);
        break;
      }

      case 'IncidentStore': {
        const parentStore: IncidentStore = (_store = store) as any;
        const { store: __parent } = parentStore;
        //  console.log('parentStore', parentStore);
        useEffect(() => {
          if (!alertOpenState) {
            // will avoid infinite loops
          }

          if (__parent.notify.notificationStatus === 'deleted') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataDeleted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'delete-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotDeleted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'updated') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'created') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataAdded);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'create-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotAdded);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'uploaded') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataUploaded);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'upload-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.fileNotUpload);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'update-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'remove-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }
          if (__parent.notify.notificationStatus === 'removed') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'file-too-large') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.fileTooLargeRejection);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'report-submit') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataReportSubmitted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'submit') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataSubmitted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }
        }, [__parent, setOpenAlertState, setAlertSeverity, alertSeverity, setAlertMessage, parentStore]);
        break;
      }

      case 'LegalStore': {
        const parentStore: LegalStore = (_store = store) as any;
        const { store: __parent } = parentStore;
        //  console.log('parentStore', parentStore);
        useEffect(() => {
          if (__parent.notify.notificationStatus === 'deleted') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataDeleted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'delete-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotDeleted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'updated') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }
          if (__parent.notify.notificationStatus === 'published') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataPublished);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }
          if (__parent.notify.notificationStatus === 'publish-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotPublished);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'created') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataAdded);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'create-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotAdded);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'uploaded') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataUploaded);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'upload-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.fileNotUpload);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'update-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'remove-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }
          if (__parent.notify.notificationStatus === 'removed') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataUpdated);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'file-too-large') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.fileTooLargeRejection);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'report-submit') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataReportSubmitted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'submit') {
            setOpenAlertState(true);
            setAlertSeverity('success');
            setAlertMessage(toastMessages.dataSubmitted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          if (__parent.notify.notificationStatus === 'submit-error') {
            setOpenAlertState(true);
            setAlertSeverity('error');
            setAlertMessage(toastMessages.dataNotSubmitted);
            delay(100).then(() => {
              __parent.notify.onStatusChange('initial');
            });
          }

          // will avoid infinite loops
        }, [__parent, setOpenAlertState, setAlertSeverity, alertSeverity, setAlertMessage, parentStore]);
        break;
      }

      default:
        Logger(['[SnackBarAlertWithStore]', 'No store matched', `storeName: ${storeName}`], 'error');
        break;
    }
    return undefined;
  };

  for (const storeItem of storeName) {
    forSwitch(storeItem);
  }

  return (
    <>
      {!store ? (
        'No store matched for SnackBarAlertWithStore'
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={onStatus ? onStatus === 'open' : alertOpenState}
          autoHideDuration={_autoHideDurationAndClearStore}
          onClose={() => {
            setOpenAlertState(false);
            setAlertMessage('');
            if (onClose) onClose();
          }}
          style={{ zIndex: '10000' }}
        >
          <Alert
            onClose={() => {
              setOpenAlertState(false);
              setAlertMessage('');
              if (onClose) onClose();
            }}
            severity={alertSeverity}
            sx={{ width: '100%' }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
