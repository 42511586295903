/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';

import { AzureAuthProviderProps, ProfileStore, PropDefaults } from '@/src/types';
import { useOutletContext } from 'react-router-dom';
import { Logger } from '@/logger/index';
import { MyIncident, MyTraining, MyProfile } from '@/src/components';
// import { useGlobalContext } from '@/store/index';
import { delay } from '@/src/utils';

// import { useGlobalContext } from '@/store/global.store';

interface Props extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

export const ProfileDetail: React.FC<Props> = (props, context) => {
  // const { detailId } = useParams();
  const [mount, setMount] = useState<number>(0);
  // const { currentUser } = useGlobalContext();

  const contextProps: Props & ProfileStore & { detailId: string } = useOutletContext();
  const { detailId: employeeId, store } = contextProps;

  Logger(['[ProfileDetail][profileStore]', store]);

  // NOTE  RESET FORM DATA WHEN UNMOUNTING!
  // ATTENTION this fixe issue with modal loading on its own
  // this clears the {refId} so the CreateUpdate modal does not show
  // we need to attach unmount in this nested component instead

  useEffect(() => {
    return () => {
      // PROVIDE ALL UNMOUNT LOGIC HERE
      // console.log('should unmount');
      store.resetStatus('employees/profile/my-training');
      store.resetStatus('employees/profile/my-incident');
      store.resetStatus('employees/profile/attendee/request');
      store.profileTrainingData.checked = false;
    };
  }, []);
  // UNMOUNTING END

  // ///////////////////////////////////////

  useEffect(() => {
    if (!store.profileTrainingData.checked && store.profileTrainingData.state === 'initial') {
      const query = { month: 0, year: 0 };
      store.setProfileTrainingList(employeeId, query);
      store.setProfileIncidents(employeeId);
      store.profileTrainingData.checked = true;
      delay(500).then(() => {
        setMount(1);
      });
    }
  }, [store, mount, setMount]);

  const state: 'ready' | 'error' | 'loading' = [!mount && 'loading', !!mount && 'ready'].filter((n) => !!n)[0] as any;

  switch (state) {
    case 'loading': {
      return <LinearProgress />;
    }

    case 'ready': {
      return (
        <Paper elevation={0}>
          <Typography variant="h2" className="mb-6">
            {/* My profile (your employee id: {employeeId}) */}
            My profile
          </Typography>
          <Box className="flex">
            <Box className="w-1/4">
              <MyProfile store={store} />
            </Box>
            <Box className="flex flex-col w-3/4">
              <MyTraining store={store} />
              <MyIncident store={store} />
            </Box>
          </Box>
        </Paper>
      );
    }

    default: {
      return <></>;
    }
  }
};
