import {
  TrainingStore,
  AzureAuthProviderProps,
  TrainingStatus,
  StoreStateDefault,
  GetTrainingDetail,
  ActionInProgress,
  TrainingRequestDtoType,
  TrainingBodyData,
  TrainingSubmitType,
} from '@/src/types';
import { Box, Button, Paper, Typography, Link, Table, TableBody, TableCell, TableContainer, TableRow, DialogTitle, DialogContent, Dialog } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@/components/ui/icons';
import { DeleteCancelTrainingModal } from '@/src/components';
import { Logger } from '@/logger/index';
import { FIELD_REQUIRED, modalDescriptions } from '@/src/static';
import { printDate, getByTrainingCode, isSuperAdmin, isTheSameDate, serverError, delay } from '@/utils/index';
import { useGlobalContext } from '@/store/index';
import TrainingForm from '@/src/components/ui/trainings/createUpdateTrainingModal/trainingFormClass';

type ModalActionType = 'cancel' | 'delete';

const invokeModalTypeState = (status: TrainingStatus, $set: (val: any) => void, modalType?: ModalActionType) => {
  if (status === 2) $set('cancel');
  if (status === 3) $set('delete');
};

interface TrainingData extends StoreStateDefault<GetTrainingDetail> {}
interface TrainingDetailOverviewProps {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  data: TrainingData;
  /**
   * We need to lock attendees screen while action takes place to void any interactions with that content
   */
  actionInProgress: (actionInProgress: ActionInProgress) => void;
  onTrainingSubmitType?: (submitType: TrainingSubmitType) => void;
}

export const TrainingDetailOverview = (props: TrainingDetailOverviewProps & TrainingStore) => {
  const { currentUser } = useGlobalContext();
  const {
    data: trainingData,
    store,
    onTrainingSubmitType,
    // actionInProgress
  } = props;
  const [getUid, setUid] = useState<string>(null as any);
  const [modalType, setModalType] = useState<ModalActionType>(null as any);
  const [title, setTitle] = useState('');
  const [hideAction, setHideAction] = useState<boolean>(false);
  const [isEditTraining, setEditTraining] = useState<boolean>(false);

  const handleOpenDeleteCancel = (id, type: ModalActionType) => {
    if (!getUid) setUid(id);
    invokeModalTypeState(trainingData?.data?.data?.status, setModalType);

    if (type === 'delete') {
      setTitle('Are you sure you want to delete this training?');
    }

    if (type === 'cancel') {
      setTitle('Are you sure you want to cancel this training?');
    }
  };

  useEffect(() => {
    if (store.trainingCanceledData.state === 'ready' && hideAction) {
      setHideAction(false);
    }
  }, [store, setHideAction]);

  const onSubmitHandler = (data: TrainingRequestDtoType | TrainingBodyData, submitType: 'submit-publish' | 'submit-draft' | 'submit-edit-publish') => {
    Logger(['[create/training][onSubmitHandler]', data], 'attention');
    Logger(['json', JSON.stringify(data)]);
    store.setTrainingCreateUpdate(data as any, 'item', 'edit');
    if (onTrainingSubmitType) onTrainingSubmitType(submitType);
  };

  useEffect(() => {
    if (store.trainingCreateUpdateData.state === 'ready' && !store.trainingCreateUpdateData.checked) {
      Logger(['[trainingCreateUpdateData][updated]', store.trainingCreateUpdateData.data], 'log');
      store.resetStatus('training/detail');
      delay(1500).then(() => {
        store.resetStatus('training/create');
      });
      store.trainingCreateUpdateData.checked = true;
      return;
    }

    if (store.trainingCreateUpdateData.state === 'error') {
      delay(4000).then(() => {
        store.resetStatus('training/create');
      });
    }
  }, [store.trainingCreateUpdateData.state]);

  if (!trainingData?.data?.data) {
    Logger(['[TrainingDetailOverview][data]', ' no data available'], 'warn');
    return null;
  }

  return (
    <>
      {isSuperAdmin(currentUser?.userProfile) && (
        <DeleteCancelTrainingModal
          deleteWaitTime={1000}
          location="detail"
          modalType={modalType}
          handleExit={() => {
            setUid(null as any);
          }}
          handleSubmit={() => {
            setUid(null as any);
            setHideAction(true);
          }}
          uid={getUid}
          store={store}
          title={title}
          description={modalType === 'delete' ? modalDescriptions.delete : modalDescriptions.cancel}
        ></DeleteCancelTrainingModal>
      )}

      {isSuperAdmin(currentUser?.userProfile) ? (
        <Dialog
          open={isEditTraining}
          onClose={() => {
            setEditTraining(false);
            store.resetStatus('training/create');
          }}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'body'}
          className="overflow-hidden"
        >
          <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography className="font-medium text-2xl">Update training</Typography>
              <Box className={`mx-3 mt-1 py-1 px-2 rounded publishedTraining text-sm h-5`}>Published</Box>
            </Box>
          </DialogTitle>
          <DialogContent className="p-0 " sx={{ overflow: 'unset' }}>
            <TrainingForm
              mode="edit-publish"
              loading={store.trainingDetailData.state !== 'error' && store.trainingDetailData.state !== 'ready'}
              editUid={trainingData?.data?.data.id}
              editData={store.trainingDetailData.data?.data}
              error={store.trainingDetailData.state === 'error'}
              serverErrorResponse={serverError(store?.trainingCreateUpdateData.error)}
              allRequired={true}
              allRequiredMessage={FIELD_REQUIRED}
              onClose={() => {
                store.resetStatus('training/create');
                setEditTraining(false);
              }}
              onSubmit={onSubmitHandler}
              formStatus={'open'}
            />
          </DialogContent>
        </Dialog>
      ) : null}
      <TableContainer component={Paper} elevation={4} className=" rounded-3xl p-6 w-auto">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className=" pr-6 pl-0 border-0 border-r border-dashed border-gray-300 align-top">
                <Box className="w-full flex flex-wrap">
                  <Typography className="w-[80px] font-medium">Course ID</Typography>
                  <Typography>{trainingData?.data?.data.courseNo}</Typography>
                </Box>
              </TableCell>
              <TableCell className=" pl-6 pr-0 border-0 w-[20px] align-top">
                <Icon icon="calendarDays" size={20} />
              </TableCell>
              <TableCell className=" px-6 border-0 border-r border-dashed border-gray-300 align-top">
                <Box className="w-full flex flex-wrap">
                  <Typography className="w-[100px] font-medium">Training date</Typography>
                  <Typography className="break-words">
                    {isTheSameDate(trainingData?.data?.data.startDate || '', trainingData?.data?.data.endDate || '')
                      ? `${printDate(trainingData?.data?.data.startDate)}`
                      : `${printDate(trainingData?.data?.data?.startDate)} - ${printDate(trainingData?.data?.data?.endDate)}`}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell className=" px-6 border-0 border-r border-dashed border-gray-300 align-top">
                <Box className="w-full flex flex-wrap">
                  <Typography className="w-[80px] font-medium">Type</Typography>
                  <Typography className="">
                    {getByTrainingCode(trainingData?.data?.data.trainingCode).trainingName}
                    {trainingData?.data?.data.onlineLink && (
                      <>
                        <span className="text-gray-400 px-2">/</span>
                        <Link
                          className=" text-black hover:text-blue-900 underline decoration-black font-medium"
                          href={`${trainingData?.data?.data.onlineLink}`}
                        >
                          Online
                        </Link>
                      </>
                    )}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell className={`px-6 border-0 ${isSuperAdmin(currentUser?.userProfile) ? 'border-r border-dashed border-gray-300' : ''}`} rowSpan={2}>
                <Typography className="font-medium text-lg mb-4 w-fit">Accepted attendees</Typography>
                <Typography className="font-medium text-xl">
                  <Icon className="mr-2" icon="userGroup" viewBox="0 0 640 512" size={24} />
                  {trainingData?.data?.data.attendees}&nbsp;/&nbsp;{trainingData?.data?.data.total}
                </Typography>
              </TableCell>
              {isSuperAdmin(currentUser?.userProfile) && (
                <TableCell className=" pl-6 pr-0 border-0 w-fit" rowSpan={2} align="right">
                  {trainingData?.data?.data.status === 2 ? (
                    <Box className="flex flex-col">
                      <Button
                        disabled={hideAction === true}
                        variant="outlined"
                        color="error"
                        className="m-2 whitespace-nowrap"
                        onClick={() => handleOpenDeleteCancel(trainingData?.data?.data.id, 'cancel')}
                      >
                        <Icon icon="calendarXmark" color="#F14242" viewBox="0 0 448 512" size={18} className="mr-2" />
                        Cancel training
                      </Button>
                      <Button
                        onClick={() => {
                          setEditTraining(true);
                        }}
                        className="m-2"
                        variant="outlined"
                        startIcon={<Icon icon="penToSquare" color="#3155FF" viewBox="0 0 576 512" size={20} />}
                      >
                        Update training
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      disabled={hideAction === true}
                      variant="contained"
                      color="error"
                      className="m-auto whitespace-nowrap"
                      onClick={() => handleOpenDeleteCancel(trainingData?.data?.data.id, 'delete')}
                    >
                      <Icon icon="trash" color="#ffffff" viewBox="0 0 448 512" size={18} className="mr-2" />
                      Delete
                    </Button>
                  )}
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell className=" pr-6 pl-0 border-0 border-r border-dashed border-gray-300 align-top">
                <Box className="w-full flex">
                  <Typography className="w-[40px] font-medium">Link</Typography>
                  <Typography className="font-medium flex whitespace-nowrap">
                    <NavLink
                      className="text-black hover:text-blue-900"
                      to={`/courses/detail/${trainingData?.data?.data.courseNo}`}
                      state={{
                        trainingId: trainingData?.data?.data?.id,
                        from: `/trainings/detail/${trainingData?.data?.data.id}`,
                        currentTrainingPassScore: trainingData?.data?.data?.postTestPassScore,
                        trainingDate: isTheSameDate(trainingData?.data?.data?.startDate || '', trainingData?.data?.data.endDate || '')
                          ? printDate(trainingData?.data?.data?.startDate)
                          : printDate(trainingData?.data?.data?.startDate) + ' - ' + printDate(trainingData?.data?.data.endDate),
                      }}
                    >
                      Course detail
                    </NavLink>
                    <span className="text-gray-400 px-1">|</span>
                    <Link className="text-black hover:text-blue-900 underline decoration-black" href={`${trainingData?.data?.data.evaluationLink}`}>
                      Evaluation
                    </Link>
                  </Typography>
                </Box>
                <Typography className="font-medium mt-4">Certificate expiration date</Typography>
                <Typography className="break-words">
                  {trainingData?.data?.data.certExpirationDate ? printDate(trainingData?.data?.data.certExpirationDate) : 'No expiration date'}
                </Typography>
              </TableCell>
              <TableCell className=" pl-6 pr-0 border-0 w-[20px] align-top">
                <Icon icon="clock" size={20} />
              </TableCell>
              <TableCell className=" px-6 border-0 border-r border-dashed border-gray-300 align-top">
                <Box className="w-full flex flex-wrap">
                  <Typography className="w-[100px] font-medium">Training time</Typography>
                  <Typography>
                    {printDate(trainingData?.data?.data.startDate, 'HH:mm')} - {printDate(trainingData?.data?.data.endDate, 'HH:mm')}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell className=" px-6 border-0 border-r border-dashed border-gray-300 align-top">
                <Box className="w-full flex flex-wrap">
                  <Typography className="w-[80px] font-medium">Location</Typography>
                  <Typography className="">{trainingData?.data?.data.location || '-'}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
