/** declare only http service related requests */

import { PostAuthLogin } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
interface Tokens {
  /** Microsoft token */
  accessToken: string;
  /** Microsoft functions api code */
  // code: string;
}

/**
 * @description login to functions api and generate jwt token to identify current user for all api calls
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const AuthLoginPost = (prefix: string = 'auth/login', tokens: Tokens, hardRejection = true): Promise<PostAuthLogin> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT + 5000, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);
  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return instance.client
    .post(prefix, `accessToken=${tokens.accessToken}`, config)
    .then((d: AxiosResponse<PostAuthLogin>) => {
      // return Promise.reject(new Error('Ups error with Login')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostAuthLogin>;
};

export const AuthLoginByPass = (prefix: string = 'auth/login-by-pass', username: string, password: string, hardRejection = true): Promise<PostAuthLogin> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT + 5000, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return instance.client
    .post(prefix, JSON.stringify({ username: username, password: password }), config)
    .then((d: AxiosResponse<PostAuthLogin>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostAuthLogin>;
};
