/** @type {import('../../../types/common').MockApiData} */
exports.employeeDelete = {
  // what type of request: [get,put,post,delete], mainly using get
  type: 'delete',
  // api name after ./api prefix, api/example
  uniqId: 'delete-employee/delete',

  /**
   * any data: can be object, array etc, depending what you want to return
   * - data is added thru AxiosService > interceptors.response after https://httpbin.org/ request was successful
   * */
  response: {
    status: {
      code: 200,
      message: 'success',
    },
    data: {
      employeeId: 'ROV1234',
    },
  },
};
