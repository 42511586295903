import React, { useState } from 'react';
import { AzureAuthProviderProps, PropDefaults } from '@/src/types';
import { Box, Collapse, IconButton, ToggleButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { nav } from './nav';
import { Icon } from '../../ui/icons';
import { useGlobalContext } from '@/store/index';

interface SideBarProps extends PropDefaults {
  azure: AzureAuthProviderProps;
}

export function SideBar(props: SideBarProps) {
  const { currentUser } = useGlobalContext();

  const MENUS = nav.filter((n) => {
    if (!currentUser.aimAdmin && Number(n.id) === 4) return false;
    else return true;
  });

  const [miniSidebar, setMiniSidebar] = useState(false);
  const [selected, setSelected] = useState('');
  const [open, setOpen] = useState('');

  const handleChange = (index: string) => {
    if (open === index) {
      setOpen('');
      setSelected('');
    } else {
      setOpen(index);
      setSelected(index);
    }
  };

  return (
    <div className={`sideBar min-h-screen max-h-screen flex flex-col fixed top-0 left-0 overflow-hidden z-[1200] ${miniSidebar && 'miniSidebar'}`}>
      <img src={require('@/assets/icons/sidebar-bg.png')} alt="Injury" className=" absolute bottom-0 left-0" />
      <NavLink to="/">
        <Box className="logo"></Box>
      </NavLink>
      <Box className="flex flex-col px-4" sx={{ maxHeight: 'calc(100vh - 140px)' }}>
        {MENUS.map((item, index) => (
          <Box key={`${item.id}+${index}`} className="relative">
            <NavLink to={item.path} className="navBar tracking-wide flex text-sm mt-2 no-underline text-white rounded overflow-hidden">
              <em className="text-center">
                <Icon icon={item.icon} color="#fff" size={item.size} viewBox={item.viewBox} />
              </em>
              <span>{item.name} </span>
            </NavLink>
            {item.children !== undefined ? (
              <>
                <ToggleButton
                  value="check"
                  selected={index.toString() === selected}
                  onClick={() => handleChange(index.toString())}
                  className="absolute top-3 border-0 menuToggle"
                >
                  <Icon icon="chevronsDown" size={15} color="#fff" viewBox="0 0 448 512" />
                </ToggleButton>
                <Collapse key={index} in={index.toString() === open}>
                  {Array.isArray(item.children)
                    ? item.children
                        .filter((n) => !n.disabled)
                        .map((childItem) => (
                          <NavLink
                            key={childItem.id}
                            to={childItem.id === '3.2' ? `${item.path}${childItem.path}` : childItem.path}
                            className="navBar no-underline text-white rounded flex text-sm mt-2 subMenu"
                          >
                            <em className="text-center">
                              <Icon icon="circle" color="#fff" size={6} viewBox="0 0 512 512" />
                            </em>
                            <span>{childItem.name}</span>
                          </NavLink>
                        ))
                    : null}
                </Collapse>
              </>
            ) : null}
          </Box>
        ))}
      </Box>
      <IconButton className="mt-auto ml-auto mb-2 w-full p-5 h-auto rounded-none miniSidebarToggle hidden lg:flex" onClick={() => setMiniSidebar(!miniSidebar)}>
        <Icon icon="chevronsLeft" color="#fff" viewBox="0 0 320 512" size={20} />
      </IconButton>
    </div>
  );
}
