/**
 * Axios tutorials
 * source: https://axios-http.com/docs/intro
 *
 * @DOES
 * - For each new API please create it from AxiosService, all get and post methods should be created from http.service
 *
 * @DONTS
 * - Dont create independent axios nested in application, use it via service.http as per example.ts
 */

import { ENVS } from '@/constants/index';
import axios, { AxiosRequestConfig, AxiosError, AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import { mockApiDataList } from '@/static/mock.server.data';
import { HttpStatus, MockApiData } from '@/src/types';
import { getMockApiDataByName } from '../index';
import { Logger } from '@/logger/index';

const mockApiData: MockApiData[] = mockApiDataList;
interface callWhatReference {
  apiRef: string;
  method: 'get' | 'post' | 'put';
}

interface CustomizedError extends HttpStatus {
  [name: string]: any;
}
/**
 * Handler error response, in case there is timeout or no connection to server the error will not be of the same format
 */
export const errorHandler = (error: any): Error => {
  if (typeof error === 'string') {
    return new Error(error);
  }
  if (axios.isAxiosError(error)) {
    return Error(`${(error as any).error || error.message}`);
  }

  if (typeof error === 'object') {
    try {
      const err: CustomizedError = error;
      return Error(`${err.error || err.message} `);
    } catch (err) {
      return Error(`undefine error, ${err}`);
    }
  } else return Error('undefine error');
};

interface AxiosRequestConf extends AxiosRequestConfig {
  /**
   * used with MOCK_SERVER to pass map mock data to api route
   * this reference must match the name from src\static\mock.server.data\index.js > mockApiDataList[0].name
   * must match api.name === apiRef
   */
  apiRef?: string;

  /**
   * MOCK_SERVER_API: optionally override to use mock, or not use server on individual routes
   * LOCAL_PROXY: call to real api via http via proxy to avoid cors issues
   * NO_PROXY: all to real api without proxy
   * */
  proxyType?: 'MOCK_SERVER_API' | 'LOCAL_PROXY' | 'NO_PROXY';
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

/** Base class for Axios */
export class AxiosService {
  private debug: boolean;
  private _client: AxiosStatic;
  private config: AxiosRequestConf;

  /**
   * map to mock-api proxy or real api proxyfied on localhost:3000/api
   */
  private LOCAL_API_BASE = ENVS.ENVIRONMENT === 'LOCAL' ? 'http://localhost:3000' : 'https://httpbin.org';
  apiPrefix = ENVS.API_PREFIX;

  baseURL: string = '';

  // NOTE dont remove!
  private callWhatReference: callWhatReference = {} as any;

  /** disable catch rejections promises */
  private reject: boolean;
  private usingMockServer = false;

  constructor(config: AxiosRequestConf, debug = true, reject: boolean = true) {
    if (!config) {
      Logger(['[AxiosService][error]', 'AxiosRequestConfig is empty'], 'error');
    }

    if (!config.apiRef) {
      throw new Error('AxiosService, apiRef/prefix must be set');
    }

    this.usingMockServer = config.proxyType === 'MOCK_SERVER_API' || ENVS.MOCK_SERVER === '1';

    // cannot use both proxy to real api and mockServer that is also our /mock-api
    if (config.proxyType === 'LOCAL_PROXY') {
      this.usingMockServer = false;
    }

    if (!config.proxyType || config.proxyType === 'NO_PROXY') {
      this.usingMockServer = false;
    }

    this.apiPrefix = this.usingMockServer ? '/mock-api' : ENVS.API_PREFIX;

    /** Provide Axios with information on what api is being called, will also help with reverse proxy to provide data */
    this.callWhatReference = {
      apiRef: config.apiRef as any,
      method: config.method as any,
    };

    /** if using mock api but not in local environment */
    if (this.usingMockServer && ENVS.ENVIRONMENT !== 'LOCAL') {
      this.apiPrefix = this.callWhatReference.method;
    }

    this.config = config;
    this.extends();
    this._client = axios;
    this.debug = debug;
    this.reject = reject;
  }

  extends() {
    // const jwt = getJwtToken();

    /** base api url */
    // CALL REAL API via local proxy to avoid cors issues,
    if (this.usingMockServer || (this.config.proxyType === 'LOCAL_PROXY' && ENVS.ENVIRONMENT === 'LOCAL')) {
      this.baseURL = new URL(this.apiPrefix, this.LOCAL_API_BASE).toString();
    } else {
      this.baseURL = new URL(ENVS.API_PREFIX, ENVS.API_BASE).toString();
    }

    const config: AxiosRequestConfig = {
      baseURL: this.baseURL,
      responseType: 'json',
      headers: {
        // set internally for all if available
        // ...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
        'Access-Control-Allow-Origin': '*',
        // 'Content-Type': 'application/json;charset=UTF-8',
      },
      timeout: 8000,
      transformRequest: [
        function (data, headers) {
          // transform headers being send
          return data;
        },
      ],
    };
    // delete this.config.proxyType;
    delete this.config.apiRef;

    this.config = Object.assign({}, config, this.config);
  }

  /** TODO
   * Implement correct error code messages from ./Codes
   */
  defaultHandleError = (error: AxiosError): Promise<AxiosError<any>> | AxiosError<any> => {
    // REVIEW PICKING favicon.ico thru this request
    // >> must be issue with server api or configuration
    if (!axios.isAxiosError(error)) {
      Logger(['[NonAxiosService][error]'], 'error');
      return Promise.reject(errorHandler(error));
    }

    if (error.response && this.debug) {
      Logger(['[AxiosService][Status]', error.response.status], 'error');
      Logger(['[AxiosService][Data]', error.response.data], 'error');
      Logger(['[AxiosService][Headers]', error.response.headers], 'error');
    }

    if (!error.response && !this.debug) Logger(['[AxiosService][error]', error], 'error');
    const status = error.response?.status;
    switch (status) {
      case 400:
        Logger([`[AxiosService][${status}]`, error.message], 'error');
        break;
      case 401:
        Logger([`[AxiosService][${status}]`, error.message], 'error');
        break;
      case 404:
        Logger([`[AxiosService][${status}]`, error.message], 'error');
        break;
      case 500:
        Logger([`[AxiosService][${status}]`, error.message], 'error');
        break;
      default:
        Logger([`[AxiosService][${status}][unhandled]`, error?.response?.statusText], 'error');
        break;
    }

    if (this.reject) {
      if (error?.response?.data) {
        return Promise.reject(new Error(error?.response?.data?.error));
      } else {
        return Promise.reject(new Error(error?.message || (error as any).error));
      }
    } else {
      Logger([`[AxiosService][soft][error]`], 'error');
      // NOTE when soft error is enabled it will return response from .then, and not catch it again, just so you know
      return null as any;
    }
  };

  /**
   *
   * @description refer to https://axios-http.com/docs/res_schema
   */
  response(resp: AxiosResponse) {
    return resp;
  }

  private interceptors(client: AxiosInstance): AxiosInstance {
    client.interceptors.request.use(
      (config) => {
        const usingLocalProxyRealApi = this.config.proxyType === 'LOCAL_PROXY' && ENVS.ENVIRONMENT === 'LOCAL';
        const usingMockServerOnProd = this.usingMockServer && (ENVS.ENVIRONMENT !== 'LOCAL' || ENVS.NODE_ENV !== 'development');

        const api = config.url;
        const url = config.baseURL + (config.url ? '/' + config.url : '');
        const prepend = usingLocalProxyRealApi ? `[http][service][local_proxy]` : `[http][service]`;
        if (api) Logger([`${prepend}[${config.method}][${api}]`, url], 'log');
        else Logger([`${prepend}[${config.method}]`, url], 'log');

        if (this.usingMockServer) {
          // append to url request in proxy mode
          config.url = config.method;
        }

        if (usingMockServerOnProd) {
          // we dont have proxy in production we call real api
          config.url = '';
        }

        // Do something before request is sent
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    client.interceptors.response.use(
      (response) => {
        // we have mock server running locally, we need to intercept to return data to axios http service
        if (this.usingMockServer /* && ENVS.ENVIRONMENT === 'LOCAL'*/) {
          const uniqId = this.callWhatReference.method + '-' + this.callWhatReference.apiRef;
          let data;
          try {
            data = getMockApiDataByName(uniqId, mockApiData)?.response;
          } catch (err) {
            Logger([`[AxiosService][interceptor][response]`, err], 'error');
          }

          Logger([`[AxiosService][MOCK_SERVER][interceptor]`], 'notice');
          //  const data = getMockApiDataByName(this.callWhatReference.apiRef, mockApiData)?.response;
          if (!data) Logger([`[AxiosService][interceptor][data]`, `no data provided for api: ${this.callWhatReference.apiRef}`], 'error');
          delete response.data;
          response.data = data;
        }
        // we are using real api
        else {
          Logger([`[AxiosService][REAL_API][interceptor][NO_CHANGE]`], 'notice');
        }
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response;
      }
      // (error) => {
      //   // Any status codes that falls outside the range of 2xx cause this function to trigger
      //   // Do something with response error
      //   return Promise.reject(error);
      // }
    );
    return client;
  }

  /** creates new clean {AxiosInstance} for every request  */
  get client(): AxiosInstance {
    const client = this._client?.create(this.config);
    return this.interceptors(client) as AxiosInstance;
  }
}
