import React from 'react';
import { Box, FormControl, Typography, InputLabel } from '@mui/material';
import { IncidentFormStoreParts, IncidentStore } from '@/src/types';
import { TagsInputIncident } from '@/src/components';

interface PartThreeSectionTwoProps {
  formStore: IncidentFormStoreParts;
  incidentStore: IncidentStore;
  disabledInput: boolean;
}

export const PartThreeSectionTwo = (props: PartThreeSectionTwoProps) => {
  const {
    formStore: { formStore },
    incidentStore: { store: incidentStore },
    disabledInput,
  } = props;
  const injuredData = formStore.store.formData.injured?.data || {};
  const handleChangeInjuredData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('injured', 'updated').setup(value, keyName, subKeyName);
  };
  const handleMultiSelection = (type: 'select' | 'delete', value: any, keyName: string) => {
    if (type === 'select') {
      handleChangeInjuredData(value.length > 0 ? value : null, keyName);
    }
    if (type === 'delete') {
      const newValueArray = injuredData[keyName].filter((item) => item.value !== value);
      handleChangeInjuredData(newValueArray.length > 0 ? newValueArray : null, keyName);
    }
  };

  return (
    <>
      <Typography variant="h5" className="mb-6 mt-8 border-0 border-l-8 border-solid border-blue-600 pl-4" sx={{ marginLeft: '-26px;' }}>
        Specific body part
      </Typography>
      <Box className="grid grid-cols-5 gap-6">
        <Box className="col-span-3 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Head and neck</InputLabel>
            <TagsInputIncident
              selectKeyName="headNeckParts"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.headNeckParts || ([] as { value: number; label: string }[])}
              selectedData={injuredData?.headNeckParts || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
              disabledInput={disabledInput}
            />
          </FormControl>
        </Box>
      </Box>
      <hr className="border-dashed border border-gray-200 my-5" />
      <Box className="grid grid-cols-5 gap-6">
        <Box className="col-span-3 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Upper limbs</InputLabel>
            <TagsInputIncident
              selectKeyName="upperLimbParts"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.upperLimbParts || ([] as { value: number; label: string }[])}
              selectedData={injuredData?.upperLimbParts || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
              disabledInput={disabledInput}
            />
          </FormControl>
        </Box>
      </Box>
      <hr className="border-dashed border border-gray-200 my-5" />
      <Box className="grid grid-cols-5 gap-6">
        <Box className="col-span-3 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Lower limbs</InputLabel>
            <TagsInputIncident
              selectKeyName="lowerLimbParts"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.lowerLimbParts || ([] as { value: number; label: string }[])}
              selectedData={injuredData?.lowerLimbParts || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
              disabledInput={disabledInput}
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
};
