import { FormComponentType, IncidentFormPartNames, IncidentStatusReport, InitialDataWrapper } from '@/src/types';
import { TextFieldProps } from '@mui/material';
import { Logger } from '../logger/index';
import React from 'react';
import { IncidentFormPartStoreState, IncidentFormStoreParts, IncidentFormStoreSchemaParts } from '@/types/store/index';
import _ from 'lodash';

/**
 * Check if number has any decimals value, except: example: 1.00000
 */
export const isDecimal = (n: number) => {
  const result = n - Math.floor(n) !== 0;
  if (result && !isNaN(n)) return true;
  else return false;
};

/**
 * global props assignment
 */
export const inputPresets = (): TextFieldProps => {
  // add any global presets
  return {
    //
    // onKeyPress: (e: any) => {
    //   try {
    //     // disable form submit on key press
    //     if (e.target.keyCode === 13 || e.key === 'Enter') {
    //       e.preventDefault();
    //     }
    //   } catch (err) {}
    // },
  };
};

/**
 * Check if value is safe to parse as number then return number Object
 */
const safeNumber = (val): number => {
  try {
    return Number(val) || 0;
  } catch (err) {
    return NaN;
  }
};

/**
 *
 * @param decimals provide value as decimal if option provided
 */
export const textFieldProps = (data: any, keyName: string, subKeyName?: string, decimals?: number): TextFieldProps => {
  const defaultValue = ((k1, k2) => {
    try {
      // make sure use trim all string from left and right
      if (typeof data[k1] === 'string') {
        data[k1] = (data[k1] || '').trim();
      }
      if (typeof data[k1] !== 'string' && data[k1]) {
        if (typeof data[k1][k2 as any] === 'string') {
          data[k1][k2 as any] = (data[k1][k2 as any] || '').trim();
        }
      }
      return k1 && !k2 ? data[k1] : k1 && k2 ? data[k1][k2] : undefined;
    } catch (err) {}
    return undefined;
  })(keyName, subKeyName);

  let props: any = {};
  try {
    if (!keyName && !subKeyName) {
      return {
        ...props,
        // provide default global props
        ...inputPresets(),
      };
    }

    if ((decimals as any) >= 0) {
      const numValue = safeNumber(defaultValue);
      // decimal value from source use that instead
      if (isDecimal(numValue)) {
        props = (defaultValue ? { defaultValue: numValue } : {}) as any;
        // parse decimal value as per  decimals selected
      } else {
        props = (defaultValue ? { defaultValue: numValue.toFixed(decimals) } : {}) as any;
      }
      // else its another value
    } else props = (defaultValue ? { defaultValue } : {}) as any;
  } catch (err) {
    // Logger(['[textFieldProps][error]', err], 'warn');
    props = {};
  }

  return {
    ...props,
    ...inputPresets(),
  };
};

/**
 * Custom Form component mainly to provide defaultValue and the default value is returned later then the component is rendered
 * - this approve will fix the issue of no defaultValue been produced to the component
 * 
 * @example:  <FormComponent
              keyOne="age"
              formData={initialReportData}
              component={(obj, keyOne) => (
                <TextField label="Age" type="number" onBlur={(e) => handleChangeInitialReportData(e.target.value, keyOne)} {...obj} />
              )}
            />
 */
export const FormComponent = (props: FormComponentType): any => {
  /**
   * example :  {keyOne:data }
   * example : {keyOne:keyTwo }
   * example:  data[keyOne][keyTwo]
   */
  const { formData, keyOne, keyTwo, minDecimalDigit, component } = props;
  if (!keyOne && keyTwo) {
    // NOTE  need to set keyOne first!
    return <>FormComponent, wrong setting</>;
  }

  const obj = textFieldProps(formData, keyOne, keyTwo, minDecimalDigit);
  return React.cloneElement(component(obj || {}, keyOne, keyTwo), {
    // This will override the original ASCIIChar in the text.
  });
};

export const checkAllApiDataReady = (arrayOfApiToCheckReady: (InitialDataWrapper & { apiName: string })[]): boolean => {
  /* this function is checking whenever all the api data used in incident create form is ready */
  let ready = true;
  arrayOfApiToCheckReady.forEach((api) => {
    if (api.state !== 'ready') {
      ready = false;
      Logger([`[IncidentCreate][checkAllApiDataReady][ ${api.apiName} is not ready ]`, api.state]);
    }
  });
  if (ready) Logger(['[IncidentCreate][checkAllApiDataReady][ all API data for incident create form is ready ]']);
  return ready;
};

/**
 * Required FormStore fields, must be set before we can submit anything
 */
export const requiredFormStoreFields = (formData: IncidentFormStoreSchemaParts) => {
  let pass = true;
  if (!formData.initialReport.data?.name) pass = false;
  // TODO add more conditions here
  return pass;
};

export const checkReadyToSaveDraftIncident = (formData: IncidentFormStoreSchemaParts): boolean => {
  let isReady = true;
  if (!formData.initialReport.data?.name) isReady = false;
  if (!formData.initialReport.data?.dateOfIncident) isReady = false;
  if (!formData.initialReport.data?.damageType) isReady = false;
  if (!formData.initialReport.data?.company) isReady = false;
  if (isReady) {
    return true;
  } else {
    return false;
  }
};

/**
 * @param {formStore} Object provide original formStore object
 * @param state optional to what state you want the reset to be ?
 */
export const resetFormStoreData = async ({ formStore }: IncidentFormStoreParts, state?: IncidentFormPartStoreState): Promise<void> => {
  const formData = {
    initialReport: null,
    description: null,
    injured: null,
    propertyDamage: null,
    incidentCausesAssessment: null,
    incidentInvestigation: null,
    incidentInvestigationTeam: null,
    correctiveAction: null,
  };

  for (const part in formData) {
    // eslint-disable-next-line no-prototype-builtins
    if (formData.hasOwnProperty(part)) {
      if (formData[part] === null || formData[part] === undefined) {
        formStore.setFormParts({ [part]: null }, state || 'reset');
        // / await delay(100);
      }
    }
  }
  return undefined as any;
};

export const getClassNameStatusReport = (status: IncidentStatusReport | undefined): string => {
  switch (status) {
    case 1:
      return 'draft';
    case 2:
      return 'inReview';
    case 3:
      return 'onGoing';
    case 4:
      return 'closed';
    default:
      return '';
  }
};

interface fieldMustHaveType<T = any> {
  keyName: string;
  // isOptional?: boolean;
  isNestedCheck?: boolean;
  nested?: T[];
}
export const fieldMustHave = (partName: IncidentFormPartNames): fieldMustHaveType<fieldMustHaveType>[] => {
  switch (partName) {
    case 'initialReport': {
      const fieldsToCheckCompletePart1 = [
        { keyName: 'name' },
        { keyName: 'age' },
        { keyName: 'employeeId' },
        { keyName: 'position' },
        { keyName: 'jobExperience' },
        { keyName: 'phone' },
        { keyName: 'damageType' },
        { keyName: 'dateOfIncident' },
        { keyName: 'dateOfReport' },
        { keyName: 'company' },
        { keyName: 'location' },
        { keyName: 'functions' },
        { keyName: 'jobClassification', isNestedCheck: true, nested: [{ keyName: 'choice' }] },
        { keyName: 'activityTypes' },
        // { keyName: 'activityTypeOther', isOptional: true },
        { keyName: 'categoryEvents' },
        // { keyName: 'categoryEventOther', isOptional: true },
        {
          keyName: 'costEstimate',
          isRequired: true,
          isNestedCheck: true,
          nested: [{ keyName: 'equipmentMaterials' }, { keyName: 'production' }, { keyName: 'adminLaborer' }],
        },
      ];
      return fieldsToCheckCompletePart1;
    }
    case 'description': {
      const fieldsToCheckCompletePart2 = [
        { keyName: 'whatDesc' },
        { keyName: 'whyDesc' },
        { keyName: 'whenDesc' },
        { keyName: 'howDesc' },
        { keyName: 'whoInvolved' },
        { keyName: 'images' },
      ];
      return fieldsToCheckCompletePart2;
    }
    case 'injured': {
      const fieldsToCheckCompletePart3 = [
        // { keyName: 'noDataSkip', isOptional: true },
        { keyName: 'injuryTypes' },
        // { keyName: 'injuryTypeOther', isOptional: true },
        { keyName: 'bodyLeftTypes' },
        { keyName: 'bodyRightTypes' },
        { keyName: 'headNeckParts' },
        { keyName: 'upperLimbParts' },
        { keyName: 'lowerLimbParts' },
      ];
      return fieldsToCheckCompletePart3;
    }
    case 'propertyDamage': {
      const fieldsToCheckCompletePart4 = [
        // { keyName: 'noDataSkip', isOptional: true },
        { keyName: 'propertyType' },
        // { keyName: 'propertyTypeOther', isOptional: true },
        { keyName: 'arrivalDate' },
        { keyName: 'treatmentDetail' },
        { keyName: 'deposition' },
        { keyName: 'incidentClassification' },
      ];
      return fieldsToCheckCompletePart4;
    }
    case 'incidentInvestigation': {
      const fieldsToCheckCompletePart5 = [{ keyName: 'htmlText' }];
      return fieldsToCheckCompletePart5;
    }
    case 'incidentCausesAssessment': {
      const fieldsToCheckCompletePart6 = [
        {
          keyName: 'casualFactors',
          isRequired: true,
          isNestedCheck: true,
          nested: [
            { keyName: 'one' },
            { keyName: 'two' },
            { keyName: 'three' },
            { keyName: 'four' },
            { keyName: 'five' },
            { keyName: 'six' },
            { keyName: 'seven' },
            { keyName: 'eight' },
            { keyName: 'nine' },
            // { keyName: 'oneOther', isOptional: true },
            // { keyName: 'twoOther', isOptional: true },
            // { keyName: 'threeOther', isOptional: true },
            // { keyName: 'fourOther', isOptional: true },
            // { keyName: 'fiveOther', isOptional: true },
            // { keyName: 'sixOther', isOptional: true },
            // { keyName: 'sevenOther', isOptional: true },
            // { keyName: 'eightOther', isOptional: true },
            // { keyName: 'nineOther', isOptional: true },
          ],
        },
        {
          keyName: 'rootCause',
          isRequired: true,
          isNestedCheck: true,
          nested: [{ keyName: 'categories' }],
        },
      ];
      return fieldsToCheckCompletePart6;
    }
    case 'incidentInvestigationTeam': {
      const fieldsToCheckCompletePart7 = [{ keyName: 'investigators' }];
      return fieldsToCheckCompletePart7;
    }
    case 'correctiveAction': {
      const fieldsToCheckCompletePart8 = [
        {
          keyName: 'actionItems',
          isNestedCheck: true,
          nested: [{ keyName: 'action' }, { keyName: 'party' }, { keyName: 'responsibility' }, { keyName: 'date' }, { keyName: 'file' }],
        },
      ];
      return fieldsToCheckCompletePart8;
    }
    default: {
      return [] as any;
    }
  }
};

export const loopCheckComplete = (fieldsToCheck: fieldMustHaveType<any>[], data: any): boolean => {
  const reasonNotComplete = [] as string[];

  try {
    for (const field of fieldsToCheck) {
      if (!_.isUndefined(data[field.keyName])) {
        if (!_.isNull(data[field.keyName])) {
          switch (typeof data[field.keyName]) {
            case 'boolean':
              if (_.isBoolean(data[field.keyName])) continue;
              else reasonNotComplete.push(`${field.keyName} is incorrect boolean`);
              continue;
            case 'number':
              if (_.isNumber(data[field.keyName]) && !_.isNaN(data[field.keyName])) continue;
              else reasonNotComplete.push(`${field.keyName} is NaN number`);
              continue;
            case 'string':
              if (_.isString(data[field.keyName]) && !_.isEmpty(_.trim(data[field.keyName]))) continue;
              else reasonNotComplete.push(`${field.keyName} is empty string`);
              continue;
            case 'object':
              if (_.isArray(data[field.keyName])) {
                if (!_.isEmpty(data[field.keyName])) {
                  if (field.isNestedCheck) {
                    for (const nestedField of data[field.keyName]) {
                      if (loopCheckComplete(field.nested as fieldMustHaveType<any>[], nestedField)) continue;
                      else reasonNotComplete.push(`${field.keyName} array object has incomplete child`);
                    }
                  } else continue;
                } else reasonNotComplete.push(`${field.keyName} is empty array object`);
              } else if (_.isPlainObject(data[field.keyName])) {
                if (!_.isEmpty(data[field.keyName])) {
                  if (field.isNestedCheck) {
                    if (loopCheckComplete(field.nested as fieldMustHaveType<any>[], data[field.keyName])) continue;
                    else reasonNotComplete.push(`${field.keyName} plain object has incomplete child`);
                  } else continue;
                } else reasonNotComplete.push(`${field.keyName} is empty plain object`);
              } else if (_.isDate(data[field.keyName])) {
                const newDate = new Date(data[field.keyName]);
                if (newDate instanceof Date && !isNaN(newDate.valueOf())) continue;
                else reasonNotComplete.push(`${field.keyName} is invalid date`);
              } else {
                reasonNotComplete.push(`${field.keyName} is unknown type object`);
              }
              continue;
            default:
              reasonNotComplete.push(`${field.keyName} is unknown type`);
              continue;
          }
        } else {
          reasonNotComplete.push(`${field.keyName} is null`);
          continue;
        }
      } else {
        reasonNotComplete.push(`${field.keyName} is undefined`);
        continue;
      }
    }
  } catch (error) {
    reasonNotComplete.push(`error while loopCheckComplete: ${error}`);
  }
  return reasonNotComplete.length === 0;
};

export const checkAndSetStatusComplete = (partName: IncidentFormPartNames, dataToCheck: { data: any; state: string }): any => {
  const fieldsToCheck = fieldMustHave(partName);
  const isComplete = loopCheckComplete(fieldsToCheck, dataToCheck.data);
  // NOTE: change status here
  if (dataToCheck.state !== 'complete' && isComplete) {
    dataToCheck.state = 'complete';
  }
  if (dataToCheck.state === 'complete' && !isComplete) {
    dataToCheck.state = 'updated';
  }
  return dataToCheck;
};
