/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-useless-escape */

import {
  BodyUploadLegalFile,
  GlobalStore,
  LegalFileSchema,
  LegalLorSchema,
  LegalLorTypeSchema,
  LegalRelatedBuListSchema,
  LegalStore,
  StoreStateV3,
} from '@/src/types';
import React, { Component } from 'react';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Logger } from '@/logger/index';
import { validFileSize, exportLinkV2 } from '@/src/utils';
import { v4 } from 'uuid';
// import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import { cloneDeep, debounce, identity, pickBy } from 'lodash';
import { SERVER_API_ERROR } from '@/src/static';
import { LegalLorRequestDto } from '@/dto/index';
// import { LoadingButton } from '@mui/lab';

/**
 * regex validation
 * https://www.cluemediator.com/regular-expression-examples-in-javascript
 */
type FileAlt = File & { uidRef?: string };

interface LegalRelatedBuListSchemaAlt extends LegalRelatedBuListSchema {
  /** internal validation */
  selected?: boolean;
}

interface FileSelectUpload {
  file: FileAlt;
  state: StoreStateV3;
  uploaded: LegalFileSchema | undefined;
}

type LoadStatus = 'initial' | 'ready' | 'submit' | 'loading' | 'error';

interface FormProps extends LegalStore {
  relatedBuData: LegalRelatedBuListSchemaAlt[];
  loadStatus: LoadStatus;
  editUid?: string;
  editData?: LegalLorSchema;

  /**
   * edit can be a draft from which you can publish
   * create can be a publish if you dont draft first!
   * draft can be edit work in the same way
   * */
  mode: 'create' | 'edit';
  noStatusChange: boolean;

  onClose: () => void;
  onSubmit: (data, submitType: 'submit-publish' | 'submit-draft', uid?: string) => void;

  submitType?: 'submit-publish' | 'submit-draft';
  onUpdate?: (status: 'success' | 'error' | 'file-upload-error') => void;
}

interface FormState extends LegalLorRequestDto {
  mounted: boolean;
  loadStatus?: LoadStatus;
  noStatusChange: boolean;
  [name: string]: any;
  onSubmit?: (data) => void;
  error?: Error | undefined;
  relatedBu: LegalRelatedBuListSchemaAlt[];
  fileSelectUpload: FileSelectUpload;
}

interface FormErrors {
  [name: string]: any;
}

const lorTypeList: LegalLorTypeSchema[] = [
  { value: 1, label: 'Environment' },
  { value: 2, label: 'Security' },
  { value: 3, label: 'Safety' },
  { value: 4, label: 'Health' },
];

interface FormRefs {
  [name: string]: HTMLDivElement & { error?: boolean; value?: string };
}
export default class LegalForm extends Component<FormProps & GlobalStore, {}, any> {
  formRefs: FormRefs = {};
  /**
   * initiate debounced callback
   */
  debouncedCallBack = debounce(
    (call: () => void) => {
      call();
    },
    200
    // { maxWait: 1000 }
  );

  // we only need one ref as we only upload one file
  fileUploadRef: string = v4();
  formRef: HTMLFormElement & { submitType: 'submit-publish' | 'submit-draft' } = undefined as any;
  allRequired = false;
  state: FormState;
  /** how many times was set */
  formIndex = 0;

  /** initial data for the form **/
  formData: LegalLorRequestDto = {} as any;
  /** form errors has the same structure as TrainingRequestDto, but it provides errors on each property */
  formErrors: FormErrors = {} as any;
  constructor(props) {
    super(props);

    if (this.props.mode === 'edit' && !this.props.editUid) {
      throw new Error('[FormErrors] in edit mode you should provide [editUid]');
    }

    if (this.props.mode === 'create') {
      const useMockData = false;
      this.formData = new LegalLorRequestDto(null as any, useMockData);
    } else if (this.props.mode === 'edit') {
      this.fileUploadRef = this.props.editUid as any;
    }

    this.state = {
      mounted: false,
      loadStatus: this.props?.loadStatus,
      error: this.props.loadStatus === 'error',
      submitType: undefined,
      ...(this.formData as any),
    };
  }

  componentDidMount() {
    if (this.state.loadStatus === 'ready' && this.props.mode === 'edit' && this.props.editData) {
      this.formData = new LegalLorRequestDto(this.props.editData || ({} as any));
      this.formData.relatedBu.forEach((n: any) => {
        n.selected = true;
      });

      this.props.relatedBuData.forEach((x) => {
        if (this.formData.relatedBu.filter((y) => y.buCode === x.buCode).length) {
          x.selected = true;
        }
      });

      const file = this.formData?.file;
      let fileSelectUpload: FileSelectUpload = null as any;
      if (file) {
        fileSelectUpload = {
          file: null,
          state: 'ready',
          uploaded: file,
        } as any;
      }

      this.setState({
        ...cloneDeep(this.state),
        ...this.formData,
        mounted: true,
        ...(fileSelectUpload ? { fileSelectUpload } : {}),
        ...(this.props.editData?.file ? { file: { uid: this.props.editData.file.uid } } : {}),
      });
    } else {
      this.setState({
        mounted: true,
        lorTypes: [{ value: 1, label: 'Environment' }],
      });
    }
  }

  hasErrors(required = false): boolean {
    let errors = false;
    if (this.formRefs?.code?.error === true) errors = true;
    if (this.formRefs?.relatedBu?.error === true && required) errors = true;
    return errors;
  }

  evaluateRequiredFields() {
    const formRefs: any = this.formRefs;
    if (!this.state.code) {
      formRefs.code = {
        ...(formRefs.code || {}),
        error: true,
      };
    }
    if (!this.state.relatedBu?.length) {
      formRefs.relatedBu = {
        ...(formRefs.relatedBu || {}),
        error: true,
      };
    }
    Object.assign(this.formRefs, formRefs);
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  get legalStore() {
    return this.props.store;
  }

  onRemoveFile = (e: any) => {
    const uploaded = this.state.fileSelectUpload?.uploaded;
    if (!uploaded) return;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.fileSelectUpload.state = 'loading';
    this.setState({
      fileSelectUpload: {
        ...this.state.fileSelectUpload,
      },
    });
    this.legalStore.setLegalFileRemove(uploaded.uid, 'NO_STORE_UPDATE', (ok, data) => {
      if (ok) {
        if (data?.idRef === uploaded.uid) {
          this.setState({
            file: null, // << form value
            fileSelectUpload: {
              file: null as any,
              state: 'ready',
            },
          });
          return;
        } else {
          Logger(['[LegalForm][setLegalFileRemove]', 'did not match: idRef !==uid'], 'error');
          return;
        }
      } else {
        Logger(['[LegalForm][setLegalFileRemove]', 'did not upload'], 'error');
      }
      // in this case maybe the file was already deleted so clear it any way
      this.setState({
        file: null, // << form value
        fileSelectUpload: {
          file: null as any,
          state: 'ready',
        },
      });
    });
  };

  onFileUpload = (e: any) => {
    const file: FileAlt = e.target.files[0];

    if (!validFileSize(file, 5)) {
      Logger(['[LegalForm][onFileUpload]', 'file too big'], 'error');
      return;
    }

    if (file !== undefined) {
      this.setState({
        fileSelectUpload: {
          file: file,
          state: 'loading',
        },
      });
      const formData: FormData = new FormData();
      formData.append('file', file as any);

      const body: BodyUploadLegalFile = {
        file: formData,
      };

      this.legalStore.setLegalFileUpload(body, this.fileUploadRef, 'NO_STORE_UPDATE', (ok, data) => {
        if (ok) {
          const legalFileUploadData = data;

          this.setState({
            file: { uid: legalFileUploadData?.uid }, // << form value
            fileSelectUpload: {
              file: this.state.fileSelectUpload?.file,
              state: 'ready',
              uploaded: legalFileUploadData,
            },
          });
          // this.props.notify.changeNotificationStatus('uploaded');
          if (this.props.onUpdate) this.props.onUpdate('success');
          return;
        } else {
          Logger(['[LegalForm][setLegalFileUpload]', 'did not upload'], 'error');
          if (this.props.onUpdate) this.props.onUpdate('error');
          //  this.props.notify.changeNotificationStatus('upload-error');
        }

        this.setState({
          file: null, // << form value
          fileSelectUpload: {
            file: null,
            state: 'ready',
            uploaded: null,
          },
        });
      });
    } else {
      Logger(['[LegalForm][onFileUpload]', 'no file set nothing uploaded'], 'error');
      if (this.props.onUpdate) this.props.onUpdate('error');
      //  this.props.notify.changeNotificationStatus('upload-error');
    }
  };

  /**
   * TODO since we now have upload, the onsubmit will not work, we need to change the behavior to on click
   * - because upload hijacks the submit for every upload
   */
  submit(e: React.FormEvent<HTMLFormElement>, isInvalid = false) {
    const stateCopy = cloneDeep(this.state);

    // in edit mode we need id
    if (this.props.editUid) stateCopy.id = this.props.editUid;

    let formData = new LegalLorRequestDto(stateCopy as any);
    formData = pickBy(formData, identity) as any;

    // NOTE submitter?.name is not available from <Form onInvalid callback
    let submitType: 'submit-publish' | 'submit-draft' | 'invalid' = this.formRef.submitType;

    const invalid = !formData.code;
    if (invalid) submitType = 'invalid';

    // if (submitType === 'submit-publish') {
    //   //   if (!(formData.employeeIds || []).length) {
    //   //     submitType = 'invalid';
    //   //   }
    // }

    switch (submitType) {
      case 'submit-draft': {
        formData.status = 1;
        if (this.props.editUid) formData.id = this.props.editUid;
        else if (formData.id) delete formData.id;

        this.evaluateRequiredFields();
        if (this.hasErrors(false)) {
          e.preventDefault();
          return false;
        }
        // NOTE to be able to send draft data while not all props are valid we have to disable preventDefault not to show warning, then we manually validate for minimum requirement to draft submit
        this.setState({ loadStatus: 'submit' });
        this.props.onSubmit(formData, 'submit-draft', (this.props.editUid || null) as any);

        e.preventDefault();
        formData.status = 1;
        return false;
      }

      case 'submit-publish': {
        formData.status = 2;
        if (this.props.editUid) formData.id = this.props.editUid;
        else if (formData.id) delete formData.id;

        this.evaluateRequiredFields();
        if (this.hasErrors(true)) {
          this.setState({ loadStatus: 'ready' }); // need to call it to update state in render
          e.preventDefault();
          return false;
        }
        this.setState({ loadStatus: 'submit' });
        this.props.onSubmit(formData, 'submit-publish', this.props.editUid);

        e.preventDefault();

        return false;
      }
      case 'invalid': {
        e.preventDefault();

        Logger(['[LegalForm][onSubmitValidate][invalid]', formData], 'error');
        return false;
      }
      default:
        e.preventDefault();
        Logger(['[LegalForm][onSubmitValidate]', 'no match'], 'warn');
    }

    return false;
  }

  render() {
    if (!this.state.mounted || !this.props.relatedBuData?.length) {
      return <CircularProgress />;
    } else if (this.state.error) {
      return <Alert severity="error">{SERVER_API_ERROR}</Alert>;
    } else if (this.state.loadStatus === 'loading' || this.state.loadStatus === 'initial') {
      return <CircularProgress className="mt-5" />;
    }

    return (
      <form
        ref={(ref) => (this.formRef = ref as any)}
        onSubmit={(e) => {
          this.submit(e);
        }}
        onInvalid={(e) => {
          this.submit(e, true);
        }}
      >
        <Box className="w-full flex">
          <Box className="w-1/2 border-0 border-r border-dashed border-gray-300 pr-8 pt-7">
            <Box className="flex">
              <FormControl className="mb-6 w-full">
                <TextField
                  ref={(r) => {
                    this.formRefs.code = r as any;
                  }}
                  required
                  onChange={(e) => {
                    const reg = new RegExp(/^[\u0E00-\u0E7Fa-zA-Z0-9-_|/]+$/);
                    if (!reg.test(e.target.value) || (e.target.value || '').length > 50) this.formRefs.code.error = true;
                    else this.formRefs.code.error = false;
                    this.formRefs.code.value = e.target.value;
                  }}
                  onBlur={(e) => {
                    this.setState({ code: e.target.value || null });
                  }}
                  error={this.formRefs.code?.error === true}
                  defaultValue={this.state.code}
                  InputLabelProps={{ shrink: true }}
                  label="Code"
                  placeholder="Please enter code (example-123/a)"
                />
              </FormControl>
              <FormControl className="mb-6 w-full ml-3">
                <InputLabel id="company-label">Type</InputLabel>
                <Select
                  className="bg-white"
                  displayEmpty
                  defaultValue={(this.state?.lorTypes || [])[0]?.value}
                  onChange={(e: any) => {
                    const ee: PointerEvent = e as any;
                    const d = [...lorTypeList.filter((n) => n.value === (ee.target as any).value)].filter((n) => !!n).filter((n) => !!n);
                    this.setState({ lorTypes: d });
                  }}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => {
                    const s = lorTypeList.filter((n) => n.value === selected);
                    if (!s.length) {
                      return <>Select</>;
                    }
                    return s.map((n) => n.label)?.join(', ');
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {lorTypeList.map((n) => (
                    <MenuItem key={n.value} value={n.value}>
                      <ListItemText primary={n.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <FormControl className="mb-6 w-full">
              <TextField
                defaultValue={this.state.name}
                onBlur={(e) => {
                  this.setState({ name: e.target.value || null });
                }}
                InputLabelProps={{ shrink: true }}
                label="Name"
                placeholder="Please enter name"
              />
            </FormControl>
            <FormControl className="mb-6 w-full">
              <TextField
                defaultValue={this.state.summary}
                onBlur={(e) => {
                  this.setState({ summary: e.target.value || null });
                }}
                className="p-0"
                InputLabelProps={{ shrink: true }}
                label="Summary"
                placeholder="Please enter summary detail"
                multiline
                minRows={3}
                maxRows={8}
              />
            </FormControl>
            <FormControl className="mb-6 w-full">
              <TextField
                defaultValue={this.state.actions}
                onBlur={(e) => {
                  this.setState({ actions: e.target.value || null });
                }}
                InputLabelProps={{ shrink: true }}
                label="Required actions"
                placeholder="Please enter Required actions"
                multiline
                minRows={3}
                maxRows={8}
              />
            </FormControl>
          </Box>
          <Box className="w-1/2 pl-8 pt-7">
            <FormControl className="mb-6 w-full">
              <TextField
                defaultValue={this.state.hierarchy}
                onBlur={(e) => {
                  this.setState({ hierarchy: e.target.value || null });
                }}
                InputLabelProps={{ shrink: true }}
                label="Hierarchy"
                placeholder="Please enter text"
              />
            </FormControl>
            <FormControl className="mb-6 w-full">
              <InputLabel id="company-label">Related company</InputLabel>
              <Select
                ref={(r) => {
                  this.formRefs.relatedBu = r as any;
                }}
                error={this.formRefs?.relatedBu?.error === true}
                className="bg-white"
                multiple
                displayEmpty
                defaultValue={(this.state?.relatedBu || []).map((n) => n.buCode)}
                onChange={(e) => {
                  const ee: PointerEvent = e as any;
                  const values: string[] = (ee.target as any)?.value || [];
                  const matched = this.props.relatedBuData.filter((n) => values.filter((x) => n.buCode === x).length);
                  if (!matched.length) this.formRefs.relatedBu.error = true;
                  else this.formRefs.relatedBu.error = false;
                  this.setState({ relatedBu: matched });
                }}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => {
                  const s = this.props.relatedBuData.filter((n) => selected.filter((x) => x === n.buCode).length);
                  if (!s.length) {
                    return <>Select</>;
                  }
                  return s
                    .filter((n) => n.selected)
                    .map((n) => n.buName)
                    ?.join(', ');
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {this.props.relatedBuData.map((n, inx) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        //  n.selected = !n.selected;

                        this.props.relatedBuData.forEach((x) => {
                          if (x.buCode === n.buCode) {
                            x.selected = !x.selected;
                          }
                        });
                      }}
                      key={n.buCode + `${inx}`}
                      value={n.buCode}
                    >
                      <Checkbox checked={(this.state.relatedBu || [])?.filter((x) => x.buCode === n.buCode && n.selected).length > 0} />
                      <ListItemText primary={n.buName} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box>
              <FormControl className="mb-6 w-full">
                <InputLabel id="company-label">LOR attachment</InputLabel>

                <Box className="flex ">
                  {
                    // disabled works better :)
                  }
                  {/* {!this.state?.fileSelectUpload?.uploaded?.url && this.state?.fileSelectUpload?.state === 'loading' && (
                    <LoadingButton className="mx-2" size="small" loading variant="outlined">
                      Submit
                    </LoadingButton>
                  )} */}

                  {this.state?.fileSelectUpload?.uploaded?.url ? (
                    <>
                      <Button
                        target={`_blank`}
                        href={exportLinkV2(this.state?.fileSelectUpload?.uploaded?.url)}
                        className="p-0 min-w-0 h-auto text-black underline"
                        variant="text"
                      >
                        View
                      </Button>
                      <Typography className="px-2">|</Typography>
                      <Button
                        disabled={this.state?.fileSelectUpload?.state === 'loading'}
                        onClick={(e) => {
                          e.preventDefault();
                          this.debouncedCallBack(() => {
                            this.onRemoveFile(e);
                          });
                          e.stopPropagation();
                          return false;
                        }}
                        className="p-0 min-w-0 h-auto underline"
                        color="error"
                        variant="text"
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <Button
                      disabled={this.state?.fileSelectUpload?.state === 'loading'}
                      variant="text"
                      size="small"
                      className="px-0 underline min-w-0 w-fit"
                      component="label"
                    >
                      <input
                        hidden
                        multiple
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        type="file"
                        onChange={(e: any) => {
                          e.preventDefault();
                          this.onFileUpload(e);
                          this.debouncedCallBack(() => {
                            this.onFileUpload(e);
                          });

                          e.stopPropagation();
                          return false;
                        }}
                        onClick={(e: any) => {
                          // e.preventDefault();
                          // e.stopPropagation();
                          return false;
                        }}
                      />
                      Upload
                    </Button>
                  )}
                </Box>
              </FormControl>
            </Box>
            <Box>
              <FormControl className="mb-6 w-1/2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableMaskedInput
                    label="Promulgation date"
                    views={['year', 'month', 'day']}
                    inputFormat="dd MMM yyyy"
                    value={this.state.promulgationDate}
                    onChange={(newValue) => {
                      this.setState({ promulgationDate: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} className="calenderIcon" />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <Box>
              <FormControl className="mb-6 w-1/2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableMaskedInput
                    label="Effective date"
                    views={['year', 'month', 'day']}
                    inputFormat="dd MMM yyyy"
                    value={this.state.effectiveDate}
                    onChange={(newValue) => {
                      Logger(['[LegalForm][onChange][effectiveDate]', newValue]);
                      this.setState({ effectiveDate: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} className="calenderIcon" />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <DialogActions className="px-8 pt-7 pb-0 justify-start border-0 border-t border-solid border-gray-300" sx={{ margin: '0 -30px' }}>
          <Button
            disabled={!this.state.code || this.state.loadStatus === 'submit' || this.hasErrors(false)}
            name="submit-draft"
            type="submit"
            onClick={() => {
              this.formRef.submitType = 'submit-draft';
            }}
            className="mr-1 w-28"
            variant="contained"
          >
            {this.props.noStatusChange ? 'Update' : 'Save draft'}
          </Button>
          <Button
            type="button"
            className="w-28 mr-auto"
            variant="outlined"
            onClick={() => {
              this.props.onClose();
            }}
          >
            Close
          </Button>
          {!this.props.noStatusChange && (
            <Button
              disabled={!this.state.code || this.state.loadStatus === 'submit' || this.hasErrors(true)}
              name="submit-publish"
              type="submit"
              onClick={() => {
                this.formRef.submitType = 'submit-publish';
              }}
              color="success"
              className="mr-1 w-28 text-white"
              variant="contained"
            >
              Publish
            </Button>
          )}
        </DialogActions>
      </form>
    );
  }
}
