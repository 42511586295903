/** declare only http service related requests */

import { AuthLogoutSchema, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Verify logged in user by jwt token from session/cookie
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const AuthLogoutPost = (prefix: string = 'auth/verify', tokens: HttpTokens, hardRejection = true): Promise<AuthLogoutSchema> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT + 5000, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .post(prefix, {}, config)
    .then((d: AxiosResponse<AuthLogoutSchema>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<AuthLogoutSchema>;
};
