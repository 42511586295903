import React, { useRef } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, TextField, InputLabel, TextFieldProps } from '@mui/material';
import { IncidentFormStoreParts, IncidentStore } from '@/src/types';
import { textFieldProps } from '@/utils/form/index';
import { TagsInputIncident } from '@/src/components';

interface PartThreeSectionOneProps {
  formStore: IncidentFormStoreParts;
  incidentStore: IncidentStore;
  disabledInput: boolean;
}

export const PartThreeSectionOne = (props: PartThreeSectionOneProps) => {
  const {
    formStore: { formStore },
    incidentStore: { store: incidentStore },
    disabledInput,
  } = props;

  const injuredData = formStore.store.formData.injured?.data || {};
  // injuredData.injuryTypes
  // injuredData.injuryTypeOther
  // injuredData.bodyRightTypes
  // injuredData.bodyLeftTypes

  const handleChangeInjuredData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('injured', 'updated').setup(value, keyName, subKeyName);
  };

  const handleMultiSelection = (type: 'select' | 'delete', value: any, keyName: string) => {
    if (type === 'select') {
      handleChangeInjuredData(value.length > 0 ? value : null, keyName);
    }
    if (type === 'delete') {
      const newValueArray = injuredData[keyName].filter((item) => item.value !== value);
      handleChangeInjuredData(newValueArray.length > 0 ? newValueArray : null, keyName);
    }
  };

  const [isCheckTypeOther, setIsCheckTypeOther] = React.useState<boolean>(false);
  const refTypeOther = useRef<TextFieldProps>(null);
  const handleCheckboxTypeOther = (checked: boolean) => {
    if (!checked) handleChangeInjuredData(null, 'injuryTypeOther');
    if (refTypeOther.current) refTypeOther.current.value = null;
  };

  const [mount, setMount] = React.useState<number>(0);
  React.useEffect(() => {
    if (formStore.store.formData.injured.state === 'updated' && formStore.store.formData.injured.data && !mount) {
      if (formStore.store.formData.injured.data.injuryTypeOther) setIsCheckTypeOther(true);
      setMount(1);
    }
  }, [formStore.store.formData.injured]);

  React.useEffect(() => {
    if (disabledInput && isCheckTypeOther) {
      setIsCheckTypeOther(false);
      if (refTypeOther.current) refTypeOther.current.value = null;
    }
  }, [disabledInput]);

  return (
    <>
      <Box className="grid grid-cols-5 gap-6">
        <Box className="col-span-3 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Type</InputLabel>
            <TagsInputIncident
              selectKeyName="injuryTypes"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.injuryTypes || ([] as { value: number; label: string }[])}
              selectedData={injuredData?.injuryTypes || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
              disabledInput={disabledInput}
            />
          </FormControl>
        </Box>
        <Box className="col-span-2">
          <FormControl className="flex flex-row mt-6">
            <FormControlLabel
              control={
                <Checkbox
                  name="other"
                  checked={isCheckTypeOther}
                  onChange={(e) => {
                    setIsCheckTypeOther(e.target.checked);
                    handleCheckboxTypeOther(e.target.checked);
                  }}
                  disabled={disabledInput}
                />
              }
              label="Other"
            />
            <TextField
              inputRef={refTypeOther}
              className="w-full"
              id="outlined-basic"
              variant="outlined"
              placeholder="Please enter detail"
              disabled={!isCheckTypeOther || disabledInput}
              onBlur={(e) => handleChangeInjuredData(e.target.value, 'injuryTypeOther')}
              {...textFieldProps(injuredData, 'injuryTypeOther')}
            />
          </FormControl>
        </Box>
      </Box>

      <hr className="border-dashed border border-gray-200 my-5" />
      <Box className="grid grid-cols-5 gap-6">
        <Box className="col-span-3 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Body type - Right</InputLabel>
            <TagsInputIncident
              selectKeyName="bodyRightTypes"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.bodyRightTypes || ([] as { value: number; label: string }[])}
              selectedData={injuredData?.bodyRightTypes || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
              disabledInput={disabledInput}
            />
          </FormControl>
        </Box>
      </Box>
      <hr className="border-dashed border border-gray-200 my-5" />
      <Box className="grid grid-cols-5 gap-6">
        <Box className="col-span-3 relative">
          <FormControl className="w-full">
            <InputLabel shrink>Body type - Left</InputLabel>
            <TagsInputIncident
              selectKeyName="bodyLeftTypes"
              dropdownData={incidentStore.incidentQueryTypesData?.data?.data?.bodyLeftTypes || ([] as { value: number; label: string }[])}
              selectedData={injuredData?.bodyLeftTypes || ([] as { value: number; label: string }[])}
              onSelection={handleMultiSelection}
              disabledInput={disabledInput}
            />
          </FormControl>
        </Box>
      </Box>
      <hr className="border-dashed border border-gray-200 my-5" />
    </>
  );
};
