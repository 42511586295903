import { UserAgentApplication, Logger, LogLevel } from 'msal';
import { ENVS_CONFIG } from '@/constants/index';
import { APPLICATION_NAME } from './../../static/index';
import { Logger as Log } from '@/logger/index';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
export const useRedirectFlow = isIE || isEdge || isFirefox;

export const requiresInteraction = (errorMessage) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return errorMessage.indexOf('consent_required') > -1 || errorMessage.indexOf('interaction_required') > -1 || errorMessage.indexOf('login_required') > -1;
};

export const fetchMsGraph = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.json();
};

export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  MAIL_READ: 'Mail.Read',
};

export const GRAPH_ENDPOINTS = {
  ME: `${ENVS_CONFIG.GRAPH_ENDPOINT}/v1.0/me`,
  MAIL: `${ENVS_CONFIG.GRAPH_ENDPOINT}/v1.0/me/messages`,
};

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
    /** these your redirect url that you asked for, after app authenticated, will redirect to this destination */
    state: encodeURIComponent(window.location.href),
  },
  EMAIL: {
    scopes: [GRAPH_SCOPES.MAIL_READ],
  },
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: ENVS_CONFIG.CLIENT_ID,
    authority: `${ENVS_CONFIG.AAD_ENDPOINT}/${ENVS_CONFIG.TENANT_ID}`,
    redirectUri: ENVS_CONFIG.REACT_APP_BASE_URL + `${ENVS_CONFIG.REDIRECT_URI_ENDPOINT}`,
    validateAuthority: true,
    postLogoutRedirectUri: ENVS_CONFIG.REACT_APP_BASE_URL,
    navigateToLoginRequestUrl: false,
    state: encodeURIComponent(window.location.href),
  },
  cache: {
    // cacheLocation: 'localStorage',
    // storeAuthStateInCookie: true, //  useRedirectFlow, // Set this to "true" if you are having issues on IE11 or Edge

    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true, // /useRedirectFlow, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    navigateFrameWait: 400,
    logger: new Logger(
      (logLevel, message) => {
        Log(['[azure][notice]', message], message, 'log');
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: true,
      }
    ),
    telemetry: {
      applicationName: APPLICATION_NAME,
      applicationVersion: '1.x.x',
      telemetryEmitter: (events) => {
        Log(['[azure][Telemetry][Events]'], events, 'log');
      },
    },
  },
});
