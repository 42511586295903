import { iENV, CONFIG_ENVS } from '../types';

/** React environment variables */
export const ENVS: iENV = {
  NODE_ENV: (process.env.NODE_ENV || '') as any,
  ALLOW_BY_PASS: true,
  // ALLOW_BY_PASS: process.env.REACT_APP_ALLOW_BY_PASS ? Number(process.env.REACT_APP_ALLOW_BY_PASS) === 1 : false,
  ENVIRONMENT: process.env?.REACT_APP_ENVIRONMENT as any,
  API_BASE: process.env?.REACT_APP_API_BASE || '',
  API_PREFIX: process.env?.REACT_APP_API_PREFIX || '',
  BASE_URL: process.env?.REACT_APP_BASE_URL || '',
  ASSETS_PATH: process.env?.REACT_APP_ASSETS_PATH || '',
  MOCK_SERVER_PORT: (Number(process.env?.REACT_APP_MOCK_SERVER_PORT) || null) as any,
  MOCK_SERVER: (process.env?.REACT_APP_MOCK_SERVER || '0') as any,
};

export const SERVER_TO_CLIENT_ERROR = 'Something went wrong. Please try again later.';
/**
 * craco exported config via webpack extension, no need to use prefix: REACT_APP_   nice!!
 * - all env values are exported here
 */
export const ENVS_CONFIG: CONFIG_ENVS = (() => {
  try {
    return require('config') as any;
  } catch (err) {}
})();

export * from './common';
