import { ProfileMyTrainingStatus, ProfileMyTrainingSchema } from '@/src/types';

export const incidentListMock = [
  {
    date: new Date(),
    incidents: 'Injury, Property damage',
    incidentId: '1',
  },
  {
    date: new Date(),
    incidents: 'Property damage',
    incidentId: '2',
  },
  {
    date: new Date(),
    incidents: 'Injury',
    incidentId: '3',
  },
  // {
  //   date: new Date(),
  //   incidents: 'Injury',
  //   incidentId: '3',
  // },
  // {
  //   date: new Date(),
  //   incidents: 'Injury',
  //   incidentId: '3',
  // },
  // {
  //   date: new Date(),
  //   incidents: 'Injury',
  //   incidentId: '3',
  // },
];

function createMyTraining(
  startDate: string | Date,
  endDate: string | Date,
  trainingId: string,
  attendeeId: string,
  testLinks: {
    preTest: string;
    postTest: string;
  },
  certificate: string,
  status: ProfileMyTrainingStatus,
  courseName: string,
  evaluation: string
): ProfileMyTrainingSchema {
  return { startDate, endDate, trainingId, attendeeId, testLinks, certificate, status, courseName, evaluation };
}
const example = [1, 1, 2, 2, 3, 3, 4, 4, 4, 4] as ProfileMyTrainingStatus[];

export const myTrainingProfileMockData = [
  ...example.map((i, id) =>
    createMyTraining(
      new Date(),
      new Date(),
      'trainingId ' + id,
      'attendeeId ' + id,
      { preTest: 'https://www.fb.com', postTest: 'https://www.fb.com' },
      'https://www.fb.com',
      i,
      'courseName ' + id,
      'https://www.fb.com'
    )
  ),
];
