export const overallStatusOptions = {
  tooltip: {
    trigger: 'item',
  },
  title: {
    show: false,
    textStyle: {
      color: 'grey',
      fontSize: 17,
    },
    text: 'No data',
    left: '29%',
    top: 'center',
  },
  legend: {
    itemHeight: 8,
    itemWidth: 21,
    top: 'center',
    right: '15%',
    orient: 'vertical',
    itemGap: 20,
    textStyle: {
      color: 'rgba(0, 0, 0, 1)',
    },
  },
  textStyle: {
    fontSize: 13,
  },
  // color: ['#EACD67', '#A3CF7C', '#92BDDB', '#ffc0cb'], //ATTENTION disabled evaluate status logic for now
  color: ['#A3CF7C', '#EACD67', '#92BDDB'],
  series: [
    {
      type: 'pie',
      radius: ['45%', '75%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 5,
        borderColor: '#fff',
        borderWidth: 1,
      },
      label: {
        show: false,
        position: 'center',
      },
      left: '10%',
      right: '50%',
      top: 0,
      bottom: 0,
      labelLine: {
        show: false,
      },
      colors: ['#A3CF7C', '#EACD67', '#92BDDB'],
      data: [
        // { value: 30, name: 'Completed (30)' },
        // { value: 40, name: 'In progress (40)' },
        // { value: 10, name: 'Overdue (10)' },
      ] as { value: number; name: string }[],
      orgData: [{ test: 1 }],
    },
  ],
};
