/** declare only http service related requests */

import { GetEmployeesById, HttpTokens, EmployeeByIdQuery } from '@/src/types';
import { paramsQueryString } from '@/src/utils';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description get employee by ID
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const EmployeeIdGet = (
  prefix: string = 'employee',
  employeeId: string,
  query: EmployeeByIdQuery,
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetEmployeesById> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };
  const queryUri = paramsQueryString(`${prefix}/${employeeId}`, query || {});

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetEmployeesById>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetEmployeesById>;
};
