import { IconButton, TableBody, TableCell, TableRow, Box, Table, TableContainer, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Icon } from '../../icons';
import { headCellsLegals } from '@/src/static';
import { EnhancedTableHead } from '../../tableHead';
import { NavLink } from 'react-router-dom';
import { AzureAuthProviderProps, LegalSmallSchema, PropDefaults } from '@/src/types';
import { LegalStore } from '@/types/store/index.d';
import { TableDataError, TableDataNotFound, DeleteCustomModal } from '@/src/components';
import { delay, exportLinkV2, legalIsOverdue, legalNiceType, printDate, userCanOnLegal } from '@/src/utils';
import { Logger } from '@/logger/index';
import { useGlobalContext } from '@/store/index';
import moment from 'moment';

interface Props extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
  onUpdate: (id: string) => void;
  isFiltered: boolean;
}

export function EnhancedTableLegals(props: Props & LegalStore) {
  const { currentUser } = useGlobalContext();
  const currentYear = new Date().getFullYear();
  const { onUpdate, store: legalStore, isFiltered } = props;
  const { resetStatus, notify, legalListData, legalDeleteData, setLegalDelete } = legalStore;
  const legalData = legalListData.data?.data || ([] as LegalSmallSchema[]);
  const [currentid, setCurrentid] = useState('');
  // const [selData, setSelData] = useState({});
  // const [openEdit, setOpenEdit] = useState<boolean>(false);
  // const [confirmDeleteModalState, setConfirmDeleteModalState] = useState<'INITIAL' | 'OPEN' | 'DELETE'>('INITIAL');
  const [showFullDescription, setFullDescription] = useState<any[]>([]);
  const [showFullHierarchy, setshowFullHierarchy] = useState<any[]>([]);
  const [title, setTitle] = useState('');

  const handleOpenDelete = (legalId) => {
    setCurrentid(legalId);
    setTitle('Are you sure you want to delete this Legal?');
  };

  useEffect(() => {
    if (legalDeleteData.state === 'ready') {
      delay(300).then(() => {
        notify.onStatusChange('deleted');
      });
      setCurrentid('');
      Logger(['[EnhancedTableLegals][legalDeleteData] >>>', legalDeleteData]);
    }

    if (legalDeleteData.state === 'error') {
      delay(300).then(() => {
        notify.onStatusChange('delete-error');
      });
      resetStatus('legal/delete/lor');
      Logger(['[EnhancedTableLegals][legalDeleteData] >>>', legalDeleteData], 'error');
    }
    // NOTE: to prevent Warning: Can't perform a React state update on an unmounted component.
    return () => {
      setCurrentid('');
    };
  }, [legalDeleteData.state]);

  const showFullDescriptionHandler = (code) => {
    if (showFullDescription.includes(code)) {
      setFullDescription([...showFullDescription.filter((i) => i !== code)]);
    } else {
      setFullDescription([...showFullDescription, code]);
    }
  };

  const showFullHierarchyHandler = (code) => {
    if (showFullHierarchy.includes(code)) {
      setshowFullHierarchy([...showFullHierarchy.filter((i) => i !== code)]);
    } else {
      setshowFullHierarchy([...showFullHierarchy, code]);
    }
  };

  const RenderName = (props: { row: LegalSmallSchema }) => {
    const { row } = props;
    if (!row?.shortName) return <>-</>;
    return (
      <>
        {row?.fullName?.length > 40 ? (
          <>
            {showFullDescription.includes(row.id + row.deadline) ? row?.fullName + ' ' : row?.shortName + ' ...'}
            <Button variant="text" className="p-0 min-w-0 h-auto font-normal text-gray-900" onClick={() => showFullDescriptionHandler(row.id + row.deadline)}>
              <span className="smaller">
                &nbsp;<span className="underline">{showFullDescription?.includes(row.id + row.deadline) ? 'Show less' : 'Show more'}</span>
              </span>
            </Button>
            {/* // TODO: use evaluationId after BE updated API */}
            {/* {showFullDescription.includes(row.evaluationId) ? row?.fullName + ' ' : row?.shortName + ' ...'}
            <Button
              variant="text"
              className="p-0 min-w-0 h-auto font-normal text-gray-900"
              onClick={() => showFullDescriptionHandler(row.evaluationId)}
            >
              <span className="underline">{showFullDescription?.includes(row.evaluationId) ? 'Show less' : 'Show more'}</span>
            </Button> */}
          </>
        ) : (
          row?.shortName
        )}
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small" className="legal-page-table">
          <EnhancedTableHead headCells={headCellsLegals} />
          {legalListData?.state === 'error' && <TableDataError col={11} description={`Please reload the page again.`} />}
          <TableBody>
            {legalData.map((row: LegalSmallSchema, index: number) => (
              <TableRow key={row.id + index} className="align-top" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className="pt-4 h-auto pr-0 td-first-icon">{row.status === 1 && <Icon icon="circle" color="#ECB623" size={8} />}</TableCell>
                <TableCell className="py-4 h-auto">{row.code}</TableCell>
                <TableCell className="py-4 h-auto">
                  <Typography className="lead">
                    <RenderName row={row} />
                  </Typography>
                </TableCell>
                <TableCell className="py-4 h-auto">{legalNiceType(row?.lorType as any)}</TableCell>
                <TableCell className="py-4 h-auto">{row?.evaluateDate ? new Date(row?.evaluateDate).getFullYear() : '-'}</TableCell>
                <TableCell className="py-4 h-auto">{printDate(row?.promulgationDate)}</TableCell>
                <TableCell className="py-4 h-auto">{printDate(row?.effectiveDate)}</TableCell>
                <TableCell className="py-4 h-auto">
                  <Typography className="lead">
                    {row.hierarchy ? (
                      row.hierarchy.length > 40 ? (
                        <>
                          {row.hierarchy.slice(0, 40)}
                          {showFullHierarchy.includes(row.id + row.deadline) ? row.hierarchy.slice(40) : null}
                          <Button
                            variant="text"
                            className="p-0 min-w-0 h-auto font-normal text-gray-900"
                            onClick={() => showFullHierarchyHandler(row.id + row.deadline)}
                          >
                            {showFullHierarchy.includes(row.id + row.deadline) ? (
                              <>
                                <span className="smaller">
                                  &nbsp;<Typography className="underline">Show less</Typography>
                                </span>
                              </>
                            ) : (
                              <Typography>
                                <span className="smaller">
                                  ...&nbsp;<span className="underline">Show more</span>
                                </span>
                              </Typography>
                            )}
                          </Button>
                        </>
                      ) : (
                        row.hierarchy
                      )
                    ) : (
                      '-'
                    )}
                  </Typography>
                </TableCell>
                <TableCell className="py-4 h-auto">
                  {(row?.relatedBu || []).map((item, index) => {
                    return index === 0 ? item.buName : ', ' + item.buName;
                  })}
                </TableCell>
                <TableCell className="py-4 h-auto">
                  {row.overdueState === 2 || row.overdueState === 3 ? (
                    <>
                      <span className="py-1 px-2 rounded overdue">Overdue</span>
                      <span className="m-2" />
                    </>
                  ) : (
                    <span
                      className={`py-1 px-2 rounded whitespace-nowrap ${
                        legalIsOverdue(row as any)
                          ? 'overdue'
                          : row.status === 1
                          ? 'draft'
                          : row.status === 2
                          ? 'published'
                          : row.status === 3
                          ? 'inProgress'
                          : row.status === 6
                          ? 'onEvaluate'
                          : 'completed'
                      }`}
                    >
                      {legalIsOverdue(row as any)
                        ? 'Overdue'
                        : row.status === 1
                        ? 'Draft'
                        : row.status === 2
                        ? 'Published'
                        : row.status === 3
                        ? 'In progress'
                        : row.status === 6
                        ? 'Evaluate'
                        : 'Completed'}
                    </span>
                  )}
                </TableCell>
                <TableCell align="right" className="h-auto td-last">
                  <Box className="flex justify-end">
                    {row.url ? (
                      <IconButton color="primary" download target={'_blank'} href={exportLinkV2(row.url)} aria-label="Download file">
                        <Icon icon="filePdf" color="#3155FF" viewBox="0 0 384 512" size={18} />
                      </IconButton>
                    ) : (
                      ''
                    )}
                    {userCanOnLegal(currentUser, row.status, 'can_edit', row as any) && (
                      <IconButton
                        color="primary"
                        aria-label="Edit details"
                        onClick={() => {
                          if (row.status === 1) {
                            onUpdate(row.id);
                          }
                        }}
                      >
                        <Icon icon="penToSquare" color="#3155FF" viewBox="0 0 576 512" size={20} />
                      </IconButton>
                    )}
                    {userCanOnLegal(currentUser, row.status, 'can_view', row as any) && (
                      <NavLink to={`/legals/detail/${row.id}?year=${moment(row.evaluateDate).isValid() ? moment(row.evaluateDate).year() : currentYear}`}>
                        <IconButton color="primary" aria-label="View details">
                          <Icon icon="eyes" color="#3155FF" viewBox="0 0 576 512" size={20} />
                        </IconButton>
                      </NavLink>
                    )}
                    {userCanOnLegal(currentUser, row.status, 'can_delete', row as any) && (
                      <IconButton color="error" aria-label="Delete data" onClick={() => handleOpenDelete(row.id)}>
                        <Icon icon="trash" color="#F14242" viewBox="0 0 448 512" size={18} />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {legalListData?.state === 'ready' && legalListData?.data?.data?.length === 0 && (
            <TableDataNotFound col={11} description={isFiltered ? 'Please change search keyword' : 'No legal information is provided to you'} />
          )}
        </Table>
      </TableContainer>
      <DeleteCustomModal
        title={title}
        description={'This action will permanently delete the Legal and cannot be undone.'}
        id={currentid}
        onAction={(id: string | null, status) => {
          if (status === 'cancel') setCurrentid('');

          const row = legalListData.data.data.filter((n) => n.id === id)[0];
          const canDelete = row && userCanOnLegal(currentUser, row.status, 'can_delete', row as any);
          if (!canDelete) {
            Logger(['[legal]DeleteCustomModal', 'cannot perform delete, no access'], 'warn');
            return;
          }

          if (status === 'confirm') {
            setLegalDelete(id as any);
            delay(200).then(() => {
              setCurrentid('');
            });
          }
        }}
      />
    </>
  );
}
