// ? THIS IS AN Attendee STORE SAMPLE CAN USE TO CREATE NEW STORE

import { useState, useEffect } from 'react';
import { AttendeeContext } from './context';
import {
  HttpTokens,
  AttendeeStore,
  QueryAttendeeList,
  QueryAttendeeSearch,
  AttendeeUpdateBodyData,
  AttendeeUploadBodyData,
  GetAttendeeList,
} from '@/src/types';
import { getJwtToken, updateAttendeeList } from '@/src/utils';
// import { Logger } from '@/logger/index';
import { ContextProviderProps } from '@/types/store';
import { AttendeeDeleteFile, AttendeeListGet, AttendeeUpdate, AttendeeUpload, AttendeeSearchGet } from '@/src/services/http';

/**
 * to give us access to azure for admin
 */

const AttendeeContextProvider = (props: ContextProviderProps) => {
  const { children } = props;
  /** Access to azure  */
  // const {} = context;
  const tokens: HttpTokens = { jwt: getJwtToken() };

  const [attendeeListData, setAttendeeList] = useState<any>({ data: undefined, state: 'initial' });
  const [allAttendeeListData, setAllAttendeeList] = useState<any>({ data: undefined, state: 'initial' });
  const [attendeeSearchData, setAttendeeSearch] = useState<any>({ data: undefined, state: 'initial' });
  const [attendeeUpdateData, setAttendeeUpdate] = useState<any>({ data: undefined, state: 'initial' });
  const [attendeeUploadData, setAttendeeUpload] = useState<any>({ data: undefined, state: 'initial' });
  const [attendeeDeleteData, setAttendeeDelete] = useState<any>({ data: undefined, state: 'initial' });

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  // TODO not yet completed
  const store: AttendeeStore = {
    store: {
      attendeeListData,
      allAttendeeListData,
      attendeeSearchData,
      attendeeUpdateData,
      attendeeDeleteData,
      attendeeUploadData,
      resetStatus: (apiName: string) => {
        if (apiName === 'training/attendees') {
          setAttendeeList({ data: undefined, state: 'initial' });
        }
        if (apiName === 'training/attendees/search') {
          setAttendeeSearch({ data: undefined, state: 'initial' });
        }
        if (apiName === 'training/attendee/upload') {
          setAttendeeUpload({ data: undefined, state: 'initial' });
        }
        if (apiName === 'training/attendee/remove-file') {
          setAttendeeDelete({ data: undefined, state: 'initial' });
        }

        if (apiName === 'training/attendee/update') {
          setAttendeeUpdate({ data: undefined, state: 'initial' });
        }
      },

      setAttendeeList: (trainingId: string, params: QueryAttendeeList) => {
        if (!isMounted) return;
        setAttendeeList({ data: undefined, state: 'loading' });
        AttendeeListGet('training/attendees', trainingId, params, tokens)
          .then((n) => {
            if (isMounted) setAttendeeList({ data: n, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) {
              setAttendeeList({ data: undefined, error: err, state: 'error' });
            }
          });
      },

      setAllAttendeeList: (trainingId: string) => {
        if (!isMounted) return;
        setAllAttendeeList({ data: undefined, state: 'loading' });
        AttendeeListGet('training/attendees', trainingId, { pageNumber: 0, itemPerPage: 9999 }, tokens)
          .then((n) => {
            if (isMounted) setAllAttendeeList({ data: n, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) {
              setAllAttendeeList({ data: undefined, error: err, state: 'error' });
            }
          });
      },

      setAttendeeSearch: (trainingId: string, params: QueryAttendeeSearch) => {
        if (!isMounted) return;
        setAttendeeSearch({ data: undefined, state: 'loading' });
        AttendeeSearchGet('training/attendees/search', trainingId, params, tokens)
          .then((n) => {
            if (isMounted) setAttendeeSearch({ data: n, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) {
              setAttendeeSearch({ data: undefined, error: err, state: 'error' });
            }
          });
      },

      setAttendeeUpdate: (id: string, body: AttendeeUpdateBodyData) => {
        if (!isMounted) return;
        setAttendeeUpdate({ data: undefined, state: 'loading' });
        AttendeeUpdate('training/attendee/update', id, body, tokens)
          .then((n) => {
            if (isMounted) {
              setAttendeeUpdate({ data: n, state: 'ready' });
              const atList: GetAttendeeList = attendeeListData.data as any;

              const { updated, data } = updateAttendeeList(atList.data, n.data);
              if (updated) {
                atList.data = data;
                setAttendeeList({ data: atList, state: 'ready' });
              }
            }
          })
          .catch((err) => {
            if (isMounted) {
              setAttendeeUpdate({ data: undefined, error: err, state: 'error' });
            }
          });
      },

      setAttendeeUpload: (id: string, body: AttendeeUploadBodyData) => {
        if (!isMounted) return;
        const ID = id;
        setAttendeeUpload({ data: undefined, state: 'loading' });
        AttendeeUpload('training/attendee/upload', id, body, tokens)
          .then((n) => {
            if (isMounted) {
              setAttendeeUpload({ data: n, state: 'ready' });
              const atList: GetAttendeeList = attendeeListData.data as any;
              let updated = false;
              const data = atList.data.map((x) => {
                if (x.id === n.data.attendeeId) {
                  x.status = 2;
                  x.certificateLink = n.data.certificateLink;
                  if (n.data.certificateLink) {
                    x.certificateFileState = 'ready';
                  }
                  updated = true;
                }
                return x;
              });
              if (updated) {
                atList.data = data;
                setAttendeeList({ data: atList, state: 'ready' });
              }
            }
          })
          .catch((err) => {
            if (isMounted) {
              setAttendeeUpload({ data: undefined, error: err, state: 'error' });
              let updated = false;
              const atList: GetAttendeeList = attendeeListData.data as any;
              const data = atList.data.map((x) => {
                if (x.id === ID) {
                  x.certificateFileState = 'error';
                  updated = true;
                }
                return x;
              });
              if (updated) {
                atList.data = data;
                setAttendeeList({ data: atList, state: 'ready' });
              }
            }
          });
      },

      setAttendeeDelete: (id: string) => {
        if (!isMounted) return;
        setAttendeeDelete({ data: undefined, state: 'loading' });
        AttendeeDeleteFile('training/attendee/remove-file', id, tokens)
          .then((n) => {
            if (isMounted) {
              setAttendeeDelete({ data: n, state: 'ready' });
            }
          })
          .catch((err) => {
            if (isMounted) {
              setAttendeeDelete({ data: undefined, error: err, state: 'error' });
            }
          });
      },
    },
  };

  // useEffect(() => {
  //   if (!parentStoreAvail) {
  //     if (parentStore) {
  //       parentStore.init.defers.forEach((n) => {
  //         n.q.resolve({ storeName: n.storeName, store: store.store });
  //       });
  //       setParentStoreAvail(true);
  //     }
  //   }
  // }, [parentStore, parentStoreAvail, setParentStoreAvail]);
  return <AttendeeContext.Provider value={store}>{children}</AttendeeContext.Provider>;
};

const withAttendeeContext = (Component: any) => {
  return function AttendeeComponent(props: any) {
    return <AttendeeContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</AttendeeContext.Consumer>;
  };
};

export { AttendeeContextProvider, withAttendeeContext };
