import { EnhancedTableActionitems, Icon } from '@/src/components';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { EmployeeSchema, LegalStore } from '@/src/types';

export const LegalEvaluationDetail = (props: LegalStore) => {
  const { store: legalStore } = props;
  const legalLorDetailData = legalStore?.legalLorDetailData?.data?.data;

  const teamMember = (employee: EmployeeSchema): string => {
    let str = '';
    if (employee?.firstName) str = employee?.firstName;
    if (employee?.lastName) str = str + ' ' + employee?.lastName;
    if (employee?.position) str = str + ' - ' + employee?.position;
    if (employee?.buName) str = str + ` (${employee?.buName})`;
    return str;
  };
  return (
    <>
      <Box className="mt-8">
        <Typography variant="h2" color="primary">
          <Icon icon="squareFull" color="#3155FF" size={10} className="mr-5" />
          Review legislation
        </Typography>

        <Box className="bg-white border border-solid border-gray-300 mt-4">
          <Box className="p-6 pb-3">
            <EnhancedTableActionitems data={legalLorDetailData?.evaluation?.actionItems} />
          </Box>
          <Box className="flex px-10 py-4 border-0 border-t border-solid border-gray-300">
            <Box className="pr-8">
              <Typography className="font-medium whitespace-nowrap">Team member</Typography>
            </Box>
            <Box className="w-full">
              <Typography className="lg:pl-6 w-full">
                {legalLorDetailData?.evaluation?.teamMembers?.length
                  ? (legalLorDetailData?.evaluation?.teamMembers || []).map((n, index: number) => {
                      return index === 0 ? teamMember(n) : ', ' + teamMember(n);
                    })
                  : '-'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="mt-6">
        <Typography variant="h2" color="primary">
          <Icon icon="squareFull" color="#3155FF" size={10} className="mr-5" />
          Report
        </Typography>
        <Box className="border border-solid border-gray-300 px-8 py-6 mt-5">
          <Typography className="leading-7">{legalLorDetailData?.evaluation?.report ? legalLorDetailData?.evaluation?.report : '-'}</Typography>
        </Box>
      </Box>
    </>
  );
};
