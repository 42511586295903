// ! api: https://fullcalendar.io/docs/react
// ! api: https://fullcalendar.io/docs/date-navigation
// time formats: https://stackoverflow.com/questions/9080944/24-hour-time-format-so-no-am-to-pm-for-fullcalendar#9088187
import React from 'react';
import { EventContentArg, ViewApi, Dictionary } from '@fullcalendar/react';

import { Icon } from '@/src/components';
import styled from '@emotion/styled';
import { TooltipProps, Tooltip, tooltipClasses, Typography, Box } from '@mui/material';
import { Logger } from '@/logger/index';
import { getCalendarViewType } from '@/src/utils';
import { CalendarEventViewType } from '@/types/common';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #4958CC',
  },
}));

export default function renderEventContent(eventInfo: EventContentArg, onEventView?: (e: CalendarEventViewType) => void, onViewApi?: (e: ViewApi) => void) {
  const data: Dictionary = eventInfo.event.extendedProps;
  const view: ViewApi = eventInfo.view;
  Logger(['[calendar][view.type]', view.type]); // dayGridMonth, timeGridWeek, timeGridDay
  // Logger(['[calendar][data]', eventInfo.event.extendedProps]);
  // Logger(['[calendar][view]', view]);
  if (typeof onEventView === 'function') onEventView(getCalendarViewType(view.type));
  if (typeof onViewApi === 'function') onViewApi(view);

  return (
    <HtmlTooltip
      placement="top-start"
      arrow
      className="calendarTooltip"
      title={
        <Box className="p-1">
          <Typography color="inherit" className="font-bold">
            {eventInfo.event.title}
          </Typography>
          <Typography color="inherit" className="mt-1 font-medium">
            <Icon icon="clock" size={14} viewBox="0 0 512 512" color="#4958CC" className="mr-2" />
            {data.time}
          </Typography>
          {!!data.location && (
            <Typography color="inherit" className="mt-1 font-medium">
              <Icon icon="locationDot" size={14} viewBox="0 0 384 512" color="#4958CC" className="mr-2" />
              {data.location}
            </Typography>
          )}
        </Box>
      }
    >
      <div className="">
        {!!data.time && <span className="startEndTime">{data.time}</span>}
        <div className="truncate">
          <span className="time">{eventInfo.timeText}</span> <span className="title">{eventInfo.event.title}</span>
          {!!data.location && (
            <div className="optional pt-2 items-center">
              <Icon icon="locationDot" size={14} viewBox="0 0 384 512" color="#4958CC" className="mr-1" />
              {data.location}
            </div>
          )}
        </div>
      </div>
    </HtmlTooltip>
  );
}
