import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React from 'react';

export function withWrapper(Ctr) {
  return function Hoc(props, context = {}) {
    const newProps = {
      ...props,
      // ... some new props
    };
    return <Ctr {...newProps} {...context} />;
  };
}

interface AppWrapperProps {
  children?: any;
  outerProps?: any;
}

export const AppWrapper: React.FC<AppWrapperProps> = ({ children, outerProps }) => {
  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider {...(outerProps as any)}>
          <>{children}</>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
};
