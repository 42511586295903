import React, { useEffect, useState } from 'react';
import { EnhancedReviewLegislationActionItemsForm, TagInputSearchEmployees } from '@/src/components';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { PropDefaults, AzureAuthProviderProps, LegalStore, EmployeeSchema, LorActionItemSchema } from '@/src/types';
import { LegalEvaluationDto } from '@/src/components/dto';
import { Logger } from '@/logger/index';
import { clone, identity, pickBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';
// import { delay } from '@/src/utils';

interface Props extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
  detailId: string;
}

export function ReviewLegislationForm(props: Props & LegalStore) {
  const [searchParams, setSearchParams] = useSearchParams();
  const year = searchParams.get('year') || '';
  const { store: legalStore, detailId } = props;
  const _evaluation = legalStore.legalLorDetailData?.data?.data?.evaluation;
  const [optionData, setOptionData] = useState<EmployeeSchema[]>([]);
  const [selectedInvestigators, setSelectedInvestigators] = useState<EmployeeSchema[]>(
    legalStore.legalLorDetailData.data?.data?.evaluation?.teamMembers || ([] as any)
  );
  const [actionItems, setActionItems] = useState<LorActionItemSchema[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorSearchEmployees, setErrorSearchEmployees] = useState<boolean>(false);
  const [evaluationData, setDetails] = useState<LegalEvaluationDto>(new LegalEvaluationDto((_evaluation || {}) as any));

  useEffect(() => {
    if (legalStore.legalEvaluationData.state === 'ready' && !legalStore.legalEvaluationData.checked) {
      legalStore.setLegalLorDetail(detailId, { year }, 'update', null as any, legalStore.legalEvaluationData.ref);
      legalStore.setLegalLorProgress(detailId, { year: year });
      Logger(['[legalStore.legalEvaluationData]', legalStore.legalEvaluationData]);

      if (legalStore.legalEvaluationData.ref === 'data-updated') {
        legalStore.notify.onStatusChange('updated');
      }

      if (legalStore.legalEvaluationData.ref === 'data-submitted') {
        legalStore.notify.onStatusChange('submit');
      }
      legalStore.legalEvaluationData.checked = true;
      return;
    }

    if (legalStore.legalEvaluationData.state === 'error' && !legalStore.legalEvaluationData.checked) {
      if (legalStore.legalEvaluationData.ref === 'data-submitted-error') {
        legalStore.notify.onStatusChange('submit-error');
      }

      if (legalStore.legalEvaluationData.ref === 'data-updated-error') {
        legalStore.notify.onStatusChange('update-error');
      }

      legalStore.legalEvaluationData.checked = true;
      Logger(['[legalStore.legalEvaluationData]', legalStore.legalEvaluationData], 'error');
    }
  }, [legalStore]);

  const submitHandler = (type: 'draft' | 'approve') => {
    const data = evaluationData;
    // BUG backend need to make sure status for evaluation cross/over and update correctly to @api /api/legal/lor/{uid}?year=
    // if (data.status === 5) {
    //   Logger(['[ReviewLegislationForm][submit]', `cannot submit again already completed, status:${data.status}`], 'error');
    //   return;
    // }
    if (type === 'draft') data.status = 3;
    if (type === 'approve') data.status = 5;
    data.teamMembers = selectedInvestigators?.length ? selectedInvestigators.map((n) => n.employeeId) : (null as any);
    data.actionItems = actionItems?.length ? actionItems : (null as any);
    data.evalStartYear = year;
    const d = new LegalEvaluationDto(clone(data));
    const dd = pickBy(d, identity) as any;
    legalStore.setLegalEvaluation(detailId, dd, type === 'approve' ? 'data-submitted' : 'data-updated');
    Logger([`[ReviewLegislationForm][submit][${type}]`, dd]);
  };

  return (
    <Box className="border border-solid border-[#F2F2F2] rounded-3xl mt-8 bg-[#F5F7FF] shadow p-6">
      <Typography variant="h2" color="primary">
        Review legislation
      </Typography>
      <Box className="bg-white p-6  rounded-3xl border border-solid border-gray-300 mt-4">
        <Box className="">
          <Box className="flex">
            <img src={require('@/assets/icons/Search.png')} alt="Occupational" width={28} height={27} className="mr-3" />
            <Typography variant="h5">Select investigators</Typography>
          </Box>
          <Box className="mt-4">
            <FormControl className="w-full">
              <TagInputSearchEmployees
                optionData={optionData || ([] as EmployeeSchema[])}
                selectedData={selectedInvestigators || ([] as EmployeeSchema[])}
                isLoading={loading}
                isErrorSearch={errorSearchEmployees}
                handleKeyFilter={(searchText: string) => {
                  if (searchText?.length > 0) {
                    setLoading(true);
                    legalStore.setLegalEmployeesQuery({ q: searchText }, 'NO_STORE_UPDATE', (ok, d) => {
                      if (ok) {
                        Logger(['[ReviewLegislationForm][legalStore.legalEmployeesQueryData]', d]);
                        setOptionData(d as any);
                        setErrorSearchEmployees(false);
                      } else setErrorSearchEmployees(true);
                      setLoading(false);
                    });
                    Logger(['[ReviewLegislationForm][legalStore.legalEmployeesQueryData]', searchText]);
                  }
                }}
                handleOnSelect={(selected: EmployeeSchema[]) => {
                  if (selected) setSelectedInvestigators([...selected]);
                }}
              />
            </FormControl>
          </Box>
        </Box>
        <Box className="mt-10">
          <Box className="flex">
            <img src={require('@/assets/icons/list.png')} alt="Occupational" width={28} height={27} className="mr-3" />
            <Typography variant="h5">Action items</Typography>
          </Box>
          <Box className="mt-4">
            <EnhancedReviewLegislationActionItemsForm
              data={(evaluationData.actionItems || []) as any}
              onUpdate={(d) => {
                Logger(['[EnhancedReviewLegislationActionItemsForm][onUpdate]', d]);
                setActionItems(d);
              }}
            />
          </Box>
        </Box>
        <Box className="mt-10">
          <Box className="flex">
            <img src={require('@/assets/icons/message-lines.png')} alt="Occupational" width={28} height={27} className="mr-3" />
            <Typography variant="h5">Report</Typography>
          </Box>
          <FormControl className="w-full mt-4">
            <TextField
              onBlur={(e) => {
                evaluationData.report = e.target.value;
                setDetails(evaluationData);
              }}
              defaultValue={evaluationData.report}
              id="outlined-textarea"
              placeholder="Enter text"
              multiline
            />
          </FormControl>
        </Box>
        <Box className="mt-9 flex">
          <Button
            disabled={legalStore.legalEvaluationData.state === 'loading'}
            onClick={(e) => {
              e.preventDefault();
              submitHandler('draft');
              return false;
            }}
            variant="contained"
          >
            Save
          </Button>
          <Button
            disabled={legalStore.legalEvaluationData.state === 'loading'}
            onClick={(e) => {
              e.preventDefault();
              submitHandler('approve');
              return false;
            }}
            variant="contained"
            color="success"
            className=" text-white ml-auto"
          >
            Approve
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
