export const IncidentsForms = [
  'Initial report',
  'Description',
  'Injured/Illness person data',
  'Property damage',
  'Incident investigation',
  'Incident causes assessment',
  'Incident investigation team',
  'Corrective action',
];

export const progresses = [
  {
    label: 'Reporter submit report',
  },
  {
    label: 'SSHE Admin review',
  },
  {
    label: 'Team leader review',
  },
  {
    label: 'Head of department/Co-founder review',
  },
  {
    label: 'General manager review',
  },
  {
    label: 'Closed',
  },
];

// // NOTE THIS DATA IS NO LONGER USED
// export interface IncidentsSchema {
//   id: string;
//   date: string;
//   incidentNo: string;
//   employeeName: string;
//   buName: string;
//   illnessClassification: string;
//   actions?: string;
// }

// function createIncidents(
//   id: string,
//   date: string,
//   incidentNo: string,
//   employeeName: string,
//   buName: string,
//   illnessClassification: string,
//   actions?: string
// ): IncidentsSchema {
//   return {
//     id,
//     date,
//     employeeName,
//     incidentNo,
//     buName,
//     illnessClassification,
//     actions,
//   };
// }

// export const mockupRows: IncidentsSchema[] = [
//   createIncidents('Ince-123456', '11 May 2022', 'Alicia Pattavitthayakul', 'ARV', 'Example text A', 'none'),
//   createIncidents('Ince-123457', '09 May 2022', 'Alexandra Rampling', 'Cariva', 'Example text A', 'none'),
//   createIncidents('Ince-123458', '05 May 2022', 'Alison Randall', 'Rovula', 'Example text A', 'none'),
//   createIncidents('Ince-123459', '01 May 2022', 'Amelia Ogden', 'Skyller', 'Example text A', 'none'),
// ] as IncidentsSchema[];

// // IncidentsCorrectiveAction mockup data
// export interface IncidentsCorrectiveActionSchema {
//   id: string;
//   correctiveAction?: string;
//   actionParty?: string;
//   responsibility?: string;
//   date?: string;
//   file?: string;
//   actions?: string;
// }

// function createIncidentsCorrectiveAction(
//   id: string,
//   correctiveAction?: string,
//   actionParty?: string,
//   responsibility?: string,
//   date?: string,
//   file?: string,
//   actions?: string
// ): IncidentsCorrectiveActionSchema {
//   return {
//     id,
//     correctiveAction,
//     actionParty,
//     responsibility,
//     date,
//     file,
//     actions,
//   };
// }

// export const mockupIncidentsCorrectiveActionScRows: IncidentsCorrectiveActionSchema[] = [
//   createIncidentsCorrectiveAction(
//     '1',
//     'Lorem ipsum dolor sit amet, consect eturadi piscing elit. Vestibulum a vestibulum mauris.',
//     'Consect eturadi piscing elit.',
//     'Lorem ipsum dolor sit amet, consect',
//     '30 Jun 2022',
//     '30 Jun 2022',
//     'none'
//   ),
//   createIncidentsCorrectiveAction(
//     '2',
//     'Lorem ipsum dolor sit amet, consect eturadi piscing elit. Vestibulum a vestibulum mauris.',
//     'Consect eturadi piscing elit.',
//     'Lorem ipsum dolor sit amet, consect',
//     '30 Jun 2022',
//     '30 Jun 2022',
//     'none'
//   ),
// ] as IncidentsCorrectiveActionSchema[];

// export const yourTasks = [
//   {
//     label: 'Review completion of corrective action',
//     description: '',
//   },
//   {
//     label: 'Give approval or reject this incident',
//     description: 'You can add note after click on the button.',
//   },
// ];

// export const itemData = [
//   {
//     img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//     title: 'Fern',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1627308595229-7830a5c91f9f',
//     title: 'Snacks',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//     title: 'Mushrooms',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1529655683826-aba9b3e77383',
//     title: 'Tower',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//     title: 'Sea star',
//   },
// ];
