import { AzureAuthProviderProps, IncidentFileSchema, IncidentStore, PropDefaults, FileDropped } from '@/src/types';
import { Masonry } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { Icon } from '@/src/components';
import { useEffect, useState } from 'react';
import { AddImageSectionDto } from '@/src/components/dto';
import { genNewFile } from '@/src/utils';
interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export default function PartTwoReport(props: IncidentProps & IncidentStore) {
  // const { history, detailId, store } = props;
  const { store } = props;
  const [mounted, setMount] = useState<number>(0);
  const [imageArr, setImageArr] = useState<(IncidentFileSchema & { file?: FileDropped })[]>([]);
  const {
    // incidentBusinessUnitsFilter,
    // // incidentCreateUpdateData,
    // incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    // setIncidentDetail,
    incidentDetailData,
  } = store;

  const incidentDetailDataDescription = incidentDetailData?.data?.data?.description;

  useEffect(() => {
    if (!mounted) {
      if (incidentDetailData.state === 'ready') {
        if (incidentDetailDataDescription?.images) {
          const descImages: (IncidentFileSchema & { file?: FileDropped })[] = (incidentDetailDataDescription?.images || []) as any;
          if (descImages.length) {
            const newDescImages = descImages.map((n) => {
              if (!(n as any).testImage) n = new AddImageSectionDto(n);
              n.file = genNewFile(n.url, n.uid);
              return n;
            });
            setImageArr(newDescImages);
          }
        }
        setMount(1);
      }
    }
  }, [imageArr, setImageArr, mounted, setMount]);

  return (
    <>
      <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
        <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
        Description
      </Typography>
      <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:mt-5 bg-white">
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            What happened?
          </Typography>
          <Typography className="lg:pl-6 w-full">{incidentDetailDataDescription?.whatDesc ? incidentDetailDataDescription?.whatDesc : '-'}</Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Why did it happen?
          </Typography>
          <Typography className="lg:pl-6 w-full">{incidentDetailDataDescription?.whyDesc ? incidentDetailDataDescription?.whyDesc : '-'}</Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            When and Where did happen?
          </Typography>
          <Typography className="lg:pl-6 w-full">{incidentDetailDataDescription?.whenDesc ? incidentDetailDataDescription?.whenDesc : '-'}</Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            How did it happened?
          </Typography>
          <Typography className="lg:pl-6 w-full">{incidentDetailDataDescription?.howDesc ? incidentDetailDataDescription?.howDesc : '-'}</Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Who was involved?
          </Typography>
          <Typography className="lg:pl-6 w-full">
            {incidentDetailDataDescription?.whoInvolved?.length
              ? (incidentDetailDataDescription?.whoInvolved || []).map((whoInvolved: any, index: number) => {
                  const whoInvolvedLabel = `${whoInvolved.firstName} ${whoInvolved.lastName} - ${whoInvolved.position} (${whoInvolved.buName})` || '-';
                  return index === 0 ? whoInvolvedLabel : ', ' + whoInvolvedLabel;
                })
              : '-'}
          </Typography>
        </Box>
        <Box className="bg-gradient-to-r from-blue-600 to-blue-100 px-4 py-3 xl:py-4 xl:pl-10">
          <Typography className="text-white text-base">Images</Typography>
        </Box>
        <Box className="py-3 px-4 lg:px-7 border-0 border-b border-solid border-gray-200 border-r">
          {imageArr.length ? (
            <Masonry columns={{ xs: 1, sm: 2 }} spacing={3} className="m-0">
              {(imageArr || []).map((item, index) => (
                <div key={index}>
                  <img
                    src={`${item?.file?.serverUrl}?w=162&auto=format`}
                    srcSet={`${item?.file?.serverUrl}?w=162&auto=format&dpr=2 2x`}
                    alt={item?.url}
                    loading="lazy"
                    style={{
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      display: 'block',
                      width: '100%',
                    }}
                  />
                </div>
              ))}
            </Masonry>
          ) : (
            <Typography className="pl-3 mb-1 lg:mb-0 md:pl-4 w-full">-</Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
