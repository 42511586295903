/** declare only http service related requests */

import { HttpTokens, GetTrainingDetail, QueryTrainingDetail } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { paramsQueryString } from '@/utils/index';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get training detail overview,
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const TrainingDetailGet = (
  prefix: string = 'training/detail',
  id: string,
  params: QueryTrainingDetail = { status: null as any },
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetTrainingDetail> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  if (!params?.status) delete params.status;
  const queryUri = paramsQueryString(`${prefix}/${id}`, params);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetTrainingDetail>) => {
      // return Promise.reject(new Error('ups!')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetTrainingDetail>;
};
