import React, { useEffect } from 'react';
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  TextField,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IncidentFormStoreParts, IncidentStore } from '@/src/types';
import { initialReport } from '@/src/static/incidents/form.data';
import { TimePicker } from '@mui/x-date-pickers';
import { IncidentInitialReportPartial } from '@/types/schema';
import { textFieldProps } from '@/utils/form/index';

interface PartOneSectionTwoProps {
  formStore: IncidentFormStoreParts;
  incidentStore: IncidentStore;
  isReadyToSaveDraftIncident: boolean;
}

export const PartOneSectionTwo = (props: PartOneSectionTwoProps) => {
  const {
    formStore: { formStore },
    incidentStore: { store: incidentStore },
    isReadyToSaveDraftIncident,
  } = props;
  const nowDate = new Date();
  const initialReportData = formStore.store.formData.initialReport?.data || {};
  const convertToDate = (dateValue: string | Date | null): Date => {
    return dateValue ? new Date(dateValue) : nowDate;
  };
  const [valueDayIncident, setValueDayIncident] = React.useState<Date | null>(convertToDate(initialReportData.dateOfIncident));
  const [valueDayReport, setValueDayReport] = React.useState<Date | null>(convertToDate(initialReportData.dateOfReport));
  // const [valueTimeIncident, setValueTimeIncident] = React.useState<Date | null>(nowDate);
  const valueTimeIncident: Date | null = nowDate;
  // const [prevSelectedTimeIncident, setPrevSelectedTimeIncident] = React.useState<Date | null>(valueTimeIncident);
  const prevSelectedTimeIncident: Date | null = valueTimeIncident;
  // const [companyFilter, setCompanyFilter] = React.useState<BusinessUnitsSchema[]>();
  // const [company, setCompany] = React.useState<BusinessUnitsSchema>((initialReportData.company || undefined) as any);
  const [prevSelectedDayIncident, setPrevSelectedDayIncident] = React.useState<Date | null>(valueDayIncident);
  const [prevSelectedDayReport, setPrevSelectedDayReport] = React.useState<Date | null>(valueDayReport);
  const [jobClassificationValue, setJobClassificationValue] = React.useState<number>(initialReportData.jobClassification?.choice);
  const [prevJobClassificationValue, setPrevJobClassificationValue] = React.useState<number>(jobClassificationValue);
  const [JobClassificationLabel, setJobClassificationLabel] = React.useState<string | undefined>(initialReportData.jobClassification?.value);

  // useEffect(() => {
  //   setCompanyFilter(incidentStore?.incidentBusinessUnitsFilter?.data?.data);
  // }, [incidentStore?.incidentBusinessUnitsFilter?.data?.data]);

  const handleChangeInitialReportData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('initialReport', 'updated').setup(value, keyName, subKeyName);
  };

  const onChangeDayIncident = (newValue, type: string) => {
    if (type === 'date') {
      setPrevSelectedDayIncident(newValue);
    } else if (type === 'report') {
      setPrevSelectedDayReport(newValue);
    }

    if (type === 'date' && newValue !== prevSelectedDayIncident) {
      setValueDayIncident(newValue);
      handleChangeInitialReportData(newValue, 'dateOfIncident');
    } else if (type === 'report' && newValue !== prevSelectedDayReport) {
      setValueDayReport(newValue);
      handleChangeInitialReportData(newValue, 'dateOfReport');
    }
  };
  const onChangeTime = (newValue) => {
    // NOTE: time incident no need to separate local state on this component and no keyName of 'timeOfIncident'
    // setPrevSelectedTimeIncident(newValue);
    setPrevSelectedDayIncident(newValue);
    if (newValue !== prevSelectedTimeIncident) {
      // setValueTimeIncident(newValue);
      // handleChangeInitialReportData(newValue, 'timeOfIncident');
      setValueDayIncident(newValue);
      handleChangeInitialReportData(newValue, 'dateOfIncident');
    }
  };
  const handleChangeCheckbox = (checked: any, keyName: string, valueCheckbox: number) => {
    let arrayCheckbox: number[] = [];
    if (checked && keyName === 'functions') {
      arrayCheckbox = initialReportData?.functions ? [...initialReportData?.functions, valueCheckbox] : [valueCheckbox];
    } else {
      arrayCheckbox = initialReportData?.functions.filter((item) => item !== valueCheckbox);
    }
    arrayCheckbox.sort((a, b) => a - b);
    handleChangeInitialReportData(arrayCheckbox.length > 0 ? arrayCheckbox : null, keyName);
  };
  const handleChangeJobClassification = (newValue) => {
    setPrevJobClassificationValue(newValue);
    if (prevJobClassificationValue !== newValue) {
      setJobClassificationValue(newValue);
      handleChangeInitialReportData({ choice: newValue, value: JobClassificationLabel || null }, 'jobClassification');
    }
  };
  const handleChangeValueJobClassification = (newValue) => {
    if (newValue) {
      setJobClassificationLabel(newValue);
    }
    handleChangeInitialReportData({ choice: jobClassificationValue, value: newValue || null }, 'jobClassification');
  };

  const [mount, setMount] = React.useState<number>(0);
  useEffect(() => {
    if (formStore.store.formData.initialReport.state === 'pristine' && !formStore.store.formData.initialReport.data) {
      formStore.setFormParts({
        initialReport: { data: { dateOfIncident: nowDate, dateOfReport: nowDate } as IncidentInitialReportPartial, state: 'updated' },
      });
    }
    if (formStore.store.formData.initialReport.state === 'updated' && !mount) {
      const newInitialReportData = { ...formStore.store.formData.initialReport.data };
      if (!newInitialReportData.dateOfIncident) newInitialReportData.dateOfIncident = nowDate;
      if (!newInitialReportData.dateOfReport) newInitialReportData.dateOfReport = nowDate;
      formStore.setFormParts({ initialReport: { data: newInitialReportData, state: 'updated' } });
      setMount(1);
    }
  }, [formStore.store.formData.initialReport]);

  return (
    <Card variant="outlined" className=" rounded-2xl p-6 mb-6">
      <Box className="grid grid-cols-4 gap-6">
        <Box>
          <FormControl className="w-full">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableMaskedInput
                onError={(reason: any, value: Date | null) => {}}
                label="Date of incident"
                views={['year', 'month', 'day']}
                inputFormat="dd MMM yyyy"
                value={valueDayIncident}
                onChange={(newValue) => {
                  onChangeDayIncident(newValue, 'date');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                    className="calenderIcon"
                    required
                    error={!isReadyToSaveDraftIncident && !initialReportData?.dateOfIncident}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
        <Box>
          <FormControl className="w-full">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Time of incident"
                ampm={false}
                value={valueDayIncident}
                onChange={(newValue) => {
                  onChangeTime(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                    className="timeIcon"
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
        <Box>
          <FormControl className="w-full">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableMaskedInput
                label="Date of report"
                views={['year', 'month', 'day']}
                inputFormat="dd MMM yyyy"
                value={valueDayReport}
                onChange={(newValue) => {
                  onChangeDayIncident(newValue, 'report');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                    className="calenderIcon"
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
      </Box>
      <Box className="grid grid-cols-4 gap-6 mt-6">
        <Box>
          <FormControl fullWidth error={!isReadyToSaveDraftIncident && !initialReportData?.company?.buCode} required>
            <InputLabel id="company-label">Company</InputLabel>
            <Select
              labelId="company-label"
              displayEmpty
              value={(initialReportData?.company?.buCode || '') as any}
              onChange={(e) => {
                const comp = incidentStore?.incidentBusinessUnitsFilter?.data?.data?.find((n) => n.buCode === e.target.value);
                if (comp) handleChangeInitialReportData(comp, 'company');
              }}
            >
              <MenuItem disabled value="">
                Please select
              </MenuItem>
              {incidentStore?.incidentBusinessUnitsFilter?.data?.data?.map((com, inx) => (
                <MenuItem key={`${inx}+${com.buCode}`} value={com.buCode}>
                  {com.buName} {`(${com.buCode})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="col-span-2">
          <FormControl className="w-full">
            <TextField
              label="Location"
              onBlur={(e) => handleChangeInitialReportData(e.target.value || null, 'location')}
              {...textFieldProps(initialReportData, 'location')}
            />
          </FormControl>
        </Box>
        <Box className="col-span-4">
          <FormControl component="fieldset">
            <Typography className=" font-medium mb-0">Function:</Typography>
            <FormGroup aria-label="position" row>
              {initialReport?.functions?.map((functions: any, index: number) => (
                <div key={`${functions.label}-${functions.value}`}>
                  <FormControlLabel
                    value={functions.value}
                    control={
                      <Checkbox
                        onChange={(e) => handleChangeCheckbox(e.target.checked, 'functions', functions.value)}
                        checked={initialReportData?.functions?.includes(functions.value) || false}
                      />
                    }
                    label={functions.label}
                    labelPlacement="end"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                  />
                </div>
              ))}
            </FormGroup>
          </FormControl>
        </Box>
        <Box className="col-span-4">
          <FormControl>
            <Typography className=" font-medium mb-0">Job classification:</Typography>

            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
              {initialReport?.jobClassification?.map((jobClassification: { value: 1 | 2 | 3; label: string }, index: number) => (
                <div key={`${jobClassification.label}-${jobClassification.value}`}>
                  <FormControlLabel
                    value={jobClassification.value}
                    control={
                      <Radio
                        checked={initialReportData?.jobClassification?.choice === jobClassification.value}
                        onChange={(e) => handleChangeJobClassification(jobClassification.value)}
                      />
                    }
                    label={jobClassification.label}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                  />
                </div>
              ))}
            </RadioGroup>
          </FormControl>
          <FormControl className="w-full">
            <TextField
              placeholder="Please specify name"
              className="w-80"
              onBlur={(e) => handleChangeValueJobClassification(e.target.value)}
              {...textFieldProps(initialReportData, 'jobClassification', 'value')}
            />
          </FormControl>
        </Box>
      </Box>
    </Card>
  );
};
