import { SnackBarAlertWithStore } from '@/src/components';
import { PropDefaults, AzureAuthProviderProps, LegalStore } from '@/src/types';
import { withLegalContext } from '@/store/index';
import { LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Legals from './index';

interface LegalsProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

const Router = (props: any): any => {
  const { pageType } = props;
  const outletProps = {
    ...props,
  };

  switch (pageType) {
    // ATTENTION we need loading status so we only load the main page one the router is mounded
    case 'loading': {
      return <LinearProgress />;
    }
    case 'legals-parent': {
      return <Legals {...(outletProps as any)} />;
    }
    case 'detail-child': {
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }
    default: {
      // console.log('loading no route found!', pageType);
      return <>No mach for legals found</>;
    }
  }
};

const LegalsPage: React.FC<LegalsProps & LegalStore> = (props) => {
  const { detailId } = useParams();
  const [mount, setMount] = useState<number>(0);
  const { store: legalStore } = props;

  useEffect(() => {
    if (!mount) {
      if (detailId) {
        if (legalStore.legalLorDetailData.state === 'ready' || legalStore.legalLorDetailData.state === 'error') {
          // NOTE for now using static id so this does not apply
          // if (legalStore.legalLorDetailData.data?.data?.id === detailId){
          //   legalStore.resetStatus('legal/lor/detail');
          // }
          legalStore.resetStatus('legal/lor/detail');
        }
      }
      setMount(1);
    }

    // if (!detailId && !!legalStore.legalLorDetailData.idRef) {
    //   legalStore.resetStatus('legal/lor/detail');
    //   console.log('should reset!', legalStore.legalLorDetailData);
    // }
  }, [setMount, mount, legalStore]);
  const pageType: 'legals-parent' | 'detail-child' | 'loading' = [!mount && 'loading', !!detailId && 'detail-child', !detailId && 'legals-parent'].filter(
    (n) => !!n
  )[0] as any;

  return (
    <>
      <Router {...props} pageType={pageType} detailId={detailId} />
      <SnackBarAlertWithStore autoHideDurationAndClearStore={4000} storeName={['LegalStore']} store={{ store: legalStore }} />
    </>
  );
};
export default withLegalContext(LegalsPage);
