import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Step, StepLabel, Stepper, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { progresses } from '@/src/static/legal/mockupData';
import { Logger } from '@/logger/index';
import { useParams, useSearchParams } from 'react-router-dom';
import { LegalStore, LorProgressSchemaItem } from '@/src/types';
import { printDate } from '@/src/utils';
const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: 170,
  },
}));
export const ProgressBarLegal: React.FC<any> = (props: LegalStore) => {
  // const [progressBar, setProgressBar] = React.useState(33);
  const { detailId } = useParams();
  const [searhParams, setSearchParams] = useSearchParams();
  const [mount, setMount] = useState<number>(0);
  const { store: legalStore } = props;
  const { legalLorProgressData } = legalStore;
  const legalLorProgress = legalLorProgressData?.data?.data?.progress;
  const [complete, setComplete] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const calculatorProgress = (legalProgress: LorProgressSchemaItem[]) => {
    let completedCount = 0;
    let total = 0;
    if (legalProgress.length) {
      legalProgress.forEach((o) => {
        if (o.done === true) completedCount++;
        total++;
      });
      setComplete(completedCount);
      const percentage = (completedCount / total) * 100;
      setPercentage(percentage);
    } else {
      setComplete(0);
      setPercentage(0);
    }
  };

  useEffect(() => {
    if (!mount) {
      if (legalLorProgressData?.state === 'initial' && !legalLorProgressData?.data?.data && detailId) {
        legalStore.setLegalLorProgress(detailId, { year: searhParams.get('year') || undefined });
      }
      setMount(1);
    }
    if (legalLorProgressData?.state === 'ready') {
      Logger(['[LegalDetail][legalLorProgressData]', legalLorProgressData]);
    }
    if (legalLorProgressData?.state === 'error') {
      Logger(['[LegalDetail][legalLorProgressData]', legalLorProgressData], 'error');
    }
  }, [legalLorProgressData, mount, setMount]);

  useEffect(() => {
    if (legalLorProgressData?.data?.data?.progress?.length) {
      calculatorProgress(legalLorProgressData?.data?.data?.progress);
    }
  }, [legalLorProgressData, calculatorProgress]);
  return (
    <Box
      className="w-full p-6 rounded-3xl my-5 bg-gradient-to-t from-blue-100 to-white max-w-[326px]"
      sx={{
        boxShadow: '0px 3px 10px #AAAAAA29',
      }}
    >
      <Box className="flex items-center">
        <Typography variant="h2" color="primary">
          Progress
        </Typography>
        <LinearProgress className="w-full ml-4 rounded-full h-2" variant="determinate" value={percentage} />
      </Box>
      <Box className="mt-6">
        <Stepper activeStep={complete} orientation="vertical" className="progressStep">
          {(legalLorProgress || []).map((progress, index) => (
            <Step key={index}>
              {progress?.done === true ? (
                <LightTooltip
                  arrow
                  followCursor
                  title={
                    <>
                      <Typography className="text-xs text-black">{`By ${
                        (progress?.employee?.firstName ? progress?.employee?.firstName : '') +
                        ' ' +
                        (progress?.employee?.middleName ? progress?.employee?.middleName : '') +
                        ' ' +
                        (progress?.employee?.lastName ? progress?.employee?.lastName : '')
                      }`}</Typography>
                      <Typography className="text-xs text-black mt-1">{progress?.date ? printDate(progress?.date, 'DD MMM YYYY, HH:mm') : '-'}</Typography>
                    </>
                  }
                >
                  <StepLabel>{progresses[index].label}</StepLabel>
                </LightTooltip>
              ) : (
                <StepLabel>{progresses[index].label}</StepLabel>
              )}
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};
