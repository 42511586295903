import { ENVS_CONFIG } from './constants';
if (ENVS_CONFIG.REACT_APP_ENVIRONMENT === 'PROD') {
  console.log('%cApplication developed with React@17.0.2 version', 'background: gray; color: white; font-size: small');
  console.log('%c----------', 'color:blue');
  console.log('%c-------', 'color:blue');
  console.log('%c-----', 'color:blue');
  console.log('%c---', 'color:blue');
  console.log('Logs disabled!');
  // NOTE this will disable standard logging in production, console.error will still show
  console.log = () => {};
  console.warn = () => {};
}
