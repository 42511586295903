/* eslint-disable no-prototype-builtins */
// ? THIS IS AN EXAMPLE STORE SAMPLE CAN USE TO CREATE NEW STORE
// @WARN dont update anything here
import { useState, useEffect, useContext } from 'react';
import { IncidentFormContext } from './context';
import {
  // HttpTokens,
  IncidentFormStore,
  IncidentSchemaParts,
  IncidentFormPartNames,
  IncidentFormStoreParts,
  IncidentFormDataTypeOf,
  IncidentInitialReportPartial,
  IncidentDescriptionPartial,
  IncidentInjuredPartial,
  IncidentPropertyDamagePartial,
  IncidentInvestigationPartial,
  IncidentCausesAssessmentPartial,
  IncidentInvestigationTeamPartial,
  IncidentCorrectiveActionPartial,
  ReviewerSchema,
} from '@/src/types';
// import { getJwtToken } from '@/src/utils';
// import { Logger } from '@/logger/index';
import {
  AzureAuthProviderProps,
  FormItemProp,
  FormStoreActionState,
  IncidentFormPartStoreState,
  IncidentFormStoreState,
  FromStoreCtaState,
} from '@/types/store';
import { Logger } from '@/logger/index';
import { clone, isArray, isEmpty } from 'lodash';
import { checkAndSetStatusComplete } from '@/src/utils/form';

/**
 * to give us access to azure for admin
 */
interface ProviderProps {
  children: React.ReactNode;
  context: {
    [name: string]: any;
    azure: AzureAuthProviderProps;
  };
}

const legalKeys = [
  'reviewers',
  'initialReport',
  'description',
  'injured',
  'propertyDamage',
  'incidentInvestigation',
  'incidentCausesAssessment',
  'incidentInvestigationTeam',
  'correctiveAction',
];

const setLatestUpdate = (_data: IncidentFormDataTypeOf<any>, source: IncidentFormDataTypeOf<any>) => {
  const d: IncidentFormDataTypeOf<any> = {
    data: {
      ...(source.data || {}),
      // override with new part/formPartName data
      ...(_data.data || {}),
    },
    state: _data.state,
  };
  return d;
};

export const useIncidentFormInitialState = () => useContext(IncidentFormContext);
const IncidentFormContextProvider = (props: ProviderProps) => {
  const contextInitialState = useIncidentFormInitialState();
  const { children } = props;
  /** Access to azure  */
  // const { azure } = context;
  // const tokens: HttpTokens = { jwt: getJwtToken() };

  /** all of form data */
  const [formData, setFormData] = useState<IncidentFormStore>(contextInitialState.formStore.store);
  const [ctaState, setCta] = useState<FromStoreCtaState>(contextInitialState.formStore.ctaState);

  const [formActionState, setFormActionState] = useState<FormStoreActionState>(contextInitialState.formStore.actionState);

  /** each form part data */

  const [reviewersData, setReviewers] = useState<IncidentFormDataTypeOf<ReviewerSchema>>(contextInitialState.formStore.store.formData.reviewers);

  const [initialReportData, setInitialReport] = useState<IncidentFormDataTypeOf<IncidentInitialReportPartial>>(
    contextInitialState.formStore.store.formData.initialReport
  );
  const [descriptionData, setDescription] = useState<IncidentFormDataTypeOf<IncidentDescriptionPartial>>(
    contextInitialState.formStore.store.formData.description
  );
  const [injuredData, setInjured] = useState<IncidentFormDataTypeOf<IncidentInjuredPartial>>(contextInitialState.formStore.store.formData.injured);
  const [propertyDamageData, setPropertyDamage] = useState<IncidentFormDataTypeOf<IncidentPropertyDamagePartial>>(
    contextInitialState.formStore.store.formData.propertyDamage
  );

  const [incidentInvestigationData, setIncidentInvestigation] = useState<IncidentFormDataTypeOf<IncidentInvestigationPartial>>(
    contextInitialState.formStore.store.formData.incidentInvestigation
  );

  const [incidentCaseAssessmentData, setIncidentCaseAssessment] = useState<IncidentFormDataTypeOf<IncidentCausesAssessmentPartial>>(
    contextInitialState.formStore.store.formData.incidentCausesAssessment
  );

  const [incidentInvestigationTeamData, setIncidentInvestigationTeam] = useState<IncidentFormDataTypeOf<IncidentInvestigationTeamPartial>>(
    contextInitialState.formStore.store.formData.incidentInvestigationTeam
  );

  const [correctiveActionData, setCorrectiveAction] = useState<IncidentFormDataTypeOf<IncidentCorrectiveActionPartial>>(
    contextInitialState.formStore.store.formData.correctiveAction
  );

  const initialFormStore = (): IncidentFormStore => {
    return {
      state: formData.state,
      formData: {
        reviewers: reviewersData,
        initialReport: initialReportData,
        description: descriptionData,
        injured: injuredData,
        propertyDamage: propertyDamageData,
        incidentInvestigation: incidentInvestigationData,
        incidentCausesAssessment: incidentCaseAssessmentData,
        incidentInvestigationTeam: incidentInvestigationTeamData,
        correctiveAction: correctiveActionData,
      },
    };
  };

  const selectFormParData = (key: IncidentFormPartNames): IncidentFormDataTypeOf<any> => {
    const forSwitch = (k: IncidentFormPartNames) => {
      switch (k) {
        case 'reviewers': {
          return reviewersData;
        }
        case 'correctiveAction': {
          return correctiveActionData;
        }

        case 'description': {
          return descriptionData;
        }
        case 'incidentCausesAssessment': {
          return incidentCaseAssessmentData;
        }
        case 'incidentInvestigation': {
          return incidentInvestigationData;
        }
        case 'incidentInvestigationTeam': {
          return incidentInvestigationTeamData;
        }
        case 'initialReport': {
          return initialReportData;
        }
        case 'injured': {
          return injuredData;
        }
        case 'propertyDamage': {
          return propertyDamageData;
        }
        default: {
          Logger(['[selectFormParData][forSwitch]', `no condition matched: ${key}`], 'warn');
        }
      }
      return {};
    };
    return forSwitch(key) as any;
  };

  /**
   * Should set only one state base on key/form part property name
   * @param stateOnly only update state for each part!
   */
  const setStates = (
    key: IncidentFormPartNames,
    data: IncidentFormDataTypeOf<any>,
    type: 'formData' | 'formPartName' | 'empty' = 'formData',
    stateOnly: IncidentFormPartStoreState | null = null
  ): boolean => {
    let pass = false;
    const forSwitch = (k: IncidentFormPartNames) => {
      switch (k) {
        case 'reviewers': {
          if (stateOnly && !type) {
            reviewersData.state = stateOnly;
            setReviewers(reviewersData);
            return true;
          }
          if (type === 'empty') {
            setReviewers({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }
          if (type === 'formData') {
            setReviewers(data);
          } else {
            setReviewers(setLatestUpdate(data as any, reviewersData));
          }

          return true;
        }

        case 'correctiveAction': {
          if (stateOnly && !type) {
            correctiveActionData.state = stateOnly;
            setCorrectiveAction(correctiveActionData);
            return true;
          }
          if (type === 'empty') {
            setCorrectiveAction({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }
          if (type === 'formData') {
            // setCorrectiveAction(data);
            setCorrectiveAction(checkAndSetStatusComplete(k, data));
          } else {
            // setCorrectiveAction(setLatestUpdate(data as any, correctiveActionData));
            setCorrectiveAction(checkAndSetStatusComplete(k, setLatestUpdate(data as any, correctiveActionData)));
          }

          return true;
        }
        case 'description': {
          if (stateOnly && !type) {
            descriptionData.state = stateOnly;
            setDescription(descriptionData);
            return true;
          }

          if (type === 'empty') {
            setDescription({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }
          if (type === 'formData') {
            // setDescription(data);
            setDescription(checkAndSetStatusComplete(k, data));
          } else {
            // setDescription(setLatestUpdate(data as any, descriptionData));
            setDescription(checkAndSetStatusComplete(k, setLatestUpdate(data as any, descriptionData)));
          }

          return true;
        }
        case 'incidentCausesAssessment': {
          if (stateOnly && !type) {
            incidentCaseAssessmentData.state = stateOnly;
            setIncidentCaseAssessment(incidentCaseAssessmentData);
            return true;
          }
          if (type === 'empty') {
            setIncidentCaseAssessment({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }
          if (type === 'formData') {
            // setIncidentCaseAssessment(data);
            setIncidentCaseAssessment(checkAndSetStatusComplete(k, data));
          } else {
            // setIncidentCaseAssessment(setLatestUpdate(data as any, incidentCaseAssessmentData));
            setIncidentCaseAssessment(checkAndSetStatusComplete(k, setLatestUpdate(data as any, incidentCaseAssessmentData)));
          }

          return true;
        }
        case 'incidentInvestigation': {
          if (stateOnly && !type) {
            incidentInvestigationData.state = stateOnly;
            setIncidentInvestigation(incidentInvestigationData);
            return true;
          }

          if (type === 'empty') {
            setIncidentInvestigation({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }
          if (type === 'formData') {
            // setIncidentInvestigation(data);
            setIncidentInvestigation(checkAndSetStatusComplete(k, data));
          } else {
            // setIncidentInvestigation(setLatestUpdate(data as any, incidentInvestigationData));
            setIncidentInvestigation(checkAndSetStatusComplete(k, setLatestUpdate(data as any, incidentInvestigationData)));
          }

          return true;
        }
        case 'incidentInvestigationTeam': {
          if (stateOnly && !type) {
            incidentInvestigationTeamData.state = stateOnly;
            setIncidentInvestigationTeam(incidentInvestigationTeamData);
            return true;
          }
          if (type === 'empty') {
            setIncidentInvestigationTeam({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }

          if (type === 'formData') {
            // setIncidentInvestigationTeam(data);
            setIncidentInvestigationTeam(checkAndSetStatusComplete(k, data));
          } else {
            // setIncidentInvestigationTeam(setLatestUpdate(data as any, incidentInvestigationTeamData));
            setIncidentInvestigationTeam(checkAndSetStatusComplete(k, setLatestUpdate(data as any, incidentInvestigationTeamData)));
            return true;
          }

          return true;
        }
        case 'initialReport': {
          if (stateOnly && !type) {
            initialReportData.state = stateOnly;
            setInitialReport(initialReportData);

            return true;
          }
          if (type === 'empty') {
            setInitialReport({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }

          if (type === 'formData') {
            // setInitialReport(data);
            setInitialReport(checkAndSetStatusComplete(k, data));
          } else {
            // setInitialReport(setLatestUpdate(data as any, initialReportData));
            setInitialReport(checkAndSetStatusComplete(k, setLatestUpdate(data as any, initialReportData)));
          }

          return true;
        }
        case 'injured': {
          if (stateOnly && !type) {
            injuredData.state = stateOnly;
            setInjured(injuredData);
            return true;
          }

          if (type === 'empty') {
            setInjured({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }
          if (type === 'formData') {
            // setInjured(data);
            setInjured(checkAndSetStatusComplete(k, data));
          } else {
            // setInjured(setLatestUpdate(data as any, injuredData));
            setInjured(checkAndSetStatusComplete(k, setLatestUpdate(data as any, injuredData)));
          }

          return true;
        }
        case 'propertyDamage': {
          if (stateOnly && !type) {
            propertyDamageData.state = stateOnly;
            setPropertyDamage(propertyDamageData);
            return true;
          }

          if (type === 'empty') {
            setPropertyDamage({ data: null as any, state: stateOnly || 'reset' });
            return true;
          }
          if (type === 'formData') {
            // setPropertyDamage(data);
            setPropertyDamage(checkAndSetStatusComplete(k, data));
          } else {
            // setPropertyDamage(setLatestUpdate(data as any, propertyDamageData));
            setPropertyDamage(checkAndSetStatusComplete(k, setLatestUpdate(data as any, propertyDamageData)));
          }

          return true;
        }
        default: {
          Logger(['[IncidentForm][forSwitch]', `no condition matched: ${key}`], 'warn');
        }
      }
      return pass;
    };

    if ((pass = forSwitch(key))) {
      // NOTE update formData if anything was set!
      const allData = initialFormStore();
      allData.state = 'updated';
      setFormData(allData);
    }

    return pass;
  };

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const store: IncidentFormStoreParts = {
    formStore: {
      ctaState: ctaState,
      actionState: formActionState,
      store: initialFormStore(),
      /**
       * - set one or multiple form parts with one call
       * - preferably only one per form part
       * @WARN dont update anything here
       */
      setFormParts: (formData: IncidentSchemaParts, state?: IncidentFormPartStoreState) => {
        const _state: any = clone(state);
        if (_state === '__reset') state = 'reset';

        if (!isMounted) return;
        if (isArray(formData)) {
          Logger(['[IncidentFormStore][setFormParts]', 'cannot use array as prop!'], 'warn');
          return;
        }

        // if (isEmpty(formData)) {
        //   Logger(['[IncidentFormStore][setFormParts]', 'formData is empty or not set!'], 'warn');
        //   setStates({} as any, d);
        //   return;
        // }
        for (const part in formData) {
          if (formData.hasOwnProperty(part)) {
            if (legalKeys.indexOf(part) !== -1) {
              if (formData[part] === null || formData[part] === undefined) {
                setStates(part as any, null as any, 'empty', state);
                //  console.log('calling for empty reset 1');
                if (state) {
                  Logger([`[IncidentFormStore][setFormParts][resetToState][${state}]`, 'formData reset '], 'warn');
                } else {
                  Logger([`[IncidentFormStore][setFormParts][reset]`, 'formData reset'], 'warn');
                }
                continue;
              }

              const updatedStateViaFormData: any = formData[part]?.state;
              const d: IncidentFormDataTypeOf<any> = { data: formData[part].data, state: state || updatedStateViaFormData || 'updated' } as any;
              setStates(part as any, d);
              //   console.log('calling for empty reset 2', formData[part]);
              // only assign matching names
            } else {
              Logger(['[IncidentFormStore][setFormParts]', `provided wrong key name: ${part} `], 'warn');
            }
          }
        }
        //  internal condition only
        if ((_state as any) === '__reset') {
          store.formStore.setMainState('pristine');
        }
      },

      /**
       * @WARN dont update anything here
       */
      setResetFormStore: () => {
        const reset = {
          initialReport: null,
          description: null,
          injured: null,
          propertyDamage: null,
          incidentCausesAssessment: null,
          incidentInvestigation: null,
          incidentInvestigationTeam: null,
          correctiveAction: null,
        };
        store.formStore.setFormParts(reset as any, '__reset' as any);
        Logger(['[IncidentFormStore][reset]', ' all of form data has been reset!'], 'notice');
      },
      /**
       * - set one property for specific from part
       * @WARN dont update anything here
       */
      setFormItem: (formPartName: IncidentFormPartNames, props: FormItemProp, state?: IncidentFormPartStoreState) => {
        if (!isMounted) return;
        if (isArray(props)) {
          Logger(['[IncidentFormStore][setFormItem]', 'cannot use array as FormItemProp!'], 'warn');
          return;
        }
        if (isEmpty(props)) {
          Logger(['[IncidentFormStore][setFormItem]', 'FormItemProp empty or not set!'], 'warn');
          return;
        }
        // we only want to set or update existing data
        const d: IncidentFormDataTypeOf<any> = { data: { ...props }, state: state || 'updated' };

        if (!setStates(formPartName, d)) {
        }
      },

      /**
       * @WARN dont update anything here
       */
      setUpdateFormItem: (formPartName: IncidentFormPartNames, state?: IncidentFormPartStoreState) => {
        const initialData = selectFormParData(formPartName).data || {};
        return {
          setup: (value: any, keyName: string, subKeyName?: string | undefined) => {
            if (subKeyName) {
              initialData[keyName] = { ...initialData[keyName], [subKeyName]: value };
            } else {
              initialData[keyName] = value;
            }
            store.formStore.setFormItem(formPartName, initialData, state || 'updated');
          },
        };
      },
      /**
       * - setState of each either form/part manually without affecting data
       * @WARN dont update anything here
       */
      setPartState: (formPartName: IncidentFormPartNames, state: IncidentFormPartStoreState) => {
        if (!isMounted) return;
        if (!formPartName || !state) return;
        setStates(formPartName, null as any, 'formPartName', state);
      },

      /**
       *
       * @WARN dont update anything here
       */
      setMainState: (state: IncidentFormStoreState) => {
        if (!isMounted) return;
        if (!state) return;
        formData.state = state;
        setFormData({ ...formData });
      },

      /**
       *
       * @WARN dont update anything here
       */
      setFormActionState: (action: FormStoreActionState) => {
        if (!isMounted) return;
        setFormActionState(action);
      },

      /**
       * @bugfix  this fix issue when setting reviewers data from modal
       */
      setCta: (action: FromStoreCtaState) => {
        setCta(action);
      },
    },
  };

  return <IncidentFormContext.Provider value={store}>{children}</IncidentFormContext.Provider>;
};

const withIncidentFormContext = (Component: any) => {
  return function IncidentFormComponent(props: any) {
    return <IncidentFormContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</IncidentFormContext.Consumer>;
  };
};

export { IncidentFormContextProvider, withIncidentFormContext };
