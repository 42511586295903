import { AzureAuthProviderProps, PropDefaults } from '@/src/types';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Icon } from '../../ui/icons';
import { Header } from '../header';
import { SideBar } from '../sideBar';

interface LayoutProps extends PropDefaults {
  azure: AzureAuthProviderProps;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const handleClickSidebarToggle = () => {
    if (sidebarToggle === false) {
      setSidebarToggle(true);
    } else {
      setSidebarToggle(false);
    }
  };
  return (
    <>
      <Box className={`flex w-full min-h-screen overflow-auto ${sidebarToggle && 'sidebarShow'}`}>
        <IconButton onClick={handleClickSidebarToggle} className=" fixed left-2 top-3 md:left-4 lg:left-6 z-[1200] xl:hidden SidebarToggle">
          <Icon icon="bars" size={24} viewBox="0 0 448 512" />
        </IconButton>
        <SideBar {...props}></SideBar>
        <Box className="w-full min-h-screen main overflow-hidden">
          <Header {...props}></Header>
          <main className="px-4 pb-4 md:px-6 md:pb-6 lg:px-8 lg:pb-8">
            <Outlet {...(props as any)} />
          </main>
        </Box>
      </Box>
    </>
  );
};

export { Layout };
