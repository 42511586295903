/** declare only http service related requests */

import { HttpTokens, GetProfileMyTrainingList, ProfileMyTrainingQuery } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { paramsQueryString } from '@/utils/index';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get training data that relates to currently logged-in user, we use user token to match to current user
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const ProfileMyTrainingGet = (
  prefix: string = 'employees/profile/my-training',
  employeeId: string,
  query: ProfileMyTrainingQuery,
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetProfileMyTrainingList> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const queryUri = paramsQueryString(`${prefix}/${employeeId}`, query || {});
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetProfileMyTrainingList>) => {
      // return Promise.reject(new Error('ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetProfileMyTrainingList>;
};
