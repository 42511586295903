import { BodyPostLegalLorStartEval, HttpTokens, PostLegalLorStartEval } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description /api/legal/lor/start-eval/{lorId}, for update published status to in progress
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const LegalLORStartEvaluationPost = (
  prefix: string = 'legal/lor/start-eval',
  lorUid: string,
  body: BodyPostLegalLorStartEval,
  tokens: HttpTokens,
  hardRejection = true
): Promise<PostLegalLorStartEval> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .post(`${prefix}/${lorUid}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostLegalLorStartEval>) => {
      // return Promise.reject(new Error('ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostLegalLorStartEval>;
};
