import iconsLib from '@/src/static/common/icons.lib';
import { NavRoute } from '@/src/types';

interface NavType extends NavRoute {
  icon: keyof typeof iconsLib;
  viewBox: string;
  size: number;
  childDisabled?: boolean;
  children?: Array<{
    id: string;
    name: string;
    path: string;
    /**
     * to hide element from displaying conditionally on aside dropdown
     */
    disabled?: boolean;
  }>;
}

export const nav: NavType[] = [
  // {
  //   id: '1',
  //   name: 'Dashboard',
  //   icon: 'chartColumn',
  //   viewBox: '0 0 512 512',
  //   size: 16,
  //   path: '/',
  // },
  {
    id: '1',
    name: 'Home',
    icon: 'house',
    viewBox: '0 0 17.995 16',
    size: 20,
    path: '/home',
  },
  {
    id: '2',
    name: 'Legals',
    icon: 'scaleBalanced',
    viewBox: '0 0 640 512',
    size: 20,
    path: '/legals',
  },
  {
    id: '3',
    name: 'Trainings',
    icon: 'bookOpen',
    viewBox: '0 0 576 512',
    size: 18,
    path: '/trainings',
    children: [
      {
        id: '3.1',
        name: 'Courses',
        path: '/courses',
      },
      {
        id: '3.2',
        name: 'Calendar',
        path: '/calendar',
        disabled: true,
      },
    ],
  },
  // NOTE Incidents {id} is sensitive, we cannot move this item below 4
  {
    id: '5',
    name: 'Incidents',
    icon: 'memoCircleInfo',
    viewBox: '0 0 576 512',
    size: 18,
    path: '/incidents',
  },
  {
    id: '4',
    name: 'Manage roles',
    icon: 'user',
    viewBox: '0 0 448 512',
    size: 16,
    path: '/admins',
  },
] as NavType[];
