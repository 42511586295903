import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Dialog, DialogContent, DialogTitle, DialogProps } from '@mui/material';
import { Icon } from '@/src/components';
import { DefaultComponentProps } from '@/types/common.d';
import CourseForm from './courseForm';
// import { Logger } from '@/logger/index';
import { CourseStore } from '@/types/store';
import { CourseRequestDtoType } from '@/types/schema';
import { serverError, delay } from '@/utils/index';
import { FIELD_REQUIRED } from '@/src/static';

function CreateEditCourseModal(props: DefaultComponentProps & CourseStore) {
  const { store } = props;
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const onSubmitHandler = (data: CourseRequestDtoType) => {
    //  Logger(['[create/course][onSubmitHandler]', data], 'attention');
    // Logger(['json', JSON.stringify(data)], 'log');
    store.courseCreate(data as any);
  };

  if (store.courseCreatedData.state === 'error') {
    //  Logger(['[store][courseCreate]', store.courseCreatedData.error], 'error');
    delay(5000).then(() => {
      store.resetStatus('course/create');
    });
  }

  if (store.courseCreatedData.state === 'ready' && open) {
    //   Logger(['[store][courseCreate]', store.courseCreatedData.data.data], 'attention');
    setOpen(false);
  }

  return (
    <Box className="ml-auto">
      <Button
        className="w-28"
        variant="contained"
        onClick={handleClickOpen('body')}
        startIcon={<Icon icon="plus" color="#ffffff" viewBox="0 0 512 512" size={18} />}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          store.resetStatus('course/create');
        }}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={scroll}
      >
        <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
          <Typography className="font-medium text-2xl">Add new course</Typography>
        </DialogTitle>
        <DialogContent className="p-0 " sx={{ overflow: 'unset' }}>
          {open ? (
            <CourseForm
              mode="create"
              serverErrorResponse={serverError(store?.courseCreatedData?.error)}
              allRequired={true}
              allRequiredMessage={FIELD_REQUIRED}
              onClose={() => {
                setOpen(false);
                store.resetStatus('course/create');
              }}
              onSubmit={onSubmitHandler}
              status="open"
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default CreateEditCourseModal;
