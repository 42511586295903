/* eslint-disable handle-callback-err */
import Alert from '@mui/material/Alert';
import React from 'react';
import { Logger } from '@/utils/logger/index';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    Logger(['ErrorBoundary', error, errorInfo], 'error');
  }

  render() {
    const state: any = this.state;
    if (state.hasError) {
      // You can render any custom fallback UI
      return <Alert severity="error">Something went wrong, check logs</Alert>;
    }

    return this.props.children as any;
  }
}
