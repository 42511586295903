/* eslint-disable spaced-comment */

/**
 * @assets: https://stackoverflow.com/questions/52752552/echarts-for-react-call-component-on-clicking-a-data-point-on-chart
 */

import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { ManHourChartBaseSchema, PropDefaults, ReducerActionType } from '@/src/types';
// import { Logger } from '@/logger/index';
import { mhOptions } from './chart.options';
import { clone } from 'lodash';

type ChartOptions = typeof mhOptions;

interface Props extends PropDefaults {
  /**
   * called when chart is ready
   */
  onChartReady: () => void;
  data: ManHourChartBaseSchema;
  error: boolean;
  chartQueryState: ReducerActionType;
  loading: boolean;
}

/**
 * Right side chart
 */
export function ManHourChartOne(props: Props) {
  const { onChartReady, data, error, loading } = props;
  const [mount, setMount] = useState<number>(0);
  const [optionsData, setOptions] = useState<ChartOptions & { title?: any }>(mhOptions);
  const [chartInstance, setChartInstance] = useState<ReactECharts>(null as any);

  const updateChart = () => {
    if (chartInstance) {
      const inst = chartInstance.getEchartsInstance();
      inst.setOption(optionsData as any);
      inst.resize();
    }
  };

  useEffect(() => {
    if (data) {
      optionsData.xAxis.data = data?.categoryList as any;
      optionsData.series[0].data = data?.seriesData as any;

      const _labels = optionsData.xAxis.data;
      optionsData.series.forEach((d, i) => {
        if (d.data) {
          const _temp: never[] = _labels.map((k, j) => {
            return (d.data[j] ? d.data[j] : 0) as never;
          });
          d.data = _temp;
        }
      });

      setOptions(clone(optionsData));
      updateChart();
    }

    if (error) {
      optionsData.xAxis.data = [];
      optionsData.series[0].data = [];
      optionsData.title = {
        ...optionsData.title,
        color: 'grey',
        fontSize: 17,
        text: 'No data',
        left: '45%',
        top: 'center',
      } as any;

      setOptions(clone(optionsData));
      updateChart();
    }

    if (!mount) setMount(1);
  }, [mount, setMount, data, error, chartInstance, setChartInstance]);

  return (
    <Box className="pt-6 px-0 pb-6 w-full rounded-2xl border border-solid border-gray-200 bg-white relative max-h-[264px] min-w-[600px] shadow-md">
      {loading && <CircularProgress className="mr-2 absolute z-40" sx={{ top: 'calc(50% - 15px)', left: 'calc(50% - 15px)' }} size={40} />}
      {mount && (
        <ReactECharts
          style={{ width: '100%', padding: 0 }}
          ref={(r: any) => {
            if (r) {
              r.inx = (r.inx || 0) + 1;
              setChartInstance(r as any);
            }
          }}
          onChartReady={onChartReady}
          option={optionsData}
          className={`mb-3 ${loading && 'opacity-25'}`}
        />
      )}
    </Box>
  );
}
