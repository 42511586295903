import { PropDefaults, AzureAuthProviderProps, CourseStore } from '@/src/types';
import { withCourseContext } from '@/store/index';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Courses from './index';
import LinearProgress from '@mui/material/LinearProgress';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { COURSES_PAGE_TITLE } from '@/src/static';

interface CoursesProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

const CoursesPage: React.FC<CoursesProps & CourseStore> = (props) => {
  setDocTitle(COURSES_PAGE_TITLE);
  const { detail } = useParams();
  const [mount, setMount] = useState<number>(0);
  const { store } = props;

  useEffect(() => {
    if (store.courseByNoData.data?.data && !!detail && store.courseByNoData.data?.data?.courseNo !== detail) {
      store.resetStatus('course/no');
      setMount(0);
    } else if (!mount) {
      setMount(1);
    }
  }, [store, detail, setMount, mount]);
  // NOTE for every route change to child we need to reset store state because it contains data from page we visited previously

  const pageType: 'courses-parent' | 'detail-child' | 'detail-loading' = [
    mount === 0 && 'detail-loading',
    !!detail && 'detail-child',
    !detail && 'courses-parent',
  ].filter((n) => !!n)[0] as any;

  switch (pageType) {
    case 'detail-loading': {
      return <LinearProgress />;
    }
    case 'courses-parent': {
      return <Courses {...(props as any)} />;
    }

    case 'detail-child': {
      const outletProps = {
        ...props,
        detail,
      };
      return (
        <>
          <Outlet context={outletProps} />
        </>
      );
    }
    default: {
      return <>No mach for courses found</>;
    }
  }
};
export default withCourseContext(CoursesPage);
