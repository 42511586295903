import { IconButton, TableBody, TableCell, TableRow, Box, Table, TableContainer } from '@mui/material';
import React, { useState } from 'react';
import { Icon } from '../../icons';
import { headCellsTrainings, modalDescriptions } from '@/src/static';
import { EnhancedTableHead } from '../../tableHead';
import { NavLink } from 'react-router-dom';
import { printDate, getByTrainingCode, sortedTrainingList, isSuperAdmin, isTheSameDate } from '@/src/utils';
import { TrainingStore, AzureAuthProviderProps } from '@/types/store/index';
import { TrainingSmallSchema, TrainingStatus } from '@/src/types';
import { useGlobalContext } from '@/store/index';
import { TableDataError } from '../../dataError';
import { TableDataNotFound } from '../../dataNotFound';
import DeleteCancelTrainingModal from '../deleteCancelTrainingModal/index';

interface EnhancedTableTrainingsProps {
  [name: string]: any;
  isFiltered: boolean;
  /** sort all items in the list by filter, helps when realtime data is added or updated */
  sortBy: TrainingStatus[];
  azure: AzureAuthProviderProps;
  /** callback to open training modal */
  handlerOpenEditModal: (uid: string) => void;
}
export function EnhancedTableTrainings(props: EnhancedTableTrainingsProps & TrainingStore) {
  const {
    store,
    isFiltered,
    // azure,
    handlerOpenEditModal,
    sortBy,
  } = props;

  const { currentUser } = useGlobalContext();
  const [getUid, setUid] = useState('');
  const [modalType, setModalType] = useState<'cancel' | 'delete'>(null as any);
  const [title, setTitle] = useState('');
  const userProfile = currentUser?.userProfile;

  const handleCancelDelete = (id: string, status: TrainingStatus, type: 'delete' | 'cancel') => {
    if (type === 'delete') setTitle('Are you sure you want to delete this training?');
    if (type === 'cancel') setTitle('Are you sure you want to cancel this training?');

    // on draft or published can cancel
    if ((status === 1 || status === 2) && type === 'cancel') {
      setModalType(type);
      setUid(id);
      return;
    }

    // on draft or canceled can delete
    if ((status === 1 || status === 3) && type === 'delete') {
      setModalType(type);

      setUid(id);
    }
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead headCells={headCellsTrainings} />
          {store?.trainingListData.state === 'error' && <TableDataError col={10} description={`Please reload the page again.`} />}

          <TableBody>
            {store?.trainingListData?.state === 'ready' &&
              sortedTrainingList(store?.trainingListData?.data?.data as any, sortBy).map((row: TrainingSmallSchema, index: number) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className="align-top">
                  <TableCell>{row.status === 1 && <Icon icon="circle" color="#ECB623" size={9} />}</TableCell>
                  <TableCell className="text-ellipsis whitespace-nowrap overflow-hidden">
                    {isTheSameDate(row.startDate || '', row.endDate || '') ? (
                      `${printDate(row.startDate)}`
                    ) : (
                      <>
                        {printDate(row.startDate)} - <wbr />
                        {printDate(row.endDate)}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {printDate(row.startDate, 'HH:mm')} - {printDate(row.endDate, 'HH:mm')}
                  </TableCell>
                  <TableCell className="break-all">{row.courseNo}</TableCell>
                  <TableCell className="break-all">{row.courseName}</TableCell>
                  <TableCell className="break-words">{!row.location ? '-' : row.location}</TableCell>
                  <TableCell>
                    {row.attendees}/{row.total}
                  </TableCell>
                  <TableCell>
                    <Box className="flex items-center">
                      <Icon
                        className="pr-2 w-2"
                        icon="circle"
                        color={row.trainingCode === 'classroom' ? '#68B7DC' : row.trainingCode === 'online' ? '#6872DC' : '#A368DC'}
                        viewBox="0 0 384 512"
                        size={6}
                      />
                      {getByTrainingCode(row.trainingCode as any)?.trainingName}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <span className={`py-1 px-2 rounded ${row.status === 1 ? 'draft' : row.status === 2 ? 'publishedTraining' : 'cancelled'}`}>
                      {row.status === 1 ? 'Draft' : row.status === 2 ? 'Published' : row.status === 3 ? 'Cancelled' : ''}
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <Box className="flex justify-end">
                      {row.status === 1 && isSuperAdmin(userProfile) ? (
                        <IconButton
                          color="primary"
                          aria-label="Edit details"
                          onClick={() => {
                            handlerOpenEditModal(row.id);
                          }}
                        >
                          <Icon icon="penToSquare" color="#3155FF" viewBox="0 0 576 512" size={20} />
                        </IconButton>
                      ) : null}
                      {row.status === 2 || row.status === 3 ? (
                        <NavLink
                          to={`/trainings/detail/${row.id}`}
                          onClick={() => {
                            if (store.trainingDetailData.state === 'error') {
                              store.resetStatus('training/detail');
                            }
                          }}
                        >
                          <IconButton color="primary" aria-label="View details">
                            <Icon icon="eyes" color="#3155FF" viewBox="0 0 576 512" size={20} />
                          </IconButton>
                        </NavLink>
                      ) : null}
                      {row.status === 1 && isSuperAdmin(userProfile) ? (
                        <IconButton color="error" aria-label="Delete data" onClick={() => handleCancelDelete(row.id, row.status, 'delete')}>
                          <Icon icon="trash" color="#F14242" viewBox="0 0 448 512" size={18} />
                        </IconButton>
                      ) : null}

                      {row.status === 2 && isSuperAdmin(userProfile) ? (
                        <IconButton color="error" aria-label="Delete data" onClick={() => handleCancelDelete(row.id, row.status, 'cancel')}>
                          <Icon icon="calendarXmark" color="#F14242" viewBox="0 0 448 512" size={18} />
                        </IconButton>
                      ) : null}

                      {row.status === 3 && isSuperAdmin(userProfile) ? (
                        <IconButton color="error" aria-label="Cancel data" onClick={() => handleCancelDelete(row.id, row.status, 'delete')}>
                          <Icon icon="trash" color="#F14242" viewBox="0 0 448 512" size={18} />
                        </IconButton>
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {store?.trainingListData.state === 'ready' && store?.trainingListData?.data?.data.length === 0 && (
            <TableDataNotFound
              col={10}
              description={isFiltered ? 'Please change search keyword or select another filters' : 'No trainings information is provided to you'}
            ></TableDataNotFound>
          )}
        </Table>
      </TableContainer>

      <DeleteCancelTrainingModal
        location="list"
        handleExit={() => {
          setModalType(null as any);
          setUid(null as any);
        }}
        handleSubmit={() => {
          setModalType(null as any);
          setUid(null as any);
        }}
        modalType={modalType}
        store={store}
        title={title}
        description={modalType === 'delete' ? modalDescriptions.delete : modalDescriptions.cancel}
        uid={getUid}
      ></DeleteCancelTrainingModal>
    </>
  );
}
