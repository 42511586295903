import { useState, useEffect, FocusEvent, Fragment } from 'react';
import { IncidentStore, ManhoursStore } from '@/types/store/index';
import { TableCell, TextField } from '@mui/material';
import { monthData } from '@/src/static';
import { ManHourBodyData, ManHourCompanySchema, ManHourSchema, QueryManHourUpdate } from '@/src/types';
import { Logger } from '@/logger/index';
import { updateManHours, getInputValue } from '@/src/utils';
import { debounce } from 'lodash';

const configCompanyColor = { ARV: 'bg-violet-100/[.3]', CAR: 'bg-cyan-100/[.3]', ROV: 'bg-amber-100/[.3]', SKL: 'bg-sky-100/[.3]', VRN: 'bg-emerald-100/[.3]' };

interface ManhoursMonthSegmentProps {
  buColumn: string[];
  data: ManHourSchema;
  parentStore: IncidentStore;
  /** called when input changed */
  onInputChange: () => void;
}

const updateValue = (
  e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  type: 'workDay' | 'hours' | 'employeeCount' | 'eachBu',
  done: (val: number, type: 'workDay' | 'hours' | 'employeeCount' | 'eachBu') => void
): any => {
  e.preventDefault();
  const v = getInputValue(e);

  if (!v) {
    e.target.value = 0 as any;
    done(0, type);
    return false;
  } else {
    done(v, type);
    return true;
  }
};

interface MonthRow {
  workDay: number;
  hours: number;
  manhours: ManHourCompanySchema[];
}

/** this fix wired bug if we pass value directly from parent component */
// const getManHour = (data: ManHourCompanySchema, manhoursSource: ManHourCompanySchema[]): string => {
//   const d: any = manhoursSource?.filter((n) => n.buCode === data.buCode)[0] || {};
//   const manHour: any = d.manHour || 0;
//   return manHour.toLocaleString();
// };

export function ManhoursMonthSegment(props: ManhoursStore & ManhoursMonthSegmentProps) {
  const { data, buColumn, onInputChange } = props;
  const parentStore = props.parentStore.store;
  const setRowData: MonthRow = { workDay: data?.workDay || undefined, hours: data?.hours || undefined, manhours: data?.manhours || [] } as any;

  const [monthRowData, setMonthRow] = useState<MonthRow>(setRowData);
  const { store } = props;

  useEffect(() => {
    if (['ready', 'error'].indexOf(store.manHourUpdateData.state) !== -1) {
      // NOTE this will notify parent store to rerender and provide toast message from child store
      if (store.manHourUpdateData.state === 'ready') {
        parentStore.notify.onStatusChange('updated');
      }

      if (store.manHourUpdateData.state === 'error') {
        parentStore.notify.onStatusChange('error');
      }

      store.resetStatus('incidents/man-hours/update');
    }
  }, [store]);

  const fromDebounce = debounce(
    (call: () => void) => {
      call();
    },
    200
    // { maxWait: 1000 }
  );

  const valueCheck = (val: any): number => {
    if (!val) return 0;
    return val;
  };

  const muliplier = () => {
    return (monthRowData.workDay || 0) * (monthRowData.hours || 0);
  };

  return (
    <>
      <TableCell className="px-3 pl-8 pr-0">{monthData.find((item) => item.value === data.month)?.label}</TableCell>
      <TableCell className="px-3 text-center">
        <TextField
          onBlur={(e) =>
            updateValue(e, 'workDay', (val, type) => {
              if (valueCheck(monthRowData?.workDay) === val) {
                return false;
              }

              const q: QueryManHourUpdate = {
                businessUnits: buColumn,
              };
              const requestBody: ManHourBodyData = {
                year: data.year,
                month: data.month as any,
                hours: monthRowData.hours,
                manhours: monthRowData.manhours,
                workDay: val,
              };
              fromDebounce(() => {
                store.setManHourUpdate(data.id, q, requestBody, null as any, onInputChange);
                setMonthRow({ ...monthRowData, [type]: val });
                Logger([`[ManhoursMonthSegment][${type}][done]`, val]);
              });
              return false;
            })
          }
          placeholder="0"
          defaultValue={monthRowData.workDay || 0}
          type="number"
          inputProps={{ style: { textAlign: 'right', backgroundColor: '#fff', width: '56px' } }}
        />
      </TableCell>
      <TableCell className="px-3 text-center">
        <TextField
          onBlur={(e) =>
            updateValue(e, 'hours', (val, type) => {
              if (valueCheck(monthRowData?.hours) === val) {
                return false;
              }
              const q: QueryManHourUpdate = {
                businessUnits: buColumn,
              };
              const requestBody: ManHourBodyData = {
                year: data.year,
                month: data.month as any,
                hours: val,
                manhours: monthRowData.manhours,
                workDay: monthRowData.workDay,
              };
              fromDebounce(() => {
                store.setManHourUpdate(data.id, q, requestBody, null as any, onInputChange);
                setMonthRow({ ...monthRowData, [type]: val });
                Logger([`[ManhoursMonthSegment][${type}][done]`, val]);
              });
              return false;
            })
          }
          placeholder="0"
          defaultValue={monthRowData.hours || 0}
          type="number"
          inputProps={{ style: { textAlign: 'right', backgroundColor: '#fff', width: '56px' } }}
        />
      </TableCell>
      {buColumn.map((v, index) => {
        const d = monthRowData.manhours.find((k) => k.buCode === v);
        return (
          <Fragment key={`${v}_${index}`}>
            <TableCell className={`px-3 text-center ${configCompanyColor[v]}`}>
              <TextField
                onBlur={(e) =>
                  updateValue(e, 'employeeCount', (val, type) => {
                    if (d && valueCheck(d.employeeCount) === val) {
                      return false;
                    }
                    const currBuCode = v;
                    const q: QueryManHourUpdate = {
                      businessUnits: buColumn,
                    };

                    monthRowData.manhours = updateManHours(currBuCode, monthRowData.manhours, { employeeCount: val });
                    const requestBody: ManHourBodyData = {
                      year: data.year,
                      month: data.month as any,
                      hours: monthRowData.hours,
                      manhours: monthRowData.manhours,
                      workDay: monthRowData.workDay,
                    };
                    fromDebounce(() => {
                      store.setManHourUpdate(data.id, q, requestBody, null as any, onInputChange);
                      setMonthRow({ ...monthRowData });
                      Logger([`[ManhoursMonthSegment][${type}][done]`, val]);
                    });
                    return false;
                  })
                }
                placeholder="0"
                defaultValue={d && d.employeeCount ? d.employeeCount : 0}
                type="number"
                inputProps={{ style: { textAlign: 'right', backgroundColor: '#fff', width: '56px' } }}
              />
            </TableCell>
            <TableCell className={`px-3 text-right ${configCompanyColor[v]} pr-5 borderManHour`}>
              {(d && d.manHour ? d.manHour : d && d.employeeCount ? d.employeeCount * muliplier() : 0).toLocaleString()}
            </TableCell>
          </Fragment>
        );
      })}
      <TableCell className="p-0 w-full"></TableCell>
      <TableCell className="px-3 font-bold text-right pr-10">{data.totalPerMonth ? data.totalPerMonth?.toLocaleString() : '0'}</TableCell>
    </>
  );
}
