exports.authLogin = {
  // what type of request: [get,put,post], mainly using get
  type: 'post',
  // api name after ./api prefix, api/example
  uniqId: 'auth/login',
  /**
   * any data: can be object, array etc, depending what you want to return
   * - data is added thru AxiosService > interceptors.response after https://httpbin.org/ request was successful
   * */
  response: {
    status: {
      code: 200,
      message: 'success',
    },
    data: {
      token: 'some_long_jwt_token',
      profile: {
        employeeId: 'ARV10105',
        firstName: 'Cat3',
        middleName: null,
        lastName: 'Pruitt',
        email: 'Cat3@arv.co.th',
        buCode: 'ARV',
        roleId: 1,
      },
    },
  },
};
/** @type {import('../../types/common').MockApiData} */
exports.authVerify = {
  // what type of request: [get,put,post], mainly using get
  type: 'get',
  // api name after ./api prefix, api/example
  uniqId: 'get-auth/verify',
  /**
   * any data: can be object, array etc, depending what you want to return
   * - data is added thru AxiosService > interceptors.response after https://httpbin.org/ request was successful
   * */
  response: {
    status: {
      code: 200,
      message: 'success',
    },
    data: {
      profile: {
        employeeId: 'ARV10105',
        firstName: 'Cat3',
        middleName: null,
        lastName: 'Pruitt',
        email: 'Cat3@arv.co.th',
        buCode: 'ARV',
        roleId: 1,
      },
    },
  },
};

exports.authLogout = {
  // what type of request: [get,put,post], mainly using get
  type: 'post',
  // api name after ./api prefix, api/example
  uniqId: 'auth/logout',
  /**
   * any data: can be object, array etc, depending what you want to return
   * - data is added thru AxiosService > interceptors.response after https://httpbin.org/ request was successful
   * */
  response: {
    status: {
      code: 200,
      message: 'success',
    },
    data: {
      token: 'some_expired_long_jwt_token',
    },
  },
};
