import { CourseUpdateSchema, CourseBodyData, CourseLearning, CoursePostTest, CoursePreTest, CourseTrainingCode, InstructionType } from '@/src/types';

interface CourseRequestDtoExt extends CourseBodyData {
  /** id is only required when sending editData to CourseForm */
  id?: string;
}
// const loadExampleData = () => {
//   try {
//     return require('@/static/courses/createform.example.json');
//   } catch (err) {
//     //  Logger(['[loadExampleData]', err], 'error');
//     return {};
//   }
// };

const assignOnly = [
  'courseNo',
  'courseName',
  'trainingCode',
  'preTest',
  'postTest',
  'instruction',
  'targetAudience',
  'totalPersons',
  'courseDuration',
  'overview',
  'learningOutcomes',
  'trainingObjectives',
  'costPerPerson',
  'version',
  'effectiveDate',
  'downloadPdfLink',
  'learning',
  'topics',
];

/**
 * should only send one training type upon submit
 */
const conditionedData = (formStateDate: CourseRequestDtoExt): CourseUpdateSchema => {
  const data: any = formStateDate;
  if (!data) return undefined as any;

  if (data?.trainingCode !== 'e-learning') {
    delete data.learning;
  }
  return data as CourseUpdateSchema;
};

export class CourseRequestDto implements CourseRequestDtoExt {
  // id = undefined as any;
  courseNo = undefined as any;
  courseName = undefined as any;
  // default values
  trainingCode: CourseTrainingCode = 'classroom';
  instruction: InstructionType = 1 as any;
  preTest: CoursePreTest = {} as any;
  postTest: CoursePostTest = {} as any;

  targetAudience = undefined as any;
  totalPersons = undefined as any;
  courseDuration = undefined as any;
  overview = undefined as any;
  learningOutcomes = undefined as any;
  trainingObjectives = undefined as any;
  costPerPerson = undefined as any;
  version = undefined as any;
  /** string date */
  effectiveDate = new Date() as any; // undefined as any;
  downloadPdfLink = undefined as any;
  learning: CourseLearning = {} as any;
  topics = undefined as any;

  constructor(data?: CourseUpdateSchema, onSubmit?: boolean, loadMock?: boolean) {
    let _data;
    // assign only required data
    if (Object.keys(data || {}).length) {
      _data = data;
      // Object.assign(this, data);
    } else if (loadMock) {
      // Object.assign(this, loadExampleData());
      // _data = loadExampleData();
    }

    _data = conditionedData(_data);

    if (_data) {
      assignOnly.forEach((key) => {
        try {
          this[key] = _data[key];
        } catch (err) {}
      });
    }
  }
}
