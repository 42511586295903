import React, { useEffect, useReducer } from 'react';
import { PropDefaults } from '@/types/common';
import { Paper, Typography, Box, Chip, LinearProgress, IconButton } from '@mui/material';
import { EnhancedTableLegals, Filters, Icon, CreateUpdateLegalModal, EnhancedTablePagination, SearchBoxInput, LegalChartSections } from '@/components/index';
import { AzureAuthProviderProps, LegalStore } from '@/src/types/store';
// import { mockupRows } from '@/src/static/legal/mockupData';
import { Logger } from '@/logger/index';
import { CallBackFilterType, ComplianceGraphSelectedValue, LegalFilterQuery, LegalStatusSchema, OverallGraphSelectedValue } from '@/src/types';

import { checkIsLastPage, isSuperAdmin } from '@/src/utils';
import { chartReducer } from '@/src/utils/reducers';

import { useGlobalContext } from '@/store/index';

const PAGE_TITLE = 'Legals';
interface LegalsProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}
function ChipCustom({ label, onDelete }) {
  return (
    <Chip
      label={label}
      className="mx-1 my-1 rounded bg-[#F0F2FA] text-sm h-[30px]"
      deleteIcon={
        <IconButton component="div" className=" bg-transparent hover:bg-transparent">
          <Icon icon="xmark" color="#3155FF" size={16} viewBox={'0 0 320 512'} />
        </IconButton>
      }
      onDelete={onDelete}
    />
  );
}

// let tempFilters = {} as {
//   nameSearching: string;
//   legalLorFilter: string;
//   buRelatedFilter: string[];
//   statusFilter: OverallGraphSelectedValue[];
//   complianceBuFilter: ComplianceGraphSelectedValue[];
//   nonComplianceBuFilter: ComplianceGraphSelectedValue[];
// };

function Legals(props: LegalsProps & LegalStore) {
  const { currentUser } = useGlobalContext();

  const [legalUid, setLegalUid] = React.useState<{ type: 'open' | 'close'; uid: string }>({ type: 'close', uid: null as any });

  const [listLoad, setListLoad] = React.useState<number>(0);
  // const [mount, setMount] = React.useState<number>(0);
  const { store: legalStore } = props;
  const [nameSearching, setNameSearching] = React.useState<string>('');
  const [legalLorFilter, setLegalLorFilter] = React.useState<string>('');
  const [buRelatedFilter, setBuRelatedFilter] = React.useState<string[]>([]);
  const [chartFiltersChange, setChartFiltersChange] = React.useState<boolean>(false);
  const [statusFilter, setStatusFilter] = React.useState<OverallGraphSelectedValue[]>([]);
  const [complianceBuFilter, setComplianceBuFilter] = React.useState<ComplianceGraphSelectedValue[]>([]);
  const [nonComplianceBuFilter, setNonComplianceBuFilter] = React.useState<ComplianceGraphSelectedValue[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const userCanAccess = isSuperAdmin(currentUser?.userProfile);
  // NOTE use this for setting new chart state
  // example:  dispatchChartState({ type: 'buCodes', value: ['1212','1234'],state:'updated' });
  const [chartState, dispatchChartState] = useReducer(chartReducer, { value: null, type: 'buCodes', state: 'initial' });

  const getParamsByActionType = (
    actionType:
      | 'byInitial'
      | 'byNewPageNumber'
      | 'byEnterSearchFilter'
      | 'byClearSearchFilter'
      | 'byLoadAfterDelete'
      | 'byRelatedBuFilter'
      | 'byLegalLorFilter'
      | 'byChartFilter'
      | 'byReset',
    changingParam?: { pageNumber?: number; itemPerPage?: number; q?: string; legalLorFilter?: string; relatedBuCodes?: string[] }
  ): LegalFilterQuery => {
    Logger([`[Incidents][getParamsByActionType] GET new IncidentList : ${actionType} changed`]);
    const params: LegalFilterQuery = { pageNumber: 0, itemPerPage: rowsPerPage };
    if (nameSearching) params.q = nameSearching;
    if (legalLorFilter) params.lorTypes = [legalLorFilter];
    if (changingParam && changingParam.relatedBuCodes) params.relatedBuCodes = changingParam.relatedBuCodes;
    else if (buRelatedFilter) params.relatedBuCodes = buRelatedFilter;
    if (statusFilter.length)
      params.status = statusFilter.reduce((prev, current) => {
        return [...prev, current.type === 'progress' ? 3 : current.type === 'complete' ? 5 : 4];
      }, [] as (LegalStatusSchema | 4)[]);
    if (complianceBuFilter.length)
      params.complianceBu = complianceBuFilter.reduce((prev, current) => {
        return [...prev, current.relatedBU.buCode];
      }, [] as string[]);
    if (nonComplianceBuFilter.length)
      params.nonComplianceBu = nonComplianceBuFilter.reduce((prev, current) => {
        return [...prev, current.relatedBU.buCode];
      }, [] as string[]);

    if (actionType === 'byNewPageNumber' && changingParam?.pageNumber !== undefined && changingParam?.itemPerPage !== undefined) {
      return { ...params, ...changingParam };
    } else if (actionType === 'byEnterSearchFilter') {
      const newSearchText = changingParam?.q ? changingParam?.q : '';
      setPage(0);
      setNameSearching(newSearchText);
      return { ...params, q: newSearchText };
    } else if (actionType === 'byClearSearchFilter') {
      setPage(0);
      setNameSearching('');
      return { ...params, q: '' };
    } else if (actionType === 'byLoadAfterDelete' && changingParam?.pageNumber !== undefined) {
      setPage(changingParam.pageNumber);
      return { ...params, ...changingParam };
    } else if (actionType === 'byLegalLorFilter' && changingParam?.legalLorFilter !== undefined) {
      setLegalLorFilter(changingParam.legalLorFilter);
      return { ...params, lorTypes: [changingParam.legalLorFilter] };
    } else if (actionType === 'byReset') {
      setPage(0);
      setRowsPerPage(10);
      setNameSearching('');
      setLegalLorFilter('');
      setBuRelatedFilter([]);
      setStatusFilter([]);
      setComplianceBuFilter([]);
      setNonComplianceBuFilter([]);
      // NOTE: to reset and call new chart data
      dispatchChartState({ type: 'buCodes', value: undefined, state: 'updated' });
      return { pageNumber: 0, itemPerPage: 10 };
    } else if (actionType === 'byRelatedBuFilter') {
      setPage(0);
      setStatusFilter([]);
      setComplianceBuFilter([]);
      setNonComplianceBuFilter([]);
      return { ...params, status: undefined, complianceBu: undefined, nonComplianceBu: undefined };
    } else {
      setPage(0);
      return { ...params };
    }
  };

  const onCallBackRelatedBuFilter = (type: CallBackFilterType, payload?: any) => {
    if (type === 'setSelectedFilter') {
      if (payload !== undefined) {
        setBuRelatedFilter(payload);
        dispatchChartState({ type: 'buCodes', value: { buRelate: payload, loading: true }, state: 'updated' });
        legalStore.setLegalList(getParamsByActionType('byRelatedBuFilter', { relatedBuCodes: payload }));
        if (payload && payload.length) {
          const allBu = legalStore?.legalBuRelatedFilter?.data?.data;
          if (allBu && allBu.length) {
            const temp1: ComplianceGraphSelectedValue[] = [];
            const temp2: ComplianceGraphSelectedValue[] = [];
            payload.forEach((d) => {
              const t = allBu.find((k) => k.buCode === d);
              if (t) {
                temp1.push({ relatedBU: t, value: 1, type: 'C' });
                temp2.push({ relatedBU: t, value: 1, type: 'NC' });
              }
            });
            setComplianceBuFilter(temp1);
            setNonComplianceBuFilter(temp2);
          }
        } else {
          setComplianceBuFilter([]);
          setNonComplianceBuFilter([]);
        }
      }
    }
    // else if (type === 'finishSelectingFilter') {
    //   legalStore.resetStatus('legal/charts');
    //   dispatchChartState({ type: 'buCodes', value: { buRelate: buRelatedFilter, loading: true }, state: 'updated' });
    //   legalStore.setLegalList(getParamsByActionType('byRelatedBuFilter'));
    // }
  };
  const onCallBackLegalLorFilter = (type: 'selectOption' | 'clear', payload?: any) => {
    if (type === 'selectOption' && payload !== undefined) legalStore.setLegalList(getParamsByActionType('byLegalLorFilter', { legalLorFilter: payload }));
    if (type === 'clear') legalStore.setLegalList(getParamsByActionType('byLegalLorFilter', { legalLorFilter: '' }));
  };

  useEffect(() => {
    // NOTE: call new legalList after selected any chart filters
    if (chartFiltersChange) {
      legalStore.setLegalList(getParamsByActionType('byChartFilter'));
      setChartFiltersChange(false);
    }
  }, [statusFilter, complianceBuFilter, nonComplianceBuFilter]);

  const onEnterSearch = (searchText: string) => {
    legalStore.setLegalList(getParamsByActionType('byEnterSearchFilter', { q: searchText || '' }));
  };
  const onClearSearch = () => {
    if (nameSearching) {
      legalStore.setLegalList(getParamsByActionType('byClearSearchFilter'));
    }
  };

  useEffect(() => {
    if (!listLoad) {
      // if (legalStore.legalListData.state === 'initial' && !legalStore.legalListData.data) {
      legalStore.setLegalList(getParamsByActionType('byInitial'));
      dispatchChartState({ type: 'buCodes', value: { buRelate: [], loading: false }, state: 'updated' });
      // }
      setListLoad(1);
    }
  }, [listLoad, setListLoad, legalStore]);

  useEffect(() => {
    if (legalStore.legalDeleteData.state === 'ready') {
      const isLastPage = checkIsLastPage(
        legalStore.legalListData.data.pagination.pageNumber,
        legalStore.legalListData.data.pagination.totalItems,
        legalStore.legalListData.data.pagination.itemPerPage
      );
      if (legalStore.legalListData.data.data.length === 0 && isLastPage && legalStore.legalListData.data.pagination.pageNumber !== 0) {
        legalStore.setLegalList(getParamsByActionType('byLoadAfterDelete', { pageNumber: page - 1 }), 'hidden_load_after_deleted');
      } else {
        legalStore.setLegalList(getParamsByActionType('byLoadAfterDelete', { pageNumber: page }), 'hidden_load_after_deleted');
      }
    }
  }, [legalStore.legalDeleteData.state]);

  const onCallBackPagination = (type: 'setRowsPerPage' | 'setPage' | 'setNewPage', payload: any) => {
    if (type === 'setPage' && payload !== undefined) setPage(payload);
    if (type === 'setRowsPerPage' && payload !== undefined) setRowsPerPage(payload);
    if (type === 'setNewPage' && payload !== undefined) {
      legalStore.setLegalList(getParamsByActionType('byNewPageNumber', { pageNumber: payload.newPage, itemPerPage: payload.rowsPerPage }));
    }
  };

  // useEffect(() => {
  //   if (mount > 0) {
  //     tempFilters = {
  //       nameSearching: nameSearching,
  //       legalLorFilter: legalLorFilter,
  //       buRelatedFilter: buRelatedFilter,
  //       statusFilter: statusFilter,
  //       complianceBuFilter: complianceBuFilter,
  //       nonComplianceBuFilter: nonComplianceBuFilter,
  //     };
  //   } else {
  //     setMount(1);
  //     if (tempFilters.nameSearching) setNameSearching(tempFilters.nameSearching);
  //     if (tempFilters.legalLorFilter) setLegalLorFilter(tempFilters.legalLorFilter);
  //     if (tempFilters.buRelatedFilter) setBuRelatedFilter(tempFilters.buRelatedFilter);
  //     if (tempFilters.statusFilter) setStatusFilter(tempFilters.statusFilter);
  //     if (tempFilters.complianceBuFilter) setComplianceBuFilter(tempFilters.complianceBuFilter);
  //     if (tempFilters.nonComplianceBuFilter) setNonComplianceBuFilter(tempFilters.nonComplianceBuFilter);
  //     // legalStore.resetStatus('legal/charts');
  //     dispatchChartState({ type: 'buCodes', value: { buRelate: tempFilters.buRelatedFilter, loading: false }, state: 'updated' });
  //   }
  // }, [nameSearching, legalLorFilter, buRelatedFilter, statusFilter, complianceBuFilter, nonComplianceBuFilter]);

  const paginationState =
    legalStore.legalListData.state === 'ready'
      ? {
          totalItems: legalStore.legalListData?.data?.pagination.totalItems,
          page: legalStore.legalListData?.data?.pagination.pageNumber,
          rowsPerPage: legalStore.legalListData?.data?.pagination.itemPerPage,
          noFilterTotalItems: legalStore.legalListData?.data?.pagination.allItems,
          legalItems: legalStore.legalListData?.data?.pagination.legalNumber,
        }
      : {
          totalItems: rowsPerPage * (page + 1),
          page: page,
          rowsPerPage: rowsPerPage,
          noFilterTotalItems: rowsPerPage * (page + 1),
          legalItems: rowsPerPage * (page + 1),
        };

  return (
    <Paper elevation={0}>
      <Typography variant="h1" className="mb-6">
        {PAGE_TITLE}
      </Typography>
      <Box className="flex mb-6 w-full h-auto">
        <Filters
          store={legalStore}
          filterProps={{
            buRelatedFilter: buRelatedFilter,
            onCallBackRelatedBuFilter: onCallBackRelatedBuFilter,
            legalLorFilter: legalLorFilter,
            onCallBackLegalLorFilter: onCallBackLegalLorFilter,
            resetAllFilter: () => {
              legalStore.setLegalList(getParamsByActionType('byReset'));
              dispatchChartState({ type: 'buCodes', value: { loading: true }, state: 'updated' });
            },
          }}
        />
        <Box className="w-full h-auto flex">
          <LegalChartSections
            chartQueryState={chartState}
            // NOTE: sent only store instead of all props
            // {...(props as any)}
            store={legalStore}
            onClickComplianceChart={(data) => {
              setChartFiltersChange(true);
              if (data.type === 'C') {
                const alreadySelected = complianceBuFilter.find((d) => d.type === data.type && d.relatedBU.buCode === data.relatedBU.buCode);
                if (!alreadySelected) setComplianceBuFilter([...complianceBuFilter, data]);
              } else if (data.type === 'NC') {
                const alreadySelected = nonComplianceBuFilter.find((d) => d.type === data.type && d.relatedBU.buCode === data.relatedBU.buCode);
                if (!alreadySelected) setNonComplianceBuFilter([...nonComplianceBuFilter, data]);
              }
              Logger(['[ChartSections][onClickComplianceChart] >>>', data]);
            }}
            onClickStatusChart={(data) => {
              setChartFiltersChange(true);
              const alreadySelected = statusFilter.find((d) => d.type === data.type);
              if (!alreadySelected) setStatusFilter([...statusFilter, data]);
              Logger(['[ChartSections][onClickStatusChart] >>>', data]);
            }}
            onReadyComplianceChart={() => {
              Logger(['[ChartSections][onReadyComplianceChart]']);
            }}
            onReadyStatusChart={() => {
              Logger(['[ChartSections][onReadyStatusChart]']);
            }}
          />
        </Box>
      </Box>
      <Paper elevation={4}>
        <Box className="flex p-6">
          <SearchBoxInput onEnterSearch={onEnterSearch} onClearSearch={onClearSearch} nameSearching={nameSearching} />
          <Box className="w-auto mr-6">
            <Typography className="font-medium w-auto inline-flex mr-2 mt-2">Filters:</Typography>
            {statusFilter.length === 0 && complianceBuFilter.length === 0 && nonComplianceBuFilter.length === 0 && (
              <Typography className="w-auto inline-flex mr-2 mt-2">All</Typography>
            )}
            {statusFilter.map((itemStatus, index) => {
              return (
                <ChipCustom
                  key={index}
                  label={`${itemStatus.type === 'progress' ? 'In progress' : itemStatus.type === 'complete' ? 'Completed' : 'Overdue'}`}
                  onDelete={() => {
                    setChartFiltersChange(true);
                    const newSelected = statusFilter.filter((i) => i.type !== itemStatus.type);
                    setStatusFilter(newSelected);
                  }}
                />
              );
            })}
            {complianceBuFilter.map((itemC, index) => {
              return (
                <React.Fragment key={index}>
                  {statusFilter.length > 0 && index === 0 && (
                    <Box component={'span'} sx={{ marginX: '8px', borderLeft: '2px solid lightgray', height: 'auto' }} />
                  )}
                  <ChipCustom
                    label={`${itemC.relatedBU.buName} (${itemC.type})`}
                    onDelete={() => {
                      setChartFiltersChange(true);
                      const newSelected = complianceBuFilter.filter((i) => i.relatedBU.buCode !== itemC.relatedBU.buCode);
                      setComplianceBuFilter(newSelected);
                    }}
                  />
                </React.Fragment>
              );
            })}
            {nonComplianceBuFilter.map((itemNC, index) => {
              return (
                <React.Fragment key={index}>
                  {(statusFilter.length > 0 || complianceBuFilter.length > 0) && index === 0 && (
                    <Box component={'span'} sx={{ marginX: '8px', borderLeft: '2px solid lightgray', height: 'auto' }} />
                  )}
                  <ChipCustom
                    key={index}
                    label={`${itemNC.relatedBU.buName} (${itemNC.type})`}
                    onDelete={() => {
                      setChartFiltersChange(true);
                      const newSelected = nonComplianceBuFilter.filter((i) => i.relatedBU.buCode !== itemNC.relatedBU.buCode);
                      setNonComplianceBuFilter(newSelected);
                    }}
                  />
                </React.Fragment>
              );
            })}
          </Box>
          {userCanAccess && (
            <CreateUpdateLegalModal
              {...(props as any)}
              updateId={legalUid}
              onCloseModal={() => {
                setLegalUid({ type: 'close', uid: null as any });
              }}
              reloadChart={() => {
                dispatchChartState({ type: 'buCodes', value: { buRelate: buRelatedFilter, loading: false }, state: 'updated' });
              }}
            />
          )}
        </Box>
        <Box className="px-6">
          <EnhancedTableLegals
            // data={mockupRows}
            {...(props as any)}
            onUpdate={(id) => {
              setLegalUid({ type: 'open', uid: id as any });
            }}
            isFiltered={
              !!nameSearching ||
              !!legalLorFilter ||
              buRelatedFilter.length > 0 ||
              statusFilter.length > 0 ||
              complianceBuFilter.length > 0 ||
              nonComplianceBuFilter.length > 0
            }
          />
        </Box>
        <Box>
          {(legalStore.legalListData.state === 'initial' || legalStore.legalListData.state === 'loading' || legalStore.legalDeleteData.state === 'ready') && (
            <LinearProgress style={{ margin: '0px 24px 0px 24px' }} />
          )}
        </Box>
        {legalStore.legalListData?.state !== 'error' && (
          <EnhancedTablePagination
            totalItems={paginationState.totalItems}
            page={paginationState.page}
            rowsPerPage={paginationState.rowsPerPage}
            onCallBackPagination={onCallBackPagination}
            isSoftReload={legalStore.legalDeleteData && legalStore.legalDeleteData.state === 'ready'}
            pageType="legal"
            noFilterTotalItems={paginationState.noFilterTotalItems}
            legalItems={paginationState.legalItems}
          />
        )}
      </Paper>
    </Paper>
  );
}

export default Legals;
