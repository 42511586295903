import { AzureAuthProviderProps, IncidentReporters, IncidentStore, PropDefaults } from '@/src/types';
import { Box, Typography } from '@mui/material';
import { EnhancedTableIncidentCorrectiveActionDetails, Icon } from '@/src/components';

interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export default function PartEightReport(props: IncidentProps & IncidentStore) {
  const { store } = props;
  const {
    // incidentBusinessUnitsFilter,
    // // incidentCreateUpdateData,
    // incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    // setIncidentDetail,
    incidentDetailData,
  } = store;

  const incidentDetailCorrectiveAction = incidentDetailData?.data?.data?.correctiveAction?.actionItems || [];
  const incidentDetailReporter = incidentDetailData?.data?.data?.reporters || ({} as IncidentReporters);

  // Logger(['[IncidentDetailReport][incidentDetailCorrectiveAction]', incidentDetailCorrectiveAction]);

  return (
    <>
      <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
        <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
        Corrective action
      </Typography>
      <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:mt-5 bg-white">
        <Box className="border-0 border-b border-solid border-gray-200 border-r md:flex p-4 xxl:px-4 xxl:py-3">
          <EnhancedTableIncidentCorrectiveActionDetails data={incidentDetailCorrectiveAction.length ? incidentDetailCorrectiveAction : []} />
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Report by
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailReporter?.reportBy
                ? `${incidentDetailReporter?.reportBy?.firstName ? incidentDetailReporter?.reportBy?.firstName : '-'} ${
                    incidentDetailReporter?.reportBy?.lastName ? incidentDetailReporter?.reportBy?.lastName : '-'
                  } ${incidentDetailReporter?.reportBy?.position ? incidentDetailReporter?.reportBy?.position : '-'} (${
                    incidentDetailReporter?.reportBy?.buName ? incidentDetailReporter?.reportBy?.buName : ''
                  })`
                : '-'}
            </Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Approved by
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailReporter?.approveBy
                ? `${incidentDetailReporter?.approveBy?.firstName ? incidentDetailReporter?.approveBy?.firstName : '-'} ${
                    incidentDetailReporter?.approveBy?.lastName ? incidentDetailReporter?.approveBy?.lastName : '-'
                  } ${incidentDetailReporter?.approveBy?.position ? incidentDetailReporter?.approveBy?.position : '-'} (${
                    incidentDetailReporter?.approveBy?.buName ? incidentDetailReporter?.approveBy?.buName : ''
                  })`
                : '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
