import React, { useReducer } from 'react';
import { PropDefaults } from '@/types/common';
import { Paper, Typography, Box, MenuItem, OutlinedInput, Select, SelectChangeEvent, Button, LinearProgress } from '@mui/material';
import { EnhancedTableIncidents, SearchBoxInput, Icon, EnhancedTablePagination, FilterMultiSelect, IncidentChartSections } from '@/components/index';
import { AzureAuthProviderProps, IncidentStore } from '@/src/types/store';
import { BusinessUnitsSchema, CallBackFilterType, QueryIncidentList, IncidentStatusReport } from '@/src/types';
import { monthData, incidentStatusData } from '@/src/static';
import { NavLink, useLocation } from 'react-router-dom';
import { checkIsLastPage, getYearArray, isAdmin, isUserOrBuAdmin } from '@/src/utils';
import { Logger } from '@/logger/index';
import { useGlobalContext } from '@/store/index';
import { chartReducer } from '@/src/utils/reducers';

const PAGE_TITLE = 'Incidents';
interface IncidentsProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
}

function Incidents(props: IncidentsProps & IncidentStore) {
  const location = useLocation();
  const locationState: { fromPageType: 'create' | 'update' } = location.state as any;
  const { currentUser } = useGlobalContext();
  const now = new Date();
  const yearData = getYearArray();
  const { store } = props;
  const incidentListData = store.incidentListData.data;
  const [mount, setMount] = React.useState(0);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [monthFilter, setMonthFilter] = React.useState<number>(0);
  const [yearFilter, setYearFilter] = React.useState<number>(now.getFullYear());
  const [businessUnitsFilter, setBusinessUnitsFilter] = React.useState<string[]>([]);
  const [nameSearching, setNameSearching] = React.useState<string>('');
  const [prevSelectedMonthFilter, setPrevSelectedMonthFilter] = React.useState(monthFilter);
  const [prevSelectedYearFilter, setPrevSelectedYearFilter] = React.useState(yearFilter);
  const [isOpenMonthFilter, setOpenMonthFilter] = React.useState<boolean>(false);
  const [isOpenYearFilter, setOpenYearFilter] = React.useState<boolean>(false);
  const [statusFilter, setStatusFilter] = React.useState<IncidentStatusReport[]>([1, 2, 3]);
  const [prevStatusFilter, setPrevStatusFilter] = React.useState<IncidentStatusReport[]>(statusFilter);
  const [isNewIncidentAdded, setIsNewIncidentAdded] = React.useState<boolean>(false);
  // set custom loading in progress to override the state
  const [loadingInProgress, setLoadingInProgress] = React.useState<boolean>(false);
  // byMonthYearFilter
  const [chartState, dispatchChartState] = useReducer(chartReducer, {
    value: { year: now.getFullYear().toString() },
    type: 'year',
    state: 'initial',
  });

  const getParamsByActionType = (
    actionType:
      | 'byInitial'
      | 'byNewPageNumber'
      | 'byEnterSearchFilter'
      | 'byClearSearchFilter'
      | 'byBusinessUnitsFilter'
      | 'byStatusFilter'
      | 'byMonthYearFilter'
      | 'byLoadAfterDelete',
    changingParam?: { pageNumber?: number; itemPerPage?: number; q?: string },
    buUnits?: string[],
    statuses?: IncidentStatusReport[],
    month?: number,
    year?: number
  ): QueryIncidentList => {
    Logger([`[Incidents][getParamsByActionType] GET new IncidentList : ${actionType} changed`]);
    const params: QueryIncidentList = { pageNumber: 0, itemPerPage: rowsPerPage, year: yearFilter };
    if (year || year === 0) params.year = year;
    if (buUnits && buUnits!.length) params.businessUnits = buUnits;
    else if (businessUnitsFilter.length) params.businessUnits = businessUnitsFilter;
    if (statuses && statuses!.length) params.status = statuses;
    else if (statusFilter.length) params.status = statusFilter;
    if (nameSearching) params.q = nameSearching;
    if (month || month === 0) params.month = month;
    else if (monthFilter !== 0) params.month = monthFilter;

    if (actionType === 'byNewPageNumber' && changingParam?.pageNumber !== undefined && changingParam?.itemPerPage !== undefined) {
      return { ...params, ...changingParam };
    } else if (actionType === 'byEnterSearchFilter') {
      const newSearchText = changingParam?.q ? changingParam?.q : '';
      setPage(0);
      setNameSearching(newSearchText);
      return { ...params, q: newSearchText };
    } else if (actionType === 'byClearSearchFilter') {
      setPage(0);
      setNameSearching('');
      return { ...params, q: '' };
    } else if (actionType === 'byLoadAfterDelete' && changingParam?.pageNumber !== undefined) {
      setPage(changingParam.pageNumber);
      return { ...params, ...changingParam };
    } else {
      setPage(0);
      return { ...params };
    }
  };

  React.useEffect(() => {
    if (!mount) {
      // NOTE: to get store.incidentListData and store.incidentBusinessUnitsFilter when first mount
      if (store.incidentListData.state === 'initial' && !incidentListData?.data) store.setIncidentList(getParamsByActionType('byInitial'));
      if (store.incidentBusinessUnitsFilter.state === 'initial' && !store.incidentBusinessUnitsFilter.data) store.setIncidentBusinessUnitsFilter();

      // NOTE: to check after route back from created success, and update pagination in store.incidentListData
      if (store.incidentListData.state === 'ready' && incidentListData?.data && locationState?.fromPageType) {
        if (locationState?.fromPageType === 'create') setIsNewIncidentAdded(true);
      }
      setMount(1);
    }

    // NOTE: to reset pagination, after loading new incidentListData
    if (mount && store.incidentListData.state === 'loading' && isNewIncidentAdded) setIsNewIncidentAdded(false);
  }, [mount, setMount, store.incidentListData.state, locationState]);

  React.useEffect(() => {
    if (store.incidentDetailDeleteData.state === 'ready') {
      // to check if the current page is the last page
      const isLastPage = checkIsLastPage(
        incidentListData.pagination.pageNumber,
        incidentListData.pagination.totalItems,
        incidentListData.pagination.itemPerPage
      );

      // to check if the row that has been deleted is only a last one item in the last list page then paginate to previous pageNumber (except for last page = 0)
      if (incidentListData.data.length === 0 && isLastPage && incidentListData.pagination.pageNumber !== 0) {
        store.setIncidentList(getParamsByActionType('byLoadAfterDelete', { pageNumber: page - 1 }), 'hidden_load_after_deleted');
      } else {
        store.setIncidentList(getParamsByActionType('byLoadAfterDelete', { pageNumber: page }), 'hidden_load_after_deleted');
      }
    }
  }, [store.incidentDetailDeleteData]);

  React.useEffect(() => {
    return () => {
      // NOTE: to reset store.incidentListData back to the data with initial filter after unmount
      store.resetStatus('incidents');
    };
  }, []);

  const paginationState =
    store.incidentListData.state === 'ready'
      ? {
          totalItems: isNewIncidentAdded ? incidentListData?.pagination.totalItems + 1 : incidentListData?.pagination.totalItems,
          page: incidentListData?.pagination.pageNumber,
          rowsPerPage: incidentListData?.pagination.itemPerPage,
        }
      : { totalItems: rowsPerPage * (page + 1), page: page, rowsPerPage: rowsPerPage };

  const onCallBackPagination = (type: 'setRowsPerPage' | 'setPage' | 'setNewPage', payload: any) => {
    if (type === 'setPage' && payload !== undefined) setPage(payload);
    if (type === 'setRowsPerPage' && payload !== undefined) setRowsPerPage(payload);
    if (type === 'setNewPage' && payload !== undefined) {
      store.setIncidentList(getParamsByActionType('byNewPageNumber', { pageNumber: payload.newPage, itemPerPage: payload.rowsPerPage }));
    }
  };

  const onEnterSearch = (searchText: string) => {
    store.setIncidentList(getParamsByActionType('byEnterSearchFilter', { q: searchText || '' }));
  };
  const onClearSearch = () => {
    if (nameSearching) {
      store.setIncidentList(getParamsByActionType('byClearSearchFilter'));
    }
  };

  const onCallBackBusinessUnitsFilter = (type: CallBackFilterType, payload?: any) => {
    if (type === 'setSelectedFilter' && payload !== undefined) {
      setBusinessUnitsFilter(payload);
      store.setIncidentList(getParamsByActionType('byBusinessUnitsFilter', {}, payload));
    }
    // if (type === 'finishSelectingFilter') store.setIncidentList(getParamsByActionType('byBusinessUnitsFilter'));
  };
  const onCallBackStatusFilter = (type: CallBackFilterType, payload?: any) => {
    if (type === 'setSelectedFilter' && payload !== undefined) {
      setPrevStatusFilter(statusFilter);
      const _statusFilter = payload.sort((a, b) => a - b);
      setStatusFilter(_statusFilter);
      store.setIncidentList(getParamsByActionType('byStatusFilter', {}, [], _statusFilter));
    }
    // if (type === 'finishSelectingFilter') {
    //   store.setIncidentList(getParamsByActionType('byStatusFilter'));
    //   setPrevStatusFilter(statusFilter);
    // }
  };

  const handleMonthChange = (event: SelectChangeEvent) => {
    const _month = parseInt(event.target.value);
    if (monthFilter !== _month) {
      setMonthFilter(_month);
      setOpenMonthFilter(false);
      setLoadingInProgress(true);
      store.setIncidentList(getParamsByActionType('byMonthYearFilter', {}, [], [], _month), 'update', (ok, data) => {
        setLoadingInProgress(false);
      });
    }
  };
  const handleYearChange = (event: SelectChangeEvent) => {
    const _year = parseInt(event.target.value);
    if (yearFilter !== _year) {
      setYearFilter(_year);
      setOpenYearFilter(false);
      const d = { type: 'year', value: { year: _year, loading: true }, state: 'updated' };
      dispatchChartState(d as any);
      store.setIncidentList(getParamsByActionType('byMonthYearFilter', {}, [], [], 0, _year), 'update', (ok, data) => {
        setLoadingInProgress(false);
      });
    }
  };
  const handleOpen = (filter: 'month' | 'year') => {
    if (filter === 'month') {
      setOpenMonthFilter(true);
      setPrevSelectedMonthFilter(monthFilter);
    }
    if (filter === 'year') {
      setOpenYearFilter(true);
      setPrevSelectedYearFilter(yearFilter);
    }
  };
  const handleClose = (filter: 'month' | 'year') => {
    if (filter === 'month') setOpenMonthFilter(false);
    if (filter === 'year') setOpenYearFilter(false);
    if (filter === 'year' && yearFilter !== prevSelectedYearFilter) {
      // NOTE: to reset chart after selected new yearFilter
      const d = { type: 'year', value: { year: yearFilter, loading: true }, state: 'updated' };
      dispatchChartState(d as any);
    }

    if (monthFilter !== prevSelectedMonthFilter || yearFilter !== prevSelectedYearFilter) {
      setLoadingInProgress(true);
      store.setIncidentList(getParamsByActionType('byMonthYearFilter'), 'update', (ok, data) => {
        setLoadingInProgress(false);
      });
    }
  };

  const loadingData =
    loadingInProgress === true ||
    store.incidentListData.state === 'initial' ||
    store.incidentListData.state === 'loading' ||
    store.incidentDetailDeleteData.state === 'ready';

  return (
    <Paper elevation={0}>
      <Typography variant="h1" className="mb-6">
        {PAGE_TITLE}
      </Typography>
      <Box className="flex mb-6">
        <Box className="grid grid-cols-2 gap-6 w-full">
          <IncidentChartSections
            chartQueryState={chartState}
            {...(props as any)}
            onReadyStatusChart={() => {
              Logger(['[ChartSections][onReadyStatusChart]']);
            }}
          />
        </Box>
      </Box>
      <Paper elevation={4}>
        <Box className="border-0 border-b border-solid border-gray-300 flex px-6">
          <NavLink to="/incidents" className="font-medium text-lg p-5 pb-2 no-underline text-blue-700 border-0 border-b-2 border-solid border-blue-700">
            Incidents
          </NavLink>
          <NavLink to="/incidents/man-hour" className="font-medium text-lg p-5 pb-2 no-underline text-gray-900">
            Man-hour
          </NavLink>
        </Box>
        <Box className="flex flex-wrap p-6 pb-0">
          <SearchBoxInput onEnterSearch={onEnterSearch} onClearSearch={onClearSearch} />
          <FilterMultiSelect
            clearable
            filterData={store.incidentBusinessUnitsFilter?.data?.data || ([] as BusinessUnitsSchema[])}
            isLoadingFilterData={store.incidentBusinessUnitsFilter.state !== 'ready'}
            selectedFilter={businessUnitsFilter}
            onCallBackFilter={onCallBackBusinessUnitsFilter}
            dropdownSetting={{
              valueKey: 'buCode',
              labelKey: 'buName',
              noSelectedText: 'All company',
            }}
            dropdownLabelConfig={(_value, _label) => `${_label} (${_value})`}
            sx={{ width: '200px' }}
            className="mr-5 mb-6"
          />
          <Select
            value={monthFilter as any}
            onChange={handleMonthChange}
            open={isOpenMonthFilter}
            onOpen={() => handleOpen('month')}
            onClose={(event: any) => {
              // if (event.target.selected === undefined)
              handleClose('month');
            }}
            input={<OutlinedInput />}
            sx={{ width: '120px' }}
            className="mr-2 mb-6"
          >
            {monthData.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={yearFilter as any}
            onChange={handleYearChange}
            open={isOpenYearFilter}
            onOpen={() => handleOpen('year')}
            onClose={(event: any) => {
              // if (event.target.selected === undefined)
              handleClose('year');
            }}
            displayEmpty
            input={<OutlinedInput />}
            sx={{ width: '120px' }}
            className="mr-5 mb-6"
          >
            {yearData
              .sort((a, b) => b - a)
              .map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
          </Select>
          <FilterMultiSelect
            clearable
            filterData={incidentStatusData || ([] as { value: number; label: string }[])}
            isLoadingFilterData={false}
            selectedFilter={statusFilter as any}
            onCallBackFilter={onCallBackStatusFilter}
            dropdownSetting={{
              valueKey: 'value',
              labelKey: 'label',
              noSelectedText: 'All status',
            }}
            dropdownLabelConfig={(_value, _label) => `${_label}`}
            sx={{ width: '162px' }}
            className="mr-5 mb-6"
          />
          {(isAdmin(currentUser?.userProfile) || isUserOrBuAdmin(currentUser?.userProfile)) && (
            <NavLink to="/incidents/create" className="no-underline ml-auto">
              <Button className="w-28" variant="contained" startIcon={<Icon icon="plus" color="#ffffff" viewBox="0 0 512 512" size={18} />}>
                Add
              </Button>
            </NavLink>
          )}
        </Box>
        <Box className="px-6">
          <EnhancedTableIncidents store={store} sortBy={prevStatusFilter} overrideLoadingInProgress={loadingInProgress} />
        </Box>

        <Box>{loadingData && <LinearProgress style={{ margin: '0px 24px 0px 24px' }} />}</Box>

        {store.incidentListData?.state !== 'error' && (
          <EnhancedTablePagination
            totalItems={paginationState.totalItems}
            page={paginationState.page}
            rowsPerPage={paginationState.rowsPerPage}
            onCallBackPagination={onCallBackPagination}
            isSoftReload={store.incidentDetailDeleteData && store.incidentDetailDeleteData.state === 'ready'}
          />
        )}
      </Paper>
    </Paper>
  );
}

export default Incidents;
