/* eslint-disable @typescript-eslint/no-use-before-define */
import { AzureAuthProviderProps, IncidentStore, PropDefaults } from '@/src/types';
import { Alert, Box, Button, IconButton, LinearProgress, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Logger } from '@/logger/index';
import { Navigate, NavLink, useOutletContext, useLocation } from 'react-router-dom';
import { Icon, ProgressBarIncident, IncidentDetailReport, YourTasksIncident } from '@/src/components';
import { useGlobalContext } from '@/store/index';
import { PAGE_NOT_FOUND_MESSAGE, incidentStatusText } from '@/src/static';
import { delay, exportLinkV2, userCanOnIncident } from '@/src/utils';
import { getClassNameStatusReport } from '@/src/utils/form';
// import { PAGE_NO_PERMISSION } from '@/static/common';

interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  detailId?: any;
  /** incident uid */
}

export const IncidentDetailPage: React.FC<IncidentProps> = (props) => {
  // const { history } = props;
  // const navigate = useNavigate();
  const { currentUser } = useGlobalContext();
  const contextProps: IncidentProps & IncidentStore = useOutletContext();
  const [mount, setMount] = useState<number>(0);
  const location = useLocation();
  const locationState: any = location.state;
  const isFromMyProfile = locationState?.from;
  const { detailId, store } = contextProps;

  const {
    //   incidentBusinessUnitsFilter,
    // incidentCreateUpdateData,
    //  incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    setIncidentProgress,
    setIncidentDetail,
    incidentDetailData,
    incidentReportApprovalData,
    incidentReportRemarksData,
    incidentProgressData,
    resetStatus,
    notify,
  } = store;

  Logger(['[IncidentDetail][detailId]', detailId]);
  Logger(['[IncidentDetail][progress]', incidentProgressData]);
  // ///////////////////////////////////
  // NOTE  RESET FORM DATA WHEN UNMOUNTING!
  useEffect(() => {
    return () => {
      // PROVIDE ALL UNMOUNT LOGIC HERE
      resetStatus('incidents/report/parts');
      resetStatus('incidents/progress');
    };
  }, []);
  // ///////////////////////////////////////

  useEffect(() => {
    if (!mount) {
      if (incidentDetailData.data?.data) {
        if (incidentDetailData.state === 'ready' && incidentDetailData.data?.data?.id !== detailId) {
          resetStatus('incidents/report/parts');
        }
      }

      if (incidentDetailData.state === 'initial' && !incidentDetailData.data?.data && detailId) {
        setIncidentDetail(detailId);
      }

      if (incidentDetailData.state === 'ready' && !!incidentDetailData.data?.data) {
        Logger(['[IncidentDetailPage][incidentDetailData]', incidentDetailData.data]);
      }

      if (incidentDetailData.state === 'error') {
        Logger(['[IncidentDetailPage][incidentDetailData]', incidentDetailData], 'error');
      }
      setMount(1);
    }

    if (incidentReportApprovalData.state === 'ready' && !!incidentReportApprovalData.data?.data) {
      Logger(['[IncidentDetailPage][incidentReportApprovalData]', incidentReportApprovalData.data]);
      resetStatus('incidents/report/approval');
      notify.onStatusChange('updated');

      // NOTE on remark update it will also update the object for reviewers/approvals /incidents/report/parts/{incidentId}
      // so we need to call the api again and get latest data with correct state from server
      // make anonymous call to this page api again
      delay(200).then(() => {
        setIncidentDetail(detailId, 'update', 'call again, report/approval updated');
        setIncidentProgress(detailId, 'update');
      });
    }

    if (incidentReportApprovalData.state === 'error') {
      Logger(['[IncidentDetailPage][incidentReportApprovalData]', incidentReportApprovalData.data], 'error');
      resetStatus('incidents/report/approval');
      notify.onStatusChange('error');
    }

    if (incidentReportRemarksData.state === 'ready' && !!incidentReportRemarksData.data?.data) {
      Logger(['[IncidentDetailPage][incidentReportRemarksData]', incidentReportRemarksData.data]);
      resetStatus('incidents/report/remarks');
      notify.onStatusChange('updated');
      // NOTE on remark update it will also update the object for reviewers/remarks /incidents/report/parts/{incidentId}
      // so we need to call the api again and get latest data with correct state from server
      // make anonymous call to this page api again
      delay(200).then(() => {
        setIncidentDetail(detailId, 'update', 'call again, report/remarks updated');
        setIncidentProgress(detailId, 'update');
      });
    }

    if (incidentReportRemarksData.state === 'error') {
      Logger(['[IncidentDetailPage][incidentReportRemarksData]', incidentReportRemarksData.data], 'error');
      resetStatus('incidents/report/remarks');
      notify.onStatusChange('error');
    }
  }, [resetStatus, mount, setMount, incidentDetailData, incidentReportRemarksData, incidentReportApprovalData]);

  /**
   * @NOTE
   *  only way to identify if report is owned by current user (general user roleId:3) that is not teamLead is to wait for incident report data to load and check its {status==draft} and {reporters.reportBy.employeeId === user...}
   */
  const userCanView = (() => {
    return userCanOnIncident(currentUser, incidentDetailData.data?.data, incidentDetailData.data?.data?.status as any, 'can_view');
  })();

  useEffect(() => {
    if (incidentProgressData.state === 'initial' && !incidentProgressData.data?.data) {
      setIncidentProgress(detailId);
    }
    if (incidentProgressData.state === 'ready' && !!incidentProgressData.data?.data) {
      Logger(['[IncidentDetailPage][incidentProgressData]', incidentProgressData.data]);
    }
  }, [incidentProgressData, setIncidentProgress]);

  Logger(['[IncidentDetailPage][incidentDetailData]', incidentDetailData]);
  Logger(['[IncidentDetailPage][userCanView]', userCanView]);

  const state: 'ready' | 'error' | 'loading' | 'deleted' | 'no_permission' = [
    store.incidentDetailData.state === 'ready' && !userCanView && 'no_permission',
    (store.incidentDetailData.state === 'ready' || store.incidentDetailData.state === 'deleted') && 'ready',
    // store.incidentDetailData.state === 'ready' && 'deleted',
    ['loading', 'initial'].indexOf(store.incidentDetailData.state) !== -1 && 'loading',
    store.incidentDetailData.state === 'error' && 'error',
  ].filter((n) => !!n)[0] as any;
  switch (state) {
    case 'deleted': {
      // when item deleted redirect to trainings
      return <Navigate to={`/incidents`} />;
    }
    case 'error': {
      return <Alert severity="error">{PAGE_NOT_FOUND_MESSAGE}</Alert>;
    }
    case 'loading': {
      return <LinearProgress style={{ margin: '0px 24px 0px 24px' }} />;
    }
    case 'no_permission': {
      // delay(4000).then(() => {
      //   navigate('/incidents');
      // });
      // return <Alert severity="error">{PAGE_NO_PERMISSION}</Alert>;

      Logger(
        [
          '[incidents/detail/{id}][redirect]',
          `redirecting to incidents/ list page because this page detailId:[ ${detailId} ] status has changed to [draft:0], and you are not the owner of this report`,
        ],
        'error'
      );

      return <Navigate to={`/incidents`} />;
    }
    case 'ready': {
      return <Render />;
    }

    default: {
      return null;
    }
  }

  function Render() {
    return (
      <Paper elevation={0}>
        {/** NOTE added to help debug we are using correct data */}
        {/* debug/uid: {store.incidentDetailData.data?.data?.id} */}
        <Box className="flex items-center">
          {isFromMyProfile ? (
            <NavLink to={`${isFromMyProfile}`}>
              <IconButton color="primary" aria-label="View details" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
              </IconButton>
            </NavLink>
          ) : (
            <NavLink to={`/incidents`}>
              <IconButton color="primary" aria-label="View details" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
              </IconButton>
            </NavLink>
          )}

          <Typography className="flex text-xl md:text-[24px] lg:text-[28px] font-medium">
            {store.incidentDetailData?.data?.data?.incidentNo}
            <span
              className={`py-[3px] px-2 text-sm my-auto font-normal rounded ml-3 ${getClassNameStatusReport(store.incidentDetailData?.data?.data?.status)}`}
            >
              {incidentStatusText[store.incidentDetailData?.data?.data?.status as any] || 'Invalid status'}
            </span>
          </Typography>

          <Box className="ml-auto mt-4 xl:hidden">
            <Button
              download
              href={exportLinkV2(`/incidents/export/${incidentDetailData.data.data.id}.pdf`)}
              className="w-36 ml-auto flex bg-white"
              variant="outlined"
              startIcon={<Icon icon="file" color="#3155FF" viewBox="0 0 384 512" size={18} />}
            >
              Export PDF
            </Button>
          </Box>
        </Box>
        <Paper elevation={0} className="flex bg-transparent">
          <IncidentDetailReport {...(props as any)} {...(contextProps as any)} />
          <Box
            // NOTE these 2 classes cause scrollbar we dont need: >> xl:overflow-y-auto xl:overflow-x-hidden <<
            className="w-full fixed bottom-0 left-0 h-auto top-auto right-auto  xl:h-full xl:w-full xl:sticky xl:ml-6 xl:right-8 xl:top-28 xl:pt-5 xl:max-w-[374px]"
            sx={{
              maxHeight: 'calc(100vh - 152px)',
              marginTop: '-30px',
            }}
          >
            <YourTasksIncident
              setFormRef={(type, decision) => {
                Logger(['[YourTasksIncident][setFormRef]', type, decision]);
              }}
              {...(contextProps as any)}
              {...(props as any)}
            />
            <Box className=" hidden xl:block">
              <ProgressBarIncident {...(contextProps as any)} {...(props as any)} />
            </Box>
          </Box>
        </Paper>
      </Paper>
    );
  }
};
