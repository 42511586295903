import React, { useState } from 'react';

import { AzureAuthProviderProps, IncidentStore, PropDefaults, BodyIncidentReportRemarks } from '@/src/types';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { useGlobalContext } from '@/store/index';
interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

/**
 * Adding remark depends on roleId, each remark has it own title
 */
export function AddRemark(props: IncidentProps & IncidentStore) {
  const { currentUser } = useGlobalContext();
  const [textRef, setTextRef] = useState<HTMLDivElement & { textValue?: string | null }>(null as any);
  // const { history, detailId, store } = props;
  const { store, title, detailId } = props;
  const {
    // incidentDetailData,
    setIncidentReportRemarks,
    incidentReportRemarksData,
  } = store;
  return (
    <>
      <Typography variant="h2" color="primary">
        {title}
      </Typography>

      <Box className="bg-white p-6  rounded-3xl border border-solid border-gray-300 mt-4">
        <Box className="">
          <Box className="flex">
            <img src={require('@/assets/icons/message-lines.png')} alt="Occupational" width={28} height={27} className="mr-3" />
            <Typography variant="h5">Add remark</Typography>
          </Box>
          <FormControl className="w-full mt-4">
            <TextField
              ref={(r) => {
                setTextRef(r as any);
              }}
              id="outlined-textarea"
              placeholder="Enter text here"
              multiline
              onBlur={(e) => {
                textRef.textValue = e.target.value || null;
              }}
            />
          </FormControl>
        </Box>
        <Box className="mt-6 flex">
          <Button
            disabled={incidentReportRemarksData.state !== 'initial'}
            variant="contained"
            onClick={() => {
              const body: BodyIncidentReportRemarks = {
                employeeId: currentUser.userProfile.employeeId,
                remark: textRef.textValue || null,
                roleId: currentUser?.userProfile?.roleId,
                //  incidentId: detailId,
              } as any;
              setIncidentReportRemarks(detailId, body);
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
}
