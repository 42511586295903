import { AzureAuthProviderProps, IncidentStore, PropDefaults } from '@/src/types';
import { Typography, Box } from '@mui/material';
import { propertyDamage } from '@/src/static/incidents/form.data';
import { printDate } from '@/src/utils';
import { Icon } from '@/src/components';
interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export default function PartFourReport(props: IncidentProps & IncidentStore) {
  // const { history, detailId, store } = props;
  const { store } = props;
  const {
    // incidentBusinessUnitsFilter,
    // // incidentCreateUpdateData,
    // incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    // setIncidentDetail,
    incidentDetailData,
  } = store;

  const incidentDetailDataPropertyDamage = incidentDetailData?.data?.data?.propertyDamage;

  return (
    <>
      <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
        <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
        Property damage
      </Typography>
      <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:mt-5 bg-white">
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Property Damage
          </Typography>
          <Typography className="lg:pl-6 w-full">
            {incidentDetailDataPropertyDamage?.propertyType?.length
              ? (incidentDetailDataPropertyDamage?.propertyType || []).map((propertyDamageCategory: any, index) => {
                  const labelPropertyDamageCategory = (propertyDamage.propertyDamageCategory || []).find(
                    (item) => item.value === propertyDamageCategory
                  )?.label;
                  return index === 0 ? labelPropertyDamageCategory : ', ' + labelPropertyDamageCategory;
                })
              : incidentDetailDataPropertyDamage?.propertyTypeOther
              ? ''
              : '-'}
            {incidentDetailDataPropertyDamage?.propertyType?.length && incidentDetailDataPropertyDamage?.propertyTypeOther ? ', ' : ' '}
            {incidentDetailDataPropertyDamage?.propertyTypeOther ? `Other (${incidentDetailDataPropertyDamage?.propertyTypeOther})` : ''}
          </Typography>
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Date of arrival at Clinic /Hospital
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataPropertyDamage?.arrivalDate ? printDate(incidentDetailDataPropertyDamage?.arrivalDate) : '-'}
            </Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Time of arrival at Clinic /Hospital
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataPropertyDamage?.arrivalDate ? printDate(incidentDetailDataPropertyDamage?.arrivalDate, 'HH:mm') : '-'}
            </Typography>
          </Box>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Details of Treatment
          </Typography>
          <Typography className="lg:pl-6 w-full">{incidentDetailDataPropertyDamage?.treatmentDetail || '-'}</Typography>
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Deposition
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataPropertyDamage?.deposition?.length
                ? (incidentDetailDataPropertyDamage?.deposition || []).map((propertyDamageDeposition: any, index) => {
                    const labelPropertyDeposition = (propertyDamage?.propertyDamageDeposition || []).find(
                      (item) => item.value === propertyDamageDeposition
                    )?.label;
                    return index === 0 ? labelPropertyDeposition : ', ' + labelPropertyDeposition;
                  })
                : '-'}
            </Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Incident Classification
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataPropertyDamage?.incidentClassification?.length
                ? (incidentDetailDataPropertyDamage?.incidentClassification || []).map((propertyDamageDeposition: any, index) => {
                    const labelPropertyDeposition = (propertyDamage?.propertyDamageIncident || []).find(
                      (item) => item.value === propertyDamageDeposition
                    )?.label;
                    return index === 0 ? labelPropertyDeposition : ', ' + labelPropertyDeposition;
                  })
                : '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
