/** declare only http service related requests */

import { GetBusinessUnits, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from './..';
/**
 * @description Get currently logged employee role, we are calling with accessToken in header
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const BusinessUnitsGet = (prefix: string = 'business-units', tokens: HttpTokens, hardRejection = true): Promise<GetBusinessUnits> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<GetBusinessUnits>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetBusinessUnits>;
};
