import { ContextProviderProps, NotificationStatus, StoreStateDefaultV3, StoreStateV3 } from '@/types/store';
import { useState, useEffect, useContext } from 'react';
import {
  IncidentListGet,
  BusinessUnitsGet,
  IncidentCreateUpdatePost,
  IncidentQueryTypesGet,
  IncidentDetailGet,
  IncidentFileRemove,
  IncidentFileUpload,
  EmployeeSearchGet,
  EmployeeQueryGet,
  IncidentDetailDelete,
  IncidentReportApproval,
  IncidentReportRemarks,
  IncidentProgressGet,
  IncidentChartsGet,
} from '@/services/http';
import { IncidentContext } from './context';
import {
  HttpTokens,
  IncidentStore,
  QueryIncidentList,
  GetIncidentList,
  GetBusinessUnits,
  PostIncidentCreateUpdate,
  GetIncidentQueryTypes,
  IncidentBodyData,
  QueryIncidentTypes,
  IncidentStatusType,
  GetIncidentDetail,
  BodyUploadIncidentFile,
  QueryRemoveIncidentImage,
  EmployeeSearchQuery,
  UploadIncidentFile,
  RemoveIncidentFile,
  GetEmployeeBySearch,
  EmployeeByQuery,
  GetEmployeeByQuery,
  DeleteIncidentDetail,
  PostIncidentReportApproval,
  PostIncidentReportRemarks,
  BodyIncidentReportApproval,
  BodyIncidentReportRemarks,
  GetIncidentProgress,
  GetIncidentsCharts,
  QueryIncidentsCharts,
  IncidentsChartSchema,
} from '@/src/types';
import { getJwtToken, transformIncidentDataToListPageStore } from '@/src/utils';
import { Logger } from '@/logger/index';
// import { incidentProgressMockData } from '@/src/static/incidents/store/mockupData';

/**
 * to give us access to azure for admin
 */

export const useIncidentContextInitialState = () => useContext(IncidentContext);

const IncidentContextProvider = (props: ContextProviderProps) => {
  const { children } = props;
  const contextInitialState = useIncidentContextInitialState();
  const tokens: HttpTokens = { jwt: getJwtToken() };

  // const [renderUpdate, setRenderUpdate] = useState<number>(0);
  const [notificationStatus, setNotificationStatus] = useState<NotificationStatus>(contextInitialState.store.notify.notificationStatus);
  const [incidentListData, setIncidentList] = useState<StoreStateDefaultV3<GetIncidentList>>(contextInitialState.store.incidentListData);
  const [incidentBusinessUnitsFilter, setIncidentBusinessUnitsFilter] = useState<StoreStateDefaultV3<GetBusinessUnits>>(
    contextInitialState.store.incidentBusinessUnitsFilter
  );
  const [incidentCreateUpdateData, setIncidentCreateUpdate] = useState<StoreStateDefaultV3<PostIncidentCreateUpdate>>(
    contextInitialState.store.incidentCreateUpdateData
  );
  const [incidentQueryTypesData, setIncidentQueryTypes] = useState<StoreStateDefaultV3<GetIncidentQueryTypes>>(
    contextInitialState.store.incidentQueryTypesData
  );

  const [incidentDetailData, setIncidentDetail] = useState<StoreStateDefaultV3<GetIncidentDetail>>(contextInitialState.store.incidentDetailData);
  const [incidentDetailDeleteData, setIncidentDetailDelete] = useState<StoreStateDefaultV3<DeleteIncidentDetail>>(
    contextInitialState.store.incidentDetailDeleteData
  );
  const [incidentFileUploadData, setIncidentFileUpload] = useState<StoreStateDefaultV3<UploadIncidentFile>>(contextInitialState.store.incidentFileUploadData);
  const [incidentFileRemoveData, setIncidentFileRemove] = useState<StoreStateDefaultV3<RemoveIncidentFile>>(contextInitialState.store.incidentFileRemoveData);
  const [incidentEmployeesSearchData, setIncidentEmployeesSearch] = useState<StoreStateDefaultV3<GetEmployeeBySearch>>(
    contextInitialState.store.incidentEmployeesSearchData
  );
  const [incidentEmployeesQueryData, setIncidentEmployeesQuery] = useState<StoreStateDefaultV3<GetEmployeeByQuery>>(
    contextInitialState.store.incidentEmployeesQueryData
  );

  const [incidentReportApprovalData, setIncidentReportApproval] = useState<StoreStateDefaultV3<PostIncidentReportApproval>>(
    contextInitialState.store.incidentReportApprovalData
  );
  const [incidentReportRemarksData, setIncidentReportRemarks] = useState<StoreStateDefaultV3<PostIncidentReportRemarks>>(
    contextInitialState.store.incidentReportRemarksData
  );

  const [incidentProgressData, setIncidentProgress] = useState<StoreStateDefaultV3<GetIncidentProgress>>(contextInitialState.store.incidentProgressData);

  const [incidentChartData, setIncidentChart] = useState<StoreStateDefaultV3<GetIncidentsCharts>>(contextInitialState.store.incidentChartData);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const store: IncidentStore = {
    store: {
      notify: {
        notificationStatus,
        /**
         * call to action when status has changed
         */
        onStatusChange: (type: NotificationStatus, message?: string) => {
          if (!isMounted) return;
          setNotificationStatus(type);
          if (message) {
            Logger([`[notify][onStatusChange][${type}]`, message]);
          }
        },
      },
      incidentChartData,
      incidentReportRemarksData,
      incidentReportApprovalData,
      // renderUpdate,
      incidentListData,
      incidentBusinessUnitsFilter,
      incidentCreateUpdateData,
      incidentQueryTypesData,
      incidentDetailData,
      incidentDetailDeleteData,
      incidentFileUploadData,
      incidentFileRemoveData,
      incidentEmployeesSearchData,
      incidentEmployeesQueryData,
      incidentProgressData,
      resetStatus: (apiName: string) => {
        if (!isMounted) return;

        if (apiName === 'incidents/delete/report') {
          setIncidentDetailDelete({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'incidents') {
          setIncidentList({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'incidents/report/parts') {
          setIncidentDetail({ data: undefined as any, state: 'initial' });
          //  console.log('clear resetStatus 3');
        }

        if (apiName === 'incidents/create-update') {
          setIncidentCreateUpdate({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'incidents/cache/file/upload') {
          setIncidentFileUpload({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'incidents/cache/file/remove') {
          setIncidentFileRemove({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'employees/query') {
          setIncidentEmployeesQuery({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'employees/search') {
          setIncidentEmployeesSearch({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'incidents/report/approval') {
          setIncidentReportApproval({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'incidents/report/remarks') {
          setIncidentReportRemarks({ data: undefined as any, state: 'initial' });
        }

        if (apiName === 'incidents/progress') {
          setIncidentProgress({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'incidents/chart/incidents') {
          setIncidentChart({ data: undefined as any, state: 'initial' });
        }
      },
      resetState: (apiName: string, state: StoreStateV3) => {},

      // setRenderUpdate: (index?: number) => {
      //   if (typeof index === 'number') {
      //     setRenderUpdate(index);
      //   } else {
      //     // increment up
      //     setRenderUpdate(renderUpdate + 1);
      //   }
      // },

      setIncidentList: (params: QueryIncidentList, type?: 'hidden_load_after_deleted' | 'update', done?: (ok: boolean, resp: GetIncidentList) => void) => {
        if (!isMounted) return;
        if (!type && type !== 'update') setIncidentList({ data: undefined as any, state: 'loading' });
        IncidentListGet('incidents', params, tokens)
          .then((data) => {
            // NOTE  this done is special
            if (done && type === 'update') done(true, data);
            if (done && type !== 'update') return done(true, data);

            if (isMounted) setIncidentList({ data, state: 'ready' });
          })
          .then(() => {
            // NOTE: to reset incidentDetailDelete after hidden load success
            if (type === 'hidden_load_after_deleted') setIncidentDetailDelete({ data: undefined as any, state: 'initial' });
          })
          .catch((error) => {
            if (!isMounted) return;
            // NOTE  this done is specials
            if (done && type === 'update') done(true, null as any);
            if (done && type !== 'update') return done(true, null as any);
            setIncidentList({ data: undefined as any, error, state: 'error' });
          });
      },
      /*
       * NOTE: this function for Business Units filter in Incident Page
       * and use at CompanyDropdown on Incident Create Component in part 1
       * it use shared API GET/business-units
       */
      setIncidentBusinessUnitsFilter: () => {
        if (!isMounted) return;
        setIncidentBusinessUnitsFilter({ data: undefined as any, state: 'loading' });
        BusinessUnitsGet('business-units', tokens)
          .then((data) => {
            if (isMounted) setIncidentBusinessUnitsFilter({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setIncidentBusinessUnitsFilter({ data: undefined as any, error, state: 'error' });
          });
      },

      setIncidentDetail: (id: string, action?: 'update', ref?: string) => {
        if (!isMounted) return;

        if (action !== 'update') setIncidentDetail({ data: null as any, state: 'loading' });
        IncidentDetailGet('incidents/report/parts', id, tokens)
          .then((data) => {
            if (isMounted) {
              setIncidentDetail({ data, state: 'ready' });

              if (action === 'update') {
                if (incidentListData.data?.data) {
                  let updated = false;
                  const newIncidentListData = (incidentListData.data?.data).map((rowIncident) => {
                    if (rowIncident.id === data?.data?.id) {
                      rowIncident = transformIncidentDataToListPageStore(rowIncident, data?.data);
                      updated = true;
                    }
                    return rowIncident;
                  });

                  if (updated) {
                    incidentListData.data.data = newIncidentListData;
                    setIncidentList(incidentListData);
                    Logger(['[setIncidentDetail][incidentListData][updated]']);
                  }
                }
              }
            }

            if (ref) {
              Logger(['[IncidentDetailGet][call][ref]', ref]);
            }
          })
          .catch((error) => {
            if (isMounted) setIncidentDetail({ data: undefined as any, error, state: 'error' });
          });
      },

      setIncidentCreateUpdate: (body: IncidentBodyData, statusType?: IncidentStatusType, done?: (ok: boolean) => void) => {
        // setIncidentCreateUpdate: (body: IncidentBodyData, statusType?: IncidentStatusType) => {
        if (!isMounted) return;
        setIncidentCreateUpdate({ data: undefined as any, state: 'loading' });
        IncidentCreateUpdatePost('incidents/create-update', tokens, body)
          .then((data) => {
            if (isMounted) {
              setIncidentCreateUpdate({ data, state: 'ready' });

              // NOTE: after success submit data, then quick update the incidentListData
              if (incidentListData.data?.data) {
                let updated = false;
                let newIncidentListData = (incidentListData.data?.data).map((rowIncident) => {
                  if (rowIncident.id === data?.data?.id) {
                    rowIncident = transformIncidentDataToListPageStore(rowIncident, data?.data);
                    updated = true;
                  }
                  return rowIncident;
                });

                // if new item not in list yet
                if (!updated) {
                  newIncidentListData = [transformIncidentDataToListPageStore(null as any, data?.data, true), ...incidentListData.data.data].filter((n) => !!n);
                  if (newIncidentListData.length > incidentListData.data?.pagination?.itemPerPage) {
                    Logger(['[IncidentStore][setIncidentCreateUpdate] current max itemPerPage:', incidentListData.data?.pagination?.itemPerPage], 'error');
                    Logger(['[IncidentStore][setIncidentCreateUpdate] data exceed itemPerPage newIncidentListData:', newIncidentListData], 'error');
                    newIncidentListData = newIncidentListData.slice(0, incidentListData.data?.pagination?.itemPerPage);
                  }
                  updated = true;
                }

                if (updated) {
                  incidentListData.data.data = newIncidentListData;
                  setIncidentList(incidentListData);
                  Logger(['[setIncidentCreateUpdate][incidentListData][updated]']);
                }
              }

              // NOTE: To ask Micheal : i think we don't need this done(ok)=>{} function anymore, it's already handle in unmount component
              if (done) done(true);
            }
          })
          .catch((error) => {
            if (isMounted) {
              setIncidentCreateUpdate({ data: undefined as any, error, state: 'error' });
              if (done) done(false);
            }
          });
      },

      setIncidentQueryTypes: (params: QueryIncidentTypes) => {
        if (!isMounted) return;
        setIncidentQueryTypes({ data: undefined as any, state: 'loading' });
        IncidentQueryTypesGet('incidents/query', params, tokens)
          .then((data) => {
            if (isMounted) setIncidentQueryTypes({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setIncidentQueryTypes({ data: undefined as any, error, state: 'error' });
          });
      },

      setIncidentDetailDelete: (id: string) => {
        if (!isMounted) return;
        setIncidentDetailDelete({ data: undefined as any, state: 'loading' });
        IncidentDetailDelete('incidents/delete/report', id, tokens)
          .then((data) => {
            if (isMounted) {
              let updated = false;
              const d = incidentListData.data.data.filter((n) => {
                if (n.id === data.data.id) {
                  updated = true;
                  return false;
                }
                return true;
              });
              if (updated) {
                incidentListData.data.data = d;
                setIncidentList(incidentListData);
              }
              setIncidentDetailDelete({ data, state: 'ready' });
            }
          })
          .catch((error) => {
            if (isMounted) setIncidentDetailDelete({ data: undefined as any, error, state: 'error' });
          });
      },

      setIncidentFileUpload: (body: BodyUploadIncidentFile, ref: string) => {
        if (!isMounted) return;
        setIncidentFileUpload({ data: undefined as any, state: 'loading' });
        IncidentFileUpload('incidents/cache/file/upload', ref as any, body, tokens)
          .then((data) => {
            if (isMounted) setIncidentFileUpload({ data, state: 'ready' });
          })
          .catch((error) => {
            // NOTE: sent ref back to check which file is error
            if (isMounted) setIncidentFileUpload({ data: { status: { ref } } as UploadIncidentFile, error, state: 'error' });
          });
      },
      setIncidentFileRemove: (id: string, params?: QueryRemoveIncidentImage) => {
        if (!isMounted) return;
        setIncidentFileRemove({ data: undefined as any, state: 'loading' });
        IncidentFileRemove('incidents/cache/file/remove', id, params as any, tokens)
          .then((data) => {
            if (isMounted) setIncidentFileRemove({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setIncidentFileRemove({ data: undefined as any, error, state: 'error' });
          });
      },
      // NOTE: this employees data is from API GET/employees/search, use in createIncidentForm part2-description
      setIncidentEmployeesSearch: (params: EmployeeSearchQuery) => {
        if (!isMounted) return;
        setIncidentEmployeesSearch({ data: undefined as any, state: 'loading' });
        EmployeeSearchGet('employees/search', params, tokens)
          .then((data) => {
            if (isMounted) setIncidentEmployeesSearch({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setIncidentEmployeesSearch({ data: undefined as any, error, state: 'error' });
          });
      },
      // NOTE: this employees data is from API GET/employees/query
      setIncidentEmployeesQuery: (params: EmployeeByQuery) => {
        if (!isMounted) return;
        setIncidentEmployeesQuery({ data: undefined as any, state: 'loading' });
        EmployeeQueryGet('employees/query', params, tokens)
          .then((data) => {
            if (isMounted) setIncidentEmployeesQuery({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setIncidentEmployeesQuery({ data: undefined as any, error, state: 'error' });
          });
      },

      /**
       * REVIEW not tested yet!
       * - api not yet available
       */
      setIncidentReportApproval: (incidentId: string, body: BodyIncidentReportApproval) => {
        if (!isMounted) return;
        setIncidentReportApproval({ data: undefined as any, state: 'loading' });
        IncidentReportApproval('incidents/report/approval', incidentId, body, tokens)
          .then((data) => {
            if (isMounted) setIncidentReportApproval({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setIncidentReportApproval({ data: undefined as any, error, state: 'error' });
          });
      },

      /**
       * REVIEW not tested yet!
       * - api not yet available
       */
      setIncidentReportRemarks: (incidentId: string, body: BodyIncidentReportRemarks) => {
        if (!isMounted) return;
        setIncidentReportRemarks({ data: undefined as any, state: 'loading' });
        IncidentReportRemarks('incidents/report/remarks', incidentId, body, tokens)
          .then((data) => {
            if (isMounted) setIncidentReportRemarks({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setIncidentReportRemarks({ data: undefined as any, error, state: 'error' });
          });
      },

      // NOTE: this progressbar data is from API GET/incidents/progress/{incidentId}
      setIncidentProgress: (id: string, action?: 'update') => {
        if (!isMounted) return;
        if (action !== 'update') setIncidentProgress({ data: undefined as any, state: 'loading' });
        // NOTE For real API
        IncidentProgressGet('incidents/progress', id, tokens)
          .then((data) => {
            if (isMounted) setIncidentProgress({ data, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setIncidentProgress({ data: undefined as any, error, state: 'error' });
          });
      },

      setIncidentChart: (params?: QueryIncidentsCharts, action?: 'update' | 'new', done?: (ok: boolean, data?: IncidentsChartSchema) => void) => {
        if (!isMounted) return;
        if (!action || action === 'new') setIncidentChart({ data: undefined as any, state: 'loading' });
        IncidentChartsGet('incidents/charts/incidents', params || {}, tokens)
          .then((data) => {
            if (!isMounted) return;
            // data.data.ltifr.seriesData = data.data.ltifr.seriesData.slice(0, 10);
            // data.data.trir.seriesData = data.data.trir.seriesData.slice(0, 10);
            if (done && action === 'update') done(true, data.data);
            if (done && action !== 'update') return done(true, data.data);
            setIncidentChart({ data, state: 'ready' });
          })
          .catch((error) => {
            if (!isMounted) return;

            if (done && action === 'update') done(false, null as any);
            if (done && action !== 'update') return done(false, null as any);
            setIncidentChart({ data: undefined as any, error, state: 'error' });
          });
      },
    },
  };

  return <IncidentContext.Provider value={store}>{children}</IncidentContext.Provider>;
};

const withIncidentContext = (Component: any) => {
  return function IncidentComponent(props: any) {
    return <IncidentContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</IncidentContext.Consumer>;
  };
};

export { IncidentContextProvider, withIncidentContext };
