/* eslint-disable no-unused-vars */

/**
 * 1: Super Admin
 * 2: Admin
 * 3: General User (also Team Lead of incident report)
 * 4: General Manager
 * 5: Head of Department/Co-founder
 */
export enum UserRoleIdConst {
  super_admin = 1,
  bu_admin = 2,
  user = 3,
  gm = 4,
  hod = 5,
}

/**
 * In reference to {IncidentStatusReport,IncidentStatusType}  types
 */
export enum IncidentStatusReport {
  /**  1=Draft = when the incident is created by the users (reporter) They can update it and submit to SSHE admin. */
  draft = 1,
  /** 2=In review  = when the incident is submitted to the SSHE admin (Super admin). SSHE admin will be notified in email and will review it */
  review = 2,

  /**
   * Ongoing = when the incident is submitted by the SSHE admin to Team leader > Head of Department / Co-founder > General Manager. (Which will be managed by the master data)
   */
  ongoing = 3,
  /**
   * Closed = when the incident is closed by General Manager only
   */
  closed = 4,
}

/**
 * These are all passive status states, we also have overdue that can happen on {published and progress}
 * - these reference the {status} property
 */
export enum LegalStatusConst {
  /** LOR form is initially created, and can be re/saved in the same status  */
  draft = 1,
  /**
   * LOR form is published via submit, can skip draft status,
   * - ATTENTION only first created LOR will have {published} instead of {evaluate} status
   */
  published = 2,
  /** When LOR evaluation has started, use need to submit start-evaluation calling  @api /api/legal/lor/start-eval/
   * {lorId} */
  progress = 3,
  /**
   * When evaluation was submitted it becomes complete
   */
  complete = 5,

  /**
   * subsequent evaluation after first publish LOR (but first LOR will always be {published})
   */
  evaluate = 5,
}

/**
 * All overdue states can happen when pass the deadline on published and progress states.
 * - these reference the {overdueState} property
 */
export enum LegalOverdueStatusConst {
  /** No overdue status */
  none = 0,
  /**
   * when evaluation for requested year has not started at all, and its already past the {deadline}
   */
  overdue_published = 2,
  /**
   * when (initially published) evaluation started before overdue but it remains in progress past the due date {deadline}
   */
  overdue_progress = 3,
  /**
   * when subsequent evaluation (on same LOR) started before overdue but it remains in progress past the due date {deadline}
   *
   */
  overdue_evaluate = 6,
}
