import { HeadCell } from '@/types/index';

export const headCellsAdmins: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
    disablePadding: false,
    sortAble: false,
    width: 1 / 5,
    textAlign: 'inherit',
  },
  {
    id: 'role',
    label: 'Role',
    disablePadding: false,
    sortAble: false,
    width: 1 / 5,
    textAlign: 'inherit',
  },
  {
    id: 'company',
    label: 'Company',
    disablePadding: false,
    sortAble: false,
    width: 1 / 5,
    textAlign: 'inherit',
  },
  {
    id: 'email',
    label: 'Email',
    disablePadding: false,
    sortAble: false,
    width: 1 / 5,
    textAlign: 'inherit',
  },
  {
    id: 'actions',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 1 / 5,
    textAlign: 'inherit',
  },
];
