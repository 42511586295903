import { PropDefaults, TrainingStore, AzureAuthProviderProps, ActionInProgress, TrainingSubmitType } from '@/src/types';
import { Alert, Box, Button, IconButton, LinearProgress, Paper, Typography, Tooltip } from '@mui/material';
import { useState, useEffect, FC, useRef, MouseEvent } from 'react';
import { Navigate, useParams, NavLink, useOutletContext, useLocation } from 'react-router-dom';
import { Icon } from '@/components/ui/icons';
import { Attendees } from '@/src/components';
import { Logger } from '@/logger/index';
import { AttendeeContextProvider } from '@/store/index';
import { trainingStatus } from '@/src/static';
import { Instance } from '@popperjs/core';
import { PAGE_NOT_FOUND_MESSAGE } from '@/static/common/index';
// import { useGlobalContext } from '@/store/index';
import { TrainingDetailOverview } from '@/components/ui/trainings/training.overview/index';
import { TrainingDetailSchema } from '@/types/schema/trainings.schema.d';
// import { ENVS_CONFIG } from '@/constants/index';
import { isTheSameDate, printDate, getByTrainingCode, excelRowInfo, excelTrainingInfo, excelAllBorders } from '@/src/utils';

import ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';

interface TrainingProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  onTrainingSubmitType?: (submitType: TrainingSubmitType) => void;
}

type ModalActionType = 'cancel' | 'delete';

export const TrainingDetail: FC<any> = (props, context) => {
  const location = useLocation();
  const locationState: any = location.state;
  const isFromMyProfile = locationState?.from;
  const contextProps: TrainingProps & TrainingStore = useOutletContext();

  const [modalType, setModalType] = useState<ModalActionType>(null as any);
  const { onTrainingSubmitType } = contextProps;
  const { detail, store, azure } = contextProps;
  const [mount, setMount] = useState(0);
  // const [dateFormat, setDateFormat] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [actionInProgress, seActionInProgress] = useState<ActionInProgress>(null as any);
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = useRef<Instance>(null);

  const params = useParams();
  const { backUrl } = params;

  const areaRef = useRef<HTMLDivElement>(null);
  const handleMouseMove = (event: MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  useEffect(() => {
    const uid: string = detail as any;
    if (!mount) {
      if (!['ready', 'deleted'].includes(store.trainingDetailData.state) && uid) {
        store.setTrainingDetail(uid);
      }
      // const currentDate = new Date();
      // setDateFormat(moment(currentDate).format('DDMMYYYY'));
      setMount(1);
    }
  }, [mount, setMount, detail]);

  useEffect(() => {
    if (mount) {
      if (store.trainingDetailData.state === 'initial' && detail) {
        store.setTrainingDetail(detail);
      }
    }
  }, [store.trainingDetailData.state]);

  useEffect(() => {
    if (['ready', 'deleted'].includes(store.trainingDetailData.state) && !modalType) {
      if (store.trainingDetailData.data?.data?.status === 2) {
        setModalType('cancel');
      }
      if (store.trainingDetailData.data?.data?.status === 3) {
        setModalType('delete');
      }
    }
  }, [setModalType, modalType, store]);

  const trainingDetailData: TrainingDetailSchema = (store.trainingDetailData.data?.data || {}) as any;

  if (store.trainingDetailData.state === 'ready') {
    Logger(['[store][courseUpdatedData]', store.trainingDetailData.data], 'attention');
  }

  const state: 'ready' | 'error' | 'loading' | 'deleted' = [
    store.trainingDeletedData.state === 'ready' && 'deleted',
    ['loading', 'initial'].indexOf(store.trainingDetailData.state) !== -1 && 'loading',
    store.trainingDetailData.state === 'error' && 'error',
    (store.trainingDetailData.state === 'ready' || store.trainingDetailData.state === 'deleted') && 'ready',
  ].filter((n) => n)[0] as any;

  // START: Export Data
  const [attendeeList, setAttendeeList] = useState<any[]>([]);
  const onChangeAttendees = (data: any) => {
    if (data && data.data && data.data.data) setAttendeeList(data.data.data);
  };
  const scoresSet = (d: any) => {
    return (d.preTestScore || d.preTestScore === 0) && (d.postTestScore || d.postTestScore === 0);
  };

  const [exportReady, setExportReady] = useState(true);
  const onExportExcel = async (e: any) => {
    e.preventDefault();
    const data = trainingDetailData;
    if (exportReady && data && data.courseName) {
      setExportReady(false);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet();
      worksheet.views.push({ showGridLines: false });
      worksheet.properties.defaultRowHeight = 25;

      worksheet.columns = [
        { key: 'col0', width: 16, header: 'Course name' },
        { key: 'col1', width: 34, header: data.courseName },
        { key: 'col2', width: 16, header: '' },
        { key: 'col3', width: 22, header: '' },
        { key: 'col4', width: 16, header: '' },
        { key: 'col5', width: 22, header: '' },
        { key: 'col6', width: 16, header: '' },
        { key: 'col7', width: 22, header: '' },
        { key: 'col8', width: 30, header: '' },
      ];
      worksheet.mergeCells(1, 2, 1, worksheet.columns.length);
      excelRowInfo(worksheet, 1, 'A', 'B');

      worksheet.addRow({ col0: 'Course ID', col1: data.courseNo });
      worksheet.mergeCells(2, 2, 2, worksheet.columns.length);
      excelRowInfo(worksheet, 2, 'A', 'B');

      worksheet.addRow({
        col0: 'Certificate expiration date',
        col1: data.certExpirationDate ? printDate(data.certExpirationDate) : 'No expiration date',
      });
      worksheet.mergeCells(3, 2, 3, worksheet.columns.length);
      excelRowInfo(worksheet, 3, 'A', 'B');

      worksheet.addRow({});
      worksheet.addRow({
        col0: 'Course detail',
        col1: `/courses/detail/${data.courseNo}`,
        col2: 'Training date',
        col3: isTheSameDate(data.startDate || '', data.endDate || '')
          ? `${printDate(data.startDate)}`
          : `${printDate(data?.startDate)} - ${printDate(data?.endDate)}`,
        col4: 'Type',
        col5: `${getByTrainingCode(data.trainingCode).trainingName} ${data.onlineLink ? 'online' : ''}`,
        col6: `Accepted attendees\n${data.attendees} / ${data.total}`,
      });
      worksheet.addRow({
        col0: 'Evaluation',
        col1: data.evaluationLink ? data.evaluationLink : '',
        col2: 'Training time',
        col3: `${printDate(data.startDate, 'HH:mm')} - ${printDate(data.endDate, 'HH:mm')}`,
        col4: 'Location',
        col5: data.location ? data.location : '',
      });
      excelTrainingInfo(worksheet, [5, 6]);

      worksheet.addRow({});
      const _row = worksheet.addRow({ col0: 'Attendees' });
      _row.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
      _row.font = { bold: true };

      worksheet.addRow({
        col0: 'Employee ID',
        col1: 'Name',
        col2: 'Position',
        col3: 'Company',
        col4: 'Status',
        col5: `Pre test${data.preTestScore || data.preTestScore === 0 ? `\n(${data.preTestScore})` : ''}`,
        col6: `Post test${data.postTestScore || data.postTestScore === 0 ? `\n(${data.postTestScore})` : ''}`,
        col7: `Result test${data.postTestPassScore || data.postTestPassScore === 0 ? `\n(${data.postTestPassScore})` : ''}`,
        col8: `Certificate`,
      });
      excelAllBorders(worksheet, 9, worksheet.columns.length);

      let rowId = 9;
      if (attendeeList && attendeeList.length) {
        attendeeList.forEach((d) => {
          rowId += 1;
          worksheet.addRow({
            col0: d.employeeId,
            col1: `${d.firstName} ${d.lastName}`,
            col2: d.position,
            col3: d.buCode,
            col4: d.status === 1 ? 'Pending' : d.status === 2 ? 'Accepted' : data.status === 3 ? 'cancelled' : '',
            col5: d.preTestScore || d.preTestScore === 0 ? d.preTestScore : '',
            col6: d.postTestScore || d.postTestScore === 0 ? d.postTestScore : '',
            col7: scoresSet(d) && d.testStatus === 1 ? 'Passed' : scoresSet(d) && d.testStatus === 0 ? 'Failed' : '',
            col8: d.certificateLink ? d.certificateLink : '',
          });
          excelAllBorders(worksheet, rowId, worksheet.columns.length, false, 2);
        });
      }

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${data.courseName}.xlsx`);

      setExportReady(true);
    }
  };
  // END: Export Data

  switch (state) {
    case 'ready': {
      return (
        <Paper elevation={0}>
          <Box className="flex items-center mb-6">
            {backUrl ? (
              <NavLink to={`/trainings/${backUrl}`}>
                <IconButton color="primary" aria-label="View details" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                  <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
                </IconButton>
              </NavLink>
            ) : isFromMyProfile ? (
              <NavLink to={`${isFromMyProfile}`}>
                <IconButton color="primary" aria-label="View details" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                  <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
                </IconButton>
              </NavLink>
            ) : (
              <NavLink to="/trainings">
                <IconButton color="primary" aria-label="View details" sx={{ marginTop: '-2px', marginLeft: '-16px' }}>
                  <Icon icon="angleLeft" color="#3155FF" viewBox="0 0 256 512" size={30} />
                </IconButton>
              </NavLink>
            )}
            <Typography variant="h1">{trainingDetailData.courseName}</Typography>
            <Typography
              sx={{ padding: '2px 10px' }}
              className={`"text-sm ml-3 rounded-md font-normal" ${
                trainingDetailData.status === 2 ? 'bg-emerald-100 text-emerald-500' : 'bg-red-100 text-red-500'
              }`}
            >
              {trainingStatus[trainingDetailData.status]}
            </Typography>
          </Box>

          <TrainingDetailOverview
            actionInProgress={(val: ActionInProgress) => {
              Logger(['[TrainingDetailOverview][actionInProgress]', val], 'log');
              seActionInProgress(val);
            }}
            azure={azure}
            store={store}
            data={store.trainingDetailData as any}
            onTrainingSubmitType={onTrainingSubmitType}
          />

          <AttendeeContextProvider context={{ azure: azure }}>
            {trainingDetailData && (
              <Attendees
                preTestScore={trainingDetailData.preTestScore}
                postTestScore={trainingDetailData.postTestScore}
                postTestPassScore={trainingDetailData.postTestPassScore}
                trainingId={detail}
                trainingStatus={trainingDetailData.status}
                onChangeAttendees={onChangeAttendees}
              >
                <Typography variant="h2" className="mr-5 my-auto">
                  Attendees
                </Typography>
                <Tooltip
                  title={`${trainingDetailData.courseName}.xlsx`}
                  placement="top"
                  arrow
                  PopperProps={{
                    popperRef,
                    anchorEl: {
                      getBoundingClientRect: () => {
                        return new DOMRect(positionRef.current.x, areaRef.current!.getBoundingClientRect().y, 0, 0);
                      },
                    },
                  }}
                >
                  <Box ref={areaRef} onMouseMove={handleMouseMove} className="ml-auto order-3">
                    {/* <Button variant="outlined" color="primary" href={exportLink(trainingDetailData.id)} download>
                      <Icon icon="file" color="#3155FF" viewBox="0 0 384 512" size={18} className="mr-2" />
                      Export report
                    </Button> */}
                    <Button onClick={onExportExcel} disabled={!exportReady} variant="outlined" color="primary">
                      <Icon icon="file" color="#3155FF" viewBox="0 0 384 512" size={18} className="mr-2" />
                      Export excel
                    </Button>
                  </Box>
                </Tooltip>
              </Attendees>
            )}
          </AttendeeContextProvider>
        </Paper>
      );
    }
    case 'deleted': {
      return <Navigate to={`/trainings`} />;
    }
    case 'error': {
      return <Alert severity="error">{PAGE_NOT_FOUND_MESSAGE}</Alert>;
    }
    case 'loading': {
      return <LinearProgress />;
    }
    default: {
      return null;
    }
  }
};
