/**
 * this file is used for declaring all kinds of reducers for complex props state/value declarations to avoid invalid data flows to components
 *
 * @example  const [chartState, dispatchChartState] = useReducer(chartReducer, { value: null, type: 'buCodes', state: 'initial' });
 */

import { ReducerActionType } from '../types';

/**
 * makes sure we provide correct state data to the component via reducer!
 * @example  const [chartState, dispatchChartState] = useReducer(chartReducer, { value: null, type: 'buCodes', state: 'initial' });
 */
export const chartReducer = (state: ReducerActionType, action: ReducerActionType): ReducerActionType => {
  switch (action.type) {
    case 'buCodes': {
      const increment: number = (state._increment || 0) + 1;
      return { value: action.value as any, type: 'buCodes', state: action.state, _increment: increment };
    }

    // the value should be an object {buCodes[],years:string}
    case 'buCodes/year': {
      const increment: number = (state._increment || 0) + 1;
      return { value: action.value as any, type: 'buCodes/year', state: action.state, _increment: increment };
    }

    // the value should be an object { years: string }
    case 'year': {
      const increment: number = (state._increment || 0) + 1;
      return { value: action.value as any, type: 'year', state: action.state, _increment: increment };
    }

    default:
      // non matching state
      return { value: '', type: 'undefined', state: 'updated' };
    // return { ...(action as any) };
  }
};
