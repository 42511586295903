// import { Logger } from '@/logger/index';
import { CallBackFilterType, LegalRelatedBuListSchema, LegalStore } from '@/src/types';
import { Box, Button, FormControl, Paper, Typography, Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FilterMultiSelect } from '@/src/components';
import { buReLatedFilterData } from '@/src/static';
import { AutocompleteChangeReason } from '@mui/material/Autocomplete';
interface FilterProps {
  filterProps: {
    buRelatedFilter: string[];
    onCallBackRelatedBuFilter: (type: CallBackFilterType, payload?: any) => void;
    legalLorFilter: string;
    onCallBackLegalLorFilter: (type: 'selectOption' | 'clear', payload?: any) => void;
    resetAllFilter: () => void;
  };
}

export function Filters(props: LegalStore & FilterProps) {
  const {
    store,
    filterProps: { buRelatedFilter, onCallBackRelatedBuFilter, legalLorFilter, onCallBackLegalLorFilter, resetAllFilter },
  } = props;

  const { legalBuRelatedFilter, setLegalBuRelatedFilter } = store;
  const selectedLorFilter = buReLatedFilterData.find((item) => item.value === legalLorFilter);
  const [prevLor, setPrevLor] = useState<{ value: string; label: string } | null>(selectedLorFilter || null);

  useEffect(() => {
    if (legalBuRelatedFilter.state === 'initial' && !legalBuRelatedFilter.data?.data) {
      setLegalBuRelatedFilter();
    }
  }, [legalBuRelatedFilter, setLegalBuRelatedFilter]);

  useEffect(() => {
    if (legalLorFilter === '') setPrevLor(null);
    if (legalLorFilter) {
      const selectedLorFilter = buReLatedFilterData.find((item) => item.value === legalLorFilter);
      setPrevLor(selectedLorFilter || null);
    }
  }, [legalLorFilter]);

  // Logger(['[RegalList][BuRelatedFilter]', legalBuRelatedFilter]);

  return (
    <Paper
      elevation={4}
      className="p-6 relative min-w-[280px] min-h-[310px] mr-6 h-auto"
      sx={{ background: 'linear-gradient(139deg, rgba(255,255,255,1) 0%, rgba(235,251,255,1) 100%)' }}
    >
      <Typography variant="h2" color="primary" className="mb-2">
        Filters
      </Typography>
      <Typography variant="caption" className="block mb-4 text-gray-400">
        Use the filters and/or click on graphs to filter the list.
      </Typography>
      <FormControl fullWidth className="mb-6">
        <Autocomplete
          value={prevLor}
          className="bg-white"
          options={buReLatedFilterData}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.value}>
              {option.label}
            </Box>
          )}
          onChange={(e, a, b: AutocompleteChangeReason, selectOption) => {
            if (b === 'selectOption' && a !== null) {
              onCallBackLegalLorFilter(b, a.value);
              setPrevLor(a);
            } else if (b === 'clear') {
              onCallBackLegalLorFilter(b, '');
              setPrevLor(null);
            }
          }}
          renderInput={({ inputProps, ...rest }) => {
            return (
              <TextField
                {...rest}
                variant="outlined"
                fullWidth
                placeholder="Show all types"
                inputProps={{
                  ...inputProps,
                  readOnly: true,
                  sx: {
                    '&::placeholder': {
                      color: 'black',
                      opacity: 1,
                    },
                  },
                  className: 'cursor-pointer p-0',
                }}
              />
            );
          }}
        />
      </FormControl>
      <FormControl fullWidth className="w-[200px] mb-14">
        <FilterMultiSelect
          clearable
          fullWidth
          filterData={legalBuRelatedFilter?.data?.data || ([] as LegalRelatedBuListSchema[])}
          isLoadingFilterData={legalBuRelatedFilter?.state !== 'ready'}
          selectedFilter={buRelatedFilter}
          onCallBackFilter={onCallBackRelatedBuFilter}
          dropdownSetting={{
            valueKey: 'buCode',
            labelKey: 'buName',
            noSelectedText: 'Related: All',
          }}
          dropdownLabelConfig={(_value, _label) => `${_label} (${_value})`}
          className="bg-white"
          sx={{ width: '100%' }}
        />
      </FormControl>
      <Box>
        <Button variant="text" className=" underline p-0" onClick={resetAllFilter}>
          Reset all filters
        </Button>
      </Box>

      <img src={require('@/assets/icons/law4x.png')} alt="legal" width={136} height={136} className=" absolute right-4 bottom-4" />
    </Paper>
  );
}
