import { AzureAuthProviderProps, IncidentStore, PropDefaults } from '@/src/types';
import { Typography, Box } from '@mui/material';
import { Icon } from '@/src/components';

interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export default function PartFiveReport(props: IncidentProps & IncidentStore) {
  const { store } = props;

  const {
    // incidentBusinessUnitsFilter,
    // // incidentCreateUpdateData,
    // incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    // setIncidentDetail,
    incidentDetailData,
  } = store;

  const incidentInvestigationData = incidentDetailData?.data?.data?.incidentInvestigation as any;

  return (
    <>
      <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
        <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
        Incident investigation
      </Typography>
      <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:mt-5 bg-white">
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4 ">
          <Typography className="lg:pl-6 md:pl-4 w-full" dangerouslySetInnerHTML={{ __html: incidentInvestigationData?.htmlText || '-' }}></Typography>
        </Box>
      </Box>
    </>
  );
}
