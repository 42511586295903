/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Fragment, useEffect, useState } from 'react';
import { ProfileAttendeeRequestBody, ProfileMyTrainingAttendeeRequestType, ProfileMyTrainingSchema, ProfileStore, PropDefaults } from '@/src/types';
import { exportLinkV2, getYearArray, isTheSameDate, printDate } from '@/src/utils';
import { Paper, Typography, Box, Button, Link, LinearProgress, debounce, OutlinedInput, MenuItem, Select } from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import { monthData } from '@/src/static';
import { DataError } from '../dataError';
import { clone } from 'lodash';
import { useGlobalContext } from '@/store/index';

interface MyTrainingProps extends PropDefaults {}

export function MyTraining(props: MyTrainingProps & ProfileStore) {
  const { currentUser } = useGlobalContext();
  const { detailId: employeeId } = useParams<{ detailId: string }>();
  const [mount, setMount] = useState<number>(0);

  const { store: profileStore } = props;

  useEffect(() => {
    if (profileStore.profileTrainingData.state === 'ready') {
    }
  }, [profileStore, mount, setMount]);

  const loading = profileStore.profileTrainingData.state !== 'error' && profileStore.profileTrainingData.state !== 'ready';
  const hasError = profileStore.profileTrainingData.state === 'error';
  const ready = profileStore.profileTrainingData.state === 'ready';
  const data = profileStore.profileTrainingData?.data?.data || [];
  const profileAttendeeLoading = ['error', 'ready', 'initial'].indexOf(profileStore.profileAttendeeRequestData.state) === -1;

  const printRange = (item: ProfileMyTrainingSchema): string => {
    if (isTheSameDate(new Date(item.startDate), new Date(item.endDate))) {
      return printDate(item.startDate, 'D MMM YYYY', true) || '-';
    } else {
      if (!item.startDate && !item.endDate) return '-';
      return `${printDate(item.startDate, 'D MMM YYYY')} - ${printDate(item.endDate, 'D MMM YYYY')}`;
    }
  };

  /**
   * initiate debounced callback
   */
  const debouncedCallBack = debounce(
    (call: () => void) => {
      call();
    },
    200
    // { maxWait: 1000 }
  );

  const requestApi = (item: ProfileMyTrainingSchema, type: ProfileMyTrainingAttendeeRequestType) => {
    const body: ProfileAttendeeRequestBody = {
      request: type,
    };
    profileStore.setProfileAttendeeRequest(employeeId as any, item.attendeeId, body, (ok) => {
      if (ok) {
        profileStore.setEmployeeById(currentUser.userProfile.employeeId, { profile: 1 });
      }
    });
  };
  // const testStatus = 1;

  return (
    <Fragment>
      <Paper
        elevation={4}
        className="p-6 px-10 relative h-auto mt-6 min-h-[400px] max-h-[590px]"
        sx={{ background: 'linear-gradient(139deg, rgba(255,255,255,1) 0%, rgba(235,251,255,1) 100%)' }}
      >
        <Box className="h-[50px] w-full">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              className="top-[-1rem] relative"
              sx={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                // p: 1,
                // m: 1,
                // bgcolor: 'background.paper',
                // borderRadius: 1,
              }}
            >
              <img
                style={{ display: 'block' }}
                src={require('@/assets/icons/mentor4x.png')}
                alt="my training icon"
                width={75}
                height={75}
                className="top-[-1.6rem] relative"
              />
              <Typography className="ml-5 text-blue-600 text-2xl font-medium">My training</Typography>
            </Box>
            <Box className={`${data.length > 4 && 'mr-5'}`}>
              <MonthYearFilter store={props.store} employeeId={employeeId as any} />
            </Box>
          </Box>
        </Box>

        {
          // NOTE ready for integration
        }
        {hasError && <DataError description={`Please reload the page again.`} />}
        {loading && <LinearProgress className="mt-4" />}

        <Box className={`h-[93%] w-full overflow-y-auto text-sm mt-3 scrollbar ${data.length > 4 && 'scrollbar-offset-5'}`} style={{ maxHeight: 400 }}>
          {ready && !data.length ? (
            <Box className={`mt-4 min-h-[150px] max-h-[180px] flex justify-center items-center ${data.length > 2 && 'overflow-auto'}`}>
              <Typography className="text-sm font-medium my-4 ml-2 " color={'#98999B'}>
                You have no training schedule.
              </Typography>
            </Box>
          ) : (
            data.map((row, index) => (
              <Box key={index} className="border-0 border-dashed border-b py-4 border-b-[#E0E0E0] flex px-0">
                <Box className="flex flex-wrap h-full w-full">
                  {/* status */}
                  <Box className="flex flex-col h-full justify-center min-w-[100px]">
                    <span
                      className={`py-1 px-2 rounded whitespace-nowrap w-fit ${
                        row.status === 1 ? 'inReview' : row.status === 2 ? 'overdue' : row.status === 3 ? 'cancelled' : 'completed'
                      }`}
                    >
                      {row.status === 1 ? 'Pending' : row.status === 2 ? 'Accepted' : row.status === 3 ? 'Declined' : 'Completed'}
                    </span>
                  </Box>

                  {/* date and name */}
                  <Box className="flex flex-col justify-center mt-1">
                    <Box className="flex items-center">
                      <img src="/icons/calendar-small.svg" alt="calendar icon" height="15" className="mr-2 mb-1" />

                      <Typography component={'span'} className="font-medium">
                        {printRange(row)}&nbsp;, {printDate(row.startDate, 'HH:mm')} -{printDate(row.endDate, 'HH:mm')} &nbsp;|
                      </Typography>
                      <NavLink
                        to={`/trainings/detail/${row?.trainingId}`}
                        state={{
                          from: `/profile/detail/${employeeId}`,
                        }}
                        className="font-medium text-gray-900 mx-2"
                      >
                        View details
                      </NavLink>

                      {!row.certificate && [1, 2, 3].includes(row.status) && row.testLinks?.preTest && (
                        <>
                          |
                          <Link href={row.testLinks?.preTest} className="font-medium text-gray-900 mx-2 underline decoration-black" target="_blank">
                            Pre-test
                          </Link>
                        </>
                      )}
                      {!row.certificate && row.testLinks?.postTest && (
                        <>
                          |
                          <Link href={row.testLinks?.postTest} className="font-medium text-gray-900 mx-2 underline decoration-black" target="_blank">
                            Post-test
                          </Link>
                        </>
                      )}
                      {row.status === 4 && row.evaluation && (
                        <>
                          |
                          <Link href={row.evaluation} className="font-medium text-gray-900 mx-2 underline decoration-black">
                            Evaluation
                          </Link>
                        </>
                      )}
                    </Box>

                    <Box className="pl-6 mt-1">
                      <Typography className="font-medium" component={'span'}>
                        {row.courseName}
                      </Typography>
                    </Box>
                  </Box>

                  {row.status === 1 ? (
                    <Box className="ml-auto mr-0 pr-0">
                      <Button
                        disabled={profileAttendeeLoading}
                        size="small"
                        variant="contained"
                        color="success"
                        className=" text-white my-1 w-[90px] h-[40px]"
                        onClick={() => {
                          debouncedCallBack(() => {
                            requestApi(row, 'accept');
                          });
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        disabled={profileAttendeeLoading}
                        onClick={() => {
                          debouncedCallBack(() => {
                            requestApi(row, 'decline');
                          });
                        }}
                        size="small"
                        variant="contained"
                        color="error"
                        className="ml-2 my-1 w-[90px] h-[40px]"
                      >
                        Decline
                      </Button>
                    </Box>
                  ) : row.status === 4 && row.certificate ? (
                    <Button
                      href={exportLinkV2(row.certificate as any, true)}
                      size="small"
                      variant="outlined"
                      className="ml-auto w-[120px] h-[40px]"
                      onClick={() => {}}
                      startIcon={<img src="/icons/certificate.png" alt="certificate icon" height="15" />}
                    >
                      Certificate
                    </Button>
                  ) : null}
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Paper>
    </Fragment>
  );
}
const yearRange = getYearArray().reverse();
function MonthYearFilter(props: ProfileStore & { employeeId: string }) {
  const { store, employeeId } = props;
  const [queryVal, setQuery] = useState<{ month?: number; year?: number } & { state: 'initial' | 'updated' }>({
    month: 0,
    year: 0,
    state: 'initial',
  });

  useEffect(() => {
    if (queryVal.state === 'updated') {
      const q: any = { ...queryVal };
      delete q.state;
      store.setProfileTrainingList(employeeId, q);
    }
  }, [queryVal, setQuery]);

  return (
    <Box className="h-[50px] mt-4">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Select
          className="bg-white min-w-[100px] mr-4"
          labelId="selectType"
          id="selectType"
          defaultValue={queryVal.month as any}
          onChange={(e) => {
            queryVal.month = Number(e.target.value) === 0 ? undefined : Number(e.target.value);
            queryVal.state = 'updated';
            setQuery(clone(queryVal));
          }}
          input={<OutlinedInput />}
        >
          {monthData.map((month, index) => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </Select>
        <Select
          className="bg-white min-w-[100px]"
          labelId="selectType"
          id="selectType"
          defaultValue={queryVal.year as any}
          onChange={(e) => {
            queryVal.year = Number(e.target.value) === 0 ? undefined : Number(e.target.value);
            queryVal.state = 'updated';
            setQuery(clone(queryVal));
          }}
          input={<OutlinedInput />}
        >
          <MenuItem value={0}>All years</MenuItem>
          {yearRange.map((year, index) => (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}
