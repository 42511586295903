import React from 'react';
import { Container, Footer } from '@/components/index';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { LOGIN_PAGE_TITLE } from '@/src/static';

interface LoginProps {
  [name: string]: any;
}

export const Login: React.FC = (LoginProps: LoginProps) => {
  setDocTitle(LOGIN_PAGE_TITLE);
  return (
    <Container>
      {<div>Login page</div>}
      <Footer />
    </Container>
  );
};
