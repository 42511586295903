import React, { useState } from 'react';
import { AzureAuthProviderProps, PropDefaults, TrainingStore, TrainingSubmitType } from '@/src/types';
import { withTrainingContext } from '@/store/index';
import { useLocation, useNavigate } from 'react-router-dom';
import TrainingRouter from './training.router';
import { SnackBarAlertWithStore } from '@/components/ui/variants';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { TRAINING_PAGE_TITLE } from '@/src/static';

interface TrainingsProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
}

export const TrainingsPage: React.FC<TrainingsProps & TrainingStore> = (props) => {
  setDocTitle(TRAINING_PAGE_TITLE);
  const { store } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [submitType, setSubmitType] = useState<TrainingSubmitType>(null as any);

  const routeName: 'trainings/detail' | 'trainings' = [
    location.pathname.includes('trainings/detail') && 'trainings/detail',
    !location.pathname.includes('trainings/detail') && location.pathname.indexOf('trainings') !== -1 && 'trainings',
  ].filter((n) => !!n)[0] as any;

  const handleOnTrainingSubmitType = (submitType: TrainingSubmitType) => {
    if (submitType) setSubmitType(submitType);
  };
  const _props = {
    ...props,
    onTrainingSubmitType: handleOnTrainingSubmitType,
  };

  return (
    <>
      <TrainingRouter {..._props} />
      <SnackBarAlertWithStore
        autoHideDurationAndClearStore={routeName === 'trainings' ? 4000 : 4000}
        storeName={['TrainingStore']}
        store={{ store }}
        submitReference={submitType}
        onDeleted={(del: boolean) => {
          if (del) {
            if (routeName === 'trainings/detail') {
              setTimeout(() => {
                navigate('/trainings');
              }, 2000);
            }
          }
        }}
      />
    </>
  );
};

export default withTrainingContext(TrainingsPage);
