/* eslint-disable no-unused-vars */
// import { Defer } from '@/types/index';

import { IncidentStatusReport, UserRoleIdConst } from '@/src/constants';
import { AzureAuthProviderProps } from '@/src/types';
import { UserAvailableOptions, UserCanOnIncident, UserIncidentReports, UserModelData, UserProfile, UserRoleId, UserSchema } from '@/types/schema/user.schema';
import { defer, isAdmin } from '@/utils/index';
import Cookies from 'universal-cookie';
import { Logger } from '@/utils/logger/index';

/**
 * Auto extenuate class to instance
 *
 */
export const userModel = (userData: any): UserSchema => {
  class UserModel implements UserSchema {
    _userProfile: UserProfile = undefined as any;
    cookies: Cookies = new Cookies();
    private _data: UserModelData = undefined as any;

    constructor(data: UserModelData) {
      if (Object.keys(data || {}).length > 0) {
        this._data = data;
        this._userProfile = this.azure.userProfile;
      }
    }

    /**
     * Check if you are an admin
     */
    get aimAdmin(): boolean {
      if (!this.userProfile) return false;
      return isAdmin(this.userProfile);
    }

    get userOptions(): UserAvailableOptions {
      return this._data?.azure?.userOptions as any;
    }

    /**
     * - check incidentReports on current user object can perform action on pages: /create /update /detail
     * - incidentReports must be available
     * @param roleId  test what user with this uid can do
     * @param status provide external status value from api, it will be used instead of `incidentReports[0].status`
     */
    // userCanOnIncident(incidentId: string, roleId?: UserRoleId, status?: IncidentStatusReport): UserCanOnIncident {
    //   if (!this.userOptions?.incidentReports) return 'NO_ACCESS';

    //   const userCan = (userIncidentReports: UserIncidentReports, _status?: IncidentStatusReport) => {
    //     const status = _status || userIncidentReports.status;
    //     // EDIT_DRAFT,
    //     const role = roleId || this.userProfile.roleId;
    //     Logger(['[userCanOnIncident][status]', status]);
    //     Logger(['[userCanOnIncident][role]', role]);
    //     Logger(['[userCanOnIncident][userProfile.employeeId]', this.userProfile.employeeId]);
    //     Logger(['[userCanOnIncident][userIncidentReports.employeeId]', userIncidentReports.employeeId]);

    //     // read only for draft that belong to report owner
    //     if (status === IncidentStatusReport.draft && this.userProfile.employeeId === userIncidentReports.employeeId) {
    //       return 'READ_ONLY';
    //     }

    //     // creator of report can also be an admin
    //     if (
    //       (role === UserRoleIdConst.super_admin || role === UserRoleIdConst.bu_admin || role === UserRoleIdConst.user) &&
    //       status === IncidentStatusReport.draft &&
    //       this.userProfile.employeeId === userIncidentReports.employeeId
    //     ) {
    //       return 'EDIT_DRAFT';
    //     }

    //     if (/* role === UserRoleIdConst.user && */ this.userProfile.employeeId === userIncidentReports.employeeId && role === UserRoleIdConst.user) {
    //       // report owner can read only
    //       // TODO this is not final validation we need to add another condition for Team Lead > who has same role as general user
    //       return 'READ_ONLY';
    //     }

    //     // super admin must approve
    //     if (status === IncidentStatusReport.review && role === UserRoleIdConst.super_admin) {
    //       return 'REVIEW';
    //     }

    //     if (role === UserRoleIdConst.gm) {
    //       if (status === IncidentStatusReport.ongoing) {
    //         return 'ONGOING';
    //       }

    //       // if (status === IncidentStatusReport.review) {
    //       //   return 'REVIEW';
    //       // }

    //       if (status === IncidentStatusReport.closed) {
    //         return 'CLOSED';
    //       }
    //     }

    //     if (role === UserRoleIdConst.hod) {
    //       if (status === IncidentStatusReport.ongoing) {
    //         return 'ONGOING';
    //       }

    //       // if (status === IncidentStatusReport.review) {
    //       //   return 'REVIEW';
    //       // }

    //       if (status === IncidentStatusReport.closed) {
    //         return 'CLOSED';
    //       }
    //     }
    //     return 'NO_ACCESS';
    //   };

    //   for (const report of this.userOptions?.incidentReports) {
    //     if (incidentId === report.incidentId) {
    //       return userCan(report, status);
    //     }
    //   }

    //   return 'NO_ACCESS';
    // }

    /**
     * @azure profile and account details
     * - You can get accountStatus from azure.accountStatus
     */
    get azure(): AzureAuthProviderProps {
      return this._data?.azure as AzureAuthProviderProps;
    }

    /** Currently logged/in  user profile */
    get userProfile() {
      return this._userProfile as UserProfile;
    }

    get displayName(): string {
      if (this.userProfile) {
        const name = this.userProfile?.firstName as string;
        const [firstName] = name.split(' ');
        return firstName ? `Hi, ${firstName}` : 'undefined';
      } else {
        return 'No Account found';
      }
    }

    get nameAbbr(): string {
      if (this.userProfile) {
        const first = (this.userProfile.firstName || '')[0];
        const last = (this.userProfile.lastName || '')[0];
        return first + last;
      } else {
        return '';
      }
    }

    // get fullNameAbbr(): string {
    //   if (this.displayName) {
    //     const fullname = this.displayName as string;
    //     return fullname.split(' ')[0] + ' ' + fullname.split(' ')[fullname.split(' ').length - 1];
    //   } else {
    //     return '';
    //   }
    // }
  }

  const user = new UserModel(userData);
  return user;
};
