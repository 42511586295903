import React, { useState } from 'react';
import { MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { FilterMultiSelect } from '@/components/index';
import { CallBackFilterType, CourseTrainingTypeSchema, TrainingStatus, TrainingStore } from '@/src/types';
import { monthData, statusData } from '@/src/static';
import { getYearArray } from '@/src/utils';

interface SearchFilterDropdownProps {
  onCallBackSearchFilterDropdown: (
    type: 'setMonthFilter' | 'setYearFilter' | 'setTrainingTypeFilter' | 'setStatusFilter' | 'finishSelectingFilter',
    payload?: any
  ) => void;
  monthFilter: number;
  yearFilter: number;
  trainingTypeFilter: string[];
  statusFilter: TrainingStatus[];
}

function SearchFilterDropdown(props: SearchFilterDropdownProps & TrainingStore) {
  const { store, onCallBackSearchFilterDropdown, monthFilter, yearFilter, trainingTypeFilter, statusFilter } = props;

  const [prevSelectedMonthFilter, setPrevSelectedMonthFilter] = useState(monthFilter);
  const [prevSelectedYearFilter, setPrevSelectedYearFilter] = useState(yearFilter);
  const [prevSelectedStatusFilter, setPrevSelectedStatusFilter] = useState(statusFilter);
  const [isOpenMonthFilter, setOpenMonthFilter] = useState<boolean>(false);
  const [isOpenYearFilter, setOpenYearFilter] = useState<boolean>(false);
  const [isOpenStatusFilter, setOpenStatusFilter] = useState<boolean>(false);

  const handleOpen = (filter: 'month' | 'year' | 'status') => {
    if (filter === 'month') {
      setOpenMonthFilter(true);
      setPrevSelectedMonthFilter(monthFilter);
    }
    if (filter === 'year') {
      setOpenYearFilter(true);
      setPrevSelectedYearFilter(yearFilter);
    }
    if (filter === 'status') {
      setOpenStatusFilter(true);
      setPrevSelectedStatusFilter(statusFilter);
    }
  };
  const handleClose = (filter: 'month' | 'year' | 'status') => {
    if (filter === 'month') setOpenMonthFilter(false);
    if (filter === 'year') setOpenYearFilter(false);
    if (filter === 'status') setOpenStatusFilter(false);
    if (monthFilter !== prevSelectedMonthFilter || yearFilter !== prevSelectedYearFilter || statusFilter !== prevSelectedStatusFilter) {
      onCallBackSearchFilterDropdown('finishSelectingFilter');
    }
  };
  const yearData = getYearArray();

  const handleMonthChange = (event: SelectChangeEvent) => {
    onCallBackSearchFilterDropdown('setMonthFilter', Number(event.target.value));
    handleClose('month');
  };
  const handleYearChange = (event: SelectChangeEvent) => {
    onCallBackSearchFilterDropdown('setYearFilter', event.target.value);
    handleClose('year');
  };
  const handleStatusChange = (event: SelectChangeEvent) => {
    // have to manually handle the status change, because the status from single select returns String not Array
    if (event.target.value === '1,2') {
      onCallBackSearchFilterDropdown('setStatusFilter', [1, 2]);
    } else if (event.target.value === '3') {
      onCallBackSearchFilterDropdown('setStatusFilter', [3]);
    }
    handleClose('status');
  };
  const onCallBackTrainingTypeFilter = (type: CallBackFilterType, payload?: any) => {
    if (type === 'setSelectedFilter' && payload !== undefined) onCallBackSearchFilterDropdown('setTrainingTypeFilter', payload);
    if (type === 'finishSelectingFilter') onCallBackSearchFilterDropdown('finishSelectingFilter');
  };

  return (
    <>
      <Select
        value={monthFilter as any as string}
        onChange={handleMonthChange}
        open={isOpenMonthFilter}
        onOpen={() => handleOpen('month')}
        onClose={(event: any) => {
          if (event.target.selected === undefined) handleClose('month');
        }}
        input={<OutlinedInput />}
        sx={{ width: '120px' }}
        className="mr-2 mb-6"
      >
        {monthData.map((month) => (
          <MenuItem key={month.value} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </Select>

      <Select
        value={yearFilter as any as string}
        onChange={handleYearChange}
        open={isOpenYearFilter}
        onOpen={() => handleOpen('year')}
        onClose={(event: any) => {
          if (event.target.selected === undefined) handleClose('year');
        }}
        displayEmpty
        input={<OutlinedInput />}
        sx={{ width: '120px' }}
        className="mr-4 mb-6"
      >
        <MenuItem value={0}>All years</MenuItem>
        {yearData
          .sort((a, b) => b - a)
          .map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
      </Select>

      <FilterMultiSelect
        clearable
        filterData={store.trainingTypeFilter?.data?.data || ([] as CourseTrainingTypeSchema[])}
        isLoadingFilterData={store.trainingTypeFilter.state !== 'ready'}
        selectedFilter={trainingTypeFilter}
        onCallBackFilter={onCallBackTrainingTypeFilter}
        dropdownSetting={{
          valueKey: 'trainingCode',
          labelKey: 'trainingName',
          noSelectedText: 'All type',
        }}
        sx={{ width: '120px' }}
        className="mr-3 mb-6"
      />

      <Select
        value={statusFilter.toString()}
        onChange={handleStatusChange}
        open={isOpenStatusFilter}
        onOpen={() => handleOpen('status')}
        onClose={(event: any) => {
          if (event.target.selected === undefined) handleClose('status');
        }}
        displayEmpty
        renderValue={(selectedValue) => {
          if (!selectedValue) return <span>Draft, Published, Cancelled</span>;
          return statusData.find((item) => item.value.toString() === selectedValue)?.label;
        }}
        input={<OutlinedInput />}
        sx={{ width: '162px' }}
        className="mr-5 mb-6"
      >
        {statusData.map((status) => (
          <MenuItem key={status.value} value={status.value}>
            {status.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default SearchFilterDropdown;
