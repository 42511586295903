export const IncidentOverviewOptions = {
  title: {
    show: false,
    textStyle: {
      color: 'grey',
      fontSize: 17,
    },
    text: 'No data',
    left: '45%',
    top: 'center',
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}',
  },
  legend: {
    top: '15%',
    left: 'center',
    textStyle: {
      fontSize: 13,
    },
  },
  xAxis: {
    type: 'category',
    splitLine: { show: true },
    data: [],
    axisLabel: {
      show: true,
      interval: 0,
    },
  },
  grid: {
    top: '28%',
    left: '0%',
    right: '0%',
    bottom: '1%',
    containLabel: true,
  },
  yAxis: {
    type: 'value',
    minorSplitLine: {
      show: true,
    },
  },
  series: [
    {
      name: 'TRIR',
      id: 'trir',
      type: 'line',
      data: [],
      color: '#EFD266',
      symbol: 'circle',
      label: {
        show: true,
        position: 'top',
      },
    },
    {
      name: 'LTIFR',
      id: 'ltift',
      type: 'line',
      data: [],
      color: '#DE6686',
      symbol: 'circle',
      label: {
        show: true,
        position: 'top',
      },
    },
    {
      name: 'Man-hours (x1000)',
      id: 'man-hours',
      type: 'line',
      data: [],
      color: '#6D9BE6',
      symbol: 'circle',
      label: {
        show: true,
        position: 'top',
      },
    },
  ],
};
