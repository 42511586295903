import { IconButton, TableBody, TableCell, TableRow, Box, Table, TableContainer, DialogContent, DialogTitle, Typography, Dialog } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Icon } from '../../icons';

import { headCellsCourses } from '@/src/static';
import { EnhancedTableHead } from '../../tableHead';

import { NavLink } from 'react-router-dom';
import { CourseSmallSchema, CourseRequestDtoType } from '@/src/types';
import { getByTrainingCode, serverError, downloadLink, delay, isSuperAdmin } from '@/src/utils/index';

import { CourseStore } from '@/types/store';
import CourseForm from '../createEditCourseModal/courseForm';
import { Logger } from '@/logger/index';
import { useGlobalContext } from '@/store/index';
import { TableDataError } from '../../dataError';
import { TableDataNotFound } from '../../dataNotFound/index';
import DeleteCourseModal from '../deleteCourseModal';

interface EnhancedTableCoursesProps {
  [name: string]: any;
  isFiltered: boolean;
}

export function EnhancedTableCourses(props: EnhancedTableCoursesProps & CourseStore) {
  const { currentUser } = useGlobalContext();

  const { store, isFiltered } = props;
  const [currentid, setCurrentid] = useState('');
  const [getUid, setUid] = useState<string | undefined>(undefined);
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

  const handleOpenDelete = (employeeId) => {
    setConfirmDeleteOpen(true);
    setCurrentid(employeeId);
  };

  const onGetCourseHandler = (uid: string) => {
    store.courseById(uid);
    setUid(uid);
    Logger(['[courseById][onGetCourseHandler]'], 'attention');
  };

  const onSubmitEditHandler = (data: CourseRequestDtoType, uid: string) => {
    //  Logger(['[edit/course][onSubmitEditHandler]', data], 'attention');
    Logger(['json', JSON.stringify(data)], 'log');
    store.courseUpdate(uid, data as any, 'list');
  };

  useEffect(() => {
    // we have update the date on the form we can not close the modal
    if (store.courseUpdatedData.state === 'ready' && getUid) {
      Logger(['[store][courseUpdatedData]', store.courseUpdatedData.data], 'attention');
      setUid(undefined);
    }

    if (store.courseUpdatedData.state === 'error') {
      Logger(['[store][courseUpdatedData]', store.courseUpdatedData.error], 'error');
      delay(5000).then(() => {
        store.resetStatus('course/update');
      });
    }
  }, [store, setUid]);

  if (store.courseByIdData.state === 'error') {
    Logger(['[store][courseByIdData]', store.courseByIdData.error], 'error');
  }

  if (store.courseByIdData.state === 'ready') {
    Logger(['[store][courseByIdData]', store.courseByIdData.data], 'attention');
  }

  /** only can edit if there is edit data,  status is ready and we have uid to call to update with*/
  // const canEdit = !!(store?.courseByIdData?.state === 'ready' && getUid);

  return (
    <>
      {isSuperAdmin(currentUser?.userProfile) && (
        <Dialog
          open={!!getUid}
          onClose={() => {
            setUid(undefined);
            store.resetStatus('course/update');
            if (store.courseByIdData.state === 'error') {
              store.resetStatus('course/id');
            }
          }}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'body'}
        >
          <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
            <Typography className="font-medium text-2xl">Edit course</Typography>
          </DialogTitle>
          <DialogContent className="p-0 " sx={{ overflow: 'unset' }}>
            <CourseForm
              mode="edit"
              error={store.courseByIdData.state === 'error'}
              loading={store?.courseByIdData.state !== 'error' && store?.courseByIdData.state !== 'ready'}
              editUid={getUid}
              editData={store.courseByIdData?.data?.data as any}
              serverErrorResponse={serverError(store?.courseUpdatedData?.error)}
              allRequired={true}
              allRequiredMessage="cannot be empty."
              onClose={() => {
                if (store.courseByIdData.state === 'error') {
                  store.resetStatus('course/id');
                }
                setUid(undefined);
                store.resetStatus('course/update');
              }}
              onSubmit={onSubmitEditHandler as any}
              status="open"
            />
          </DialogContent>
        </Dialog>
      )}

      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead headCells={headCellsCourses} />
          {store?.courseList.state === 'ready' && (
            <TableBody>
              {store?.courseList.data.data.map((row: CourseSmallSchema) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{row.courseNo}</TableCell>
                  {/** data added from courseCreate() is added dynamically and does not have courseName */}
                  <TableCell>{row.courseName}</TableCell>

                  <TableCell>
                    <Box className="flex items-center">
                      <Icon
                        className="pr-2 w-2"
                        icon="circle"
                        color={row.trainingCode === 'classroom' ? '#68B7DC' : row.trainingCode === 'online' ? '#6872DC' : '#A368DC'}
                        viewBox="0 0 384 512"
                        size={6}
                      />
                      {row.trainingName || getByTrainingCode(row.trainingCode as any)?.trainingName}
                    </Box>
                  </TableCell>
                  <TableCell align="right">{row.courseDuration}</TableCell>
                  <TableCell align="center">
                    {downloadLink(row.downloadPdfLink) ? (
                      <IconButton color="primary" aria-label="Download file" download href={downloadLink(row.downloadPdfLink)}>
                        <Icon icon="file" color="#3155FF" viewBox="0 0 384 512" size={18} />
                      </IconButton>
                    ) : null}
                  </TableCell>

                  <TableCell align="right">
                    <Box className="flex justify-end">
                      <NavLink to={`/courses/detail/${row.courseNo}`}>
                        <IconButton color="primary" aria-label="View details">
                          <Icon icon="eyes" color="#3155FF" viewBox="0 0 576 512" size={20} />
                        </IconButton>
                      </NavLink>

                      {isSuperAdmin(currentUser?.userProfile) && (
                        <>
                          <IconButton
                            color="primary"
                            aria-label="Edit details"
                            onClick={() => {
                              onGetCourseHandler(row.id);
                            }}
                          >
                            <Icon icon="penToSquare" color="#3155FF" viewBox="0 0 576 512" size={20} />
                          </IconButton>
                          <IconButton color="error" aria-label="Delete data" onClick={() => handleOpenDelete(row.id)}>
                            <Icon icon="trash" color="#F14242" viewBox="0 0 448 512" size={18} />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          {store?.courseList.state === 'error' && <TableDataError col={6} description={`Please reload the page again.`} />}

          {store?.courseList.state === 'ready' && store?.courseList.data.data.length === 0 && (
            <TableDataNotFound
              col={6}
              description={isFiltered ? 'Please change search keyword or select another training type' : 'No course information is provided to you'}
            ></TableDataNotFound>
          )}
        </Table>
      </TableContainer>
      {/* <EditCourseModal onCAllBack={onCAllBack} employee={getUid} openEdit={openEdit} ready={stateData === 'ready'} /> */}
      <DeleteCourseModal
        title={'Are you sure you want to delete this course?'}
        description={'This action will permanently delete the course and cannot be undone.'}
        isOpen={isConfirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        currentid={currentid}
      ></DeleteCourseModal>
    </>
  );
}
