// ! ATTENTION this is a non user model for no-access routes

/* eslint-disable no-unused-vars */
// import { Defer } from '@/types/index';

import { AzureAuthProviderProps } from '@/src/types';
import { UserAvailableOptions, UserModelData, UserProfile, UserSchema } from '@/types/schema/user.schema';
// import { defer, isAdmin } from '@/utils/index';
import Cookies from 'universal-cookie';

/**
 *  Non User model
 *
 */
export const nonUserModel = (userData: any): UserSchema => {
  class UserModel implements UserSchema {
    _userProfile: UserProfile = undefined as any;
    cookies: Cookies = new Cookies();
    private _data: UserModelData = undefined as any;

    constructor(data: UserModelData) {
      if (Object.keys(data || {}).length > 0) {
        this._data = data;
        this._userProfile = null as any; // this.azure.userProfile;
      }
    }

    // userCanOnIncident(incidentId: string, roleId?: UserRoleId) {
    //   return 'NO_ACCESS' as any;
    // }

    /**
     * Check if you are an admin
     */
    get aimAdmin(): boolean {
      return false;
    }

    /**
     * @azure profile and account details
     * - You can get accountStatus from azure.accountStatus
     */
    get azure(): AzureAuthProviderProps {
      return undefined as any;
    }

    /** Currently logged/in  user profile */
    get userProfile() {
      return undefined as any;
    }

    /** no user options for non users */
    get userOptions(): UserAvailableOptions {
      return undefined as any;
    }

    get displayName(): string {
      return 'No Account found';
    }
  }

  const user = new UserModel(userData);
  return user;
};
