import React, { useState, useEffect } from 'react';
import { PropDefaults } from '@/types/common';
import { Paper, Typography, Box } from '@mui/material';
import { CreateAdminModal, EnhancedTableAdmin, EnhancedTablePagination, FilterUserCompany, SearchBoxInput } from '@/components/index';
import { AdminStore, AzureAuthProviderProps } from '@/src/types/store';
import { withAdminContext } from '@/store/admins.store';
import { EmployeeCreate } from '@/src/types';
import LinearProgress from '@mui/material/LinearProgress';
import { checkIsLastPage, getAdminParamsFilter } from '@/src/utils';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { ADMINS_PAGE_TITLE } from '@/src/static';

const PAGE_TITLE = 'Admins';
interface AdminsProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title: string;
}

function Admins(props: AdminsProps & AdminStore) {
  setDocTitle(ADMINS_PAGE_TITLE);
  const { store } = props;
  const { businessUnits, employees, resDeletedEmployees } = store || {};
  const [mount, setMount] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [companyValue, setCompanyValue] = React.useState<string[]>([]);
  const [nameSearching, setNameSearching] = React.useState<string>('');

  useEffect(() => {
    if (!mount) {
      if (businessUnits.state === 'initial' && !businessUnits.data) store.setBusinessUnits();
      //  if (employeesFilter.state === 'initial' && !employeesFilter.data) store.setEmployeesFilter({ q: '' });
      setMount(1);
    }
  }, [mount, setMount]);

  useEffect(() => {
    if (businessUnits.state !== 'ready') return;
    if (employees.state === 'initial' && !employees.data) {
      const params = getAdminParamsFilter(businessUnits.data, 0, rowsPerPage, companyValue, nameSearching);
      store.setEmployees(params);
    }
  }, [businessUnits]);

  useEffect(() => {
    if (resDeletedEmployees.state === 'ready') {
      // to check if the current page is the last page
      const isLastPage = checkIsLastPage(employees.data.pagination.pageNumber, employees.data.pagination.totalItems, employees.data.pagination.itemPerPage);

      // to check if the row that has been deleted is only a last one item in the last list page then paginate to previous pageNumber (except for last page = 0)
      if (employees.data.data.length === 0 && isLastPage && employees.data.pagination.pageNumber !== 0) {
        const params = getAdminParamsFilter(businessUnits.data, page - 1, rowsPerPage, companyValue, nameSearching);
        setPage(page - 1);
        store.setEmployees(params);
      } else {
        const params = getAdminParamsFilter(businessUnits.data, page, rowsPerPage, companyValue, nameSearching);
        store.setEmployees(params);
      }
    }
  }, [resDeletedEmployees]);

  const onCallBackFilter = (type: 'setCompanyValue' | 'reloadByParams', payload: any) => {
    let params = getAdminParamsFilter(businessUnits.data, 0, rowsPerPage, companyValue, nameSearching);
    if (type === 'setCompanyValue' && payload !== undefined) {
      setCompanyValue(payload);
      params = getAdminParamsFilter(businessUnits.data, 0, rowsPerPage, payload, nameSearching);
    }
    if (type !== 'reloadByParams') {
      setPage(0);
      store.setEmployees(params);
    }
  };

  const onCallBackPagination = (type: 'setRowsPerPage' | 'setPage' | 'setNewPage', payload: any) => {
    if (type === 'setPage' && payload !== undefined) setPage(payload);
    if (type === 'setRowsPerPage' && payload !== undefined) setRowsPerPage(payload);
    if (type === 'setNewPage' && payload !== undefined) {
      const params = getAdminParamsFilter(businessUnits.data, payload.newPage, payload.rowsPerPage, companyValue, nameSearching);
      store.setEmployees(params);
    }
  };

  const onEnterSearch = (searchText: string) => {
    setPage(0);
    setNameSearching(searchText);
    const params = getAdminParamsFilter(businessUnits.data, 0, rowsPerPage, companyValue, searchText);
    store.setEmployees(params);
  };

  const onClearSearch = () => {
    if (nameSearching) {
      setPage(0);
      setNameSearching('');
      const params = getAdminParamsFilter(businessUnits.data, 0, rowsPerPage, companyValue, '');
      store.setEmployees(params);
    }
  };

  const handleOnAdded = (create: { data: EmployeeCreate }) => {
    store.updateEmployeesState(create);
  };

  const paginationState =
    employees.state === 'ready'
      ? {
          totalItems: employees.data?.pagination.totalItems,
          page: employees.data?.pagination.pageNumber,
          rowsPerPage: employees.data?.pagination.itemPerPage,
        }
      : {
          totalItems: rowsPerPage * (page + 1),
          page: page,
          rowsPerPage: rowsPerPage,
        };

  return (
    <Paper elevation={0}>
      <Typography variant="h1" className="mb-6">
        {PAGE_TITLE}
      </Typography>
      <Paper elevation={4}>
        <Box className="flex  p-6">
          <SearchBoxInput onEnterSearch={onEnterSearch} onClearSearch={onClearSearch} />

          <FilterUserCompany
            data={businessUnits.data}
            companyValue={companyValue}
            loading={businessUnits.state !== 'ready'}
            onCallBackFilter={onCallBackFilter}
          />
          {employees.state === 'ready' ? <CreateAdminModal onAdded={handleOnAdded} ready={employees.state === 'ready'} /> : null}
        </Box>
        <Box className="px-6">
          <EnhancedTableAdmin
            data={employees.data}
            stateData={employees.state}
            isFiltered={!!nameSearching || companyValue.length > 0}
            loadlistpage={() => {
              const params = getAdminParamsFilter(businessUnits.data, 0, rowsPerPage, companyValue, nameSearching);
              store.setEmployees(params);
            }}
          />
        </Box>
        <Box>
          {(employees.state === 'initial' || employees.state === 'loading' || resDeletedEmployees.state === 'ready') && (
            <LinearProgress style={{ margin: '0px 24px 0px 24px' }} />
          )}
        </Box>
        {employees.state !== 'error' && (
          <EnhancedTablePagination
            totalItems={paginationState.totalItems}
            page={paginationState.page}
            rowsPerPage={paginationState.rowsPerPage}
            onCallBackPagination={onCallBackPagination}
            isSoftReload={resDeletedEmployees && resDeletedEmployees.state === 'ready'}
          />
        )}
      </Paper>
    </Paper>
  );
}

export default withAdminContext(Admins);
