import React, { useEffect, useState } from 'react';
import { PropDefaults } from '@/types/common';
import { Paper, Typography, Box, LinearProgress, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { CreateTrainingModal, EnhancedTablePagination, EnhancedTableTrainings, Icon, SearchBoxInput, SearchFilterDropdown } from '@/components/index';
import { AzureAuthProviderProps, TrainingStore } from '@/src/types/store';
import { QueryTrainingList, TrainingStatus, TrainingRequestDtoType, TrainingBodyData, TrainingSubmitType } from '@/src/types';
import { useGlobalContext } from '@/store/index';
import { SnackBarAlert } from '@/components/ui/variants/snackbar';
import { FIELD_REQUIRED } from '@/src/static';
import { Logger } from '@/logger/index';
import { checkIsLastPage, delay, isSuperAdmin, serverError } from '@/src/utils';
import TrainingForm from '@/src/components/ui/trainings/createUpdateTrainingModal/trainingFormClass';
import { NavLink } from 'react-router-dom';

const PAGE_TITLE = 'Trainings';
interface TrainingProps extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
  onTrainingSubmitType?: (submitType: TrainingSubmitType) => void;
}

function Trainings(props: TrainingProps & TrainingStore) {
  // const now = new Date();
  const { currentUser } = useGlobalContext();
  const { azure, store, onTrainingSubmitType } = props;
  const { trainingListData } = store || {};

  const [mount, setMount] = useState(0);
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  // const [monthFilter, setMonthFilter] = React.useState<number>(now.getMonth() + 1);
  // const [yearFilter, setYearFilter] = React.useState<number>(now.getFullYear());
  const [monthFilter, setMonthFilter] = React.useState<number>(0);
  const [yearFilter, setYearFilter] = React.useState<number>(0);
  const [trainingTypeFilter, setTrainingTypeFilter] = React.useState<string[]>([]);
  const [statusFilter, setStatusFilter] = React.useState<TrainingStatus[]>([1, 2]);
  const [prevStatusFilter, setPrevStatusFilter] = React.useState<TrainingStatus[]>(statusFilter);
  const [nameSearching, setNameSearching] = useState<string>('');
  // const [toastDisplayMessages, setToastMessages] = useState<{ error?: string; success?: string }>({ success: '', error: '' });
  const toastDisplayMessages: { error?: string; success?: string } = { success: '', error: '' };
  const [alertState, setAlertState] = useState<boolean | undefined>(undefined as any);
  // const [alertSeverity, setAlertSeverity] = useState<'error' | 'success'>('success');
  const alertSeverity: 'error' | 'success' = 'success';

  const [uidVal, setUid] = useState<string | undefined>(undefined as any);
  const userProfile = currentUser?.userProfile;

  /**
   * call to api to get latest training detail information for edit training modal
   * and set uid
   */
  const handleOpenTrainingModal = (uid: string) => {
    store.setTrainingDetail(uid, { status: 1 });
    setUid(uid);
  };
  const onSubmitHandler = (data: TrainingRequestDtoType | TrainingBodyData, submitType: 'submit-publish' | 'submit-draft' | 'submit-edit-publish') => {
    Logger(['[create/training][onSubmitHandler]', data], 'attention');
    Logger(['json', JSON.stringify(data)], 'log');

    /**
     * NOTE on update/draft {id} is passed within the formData
     */
    store.setTrainingCreateUpdate(data as any, 'list', 'edit');
    if (onTrainingSubmitType) onTrainingSubmitType(submitType);
  };

  useEffect(() => {
    if (!mount) {
      const params: QueryTrainingList = {
        itemPerPage: itemPerPage,
        pageNumber: pageNumber,
        status: statusFilter,
        year: yearFilter,
      };
      if (monthFilter !== 0) params.month = monthFilter;

      if (trainingListData.state === 'initial' && !trainingListData.data) store.setTrainingList(params);
      if (store.trainingTypeFilter.state === 'initial' && !store.trainingTypeFilter.data) store.setTrainingTypeFilter();
      setMount(1);
    }
  }, [mount, setMount]);

  useEffect(() => {
    return () => {
      // NOTE: to reset store.trainingListData back to the data with initial filter after unmount
      store.resetStatus('trainings');
    };
  }, []);

  useEffect(() => {
    if (store.trainingDeletedData.state === 'ready') {
      // to check if the current page is the last page
      const isLastPage = checkIsLastPage(
        trainingListData.data?.pagination?.pageNumber,
        trainingListData.data?.pagination?.totalItems,
        trainingListData.data?.pagination?.itemPerPage
      );

      const params: QueryTrainingList = {
        pageNumber: pageNumber,
        itemPerPage: itemPerPage,
        status: statusFilter,
        year: yearFilter,
      };
      if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
      if (nameSearching) params.q = nameSearching;
      if (monthFilter !== 0) params.month = monthFilter;

      // to check if the row that has been deleted is only a last one item in the last list page then paginate to previous pageNumber (except for last page = 0)
      if (trainingListData.data?.data.length === 0 && isLastPage && trainingListData.data?.pagination?.pageNumber !== 0) {
        params.pageNumber = pageNumber - 1;
        setPageNumber(pageNumber - 1);
        store.setTrainingList(params, 'hidden_load_after_deleted');
      } else {
        store.setTrainingList(params, 'hidden_load_after_deleted');
      }
    }
  }, [store.trainingDeletedData]);

  useEffect(() => {
    let hasCanceledData = false;
    if (!statusFilter.includes(3) && trainingListData?.data?.data) {
      // check if there is a Canceled data in the store after changed status in training detail
      const findCanceledData = trainingListData?.data?.data.filter((data) => data.status === 3);
      if (findCanceledData.length > 0) hasCanceledData = true;
    }

    if (store.trainingCanceledData.state === 'ready' && hasCanceledData) {
      const isLastPage = checkIsLastPage(
        trainingListData.data?.pagination?.pageNumber,
        trainingListData.data?.pagination?.totalItems,
        trainingListData.data?.pagination?.itemPerPage
      );

      const params: QueryTrainingList = {
        pageNumber: pageNumber,
        itemPerPage: itemPerPage,
        status: statusFilter,
        year: yearFilter,
      };
      if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
      if (nameSearching) params.q = nameSearching;
      if (monthFilter !== 0) params.month = monthFilter;

      const remainNotCanceled = trainingListData?.data?.data.filter((data) => data.status !== 3);

      // to check if the row that has been Canceled is only a last one item in the last list page then paginate to previous pageNumber (except for last page = 0)
      if (remainNotCanceled.length === 0 && isLastPage && trainingListData.data?.pagination?.pageNumber !== 0) {
        params.pageNumber = pageNumber - 1;
        setPageNumber(pageNumber - 1);
        store.setTrainingList(params, 'hidden_load_after_deleted');
      } else {
        store.setTrainingList(params, 'hidden_load_after_deleted');
      }
    }
  }, [store.trainingCanceledData, store.trainingListData]);

  useEffect(() => {
    if (store.trainingCanceledData.state === 'ready') {
      Logger(['[trainingCanceledData]', store.trainingCanceledData.data], 'log');
      setUid(undefined);
      return;
    }

    if (store.trainingCanceledData.state === 'error') {
      Logger(['[trainingCanceledData]', store.trainingCanceledData.data], 'error');
    }
  }, [store.trainingCanceledData.state]);

  useEffect(() => {
    if (store.trainingDeletedData.state === 'ready') {
      Logger(['[trainingDeletedData]', store.trainingDeletedData.data], 'log');
      return;
    }
    if (store.trainingDeletedData.state === 'error') {
      Logger(['[trainingDeletedData]', store.trainingDeletedData.data], 'error');
    }
  }, [store.trainingDeletedData.state]);

  useEffect(() => {
    // we have update the date on the form we can not close the modal
    if (store.trainingCreateUpdateData.state === 'ready' && !store.trainingCreateUpdateData.checked) {
      Logger(['[trainingCreateUpdateData][created]', store.trainingCreateUpdateData.data], 'log');
      store.resetStatus('training/detail');
      delay(1500).then(() => {
        store.resetStatus('training/create');
      });
      setUid(undefined);
      store.trainingCreateUpdateData.checked = true;
      return;
    }

    if (store.trainingCreateUpdateData.state === 'error') {
      delay(4000).then(() => {
        store.resetStatus('training/create');
      });
    }
  }, [store.trainingCreateUpdateData.state]);

  const onEnterSearch = (searchText: string) => {
    setPageNumber(0);
    setNameSearching(searchText);
    const params: QueryTrainingList = {
      pageNumber: 0,
      itemPerPage: itemPerPage,
      year: yearFilter,
    };
    if (searchText) params.q = searchText;
    if (statusFilter.length) params.status = statusFilter;
    if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
    if (monthFilter !== 0) params.month = monthFilter;
    store.setTrainingList(params);
  };

  const onClearSearch = () => {
    if (nameSearching) {
      setPageNumber(0);
      setNameSearching('');
      const params: QueryTrainingList = {
        pageNumber: 0,
        itemPerPage: itemPerPage,
        year: yearFilter,
      };
      if (statusFilter.length) params.status = statusFilter;
      if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
      if (monthFilter !== 0) params.month = monthFilter;

      store.setTrainingList(params);
    }
  };

  const onCallBackSearchFilterDropdown = (
    type: 'setMonthFilter' | 'setYearFilter' | 'setTrainingTypeFilter' | 'setStatusFilter' | 'finishSelectingFilter',
    payload?: any
  ) => {
    setPageNumber(0);
    const params: QueryTrainingList = {
      pageNumber: 0,
      itemPerPage: itemPerPage,
      year: yearFilter,
    };
    if (nameSearching) params.q = nameSearching;
    if (type === 'setMonthFilter' && payload !== undefined) {
      setMonthFilter(payload);
      if (payload !== 0) params.month = payload;
    } else if (monthFilter !== 0) params.month = monthFilter;
    if (type === 'setYearFilter' && payload !== undefined) {
      setYearFilter(payload);
      params.year = payload;
    }
    if (type === 'setStatusFilter' && payload !== undefined) {
      setStatusFilter(payload);
      if (payload.length) params.status = payload;
    } else if (statusFilter.length) params.status = statusFilter;
    if (type === 'setTrainingTypeFilter' && payload !== undefined) {
      setTrainingTypeFilter(payload);
      if (payload.length) params.trainingCodes = payload.join(',');
    } else if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
    if (type !== 'finishSelectingFilter') {
      store.setTrainingList(params);
      setPrevStatusFilter(statusFilter);
    }
  };

  const onCallBackPagination = (type: 'setRowsPerPage' | 'setPage' | 'setNewPage', payload: any) => {
    if (type === 'setPage' && payload !== undefined) setPageNumber(payload);
    if (type === 'setRowsPerPage' && payload !== undefined) setItemPerPage(payload);
    if (type === 'setNewPage' && payload !== undefined) {
      const params: QueryTrainingList = {
        pageNumber: payload.newPage,
        itemPerPage: payload.rowsPerPage,
        year: yearFilter,
      };
      if (trainingTypeFilter.length) params.trainingCodes = trainingTypeFilter.join(',');
      if (statusFilter.length) params.status = statusFilter;
      if (nameSearching) params.q = nameSearching;
      if (monthFilter !== 0) params.month = monthFilter;

      store.setTrainingList(params);
    }
  };
  const canEdit = /* store.trainingDetailData.state === 'ready' &&*/ !!uidVal;

  const paginationState =
    store.trainingListData.state === 'ready'
      ? {
          totalItems: store.trainingListData.data?.pagination?.totalItems,
          page: store.trainingListData.data?.pagination?.pageNumber,
          rowsPerPage: store.trainingListData.data?.pagination?.itemPerPage,
        }
      : {
          totalItems: itemPerPage * (pageNumber + 1),
          page: pageNumber,
          rowsPerPage: itemPerPage,
        };

  return (
    <>
      {isSuperAdmin(userProfile) ? (
        <Dialog
          open={canEdit}
          onClose={() => {
            store.resetStatus('training/create');
            if (store.trainingDetailData.state === 'ready') store.resetStatus('training/detail');
            setUid(undefined);
          }}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'body'}
          className="overflow-hidden"
        >
          <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography className="font-medium text-2xl">Edit training</Typography>
              <Button className="mx-3 p-0 small mt-1" size="small" variant="contained" disabled sx={{ height: 25 }}>
                Draft
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent className="p-0 " sx={{ overflow: 'unset' }}>
            <TrainingForm
              mode="edit"
              loading={store.trainingDetailData.state !== 'error' && store.trainingDetailData.state !== 'ready'}
              editUid={uidVal}
              editData={store.trainingDetailData.data?.data}
              error={store.trainingDetailData.state === 'error'}
              serverErrorResponse={serverError(store?.trainingCreateUpdateData.error)}
              allRequired={true}
              allRequiredMessage={FIELD_REQUIRED}
              onClose={() => {
                store.resetStatus('training/create');
                if (store.trainingDetailData.state === 'ready') store.resetStatus('training/detail');
                setUid(undefined);
              }}
              onSubmit={onSubmitHandler}
              formStatus={'open'}
            />
          </DialogContent>
        </Dialog>
      ) : null}
      <Paper elevation={0}>
        <Typography variant="h1" className="mb-6">
          {PAGE_TITLE}
        </Typography>

        <Paper elevation={4}>
          <Box className="flex flex-wrap p-6 px-4 pb-0 mx-2">
            <SearchBoxInput onEnterSearch={onEnterSearch} onClearSearch={onClearSearch} />

            <SearchFilterDropdown
              store={store}
              onCallBackSearchFilterDropdown={onCallBackSearchFilterDropdown}
              monthFilter={monthFilter}
              yearFilter={yearFilter}
              trainingTypeFilter={trainingTypeFilter}
              statusFilter={statusFilter}
            />
            <Box className="mr-2 mb-6 flex ml-auto border border-gray-300 border-solid rounded-lg">
              <NavLink to="/trainings/calendar" className="font-medium px-3  flex">
                <Icon icon="calendarDays" size={20} className="m-auto" />
              </NavLink>
              <NavLink to="/trainings" className="font-medium px-3 border-0 border-l border-gray-300 border-solid flex">
                <Icon icon="listUl" size={20} color="#3155FF" className="m-auto" />
              </NavLink>
            </Box>
            {isSuperAdmin(currentUser?.userProfile) && (
              <CreateTrainingModal
                store={store}
                onSubmitType={(status: 'submit-publish' | 'submit-draft' | 'submit-edit-publish') => {
                  if (onTrainingSubmitType) {
                    if (status === 'submit-draft') onTrainingSubmitType('submit-draft-new');
                    if (status === 'submit-publish') onTrainingSubmitType('submit-publish-new');
                  }
                }}
              />
            )}
          </Box>
          <Box className="px-6">
            {store && (
              <EnhancedTableTrainings
                sortBy={prevStatusFilter}
                handlerOpenEditModal={handleOpenTrainingModal}
                azure={azure}
                store={store}
                isFiltered={!!nameSearching || trainingTypeFilter.length > 0}
              />
            )}
          </Box>
          <Box>
            {(trainingListData.state === 'initial' ||
              trainingListData.state === 'loading' ||
              store.trainingDeletedData.state === 'ready' ||
              store.trainingCanceledData.state === 'ready') && <LinearProgress style={{ margin: '0px 24px 0px 24px' }} />}
          </Box>
          {store.trainingListData.state !== 'error' && (
            <EnhancedTablePagination
              totalItems={paginationState.totalItems}
              page={paginationState.page}
              rowsPerPage={paginationState.rowsPerPage}
              onCallBackPagination={onCallBackPagination}
              isSoftReload={store.trainingDeletedData && store.trainingDeletedData.state === 'ready'}
            />
          )}

          <SnackBarAlert
            messages={toastDisplayMessages as any}
            severity={alertSeverity}
            state={alertState as any}
            onClose={() => {
              setAlertState(false);
            }}
          />
        </Paper>
      </Paper>
    </>
  );
}

export default Trainings;
