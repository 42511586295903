/** declare only http service related requests */

import { HttpTokens, UpdateEmployeeById } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description update employee by ID
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const EmployeeUpdate = (
  prefix: string = 'employee/update',
  employeeId: string,
  data: any,
  tokens: HttpTokens,
  hardRejection = true
): Promise<UpdateEmployeeById> => {
  const instance = new AxiosService({ method: 'put', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend
  // const queryUri = paramsQueryString(prefix, params);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  const urlencoded = new URLSearchParams();
  urlencoded.append('roleId', data.roleId);
  return instance.client
    .put(`${prefix}/${employeeId}`, urlencoded, config)
    .then((d: AxiosResponse<UpdateEmployeeById>) => {
      // return Promise.reject(new Error('ups!')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<UpdateEmployeeById>;
};
