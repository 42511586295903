export const TRIRoptions = {
  title: {
    left: 'center',
    textStyle: {
      fontSize: 15,
    },
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}',
  },
  legend: {
    left: 'center',
    textStyle: {
      fontSize: 16,
    },
  },
  xAxis: {
    type: 'category',
    splitLine: { show: true },
    data: [],
  },
  grid: {
    left: '0%',
    right: '0%',
    bottom: '1%',
    containLabel: true,
  },
  yAxis: {
    type: 'value',
    minorSplitLine: {
      show: true,
    },
  },
  series: [
    {
      name: 'TRIR: Total Recordable Injury Rate',
      type: 'line',
      data: [],
      color: '#EFD266',
      symbol: 'circle',
      label: {
        show: true,
        position: 'top',
      },
    },
  ],
};

export const LTIFRoptions = {
  title: {
    left: 'center',
    textStyle: {
      fontSize: 15,
    },
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}',
  },
  legend: {
    left: 'center',
    textStyle: {
      fontSize: 16,
    },
  },
  xAxis: {
    type: 'category',
    splitLine: { show: true },
    data: [],
  },
  grid: {
    left: '0%',
    right: '0%',
    bottom: '1%',
    containLabel: true,
  },
  yAxis: {
    type: 'value',
    minorSplitLine: {
      show: true,
    },
  },
  series: [
    {
      name: 'LTIFR: Lost Time Injury Frequency Rate',
      type: 'line',
      data: [],
      color: '#DE6786',
      symbol: 'circle',
      label: {
        show: true,
        position: 'top',
      },
    },
  ],
};
