import React, { useEffect, useState } from 'react';
import { AzureAuthProviderProps, IncidentStore, PropDefaults, ReducerActionType } from '@/src/types';

import { TRIRchartLeft } from './TRIR.chart';
import { LTIFRchartRight } from './LTIFR.chart';
import { Box, Skeleton } from '@mui/material';

interface Props extends PropDefaults {
  chartQueryState: ReducerActionType;
  [name: string]: any;
  azure: AzureAuthProviderProps;
  title?: string;
  onReadyStatusChart: () => void;
  onReadyComplianceChart: () => void;
}

const IncidentChartSections = (props: Props & IncidentStore) => {
  const { store: incidentStore, chartQueryState } = props;

  const { onReadyStatusChart } = props;
  const [mount, setMount] = useState<number>(0);

  useEffect(() => {
    if (!mount) {
      if (incidentStore.incidentChartData.state === 'initial' && !incidentStore.incidentChartData?.data?.data) {
        incidentStore.setIncidentChart(chartQueryState.value || {});
      }
      setMount(1);
    }

    if (chartQueryState.state === 'updated' && !chartQueryState.checked) {
      incidentStore.setIncidentChart(chartQueryState.value || {}, 'update', (ok, d) => {
        chartQueryState.value.loading = false;
      });
      chartQueryState.checked = true;
    }
  }, [mount, setMount, incidentStore, chartQueryState]);

  return (
    <React.Fragment>
      {['error', 'ready'].indexOf(incidentStore.incidentChartData.state) !== -1 ? (
        <>
          <TRIRchartLeft {...(props as any)} onChartReady={onReadyStatusChart} />
          <LTIFRchartRight {...(props as any)} onChartReady={onReadyStatusChart} />
        </>
      ) : (
        <>
          <Box className="h-auto w-full mr-6 rounded-2xl shadow-md relative" sx={{ maxHeight: 330 }}>
            <Box className="w-full flex absolute top-7 z-30 justify-center">
              <Box className="w-6 mr-1 relative">
                <Box className="w-full h-[2px] top-[calc(50%-1px)] bg-[#EFD266] absolute"></Box>
                <Box className="w-[11px] h-[11px] rounded-full bg-[#EFD266] absolute left-[calc(50%-5.5px)] top-[calc(50%-5.5px)]"></Box>
              </Box>
              TRIR: Total Recordable Injury Rate
            </Box>
            <Skeleton animation="wave" component={'div'} className="h-[330px] w-[100%] mr-6 transform-none rounded-2xl  border-gray-200 bg-white" />
          </Box>
          <Box className="h-auto w-full rounded-2xl shadow-md relative" sx={{ maxHeight: 330 }}>
            <Box className="w-full flex absolute top-7 z-30 justify-center">
              <Box className="w-6 mr-1 relative">
                <Box className="w-full h-[2px] top-[calc(50%-1px)] bg-[#DE6786] absolute"></Box>
                <Box className="w-[11px] h-[11px] rounded-full bg-[#DE6786] absolute left-[calc(50%-5.5px)] top-[calc(50%-5.5px)]"></Box>
              </Box>
              LTIFR: Lost Time Injury Frequency Rate
            </Box>
            <Skeleton animation="wave" component={'div'} className="h-[330px] w-[100%] transform-none rounded-2xl border-gray-200 bg-white" />
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default React.memo(IncidentChartSections);
