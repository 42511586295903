import React, { useEffect, useState } from 'react';
import { Box, Step, StepButton, Stepper, Typography } from '@mui/material';
import { IncidentsForms } from '@/src/static/incidents/mockupData';
import { PropDefaults, IncidentFormStoreParts } from '@/src/types';
import { withIncidentFormContext } from '@/store/index';

interface ChecklistIncidentProps extends PropDefaults {
  checklistProps: { activeStep: number; handleChange: (step: number) => void };
}

const ChecklistIncident = (props: ChecklistIncidentProps & IncidentFormStoreParts) => {
  const {
    formStore,
    checklistProps: { activeStep, handleChange },
  } = props;
  const [completedPart, setCompletedPart] = useState<number[]>([]);

  useEffect(() => {
    const newCompletedPart: number[] = [];
    if (formStore.store.formData.initialReport.state === 'complete') newCompletedPart.push(0);
    if (formStore.store.formData.description.state === 'complete') newCompletedPart.push(1);
    if (formStore.store.formData.injured.state === 'complete') newCompletedPart.push(2);
    if (formStore.store.formData.propertyDamage.state === 'complete') newCompletedPart.push(3);
    if (formStore.store.formData.incidentInvestigation.state === 'complete') newCompletedPart.push(4);
    if (formStore.store.formData.incidentCausesAssessment.state === 'complete') newCompletedPart.push(5);
    if (formStore.store.formData.incidentInvestigationTeam.state === 'complete') newCompletedPart.push(6);
    if (formStore.store.formData.correctiveAction.state === 'complete') newCompletedPart.push(7);
    setCompletedPart(newCompletedPart);
  }, [formStore.store]);

  return (
    <Box
      className="w-full p-6 rounded-3xl bg-gradient-to-t from-blue-100 to-white max-w-[326px]"
      sx={{
        boxShadow: '0px 3px 10px #AAAAAA29',
      }}
    >
      <img src={require('@/assets/icons/report4x.png')} alt="Occupational" width={119} className=" absolute right-4 top-0" />
      <Typography variant="h2" color="primary">
        Checklist
      </Typography>
      <Typography variant="caption" className=" text-gray-400">
        If the part has no data, you can skip.
      </Typography>
      <Box className="mt-6">
        <Stepper nonLinear activeStep={activeStep} orientation="vertical" className="checklistStep">
          {IncidentsForms.map((label, index) => (
            <Step key={label} completed={completedPart.includes(index)}>
              <StepButton color="inherit" onClick={() => handleChange(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default withIncidentFormContext(ChecklistIncident);
