/** declare only http service related requests */

import { PostCreateCourse, CourseBodyData, HttpTokens } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description create new course and return new CourseSchema, including course id/uid
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const CourseCreatePost = (
  prefix: string = 'course/create',
  tokens: HttpTokens,
  body: CourseBodyData,
  hardRejection = true
): Promise<PostCreateCourse> => {
  const instance = new AxiosService({ method: 'post', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend
  // const queryUri = paramsQueryString(prefix, params);
  // new URLSearchParams();
  const config = {
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };
  // console.log('calling with', body);

  return (
    instance.client
      // TODO
      .post(`${prefix}`, JSON.stringify(body), config)
      .then((d: AxiosResponse<PostCreateCourse>) => {
        // return Promise.reject(new Error('ups!')) as any;
        return d.data;
      })
      .catch(instance.defaultHandleError) as Promise<PostCreateCourse>
  );
};
