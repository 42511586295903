import React from 'react';

import { Typography, Box, TableRow, TableCell, TableBody } from '@mui/material';
import { ErrorMessageSvg } from '@/src/static';

interface PropsTableData {
  description?: string;
  col: number;
}

interface PropsData {
  description?: string;
}

export const DataError = (props: PropsData): JSX.Element => {
  const { description } = props;
  return (
    <Box className="flex flex-col w-full items-center py-14">
      <div className="mb-1">
        {
          // NOTE for some reason importing svg as <img cause render error, but render directly as component works!
        }
        <ErrorMessageSvg />
      </div>

      <Typography variant="body2" className="p-0 text-gray-400 font-medium mb-1">
        We are experiencing issues
      </Typography>
      {description ? (
        <Typography variant="body2" className="p-0 text-gray-400 whitespace-nowrap">
          {description}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export const TableDataError = (props: PropsTableData): JSX.Element => {
  const { col } = props;
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={col} className="border-0">
          <DataError {...(props as any)} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
