import { DeleteCustomModal, Icon } from '@/src/components';

import React, { Component } from 'react';
import { Box, IconButton, TableBody, TableCell, TableRow, Table, TableContainer, TextField, Button, Typography } from '@mui/material';
import {
  IncidentCorrectiveActionPartial,
  IncidentFormStoreParts,
  IncidentActionItemSchema,
  IncidentStore,
  BodyUploadIncidentFile,
  UploadIncidentFile,
  IncidentSchema,
} from '@/src/types';
import { Subject } from 'rxjs';
import { headCellsIncidentsCorrectiveAction } from '@/src/static';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { EnhancedTableHead } from './../../../tableHead/index';
import { clone } from 'lodash';
import { exportLinkV2, validFileSize } from '@/src/utils';
import { v4 } from 'uuid';
import { StoreStateV3 } from '@/types/store/common';

interface CorrectiveActionPartEightProps extends IncidentFormStoreParts {
  [name: string]: any;
  incidentStore: IncidentStore;
  pageType: 'create' | 'update';
}

type FileAlt = File & { actionUid: string };

interface IncidentActionItemSchemaAlt extends IncidentActionItemSchema {
  uploaded?: boolean;
  /**
   * added later
   *
   */
}
interface State {
  error: any;
  actionItems: IncidentActionItemSchemaAlt[];
  actionUid: string;
  calendarDefaultValue: Date;
  fileSelectUpload: {
    file: FileAlt;
    state: StoreStateV3;
    uploaded: UploadIncidentFile | undefined;
  };
}

export default class CorrectiveActionPartEight extends Component<CorrectiveActionPartEightProps, {}, any> {
  subs$: Array<any> = [];
  subSearch$: Subject<{ q: string }> = new Subject();
  state: State;
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      actionItems: [],
      actionUid: null as any,
      calendarDefaultValue: new Date(),
      fileSelectUpload: null as any,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.incidentStore.store.incidentFileUploadData.state === 'ready') {
      const incidentFileUploadData: UploadIncidentFile = clone(this.incidentStore.store.incidentFileUploadData.data);
      this.incidentStore.store.resetStatus('incidents/cache/file/upload');
      let updated = false;
      this.state.actionItems.forEach((n) => {
        if (n.uid === incidentFileUploadData.data.ref) {
          n.uploaded = true;
          n.file = incidentFileUploadData.data;
          updated = true;
        }
      });

      if (updated) {
        this.setState({
          fileSelectUpload: {
            file: this.state.fileSelectUpload,
            state: 'ready',
            uploaded: incidentFileUploadData,
          },
          actionItems: this.state.actionItems,
        });

        this.handleChangeStoreUpdate(this.state.actionItems?.length ? clone(this.state.actionItems) : null, 'actionItems');
      }
      return;
    }

    if (this.incidentStore.store.incidentFileUploadData.state === 'error') {
      this.incidentStore.store.notify.onStatusChange('upload-error');

      this.incidentStore.store.resetStatus('incidents/cache/file/remove');
    }

    if (this.incidentStore.store.incidentFileRemoveData.state === 'ready') {
      this.incidentStore.store.notify.onStatusChange('removed');

      this.incidentStore.store.resetStatus('incidents/cache/file/remove');
    }

    if (this.incidentStore.store.incidentFileRemoveData.state === 'error') {
      this.incidentStore.store.notify.onStatusChange('remove-error');
      this.incidentStore.store.resetStatus('incidents/cache/file/remove');
    }
  }

  get incidentStore(): IncidentStore {
    return this.props.incidentStore;
  }

  get incidentDetailData(): IncidentSchema {
    if (this.props.incidentStore.store.incidentDetailData.state === 'ready' || this.props.incidentStore.store.incidentDetailData.state === 'updated') {
      return this.props.incidentStore.store.incidentDetailData.data.data;
    }
    return undefined as any;
  }

  getActionItemsById(uid): IncidentActionItemSchemaAlt {
    return (this.state.actionItems || []).filter((n) => n.uid === uid)[0];
  }

  handleChangeStoreUpdate(value: any, keyName: string, subKeyName?: string) {
    if (!this.props.formStore) return;
    this.props.formStore.setUpdateFormItem('correctiveAction', 'updated').setup(value, keyName, subKeyName);
  }

  componentDidMount() {
    this.setState({
      actionItems: (this.correctiveAction?.actionItems || []).map((n: any) => {
        if (n.file != null) {
          if (this.props.pageType === 'update') {
            n.updated = true;
            n.uploaded = true;
          }
          return n;
        }
      }),
    });
    this.setState({
      actionItems: this.correctiveAction?.actionItems || [],
    });
  }

  componentWillUnmount() {}

  get correctiveAction(): IncidentCorrectiveActionPartial {
    return clone(this.props.formStore.store.formData.correctiveAction.data);
  }

  onRemoveFile = (e: any, item: IncidentActionItemSchemaAlt, inx: number) => {
    if (item.file) {
      this.incidentStore.store.setIncidentFileRemove(item.file.uid, { cache: item.file.cached });
    }
    let updated = false;
    this.state.actionItems.forEach((el, inx) => {
      if (el.uid === item?.uid) {
        el.file = null as any;
        el.uploaded = false;
        updated = true;
      }
    });
    if (updated) {
      this.setState({ actionItems: this.state.actionItems });
      this.handleChangeStoreUpdate(this.state.actionItems?.length ? clone(this.state.actionItems) : null, 'actionItems');
    }
  };

  updateItemById(item: IncidentActionItemSchemaAlt, name: string, value: any) {
    let updated = false;
    this.state.actionItems.forEach((n) => {
      if (n.uid === item?.uid) {
        n[name] = value;
        updated = true;
      }
    });
    if (updated) {
      this.handleChangeStoreUpdate(this.state.actionItems?.length ? clone(this.state.actionItems) : null, 'actionItems');
      this.setState({ actionItems: this.state.actionItems.slice() });
    }
  }

  handleRemoveItem = (uid: string) => {
    if (!uid) {
      return;
    }
    const item = this.getActionItemsById(uid);

    if (item?.file !== undefined && item.file?.uid) {
      this.incidentStore.store.setIncidentFileRemove(item.file.uid, { cache: item.file.cached });
    }

    let updated = false;
    this.state.actionItems.forEach((el, inx) => {
      if (el.uid === item?.uid) {
        el.file = null as any;
        el.uploaded = false;
        this.state.actionItems.splice(inx, 1);
        updated = true;
      }
    });

    if (updated) {
      this.setState({ actionItems: this.state.actionItems.slice(), actionUid: null });
      this.handleChangeStoreUpdate(this.state.actionItems?.length ? clone(this.state.actionItems) : null, 'actionItems');
    } else {
      this.setState({ actionUid: null });
    }
  };

  onFileUpload = (e: any, item: IncidentActionItemSchemaAlt, inx: number) => {
    const file: FileAlt = e.target.files[0];
    if (!validFileSize(file, 5)) {
      this.incidentStore.store.notify.onStatusChange('file-too-large');
      this.incidentStore.store.resetStatus('incidents/cache/file/remove');
      return;
    }
    if (file !== undefined) {
      Object.assign(file, {
        actionUid: item?.uid,
      });

      this.setState({
        fileSelectUpload: {
          file: file,
          state: 'loading',
        },
      });
    }

    const formData: FormData = new FormData();
    formData.append('file', file as any);

    const body: BodyUploadIncidentFile = {
      file: formData,
    };

    this.incidentStore.store.setIncidentFileUpload(body, file.actionUid);
  };

  render() {
    return (
      <>
        <Box className="border border-solid border-gray-300 rounded-3xl bg-white">
          <Box className="p-5">
            <TableContainer>
              <Table aria-labelledby="tableTitle" size="small" className="inlineTable mb-5">
                <EnhancedTableHead headCells={headCellsIncidentsCorrectiveAction} />
                <TableBody>
                  {this.state?.actionItems.map((row, index: number) => (
                    <TableRow key={row.uid + index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <TextField
                          key={row.uid + index}
                          onBlur={(e) => {
                            this.updateItemById(row, 'action', e.target.value);
                          }}
                          className="w-full"
                          id="outlined-basic"
                          defaultValue={row.action}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          key={row.uid + index}
                          onBlur={(e) => {
                            this.updateItemById(row, 'party', e.target.value);
                          }}
                          className="w-full"
                          id="outlined-basic"
                          defaultValue={row.party}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          key={row.uid + index}
                          onBlur={(e) => {
                            this.updateItemById(row, 'responsibility', e.target.value);
                          }}
                          className="w-full"
                          id="outlined-basic"
                          defaultValue={row.responsibility}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableMaskedInput
                            views={['year', 'month', 'day']}
                            inputFormat="dd MMM yyyy"
                            value={row.date || this.state.calendarDefaultValue}
                            onChange={(newValue) => {
                              this.setState({
                                calendarDefaultValue: newValue,
                              });
                              this.updateItemById(row, 'date', newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  readOnly: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                                className="calenderIcon w-full"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell>
                        <Box className="flex ">
                          {row.uploaded ? (
                            <>
                              <Button target={`_blank`} href={exportLinkV2(row.file?.url)} className="p-0 min-w-0 h-auto text-black underline" variant="text">
                                View
                              </Button>
                              <Typography className="px-2">|</Typography>
                              <Button
                                onClick={(e) => {
                                  this.onRemoveFile(e, row, index);
                                }}
                                className="p-0 min-w-0 h-auto underline"
                                color="error"
                                variant="text"
                              >
                                Remove
                              </Button>
                            </>
                          ) : (
                            <Button
                              disabled={this.state?.fileSelectUpload?.state === 'loading'}
                              variant="text"
                              size="small"
                              className="px-0 underline"
                              component="label"
                            >
                              <input
                                hidden
                                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                multiple
                                type="file"
                                onChange={(e) => {
                                  this.onFileUpload(e, row, index);
                                }}
                              />
                              Upload
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box className="flex justify-end">
                          <IconButton
                            color="error"
                            aria-label="Delete data"
                            className="mx-2"
                            onClick={() => {
                              this.setState({
                                actionUid: row.uid,
                              });
                            }}
                          >
                            <Icon icon="trash" color="#F14242" viewBox="0 0 448 512" size={18} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Button
                        onClick={(e) => {
                          // add empty row on action
                          const newRow: IncidentActionItemSchemaAlt = {
                            // uid is generated by the server, we provide local
                            uid: v4(),
                            action: null,
                            party: null,
                            responsibility: null,
                            date: new Date(),
                            file: null as any,
                          } as any;

                          this.setState({
                            actionItems: [].concat(this.state.actionItems as any, newRow as any).filter((n) => !!n),
                          });
                        }}
                        variant="outlined"
                        startIcon={<Icon icon="plus" color="#3155FF" viewBox="0 0 512 512" size={18} />}
                      >
                        Add new row
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {this.state.actionUid && (
              <DeleteCustomModal
                title={`Are you sure you would like to delete this corrective action?`}
                description={'This action will permanently delete the corrective action and cannot be undone.'}
                onAction={(uid, status) => {
                  if (status === 'cancel') {
                    this.setState({
                      actionUid: null,
                    });
                  } else {
                    this.handleRemoveItem(uid as any);
                  }
                }}
                id={this.state.actionUid}
              />
            )}
          </Box>
          {this.incidentDetailData?.reporters && (
            <Box className="w-full flex border-0 border-t border-solid border-gray-300 text-sm">
              <Box className="w-1/2 border-0 border-r border-solid border-gray-300 flex">
                <Box className="font-semibold p-4 pl-10 w-28">Report by</Box>
                <Box className="p-4">
                  {!!this.incidentDetailData.reporters?.reportBy && (
                    <>
                      <Typography>
                        {this.incidentDetailData.reporters?.reportBy?.firstName} {this.incidentDetailData.reporters?.reportBy?.lastName}
                      </Typography>
                      <Typography className="mt-1">
                        {this.incidentDetailData.reporters?.reportBy?.position} ({this.incidentDetailData.reporters?.reportBy?.buName})
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
              <Box className="w-1/2 flex">
                <Box className="font-semibold p-4 pl-10 w-28">Approve by</Box>
                <Box className="p-4">
                  {!!this.incidentDetailData.reporters?.approveBy && (
                    <>
                      <Typography>
                        {this.incidentDetailData.reporters?.approveBy?.firstName} {this.incidentDetailData.reporters?.approveBy?.lastName}
                      </Typography>
                      <Typography className="mt-1">
                        {this.incidentDetailData.reporters?.approveBy?.position} ({this.incidentDetailData.reporters?.approveBy?.buName})
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  }
}
