import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { GlobalContextProvider } from '@/store/index';
import { ErrorBoundary } from '@/components/core/hocs';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
// onAppLoad?: (location: Location) => { };
ReactDOM.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </GlobalContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
