/* eslint-disable no-prototype-builtins */
import { Box, Checkbox, FormControlLabel, Typography, LinearProgress, Alert } from '@mui/material';

import React, { useState, useEffect, createRef } from 'react';
import { Logger } from '@/logger/index';
import { IncidentStore } from '@/types/store/index';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  InitialReportPartOne,
  DescriptionPartTwo,
  InjuredPartThree,
  CorrectiveActionPartEight,
  IncidentCausesAssessmentPartSix,
  IncidentInvestigationPartFive,
  IncidentInvestigationTeamPartSeven,
  PropertyDamagePartFour,
  SubmitFooter,
  AccordionBase,
  AccordionSummaryBase,
} from '@/src/components';
import { withIncidentFormContext } from '@/store/index';
import {
  // IncidentFormPartNames,
  // IncidentInitialReportPartial,
  IncidentFormStoreParts,
  // IncidentCorrectiveActionPartial,
  // BusinessUnitsSchema,
  // IncidentFormDataType,
  IncidentBodyData,
  FormSubmitType,
  FormSubmitAction,
  IncidentStatusReport,
} from '@/src/types';
import { delay, formStoreFormat, objectWithProp } from '@/src/utils';
import { IncidentRequestDto } from '@/src/components/dto';
import { cloneDeep, identity, pickBy } from 'lodash';
import { AccordionDetailsBase } from './../../../accordions/index';
import { checkReadyToSaveDraftIncident } from '@/src/utils/form';
import { SERVER_API_ERROR } from '@/src/static';
import { BrowserHistory } from 'history';

interface IncidentFormProps {
  incidentStore: IncidentStore;
  pageType: 'create' | 'update';
  history: BrowserHistory | undefined;
  /** incident report by id only available from pageType:update on this component */
  updateId?: string;
  checklistProps: { activeStep: number; handleChange: (step: number, checkRequired?: string) => void };
}

/**
 *
 * @param props.pageType identifies from which route this form was loaded
 * * `pageType:update` => means we already have available data for { incidentStore.incidentDetailData} as it has been loaded from IncidentCreateUpdate via condition, loaded via /incidents/update/:uid
 * * `pageType:create` => its a new form loaded via /incidents/create
 *
 */
const IncidentForm = (props: IncidentFormProps & IncidentFormStoreParts) => {
  const now = new Date();
  const navigate = useNavigate();
  const [doRedirect, setRedirect] = useState<number>(0);
  const [skipDataPart, setSkipDataPart] = useState<'injured' | 'propertyDamage' | undefined>();
  const [formRef, setFormRef] = useState<HTMLFormElement & { submitType: FormSubmitType; submitDecision: 'OK' | 'CANCEL' }>(null as any);
  const [isReadyToSaveDraftIncident, setReadyToSaveDraftIncident] = useState<boolean>(true);
  const [mounted, setMount] = useState<number>(0);
  const scrollToTop = () => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' } as ScrollToOptions);
  const partRefInitial = [1, 2, 3, 4, 5, 6, 7, 8].map((_part) => createRef<HTMLDivElement>());
  const {
    updateId,
    pageType,
    formStore,
    incidentStore: { store: incidentStore },
    checklistProps: { activeStep, handleChange },
  } = props;

  useEffect(() => {
    if (!mounted) {
      incidentStore.setIncidentList({ pageNumber: 0, itemPerPage: 10, month: now.getMonth() + 1, year: now.getFullYear(), status: [1, 2, 3] });
      setMount(1);
    }
    if (mounted) {
      if (pageType === 'update') {
        // populate the form store from report api
        // 1. dto
        // 2. transform to correct form store format
        // 3. update form store main state so it can load the from with default store values
        const incidentData = new IncidentRequestDto(incidentStore.incidentDetailData?.data?.data as any);
        Logger(['[IncidentForm][update][incidentData]', incidentData]);

        const formStoreTransformedData = formStoreFormat(incidentData);
        Logger(['[IncidentForm][update][incidentData][formStoreFormat]', formStoreTransformedData]);
        if (formStoreTransformedData) {
          formStore.setFormParts(formStoreTransformedData as any, 'updated'); // individual form part states will also be set to updated
        } else {
          Logger(['[IncidentForm][update][incidentData][formStoreFormat]', ' data not transformed correctly'], 'error');
        }

        formStore.setMainState('updated'); // main form state

        // NOTE: to check if get incidentStore.incidentListData is success or not
        if (incidentStore.incidentListData.state === 'ready') {
          Logger(['[IncidentForm][incidentListData] success : Ready to update incidentListData after submit', incidentStore.incidentListData], 'notice');
        }
        if (incidentStore.incidentListData.state === 'error') {
          Logger(['[IncidentForm][incidentListData] error : incidentListData error, cannot update after submit', incidentStore.incidentListData], 'error');
        }
      }
    }
  }, [mounted, setMount]);

  useEffect(() => {
    // NOTE: set expansion speed 300, then scroll after that, so 350
    delay(350).then(() => {
      if (activeStep !== -1) partRefInitial[activeStep].current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      else scrollToTop();
    });
  }, [activeStep]);

  useEffect(() => {
    // NOTE for data updated/created
    if (incidentStore.incidentCreateUpdateData.state === 'ready') {
      Logger(['[IncidentForm][incidentCreateUpdateData]', incidentStore.incidentCreateUpdateData, formRef?.submitType]);
      if (formRef?.submitType === 'submit-draft' || formRef?.submitType === 'submit-update') {
        if (!updateId) incidentStore.notify.onStatusChange('created');
        else incidentStore.notify.onStatusChange('updated');
      }

      // submit from no draft
      if (formRef?.submitType === 'submit-publish') {
        incidentStore.notify.onStatusChange('report-submit');
      }

      if (['submit-approve', 'submit-reject'].indexOf(formRef?.submitType) !== -1 && updateId) {
        incidentStore.notify.onStatusChange('submit');
      }

      if (formRef?.submitType) {
        formStore.setFormActionState(formRef.submitType as any);
        formRef.submitType = null as any;
      }
      incidentStore.resetStatus('incidents/create-update');
    }

    if (incidentStore.incidentCreateUpdateData.state === 'error') {
      Logger(['[IncidentForm][incidentCreateUpdateData]', incidentStore.incidentCreateUpdateData, formRef?.submitType], 'error');

      if (formRef?.submitType === 'submit-draft' || formRef?.submitType === 'submit-update') {
        if (!updateId) incidentStore.notify.onStatusChange('create-error');
        else incidentStore.notify.onStatusChange('update-error');
      }

      if (formRef?.submitType === 'submit-publish' && !updateId) {
        incidentStore.notify.onStatusChange('create-error');
      }

      if (['submit-publish', 'submit-approve', 'submit-reject'].indexOf(formRef?.submitType) !== -1 && updateId) {
        incidentStore.notify.onStatusChange('update-error');
      }

      incidentStore.resetStatus('incidents/create-update');
      if (formRef?.submitType) {
        formRef.submitType = null as any;
      }

      formStore.setMainState('updated'); // main form state
      formStore.setFormActionState('initial');
    }
  }, [incidentStore, setFormRef, formRef]);

  // NOTE  RESET FORM DATA WHEN UNMOUNTING!
  useEffect(() => {
    return () => {
      formStore.setFormActionState('initial');
      formStore.setResetFormStore();
      formStore.setMainState('pristine');
      incidentStore.resetStatus('incidents/create-update');
      incidentStore.resetStatus('incidents/report/parts');
    };
  }, []);

  Logger(['[IncidentForm][formStore]', formStore.store.formData]);
  Logger(['[IncidentForm][formStore][actionState]', formStore.actionState]);

  /**
   * Submit data process, repeated logic
   */
  const submitProcess = (incidentBodyData: IncidentBodyData, status: IncidentStatusReport) => {
    if (checkReadyToSaveDraftIncident(formStore.store.formData)) {
      setReadyToSaveDraftIncident(true);
      incidentBodyData.status = status;

      //  approvals?: IncidentReportApprovalSchema[];
      // const revs: ReviewerSchema = {
      //   approvals: [{ employeeId: currentUser?.userProfile?.employeeId, notes: (e.target.value || null) as any } as any],
      // };
      // formStore.setFormItem('reviewers', revs as any);

      incidentStore.setIncidentCreateUpdate(incidentBodyData, undefined, (ok) => {
        if (ok) {
          // NOTE: i see that this 2 lines already trick again when unmount
          // NOTE: To ask Micheal : i think we don't need this done(ok)=>{} function anymore, it's already handle in unmount component
          // formStore.setResetFormStore();
          // formStore.setMainState('pristine');
        }
      });
    } else {
      setReadyToSaveDraftIncident(false);
      handleChange(0, 'openRequiredField');
      delay(350).then(() => {
        scrollToTop();
      });

      Logger(['[SubmitFooter][formStore][ NOT READY TO SAVE DRAFT ]: missing some required fields', formStore.store.formData], 'warn');
    }
    formStore.setMainState('loading');
  };

  const handleFormSubmitClick = (e: HTMLFormElement & { submitType: FormSubmitType; submitDecision: 'OK' | 'CANCEL' }): any => {
    const n: FormSubmitAction = {
      decision: formRef.submitDecision,
      form: formRef.submitType,
    };

    // formRef.submitType = null as any;
    // formRef.submitDecision = null as any;

    // ATTENTION  for any type of submit (submitType) from edit page (pageType==update) we need to supply {id} to request/body so that  server knows it is an update to an existing record, otherwise it will try to create new again!
    const data = objectWithProp(formStore.store.formData);
    if (pageType === 'update') {
      data.id = updateId;
    }
    const _incidentBodyData = new IncidentRequestDto(data as any);
    const incidentBodyDataCopy = cloneDeep(_incidentBodyData);
    // remove nullable values
    const incidentBodyData: IncidentBodyData = pickBy(incidentBodyDataCopy, identity) as any;
    Logger(['[IncidentForm][FormSubmitAction][transformData]', incidentBodyData]);
    Logger(['[IncidentForm][FormSubmitAction][transformData]', incidentBodyData]);

    if (n.form === 'submit-draft') {
      if (n.decision === 'OK') {
        submitProcess(incidentBodyData, 1);
      }

      // reset from store redirect to incidents page
      if (n.decision === 'CANCEL') {
        setRedirect(1);
        // NOTE: i see that this 2 lines already trick again when unmount
        // incidentStore.resetStatus('incidents/create-update');
        // formStore.setResetFormStore();

        navigate('/incidents');

        return;
      }
    }

    if (n.form === 'submit-publish') {
      if (n.decision === 'OK') {
        // if success redirect to incidents page
        submitProcess(incidentBodyData, 2);
      }

      if (n.decision === 'CANCEL') {
        Logger(['[IncidentForm][FormSubmitAction][SUBMIT][CANCEL]'], 'warn');
      }
    }

    // approve
    if (n.form === 'submit-approve') {
      if (n.decision === 'OK') {
        // if success redirect to incidents page

        submitProcess(incidentBodyData, 3);
      }

      if (n.decision === 'CANCEL') {
        Logger(['[IncidentForm][FormSubmitAction][APPROVE][CANCEL]'], 'warn');
      }
    }

    if (n.form === 'submit-update') {
      if (n.decision === 'OK') {
        // NOTE super admin can update without changing status

        submitProcess(incidentBodyData, 2);
        Logger(['[IncidentForm][FormSubmitAction][SUPER_ADMIN][UPDATE]'], 'notice');
      }

      // if (n.decision === 'CANCEL') {
      //   Logger(['[IncidentForm][FormSubmitAction][APPROVE][CANCEL]'], 'warn');
      // }
    }

    if (n.form === 'submit-reject') {
      if (n.decision === 'OK') {
        // if success redirect to incidents page
        // NOTE reject to revert back to draft status
        submitProcess(incidentBodyData, 1);
      }

      if (n.decision === 'CANCEL') {
        Logger(['[IncidentForm][FormSubmitAction][REJECT][CANCEL]'], 'warn');
        // cta ?
      }
    }

    formStore.setFormActionState('initial');

    return false; // to avoid reload
  };

  const handleCheckNoData = (part: 'injured' | 'propertyDamage', isCheckValue: boolean) => {
    if (isCheckValue) {
      setSkipDataPart(part);
      formStore.setFormParts({ [part]: { data: { noDataSkip: true }, state: 'reset' } });
    } else {
      setSkipDataPart(undefined);
      formStore.setFormParts({ [part]: { data: null, state: 'reset' } });
    }
  };

  useEffect(() => {
    if (formStore.store.formData.injured.data && formStore.store.formData.injured.data.noDataSkip) {
      setSkipDataPart('injured');
    }
    if (formStore.store.formData.propertyDamage.data && formStore.store.formData.propertyDamage.data.noDataSkip) {
      setSkipDataPart('propertyDamage');
    }
  }, [formStore.store.formData.injured, formStore.store.formData.propertyDamage]);

  // all form executions happen from here
  useEffect(() => {
    if (formStore.ctaState === 'CTA' && ['submit-approve', 'submit-reject'].indexOf(formRef?.submitType as any) !== -1) {
      handleFormSubmitClick(formRef);
      formStore.setCta('initial');
    }
  }, [formStore, formRef, handleFormSubmitClick]);

  // reset is a bit operation, it goes thru each form part to reset it!
  if (doRedirect) {
    return <LinearProgress />;
  }

  // once form is submitted we redirect to incidents page
  if (
    ['submit-publish', 'submit-draft', 'submit-approve', 'submit-update', 'submit-reject'].indexOf(formStore.actionState as any) !== -1 &&
    incidentStore.incidentCreateUpdateData.state !== 'error'
  ) {
    return <Navigate to={`/incidents`} state={{ fromPageType: pageType }} />;
  }

  /**
   * ATTENTION
   * incidentStore.incidentDetailData is already available when coming from route /incidents/update and when pageType=update
   */

  const condition: 'ready' | 'loading' | 'error' = [
    pageType === 'create' ? 'ready' : null,
    // ATTENTION  if we are on update route we need to wait for the form store to be set so then we can use the textFieldProps > defaulValue
    // formStore.store.state === loading indicated that the form has been submitted and we have to disable the buttons
    pageType === 'update' && ['loading', 'updated'].indexOf(formStore.store.state) === -1 ? 'loading' : null,
    pageType === 'update' && ['loading', 'updated'].indexOf(formStore.store.state) !== -1 ? 'ready' : null,
  ].filter((n) => !!n)[0] as any;

  switch (condition) {
    case 'ready': {
      return (
        <form
          ref={(ref: any) => {
            setFormRef(ref);
          }}
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
          onInvalid={(e) => {}}
        >
          <AccordionBase expanded={activeStep === 0} onChange={() => handleChange(0)}>
            <AccordionSummaryBase aria-controls="panel1d-content">
              <div ref={partRefInitial[0]} />
              <Typography variant="h2" color="primary" className="ml-4">
                Initial report
              </Typography>
            </AccordionSummaryBase>
            <AccordionDetailsBase className="px-0">
              <InitialReportPartOne formStore={formStore} incidentStore={{ store: incidentStore }} isReadyToSaveDraftIncident={isReadyToSaveDraftIncident} />
            </AccordionDetailsBase>
          </AccordionBase>
          <AccordionBase expanded={activeStep === 1} onChange={() => handleChange(1)}>
            <AccordionSummaryBase aria-controls="panel1d-content">
              <div ref={partRefInitial[1]} />
              <Typography variant="h2" color="primary" className="ml-4">
                Description
              </Typography>
            </AccordionSummaryBase>
            <AccordionDetailsBase className="px-0">
              <DescriptionPartTwo formStore={formStore} incidentStore={{ store: incidentStore }}></DescriptionPartTwo>
            </AccordionDetailsBase>
          </AccordionBase>
          <Box className=" relative mb-7">
            <FormControlLabel
              className=" absolute top-6 right-3 z-10"
              control={
                <Checkbox
                  checked={skipDataPart === 'injured'}
                  onChange={(e) => handleCheckNoData('injured', e.target.checked)}
                  disabled={skipDataPart === 'propertyDamage'}
                />
              }
              label="No data. Skip this part"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
            />
            <AccordionBase expanded={activeStep === 2} onChange={() => handleChange(2)}>
              <AccordionSummaryBase aria-controls="panel1d-content">
                <div ref={partRefInitial[2]} />
                <Typography variant="h2" color="primary" className="ml-4">
                  Injured/Illness person data
                </Typography>
              </AccordionSummaryBase>
              <AccordionDetailsBase className="px-0">
                <InjuredPartThree formStore={formStore} incidentStore={{ store: incidentStore }} disabledInput={skipDataPart === 'injured'} />
              </AccordionDetailsBase>
            </AccordionBase>
          </Box>
          <Box className=" relative mb-7">
            <FormControlLabel
              className=" absolute top-6 right-3 z-10"
              control={
                <Checkbox
                  checked={skipDataPart === 'propertyDamage'}
                  onChange={(e) => handleCheckNoData('propertyDamage', e.target.checked)}
                  disabled={skipDataPart === 'injured'}
                />
              }
              label="No data. Skip this part"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
            />
            <AccordionBase expanded={activeStep === 3} onChange={() => handleChange(3)}>
              <AccordionSummaryBase aria-controls="panel1d-content">
                <div ref={partRefInitial[3]} />
                <Typography variant="h2" color="primary" className="ml-4">
                  Property damage
                </Typography>
              </AccordionSummaryBase>
              <AccordionDetailsBase className="px-0">
                <PropertyDamagePartFour formStore={formStore} disabledInput={skipDataPart === 'propertyDamage'} />
              </AccordionDetailsBase>
            </AccordionBase>
          </Box>
          <AccordionBase expanded={activeStep === 4} onChange={() => handleChange(4)}>
            <AccordionSummaryBase aria-controls="panel1d-content">
              <div ref={partRefInitial[4]} />
              <Typography variant="h2" color="primary" className="ml-4">
                Incident investigation
              </Typography>
            </AccordionSummaryBase>
            <AccordionDetailsBase className="px-0">
              <IncidentInvestigationPartFive formStore={formStore} incidentStore={{ store: incidentStore }} />
            </AccordionDetailsBase>
          </AccordionBase>
          <AccordionBase expanded={activeStep === 5} onChange={() => handleChange(5)}>
            <AccordionSummaryBase aria-controls="panel1d-content">
              <div ref={partRefInitial[5]} />
              <Typography variant="h2" color="primary" className="ml-4">
                Incident causes assessment
              </Typography>
              <Typography className="ml-auto text-gray-400 text-sm">Please tick as many as apply</Typography>
            </AccordionSummaryBase>
            <AccordionDetailsBase className="px-0">
              <IncidentCausesAssessmentPartSix formStore={formStore} incidentStore={{ store: incidentStore }} />
            </AccordionDetailsBase>
          </AccordionBase>
          <AccordionBase expanded={activeStep === 6} onChange={() => handleChange(6)}>
            <AccordionSummaryBase aria-controls="panel1d-content">
              <div ref={partRefInitial[6]} />
              <Typography variant="h2" color="primary" className="ml-4">
                Incident investigation team
              </Typography>
            </AccordionSummaryBase>
            <AccordionDetailsBase className="px-0">
              <IncidentInvestigationTeamPartSeven formStore={formStore}></IncidentInvestigationTeamPartSeven>
            </AccordionDetailsBase>
          </AccordionBase>
          <AccordionBase expanded={activeStep === 7} onChange={() => handleChange(7)}>
            <AccordionSummaryBase aria-controls="panel1d-content">
              <div ref={partRefInitial[7]} />
              <Typography variant="h2" color="primary" className="ml-4">
                Corrective action
              </Typography>
            </AccordionSummaryBase>
            <AccordionDetailsBase className="px-0">
              <CorrectiveActionPartEight pageType={pageType} formStore={formStore} incidentStore={{ store: incidentStore }} />
            </AccordionDetailsBase>
          </AccordionBase>

          <SubmitFooter
            pageType={pageType}
            incidentStore={{ store: incidentStore }}
            formStore={formStore}
            setFormRef={(ref, decision) => {
              // BUGFIX >  dont allow to update formRef on cancel not from draft
              if (ref !== 'submit-draft' && decision === 'CANCEL') {
                return;
              }

              formRef.submitType = ref;
              if (decision) formRef.submitDecision = decision;
              setFormRef({ ...formRef });

              // NOTE we have moved these 3 calls to ctaState action to fix bug for latest data update
              // issue caused by update fromStore from modal
              if (['submit-approve', 'submit-reject'].indexOf(ref) === -1) {
                handleFormSubmitClick(formRef);
              }
            }}
            checkReadyToSave={() => {
              if (checkReadyToSaveDraftIncident(formStore.store.formData)) {
                return true;
              } else {
                formStore.setMainState('loading'); // set main form state to loading to disable action buttons.
                setReadyToSaveDraftIncident(false);
                handleChange(0, 'openRequiredField');
                delay(350).then(() => {
                  scrollToTop();
                });
                return false;
              }
            }}
          />
        </form>
      );
    }
    case 'loading': {
      return <LinearProgress />;
    }
    case 'error': {
      return <Alert severity="error">{SERVER_API_ERROR}</Alert>;
    }

    default: {
      return <>No condition matched</>;
    }
  }
};

export default withIncidentFormContext(IncidentForm);
