import { createContext } from 'react';
import { initialDataWrapper } from '../../common';
import { IncidentStore, NotificationStatus, StoreStateV3 } from '@/types/store';
import {
  IncidentBodyData,
  QueryIncidentList,
  QueryIncidentTypes,
  IncidentStatusType,
  BodyUploadIncidentFile,
  EmployeeSearchQuery,
  QueryRemoveIncidentImage,
  EmployeeByQuery,
  BodyIncidentReportApproval,
  BodyIncidentReportRemarks,
  QueryIncidentsCharts,
  IncidentsChartSchema,
  GetIncidentList,
} from '@/src/types';

// import { incidentDetailMockData } from '@/src/static/incidents/store/mockupData';

export const IncidentContext: React.Context<IncidentStore> = createContext<IncidentStore>({
  store: {
    notify: {
      /**
       * call to action when status has changed
       */
      onStatusChange: (type: NotificationStatus, message?: string) => {},
      notificationStatus: 'initial',
    },

    resetStatus: (apiName: string) => {},
    resetState: (apiName: string, state: StoreStateV3) => {},
    /**
     * By calling we force to reload context provider, so it can provide latest data
     */
    // setRenderUpdate: (index?: number) => {},
    // renderUpdate: 0,
    setIncidentList: (params: QueryIncidentList, type?: 'hidden_load_after_deleted' | 'update', done?: (ok: boolean, resp: GetIncidentList) => void) => {},
    incidentListData: {
      ...initialDataWrapper,
    },
    setIncidentBusinessUnitsFilter: () => {},
    incidentBusinessUnitsFilter: {
      ...initialDataWrapper,
    },
    setIncidentCreateUpdate: (body: IncidentBodyData, statusType?: IncidentStatusType, done?: (ok: boolean) => void) => {},
    incidentCreateUpdateData: {
      ...initialDataWrapper,
    },
    setIncidentQueryTypes: (params: QueryIncidentTypes) => {},
    incidentQueryTypesData: {
      ...initialDataWrapper,
    },

    setIncidentDetail: (id: string, action?: 'update', ref?: string) => {},
    incidentDetailData: {
      // error: undefined,
      // data: incidentDetailMockData,
      // state: 'initial',
      ...initialDataWrapper,
    },

    setIncidentDetailDelete: (id: string) => {},
    incidentDetailDeleteData: {
      ...initialDataWrapper,
    },

    setIncidentReportApproval: (incidentId: string, body: BodyIncidentReportApproval) => {},
    incidentReportApprovalData: {
      ...initialDataWrapper,
    },

    setIncidentReportRemarks: (incidentId: string, body: BodyIncidentReportRemarks) => {},
    incidentReportRemarksData: {
      ...initialDataWrapper,
    },

    setIncidentFileUpload: (body: BodyUploadIncidentFile, ref: string) => {},
    incidentFileUploadData: {
      ...initialDataWrapper,
    },
    setIncidentFileRemove: (id: string, params?: QueryRemoveIncidentImage) => {},
    incidentFileRemoveData: {
      ...initialDataWrapper,
    },

    // NOTE: this employees data is from API GET/employees/search, use in createIncidentForm part2-description
    setIncidentEmployeesSearch: (params: EmployeeSearchQuery) => {},
    incidentEmployeesSearchData: {
      ...initialDataWrapper,
    },

    // NOTE: this employees data is from API GET/employees/query
    setIncidentEmployeesQuery: (params: EmployeeByQuery) => {},
    incidentEmployeesQueryData: {
      ...initialDataWrapper,
    },

    // NOTE: this progressbar data is from API GET/incidents/progress/{incidentId}
    setIncidentProgress: (id: string) => {},
    incidentProgressData: {
      ...initialDataWrapper,
    },
    setIncidentChart: (params?: QueryIncidentsCharts, action?: 'update' | 'new', done?: (ok: boolean, data?: IncidentsChartSchema) => void) => {},

    incidentChartData: {
      ...initialDataWrapper,
    },
  },
} as IncidentStore);
