import { useState, useEffect } from 'react';
import { Icon } from '../../icons';
import { AzureAuthProviderProps, GetLegalHistoryList, LegalHistorySmallSchema, LegalHistoryQuery, PropDefaults, LegalHistorySchema } from '@/src/types';
import { LegalStore } from '@/types/store/index.d';
import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Table,
  TableContainer,
  Button,
  Typography,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  CircularProgress,
} from '@mui/material';
import { printDate } from '@/src/utils';
import { EnhancedTablePagination } from '@/src/components';

interface Props extends PropDefaults {
  [name: string]: any;
  azure: AzureAuthProviderProps;
  detailId: string;
  year: string;
  canEvaluate: boolean;
}

export function TableHistoryLegals(props: Props & LegalStore) {
  const { store: legalStore, detailId, year } = props;
  const { legalHistoryListData, legalHistoryData } = legalStore;
  const historyData = legalHistoryListData?.data?.data || ([] as GetLegalHistoryList[]);
  const historyDetail = legalHistoryData?.data?.data as LegalHistorySchema;

  const pagination: LegalHistoryQuery =
    legalHistoryListData?.state === 'ready'
      ? {
          pageNumber: legalHistoryListData?.data?.pagination?.pageNumber,
          itemPerPage: legalHistoryListData?.data?.pagination?.itemPerPage,
          totalItems: legalHistoryListData?.data?.pagination?.totalItems,
          year: year,
        }
      : {
          pageNumber: 0,
          itemPerPage: 10,
          totalItems: 0,
          year: year,
        };
  const onCallBackPagination = (type: 'setRowsPerPage' | 'setPage' | 'setNewPage', payload: any) => {
    if (payload !== undefined) {
      const params: LegalHistoryQuery = {
        pageNumber: payload.newPage,
        itemPerPage: payload.rowsPerPage,
        year: year,
      };
      legalStore?.setLegalHistoryList(detailId, params);
    }
  };

  const [logId, setLogId] = useState<string>('');
  const onLogToggle = (e: any = null, id: string = '') => {
    if (e) e.preventDefault();
    if (id && id !== 'backdropClick') {
      setLogId(id);
      legalStore?.resetStatus('legal/history');
      legalStore?.setLegalHistory(id);
    } else {
      setLogId('');
    }
  };

  useEffect(() => {
    legalStore?.resetStatus('legal/history-list');
    if (detailId && year) {
      legalStore?.setLegalHistoryList(detailId, pagination);
    }
    return () => {
      legalStore?.resetStatus('legal/history-list');
    };
  }, [detailId, year]);

  return !detailId || !year || !historyData.length ? (
    <></>
  ) : (
    <>
      <Box className="w-full lg:max-w[calc(100% - 400px)] mt-8">
        <Typography variant="h2" color="primary">
          <Icon icon="squareFull" color="#3155FF" size={10} className="mr-5" />
          Legal history
        </Typography>
        <TableContainer className="mt-4">
          <Table aria-labelledby="tableTitle" size="small" className="legal-page-table">
            <TableHead>
              <TableCell
                className="align-top"
                padding="normal"
                sx={{
                  fontWeight: 'bold',
                  width: 400,
                  whiteSpace: 'nowrap',
                }}
              >
                Edit by
              </TableCell>
              <TableCell
                className="align-top"
                padding="normal"
                sx={{
                  fontWeight: 'bold',
                  width: 400,
                  whiteSpace: 'nowrap',
                }}
              >
                Edit date
              </TableCell>
              <TableCell></TableCell>
            </TableHead>
            <TableBody>
              {historyData.map((row: LegalHistorySmallSchema, index: number) => (
                <TableRow key={index} className="align-top" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className="py-4 h-auto">
                    {row.employeeFirstname} {row.employeeLastname}
                  </TableCell>
                  <TableCell className="py-4 h-auto">{row.updated ? printDate(row.updated, 'DD MMM YYYY HH:mm:ss') : '-'}</TableCell>
                  <TableCell align="right" className="h-auto td-last">
                    <Box className="flex justify-end">
                      <IconButton onClick={(e) => onLogToggle(e, row.logId)} color="primary" aria-label="View details">
                        <Icon icon="eyes" color="#3155FF" viewBox="0 0 576 512" size={20} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <EnhancedTablePagination
          totalItems={pagination.totalItems ? pagination.totalItems : 0}
          page={pagination.pageNumber}
          rowsPerPage={pagination.itemPerPage}
          onCallBackPagination={onCallBackPagination}
          isSoftReload={false}
        />
      </Box>

      <Dialog open={!!logId} onClose={onLogToggle} fullWidth={true} maxWidth={'md'} scroll={'body'}>
        <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
          <Typography className="font-medium text-xl">{historyDetail ? `${historyDetail.name} (${historyDetail.code})` : 'Legal history'}</Typography>
        </DialogTitle>
        <DialogContent className="p-0" sx={{ overflow: 'unset' }}>
          {!historyDetail ? (
            <CircularProgress className="m-6" />
          ) : (
            <Box className="py-6">
              <Typography variant="h6" color="primary">
                Updated by :{' '}
                <span style={{ color: '#000000', fontWeight: 300 }}>
                  {historyDetail?.lastUpdatedBy ? (
                    <>
                      {historyDetail.lastUpdatedBy?.firstName} {historyDetail.lastUpdatedBy?.lastName}
                    </>
                  ) : (
                    <>
                      {historyDetail.lastCreatedBy?.firstName} {historyDetail.lastCreatedBy?.lastName}
                    </>
                  )}
                </span>
              </Typography>
              <Typography variant="h6" color="primary" className="mt-1">
                Updated date :{' '}
                <span style={{ color: '#000000', fontWeight: 300 }}>
                  {printDate(historyDetail?.updated ? historyDetail?.updated : historyDetail?.created, 'DD MMM YYYY HH:mm:ss')}
                </span>
              </Typography>
              <Box className="mt-8">
                <Typography variant="h2" color="primary">
                  <Icon icon="squareFull" color="#3155FF" size={10} className="mr-5" />
                  Summary
                </Typography>
              </Box>
              <Box className="border border-solid border-gray-300 p-6 mt-5">
                <Typography className="leading-7" sx={{ whiteSpace: 'pre-line' }}>
                  {historyDetail?.summary}
                </Typography>
              </Box>
              <Box className="mt-8">
                <Typography variant="h2" color="primary">
                  <Icon icon="squareFull" color="#3155FF" size={10} className="mr-5" />
                  Required actions
                </Typography>
                <Box className="border border-solid border-gray-300 p-6 mt-5">
                  <Typography className="leading-7" sx={{ whiteSpace: 'pre-line' }}>
                    {historyDetail?.actions}
                  </Typography>
                </Box>
              </Box>
              {(historyDetail?.status === 3 || historyDetail?.status === 5) && (
                <Box className="border border-solid border-[#F2F2F2] rounded-3xl mt-8 bg-[#F5F7FF] shadow p-6">
                  <Typography variant="h2" color="primary">
                    Review legislation
                  </Typography>
                  <Box className="bg-white p-6  rounded-3xl border border-solid border-gray-300 mt-4">
                    <Box className="flex">
                      <img src={require('@/assets/icons/Search.png')} alt="Occupational" width={28} height={27} className="mr-3" />
                      <Typography variant="h5">Investigators</Typography>
                    </Box>
                    <Box className="mt-4">
                      {historyDetail && historyDetail.evaluation && historyDetail.evaluation.teamMembers && historyDetail.evaluation.teamMembers.length ? (
                        <Box>
                          {historyDetail.evaluation.teamMembers
                            .sort((a, b) => a.firstName.localeCompare(b.firstName))
                            .map((d, i) => (
                              <Chip
                                key={`tag_${i}`}
                                className="delIcon mt-2 mr-2 rounded-md bg-[#E6EBFF]"
                                label={`${d.firstName} ${d.lastName} - ${d.position ? d.position : ''} (${d.buName})`}
                              />
                            ))}
                        </Box>
                      ) : (
                        <Typography variant="h6" fontWeight={400}>
                          No assigned investigator
                        </Typography>
                      )}
                    </Box>
                    <Box className="mt-10">
                      <Box className="flex">
                        <img src={require('@/assets/icons/list.png')} alt="Occupational" width={28} height={27} className="mr-3" />
                        <Typography variant="h5">Action items</Typography>
                      </Box>
                      <Box className="mt-4">
                        {historyDetail.evaluation && historyDetail.evaluation.actionItems && historyDetail.evaluation.actionItems.length ? (
                          <TableContainer>
                            <Table aria-labelledby="tableTitle" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ fontWeight: 'bold' }}>No.</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold' }}>Action party</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold' }}>Due date</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {historyDetail.evaluation.actionItems?.map((row, index: number) => (
                                  <TableRow key={`${row.uid}`}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{row.action}</TableCell>
                                    <TableCell>{printDate(row.date)}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Typography variant="h6" fontWeight={400}>
                            No action items
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box className="mt-10">
                      <Box className="flex">
                        <img src={require('@/assets/icons/message-lines.png')} alt="Occupational" width={28} height={27} className="mr-3" />
                        <Typography variant="h5">Report</Typography>
                      </Box>
                      <Box className="mt-4">
                        <Typography className="leading-7" sx={{ whiteSpace: 'pre-line' }}>
                          {historyDetail.evaluation && historyDetail.evaluation.report ? historyDetail.evaluation.report : 'No report'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          <DialogActions className="px-8 pt-7 pb-0 justify-start border-0 border-t border-solid border-gray-300" sx={{ margin: '0 -30px' }}>
            <Button onClick={onLogToggle} type="button" className="w-28" variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
