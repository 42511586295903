import { createContext } from 'react';
import { initialDataWrapper } from '../common';
import { StoreStateV3, ProfileStore, NotificationStatus } from '@/types/store';
import { ProfileAttendeeRequestBody, ProfileMyTrainingQuery, EmployeeByIdQuery } from '@/src/types';

export const ProfileContext: React.Context<ProfileStore> = createContext({
  store: {
    resetStatus: (apiName: string) => {},
    resetState: (apiName: string, state: StoreStateV3) => {},
    notify: {
      /**
       * call to action when status has changed
       */
      onStatusChange: (type: NotificationStatus, message?: string) => {},
      notificationStatus: 'initial',
    },
    setProfileIncidents: (employeeId: string) => {},
    profileIncidentsData: {
      ...initialDataWrapper,
    },
    setProfileTrainingList: (employeeId: string, query?: ProfileMyTrainingQuery) => {},
    profileTrainingData: {
      ...initialDataWrapper,
    },
    setProfileAttendeeRequest: (employeeId: string, attendeeId: string, body: ProfileAttendeeRequestBody) => {},
    profileAttendeeRequestData: {
      ...initialDataWrapper,
    },
    setEmployeeById: (employeeId: string, query?: EmployeeByIdQuery, done?: (ok: boolean) => void) => {},
    employeeByIdData: {
      ...initialDataWrapper,
    },
  },
} as ProfileStore);
