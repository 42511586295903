import { useState, useEffect } from 'react';
import { TableCell, TableRow } from '@mui/material';
// import { AzureAuthProviderProps, ManhoursStore, IncidentStore } from '@/src/types/store';
import { ManHourBusinessUnitTotal, ManHourCalculations } from '@/src/types';

const configCompanyColor = { ARV: 'bg-violet-100/[.3]', CAR: 'bg-cyan-100/[.3]', ROV: 'bg-amber-100/[.3]', SKL: 'bg-sky-100/[.3]', VRN: 'bg-emerald-100/[.3]' };

interface TotalManhoursProps {
  calculations: ManHourCalculations;
  buColumn: string[];
}

export function Total(
  props: TotalManhoursProps
  // & ManhoursStore
) {
  const { calculations, buColumn } = props;
  const { yearTotal, totals } = calculations;
  const [totalColumn, setTotalColumn] = useState<ManHourBusinessUnitTotal[]>([]);

  useEffect(() => {
    if (yearTotal === 0 && totals.length === 0) {
      setTotalColumn(
        buColumn.map((item) => {
          return { buCode: item, total: '-' } as ManHourBusinessUnitTotal;
        })
      );
    } else setTotalColumn(totals);
  }, [calculations]);

  return (
    <TableRow>
      <TableCell className="px-3 font-bold pl-8 border-0 pb-7 pt-3" colSpan={3}>
        Total
      </TableCell>
      {buColumn.map((d, inx) => {
        const n = totalColumn.find((k) => k.buCode === d);
        return (
          <TableCell key={`${inx}-${d}`} className={`px-3 font-bold text-right border-0 pr-5 pb-7 pt-3 ${configCompanyColor[d]}`} colSpan={2}>
            {n ? n.total.toLocaleString() : 0}
          </TableCell>
        );
      })}
      <TableCell className="p-0 w-full"></TableCell>
      <TableCell className="px-3 font-bold text-right border-0 pr-10 pb-7 pt-3">{yearTotal ? yearTotal.toLocaleString() : '0'} </TableCell>
    </TableRow>
  );
}
