import React from 'react';
import { Card, Typography } from '@mui/material';
import { IncidentStore, IncidentFormStoreParts } from '@/src/types';
// import { Logger } from '@/logger/index';
import { PartSixSectionOne, PartSixSectionThree, PartSixSectionTwo } from '@/src/components';

interface IncidentCausesAssessmentPartSixProps {
  incidentStore: IncidentStore;
}

export const IncidentCausesAssessmentPartSix: React.FC<any> = (props: IncidentCausesAssessmentPartSixProps & IncidentFormStoreParts) => {
  const {
    formStore,
    incidentStore: { store: incidentStore },
  } = props;

  // Logger(['[PropertyDamagePartFour][formStore][incidentCausesAssessment]', formStore.store.formData.incidentCausesAssessment]);
  // Logger(['[PropertyDamagePartFour][incidentStore][incidentQueryTypesData]', incidentStore.incidentQueryTypesData]);

  return (
    <>
      <Card variant="outlined" className=" rounded-2xl p-6">
        <Typography variant="h5" className="mb-6 mt-2 border-0 border-l-8 border-solid border-blue-600 pl-4" sx={{ marginLeft: '-26px;' }}>
          Causal Factors
        </Typography>

        {/* part 6 section 1 */}
        <PartSixSectionOne formStore={{ formStore }} incidentStore={{ store: incidentStore }} />

        {/* part 6 section 2 */}
        <PartSixSectionTwo formStore={{ formStore }} incidentStore={{ store: incidentStore }} />

        <hr className="border-dashed border border-gray-200 my-6" />
        <Typography variant="h5" className="mb-6 mt-8 border-0 border-l-8 border-solid border-blue-600 pl-4" sx={{ marginLeft: '-26px;' }}>
          Root Cause Categories
        </Typography>

        {/* part 6 section 3 */}
        <PartSixSectionThree formStore={{ formStore }} incidentStore={{ store: incidentStore }} />
      </Card>
    </>
  );
};
