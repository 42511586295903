/** declare only http service related requests */

import { HttpTokens, GetLegalCharts, LegalChartsQuery } from '@/src/types';
import { paramsQueryString } from '@/src/utils';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';

/**
 * @description Get 2 charts data
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const LegalChartsGet = (prefix: string = 'legal/charts', query: LegalChartsQuery, tokens: HttpTokens, hardRejection = true): Promise<GetLegalCharts> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  const queryUri = paramsQueryString(prefix, query);

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetLegalCharts>) => {
      // return Promise.reject(new Error('Ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetLegalCharts>;
};
