const data = [
  {
    employeeId: 'ARV10060',
    firstName: 'Abigai',
    middleName: null,
    lastName: 'Pruitt',
    email: 'Abigai@arv.co.th',
    buCode: 'ARV',
    roleId: 1,
  },
  {
    employeeId: 'ARV10105',
    firstName: 'Catt',
    middleName: null,
    lastName: 'xxx',
    email: 'Cat@arv.co.th',
    buCode: 'ARV',
    roleId: 1,
  },
  {
    employeeId: 'ARV10105',
    firstName: 'Cat2',
    middleName: null,
    lastName: 'Pruitt',
    email: 'Cat2@arv.co.th',
    buCode: 'ARV',
    roleId: 2,
  },
  {
    employeeId: 'ARV10105',
    firstName: 'Cat3',
    middleName: null,
    lastName: 'Pruitt',
    email: 'Cat3@arv.co.th',
    buCode: 'ARV',
    roleId: 3,
  },
];
/** @type {import('../../../types/common').MockApiData} */
exports.employeeList = {
  // what type of request: [get,put,post], mainly using get
  type: 'get',
  // api name after ./api prefix, api/example
  uniqId: 'get-employees',
  /**
   * any data: can be object, array etc, depending what you want to return
   * - data is added thru AxiosService > interceptors.response after https://httpbin.org/ request was successful
   * */
  response: {
    status: {
      code: 200,
      message: 'success',
    },
    data,
    pagination: {
      totalItems: data.length,
      pageNumber: 0,
      itemPerPage: 10,
    },
  },
};
