import { Box, Paper, Table, TableBody, TableContainer, CircularProgress } from '@mui/material';
import { useState, useEffect, FC, Fragment } from 'react';
import { TableDataNotFound, EnhancedTablePagination, SearchBoxInput } from '@/components/index';
import { headCellsAttendees } from '@/src/static';
import { EnhancedTableHead } from '../../tableHead';
import { withAttendeeContext } from '@/store/index';
import { AttendeeStore } from '@/types/store/index';
import { AttendeeSchema, TrainingStatus } from '@/src/types/schema';
import { AttendeeSegment } from './attendee.segment';
import { SnackBarAlertWithStore } from '@/components/ui/variants';
import { ActionInProgress, QueryAttendeeList } from '@/src/types';

interface AttendeeProps extends AttendeeStore {
  [name: string]: any;
  children: any;
  trainingId: string;
  trainingStatus: TrainingStatus;
  actionInProgress?: ActionInProgress;
  preTestScore: number;
  postTestScore: number;
  postTestPassScore: number;
  onChangeAttendees: (data: any) => void;
}

const Attendees: FC<AttendeeProps> = (props) => {
  const { children, store, trainingId, trainingStatus, preTestScore, postTestScore, postTestPassScore, onChangeAttendees } = props;

  const [mount, setMount] = useState(0);
  // const [itemPerPage, setItemPerPage] = useState<number>(10);
  const itemPerPage: number = 10;
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [nameSearching, setNameSearching] = useState<string>('');

  useEffect(() => {
    if (!mount) {
      if (store.attendeeListData.state !== 'ready') {
        const params: QueryAttendeeList = { pageNumber: page, itemPerPage: rowsPerPage };
        if (nameSearching) params.q = nameSearching;
        store.setAttendeeList(trainingId, params);
        store.setAllAttendeeList(trainingId);
      }
      setMount(1);
    }
  }, [mount, setMount, store]);

  const paginationState =
    store.attendeeListData.state === 'ready'
      ? {
          totalItems: store.attendeeListData.data?.pagination.totalItems,
          page: store.attendeeListData.data?.pagination.pageNumber,
          rowsPerPage: store.attendeeListData.data?.pagination.itemPerPage,
        }
      : {
          totalItems: itemPerPage * (pageNumber + 1),
          page: pageNumber,
          rowsPerPage: itemPerPage,
        };

  const onCallBackPagination = (type: 'setRowsPerPage' | 'setPage' | 'setNewPage', payload: any) => {
    if (type === 'setPage' && payload !== undefined) setPage(payload);
    if (type === 'setRowsPerPage' && payload !== undefined) setRowsPerPage(payload);
    if (type === 'setNewPage' && payload !== undefined) {
      const params: QueryAttendeeList = {
        pageNumber: payload.newPage,
        itemPerPage: payload.rowsPerPage,
      };
      if (nameSearching) params.q = nameSearching;
      store.setAttendeeList(trainingId, params);
    }
  };

  const onEnterSearch = (searchText: string) => {
    setPageNumber(0);
    setNameSearching(searchText);
    const params: QueryAttendeeList = { pageNumber: 0, itemPerPage: rowsPerPage };
    if (searchText) params.q = searchText;
    store.setAttendeeList(trainingId, params);
  };

  const onClearSearch = () => {
    if (nameSearching) {
      setPageNumber(0);
      setNameSearching('');
      const params: QueryAttendeeList = { pageNumber: 0, itemPerPage: rowsPerPage };
      store.setAttendeeList(trainingId, params);
    }
  };

  useEffect(() => {
    onChangeAttendees(store.allAttendeeListData);
  }, [store.allAttendeeListData]);

  return (
    <Paper elevation={4} className="py-4 mt-6">
      <Box className="flex flex-wrap p-6 pb-0 ">
        {children}
        <SearchBoxInput onEnterSearch={onEnterSearch} onClearSearch={onClearSearch} />
      </Box>

      <Box className="flex flex-wrap p-6 pb-0">
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead preTestScore={preTestScore} postTestScore={postTestScore} postTestPassScore={postTestPassScore} headCells={headCellsAttendees} />
            <TableBody>
              {store &&
                store.attendeeListData.state === 'ready' &&
                (store.attendeeListData?.data?.data || []).map((row: AttendeeSchema, index: number) => (
                  <Fragment key={`${index}_${row.status}`}>
                    <AttendeeSegment
                      preTestScore={preTestScore}
                      postTestScore={postTestScore}
                      trainingId={trainingId}
                      index={index}
                      data={row}
                      store={store}
                      trainingStatus={trainingStatus}
                      onChangeInput={() => {
                        store.setAllAttendeeList(trainingId);
                      }}
                    />
                  </Fragment>
                ))}
            </TableBody>

            {store.attendeeListData.state === 'ready' && store.attendeeListData?.data?.data?.length === 0 && (
              <TableDataNotFound col={10} description={'Please change search keyword'} />
            )}
          </Table>
        </TableContainer>
        {(store.attendeeListData.state === 'initial' || store.attendeeListData.state === 'loading') && (
          <Box className="flex justify-center p-6 w-[100%]">
            <CircularProgress />
          </Box>
        )}
        {/**
         * REVIEW this is incorrect delete modal there is no delete for attendees
         */}
      </Box>
      {store.attendeeListData.state !== 'error' && (
        <EnhancedTablePagination
          totalItems={paginationState.totalItems}
          page={paginationState.page}
          rowsPerPage={paginationState.rowsPerPage}
          onCallBackPagination={onCallBackPagination}
          isSoftReload={false}
        />
      )}
      <>
        <SnackBarAlertWithStore autoHideDurationAndClearStore={1500} storeName={['AttendeeStore']} store={{ store }} />
      </>
    </Paper>
  );
};
export default withAttendeeContext(Attendees);
