import { IconButton, TableBody, TableCell, TableRow, Box, Table, TableContainer } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Icon } from '../../icons';
// import { AdminStore, AzureAuthProviderProps } from '@/src/types/store';
import { IncidentStatusReport } from '@/src/types';
import { headCellsIncidents } from '@/src/static';
import { EnhancedTableHead } from '../../tableHead';

import { NavLink } from 'react-router-dom';
import { delay, printDate, sortedIncidentsList, userCanOnIncident } from '@/src/utils';
import { IncidentStore } from '@/types/store';
import { initialReport } from '@/src/static/incidents/form.data';
import { Logger } from '@/logger/index';
import { useGlobalContext } from '@/store/index';
import { TableDataError } from '../../dataError';
import { TableDataNotFound } from '../../dataNotFound';
import DeleteCustomModal from '../../variants/delete.modal';

interface EnhancedTableIncidentsProps {
  sortBy: IncidentStatusReport[];
  /**
   * override incident state with custom loader, from on filter change
   */
  overrideLoadingInProgress: boolean;
}
export function EnhancedTableIncidents(props: EnhancedTableIncidentsProps & IncidentStore) {
  const { currentUser } = useGlobalContext();
  const { store, sortBy, overrideLoadingInProgress } = props;
  const { setIncidentDetailDelete, incidentDetailDeleteData, notify, resetStatus } = store;
  const [incidentId, setIncidentId] = useState<string | null>('');
  const [mount, setMount] = useState<number>(0);
  const [title, setTitle] = useState('');

  const handleOpenDelete = (employeeId: string) => {
    setIncidentId(employeeId);
    setTitle('Are you sure you want to delete this Incident?');
  };

  useEffect(() => {
    if (!mount) {
      setMount(1);
    }
    if (mount) {
      if (incidentDetailDeleteData.state === 'ready') {
        notify.onStatusChange('deleted');
        // NOTE: cannot reset status of deleteData in store yet, because have to wait for hidden load new incidentListData after delete success
        // resetStatus('incidents/delete/report');
        Logger(['[EnhancedTableIncidents][incidentDetailDeleteData]', incidentDetailDeleteData]);
      }

      if (incidentDetailDeleteData.state === 'error') {
        notify.onStatusChange('delete-error');
        resetStatus('incidents/delete/report');
        Logger(['[EnhancedTableIncidents][incidentDetailDeleteData]', incidentDetailDeleteData], 'error');
      }
    }
  }, [incidentDetailDeleteData]);

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead headCells={headCellsIncidents} />

          {store.incidentListData.state === 'error' && <TableDataError col={8} description={`Please reload the page again.`} />}

          <TableBody>
            {sortedIncidentsList(store.incidentListData.data?.data, sortBy).map((row, index: number) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row.status === 1 && <Icon icon="circle" color="#ECB623" size={9} />}</TableCell>
                <TableCell>{row.incidentNo}</TableCell>
                <TableCell>{printDate(row.incidentDate)}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.buName + ' (' + row.buCode + ')'}</TableCell>
                <TableCell>
                  {row.damageType &&
                    row.damageType.map((item, index: number) => {
                      return (index > 0 ? ', ' : '') + initialReport.incidentDamageType?.find((i) => i.value === item)?.label;
                    })}
                </TableCell>
                <TableCell>
                  <span className={`py-1 px-2 rounded ${row.status === 1 ? 'draft' : row.status === 2 ? 'inReview' : row.status === 3 ? 'onGoing' : 'closed'}`}>
                    {row.status === 1 ? 'Draft' : row.status === 2 ? 'In review' : row.status === 3 ? 'Ongoing' : 'Closed'}
                  </span>
                </TableCell>

                <TableCell align="right">
                  <Box className="flex justify-end">
                    {userCanOnIncident(currentUser, row as any, row.status, 'can_edit') && (
                      <NavLink to={`/incidents/update/${row.id}`}>
                        <IconButton color="primary" aria-label="View details">
                          <Icon icon="penToSquare" color="#3155FF" viewBox="0 0 576 512" size={20} />
                        </IconButton>
                      </NavLink>
                    )}
                    {userCanOnIncident(currentUser, row as any, row.status, 'can_view') && (
                      <NavLink to={`/incidents/detail/${row.id}`}>
                        <IconButton color="primary" aria-label="View details">
                          <Icon icon="eyes" color="#3155FF" viewBox="0 0 576 512" size={20} />
                        </IconButton>
                      </NavLink>
                    )}

                    {/** NOTE this will work once employeeId was added to {IncidentSmallSchema} */}
                    {userCanOnIncident(currentUser, row as any, row.status, 'can_delete') && (
                      <IconButton color="error" aria-label="Delete data" onClick={() => handleOpenDelete(row.id)}>
                        <Icon icon="trash" color="#F14242" viewBox="0 0 448 512" size={18} />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {!overrideLoadingInProgress && store.incidentListData.state === 'ready' && store.incidentListData?.data?.data?.length === 0 && (
            <TableDataNotFound col={8} description={'Please change search keyword'} />
          )}
        </Table>
      </TableContainer>

      <DeleteCustomModal
        title={title}
        description={'This action will permanently delete the Incident and cannot be undone.'}
        id={incidentId}
        onAction={(id: string | null, status) => {
          if (status === 'cancel') setIncidentId(null);
          if (status === 'confirm') {
            setIncidentDetailDelete(id as any);
            delay(200).then(() => {
              setIncidentId(null);
            });
          }
        }}
      />
    </>
  );
}
