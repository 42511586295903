import { TableBody, TableCell, TableRow, Table, TableContainer } from '@mui/material';
// import { AdminStore, AzureAuthProviderProps } from '@/src/types/store';
import { headCellsActionItems } from '@/src/static';
import { EnhancedTableHead } from '../../tableHead';
import { DeleteCustomModal } from '@/src/components';
import { LorActionItemSchema } from '@/src/types';
import { printDate } from '@/src/utils';
interface EnhancedTableActionitemsProps {
  data: any;
}
export function EnhancedTableActionitems(props: EnhancedTableActionitemsProps) {
  const { data } = props;
  // const [currentid, setCurrentid] = useState('');
  const currentid: string = '';
  // const [valueDueDate, setValueDueDate] = useState<Date | null>();
  // const [confirmDeleteModalState, setConfirmDeleteModalState] = useState<'INITIAL' | 'OPEN' | 'DELETE'>('INITIAL');
  // const [titel, setTitle] = useState('');
  const titel: string = '';

  // const handleOpenDelete = (employeeId) => {
  //   setConfirmDeleteModalState('OPEN');
  //   setCurrentid(employeeId);
  //   setTitle('Are you sure you want to delete this Incident?');
  // };
  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead headCells={headCellsActionItems} />
          <TableBody>
            {data ? (
              data?.map((row: LorActionItemSchema, index: number) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.action ? row.action : '-'}</TableCell>
                  <TableCell> {row?.date ? printDate(row?.date, 'DD MMM YYYY') : '-'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <EditIncidentModal onCAllBack={onCAllBack} employee={selData} openEdit={openEdit} ready={stateData === 'ready'} /> */}
      <DeleteCustomModal
        title={titel}
        description={'This action will permanently delete the Incident and cannot be undone.'}
        id={currentid}
        onAction={(id, status) => {}}
      />
    </>
  );
}
