import { Paper, Box, Avatar, Typography, Divider } from '@mui/material';
import { EmployeeProfile, ProfileStore } from '@/src/types';
import React, { Fragment, useEffect, useState } from 'react';
import { useGlobalContext } from '@/store/index';
import { exportLinkV2, urlWithToken, windowLocationRelativeUrl } from '@/utils/index';

interface ProfileProps {}

export function MyProfile(props: ProfileProps & ProfileStore) {
  const { currentUser } = useGlobalContext();
  const userProfile = currentUser?.userProfile || {};
  const [mount, setMount] = useState<number>(0);
  const { store } = props;

  const validProfilePicture = (): string => {
    const url = exportLinkV2(userProfile.imageUrl as any, true);
    if (!url) return windowLocationRelativeUrl('icons/profile-icon.svg');
    else return urlWithToken(url, 'accessToken');
  };

  useEffect(() => {
    if (store.employeeByIdData.state === 'initial' && !mount) {
      store.setEmployeeById(currentUser.userProfile.employeeId, { profile: 1 });
      setMount(1);
    }
  }, [store, mount, setMount]);

  const freshProfileData: EmployeeProfile = (store.employeeByIdData.data?.data?.profiles || {}) as any;
  return (
    <Fragment>
      <Paper
        elevation={4}
        className="px-6 pt-6 pb-4 relative h-auto mr-6 mt-6 min-h-[200px]"
        sx={{ background: 'linear-gradient(139deg, rgba(255,255,255,1) 0%, rgba(235,251,255,1) 100%)' }}
        // style={{ width: '420px' }}
      >
        <Box style={{ width: '99%' }}>
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: -50, padding: 10 }}>
            <Avatar
              // ref={setAvatarRef as any}
              // imgProps={
              //   {
              //     onError: () => {
              //       console.log('on profile error?', avatarRef.src);
              //       avatarRef.src = windowLocationRelativeUrl('icons/profile-icon.svg');
              //       setAvatarRef(avatarRef);
              //     },
              //   } as any
              // }
              alt={`${userProfile.firstName} ${userProfile.lastName}`}
              src={validProfilePicture()}
              // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5BM92rRHlOYJJA6AR51NqDfCEE8I5roZUog&usqp=CAU"
              sx={{ width: 166, height: 166, border: '4px solid #ffffff' }}
            />
          </Box>

          <Typography className="text-xl font-meduim mt-2 flex justify-center">
            {userProfile.firstName}&nbsp;{userProfile.lastName}
          </Typography>
          <Typography className="text-sm font-medium mb-3 flex justify-center">
            {userProfile.position}&nbsp;({userProfile.buName})
          </Typography>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Box className="mt-3" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Typography className="text-2xl font-meduim">{freshProfileData?.certificates || 0}</Typography>
            <Typography className="text-2xl font-meduim">{freshProfileData?.incidents || 0}</Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-around' }} className="mt-0">
            <Typography className="text-sm font-medium">Certificates</Typography>
            <Typography className="text-sm font-medium">Incidents</Typography>
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
}
