/** declare only http service related requests */

import { HttpTokens, PostManHourMonth, QueryManHourUpdate, ManHourBodyData } from '@/src/types';
import { paramsQueryString } from '@/src/utils';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * ATTENTION api not ready yet!
 * @description update man-hour month scores by id
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const ManHourUpdate = (
  prefix: string = 'incidents/man-hours/update',
  id: string,
  query: QueryManHourUpdate,
  body: ManHourBodyData,
  tokens: HttpTokens,
  hardRejection = true
): Promise<PostManHourMonth> => {
  const instance = new AxiosService({ method: 'put', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  const queryUri = paramsQueryString(`${prefix}/${id}`, query);
  // Logger(['[ManHourUpdate/query][query]', query]);
  // Logger(['[ManHourUpdate/query][body]', JSON.stringify(body)]);

  return instance.client
    .put(`${queryUri}`, JSON.stringify(body), config)
    .then((d: AxiosResponse<PostManHourMonth>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostManHourMonth>;
};
