import { createContext } from 'react';
import { CalendarStore, StoreStateV3 } from '@/types/store';
import { initialDataWrapper } from '../common';
import { QueryCalendarEvents } from '@/src/types';

export const CalendarContext: React.Context<CalendarStore> = createContext<CalendarStore>({
  store: {
    resetStatus: (apiName: string) => {},
    resetState: (apiName: string, state: StoreStateV3) => {},
    setCalendarEvents: (query: QueryCalendarEvents, updateState?: 'update' | 'new') => {},
    calendarData: {
      ...initialDataWrapper,
    },
  },
} as CalendarStore);
