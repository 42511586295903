import React from 'react';
import { Container } from '@/components/index';
import { Box, Paper, Typography } from '@mui/material';
import { setDocTitle } from '@/src/components/documentTitleHook';
import { ERROR_404_PAGE_TITLE } from '@/src/static';

export const Error404: React.FC = () => {
  setDocTitle(ERROR_404_PAGE_TITLE);
  return (
    <Container>
      <Box className="generalMessage flex flex-col">
        <img className="mx-auto mt-14" src="/icons/SSHE-logo.svg" alt="pankod" height="58" />
        <Paper elevation={4} className="w-full text-center block mx-auto mt-14 p-12" sx={{ maxWidth: '700px' }}>
          <img className="mx-auto" src="/icons/error404.svg" alt="pankod" height="94" width="84" />
          <Typography className="mt-8" variant="h2">
            Sorry we could not find this page.
          </Typography>
          <Typography className="mt-4" variant="h6">
            The page you are looking for might have been removed or temporarily unavailable.
          </Typography>
          {/* <Button className="mt-8 w-28" variant="outlined">
            Close
          </Button> */}
        </Paper>
      </Box>
    </Container>
  );
};
