import { Box, Card, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, TextField, TextFieldProps, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { IncidentClassificationType, IncidentDepositionType, IncidentFormStoreParts, IncidentPropertyType } from '@/src/types';
import { propertyDamage } from '@/src/static/incidents/form.data';
import { textFieldProps } from '@/src/utils/form';

interface PropertyDamagePartFourProps {
  // incidentStore: IncidentStore;
  disabledInput: boolean;
}

export const PropertyDamagePartFour: React.FC<any> = (props: PropertyDamagePartFourProps & IncidentFormStoreParts, context) => {
  const {
    formStore,
    // incidentStore: { store: incidentStore },
    disabledInput,
  } = props;

  const propertyDamageData = formStore.store.formData.propertyDamage?.data || {};

  const handleChangePropertyDamageData = (value: any, keyName: string, subKeyName?: string) => {
    formStore.setUpdateFormItem('propertyDamage', 'updated').setup(value, keyName, subKeyName);
  };

  const [isCheckOther, setIsCheckOther] = useState<boolean>(false);
  const refTypeOther = useRef<TextFieldProps>(null);
  const refTextFieldArea = useRef<TextFieldProps>(null);

  const handleCheckboxOther = (checked: boolean) => {
    if (!checked) handleChangePropertyDamageData(null, 'propertyTypeOther');
    if (refTypeOther.current) refTypeOther.current.value = null;
  };

  const handleChangeCheckbox = (checked: any, valueCheckbox: number, keyName: string) => {
    let arrayCheckbox: number[] = [];
    if (checked) {
      arrayCheckbox = propertyDamageData[keyName] ? [...propertyDamageData[keyName], valueCheckbox] : [valueCheckbox];
    } else {
      arrayCheckbox = propertyDamageData[keyName].filter((item) => item !== valueCheckbox);
    }
    arrayCheckbox.sort((a, b) => a - b);
    handleChangePropertyDamageData(arrayCheckbox.length > 0 ? arrayCheckbox : null, keyName);
  };

  const [mount, setMount] = React.useState<number>(0);
  useEffect(() => {
    // TODO: this comment out to test save draft each part only, bring back when all part test save draft DONE
    // if (formStore.store.formData.propertyDamage.state === 'pristine' && !formStore.store.formData.propertyDamage.data) {
    //   handleChangePropertyDamageData(new Date(), 'arrivalDate');
    // }
    if (formStore.store.formData.propertyDamage.state === 'updated' && !mount) {
      if (formStore.store.formData.propertyDamage.data.propertyTypeOther) setIsCheckOther(true);
      const newPropertyDamageData = { ...formStore.store.formData.propertyDamage.data };
      if (!newPropertyDamageData.arrivalDate) newPropertyDamageData.arrivalDate = new Date();
      formStore.setFormParts({ propertyDamage: { data: newPropertyDamageData, state: 'updated' } });
      setMount(1);
    }
  }, [formStore.store.formData.propertyDamage]);

  useEffect(() => {
    if (disabledInput) {
      if (refTextFieldArea.current) refTextFieldArea.current.value = null;
      if (isCheckOther) {
        setIsCheckOther(false);
        if (refTypeOther.current) refTypeOther.current.value = null;
      }
    }
  }, [disabledInput]);

  // Logger(['[PropertyDamagePartFour][formStore]', formStore.store.formData.propertyDamage]);
  // Logger(['[PropertyDamagePartFour][propertyDamage]', propertyDamage]);

  return (
    <>
      <Card variant="outlined" className=" rounded-2xl p-6">
        <Box className="grid grid-cols-1 gap-6">
          <Box>
            <FormControl component="fieldset">
              <Typography className=" font-medium mb-0">Please select</Typography>
              <FormGroup aria-label="position" row>
                {propertyDamage?.propertyDamageCategory?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={
                      <Checkbox
                        checked={propertyDamageData.propertyType?.includes(item.value as IncidentPropertyType) || false}
                        onChange={(e) => handleChangeCheckbox(e.target.checked, item.value, 'propertyType')}
                        disabled={disabledInput}
                      />
                    }
                    label={item.label}
                    labelPlacement="end"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                  />
                ))}
                <FormControlLabel
                  value="Other"
                  control={
                    <Checkbox
                      checked={isCheckOther}
                      onChange={(e) => {
                        setIsCheckOther(e.target.checked);
                        handleCheckboxOther(e.target.checked);
                      }}
                      disabled={disabledInput}
                    />
                  }
                  label="Other"
                  labelPlacement="end"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                />
                <TextField
                  inputRef={refTypeOther}
                  className="w-64"
                  placeholder="Please enter detail"
                  disabled={!isCheckOther || disabledInput}
                  onBlur={(e) => handleChangePropertyDamageData(e.target.value, 'propertyTypeOther')}
                  {...textFieldProps(propertyDamageData, 'propertyTypeOther')}
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box className="flex">
            <FormControl className="mr-6 w-64">
              <InputLabel shrink>Date of arrival at Clinic/Hospital</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableMaskedInput
                  views={['year', 'month', 'day']}
                  inputFormat="dd MMM yyyy"
                  value={propertyDamageData.arrivalDate}
                  onChange={(newValue) => handleChangePropertyDamageData(newValue, 'arrivalDate')}
                  disabled={disabledInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      className="calenderIcon"
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl className="w-64">
              <InputLabel shrink>Time of arrival at Clinic/Hospital</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  ampm={false}
                  value={propertyDamageData.arrivalDate}
                  onChange={(newValue) => handleChangePropertyDamageData(newValue, 'arrivalDate')}
                  disabled={disabledInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      className="timeIcon"
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box>
            <FormControl className="w-full">
              <InputLabel shrink>Details of Treatment</InputLabel>
              <TextField
                inputRef={refTextFieldArea}
                id="outlined-textarea"
                multiline
                onBlur={(e) => handleChangePropertyDamageData(e.target.value || null, 'treatmentDetail')}
                {...textFieldProps(propertyDamageData, 'treatmentDetail')}
                disabled={disabledInput}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset">
              <Typography className=" font-medium mb-0">Deposition</Typography>
              <FormGroup aria-label="position" row>
                {propertyDamage?.propertyDamageDeposition?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={
                      <Checkbox
                        checked={propertyDamageData.deposition?.includes(item.value as IncidentDepositionType) || false}
                        onChange={(e) => handleChangeCheckbox(e.target.checked, item.value, 'deposition')}
                        disabled={disabledInput}
                      />
                    }
                    label={item.label}
                    labelPlacement="end"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset">
              <Typography className=" font-medium mb-0">Incident Classification</Typography>
              <FormGroup aria-label="position" row>
                {propertyDamage?.propertyDamageIncident?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={item.value}
                    control={
                      <Checkbox
                        checked={propertyDamageData.incidentClassification?.includes(item.value as IncidentClassificationType) || false}
                        onChange={(e) => handleChangeCheckbox(e.target.checked, item.value, 'incidentClassification')}
                        disabled={disabledInput}
                      />
                    }
                    label={item.label}
                    labelPlacement="end"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Card>
    </>
  );
};
