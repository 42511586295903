import React, { useState } from 'react';

import { Typography, Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { ConfirmModalState } from '@/types/common.d';
import { IncidentFormStoreParts, IncidentStore } from '@/src/types';
import { useGlobalContext } from '@/store/index';
import { IncidentStatusReport as IncidentStatusReportConst } from '@/constants/index';
import { delay } from '@/src/utils';

interface PropsConfirmIncidentModal {
  /** we use the same ConfirmIncidentModal on both edit and detail page */
  pageType: 'detail' | 'edit_new';
  title: JSX.Element;
  state: ConfirmModalState;
  description: string;
  textArea?: string;
  primaryButton?: string;
  secondaryButton?: string;
  setState: (state: ConfirmModalState) => void;
  incidentStore: IncidentStore;
  /** disable action  is its set*/
  disabled: boolean;
}

function ConfirmIncidentModal(props: PropsConfirmIncidentModal & IncidentFormStoreParts) {
  const { currentUser } = useGlobalContext();
  const {
    disabled,
    title,
    state,
    setState,
    description,
    textArea,
    primaryButton,
    secondaryButton,
    formStore,
    pageType,
    incidentStore: { store: incidentStore },
  } = props;

  const [textRef, setTextRef] = useState<HTMLDivElement & { textValue?: string; submitted?: boolean }>({} as any);
  const incidentDetailData = incidentStore.incidentDetailData;
  const detailInReview = incidentDetailData.state === 'ready' && incidentDetailData.data?.data?.status === IncidentStatusReportConst.review;

  return (
    <>
      <Dialog
        open={state.state === 'OPEN'}
        onClose={(event, reason: 'backdropClick' | 'escapeKeyDown') => {
          // delay(200).then(() => {
          textRef.submitted = false;
          setTextRef(textRef);
          setState({ state: 'INITIAL', type: state.type, decision: 'CANCEL' });
          console.log('close modal ?');
          // });
        }}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <Typography variant="h2" className="p-0 mb-6">
          {title}
        </Typography>
        <DialogContent className="p-0 mb-6">
          <Typography id="transition-modal-description">{description}</Typography>
          {textArea !== '' && (
            <TextField
              ref={(r) => {
                setTextRef(r as any);
              }}
              id="outlined-textarea"
              label={textArea}
              multiline
              className="mt-6 w-full"
              onBlur={(e) => {
                textRef.textValue = (e.target.value || null) as any;
                if (pageType === 'edit_new') {
                }
                if (pageType === 'detail') {
                }
              }}
            />
          )}
        </DialogContent>
        <DialogActions className="p-0 justify-start">
          {primaryButton !== '' && (
            <Button
              disabled={disabled === true || textRef?.submitted === true}
              className="mr-1 w-28"
              variant="contained"
              onClick={() => {
                textRef.submitted = true;
                setTextRef(textRef);
                if (pageType === 'edit_new') {
                  if (incidentDetailData.state === 'ready' && incidentDetailData.data?.data?.status === IncidentStatusReportConst.review) {
                    const reviewers = {
                      approvals: [
                        {
                          employeeId: currentUser?.userProfile?.employeeId,
                          approve: state.type === 'APPROVE' ? 'accept' : 'reject',
                          incidentStatus: incidentDetailData.data?.data?.status,
                          notes: textRef.textValue,
                        },
                      ],
                    } as any;
                    if (formStore) {
                      formStore?.setFormItem('reviewers', reviewers as any);
                      formStore?.setCta('CTA');
                    }
                  }
                }

                if (pageType === 'detail') {
                  // console.log('on click', textRef.textValue);
                }

                delay(200).then(() => {
                  setState({ state: 'CONFIRM', type: state.type, decision: 'OK', text: textRef?.textValue });
                });
              }}
            >
              {primaryButton}
            </Button>
          )}
          {secondaryButton !== '' && (
            <Button
              disabled={disabled === true}
              className="w-28"
              variant="outlined"
              onClick={() => {
                if (detailInReview && formStore) {
                  formStore.setFormParts({ reviewers: null } as any);
                  formStore?.setCta('initial');
                }
                delay(200).then(() => {
                  textRef.submitted = false;
                  setTextRef(textRef);
                  setState({ state: 'INITIAL', type: state.type, decision: 'CANCEL' });
                });
              }}
            >
              {secondaryButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmIncidentModal;
