import { AzureAuthProviderProps, IncidentStore, PropDefaults } from '@/src/types';
import { printDate } from '@/utils/index';
import { initialReport } from '@/src/static/incidents/form.data';
import { Box, Typography } from '@mui/material';
import { Icon } from '@/src/components';
interface IncidentProps extends PropDefaults {
  [name: string]: any;
  title?: string;
  azure: AzureAuthProviderProps;
  /** incident uid */
  detailId: string;
}

export default function PartOneReport(props: IncidentProps & IncidentStore) {
  // const { history, detailId, store } = props;
  const { store } = props;
  const {
    // incidentBusinessUnitsFilter,
    // // incidentCreateUpdateData,
    // incidentQueryTypesData,
    // setIncidentBusinessUnitsFilter,
    // // setIncidentCreateUpdate,
    // setIncidentQueryTypes,
    // setIncidentDetail,
    incidentDetailData,
  } = store;
  const incidentDetailDataInitialReport = incidentDetailData?.data?.data?.initialReport;

  return (
    <>
      <Typography color="primary" className="text-lg xl:text-2xl font-medium flex items-center">
        <Icon icon="squareFull" color="#3155FF" size={10} className="mr-4" />
        Initial report
      </Typography>
      <Box className="border border-solid border-gray-200 border-b-0 border-r-0 mt-2 xl:mt-5 bg-white">
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Name
            </Typography>
            <Typography className="lg:pl-6 w-full">{incidentDetailDataInitialReport?.name || '-'}</Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Age
            </Typography>
            <Typography className="lg:pl-6 w-full">{incidentDetailDataInitialReport?.age || '-'}</Typography>
          </Box>
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Employee ID
            </Typography>
            <Typography className="lg:pl-6 w-full">{incidentDetailDataInitialReport?.employeeId || '-'}</Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Position
            </Typography>
            <Typography className="lg:pl-6 w-full">{incidentDetailDataInitialReport?.position || '-'}</Typography>
          </Box>
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Job experience
            </Typography>
            <Typography className="lg:pl-6 w-full">{incidentDetailDataInitialReport?.jobExperience || '-'}</Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Phone
            </Typography>
            <Typography className="lg:pl-6 w-full">{incidentDetailDataInitialReport?.phone || '-'}</Typography>
          </Box>
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Illness classification
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataInitialReport?.damageType?.length
                ? (incidentDetailDataInitialReport?.damageType || []).map((damageType: any, index) => {
                    const labelFunction = (initialReport.incidentDamageType || []).find((item) => item.value === damageType)?.label;
                    return index === 0 ? labelFunction : ', ' + labelFunction;
                  })
                : '-'}
            </Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Date of report
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataInitialReport?.dateOfReport ? printDate(incidentDetailDataInitialReport?.dateOfReport) : '-'}
            </Typography>
          </Box>
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Date of incident
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataInitialReport?.dateOfIncident ? printDate(incidentDetailDataInitialReport?.dateOfIncident) : '-'}
            </Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Time of incident
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataInitialReport?.dateOfIncident ? printDate(incidentDetailDataInitialReport?.dateOfIncident, 'HH:mm') : '-'}
            </Typography>
          </Box>
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Company
            </Typography>
            <Typography className="lg:pl-6 w-full">{incidentDetailDataInitialReport?.company?.buName || '-'}</Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Location
            </Typography>
            <Typography className="lg:pl-6 w-full">{incidentDetailDataInitialReport?.location || '-'}</Typography>
          </Box>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Function
          </Typography>
          <Typography className="lg:pl-6 w-full flex">
            {incidentDetailDataInitialReport?.functions?.length
              ? (incidentDetailDataInitialReport?.functions || []).map((functions: any, index) => {
                  const labelFunction = (initialReport.functions || []).find((item) => item.value === functions)?.label;
                  return index === 0 ? labelFunction : ', ' + labelFunction;
                })
              : '-'}
          </Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Job classification
          </Typography>
          <Typography className="lg:pl-6 w-full">
            {(initialReport.jobClassification || []).find((item) => item.value === incidentDetailDataInitialReport?.jobClassification?.choice)?.label || '-'}
            {incidentDetailDataInitialReport?.jobClassification?.value ? ' (' + incidentDetailDataInitialReport?.jobClassification?.value + ')' : ''}
          </Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Type of activity
          </Typography>
          <Typography className="lg:pl-6 w-full flex">
            {incidentDetailDataInitialReport?.activityTypes?.length
              ? (incidentDetailDataInitialReport?.activityTypes || []).map((activityTypes: any, index: number) => {
                  return index === 0 ? activityTypes.label : ', ' + activityTypes.label;
                })
              : incidentDetailDataInitialReport?.activityTypeOther
              ? ''
              : '-'}
            {incidentDetailDataInitialReport?.activityTypes?.length && incidentDetailDataInitialReport?.activityTypeOther ? ', ' : ' '}
            {incidentDetailDataInitialReport?.activityTypeOther ? `Other (${incidentDetailDataInitialReport?.activityTypeOther})` : ''}
          </Typography>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Event category
          </Typography>
          <Typography className="lg:pl-6 w-full flex">
            {incidentDetailDataInitialReport?.categoryEvents?.length
              ? (incidentDetailDataInitialReport?.categoryEvents || []).map((categoryEvents: any, index: number) => {
                  return index === 0 ? categoryEvents.label : ', ' + categoryEvents.label;
                })
              : incidentDetailDataInitialReport?.categoryEventOther
              ? ''
              : '-'}
            {incidentDetailDataInitialReport?.categoryEvents?.length && incidentDetailDataInitialReport?.categoryEventOther ? ', ' : ' '}
            {incidentDetailDataInitialReport?.categoryEventOther ? `Other (${incidentDetailDataInitialReport?.categoryEventOther})` : ''}
          </Typography>
        </Box>
        <Box className="bg-gradient-to-r from-blue-600 to-blue-100 px-4 py-3 xl:py-4 xl:pl-10">
          <Typography className="text-white text-base">Cost estimate in THB</Typography>
        </Box>
        <Box className="grid grid-cols-1 xxl:grid-cols-2 gap-0">
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Equipment and Material
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataInitialReport?.costEstimate?.equipmentMaterials
                ? incidentDetailDataInitialReport?.costEstimate?.equipmentMaterials?.toLocaleString() + ' Baht'
                : '-'}
            </Typography>
          </Box>
          <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
            <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
              Production
            </Typography>
            <Typography className="lg:pl-6 w-full">
              {incidentDetailDataInitialReport?.costEstimate?.production
                ? incidentDetailDataInitialReport?.costEstimate?.production?.toLocaleString() + ' Baht'
                : '-'}
            </Typography>
          </Box>
        </Box>
        <Box className="py-3 px-4 border-0 border-b border-solid border-gray-200 border-r md:flex xl:py-4">
          <Typography className="font-medium mb-1 lg:mb-0 md:pl-4 w-full md:max-w-[154px] md:min-w-[154px] lg:pl-6 lg:max-w-[168px] lg:min-w-[168px]">
            Admin/Laborer
          </Typography>
          <Typography className="lg:pl-6 w-full">
            {incidentDetailDataInitialReport?.costEstimate?.adminLaborer
              ? incidentDetailDataInitialReport?.costEstimate?.adminLaborer?.toLocaleString() + ' Baht'
              : '-'}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
