/** declare only http service related requests */

import { HttpTokens, GetLegalHistoryList, LorHistoryQuery } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { paramsQueryString } from '@/utils/index';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get /legal list
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const LegalHistoryListGet = (
  prefix: string = 'legal/history-list',
  id: string,
  query: LorHistoryQuery,
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetLegalHistoryList> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  const queryUri = paramsQueryString(`${prefix}/${id}`, query);

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetLegalHistoryList>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetLegalHistoryList>;
};
