import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { HeadCell } from '@/types/schema/index';

interface EnhancedTableHeadProps {
  headCells: HeadCell[];
  /**
   * Optional for training/attendee
   */
  preTestScore?: number | undefined;
  /**
   * Optional for training/attendee
   */
  postTestScore?: number | undefined;
  /** Optional for training/attendee */
  postTestPassScore?: number | undefined;
}

const TableHeadRow = (props: HeadCell & { preTestScore?: number; postTestScore?: number; postTestPassScore?: number }): any => {
  const { label, id, preTestScore, postTestScore, postTestPassScore } = props;
  if (id === 'preTestScore' && (preTestScore || preTestScore === 0)) {
    return (
      <div className="block">
        {label} <br /> <span className=" text-xs font-normal">(full {preTestScore})</span>
      </div>
    );
  } else if (id === 'postTestScore' && (postTestScore || postTestScore === 0)) {
    return (
      <div className="block">
        {label} <br /> <span className=" text-xs font-normal">(full {postTestScore})</span>
      </div>
    );
  } else if (id === 'testScore' && (postTestPassScore || postTestPassScore === 0)) {
    return (
      <div className="block">
        {label} <br /> <span className=" text-xs font-normal">(pass {postTestPassScore})</span>
      </div>
    );
  } else return label;
};

export function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { headCells, preTestScore, postTestScore, postTestPassScore } = props;

  return (
    <TableHead>
      <TableRow className="round-xl">
        {headCells.map((headCell) => (
          <TableCell
            // ATTENTION had to disable this, it breaks header words on all tables!

            // className="break-all"
            className="align-top"
            key={headCell.id as any}
            align={headCell.textAlign as any}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{
              fontWeight: 'bold',
              width: headCell.width,
              whiteSpace: 'nowrap',
            }}
          >
            <TableSortLabel hideSortIcon={!headCell.sortAble} className={headCell.sortAble === false ? 'hover:cursor-default' : ''}>
              <TableHeadRow {...(headCell as any)} preTestScore={preTestScore} postTestScore={postTestScore} postTestPassScore={postTestPassScore} />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
