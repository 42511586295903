/** declare only http service related requests */

import { HttpTokens, GetLegalList, LegalFilterQuery } from '@/src/types';
import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';
import { paramsQueryString } from '@/utils/index';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get /legal list
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const LegalListGet = (prefix: string = 'legal', params: LegalFilterQuery, tokens: HttpTokens, hardRejection = true): Promise<GetLegalList> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  const queryUri = paramsQueryString(prefix, params);
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetLegalList>) => {
      // return Promise.reject(new Error('ups')) as any;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetLegalList>;
};
