export { default as icons } from './icons.lib';
export const APPLICATION_NAME = 'ARV SSHE';
export const toastMessages = {
  // for admin
  adminAdded: 'Data has been added successfully.',
  adminNotAdded: 'Data was not added!',
  adminUpdated: 'Data has been updated successfully.',
  adminNotUpdated: 'Data was not updated.',
  adminDeleted: 'Data has been deleted successfully.',
  adminNotDeleted: 'Data was not deleted.',
  // for generic
  dataAdded: 'Data has been added successfully.',
  dataReportSubmitted: 'Your report has been submitted successfully.',
  dataSubmitted: 'Your response has been submitted successfully.',
  dataNotSubmitted: 'Your response was not submitted!',
  dataUploaded: 'Data has been uploaded successfully.',
  dataNotUploaded: 'Data was not uploaded.',
  dataNotAdded: 'Data was not added!',
  dataUpdated: 'Data has been updated successfully.',
  dataPublished: 'Data has been published successfully.',
  dataNotPublished: 'Data not published.',
  dataNotUpdated: 'Data was not updated.',
  dataDeleted: 'Data has been deleted successfully.',
  dataNotDeleted: 'Data was not deleted.',
  fileNotUpload: 'Data was not uploaded.',
  fileUpload: 'Data has been uploaded successfully.',
  fileTooLargeRejection: 'This file is too large. Max 5 MB.',
};

export const modalDescriptions = {
  delete: 'This action will permanently delete the training and cannot be undone.',
  cancel: 'This action cannot be undone and the system will send training cancellation email to all attendees',
};

export const PAGE_NOT_FOUND_MESSAGE = 'Page not found.';
export const PAGE_NO_PERMISSION = 'No permission to access this page';
export const PAGE_NO_PERMISSION_V2 = 'Not available until published';
export const PAGE_NOT_EXIST_MESSAGE = 'Page does not exist, or has been deleted.';

/** form field required, message */
export const FIELD_REQUIRED = 'This field is required';

/** form field invalid message */
export const FIELD_INVALID = 'This field is invalid';

export const SERVER_API_ERROR = 'We are experiencing issues, please try again later';

export const APP_NAME = 'ARV SSHE';
export const ADMINS_PAGE_TITLE = 'Admins';
export const COURSES_PAGE_TITLE = 'Courses';
export const TRAINING_PAGE_TITLE = 'Trainings';
export const DASHBOARD_PAGE_TITLE = 'Dashboard';
export const LEGAL_PAGE_TITLE = 'Legals';
export const INCIDENT_PAGE_TITLE = 'Incidents';
export const ERROR_404_PAGE_TITLE = 'Error 404';
export const ERROR_500_PAGE_TITLE = 'Error 500';
export const LOGIN_PAGE_TITLE = 'Login';
export const RESPONSE_PAGE_TITLE = 'Response Message';

export const monthData = [
  { value: 0, label: 'All months' },
  { value: 1, label: 'Jan' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Apr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Aug' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dec' },
];

export const calendarMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
