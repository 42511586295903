import { AzureAuthProviderProps, StoreStateDefault } from '@/types/store';
import { useState, useEffect, useContext } from 'react';
import {
  CourseTrainingTypesGet,
  TrainingCancelPut,
  TrainingCreateUpdatePost,
  TrainingDelete,
  TrainingDetailGet,
  TrainingListGet,
  TrainingSearchGet,
} from '@/services/http';
import { TrainingContext } from './context';
import {
  HttpTokens,
  QueryTrainingList,
  TrainingBodyData,
  TrainingStore,
  QueryTrainingSearch,
  GetTrainingList,
  QueryTrainingDetail,
  GetTrainingDetail,
} from '@/src/types';
// import { getJwtToken, updateTrainingList, updateTrainingDetail, createUpdateTrainingList, delay, updateTrainingPaginationList } from '@/src/utils';
import { getJwtToken, updateTrainingList, createUpdateTrainingList, delay, updateTrainingPaginationList } from '@/src/utils';
// import { deAT } from 'date-fns/locale';
// import { Logger } from '@/logger/index';
// import { cloneDeep } from 'lodash';
import { Logger } from '@/logger/index';

/**
 * to give us access to azure for admin
 */
interface ProviderProps {
  children: React.ReactNode;
  context: {
    [name: string]: any;
    azure: AzureAuthProviderProps;
  };
}
export const useTrainingContextInitialState = () => useContext(TrainingContext);

const TrainingContextProvider = (props: ProviderProps) => {
  const { children } = props;
  /** export initial values from context */
  const contextInitialState = useTrainingContextInitialState();

  /** Access to azure  */
  // const { azure } = context;
  const tokens: HttpTokens = { jwt: getJwtToken() };

  const [trainingDetailData, setTrainingDetail] = useState<StoreStateDefault<GetTrainingDetail> | any>(contextInitialState.store.trainingDetailData);
  const [trainingListData, setTrainingList] = useState<StoreStateDefault<GetTrainingList> | any>(contextInitialState.store.trainingListData);
  const [trainingCreateUpdateData, setTrainingCreateUpdate] = useState<any>(contextInitialState.store.trainingCreateUpdateData);
  const [trainingSearchData, setTrainingSearch] = useState<any>(contextInitialState.store.trainingSearchData);
  const [trainingTypeFilter, setTrainingTypeFilter] = useState<any>(contextInitialState.store.trainingTypeFilter);
  const [trainingDeletedData, setTrainingDelete] = useState<any>(contextInitialState.store.trainingDeletedData);
  const [trainingCanceledData, setTrainingCancel] = useState<any>(contextInitialState.store.trainingCanceledData);

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const store: TrainingStore = {
    store: {
      trainingSearchData,
      trainingCreateUpdateData,
      trainingListData,
      trainingTypeFilter,
      trainingDeletedData,
      trainingCanceledData,
      trainingDetailData,
      resetStatus: (apiName: string) => {
        // TODO TO COMPLETE ALL OTHER apis later
        // or as we see fit

        if (apiName === 'trainings') {
          setTrainingList({ data: undefined as any, state: 'initial' });
        }
        if (apiName === 'training/create') {
          // call to reset api
          setTrainingCreateUpdate({ data: undefined, state: 'initial' });
        }
        if (apiName === 'training/detail') {
          // call to reset api
          setTrainingDetail({ data: undefined, state: 'initial' } as any);
        }
        if (apiName === 'training/cancel') {
          // call to reset api
          setTrainingCancel({ data: undefined, state: 'initial' });
        }
        if (apiName === 'training/delete') {
          // call to reset api
          setTrainingDelete({ data: undefined, state: 'initial' });
        }
      },

      setTrainingCreateUpdate: (body: TrainingBodyData, updateFor: 'list' | 'item', condition?: 'edit' | 'create') => {
        if (!isMounted) return;

        setTrainingCreateUpdate({ data: undefined, state: 'loading' });
        TrainingCreateUpdatePost('training/create', tokens, body)
          .then((n) => {
            if (isMounted) {
              setTrainingCreateUpdate({ data: n, state: 'ready' });

              // check if data returned  from this response can be matched to detail object
              // if (trainingDetailData?.data?.data) {
              //   const { data, updated } = updateTrainingDetail(cloneDeep(trainingDetailData)?.data?.data, n.data);
              //   if (updated) {
              //     trainingDetailData.data = data;
              //     setTrainingDetail({ data: trainingDetailData, state: 'ready' });
              //   }
              // } else {
              //   setTrainingDetail({ data: n, state: 'ready' });
              // }

              if (updateFor === 'list') {
                let list: GetTrainingList = trainingListData?.data;

                if (list?.data && condition === 'create') {
                  const { data } = createUpdateTrainingList(list.data, n.data as any);

                  list = updateTrainingPaginationList(list, data);
                  if (list.data.length > trainingListData.data?.pagination?.itemPerPage) {
                    Logger(['[TrainingStore][setTrainingCreateUpdate] current max itemPerPage:', trainingListData.data?.pagination?.itemPerPage], 'error');
                    Logger(['[TrainingStore][setTrainingCreateUpdate] data exceed itemPerPage list:', list], 'error');
                    list.data = list.data.slice(0, trainingListData.data?.pagination?.itemPerPage);
                  }
                  setTrainingList({ data: list, state: 'ready' });

                  return;
                }

                if (list?.data && condition === 'edit') {
                  const { data } = createUpdateTrainingList(list.data, n.data as any);

                  list.data = data;
                  setTrainingList({ data: list, state: 'ready' });
                }
              }
            }
          })
          .catch((err) => {
            if (isMounted) {
              setTrainingCreateUpdate({ data: undefined, error: err, state: 'error' });
            }
          });
      },
      setTrainingList: (params: QueryTrainingList, type?: 'hidden_load_after_deleted') => {
        if (!isMounted) return;
        if (!type) setTrainingList({ data: undefined, state: 'loading' });
        TrainingListGet('trainings', params, tokens)
          .then((data) => {
            if (isMounted) setTrainingList({ data, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) setTrainingList({ data: undefined, error: err, state: 'error' });
          })
          .then(() => {
            // NOTE: to reset trainingDeletedData & trainingCanceledData after hidden load success
            if (type === 'hidden_load_after_deleted') {
              setTrainingCancel({ data: undefined as any, state: 'initial' });
              setTrainingDelete({ data: undefined, state: 'initial' });
            }
          });
      },
      setTrainingSearch: (params: QueryTrainingSearch) => {
        if (!isMounted) return;

        setTrainingSearch({ data: undefined, state: 'loading' });
        TrainingSearchGet('trainings/search', params, tokens)
          .then((n) => {
            if (isMounted) setTrainingSearch({ data: n, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) setTrainingSearch({ data: undefined, error: err, state: 'error' });
          });
      },
      /*
       * this function for training-types filter in Training Page
       * it use same API as in CourseStore training-types
       * TODO: improve this later (by providing in new mixed-Store)
       */
      setTrainingTypeFilter: () => {
        if (!isMounted) return;
        setTrainingTypeFilter({ data: undefined, state: 'loading' });
        CourseTrainingTypesGet('course/training-types', tokens)
          .then((resData) => {
            if (isMounted) setTrainingTypeFilter({ data: resData, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) setTrainingTypeFilter({ data: undefined, error: err, state: 'error' });
          });
      },
      setTrainingDetail: (id: string, param?: QueryTrainingDetail) => {
        if (!isMounted) return;
        setTrainingDetail({ data: undefined, state: 'loading' });
        TrainingDetailGet('training/detail', id, param, tokens)
          .then((d) => {
            if (isMounted) setTrainingDetail({ data: d, state: 'ready' });
          })
          .catch((error) => {
            if (isMounted) setTrainingDetail({ data: undefined, error, state: 'error' });
          });
      },
      setTrainingCancel: (uid: string, updateFor: 'list' | 'item') => {
        if (!isMounted) return;
        setTrainingCancel({ data: undefined, state: 'loading' });

        TrainingCancelPut('training/cancel', tokens, uid)
          .then((d) => {
            if (isMounted) {
              setTrainingCancel({ data: d, state: 'ready' });
              setTrainingDetail({ data: d, state: 'ready' });

              const updateTraining = () => {
                const list: GetTrainingList = trainingListData?.data;
                if (list?.data) {
                  const { updated, data } = updateTrainingList(list.data, d.data as any);
                  if (updated) {
                    list.data = data;
                    setTrainingList({ data: list, state: 'ready' });
                  }
                }
                return list;
              };

              if (updateFor === 'item') {
                if (trainingDetailData.data) {
                  if (d.data.id === trainingDetailData.data.data?.id) {
                    setTrainingDetail({ data: d, state: 'ready' });
                  }
                }
                updateTraining();
              }
              // TODO some logic to correct pagination on cancel
              if (updateFor === 'list') updateTraining();
            }
          })
          .catch((error) => {
            if (isMounted) setTrainingCancel({ data: undefined, error, state: 'error' });
          });
      },
      setTrainingDelete: (uid: string, updateFor: 'list' | 'item', delayed?: number) => {
        if (!isMounted) return;
        setTrainingDelete({ data: undefined, state: 'loading' });

        TrainingDelete('training/delete', tokens, uid)
          .then(async (data) => {
            if (isMounted) {
              // delay state update to allow notification to display
              if (delayed) {
                // update state to deleted but leave data available fro short time
                setTrainingDetail({ ...trainingDetailData, state: 'deleted' });
                setTrainingDelete({ data, state: 'ready' });
                await delay(delayed);
              } else {
                setTrainingDelete({ data, state: 'ready' });
              }

              const updateList = () => {
                if (trainingListData?.data?.data) {
                  const list: GetTrainingList = trainingListData?.data;
                  const count = list.data.length;
                  const d = list.data.filter((n) => n.id !== data.data.id);
                  list.data = d;
                  if (count !== d.length) {
                    setTrainingList({ data: list, state: 'ready' });
                  }
                }
              };

              if (updateFor === 'item') {
                if (trainingDetailData.data) {
                  if (data.data.id === trainingDetailData.data.data?.id) {
                    setTrainingDetail({ data: undefined, state: 'initial' });
                  }
                }
                updateList();
              }

              if (updateFor === 'list') updateList();
            }
          })
          .catch((error) => {
            if (isMounted) setTrainingDelete({ data: undefined, error, state: 'error' });
          });
      },
    },
  };

  return <TrainingContext.Provider value={store}>{children}</TrainingContext.Provider>;
};

const withTrainingContext = (Component: any) => {
  return function TrainingComponent(props: any) {
    return (
      <TrainingContext.Consumer>
        {(contexts) => {
          return <Component {...props} {...contexts} />;
        }}
      </TrainingContext.Consumer>
    );
  };
};

export { TrainingContextProvider, withTrainingContext };
