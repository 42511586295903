import { BodyLegalLorEvaluation, LegalStatusSchema, LorActionItemSchema } from '@/src/types';
import { assignPropsToThis } from '@/src/utils';

const legalPropsAssignments = ['status', 'teamMembers', 'actionItems', 'report', 'evalStartYear'];

/**
 * should only send one training type upon submit
 */
const conditionedData = (formStateDate: BodyLegalLorEvaluation): BodyLegalLorEvaluation => {
  const data: any = formStateDate;
  if (!data) return undefined as any;

  return data as BodyLegalLorEvaluation;
};

export class LegalEvaluationDto implements BodyLegalLorEvaluation {
  status: LegalStatusSchema = null as any;
  // we only need to provide employee ids in a string
  teamMembers?: string[] = [];
  actionItems?: LorActionItemSchema[] = [];

  evalStartYear: string = '';
  report?: string = '';
  constructor(data?: BodyLegalLorEvaluation, loadMock?: boolean) {
    let _data;
    // assign only required data
    if (Object.keys(data || {}).length) {
      _data = data;
    } else if (loadMock) {
      // _data = loadExampleData();
    }

    _data = conditionedData(_data);
    if (_data) /** updates > this= */ assignPropsToThis(legalPropsAssignments, _data, this);
  }
}
