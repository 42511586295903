import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Icon } from '@/components/index';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { delay } from '@/utils/index';

export function SearchBoxInput(props: { onEnterSearch: (val: any) => any; onClearSearch: () => void; nameSearching?: string }) {
  const { onEnterSearch, onClearSearch, nameSearching } = props;
  const [textSearch, setTextSearch] = useState(nameSearching || '');
  const [isCleared, setIsCleared] = useState(false);

  useEffect(() => {
    return () => {
      setTextSearch('');
      setIsCleared(false);
    };
  }, []);

  useEffect(() => {
    if (nameSearching === '') setTextSearch('');
    if (nameSearching) setTextSearch(nameSearching);
  }, [nameSearching]);

  return (
    <TextField
      placeholder="Enter keyword"
      variant="outlined"
      sx={{ width: '320px', marginRight: '15px' }}
      value={textSearch}
      inputRef={(input) => {
        if (isCleared && input) input.focus();
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              sx={{ mr: '8px', visibility: textSearch ? 'visible' : 'hidden' }}
              onClick={() => {
                setTextSearch('');
                onClearSearch();
                setIsCleared(true);
                delay(1000).then(() => setIsCleared(false));
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <Icon icon="magnifyingGlass" color="#3155FF" viewBox="0 0 512 512" size={20} />
          </InputAdornment>
        ),
        autoComplete: 'off',
      }}
      onChange={(e) => {
        setTextSearch(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onEnterSearch(textSearch);
      }}
    />
  );
}
