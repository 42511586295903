// ! api: https://fullcalendar.io/docs/react
// ! api: https://fullcalendar.io/docs/date-navigation
// ! api: https://fullcalendar.io/docs/eventTimeFormat
// ! api: Docs  Event Popover  https://fullcalendar.io/docs/event-popover
// time formats: https://stackoverflow.com/questions/9080944/24-hour-time-format-so-no-am-to-pm-for-fullcalendar#9088187
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FullCalendar, { EventContentArg, EventClickArg, ViewApi, EventApi, FormatterInput, DateFormatter, ToolbarInput } from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { CircularProgress, Alert, Box } from '@mui/material';
import { Logger } from '@/logger/index';
import { CalendarDto } from '@/dto/index';
import { PropDefaults, CalendarStore, GetCalendarEvents } from '@/src/types';
import { calendarRange, calendarViewApiRange, getCalendarViewType } from '@/src/utils';
import { CalendarEventViewType } from '@/types/common';
import renderEventContent from './calendar.event.content';
import { SERVER_API_ERROR } from '@/src/static';

type CalendarUpdateStatus = 'initial' | 'busy' | 'updated';

const initialView = 'dayGridMonth';
const eventTimeFormat: FormatterInput | DateFormatter = {
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h24',
  week: 'short',
  meridiem: false,
};

const slotLabelFormat: FormatterInput | FormatterInput[] = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: false,
};

const headerToolbar: false | ToolbarInput | undefined = {
  left: 'prev,next today dayGridMonth,timeGridWeek,timeGridDay',
  center: 'title',
  right: '',
};

export default function CalendarUI(props: PropDefaults & CalendarStore) {
  const { store } = props;
  const navigate = useNavigate();

  const [mount, setMount] = useState<number>(0);
  const [viewApi, setViewApi] = useState<ViewApi>(null as any);
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [calStatusUpdate, setCalStatusUpdate] = useState<CalendarUpdateStatus>('initial');
  const [calendarData, setCalendarData] = useState<GetCalendarEvents>(null as any);
  const [eventView, setEventViewChange] = useState<CalendarEventViewType>(getCalendarViewType(initialView));

  const currentYearStartDate = new Date(currentYear, 0, 1);
  const calendarRef = React.createRef<FullCalendar>();

  const handleEvents = (events: EventApi[]) => {
    Logger(['[CalendarUI][events]', events]);

    if (calendarRef.current && calendarData) {
      const calendarApi = calendarRef.current.getApi();
      // depending on eventView go next
      // calendarApi.next();
      //  Logger(['[CalendarUI][events][calendarApi]', calendarApi.getCurrentData()]);

      const calendarApiRange = calendarApi.getCurrentData().viewApi;
      // data requested from ui
      const { currentStart, currentEnd } = calendarViewApiRange(calendarApiRange);
      const calendarDataRange = calendarRange(calendarData?.range);

      if (calendarDataRange) {
        if (currentStart >= (calendarDataRange as any)?.to) {
          Logger(
            [
              '[CalendarUI][range]',
              'data NOT in range',
              {
                currentEnd: new Date(currentEnd),
                to: new Date((calendarDataRange as any)?.to),
              },
            ],
            'warn'
          );

          // to avoid infinite calls, only execute once mounted and original data was set
          if (mount && calStatusUpdate !== 'busy') {
            // update calendar, add more data to existing calendar array
            // NOTE call to api
            // we only want to update if calendar is out of bound - on the next year
            const calendarYearSelected = new Date(currentStart).getFullYear();
            if (calendarYearSelected > currentYear) {
              store.setCalendarEvents({ type: 'year', date: calendarApiRange.currentStart }, 'update');
              setCalStatusUpdate('busy');
              setCurrentYear(calendarYearSelected); // offset for next year
              Logger(['[CalendarUI][setCalendarEvents][update][date]', new Date(calendarApiRange.currentStart)]);
            }
          }
        } else {
          Logger(
            [
              '[CalendarUI][range]',
              'data in range',
              {
                currentEnd: new Date(currentEnd),
                to: new Date((calendarDataRange as any)?.to),
              },
            ],
            'notice'
          );
        }
      }
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    navigate(`/trainings/detail/${clickInfo.event._def.publicId}/calendar`);
  };

  // const handleDateSelect = (selectInfo: DateSelectArg) => {
  //   Logger(['[CalendarUI][handleDateSelect]', selectInfo]);
  //   // let title = prompt('Please enter a new title for your event')
  //   // let calendarApi = selectInfo.view.calendar
  //   // calendarApi.unselect() // clear date selection
  //   // if (title) {
  //   //   calendarApi.addEvent({
  //   //     id: createEventId(),
  //   //     title,
  //   //     start: selectInfo.startStr,
  //   //     end: selectInfo.endStr,
  //   //     allDay: selectInfo.allDay
  //   //   })
  //   // }
  // };

  useEffect(() => {
    /**
     * when calling type=year we ask the 1 whole year payload
     * when calling with {date} set, we are asking for whole of data[] for that {type} from start to end
     *
     * detect what calendar date we want to view, if this data is not available we need to call api and refresh the ui again
     */
    if (store.calendarData.state === 'initial' && !mount) {
      store.setCalendarEvents({ type: 'year', date: currentYearStartDate });
      setMount(1);
    }

    if (store.calendarData.state === 'updated' && calStatusUpdate === 'busy') {
      // only update if we have some data
      if (store.calendarData.data.data.length) {
        setCalendarData(store.calendarData.data);
        store.resetState('calendar', 'ready');
        setCalStatusUpdate('updated');
      }
    }

    if (store.calendarData.state === 'ready' && !calendarData) {
      setCalendarData(store.calendarData.data);
    }
  }, [store, calendarData, setCalendarData, setCalStatusUpdate, calStatusUpdate]);

  Logger(['[CalendarUI][store.calendarData.state]', store.calendarData, calendarData]);

  const conditions: 'ready' | 'error' | 'loading' = [
    ['ready', 'updated'].indexOf(store.calendarData.state) === -1 && store.calendarData.state !== 'error' && 'loading',
    store.calendarData.state === 'error' && 'error',
    ['ready', 'updated'].indexOf(store.calendarData.state) !== -1 && calendarData !== null && 'ready',
  ].filter((n) => !!n)[0] as any;

  // const eventDayFormat: FormatterInput | DateFormatter = {};
  switch (conditions) {
    case 'ready': {
      return (
        <FullCalendar
          dragScroll={true}
          // selectable={true}
          handleWindowResize={true}
          windowResizeDelay={1000}
          // expandRows={true}
          eventShortHeight={200}
          eventClassNames="full-calendar-event-item"
          ref={calendarRef as any}
          timeZone="UTC"
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={headerToolbar}
          dayMaxEventRows={true}
          slotLabelFormat={slotLabelFormat}
          allDaySlot={false}
          eventOverlap={false}
          eventDisplay="block"
          progressiveEventRendering={true}
          height="100%"
          initialView={initialView}
          dayMaxEvents={true}
          eventTimeFormat={eventTimeFormat}
          eventMaxStack={2}
          // NOTE the programmatic way to fix positioning should be done here
          // currently cannot find best approach, so we do it the css way :)
          // moreLinkClick={(args: any) => {
          //   delay(200).then(() => {
          //     try {
          //       const el: any = document.querySelector('.fc-popover.fc-more-popover') as any;
          //       const posLeft = Number(el.style.left.replace('px', ''));
          //     } catch (err) {}
          //   });
          // }}
          // dayHeaderFormat={eventDayFormat}
          eventContent={(eventInfo: EventContentArg) => {
            return renderEventContent(eventInfo, setEventViewChange, setViewApi);
          }}
          events={calendarData?.data.map((n) => {
            const d: any = new CalendarDto(n as any, false);
            d.allDay = false;
            return d;
          })}
          eventClick={handleEventClick}
          eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          //  select={handleDateSelect}
        />
      );
    }
    case 'loading': {
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress className="mt-5" />
        </Box>
      );
    }
    case 'error': {
      return <Alert severity="error">{SERVER_API_ERROR}</Alert>;
    }
    default: {
      return <>No condition met</>;
    }
  }
}
