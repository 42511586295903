import { Skeleton, Box } from '@mui/material';

export const PreloaderIncidentForm = () => {
  return (
    <>
      <Skeleton animation="wave" className="w-full h-4/5 mb-5 rounded-2xl transform-none" sx={{ backgroundColor: '#F5F7FF' }} />
      <Skeleton animation="wave" className="w-full h-[92px] mb-5 rounded-2xl transform-none" sx={{ backgroundColor: '#F5F7FF' }} />
      <Skeleton animation="wave" className="w-full h-[92px] mb-5 rounded-2xl transform-none" sx={{ backgroundColor: '#F5F7FF' }} />
      <Skeleton animation="wave" className="w-full h-[92px] mb-5 rounded-2xl transform-none" sx={{ backgroundColor: '#F5F7FF' }} />
      <Skeleton animation="wave" className="w-full h-[92px] mb-5 rounded-2xl transform-none" sx={{ backgroundColor: '#F5F7FF' }} />
      <Skeleton animation="wave" className="w-full h-[92px] mb-5 rounded-2xl transform-none" sx={{ backgroundColor: '#F5F7FF' }} />
      <Skeleton animation="wave" className="w-full h-[92px] mb-5 rounded-2xl transform-none" sx={{ backgroundColor: '#F5F7FF' }} />
      <Skeleton animation="wave" className="w-full h-[92px] mb-5 rounded-2xl transform-none" sx={{ backgroundColor: '#F5F7FF' }} />
      <Box
        className="sticky bottom-0 py-5 px-8 bg-white z-20 flex"
        sx={{ boxShadow: '0px 3px 10px #00000029', marginLeft: '-32px', marginBottom: '-32px', width: 'calc(100% + 400px)' }}
      >
        <Box className="mr-auto flex">
          <Skeleton animation="wave" className="w-[100px] h-[40px] transform-none rounded-lg" sx={{ backgroundColor: '#3155ff' }} />
          <Skeleton animation="wave" className="w-[100px] h-[40px] transform-none rounded-lg ml-4" sx={{ backgroundColor: '#F5F7FF' }} />
        </Box>
        <Box className="ml-auto flex">
          <Skeleton animation="wave" className="w-[175px] h-[40px] transform-none rounded-lg ml-4" sx={{ backgroundColor: '#3155ff' }} />
          <Skeleton animation="wave" className="w-[104px] h-[40px] transform-none rounded-lg ml-4" sx={{ backgroundColor: '#00C083' }} />
          <Skeleton animation="wave" className="w-[100px] h-[40px] transform-none rounded-lg ml-4" sx={{ backgroundColor: '#F14242' }} />
        </Box>
      </Box>
    </>
  );
};
