/** @type {import('../../../types/common').MockApiData} */
exports.employeeUpdate = {
  // what type of request: [get,put,post], mainly using get
  type: 'put',
  // api name after ./api prefix, api/example
  uniqId: 'put-employee/update',

  /**
   * any data: can be object, array etc, depending what you want to return
   * - data is added thru AxiosService > interceptors.response after https://httpbin.org/ request was successful
   * */
  response: {
    status: {
      code: 200,
      message: 'success',
    },
    data: {
      employeeId: 'ARV10060',
      firstName: 'Abigai',
      middleName: null,
      lastName: 'Pruitt',
      email: 'Abigai@arv.co.th',
      buCode: 'ARV',
      roleId: 1,
    },
  },
};
