import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, Dialog, DialogContent, DialogTitle, DialogProps } from '@mui/material';
import { Icon } from '@/src/components';
import TrainingForm from './trainingFormClass';
import { Logger } from '@/logger/index';
import { DefaultComponentProps, TrainingRequestDtoType, TrainingBodyData } from '@/src/types';
import { serverError } from '@/src/utils';
import { FIELD_REQUIRED } from '@/static/index';
import { TrainingStore } from '@/types/store/index';

interface CreateTrainingModalProps extends DefaultComponentProps {
  onSubmitType(type: 'submit-publish' | 'submit-draft' | 'submit-edit-publish'): void;
}

function CreateTrainingModal(props: CreateTrainingModalProps & TrainingStore) {
  const { store, onSubmitType } = props;
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  /**
   *
   * @param submitType depending on submit time display correct toast message
   */
  const onSubmitHandler = (data: TrainingRequestDtoType | TrainingBodyData, submitType: 'submit-publish' | 'submit-draft' | 'submit-edit-publish') => {
    Logger(['[create/training][onSubmitHandler]', data], 'attention');
    Logger(['json', JSON.stringify(data)]);
    store.setTrainingCreateUpdate(data as any, 'list', 'create');
    if (onSubmitType) onSubmitType(submitType);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    if (store.trainingCreateUpdateData.state === 'ready') {
      setOpen(false);
    }
  }, [store]);

  if (store.trainingCreateUpdateData.state === 'error' && open) {
    Logger(['[store][trainingCreateUpdateData]', store.trainingCreateUpdateData.error], 'error');
  }

  return (
    <Box>
      <Button
        className="w-28"
        variant="contained"
        onClick={handleClickOpen('body')}
        startIcon={<Icon icon="plus" color="#ffffff" viewBox="0 0 512 512" size={18} />}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={(e) => {
          setOpen(false);
          store.resetStatus('training/create');
          if (store.trainingDetailData.state === 'ready') store.resetStatus('training/detail');
        }}
        fullWidth={true}
        maxWidth={'lg'}
        scroll={scroll}
        className="overflow-hidden"
      >
        <DialogTitle className="py-8 pt-0 pb-4 border-0 border-b border-solid border-gray-300" sx={{ margin: '-5px -30px 0' }}>
          <Typography className="font-medium text-2xl">Add new training</Typography>
        </DialogTitle>
        <DialogContent className="p-0 " sx={{ overflow: 'unset' }}>
          <TrainingForm
            mode="create"
            serverErrorResponse={serverError(store?.trainingCreateUpdateData.error)}
            allRequired={true}
            allRequiredMessage={FIELD_REQUIRED}
            onClose={() => {
              setOpen(false);
              store.resetStatus('training/create');
              if (store.trainingDetailData.state === 'ready') store.resetStatus('training/detail');
            }}
            onSubmit={onSubmitHandler}
            formStatus={'open'}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default CreateTrainingModal;
