import { TrainingBodyData, TrainingStatus, TrainingDetailSchema, CourseTrainingCode } from '@/src/types';

// const loadExampleData = () => {
//   try {
//     return require('@/static/trainings/createform.training.example.json');
//   } catch (err) {
//     // Logger(['[loadExampleData]', err], 'error');
//     return {};
//   }
// };

const assignOnly = [
  'id',
  'employeeIds',
  'courseId',
  'startDate',
  'endDate',
  'location',
  'evaluationLink',
  'certExpirationDate',
  'status',
  'employeeList',
  'onlineLink',
  'trainingCode',
  'sendEmail',
];

/**
 * should only send one training type upon submit
 */
const conditionedData = (formStateDate: TrainingDetailSchema): TrainingBodyData => {
  const data: any = formStateDate;
  if (!data) return undefined as any;
  if (!formStateDate?.employeeList?.length) {
    delete data.employeeList;
  }
  return data as TrainingBodyData;
};

export class TrainingRequestDto implements TrainingBodyData {
  /**  optional for create new training, required for update */
  id: string = undefined as any;
  courseId: string = undefined as any;
  /** allow nulls for status=1 (Draft)   */
  startDate?: Date | string = new Date(); // new Date('2021-06-12T00:00:00');
  /** allow nulls for status=1 (Draft) */
  endDate?: Date | string = new Date(); // undefined as any;
  location: string = '' as any;
  /** require for online, e-learning (allow nulls for status=1 (Draft) */
  evaluationLink: string = '' as any;
  onlineLink?: string | undefined = '' as any;
  status: TrainingStatus = '' as any;
  /** list of employeeId[],also known as attendees of course/training */
  employeeIds: Array<string> = [] as any;
  trainingCode: CourseTrainingCode = '' as any;
  /** this list is only available for status=1 (draft) when editing the form*/
  employeeList: Array<{ employeeId: string; firstName: string; middleName?: string; lastName: string }> = [] as any;
  /* added in UAT feedback from client to handle sent email to attendee for training */
  sendEmail?: boolean = false;
  certExpirationDate?: Date | string | null = null;

  constructor(data?: TrainingDetailSchema, loadMock?: boolean) {
    let _data;
    // assign only required data
    if (Object.keys(data || {}).length) {
      _data = data;
    } else if (loadMock) {
      // todo
      // _data = loadExampleData();
    }

    _data = conditionedData(_data);
    if (_data) {
      assignOnly.forEach((key) => {
        try {
          this[key] = _data[key] || undefined;
        } catch (err) {}
      });
    }
  }
}
