import React from 'react';
import { Box, TablePagination, Tooltip, styled, tooltipClasses, TooltipProps, IconButton } from '@mui/material';
import { Icon } from '@/src/components';

interface EnhancedTablePaginationProps {
  [name: string]: any;
  totalItems: number;
  page: number;
  rowsPerPage: number;
  onCallBackPagination: Function;
  isSoftReload: boolean;
  pageType?: string;
  noFilterTotalItems?: number;
  legalItems?: number;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

function EnhancedTablePagination(props: EnhancedTablePaginationProps) {
  const { totalItems, page, rowsPerPage, onCallBackPagination, isSoftReload, pageType = 'default', noFilterTotalItems, legalItems } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    onCallBackPagination('setPage', newPage);
    onCallBackPagination('setNewPage', { newPage, rowsPerPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsPer = parseInt(event.target.value, 10);
    onCallBackPagination('setRowsPerPage', rowsPer);
    onCallBackPagination('setPage', 0);
    onCallBackPagination('setNewPage', { newPage: 0, rowsPerPage: rowsPer });
  };

  const LegalCustomPagination = (props) => {
    return (
      <div {...props}>
        <div className="flex w-full">
          <div className="mr-auto self-center flex">
            Results: {legalItems} of {noFilterTotalItems} legislations
            <Box
              sx={{
                marginX: '8px',
                borderLeft: '2px solid lightgray',
                height: 'auto',
              }}
            />
            Type:
            <LightTooltip title={<Box className=" text-center">Env = Environment, Sec = Security, Saf = Safety, Hea = Health</Box>} arrow>
              <IconButton className="p-0 ml-2">
                <Icon icon="circleInfo" color="#3155FF" size={18} className="" />
              </IconButton>
            </LightTooltip>
          </div>
          {props.children}
        </div>
      </div>
    );
  };

  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 50]}
      component={pageType === 'legal' ? LegalCustomPagination : 'div'}
      count={totalItems}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => {
        if (isSoftReload) {
          return `${from}–${to - 1} of ${count !== -1 ? count - 1 : `more than ${to}`}`;
        } else {
          return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
        }
      }}
      showFirstButton
      showLastButton
    />
  );
}

export default EnhancedTablePagination;
