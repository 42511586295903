import { HeadCell } from '@/types/index';

export const incidentDamageTypes = [
  { value: 1, label: 'Injury', iconPath: 'patient.png' },
  { value: 2, label: 'Occupational', iconPath: 'occupational-health-and-safety.png' },
  { value: 3, label: 'Property damage', iconPath: 'broken.png' },
  { value: 4, label: 'Near miss', iconPath: 'near-miss.png' },
];

export const headCellsIncidents: HeadCell[] = [
  {
    id: 'draft',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 0.1 / 8,
    textAlign: 'inherit',
  },
  {
    id: 'id',
    label: 'No.',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 8,
    textAlign: 'inherit',
  },
  {
    id: 'date',
    label: 'Date of incident',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 8,
    textAlign: 'inherit',
  },
  {
    id: 'employeeName',
    label: 'Employee name',
    disablePadding: false,
    sortAble: false,
    width: 1.4 / 8,
    textAlign: 'inherit',
  },
  {
    id: 'company',
    label: 'Company',
    disablePadding: false,
    sortAble: false,
    width: 0.6 / 8,
    textAlign: 'inherit',
  },
  {
    id: 'classification',
    label: 'Classification',
    disablePadding: false,
    sortAble: false,
    width: 1.6 / 8,
    textAlign: 'inherit',
  },
  {
    id: 'status',
    label: 'Status',
    disablePadding: false,
    sortAble: false,
    width: 0.6 / 8,
    textAlign: 'inherit',
  },
  {
    id: 'actions',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 0.8 / 8,
    textAlign: 'inherit',
  },
];

export const headCellsIncidentsCorrectiveAction: HeadCell[] = [
  {
    id: 'id',
    label: 'No.',
    disablePadding: false,
    sortAble: false,
    width: 0.2 / 7,
    textAlign: 'inherit',
  },
  {
    id: 'correctiveAction',
    label: 'Corrective action',
    disablePadding: false,
    sortAble: false,
    width: 1.2 / 7,
    textAlign: 'inherit',
  },
  {
    id: 'actionParty',
    label: 'Action party',
    disablePadding: false,
    sortAble: false,
    width: 1.2 / 7,
    textAlign: 'inherit',
  },
  {
    id: 'responsibility',
    label: 'Responsibility',
    disablePadding: false,
    sortAble: false,
    width: 1 / 7,
    textAlign: 'inherit',
  },
  {
    id: 'date',
    label: 'Due date',
    disablePadding: false,
    sortAble: false,
    width: 1 / 7,
    textAlign: 'inherit',
  },
  {
    id: 'file',
    label: 'File',
    disablePadding: false,
    sortAble: false,
    width: 0.4 / 7,
    textAlign: 'inherit',
  },
  {
    id: 'actions',
    label: '',
    disablePadding: false,
    sortAble: false,
    width: 0.1 / 7,
    textAlign: 'inherit',
  },
];

export const incidentStatusText = {
  1: 'Draft',
  2: 'In review',
  3: 'Ongoing',
  4: 'Closed',
};

export const incidentStatusData = [
  { value: 1, label: 'Draft' },
  { value: 2, label: 'In review' },
  { value: 3, label: 'Ongoing' },
  { value: 4, label: 'Closed' },
];

export const approverTaskTitles = {
  tl: 'Review completion of corrective action',
  hod: 'Verification and validation of incident report',
  addNote: 'You can add note after click on the button.',
  approveNote: 'Give approval or reject this incident',
};

// export const reviewersMockData: ReviewerSchema = {
//   remarks: [
//     {
//       employee: {
//         employeeId: 'string',
//         firstName: 'string',
//         middleName: 'string',
//         lastName: 'string',
//         email: 'string',
//         buCode: 'string',
//         buName: 'string',
//         roleId: 0 as any,
//         position: 'string',
//       },
//       remark: 'string',
//       date: '2022-09-05T02:55:08.172Z',
//       ref: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
//     },
//   ],
//   approvals: [
//     {
//       incidentStatus: 0 as any,
//       employee: {
//         employeeId: 'string',
//         firstName: 'string',
//         middleName: 'string',
//         lastName: 'string',
//         email: 'string',
//         buCode: 'string',
//         buName: 'string',
//         roleId: 0 as any,
//         position: 'string',
//       },
//       approve: 'accept',
//       notes: 'some notes ',
//       date: '2022-09-05T02:55:08.172Z',
//       ref: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
//     } as any,
//   ],
// };

// export interface ReviewerSchema {
//   /**
//    * @optional only if we add any remark notes
//    * remarks only for: TM, CO/F (excluding super_admin)
//    */
//   remarks?: IncidentReportRemarkSchema[];

//   /**
//    * @required beond draft>review, once super_admin approve/reject we need to provide this information in request/body
//    * 1. super_admin to team lead
//    * 2. team lead to co/founder
//    * 3. co/founder to general manager
//    * 4. general manager approve and close report
//    */
//   approvals?: IncidentReportApprovalSchema[];
// }
