import { GetLegalBuRelated, LegalRelatedBuListSchema } from '@/src/types';

// NOTE THIS DATA IS NO LONGER USED
// export interface LegalsSchema {
//   code: string;
//   name: string;
//   promulgationDate: string;
//   effectiveDate: string;
//   hierarchy: string;
//   relatedBU: string;
//   status: number;
//   download: string;
//   actions?: string;
//   lorType?: { value: number; label: string };
// }

// function createLegals(
//   code: string,
//   name: string,
//   promulgationDate: string,
//   effectiveDate: string,
//   hierarchy: string,
//   relatedBU: string,
//   status: number,
//   download: string,
//   actions?: string,
//   lorType?: { value: number; label: string }
// ): LegalsSchema {
//   return {
//     code,
//     name,
//     promulgationDate,
//     effectiveDate,
//     hierarchy,
//     relatedBU,
//     status,
//     download,
//     actions,
//     lorType,
//   };
// }

// export const mockupRows = [
//   createLegals(
//     'MOT-C1234-G1231',
//     'ประกาศสำนักงานปรมาณูเพื่อสันติภาพ เรื่อง แบบคำขอแจ้งการ ครอบครองหรือใช้เครื่องกำเนิดรังสี ใบรับแจ้ง รายงานแสดงการ เพิ่มขึ้นหรือลดลงของจำนวนเครื่องกำเนิดรังสี',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'ARV, Cariva, Rovala, Skyller, Varuna',
//     1,
//     'www.facebook.com',
//     'none',
//     { value: 1, label: 'Environment' }
//   ),
//   createLegals(
//     'MOT-C1234-G1232',
//     'ประกาศสำนักงานปรมาณูเพื่อสันติภาพ เรื่อง แบบคำขอแจ้งการ ครอบครองหรือใช้เครื่องกำเนิดรังสี ใบรับแจ้ง รายงานแสดงการ เพิ่มขึ้นหรือลดลงของจำนวนเครื่องกำเนิดรังสี',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     2,
//     'www.facebook.com',
//     'none',
//     { value: 2, label: 'Security' }
//   ),
//   createLegals(
//     'MOT-C1234-G1233',
//     'การอบรมแรงงานการกำหนดมาตรฐานบริการ ภาค3',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     2,
//     'www.facebook.com',
//     'none',
//     { value: 1, label: 'Environment' }
//   ),
//   createLegals(
//     'MOT-C1234-G1234',
//     'การอบรมแรงงานการกำหนดมาตรฐานบริการ ภาค4',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     2,
//     'www.facebook.com',
//     'none',
//     { value: 2, label: 'Security' }
//   ),
//   createLegals(
//     'MOT-C1234-G1235',
//     'การอบรมแรงงานการกำหนดมาตรฐานบริการ ภาค5',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     3,
//     'www.facebook.com',
//     'none',
//     { value: 2, label: 'Security' }
//   ),
//   createLegals(
//     'MOT-C1234-G1236',
//     'การอบรมแรงงานการกำหนดมาตรฐานบริการ ภาค6',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     3,
//     'www.facebook.com',
//     'none',
//     { value: 2, label: 'Security' }
//   ),
//   createLegals(
//     'MOT-C1234-G1237',
//     'การอบรมแรงงานการกำหนดมาตรฐานบริการ ภาค7',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     3,
//     'www.facebook.com',
//     'none',
//     { value: 1, label: 'Environment' }
//   ),
//   createLegals(
//     'MOT-C1234-G1238',
//     'การอบรมแรงงานการกำหนดมาตรฐานบริการ ภาค8',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     4,
//     'www.facebook.com',
//     'none',
//     { value: 1, label: 'Environment' }
//   ),
//   createLegals(
//     'MOT-C1234-G1239',
//     'การอบรมแรงงานการกำหนดมาตรฐานบริการ ภาค9',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     4,
//     'www.facebook.com',
//     'none',
//     { value: 1, label: 'Environment' }
//   ),
//   createLegals(
//     'MOT-C1234-G1240',
//     'การอบรมแรงงานการกำหนดมาตรฐานบริการ ภาค10',
//     '16/08/2022',
//     '19/08/2022',
//     'Notification (ประกาศ กระทรวงและประกาศกรม)',
//     'Cariva, Rovula',
//     5,
//     'www.facebook.com',
//     'none',
//     { value: 1, label: 'Environment' }
//   ),
// ] as LegalsSchema[];

export const progresses = [
  {
    label: 'Legislation info',
  },
  {
    label: 'Review legislation',
  },
  {
    label: 'Closeout person',
    description: 'Yes',
  },
];

// export interface ActionItemsSchema {
//   actionParty: string;
//   dueDate: string;
//   actions?: string;
// }

// function createActionItems(actionParty: string, dueDate: string, actions?: string): ActionItemsSchema {
//   return {
//     actionParty,
//     dueDate,
//     actions,
//   };
// }

// export const mockupRowsActionItems: ActionItemsSchema[] = [
//   createActionItems(
//     'Lorem ipsum dolor sit amet, consect eturadipiscing elit. Vestibulum a vestibulum mauris. Vestibulum nec nunc scelerisq iaculis nunc non, bibendum tellus. Maecenas pellentesque mauris elementum egestas.',
//     'none'
//   ),
//   createActionItems(
//     'Lorem ipsum dolor sit amet, consect eturadipiscing elit. Vestibulum a vestibulum mauris. Vestibulum nec nunc scelerisq iaculis nunc non, bibendum tellus. Maecenas pellentesque mauris elementum egestas.',
//     'none'
//   ),
// ] as ActionItemsSchema[];

export const mockupRegalFilter: GetLegalBuRelated = {
  status: { code: 200, message: 'success' },
  data: [
    { buCode: 'arv', buName: 'ARV' },
    { buCode: 'cariva', buName: 'Cariva' },
    { buCode: 'rovula', buName: 'Rovula' },
    { buCode: 'skyller', buName: 'Skyller' },
    { buCode: 'varuna', buName: 'Varuna' },
  ] as LegalRelatedBuListSchema[],
};

export const ggg123 = false;
