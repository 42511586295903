/** declare only http service related requests */

import { HttpTokens, QueryCalendarEvents, GetCalendarEvents } from '@/src/types';
import { AxiosService } from '@/utils/axios';
// import { niceUrl, noSlash } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { paramsQueryString, printDate } from '@/utils/index';
import { DEFAULT_HTTP_TIMEOUT } from '../..';
/**
 * @description Get calendar event list by specific type: year/month/week/day,
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */
export const CalendarEventListGet = (
  prefix: string = 'calendar',
  params: QueryCalendarEvents,
  tokens: HttpTokens,
  hardRejection = true
): Promise<GetCalendarEvents> => {
  const instance = new AxiosService({ method: 'get', timeout: DEFAULT_HTTP_TIMEOUT + 5000, apiRef: prefix, proxyType: 'LOCAL_PROXY' }, true, hardRejection);

  // clone params to check&change params.status as TrainingStatus[] to params.status as string before sent with API

  if (params.date) {
    // * date format must be: yyyy-MM-dd > (ex. 2022-07-18)
    params.date = printDate(params.date, 'YYYY-MM-DD') as any;
  }
  const queryUri = paramsQueryString(prefix, params);
  // const nice = niceUrl(instance.baseURL, noSlash('api/' + prefix));
  // token not yet set on backend
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...(tokens.jwt ? { Authorization: 'Bearer ' + tokens.jwt } : {}),
    },
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<GetCalendarEvents>) => {
      // REVIEW
      // temporarily add id to each calendar event,
      // const data = d.data.data.map((n) => {
      //   n.id = uuidv4();
      //   return n;
      // });
      // d.data.data = data;
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetCalendarEvents>;
};
