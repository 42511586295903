// ? This store is actually for employee api, and being used on /admins route

import { AdminStore, AzureAuthProviderProps, StoreState } from '@/types/store';
import { useEffect, useState } from 'react';
import { EmployeeDelete, EmployeeCreate, EmployeeListGet, BusinessUnitsGet, EmployeeFilterGet, EmployeeUpdate, EmployeeSearchGet } from '@/services/http';
import { cloneDeep } from 'lodash';

import { AdminContext } from './context';
import {
  HttpTokens,
  EmployeeFilterQuery,
  QueryEmployeeList,
  EmployeeBodyData,
  GetEmployeeList,
  EmployeeCreate as CreateEmployee,
  EmployeeUpdate as UpdateEmployee,
  EmployeeDelete as DeleteEmployees,
  EmployeeSearchQuery,
} from '@/src/types';
import { checkIsLastPage, getJwtToken } from '@/src/utils';
import { Logger } from '@/logger/index';

/**
 * to give us access to azure for admin
 */
interface ProviderProps {
  children: React.ReactNode;
  context: {
    [name: string]: any;
    azure: AzureAuthProviderProps;
  };
}

const AdminContextProvider = (props: ProviderProps) => {
  const { children, context } = props;
  /** Access to azure  */
  const { azure } = context;

  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  const tokens: HttpTokens = { jwt: getJwtToken() };
  const [employees, setEmp] = useState<{ data: GetEmployeeList; state: StoreState; error: any }>({ data: undefined, state: 'initial' } as any);
  const [employeeCreated, setCreateEmployee] = useState<any>({ data: undefined, state: 'initial' });
  const [businessUnits, setDataBusinessUnits] = useState<any>({ data: undefined, state: 'initial' });
  const [employeesFilter, setDataEmployeesFilter] = useState<any>({ data: undefined, state: 'initial' });
  const [resDeletedEmployees, setResDeletedEmployees] = useState<any>({ data: undefined, state: 'initial' });
  const [employeesSearchData, setEmployeesSearch] = useState<any>({ data: undefined, state: 'initial' });

  const [resUpdateEmployees, setResUpdateEmployees] = useState<any>({ data: undefined, state: 'initial' });

  const setEmployees = (params?: any) => {
    // NOTE the reson why we have to check with resDeletedEmployees.state === 'initial'
    // is we don't want to set employees data to undefined while loading new data after realtime delete
    if (isMounted && resDeletedEmployees.state === 'initial') setEmp({ data: undefined, state: 'loading' } as any);
    EmployeeListGet('employees', params as QueryEmployeeList, {} as any)
      .then((data) => {
        if (isMounted) {
          setEmp({ data, state: 'ready' } as any);
          // reset state delete to trick old pagination (after deleted) back to normal pagination
          setResDeletedEmployees({ data: undefined, state: 'initial' });
        }
      })
      .catch((err) => {
        if (isMounted) setEmp({ data: undefined, error: err, state: 'error' } as any);
      });
  };

  /**
   * update employees list from inside the store
   *
   */
  const updateEmployeesState = (newEmployee: { data: CreateEmployee }): any => {
    if (!newEmployee?.data) {
      Logger([`[updateEmployeesState][newEmployee]`, `not set`], 'error');
      return;
    }
    if (employees?.data) {
      // check if last row is on current page
      const isLastPage = checkIsLastPage(employees.data.pagination.pageNumber, employees.data.pagination.totalItems, employees.data.pagination.itemPerPage);

      // find updated employee and add to current state
      let updated = false;
      const totalItemsBeforeAdd = employees.data.pagination.totalItems;

      let d = employees.data.data?.map((n) => {
        if (n?.employeeId.indexOf(newEmployee.data?.employeeId) !== -1) {
          n.roleId = newEmployee.data.roleId as any;
          updated = true;
        }
        return n;
      });

      // if not updated means not in paged results
      if (!updated && newEmployee.data) {
        if (!isLastPage) {
          employees.data.data.splice(employees.data.data.length - 1, 1);
        }
        employees.data.data.unshift(newEmployee.data);
        updated = true;
        d = employees.data.data;
      }

      if (updated) {
        employees.data.data = d;
        employees.data.pagination.totalItems = totalItemsBeforeAdd + 1;
        if (isMounted) setEmp(cloneDeep(employees));

        Logger([`[updateEmployeesState][updated]`, `employeeId: ${newEmployee?.data?.employeeId}`], 'attention');
        Logger([`[updateEmployeesState][updated]`, `email: ${newEmployee?.data.email}`], 'attention');
      }
    }
  };

  const employeeUpdateState = (EditEmployee: { data: UpdateEmployee }): any => {
    if (!EditEmployee?.data) {
      Logger([`[updateEmployeesState][EditEmployee]`, `not set`], 'error');
      return;
    }

    if (employees?.data) {
      // find updated employee and add to current state
      let updated = false;

      let d = employees.data.data.map((n) => {
        if (n?.employeeId.indexOf(EditEmployee.data?.employeeId) !== -1) {
          n.roleId = EditEmployee.data.roleId as any;
          updated = true;
        }
        return n;
      });
      // if not updated means not in paged results
      if (!updated && EditEmployee.data) {
        employees.data.data.splice(employees.data.data.length - 1, 1);
        employees.data.data.unshift(EditEmployee.data);
        updated = true;
        d = employees.data.data;
      }

      if (updated) {
        employees.data.data = d;
        if (isMounted) setEmp(cloneDeep(employees));
        Logger([`[updateEmployeesState][updated]`, `employeeId: ${EditEmployee?.data?.employeeId}`], 'attention');
        Logger([`[updateEmployeesState][updated]`, `email: ${EditEmployee?.data.email}`], 'attention');
      }
    }
  };

  const employeeDeleteState = (DeleteEmployee: { data: DeleteEmployees }): any => {
    if (!DeleteEmployee?.data) {
      Logger([`[employeeDeleteState][EditEmployee]`, `not set`], 'error');
      return;
    }

    if (employees?.data) {
      // i delete this pagination out to prevent errors when MUI pagination is delay setting new page after deleted last row on last page, it's still updated when soft delete completed and call API get new list again.
      employees.data.data = employees.data.data.filter((i) => i?.employeeId !== DeleteEmployee.data?.employeeId);
      if (isMounted) setEmp(cloneDeep(employees));
      Logger([`[employeeDeleteState][updated]`, `employeeId: ${DeleteEmployee?.data?.employeeId}`], 'attention');
    }
  };

  const createEmployee = (employeeId: string, body: EmployeeBodyData) => {
    if (isMounted) setCreateEmployee({ data: undefined, state: 'loading' });

    EmployeeCreate('employee/create', employeeId as string, tokens, body)
      .then((data) => {
        if (isMounted) {
          azure.reVerify(data.data?.employeeId);
          setCreateEmployee({ data, state: 'ready' });
        }
      })
      .catch((err) => {
        if (isMounted) setCreateEmployee({ data: undefined, error: err, state: 'error' });
        return Promise.reject(err);
      });
  };

  const deletedEmployees = (employeeId?: string) => {
    if (isMounted) setResDeletedEmployees({ data: null, state: 'loading' });
    EmployeeDelete('employee/delete', employeeId as string, { jwt: getJwtToken() })
      .then((data) => {
        if (isMounted) {
          employeeDeleteState(data);
          azure.reVerify(data.data?.employeeId);
          setResDeletedEmployees({ data, state: 'ready' });
        }
      })
      .catch((err) => {
        if (isMounted) setResDeletedEmployees({ data: undefined, error: err, state: 'error' });
        return Promise.reject(err);
      });
  };

  const updateEmployees = (employeeId?: string, data?: object) => {
    if (isMounted) setResUpdateEmployees({ data: undefined, state: 'loading' });

    EmployeeUpdate('employee/update', employeeId as string, data as object, { jwt: getJwtToken() })
      .then((data) => {
        if (isMounted) {
          azure.reVerify(data.data?.employeeId);
          setResUpdateEmployees({ data, state: 'ready' });
          employeeUpdateState(data);
        }
      })
      .catch((err) => {
        if (isMounted) setResUpdateEmployees({ data: undefined, error: err, state: 'error' });
        return Promise.reject(err);
      });
  };

  const setBusinessUnits = (params?: any) => {
    // if (businessUnits?.data) return;
    if (isMounted) setDataBusinessUnits({ data: undefined, state: 'loading' });

    BusinessUnitsGet('business-units', tokens)
      .then((data) => {
        if (isMounted) setDataBusinessUnits({ data, state: 'ready' });
      })
      .catch((err) => {
        if (isMounted) setDataBusinessUnits({ data: undefined, error: err, state: 'error' });
      });
  };

  const setEmployeesFilter = (params: EmployeeFilterQuery) => {
    //  if (employeesFilter?.data) return;
    if (isMounted) setDataEmployeesFilter({ data: undefined, state: 'loading' });

    EmployeeFilterGet('employees/filter', params, tokens)
      .then((data) => {
        if (isMounted) setDataEmployeesFilter({ data, state: 'ready' });
      })
      .catch((err) => {
        if (isMounted) setDataEmployeesFilter({ data: undefined, error: err, state: 'error' });
        return Promise.reject(err);
      });
  };

  const adminStore: AdminStore = {
    store: {
      resDeletedEmployees: resDeletedEmployees,
      deletedEmployees,
      resUpdateEmployees: resUpdateEmployees,
      updateEmployees,
      createEmployee,
      employeeCreated,
      employees,
      updateEmployeesState,
      setEmployees,
      businessUnits,
      setBusinessUnits,
      employeesFilter: employeesFilter,
      setEmployeesFilter,
      resetStatus: (apiName: string) => {
        if (apiName === 'employees/search') {
          setEmployeesSearch({ data: undefined, state: 'initial' });
        }
        if (apiName === 'employee/update') {
          setResUpdateEmployees({ data: undefined, state: 'initial' });
        }
        if (apiName === 'employee/create') {
          setCreateEmployee({ data: undefined, state: 'initial' });
        }
        //
      },
      setEmployeesSearch: (params: EmployeeSearchQuery) => {
        if (isMounted) setEmployeesSearch({ data: undefined, state: 'loading' });

        EmployeeSearchGet('employees/search', params, tokens)
          .then((d) => {
            if (isMounted) setEmployeesSearch({ data: d, state: 'ready' });
          })
          .catch((err) => {
            if (isMounted) setEmployeesSearch({ data: undefined, error: err, state: 'error' });
            // return Promise.reject(err);
          });
      },
      employeesSearchData,
    },
  };

  return <AdminContext.Provider value={adminStore}>{children}</AdminContext.Provider>;
};

const withAdminContext = (Component: any) => {
  return function AdminsComponent(props: any) {
    return <AdminContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</AdminContext.Consumer>;
  };
};

export { AdminContextProvider, withAdminContext };
