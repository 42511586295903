import {
  LegalChartSchema,
  LegalChartsQuery,
  QueryIncidentsDashboardCharts,
  QueryTrainingChart,
  IncidentDashboardChartSchema,
  TrainingChartSchema,
  TrainingThisMonthSchema,
  TrainingThisYearSchema,
} from '@/src/types';
import { DashboardStore, NotificationStatus } from '@/types/store';
import { createContext } from 'react';
import { initialDataWrapper } from '../common';

export const DashboardContext: React.Context<DashboardStore> = createContext({
  store: {
    notify: {
      /**
       * call to action when status has changed
       */
      onStatusChange: (type: NotificationStatus, message?: string) => {},
      notificationStatus: 'initial',
    },
    resetStatus: (apiName: string) => {},
    setDashboardIncidentsChart: (query?: QueryIncidentsDashboardCharts, done?: (ok: boolean, data?: IncidentDashboardChartSchema) => void) => {},
    dashboardIncidentsChartData: {
      ...initialDataWrapper,
    },
    setDashboardTrainingThisMonth: (done?: (ok: boolean, data?: TrainingThisMonthSchema[]) => void) => {},
    dashboardTrainingThisMonthData: {
      ...initialDataWrapper,
    },
    setDashboardTrainingThisYear: (done?: (ok: boolean, data?: TrainingThisYearSchema[]) => void) => {},
    dashboardTrainingThisYearData: {
      ...initialDataWrapper,
    },
    setDashboardTrainingsChart: (query?: QueryTrainingChart, done?: (ok: boolean, data?: TrainingChartSchema) => void) => {},
    dashboardTrainingsChartData: {
      ...initialDataWrapper,
    },
    setDashboardLegalCharts: (query?: LegalChartsQuery, action?: 'update' | 'new', done?: (ok: boolean, data?: LegalChartSchema) => void) => {},
    dashboardLegalChartsData: {
      ...initialDataWrapper,
    },
  },
} as DashboardStore);
