import React, { useEffect, useState } from 'react';
import { ComplianceGraphSelectedValue, LegalStore, OverallGraphSelectedValue, PropDefaults, ReducerActionType } from '@/src/types';
import { ComplianceChart } from './complianceChart';
import { OverallStatus } from './overallStatusChart';
import { Box, Skeleton, Typography } from '@mui/material';

interface Props extends PropDefaults {
  chartQueryState: ReducerActionType;
  // [name: string]: any;
  // azure: AzureAuthProviderProps;
  // title?: string;
  onClickStatusChart: (data: OverallGraphSelectedValue) => void;
  onClickComplianceChart: (data: ComplianceGraphSelectedValue) => void;

  /**
   * called when chart is ready
   */
  onReadyStatusChart: () => void;
  onReadyComplianceChart: () => void;
}

export const LegalChartSections = (props: Props & LegalStore) => {
  const { store: legalStore, chartQueryState } = props;

  const { onClickStatusChart, onReadyStatusChart, onClickComplianceChart, onReadyComplianceChart } = props;
  const [mount, setMount] = useState<number>(0);

  useEffect(() => {
    if (!mount) {
      if (legalStore.legalChartsData.state === 'initial' && !legalStore.legalChartsData?.data?.data) {
        legalStore.setLegalCharts();
      }
      setMount(1);
    }
  }, [mount, setMount, legalStore]);

  useEffect(() => {
    if (
      chartQueryState.state === 'updated' &&
      chartQueryState.type === 'buCodes' &&
      !chartQueryState.checked &&
      (legalStore.legalChartsData.state === 'ready' || legalStore.legalChartsData.state === 'initial')
    ) {
      legalStore.setLegalCharts({ buCodes: chartQueryState.value?.buRelate }, 'update');
      chartQueryState.checked = true;
      if (chartQueryState.value) chartQueryState.value.loading = false;
    }
  }, [chartQueryState]);

  return (
    <React.Fragment>
      {['error', 'ready'].indexOf(legalStore.legalChartsData.state) !== -1 ? (
        <>
          <OverallStatus chartQueryState={chartQueryState} store={legalStore} onChartClick={onClickStatusChart} onChartReady={onReadyStatusChart} />
          <ComplianceChart chartQueryState={chartQueryState} store={legalStore} onChartClick={onClickComplianceChart} onChartReady={onReadyComplianceChart} />
        </>
      ) : (
        <>
          <Box className="h-auto w-1/2 mr-6 rounded-2xl shadow-md relative">
            <Box className="flex absolute top-6 left-6 z-30">
              <Typography variant="h2" color="primary" className="">
                Overall status
              </Typography>
            </Box>
            <Skeleton
              animation="wave"
              component={'div'}
              className="h-full w-full mr-6 transform-none rounded-2xl border border-solid border-gray-200 bg-white"
            />
          </Box>
          <Box className="w-1/2 rounded-2xl shadow-md">
            <Skeleton animation="wave" component={'div'} className="h-full w-full transform-none rounded-2xl border border-solid border-gray-200 bg-white" />
          </Box>
        </>
      )}
    </React.Fragment>
  );
};
