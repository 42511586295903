import { AdminStore, EmployeeBodyData, EmployeeFilterQuery, QueryEmployeeList, EmployeeCreate as CreateEmployee, EmployeeSearchQuery } from '@/src/types';

import { createContext } from 'react';

const initialDataWrapper = {
  error: undefined,
  data: undefined,
  state: 'initial',
};

export const AdminContext: React.Context<AdminStore> = createContext({
  store: {
    employees: {
      ...initialDataWrapper,
    },
    updateEmployeesState: (newEmployee: { data: CreateEmployee }) => {},
    createEmployee: (employeeId: string, body: EmployeeBodyData) => {},
    setEmployees: (params: QueryEmployeeList) => {},
    deletedEmployees: (id?: string) => {},
    businessUnits: {
      ...initialDataWrapper,
    },
    setBusinessUnits: (params?: string) => {},
    employeesFilter: {
      ...initialDataWrapper,
    },
    resUpdateEmployees: {
      ...initialDataWrapper,
    },
    employeeCreated: {
      ...initialDataWrapper,
    },
    updateEmployees: (data?: object, employeeId?: string) => {},
    setEmployeesFilter: (params: EmployeeFilterQuery) => {},
    setEmployeesSearch: (params: EmployeeSearchQuery) => {},
    employeesSearchData: {
      ...initialDataWrapper,
    },
    resetStatus: (apiName: string) => {},
  },
} as any);
